<template>
    <div class="modal-container z-50">
        <div class="modal-body shadow-xl m-3">
            <span class="modal-close" @click="emit('cancel')">
                <XMarkIcon class="h-5 w-5 text-gray-600 hover:text-gray-600" />
            </span>
            <div class="sm:flex sm:items-start">
                <div class="text-center">
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                        <ExclamationTriangleIcon class="h-6 w-6 text-red-600" />
                    </div>
                    <div class="mt-2">
                        <p class="text-sm text-gray-600">
                            You are about to {{ actionName }} '<strong>{{ name }}</strong
                            >'
                        </p>
                        <p class="text-sm text-gray-600">
                            To confirm this action please input '<strong>{{ actionName?.toLowerCase() }} it</strong>' below.
                        </p>
                    </div>
                    <div class="w-full my-3">
                        <input
                            :id="actionName?.toLowerCase()"
                            v-model="inputValue"
                            aria-label="input"
                            :placeholder="`${actionName?.toLowerCase()} it`"
                            :name="actionName?.toLowerCase()"
                            type="text"
                            class="w-full inline-flex justify-center appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm" />
                    </div>
                </div>
            </div>

            <div class="modal-action">
                <div class="sm:flex sm:flex-row-reverse">
                    <button
                        aria-label="button"
                        type="button"
                        class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        :class="{
                            'opacity-25 cursor-not-allowed': inputValue != `${actionName?.toLowerCase()} it`
                        }"
                        :disabled="inputValue != `${actionName?.toLowerCase()} it`"
                        @click="emit('confirm')">
                        Confirm
                    </button>
                    <button
                        aria-label="button"
                        type="button"
                        class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        @click="emit('cancel')">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    actionName: String,
    name: String
});
const emit = defineEmits(['confirm', 'cancel']);
const inputValue = ref('');
</script>

<style>
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #cececeb5;
}
.modal-body {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 20px 40px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
}
.modal-action {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
}
.modal-close {
    cursor: pointer;
    position: relative;
    align-self: end;
    right: -25px;
    top: -10px;
}
</style>
