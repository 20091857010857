import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLoaderStore } from './loader.store';
import * as transformsApi from '../api/transforms.api';
import _ from 'lodash';

export const useTransformsStore = defineStore('transforms', () => {
    const transformsForConnectors = ref(undefined);
    const transforms = ref(null);
    const loader = useLoaderStore();
    const totalSize = ref(0);
    const page = ref(0);
    const pageSize = ref(0);
    const lastSearchQuery = ref(null);
    const transformConfigs = ref(null);

    return {
        total: computed(() => totalSize.value),
        page: computed(() => page.value),
        pageSize: computed(() => pageSize.value),
        transforms,
        transformsForConnectors,
        configs: computed(() => transformConfigs.value),
        // Getters
        all: computed(() => transforms.value),
        allForConnectors: computed(() => transformsForConnectors.value),
        // Actions
        fetch: async function ({ page_size, page_number, partial_name } = {}) {
            if (
                !partial_name &&
                partial_name !== '' &&
                !lastSearchQuery.value &&
                lastSearchQuery.value !== '' &&
                page_number === page.value &&
                !_.isEmpty(transforms.value) &&
                pageSize.value === page_size
            )
                return transforms.value;

            loader.activateLoading('transforms.fetch');

            return transformsApi
                .fetchTransforms({ page_size, page_number: page_number + 1, partial_name })
                .then(({ result, total }) => {
                    transforms.value = _.isEmpty(result || []) ? [] : result;

                    page.value = page_number;
                    pageSize.value = page_size;
                    totalSize.value = total;
                    lastSearchQuery.value = partial_name;

                    return result;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('transforms.fetch');
                });
        },
        fetchTransformConfigs: async () => {
            loader.activateLoading('metrics.transforms.fetchTransformConfigs');
            try {
                if (transformConfigs.value && !_.isEmpty(transformConfigs.value)) {
                    return transformConfigs.value;
                }

                const data = await transformsApi.fetchTransformConfigs();
                transformConfigs.value = data;

                return data;
            } catch (err) {
                console.error(err);
            } finally {
                loader.deactivateLoading('metrics.transforms.fetchTransformConfigs');
            }
        },
        fetchForConnectors: async function (forceFetch = false, pageSizeNumber = 1000) {
            if (transformsForConnectors.value && !_.isEmpty(transformsForConnectors.value) && !forceFetch) {
                return transformsForConnectors.value;
            }

            loader.activateLoading('transforms.fetch');

            return transformsApi
                .fetchTransformsForConnectors(pageSizeNumber)
                .then((data) => {
                    transformsForConnectors.value = data.result;

                    return data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('transforms.fetch');
                });
        },
        // eslint-disable-next-line no-unused-vars
        editTransform: async function (transform) {
            loader.activateLoading('transforms.edit');

            return transformsApi
                .editTransform(transform)
                .then((data) => {
                    transforms.value = transforms.value.map((t) => {
                        if (t.id === data.id) {
                            return {
                                ...t,
                                ...data
                            };
                        }

                        return t;
                    });

                    return transforms.value;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('transforms.edit');
                });
        }
    };
});
