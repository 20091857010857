<template>
    <nav class="flex items-center justify-between py-3" aria-label="Pagination">
        <div class="hidden sm:block">
            <div v-if="message && message.show && !message.showCustomMessage" class="flex text-sm text-gray-700">
                <span class="pr-1">{{ message.firstPart }}</span>
                <span class="font-medium px-1">{{ pageFrom }}</span>
                <span>{{ message.middlePart }}</span>
                <span class="font-medium pl-1">{{ pageTo }}</span>
                <span v-if="!smallAmountOfItems" class="pl-1">{{ lastPart }}</span>
            </div>
        </div>
        <p v-if="message.showCustomMessage" class="text-sm text-gray-700">
            <slot name="customMessage" />
        </p>
        <div v-if="currentPageItemsCount" class="flex flex-1 justify-between sm:justify-end">
            <span class="isolate inline-flex rounded-md shadow-sm">
                <button
                    aria-label="button"
                    type="button"
                    class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 hover:enabled:bg-gray-50 focus:z-10 disabled:bg-gray-100"
                    :disabled="disableNavigation || isFirstPage()"
                    @click="changePage(-1)">
                    <span class="sr-only">Previous</span>
                    <ChevronLeftIcon class="h-5 w-5" />
                </button>
                <div
                    type="button"
                    class="relative -ml-px inline-flex items-center justify-center bg-white px-3 py-2 text-sm text-gray-600 ring-1 ring-inset ring-gray-300 w-[96px]">
                    {{ pageText }} {{ page + 1 }}
                </div>
                <button
                    aria-label="button"
                    type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 hover:enabled:bg-gray-50 focus:z-10 disabled:bg-gray-100"
                    :disabled="disableNavigation || isLastPage()"
                    @click="changePage(1)">
                    <span class="sr-only">Next</span>
                    <ChevronRightIcon class="h-5 w-5" />
                </button>
            </span>
        </div>
    </nav>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'Pagination',
    components: {
        ChevronLeftIcon,
        ChevronRightIcon
    },
    props: {
        page: {
            type: Number,
            required: true
        },
        pageSize: {
            type: Number,
            required: true
        },
        currentPageItemsCount: {
            type: Number,
            default: 0
        },
        disableNavigation: {
            type: Boolean,
            default: false
        },
        totalItemsCount: {
            type: Number,
            default: null
        },
        message: {
            type: Object,
            default: {
                show: true,
                showCustomMessage: false,
                firstPart: 'Showing results',
                middlePart: 'to',
                lastPart: ''
            }
        },
        pageText: {
            type: String,
            default: 'Page'
        }
    },
    emits: ['pageChange'],
    data() {
        return {
            copied: false
        };
    },
    computed: {
        pageFrom() {
            return !this.pageTo ? 0 : this.page * this.pageSize + 1;
        },
        pageTo() {
            return this.page * this.pageSize + Math.min(this.currentPageItemsCount, this.pageSize);
        },
        smallAmountOfItems() {
            return this.totalItemsCount <= this.pageSize;
        },
        lastPart() {
            if (this.message?.lastPart) return this.message.lastPart;

            if (!this.smallAmountOfItems) {
                return `of ${this.totalItemsCount}`;
            }
        }
    },
    methods: {
        changePage(increment) {
            if ((this.isFirstPage() && increment < 0) || (this.isLastPage() && increment > 0)) {
                return;
            }
            this.$emit('pageChange', this.page + increment);
        },
        isFirstPage() {
            return this.page === 0;
        },
        isLastPage() {
            const maxItemsUntilCurrentPage = (this.page + 1) * this.pageSize;
            if (!this.totalItemsCount) {
                return this.pageTo < maxItemsUntilCurrentPage;
            }
            return this.totalItemsCount <= maxItemsUntilCurrentPage;
        }
    }
};
</script>

<style scoped></style>
