import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAuthStore = defineStore('auth', () => {
    const isAuthenticated = ref(false);
    const accessToken = ref('');
    const user = ref(null);
    const userTenantId = ref(null);
    const authState = ref(null);
    const organisation = ref(null);
    const isOrgAdmin = ref(false);
    const isCustomerTenant = ref(false);
    const isStreamkapUser = ref(false);

    const updateState = (newState) => {
        authState.value = newState;

        userTenantId.value = newState.user?.tenantId;
        isStreamkapUser.value = newState.user?.email?.includes('@streamkap.com');

        const currentTenant = newState.user?.tenants?.find((x) => x.tenantId === userTenantId.value);

        if (currentTenant) {
            const hasAdminRole = currentTenant.roles.some((r) => r.key.toLowerCase() === 'admin');
            if (hasAdminRole) {
                isOrgAdmin.value = true;
            }

            isCustomerTenant.value = !newState?.tenantsState?.activeTenant?.name?.toLowerCase().includes('streamkap');
        }

        if (newState.tenantsState.tenants && newState.user !== user.value) {
            organisation.value = newState.tenantsState.tenants.find((tennat) => tennat.tenantId === newState.user?.tenantId);
        }

        if (newState.user && newState.user.accessToken !== accessToken.value) {
            accessToken.value = newState.user.accessToken;
        }

        if (newState.isAuthenticated !== isAuthenticated.value) {
            isAuthenticated.value = newState.isAuthenticated;
        }

        if (newState.user && newState.user !== user.value) {
            const name = newState.user ? newState.user.name : '';

            const regex = new RegExp(/^(?<first>[\w-. ]+\s)+(?<last>[\w]*)$/g);
            const result = regex.exec(name);
            if (result) {
                user.value = {
                    firstName: (result.groups.first || '').trim(),
                    lastName: (result.groups.last || '').trim(),
                    ...newState.user
                };
            } else {
                user.value = {
                    lastName: name,
                    ...newState.user
                };
            }
        }
    };

    return {
        isAuthenticated,
        accessToken,
        user,
        userTenantId,
        organisation,
        isOrgAdmin,
        isStreamkapUser: computed(() => isStreamkapUser.value),
        isCustomerTenant: computed(() => isCustomerTenant.value),
        updateState
    };
});
