<script>
import { DocumentMagnifyingGlassIcon, ChatBubbleLeftRightIcon, NewspaperIcon } from '@heroicons/vue/24/outline';
import { useLoaderStore } from '@/stores/loader.store';
import { useAuthStore } from '@/stores/auth.store';
import _ from 'lodash';

export default {
    name: 'ProductUpdatesSection',
    components: {
        DocumentMagnifyingGlassIcon,
        ChatBubbleLeftRightIcon,
        NewspaperIcon
        // eslint-disable-next-line vue/no-reserved-component-names
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            livechatOpen: false,
            unbind: null,
            authStore: useAuthStore(),
            loaderStore: useLoaderStore(),
            ...this.mapAuthState(),
            unreadAnnouncements: 0
        };
    },
    computed: {
        announceKitUser() {
            if (
                !this.authStore.user?.firstName ||
                !this.authStore.user ||
                _.isEmpty(this.authStore.user) ||
                !this.authState?.tenantsState ||
                _.isEmpty(this.authState?.tenantsState)
            ) {
                return null;
            }

            const user = {
                ...this.authStore.user,
                tenant: {
                    ...this.authState?.tenantsState?.activeTenant
                }
            };

            return {
                id: user.id,
                email: user.email,
                name: user.firstName + ' ' + user.lastName,
                tenant: {
                    id: user.tenant.tenantId,
                    name: user.tenant.name
                }
            };
        },
        isAuthenticated() {
            return !!this.authState.isAuthenticated;
        }
    },
    async mounted() {
        try {
            this.unreadAnnouncements = await this.$refs?.ankRef?.unread();

            this.unbind = await window.Pylon('onChangeUnreadMessagesCount', this.updateUnreadCount);
        } catch (error) {
            console.error(error);
        }
    },
    beforeUnmount() {
        if (this.unbind) this.unbind();
    },
    methods: {
        toggleLivechat() {
            this.livechatOpen ? window.Pylon('hide') : window.Pylon('show');
            this.livechatOpen = !this.livechatOpen;
        },
        updateUnreadCount(data) {
            this.unreadCount = data.unread_count;
        }
    }
};
</script>

<template>
    <div class="flex items-center justify-center flex-shrink-0 py-4 px-2" :class="!isMobile ? 'gap-1.5' : 'gap-3'">
        <div
            class="cursor-pointer flex justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-600 shadow-sm hover:bg-gray-50"
            style="min-width: 3.75rem"
            @click="toggleLivechat()">
            <div class="flex items-center justify-center p-1">
                <ChatBubbleLeftRightIcon class="h-5 w-5 text-gray-600" />
                <!-- <div v-if="loaderStore.isLoading('user.fetchFrontChatHash')" class="snippet ml-3 mr-2" data-title=".dot-flashing">
                    <div class="stage">
                        <div class="dot-flashing" />
                    </div>
                </div> -->

                <!-- Unread count badge -->
                <span
                    v-if="unreadCount"
                    class="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full">
                    {{ unreadCount }}
                </span>
            </div>
        </div>
        <div
            aria-label="button"
            type="button"
            style="min-width: 3.75rem"
            class="cursor-pointer p-1 flex justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-600 shadow-sm hover:bg-gray-50">
            <a href="https://docs.streamkap.com" target="_blank" rel="noopener noreferrer" class="flex justify-center items-center">
                <DocumentMagnifyingGlassIcon class="h-5 w-5 text-gray-600 group-hover:text-gray-600" />
            </a>
        </div>
        <div
            class="p-1 ak-trigger rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-600 shadow-sm hover:bg-gray-50 cursor-pointer"
            style="min-width: 3.75rem">
            <div v-if="announceKitUser && isAuthenticated" class="flex justify-center items-center">
                <NewspaperIcon class="h-5 w-5 text-gray-600 group-hover:text-gray-600" :class="unreadAnnouncements ? 'ml-0' : 'ml-2'" />
                <!-- <span class="mr-1">What's New </span> -->
                <AnnounceKit
                    ref="ankRef"
                    catch-click=".ak-trigger"
                    widget="https://updates.streamkap.com/widgets/v2/2y77y"
                    :user="announceKitUser"
                    class="ml-2" />
            </div>
        </div>
    </div>
</template>

<style></style>
