<script setup>
import { watch, computed, ref, onMounted, onUnmounted } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useSourcesStore } from '../../stores/sources.store';
import AppLoader from '../../components/AppLoader.vue';
import DynamicField from '../../components/DynamicField.vue';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import Tooltip from '../../components/Tooltip.vue';
import ErrorMessage from '../../components/ErrorMessage.vue';
import { ChevronUpIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import TreeView from '../../components/TreeView.vue';
import _ from 'lodash';
import { buildTreeNodesOutOfExistingConfig, adjustControlConditionalVisibility, adjustControlConditionalValues } from '../../utils/utils';
import RowActionMenu from '@/components/RowActionMenu.vue';

const props = defineProps({
    connector: {
        type: String,
        default: null
    },
    cloneConfig: {
        type: Object,
        default: null
    }
});

const sources = useSourcesStore();
const router = useRouter();
const connection = ref({});
const connectionName = ref('');
let loading = ref(false);
let loadingList = ref(false);
let formError = ref('');
let selectedTitle = ref(null);
let schemaSelectionEnabled = ref(false);
let sourceSettingsSnapshot = ref(null);
let noExistingSchema = ref(true);

let treeLevels = ref([]);
let treeNodes = ref([]);
let canSave = ref(true);
let allSourceConnectors = ref([]);
let advancedMode = ref(false);
let hidePromptTemporary = ref(false);

const hideLateralDescriptionValue = ref(true);

const handleResize = () => {
    hideLateralDescriptionValue.value = window.innerWidth <= 1024;
};

const visibleConnectorSchemaConfig = computed(() => {
    const connectorConfig =
        sourceConnectorConfig.value
            ?.filter((x) => !x.display_advanced)
            .filter((x) => x.tab === 'schema' && !x.schema_level && !x.schema_name_format) || [];

    return _.orderBy(connectorConfig, 'order_of_display', ['asc']);
});

const visibleBasicConnectorConfigControls = computed(() => {
    return _.chain(adjustControlConditionalValues(sourceConnectorConfig.value))
        .filter((x) => !x.display_advanced)
        .filter((x) => x.tab !== 'schema')
        .orderBy('order_of_display', 'asc')
        .map((control) => adjustControlConditionalVisibility(control, sourceConnectorConfig.value)?.control || null)
        .compact()
        .value();
});

const visibleAdvancedConnectorConfigControls = computed(() => {
    return _.chain(adjustControlConditionalValues(sourceConnectorConfig.value))
        .filter((x) => x.display_advanced)
        .filter((x) => x.tab !== 'schema')
        .orderBy('order_of_display', 'asc')
        .map((control) => adjustControlConditionalVisibility(control, sourceConnectorConfig.value)?.control || null)
        .compact()
        .value();
});

window.addEventListener('resize', handleResize);

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

const sourceConnectorConfig = computed(() => {
    const configurations = sources.configurations[props.connector];
    if (!configurations) return null;

    const connectorConfig = _.cloneDeep(configurations);
    const toClone = sourceSettingsSnapshot.value || props.cloneConfig;

    if (toClone && connectorConfig) {
        const cloneConfig = _.isString(toClone) ? JSON.parse(toClone) : toClone;

        return _.map(connectorConfig, (configItem) => {
            // Avoid the encrypt parts only if it's from a clone
            if (configItem.encrypt && props.cloneConfig) return configItem;

            const clonedConfigValue = cloneConfig[configItem.name];
            if (_.isNil(clonedConfigValue)) return configItem;

            return {
                ...configItem,
                value: {
                    ...configItem.value,
                    default: _.isObject(clonedConfigValue) ? clonedConfigValue.value : clonedConfigValue
                }
            };
        });
    }

    return connectorConfig;
});

const constructTreeNodes = () => {
    var sourceConnector = Object.values(sources.sourceConnectors)
        .flat()
        .find((x) => x.connector == props.connector);

    if (props.cloneConfig) {
        const nodes = buildTreeNodesOutOfExistingConfig(JSON.parse(props.cloneConfig), sourceConnectorConfig.value, sourceConnector);

        treeNodes.value = nodes.treeNodes;
        treeLevels.value = nodes.treeLevels;
    } else {
        treeNodes.value = [];

        sourceConnector.schema_levels.forEach((schemaLevel) => {
            const treeLevel = {
                schemaLevel: schemaLevel,
                schemaObject: sourceConnector.config.find((c) => c.tab === 'schema' && c.schema_level == schemaLevel)?.name
            };

            treeLevels.value.push(treeLevel);
        });
    }
};

function updateCanSave(newValue) {
    canSave.value = newValue;
}

onMounted(async () => {
    handleResize();

    try {
        loadingList.value = true;
        allSourceConnectors.value = await getSourcesConnectors();

        selectedTitle.value = allSourceConnectors.value?.find((conn) => conn.connector == props.connector)?.name;

        watch(
            props,
            async ({ connector }) => {
                try {
                    loadingList.value = true;

                    if (connector && !sourceConnectorConfig.value) await sources.fetchConfiguration(connector);

                    noExistingSchema.value = !sourceConnectorConfig.value?.some((x) => x.tab === 'schema');

                    if (props.connector) {
                        if (!sources.sources) {
                            await sources.fetch();
                        }

                        if (!noExistingSchema.value) constructTreeNodes();
                    }
                } finally {
                    loadingList.value = false;
                }
            },
            { immediate: true }
        );
    } finally {
        loadingList.value = false;
    }
});

watch(
    treeNodes,
    (newVal) => {
        editTreeLists(newVal);
    },
    { deep: true }
);

const onSaveSource = () => {
    loading.value = true;

    const config = Object.values(connection.value).reduce((payload, { name, value }) => {
        return {
            ...payload,
            [name]: value
        };
    }, {});

    sources
        .create({
            name: connectionName.value,
            connector: props.connector,
            config
        })
        .then(async () => {
            // sources.sources = [];
            // metrics.sources.inlineSourceMetricsList = [];
            hidePromptTemporary.value = true;
            router.push({ name: 'connectors' });
        })
        .catch((err) => {
            formError.value = err;
        })
        .finally(() => {
            loading.value = false;
            hidePromptTemporary.value = false;
        });
};

const getClass = (control, index, advanced = false) => {
    const spaceLeft = control.space_left;
    const usedConnectors = advanced ? visibleAdvancedConnectorConfigControls.value : visibleBasicConnectorConfigControls.value;

    const nextControlSpaceLeft = usedConnectors[index + 1]?.space_left;
    return spaceLeft ? (!nextControlSpaceLeft ? 'pl-5 mb-7' : 'pl-5 mb-3') : 'mb-3';
};

const onFieldChanged = ({ value, valid, name }) => {
    const control = sources.configurations[props.connector].find((x) => x.name == name);

    if (control) {
        control.value.current_value = value;
    }

    connection.value[name] = {
        value,
        valid,
        name
    };
};

onBeforeRouteLeave((to, from, next) => {
    if (loadingList.value) {
        return next();
    }

    if (hidePromptTemporary.value) {
        return next();
    }

    const answer = window.confirm('Are you sure you want to leave? You may have unsaved changes.');

    if (answer) {
        return next();
    } else {
        return next(false);
    }
});

const enableNext = computed(() => {
    const propsConnector = props.connector;
    const sourceConfig = sourceConnectorConfig.value?.filter((x) => x.tab !== 'schema');

    if (propsConnector && sourceConfig && connectionName.value.length > 0) {
        let valid = true;

        for (let i = 0; i < sourceConfig.length; i++) {
            const name = sourceConfig[i].name || '';
            const isConnectionValid = sourceConfig[i].display_in_ui ? connection.value[name] && connection.value[name].valid : true;
            valid = valid && isConnectionValid;
        }

        return valid;
    } else {
        return false;
    }
});

const enableSave = computed(() => {
    const propsConnector = props.connector;

    // if there is no schema, we have to just see if the other parts of the connection is valid
    const sourceConfig = sourceConnectorConfig.value?.filter((x) => (noExistingSchema.value ? !!x : x.tab === 'schema'));

    if (propsConnector && sourceConfig && connectionName.value.length > 0) {
        let valid = true;

        for (let i = 0; i < sourceConfig.length; i++) {
            const name = sourceConfig[i].name || '';
            const isConnectionValid = sourceConfig[i].display_in_ui ? connection.value[name] && connection.value[name].valid : true;
            valid = valid && isConnectionValid;
        }

        const treeNodesValue = treeNodes.value;

        const finalResult = valid && canSave.value && (noExistingSchema.value || !_.isEmpty(treeNodesValue));

        return finalResult;
    } else {
        return false;
    }
});

async function getSourcesConnectors() {
    var results = [];

    const values = await sources.fetchConnectors();

    Object.values(values || {})
        .flat()
        .forEach((value) => {
            const item = { name: value.display_name, connector: value.connector };
            results.push(item);
        });

    return _.orderBy(results, 'name', ['asc']);
}

const getConnectorType = (sourceConnector) => {
    const connector = Object.values(sources.sourceConnectors)
        .flat()
        .find((x) => x.connector == sourceConnector);

    const arr = connector?.status?.split(' ');

    for (var i = 0; i < arr?.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const firstLetterUpper = arr?.join(' ');

    return firstLetterUpper == 'Beta' ? firstLetterUpper : '';
};

const getConnectorTypeColor = (connector) => {
    const sourceType = getConnectorType(connector);

    let result = '#F3F4F6';
    switch (sourceType) {
        // case "Published":
        //     result = "rgb(220, 252, 231)";
        //     break;
        case 'Beta':
            result = 'rgb(254 249 195)';
            break;
        default:
            break;
    }
    return result;
};

function constructTreeLists(nodesList) {
    const outputMap = {};

    treeLevels.value.forEach((level) => {
        outputMap[level.schemaObject] = [];
    });

    const processNode = (node, parentName) => {
        if (node.isSelected) {
            const schemaObject = node.schemaObject;
            if (schemaObject) {
                const nodeName = parentName ? `${parentName}.${node.name}` : node.name;
                outputMap[schemaObject].push(nodeName);
            }
            if (node.children) {
                node.children.forEach((childNode) => processNode(childNode, node.name));
            }
        }
    };

    nodesList.forEach((node) => processNode(node, null));
    return outputMap;
}

function editTreeLists(nodesList) {
    const outputMap = constructTreeLists(nodesList);

    for (const schemaObject in outputMap) {
        const value = outputMap[schemaObject].join(', ');
        const valid = value.length > 0;
        const name = schemaObject;

        connection.value[name] = {
            value,
            valid,
            name
        };
    }
}

function goNext() {
    sourceSettingsSnapshot.value = _.cloneDeep(connection.value);
    schemaSelectionEnabled.value = !schemaSelectionEnabled.value;
}
</script>

<template>
    <div class="min-h-screen">
        <div class="page-header">
            <AppHeader gap="''" :show-title="false">
                <template #titleLeft>
                    <nav class="text-gray-700" aria-label="Breadcrumb">
                        <ol class="list-none p-0 inline-flex font-semibold">
                            <li class="flex items-center">
                                <router-link to="/connectors?tab=Sources" class="text-green-400 hover:text-green-500">Sources</router-link>
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                <router-link to="/connectors/add?tab=Sources" class="text-green-400 hover:text-green-500">Add Source</router-link>
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                <span v-if="selectedTitle" class="text-gray-600">{{ selectedTitle }}</span>
                                <div v-else class="snippet mt-2 ml-2 flex items-baseline" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </template>
            </AppHeader>
        </div>
        <AppLoader :force-loading="loadingList || (!sourceConnectorConfig && connector)" default-height="80vh">
            <!-- <div v-if="!props.connector">
            <ConnectorList v-if="allSourceConnectors.length > 0" :connectors="allSourceConnectors" @select="onConnectorSelected" />
            <div v-else>0 source connectors found.</div>
        </div> -->
            <div>
                <div v-if="formError" class="flex flex-col items-end text-md font-semibold mr-1">
                    <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'create-source'" />
                </div>
                <div v-if="sourceConnectorConfig" class="flex flex-row justify-center w-full">
                    <div class="w-full">
                        <div class="flex justify-between items-center mb-3">
                            <div class="flex flex-row gap-2 items-center">
                                <img
                                    class="self-start h-12 w-12 flex-shrink-0"
                                    :src="`/icons/${props.connector}.svg`"
                                    :alt="`${props.connector} icon`" />
                                <span class="text-lg font-medium text-gray-800">{{ selectedTitle }}</span>
                            </div>
                            <div v-if="getConnectorType(props.connector)">
                                <span
                                    class="inline-flex items-center rounded-full px-4 py-2 text-sm font-medium uppercase shadow-sm"
                                    :style="`background: ${getConnectorTypeColor(props.connector)};`">
                                    {{ getConnectorType(props.connector) }}
                                </span>
                            </div>
                        </div>
                        <div class="bg-white p-4 shadow sm:rounded-lg sm:px-10 overflow-y-auto" style="max-height: 80vh">
                            <form
                                v-show="!schemaSelectionEnabled"
                                class="grid grid-cols-1 gap-y-3 sm:grid-cols-1 sm:gap-x-8 mt-5"
                                action="#"
                                method="POST"
                                autocomplete="new-password">
                                <div class="mb-5">
                                    <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                                    <div class="flex items-center mt-1">
                                        <div class="flex-1" :class="hideLateralDescriptionValue ? 'mr-2' : 'mr-4'">
                                            <input
                                                id="name"
                                                v-model="connectionName"
                                                aria-label="input"
                                                name="name"
                                                type="text"
                                                autocomplete="new-password"
                                                required=""
                                                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm" />
                                        </div>

                                        <div
                                            v-if="!hideLateralDescriptionValue"
                                            class="flex-1 p-3 bg-green-50 border-l-4 border-green-200 text-xs text-black rounded-md relative arrow-left">
                                            The name of the source
                                        </div>

                                        <Tooltip v-else tooltip-text="The name of the destination" />
                                    </div>
                                </div>

                                <div v-for="(control, index) in visibleBasicConnectorConfigControls" :key="index">
                                    <div v-show="control.display_in_ui" :class="getClass(control, index)">
                                        <DynamicField
                                            :key="control.name"
                                            :config="control"
                                            :hide-lateral-description="false"
                                            @input="onFieldChanged" />
                                    </div>
                                </div>

                                <div v-if="visibleAdvancedConnectorConfigControls.length > 0">
                                    <div>
                                        <h2 class="mb-0">
                                            <div
                                                class="group relative flex w-full items-center font-medium p-2 rounded-t-lg text-md cursor-pointer hover:bg-green-50 transition-all duration-300 ease-in-out"
                                                :class="advancedMode ? 'bg-green-50' : ''"
                                                type="button"
                                                @keydown.enter="advancedMode = !advancedMode"
                                                :aria-expanded="advancedMode"
                                                role="button"
                                                @click="advancedMode = !advancedMode">
                                                Advanced
                                                <span class="ml-auto h-5 w-5 shrink-0 transition-transform duration-200 ease-in-out">
                                                    <ChevronUpIcon v-show="!advancedMode" class="h-5 w-5 text-gray-600"></ChevronUpIcon>
                                                    <ChevronDownIcon v-show="advancedMode" class="h-5 w-5 text-gray-600"></ChevronDownIcon>
                                                </span>
                                            </div>
                                        </h2>
                                        <div
                                            v-show="advancedMode"
                                            class="grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-8 mb-5"
                                            style="max-height: 45vh"
                                            :class="advancedMode ? 'border p-5 pt-8' : 'p-3'">
                                            <div v-for="(control, index) in visibleAdvancedConnectorConfigControls" :key="index">
                                                <div v-show="control.display_in_ui" :class="getClass(control, index, true)">
                                                    <DynamicField
                                                        :key="control.name"
                                                        :config="control"
                                                        :hide-lateral-description="false"
                                                        @input="onFieldChanged" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div v-show="schemaSelectionEnabled">
                                <div :class="visibleConnectorSchemaConfig?.length ? 'mt-10' : 'mt-3'">
                                    <div v-for="(parameter, index) in visibleConnectorSchemaConfig" :key="index">
                                        <div
                                            v-show="parameter.display_in_ui"
                                            :class="
                                                parameter.space_left
                                                    ? !visibleConnectorSchemaConfig[index + 1]?.space_left
                                                        ? 'pl-5 mb-7'
                                                        : 'pl-5 mb-3'
                                                    : 'mb-3'
                                            ">
                                            <DynamicField
                                                :key="parameter.name"
                                                :config="parameter"
                                                :hide-lateral-description="false"
                                                @input="onFieldChanged" />
                                        </div>
                                    </div>
                                </div>

                                <div :class="visibleConnectorSchemaConfig?.length ? 'mb-10' : 'mb-5'">
                                    <label class="text-base font-medium text-gray-700 mb-2">Schema</label>
                                    <div
                                        v-if="!(treeNodes && treeLevels && treeLevels[0] && treeLevels[0].schemaLevel)"
                                        class="snippet mt-2 ml-2 flex items-baseline"
                                        data-title=".dot-flashing">
                                        <div class="stage">
                                            <div class="dot-flashing" />
                                        </div>
                                    </div>
                                    <TreeView
                                        v-else
                                        :tree-nodes="treeNodes"
                                        :tree-levels="treeLevels"
                                        :add-default-empty-node="true"
                                        :edit-mode="true"
                                        :connector-type="props.connector"
                                        @can-save-duplicate="updateCanSave($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLoader>
    </div>
    <AppFooter v-if="sourceConnectorConfig">
        <template #right>
            <RowActionMenu
                :show-save="true"
                :show-next="!noExistingSchema"
                :show-back="true"
                :show-discard="false"
                :disable-back="!schemaSelectionEnabled"
                :disable-next="!enableNext || schemaSelectionEnabled"
                :disable-save="!enableSave || loading"
                :loading="loading"
                @next="goNext"
                @back="schemaSelectionEnabled = !schemaSelectionEnabled"
                @save="onSaveSource" />
        </template>
    </AppFooter>
</template>

<style>
.parent-nodes {
    margin-top: 0px !important;
}

.arrow-left::before {
    content: '';
    position: absolute;
    left: -12px; /* Adjusted for the larger arrow */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent; /* increased for a taller arrow */
    border-bottom: 18px solid transparent; /* increased for a taller arrow */
    border-right: 12px solid #bbf7d0; /* adjusted to match the border color of the blue container */
}
</style>
