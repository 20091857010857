<script>
import { adjustControlConditionalVisibility, adjustControlConditionalValues } from '@/utils/utils';
import DynamicField from './DynamicField.vue';
import TextField from './TextField.vue';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import _ from 'lodash';

export default {
    components: {
        TextField,
        DynamicField,
        ChevronUpIcon,
        ChevronDownIcon
    },
    props: {
        connector: {
            type: Object,
            required: true
        },
        defaultConnector: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        tab: {
            type: String,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        },
        justifyContent: {
            type: String,
            default: 'justify-center'
        }
    },
    data() {
        return {
            defaultConfig: _.cloneDeep(this.defaultConnector.config),
            defaultName: _.cloneDeep(this.defaultConnector.name),
            advancedMode: false,
            currentConfig: _.cloneDeep(this.config)
        };
    },
    computed: {
        configExists() {
            return !_.isEmpty(this.connector.config) && !_.isEmpty(this.currentConfig);
        },
        advancedProps() {
            return this.currentConfig?.filter((config) => config.display_advanced).map((config) => config.name);
        },
        hideLateralDescription() {
            return this.editMode;
        },
        visibleBasicConnectorConfigControls() {
            return _.chain(this.adjustControlConditionalValues(this.currentConfig))
                .filter((x) => !x.display_advanced)
                .orderBy('order_of_display', 'asc')
                .map((control) => this.adjustControlConditionalVisibility(control, this.currentConfig)?.control || null)
                .compact()
                .groupBy('tab')
                .value();
        },
        visibleAdvancedConnectorConfigControls() {
            return _.chain(this.adjustControlConditionalValues(this.currentConfig))
                .filter((x) => x.display_advanced)
                .orderBy('order_of_display', 'asc')
                .map((control) => this.adjustControlConditionalVisibility(control, this.currentConfig)?.control || null)
                .compact()
                .groupBy('tab')
                .value();
        }
    },
    watch: {
        config: {
            handler(val) {
                this.currentConfig = _.cloneDeep(val);
            },
            deep: true
        },
        'connector.config': {
            handler(val) {
                const keys = _.keys(val).filter((key) => !this.advancedProps.includes(key));

                let value = _.omit(
                    this.defaultConfig,
                    Object.keys(this.defaultConfig).filter((key) => !keys.includes(key) && !this.advancedProps.includes(key))
                );

                // we have to populate this with the default values if we have any from the general default config from BE
                value = _.mapValues(value, (v, key) => {
                    if (_.isNil(v)) {
                        const configVal = this.currentConfig.find((x) => x.name == key)?.value?.default;

                        // so we can include also the boolean values or 0 cases
                        return _.isBoolean(configVal) || _.isNumber(configVal) ? configVal : configVal || v;
                    }

                    return v;
                });

                const valueKeys = _.keys(value);
                const configKeys = _.map(this.currentConfig, 'name');

                // we have to populate this with the default values if we have any from the general default config from BE - that is not included at all in the connector config
                const diff = _.difference(configKeys, valueKeys);
                diff.forEach((key) => {
                    const configVal = this.currentConfig.find((x) => x.name == key)?.value?.default;

                    if (!_.isNil(configVal)) {
                        value[key] = configVal;
                    }
                });

                this.$emit('changed', !_.isEqual(val, value) || this.connector.name !== this.defaultName);
            },
            deep: true
        }
    },
    mounted() {
        this.defaultConfig = _.cloneDeep(this.defaultConnector.config);
        this.defaultName = _.cloneDeep(this.defaultConnector.name);
    },
    methods: {
        adjustControlConditionalValues,
        adjustControlConditionalVisibility,
        onFieldChanged({ value, valid, name }) {
            const control = this.currentConfig.find((x) => x.name == name);

            if (control) {
                control.value.current_value = value;

                this.currentConfig = _.chain(this.adjustControlConditionalValues(this.currentConfig))
                    .map((control) => this.adjustControlConditionalVisibility(control, this.currentConfig)?.control || null)
                    .compact()
                    .value();
            }

            if (valid && !_.isUndefined(value)) {
                if (this.connector) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.connector.config[name] = value;
                }
            }
        },
        handleNameChange(newName) {
            // eslint-disable-next-line vue/no-mutating-props
            this.connector.name = newName;

            const keys = _.keys(this.connector.config).filter((key) => !this.advancedProps.includes(key));
            const value = _.omit(
                this.defaultConfig,
                Object.keys(this.defaultConfig).filter((key) => !keys.includes(key) && !this.advancedProps.includes(key))
            );

            this.$emit('changed', this.connector.name !== this.defaultName || !_.isEqual(this.connector.config, value));
        },
        getClass(control, index, advanced = false) {
            const spaceLeft = control.space_left;
            const usedConnectors = advanced ? this.visibleAdvancedConnectorConfigControls : this.visibleBasicConnectorConfigControls;

            const nextControlSpaceLeft = usedConnectors[index + 1]?.space_left;
            return spaceLeft ? (!nextControlSpaceLeft ? 'pl-5 mb-7' : 'pl-5 mb-5') : 'mb-5';
        }
    }
};
</script>

<template>
    <div
        v-if="configExists"
        :class="`bg-white p-4 pt-10 shadow sm:rounded-lg sm:px-10 overflow-y-auto flex flex-row ${justifyContent} w-full`"
        style="max-height: 82vh">
        <div class="sm:w-full">
            <div>
                <form class="grid grid-cols-1 sm:grid-cols-1" action="#" method="POST" autocomplete="new-password">
                    <TextField
                        v-if="tab === 'settings'"
                        :config="{
                            display_name: 'Connector name',
                            description: 'The name of the connector'
                        }"
                        :edit-mode="editMode"
                        :value="connector.name"
                        :override-label-opacity="true"
                        :hide-lateral-description="!editMode"
                        class="pb-6"
                        @input="handleNameChange" />
                    <div v-for="(control, index) in visibleBasicConnectorConfigControls?.[tab]" :key="control.name">
                        <div v-show="control.display_in_ui" :class="getClass(control, index)">
                            <DynamicField
                                :config="control"
                                :value="connector.config[control.name]"
                                :edit-mode="editMode"
                                class="!opacity-100"
                                :override-label-opacity="true"
                                :hide-lateral-description="!editMode"
                                @input="onFieldChanged" />
                        </div>
                    </div>
                    <div v-if="tab !== 'settings' && !visibleBasicConnectorConfigControls?.[tab]">
                        <div class="flex justify-center items-center pb-5">
                            <div class="text-center">
                                <h2 class="text-xl font-medium text-gray-700">No configuration available</h2>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab === 'settings' && visibleAdvancedConnectorConfigControls?.[tab]?.length > 0">
                        <div>
                            <h2 class="mb-0">
                                <div
                                    class="group relative flex w-full items-center font-medium p-2 rounded-t-lg text-md cursor-pointer hover:bg-green-50 transition-all duration-300 ease-in-out"
                                    :class="advancedMode ? 'bg-green-50' : ''"
                                    type="button"
                                    @keydown.enter="advancedMode = !advancedMode"
                                    :aria-expanded="advancedMode"
                                    role="button"
                                    @click="advancedMode = !advancedMode">
                                    Advanced
                                    <span class="ml-auto h-5 w-5 shrink-0 transition-transform duration-200 ease-in-out">
                                        <ChevronUpIcon v-show="!advancedMode" class="h-5 w-5 text-gray-600"></ChevronUpIcon>
                                        <ChevronDownIcon v-show="advancedMode" class="h-5 w-5 text-gray-600"></ChevronDownIcon>
                                    </span>
                                </div>
                            </h2>
                            <div
                                v-show="advancedMode"
                                class="grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-8 mb-5"
                                style="max-height: 45vh"
                                :class="advancedMode ? 'border p-5 pt-8' : 'p-3'">
                                <div v-for="(control, index) in visibleAdvancedConnectorConfigControls?.[tab]" :key="control.name">
                                    <div v-show="control.display_in_ui" :class="getClass(control, index, true)">
                                        <DynamicField
                                            class="!opacity-100"
                                            :config="control"
                                            :value="connector.config[control.name]"
                                            :hide-lateral-description="!editMode"
                                            :edit-mode="editMode"
                                            :override-label-opacity="true"
                                            @input="onFieldChanged" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
'
