import { HeadlessService, FetchResult, ISession } from '@novu/headless';
import Config from '@/utils/config';
import { useAuthStore } from '../stores/auth.store';

class NovuService {
    constructor() {
        this.headlessService = null;
        this.headlessServiceInitialized = false;
        this.unreadCount = null;
        this.unreadCountCallback = null;
    }

    async init() {
        const authStore = useAuthStore();
        const user = authStore.user;
        const organisation = authStore.organisation;

        if (user && organisation) {
            const novuAppId = Config?.novu?.appId;
            const novuSubscriberId = authStore?.userTenantId;

            if (novuAppId && novuSubscriberId) {
                this.headlessService = new HeadlessService({
                    applicationIdentifier: novuAppId,
                    subscriberId: novuSubscriberId
                });

                this.headlessServiceInitialized = await new Promise((resolve) => {
                    this.headlessService.initializeSession({
                        listener: (res) => {},
                        onSuccess: (session) => {
                            this.listenUnreadCount();
                            resolve(true);
                        },
                        onError: (error) => {
                            console.error('Alerts service failed to initialize: ' + error);
                            resolve(false);
                        }
                    });
                });
            }
        }

        return this.headlessServiceInitialized;
    }

    listenUnreadCount() {
        this.headlessService.listenUnreadCountChange({
            listener: (unreadCount) => {
                this.unreadCount = unreadCount;
                if (this.unreadCountCallback) {
                    this.unreadCountCallback(unreadCount);
                }
            }
        });
    }

    registerUnreadCountCallback(callback) {
        this.unreadCountCallback = callback;
    }

    async fetchUserPreferences() {
        let userPreferences = undefined;
        if (this.headlessServiceInitialized) {
            // Fetching user preferences..
            userPreferences = await new Promise((resolve) => {
                this.headlessService.fetchUserPreferences({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        // User preferences fetched successfully.
                        resolve(response);
                    },
                    onError: (error) => {
                        // User preferences failed to be fetched.
                        console.error('User preferences failed to be fetched:' + error);
                        resolve(null);
                    }
                });
            });
        }

        return userPreferences;
    }

    async updateUserPreference(preference) {
        let success = false;
        if (this.headlessServiceInitialized) {
            // Updating user preference..
            success = await new Promise((resolve) => {
                this.headlessService.updateUserPreferences({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        // User preferences updated successfully.
                        resolve(true);
                    },
                    onError: (error) => {
                        // User preferences updated to be fetched.
                        console.error('User preference failed to be updated:' + error);
                        resolve(null);
                    },
                    templateId: preference.templateId,
                    channelType: preference.channelType,
                    checked: preference.checked
                });
            });
        }

        return success;
    }

    async fetchNotifications(page = 1, query) {
        let userNotifications = undefined;
        if (this.headlessServiceInitialized) {
            // Fetching user notifications..
            userNotifications = await new Promise((resolve) => {
                this.headlessService.fetchNotifications({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        // User notifications fetched successfully.
                        resolve(response);
                    },
                    page: page,
                    query,
                    onError: (error) => {
                        // User notifications failed to be fetched.
                        console.error('User notifications failed to be fetched:' + error);
                        resolve(null);
                    }
                });
            });
        }

        return userNotifications;
    }

    async fetchUnreadCount() {
        let userUnreadNotificationsCount = undefined;
        if (this.headlessServiceInitialized) {
            // Fetching user unread notifications count..
            userUnreadNotificationsCount = await new Promise((resolve) => {
                this.headlessService.fetchUnreadCount({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        // User unread notifications count fetched successfully.
                        resolve(response.count);
                    },
                    onError: (error) => {
                        // User unread notifications count failed to be fetched.
                        console.error('User unread notifications count failed to be fetched:' + error);
                        resolve(null);
                    }
                });
            });
        }

        return userUnreadNotificationsCount;
    }

    async MarkNotificationsAs(notificationIds = [], read = false) {
        let success = false;
        if (this.headlessServiceInitialized) {
            // Marking notifications..
            success = await new Promise((resolve) => {
                this.headlessService.markNotificationsAs({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        // Notifications marked successfully.
                        resolve(true);
                    },
                    onError: (error) => {
                        // Notifications failed to be marked.
                        console.error('Notifications failed to be marked:' + error);
                        resolve(false);
                    },
                    messageId: notificationIds,
                    mark: { read }
                });
            });
        }

        return success;
    }

    async RemoveNotifications(notificationIds = []) {
        let deleted = false;
        if (this.headlessServiceInitialized) {
            // Removing notifications..
            deleted = await new Promise((resolve) => {
                this.headlessService.removeNotifications({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        // Removing notifications.
                        resolve(true);
                    },
                    onError: (error) => {
                        // Removing notifications.
                        console.error('Removing notifications failed:' + error);
                        resolve(false);
                    },
                    messageIds: notificationIds
                });
            });
        }

        return deleted;
    }

    async MarkAllNotificationsAsRead() {
        let marked = false;
        if (this.headlessServiceInitialized) {
            marked = await new Promise((resolve) => {
                this.headlessService.markAllMessagesAsRead({
                    listener: ({ data, error, isError, isFetching, isLoading, status }) => {},
                    onSuccess: (response) => {
                        resolve(true);
                    },
                    onError: (error) => {
                        console.error('Marking all notifications as read failed:' + error);
                        resolve(false);
                    }
                });
            });
        }

        return marked;
    }
}

export default new NovuService();
