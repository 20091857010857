import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLoaderStore } from './loader.store';
import * as logsApi from '../api/logs.api';

import { useSourcesStore } from './sources.store';
import { useDestinationsStore } from './destinations.store';

export const useLogsStore = defineStore('logs', () => {
    const logs = ref(undefined);
    const connectors = ref(undefined);

    const loader = useLoaderStore();

    const formatLogs = (response) => {
        const formattedLogs = [];
        response.connector_logs.forEach((log) => {
            formattedLogs.push({
                timestamp: log.timestamp,
                level: log.log_level,
                message: log.message,
                connectorId: log.entity_id,
                connector: log.connector_display_name,
                connectorContext: log.connector_context
            });
        });

        return formattedLogs.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));
    };

    const formatConnectors = (sources, destinations) => {
        const formattedConnectors = [];

        const iterateConnectors = (connectors, context) => {
            connectors.forEach((connector) => {
                formattedConnectors.push({
                    ...connector,
                    context
                });
            });
        };

        iterateConnectors(sources, 'sources');
        iterateConnectors(destinations, 'destinations');
        return formattedConnectors;
    };

    return {
        logs,
        // Getters
        all: computed(() => {
            const sourceStore = useSourcesStore();
            const destinationStore = useDestinationsStore();

            const connectors = [...(sourceStore.allBrief || []), ...(destinationStore.allBrief || [])];

            return logs.value?.map((log) => {
                const connectorInfo = connectors.find((connector) => connector.id === log.connectorId);

                return {
                    ...log,
                    connectorType: connectorInfo?.connector
                };
            });
        }),
        connectors: computed(() => connectors.value),
        // Actions
        fetch: async function (timestampFrom, from, size, sourceIds, destinationIds) {
            loader.activateLoading('logs.fetch');
            const sourceStore = useSourcesStore();
            const destinationStore = useDestinationsStore();

            return await Promise.all([
                logsApi.fetchLogs(timestampFrom, from, size, sourceIds, destinationIds),
                sourceStore.fetchAllBrief(false),
                destinationStore.fetchAllBrief(false)
            ])
                .then(([logsResponse]) => {
                    connectors.value = formatConnectors(sourceStore.allBrief, destinationStore.allBrief);
                    logs.value = formatLogs(logsResponse);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('logs.fetch');
                });
        }
    };
});
