import { useAuthStore } from '@/stores/auth.store';
import { hasAccess } from '@/utils/utils';

const init = (el, binding) => {
    const authStore = useAuthStore();
    const userFromState = authStore.user;

    const config = { permissions: null, roles: null };

    // Determine the type based on the provided modifiers and argument
    const type = binding.modifiers.or ? 'or' : binding.modifiers.and ? 'and' : binding.arg || 'and';

    if (binding.modifiers.permissions) {
        config.permissions = {
            values: binding.value,
            type: type
        };
    }

    if (binding.modifiers.roles) {
        config.roles = {
            values: binding.value,
            type: type
        };
    }

    if (!binding.modifiers.permissions && !binding.modifiers.roles) {
        // Default behavior if no specific modifier is provided
        config.permissions = {
            values: binding.value,
            type: type
        };
    }

    if (!hasAccess(userFromState, config)) {
        el.parentNode && el.parentNode.removeChild(el);
    }
};

const canAccessDirective = (app) => {
    app.directive('can-access', {
        mounted(el, binding) {
            init(el, binding);
        },
        updated(el, binding) {
            init(el, binding);
        }
    });
};

export default canAccessDirective;
