import api from '../services/axios.service';
import { constructQueryParams } from '../utils/utils';

export async function fetchDestinationConnectors(from = 0, size = 1) {
    return api.get(`list_destinations?from=${from}&size=${size}`);
}

export async function fetchDestinations(page = 0, pageSize = 10, searchQuery = null) {
    const queryParams = constructQueryParams({ page, page_size: pageSize, partial_name: searchQuery });

    return api.get(`destinations?${queryParams}`);
}

export async function fetchAllDestinationsBrief() {
    return api.get(`destinations/all`);
}

export async function fetchDestinationConfiguration(connector) {
    return api.get(`destination_configuration?connector=${connector}`);
}

export async function deleteDestinationConnection(id) {
    return api.delete(`destinations?id=${id}`);
}

export async function pauseDestinationConnection(id, hideToast = false) {
    return api.put(`pause_destination?id=${id}`, {}, {}, hideToast);
}

export async function stopDestinationConnection(id, hideToast = false) {
    return api.put(`stop_destination?id=${id}`, {}, {}, hideToast);
}

export async function resumeDestinationConnection(id, hideToast = false) {
    return api.put(`resume_destination?id=${id}`, {}, {}, hideToast);
}

export async function restartDestinationConnection(id) {
    return api.post(`restart_destination?id=${id}`);
}

export async function createDestination(connection) {
    return api.post(`destinations`, connection);
}

export async function getDestination(id) {
    const response = await api.get(`destinations?id=${id}`);

    return response?.result?.[0];
}

export async function editDestination(connection, id, hideToast = false) {
    return api.put(`destinations?id=${id}`, connection, {}, hideToast);
}

export async function getTopicSampleScripts(destinationId, topicIds) {
    const topicsJsonString = topicIds?.length ? JSON.stringify(topicIds) : null;

    return topicsJsonString
        ? api.get(`destinations/sample_scripts?topic_ids=${topicsJsonString}&destination_id=${destinationId}&text=false`)
        : api.get(`destinations/sample_scripts?destination_id=${destinationId}&text=true`, {
              Accept: 'text/plain'
          });
}
