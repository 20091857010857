<script>
import LoadingButton from '../../global/buttons/loading-btn.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import * as notificationsApi from '../../../api/notifications.api';

export default {
    components: {
        LoadingButton,
        CheckCircleIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        isConnected: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        subscriberId: {
            type: String,
            default: null
        },
        providerId: {
            type: String,
            default: null
        },
        isPolling: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        ...notificationsApi,
        async toggleConnection() {
            this.loading = true;

            try {
                let connected = !this.isConnected;

                if (!connected && this.url) {
                    await notificationsApi.deleteSubscriberCredentials(this.subscriberId, this.providerId);
                    this.$emit('update:isConnected', connected);
                }

                if (connected && this.url && !this.isInput) {
                    window.open(this.url, '_blank');
                    this.$emit('update:subscriber', null);
                }
            } catch (error) {
                console.error('Error toggling connection:', error);
            } finally {
                this.loading = false;
            }
        },
        async cancelConnection() {
            try {
                await notificationsApi.deleteSubscriberCredentials(this.subscriberId, this.providerId);
                this.$emit('update:isPolling', false);
            } catch (error) {
                console.error('Failed to delete subscriber credentials:', error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<template>
    <div class="integration-item flex items-center justify-between p-4 border-b bg-white shadow">
        <div class="flex items-start">
            <img :src="logo" :alt="title + ' logo'" class="w-10 h-10 mr-4" />
            <div>
                <div class="flex items-center">
                    <span class="text-md font-medium">{{ title }}</span>
                    <CheckCircleIcon v-if="isConnected" class="h-5 w-5 text-green-700 ml-1" />
                </div>
                <div class="text-xs text-gray-600 italic">App</div>
            </div>
        </div>
        <div class="flex place-content-end space-x-2 w-4/5">
            <LoadingButton
                :custom-css="[
                    'px-6 py-2 w-28 rounded-full font-medium transition',
                    {
                        'bg-gray-500 hover:bg-gray-600': isConnected,
                        'bg-green-400 hover:bg-green-500': !isConnected
                    }
                ]"
                :is-loading="loading || isPolling"
                :can-save="true"
                @click="toggleConnection">
                {{ isConnected ? 'Disconnect' : 'Connect' }}
            </LoadingButton>
            <LoadingButton
                v-if="isPolling && !isConnected"
                custom-css="px-4 py-2 w-24 rounded-full bg-red-500 hover:bg-red-600"
                :is-loading="false"
                :can-save="true"
                @click="cancelConnection">
                Cancel
            </LoadingButton>
        </div>
    </div>
</template>
