<template>
    <div class="w-full pt-5 flex flex-col md:flex-row gap-5">
        <div class="w-full lg:w-1/2 flex flex-col">
            <div class="flex flex-col gap-3 items-center mb-3">
                <div class="font-medium">Select a {{ props.type?.toLowerCase() }}</div>
                <SearchInput
                    :custom-class="'w-2/3'"
                    :placeholder="`Search for a ${props.type?.toLowerCase()}...`"
                    @search="(input) => (connectorSearchQuery = input)" />
            </div>

            <div class="group flex flex-col align-start gap-3 overflow-y-auto pb-3 pr-3" style="max-height: 82vh">
                <div v-for="(group, groupName) in filteredConnectorGroups" :key="groupName" class="relative">
                    <div
                        class="relative bg-white rounded-lg p-4 border cursor-pointer shadow-wrapper gap-3 group-item"
                        @click="group.length > 1 ? selectGroup(groupName) : selectItem(group[0])">
                        <div class="flex justify-between items-center">
                            <div class="flex items-center gap-2 ml-4 font-medium">
                                <img :src="getConnectorIcon(groupName)" alt="" class="w-9 h-9" />
                                {{ groupName }}
                            </div>
                            <!-- <div class="flex flex-row items-center justify-center h-full flex-wrap rounded py-0.5 text-lg font-medium">
                                <span
                                    class="inline-flex items-center rounded-full px-3 py-1 text-xs bg-green-200 text-gray-800 shadow font-medium"
                                    title="Connectors"
                                    >{{ group.length }} type{{ group.length > 1 ? 's' : '' }}</span
                                >
                            </div> -->
                        </div>
                        <div
                            :style="{
                                'max-height': selectedGroupName === groupName ? '10000px' : '0px',
                                transition: selectedGroupName === groupName ? 'max-height 0.5s ease-in-out' : ''
                            }"
                            class="overflow-hidden">
                            <!-- Vertical connector line for the parent -->
                            <div v-if="selectedGroupName === groupName" class="node-branch"></div>
                            <div
                                v-for="(child, index) in group"
                                :key="index"
                                class="flex flex-col w-full align-start gap-3 mt-3 child-node-container">
                                <!-- Horizontal leaf for each child -->
                                <div class="node-branch-leaf"></div>
                                <div
                                    class="w-full rounded-lg hover:bg-gray-100 bg-white p-4 border cursor-pointer flex items-center gap-3 transition-all duration-300 ease-in-out ml-10"
                                    @click.stop="selectItem(child)">
                                    <img :src="getConnectorIcon(child.connector)" alt="" class="w-9 h-9" />
                                    {{ child?.display_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!Object.keys(filteredConnectorGroups).length" class="text-center text-gray-500">
                    No {{ props.type?.toLowerCase() }} found
                </div>
            </div>
        </div>
        <div class="w-full lg:w-1/2 flex flex-col mb-5 lg:mb-0">
            <span class="font-medium mb-4 flex items-center gap-1">
                <UserPlusIcon class="h-5 w-5 inline-block" />
                Need help? Invite a colleague to help you connecting a {{ props.type?.toLowerCase() }}</span
            >
            <div class="bg-white rounded-lg shadow-md p-5 mb-5">
                <div class="mb-3">Not sure which {{ props.type?.toLowerCase() }} to connect? Invite a colleague who does.</div>
                <button
                    aria-label="button"
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                    @click="openInviteModal = true">
                    Invite a colleague
                </button>
            </div>

            <span class="font-medium mb-4 flex items-center gap-1">
                <BookOpenIcon class="h-5 w-5 inline-block" />
                Helpful resources</span
            >
            <div class="bg-white rounded-lg shadow-md p-5">
                <p v-if="props.type?.toLowerCase() === 'source'">
                    A Source is where your data originates. In Streamkap, you can create multiple sources, for each one of your databases.
                </p>
                <p v-else-if="props.type?.toLowerCase() === 'destination'">
                    A Destination is where your data is sent to. In Streamkap, you can create multiple destinations, for each one of your sources.
                </p>
                <div class="flex flex-col gap-1 mt-5">
                    <a
                        href="https://docs.streamkap.com/docs/getting-started"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-cyan-950 hover:text-cyan-800"
                        >Get started with Streamkap

                        <ArrowTopRightOnSquareIcon class="h-4 w-4 inline-block" />
                    </a>

                    <a
                        href="https://docs.streamkap.com/docs/connection-options"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-cyan-950 hover:text-cyan-800">
                        Connection options
                        <ArrowTopRightOnSquareIcon class="h-4 w-4 inline-block" />
                    </a>

                    <a
                        href="https://docs.streamkap.com/docs/streamkap-ip-addresses"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-cyan-950 hover:text-cyan-800">
                        Streamkap IP addresses

                        <ArrowTopRightOnSquareIcon class="h-4 w-4 inline-block"
                    /></a>
                </div>
            </div>
        </div>

        <ModalComponent
            v-if="openInviteModal"
            :is-open="openInviteModal"
            :show-footer-buttons="false"
            title="Invite a colleague"
            :size="'4xl'"
            position="start"
            @update:is-open="openInviteModal = $event">
            <template #header></template>
            <div>
                <div class="mb-4">
                    <label for="colleagueEmail" class="block text-sm font-medium text-gray-700 mb-1">Colleague's Email</label>
                    <input
                        id="colleagueEmail"
                        v-model="colleagueEmail"
                        aria-label="input"
                        :class="{ 'border-red-500': colleagueEmail.length && !isValidEmail(colleagueEmail) }"
                        type="email"
                        class="shadow-sm block w-full border sm:text-sm rounded-md"
                        placeholder="email@example.com" />
                </div>

                <!-- Textarea for invitation message -->
                <div class="mb-4">
                    <label for="invitationMessage" class="block text-sm font-medium text-gray-700 mb-1">Invitation Message</label>
                    <textarea
                        id="invitationMessage"
                        v-model="invitationMessage"
                        rows="10"
                        class="shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md"
                        :placeholder="`Your message...`"></textarea>
                </div>

                <!-- Send Invitation Button -->
                <LoadingButton
                    custom-css="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
                        text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                    :is-loading="props.invitationLoading"
                    :can-save="canSendInvitation"
                    @click="sendInvitation">
                    Send Invitation
                </LoadingButton>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { getConnectorIcon } from '@/utils/constants';
import ModalComponent from '../global/modals/Modal.vue';
import { useFrontegg } from '@frontegg/vue';
import { isValidEmail } from '@/utils/validators';
import { ArrowTopRightOnSquareIcon, BookOpenIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import LoadingButton from '../global/buttons/loading-btn.vue';
import SearchInput from '@/components/global/SearchInput.vue';

const frontegg = useFrontegg();

const props = defineProps({
    connectorGroups: {
        type: Object,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    invitationLoading: {
        type: Boolean,
        required: true,
        default: false
    },
    closeInviteModal: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['select-connector', 'send-invitation']);
const invitationMessage = ref(`Hello!

I have invited you to setup a ${props.type} connector in Streamkap, a real-time streaming platform.
You should have received another invite to sign up (if you don't already have an account).
If you need any clarifications, please get in touch with me.

Thank you!`);

let connectorSearchQuery = ref('');

const colleagueEmail = ref('');
const openInviteModal = ref(false);

const filteredConnectorGroups = computed(() => {
    if (connectorSearchQuery.value) {
        return Object.keys(props.connectorGroups).reduce((acc, groupName) => {
            const filteredGroup = props.connectorGroups[groupName].filter((x) =>
                x.display_name.toLowerCase().includes(connectorSearchQuery.value.toLowerCase())
            );
            if (filteredGroup.length) {
                acc[groupName] = filteredGroup;
            }
            return acc;
        }, {});
    }

    return props.connectorGroups;
});

watch(
    () => props.closeInviteModal,
    (closeInviteModal) => {
        if (closeInviteModal) {
            colleagueEmail.value = '';
            invitationMessage.value = '';

            openInviteModal.value = false;
        }
    }
);

const canSendInvitation = computed(() => {
    return isValidEmail(colleagueEmail.value) && !!invitationMessage.value;
});

const selectedGroupName = ref(null);

const selectGroup = (groupName) => {
    selectedGroupName.value = selectedGroupName.value === groupName ? null : groupName;
};

const selectItem = (child) => {
    emit('select-connector', child);
};

const sendInvitation = () => {
    emit('send-invitation', {
        from_email: frontegg.authState.user?.email,
        tenantId: frontegg.authState.user?.tenantId,
        to_email: colleagueEmail.value,
        message: invitationMessage.value,
        connectorType: props.type.toLowerCase(),
        returnUrl: window.location.href
    });
};
</script>

<style>
.tile:hover {
    transform: translateY(-2px); /* Reduced movement */
}

.group-item {
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.group-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2); /* Slightly more noticeable shadow for testing */
}

.shadow-wrapper::before,
.shadow-wrapper::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    pointer-events: none;
}

.node-branch {
    position: absolute;
    top: 0px; /* Adjust as needed to align with the parent's title */
    bottom: 0;
    left: 15px; /* Adjust to align with the center of the parent's icon */
    width: 2px; /* Width of the vertical line */
    background-color: #efefef;
}

.node-branch-leaf {
    position: absolute;
    top: 50%;
    left: 0px; /* Same as the left position of the vertical line */
    width: 20px; /* Length of the horizontal line */
    height: 2px; /* Thickness of the line */
    background-color: #efefef;
    transform: translateY(-50%);
}

.child-node-container {
    display: flex;
    position: relative;
    align-items: center; /* This will help vertically center the leaf */
    width: 100%;
}
</style>
