<template>
    <!-- Messages Table -->
    <section class="paginated-section">
        <AppLoader
            :force-loading="loading"
            default-height="30vh"
            :default-width="'100%'"
            background="#f9fafb"
            :default-class="'shadow-md rounded-md'">
            <div v-if="!loading" class="relative w-full shadow-md overflow-auto">
                <table class="min-w-full divide-y divide-gray-300 border" aria-label="topic messages table">
                    <thead class="bg-gray-50">
                        <tr>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                <div class="flex">
                                    <span>Offset</span>
                                </div>
                            </th>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                <div class="flex">
                                    <span>Partition</span>
                                </div>
                            </th>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                <div class="flex">
                                    <span>Timestamp</span>
                                </div>
                            </th>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                <div class="flex">
                                    <span>Key</span>
                                </div>
                            </th>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                <div class="flex">
                                    <span>Value</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <template v-if="!loading">
                            <tr
                                v-for="(message, index) in props.messages"
                                :key="index"
                                :class="{
                                    'bg-gray-100': selectedMessage && selectedMessage === message,
                                    'hover:bg-gray-50': !selectedMessage || (selectedMessage && selectedMessage !== message)
                                }"
                                class="transition duration-300 ease-in-out cursor-pointer"
                                @click="selectMessage(message)">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <button aria-label="button" class="focus:outline-none">
                                        <MinusIcon
                                            v-if="selectedMessage && selectedMessage === message"
                                            class="h-4 w-4 text-green-500 border border-green-400" />
                                        <PlusIcon v-else class="h-4 w-4 text-green-500 border border-green-400" />
                                    </button>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center gap-1">
                                        {{ message.offset }}
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ message.partition }}</td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ message.timestamp }}</td>
                                <td class="px-6 py-4 whitespace-nowrap font-mono text-xs truncate max-w-lg">{{ message.key }}</td>
                                <td class="px-6 py-4 whitespace-nowrap font-mono text-xs truncate max-w-lg">{{ message.value }}</td>
                            </tr>
                            <tr v-if="!props.messages.length">
                                <td colspan="99" class="text-center p-2">
                                    <small>No messages found</small>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="loading">
                            <td colspan="3" class="text-center p-4">Loading...</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </AppLoader>

        <!-- Pagination -->
        <Pagination
            v-if="messages.length > 0 || page > 0"
            :page="page"
            :disable-navigation="loading"
            :page-size="props.pageSize"
            :current-page-items-count="messages.length"
            :total-items-count="totalMessages"
            @page-change="onPageChange">
        </Pagination>
    </section>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Pagination from '../Pagination.vue';
import { PlusIcon, MinusIcon } from '@heroicons/vue/24/outline';
import AppLoader from '../../components/AppLoader.vue';

const props = defineProps({
    messages: {
        type: Array,
        default: () => []
    },
    totalMessages: {
        type: Number,
        default: 0
    },
    pageSize: {
        type: Number,
        default: 10
    },
    loading: Boolean,
    unselectAll: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['select-message', 'page-change']);

const page = ref(0);
const selectedMessage = ref(null);

const onPageChange = (newPage) => {
    page.value = newPage;
    emit('page-change', newPage);
};

watch(
    () => props.unselectAll,
    (newVal) => {
        if (newVal) {
            selectedMessage.value = null;
        }
    }
);

const selectMessage = (message) => {
    if (selectedMessage.value === message) {
        selectedMessage.value = null;
    } else {
        selectedMessage.value = message;
    }

    emit('select-message', selectedMessage.value);
};
</script>
