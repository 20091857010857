import api from '../services/axios.service';

export async function fetchLogs(
    timestampFrom,
    from,
    size,
    source_ids = null,
    destination_ids = null
) {
    const body =
        source_ids || destination_ids
            ? { source_ids: source_ids ?? [], destination_ids: destination_ids ?? [] }
            : {};

    return api.post(`logs?timestamp_from=${timestampFrom}&from=${from}&size=${size}`, body);
}
