<template>
    <div class="wrapper">
        <input
            aria-label="input"
            type="range"
            :style="inputStyle"
            :min="min"
            :max="max"
            :step="step"
            :value="currentValue"
            :readonly="!editMode"
            :disabled="!editMode"
            :class="{ disabled: !editMode }"
            @input="onInput"
            @mouseover="onMouseOver"
            @mouseleave="onMouseLeave" />
        <div class="bubble" :style="bubbleStyle">
            {{ currentValue }}
        </div>
        <div class="values">
            <div>{{ min }}</div>
            <div>{{ max }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        min: {
            type: Number,
            required: true
        },
        max: {
            type: Number,
            required: true
        },
        modelValue: {
            type: Number,
            required: true
        },
        step: {
            type: Number,
            default: 1024
        },
        editMode: {
            type: Boolean,
            default: true
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            currentValue: this.modelValue,
            isMouseOverSlider: false,
            bubbleOpacity: 0,
            bubbleOffset: null
        };
    },
    computed: {
        inputStyle() {
            return `background: ${this.computeBackground(this.computePercent())}`;
        },
        bubbleStyle() {
            return `left: ${this.bubbleOffset ?? this.computeBubbleOffset()}; opacity: ${this.bubbleOpacity};`;
        }
    },
    methods: {
        onInput(e) {
            this.currentValue = e.target.value;
            e.target.style.background = this.computeBackground();
            this.bubbleOffset = this.computeBubbleOffset();
            this.$emit('update:modelValue', this.currentValue);
        },
        onMouseOver() {
            this.isMouseOverSlider = true;
            setTimeout(() => {
                if (this.isMouseOverSlider) {
                    this.bubbleOpacity = 1;
                }
            }, 300);
        },
        onMouseLeave() {
            this.bubbleOpacity = 0;
            this.isMouseOverSlider = false;
        },
        computePercent() {
            return ((this.currentValue - this.min) / (this.max - this.min)) * 100;
        },
        computeBubbleOffset() {
            const percent = this.computePercent();
            const offset = percent >= 50 ? -10 * (((percent - 50) * 2) / 100) : 10 * ((100 - percent * 2) / 100);
            return `calc(${percent}% + ${offset}px)`;
        },
        computeBackground() {
            const percent = this.computePercent();
            return `linear-gradient(to right, var(--completed-color) 0%, var(--completed-color) ${percent}%, var(--empty-color) ${percent}%, var(--empty-color) 100%)`;
        }
    }
};
</script>

<style scoped>
.wrapper {
    --completed-color: #22c55e;
    --empty-color: #e5e7eb;
    --thumb-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    font-size: 14px;
    width: 100%;
}

input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
}

input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    margin-top: -5px;
    background: var(--thumb-color);
    border-radius: 50%;
    border: 1px solid var(--empty-color);
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
    transition: box-shadow 0.15s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:hover {
    box-shadow: 0px 1px 4px rgb(0 0 0 / 50%);
}

input[type='range']::-webkit-slider-thumb:hover + .bubble {
    display: inline-block;
}

.bubble {
    position: absolute;
    top: 20px;
    padding: 2px 6px;
    background: var(--empty-color);
    border-radius: 5px;
    transition: opacity 0.2s ease-in-out;
    transform: translateX(-50%);
}

.values {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
}
</style>
