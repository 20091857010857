<template>
    <div class="page-header">
        <AppHeader gap="''">
            <template #titleLeft>
                <nav class="text-gray-700" aria-label="Breadcrumb">
                    <ol class="list-none p-0 inline-flex font-semibold">
                        <li class="flex items-center">
                            <router-link to="/connectors?tab=Sources" class="text-green-400 hover:text-green-500">Connectors</router-link>
                            <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                        </li>
                    </ol>
                </nav>
            </template>
        </AppHeader>
    </div>
    <Tabs :tabs="filteredTabs" :initial-active-tab="selectedTab" @tab-change="setCurrentTab">
        <template #Sources>
            <AppLoader :force-loading="sourcesLoading" :default-height="'70vh'">
                <AddConnectorsList
                    :close-invite-modal="closeInviteModal"
                    :connector-groups="sourcesStore.sourceConnectors"
                    :type="'Source'"
                    :invitation-loading="invitationLoading"
                    @select-connector="(item) => selectItem(item, 'source')"
                    @send-invitation="sendInvitation" />
            </AppLoader>
        </template>

        <template #Destinations>
            <AppLoader :force-loading="destinationsLoading" :default-height="'70vh'">
                <AddConnectorsList
                    :close-invite-modal="closeInviteModal"
                    :connector-groups="destinationsStore.destinationConnectors"
                    :type="'Destination'"
                    :invitation-loading="invitationLoading"
                    @select-connector="(item) => selectItem(item, 'destination')"
                    @send-invitation="sendInvitation" />
            </AppLoader>
        </template>
    </Tabs>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import Tabs from '@/components/global/Tabs.vue';
import AddConnectorsList from '@/components/Connectors/AddConnectorsList.vue';
import { useSourcesStore } from '@/stores/sources.store';
import { useDestinationsStore } from '@/stores/destinations.store';
import AppHeader from '../../components/AppHeader.vue';
import AppLoader from '@/components/AppLoader.vue';
import { ChevronRightIcon, CloudArrowDownIcon, CloudArrowUpIcon } from '@heroicons/vue/24/solid';
import { useUserStore } from '@/stores/user.store';
import { Permissions } from '@/utils/constants';
import { hasAccess } from '@/utils/utils';
import { useAuthStore } from '@/stores/auth.store';

const router = useRouter();
const sourcesStore = useSourcesStore();
const destinationsStore = useDestinationsStore();
const userStore = useUserStore();
const authStore = useAuthStore();

const tabs = [
    {
        name: 'Sources',
        icon: {
            left: true,
            icon: CloudArrowDownIcon,
            size: 'h-6 w-6',
            weight: 'font-semibold'
        },
        accessRules: {
            permissions: {
                values: [Permissions.WRITE_SOURCES]
            }
        }
    },
    {
        name: 'Destinations',
        icon: {
            left: true,
            icon: CloudArrowUpIcon,
            size: 'h-6 w-6',
            weight: 'font-semibold'
        },
        accessRules: {
            permissions: {
                values: [Permissions.WRITE_DESTINATIONS]
            }
        }
    }
];

const selectedTab = ref(router.currentRoute.value.query.tab); // Default to 'source'
const invitationLoading = ref(false);
const sourcesLoading = ref(false);
const destinationsLoading = ref(false);
const closeInviteModal = ref(false);

onMounted(async () => {
    try {
        sourcesLoading.value = true;
        destinationsLoading.value = true;

        if (!selectedTab.value) setCurrentTab(filteredTabs.value[0].name);

        await Promise.all([
            sourcesStore.fetchConnectors().then(() => (sourcesLoading.value = false)),
            destinationsStore.fetchConnectors().then(() => (destinationsLoading.value = false))
        ]);
    } finally {
        sourcesLoading.value = false;
        destinationsLoading.value = false;
    }
});

const filteredTabs = computed(() => {
    return tabs.filter((n) => {
        return hasAccess(authStore.user, n.accessRules || {});
    });
});

const selectItem = (child, type = 'source') => {
    router.push({ name: type === 'source' ? 'new-source' : 'new-destination', params: { connector: child.connector } });
};

const setCurrentTab = (str) => {
    selectedTab.value = str;

    router.push({ query: { tab: selectedTab.value } });
};

const sendInvitation = async (invitationInfo) => {
    invitationLoading.value = true;
    closeInviteModal.value = false;

    try {
        await userStore.inviteColleague(invitationInfo);
        closeInviteModal.value = true;
    } finally {
        invitationLoading.value = false;
    }
};

watch(
    () => router.currentRoute.value.query.tab,
    (newTab) => {
        if (newTab) {
            setCurrentTab(newTab);
        }
    },
    { immediate: true, deep: true }
);
</script>
