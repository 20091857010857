/* eslint-disable indent */
import { defineStore } from 'pinia';
import * as sourcesApi from '../api/sources.api';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useLoaderStore } from './loader.store';
import { useMetricsStore } from './metrics.store';
import { usePipelinesStore } from './pipelines.store';
import { getConnectorDisplayName } from '../utils/constants';
import _ from 'lodash';
import { retryApiCall } from '../utils/utils';

export const useSourcesStore = defineStore('sources', () => {
    const sources = ref(undefined);
    const allSourcesBrief = ref(undefined);
    const total = ref(0);
    const page = ref(0);
    const pageSize = ref(0);
    const lastSearchQuery = ref(null);
    const sourceConnectors = ref({});
    const sourceConfigurations = ref({});
    const router = useRouter();

    const loader = useLoaderStore();

    return {
        // State
        sources,
        // Getters
        all: computed(() => sources.value),
        allBrief: computed(() => allSourcesBrief.value),
        total: computed(() => total.value),
        page: computed(() => page.value),
        pageSize: computed(() => pageSize.value),
        sourceConnectors: computed(() => sourceConnectors.value),
        byId: (id) =>
            computed(() => {
                return _.find(sources.value, { id });
            }),
        connectors: computed(() => {
            var result = [];
            if (sources.value != null && sources.value.length > 0) {
                var connectors = sources.value.map((a) => a.connector);
                if (Array.isArray(connectors)) {
                    connectors.forEach((connector) => {
                        const index = result.findIndex((object) => object.name === connector.charAt(0).toUpperCase() + connector.slice(1));
                        if (index === -1) {
                            const item = {
                                name: connector.charAt(0).toUpperCase() + connector.slice(1),
                                connector: connector
                            };
                            result.push(item);
                        }
                    });
                }
            }
            return result;
        }),
        configurations: computed(() => sourceConfigurations.value),
        // WebSocket
        // Actions
        getById: async function (id, customMessage, forceLoad = false) {
            if (!id) return;

            const found = _.find(sources.value, { id });

            if (found && !forceLoad) {
                return found;
            }

            loader.activateLoading('source.fetch');
            const result = await sourcesApi
                .getSource(id)
                .then(async (data) => {
                    if (!data) {
                        router.push({ name: '404', params: { customMessage } });
                        return;
                    }

                    if (!sources.value) {
                        sources.value = [data];
                    }

                    if (sources.value?.length) {
                        sources.value = sources?.value?.map((source) => {
                            if (source.id === id) {
                                return {
                                    ...source,
                                    ...data
                                };
                            }

                            return source;
                        });
                    }

                    const pipelinesStore = usePipelinesStore();

                    if (pipelinesStore.pipelines?.length) {
                        pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                            if (pipeline.source.id === id) {
                                return {
                                    ...pipeline,
                                    source: {
                                        ...pipeline.source,
                                        ...data
                                    }
                                };
                            }

                            return pipeline;
                        });
                    }

                    return data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('source.fetch');
                });

            return result;
        },
        fetchCreated: async function (pageNumber = page.value, pageSizeNumber = pageSize.value, searchQuery = null) {
            if (
                !searchQuery &&
                searchQuery !== '' &&
                !lastSearchQuery.value &&
                lastSearchQuery.value !== '' &&
                pageNumber === page.value &&
                !_.isEmpty(sources.value) &&
                pageSize.value === pageSizeNumber
            )
                return sources.value;

            loader.activateLoading('sources.fetchCreated');

            return sourcesApi
                .fetchSources(pageNumber + 1, pageSizeNumber, searchQuery)
                .then(async (data) => {
                    if (Array.isArray(data.result)) {
                        data.result.forEach((source) => {
                            source['connectorDisplayName'] = getConnectorDisplayName(source.connector);
                        });
                    }

                    page.value = pageNumber;
                    total.value = data.total;
                    sources.value = data.result;
                    lastSearchQuery.value = searchQuery;
                    pageSize.value = data.page_size;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('sources.fetchCreated');
                });
        },
        fetch: async function (pageNumber, pageSizeNumber) {
            if (pageNumber === page.value && !_.isEmpty(sources.value) && pageSize.value === pageSizeNumber) return sources.value;

            loader.activateLoading('sources.fetch');
            const sourceConnectorsList = Object.values(sourceConnectors.value || {}).flat();

            if (sourceConnectorsList.length <= 0) {
                await sourcesApi
                    .fetchSourceConnectors()
                    .then(async (data) => {
                        sourceConnectors.value = data;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            return sourcesApi
                .fetchSources(pageNumber, pageSizeNumber)
                .then(async (data) => {
                    if (Array.isArray(data.result)) {
                        data.result.forEach((source) => {
                            source['connectorDisplayName'] = getConnectorDisplayName(source.connector);
                        });
                        const ids = data.result.map((source) => source.id);
                        const metricsStore = useMetricsStore();
                        await metricsStore.sources.fetchAllInlineSourceMetrics(ids);
                    }

                    page.value = pageNumber;
                    total.value = data.total;
                    sources.value = data.result;
                    pageSize.value = data.page_size;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('sources.fetch');
                });
        },
        fetchAllBrief: async function (fetchMetrics = true) {
            loader.activateLoading('sources.fetchAllBrief');
            loader.activateLoading('sources.fetch');

            return allSourcesBrief.value && !_.isEmpty(allSourcesBrief.value)
                ? allSourcesBrief.value
                : sourcesApi
                      .fetchAllSourcesBrief()
                      .then(async (data) => {
                          if (Array.isArray(data)) {
                              data.forEach((source) => {
                                  source['connectorDisplayName'] = getConnectorDisplayName(source.connector);
                              });

                              if (fetchMetrics) {
                                  const ids = data.map((source) => source.id);
                                  const metricsStore = useMetricsStore();
                                  await metricsStore.sources.fetchAllInlineSourceMetrics(ids);
                              }
                          }

                          allSourcesBrief.value = data;

                          return data;
                      })
                      .catch((err) => {
                          console.error(err);
                      })
                      .finally(() => {
                          loader.deactivateLoading('sources.fetchAllBrief');
                          loader.deactivateLoading('sources.fetch');
                      });
        },
        fetchConnectors: async function () {
            loader.activateLoading('sources.fetchConnectors');
            let sourceConnectorsList = Object.values(sourceConnectors.value || {}).flat();

            return sourceConnectorsList.length > 0
                ? sourceConnectors.value
                : sourcesApi
                      .fetchSourceConnectors()
                      .then(async (data) => {
                          sourceConnectors.value = data;

                          if (allSourcesBrief.value && !_.isEmpty(allSourcesBrief.value)) {
                              allSourcesBrief.value = allSourcesBrief.value.map((source) => {
                                  source['connectorDisplayName'] = getConnectorDisplayName(source.connector);

                                  return source;
                              });
                          }

                          if (!_.isEmpty(sources.value)) {
                              sources.value = sources.value.map((source) => {
                                  source['connectorDisplayName'] = getConnectorDisplayName(source.connector);

                                  return source;
                              });
                          }

                          return data;
                      })
                      .catch((err) => {
                          console.error(err);
                      })
                      .finally(() => {
                          loader.deactivateLoading('sources.fetchConnectors');
                      });
        },
        fetchConfiguration: async function (connector) {
            loader.activateLoading('sources.fetchConfiguration');
            if (sourceConfigurations.value[connector]) return sourceConfigurations.value[connector];

            return sourcesApi
                .fetchSourceConfiguration(connector)
                .then((data) => {
                    if (Array.isArray(data)) {
                        data.forEach((obj) => {
                            obj.display_in_ui = true;
                        });
                    }

                    sourceConfigurations.value[connector] = data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('sources.fetchConfiguration');
                });
        },
        delete: async function (id) {
            loader.activateLoading(`sources.delete`);
            return sourcesApi
                .deleteSourceConnection(id)
                .then(() => {
                    sources.value = sources.value?.filter((source) => source.id !== id);
                    allSourcesBrief.value = allSourcesBrief?.value?.filter((source) => source.id !== id);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`sources.delete`);
                });
        },
        pause: async function (id, hideToast = false) {
            loader.activateLoading(`sources.pause.${id}`);
            return sourcesApi
                .pauseSourceConnection(id, hideToast)
                .then(() => {
                    sources.value = sources?.value?.map((source) => {
                        if (source.id === id) {
                            const metricsStore = useMetricsStore();
                            metricsStore.sources.updateStatus(id, 'Paused');

                            return {
                                ...source,
                                connector_status: 'Paused'
                            };
                        }

                        return source;
                    });

                    const pipelinesStore = usePipelinesStore();

                    pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                        if (pipeline.source.id === id) {
                            return {
                                ...pipeline,
                                source: {
                                    ...pipeline.source,
                                    connector_status: 'Paused'
                                }
                            };
                        }

                        return pipeline;
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`sources.pause.${id}`);
                });
        },
        stop: async function (id, hideToast = false) {
            loader.activateLoading(`sources.stop.${id}`);
            return sourcesApi
                .stopSourceConnection(id, hideToast)
                .then(() => {
                    sources.value = sources?.value?.map((source) => {
                        if (source.id === id) {
                            const metricsStore = useMetricsStore();
                            metricsStore.sources.updateStatus(id, 'Stopped');

                            return {
                                ...source,
                                connector_status: 'Stopped'
                            };
                        }

                        return source;
                    });

                    const pipelinesStore = usePipelinesStore();

                    pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                        if (pipeline.source.id === id) {
                            return {
                                ...pipeline,
                                source: {
                                    ...pipeline.source,
                                    connector_status: 'Stopped'
                                }
                            };
                        }

                        return pipeline;
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`sources.stop.${id}`);
                });
        },
        resume: async function (id, hideToast = false) {
            loader.activateLoading(`sources.resume.${id}`);
            return sourcesApi
                .resumeSourceConnection(id, hideToast)
                .then(() => {
                    sources.value = sources?.value?.map((source) => {
                        if (source.id === id) {
                            const metricsStore = useMetricsStore();
                            metricsStore.sources.updateStatus(id, 'Active');
                            return {
                                ...source,
                                connector_status: 'Active'
                            };
                        }

                        return source;
                    });

                    const pipelinesStore = usePipelinesStore();

                    pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                        if (pipeline.source.id === id) {
                            return {
                                ...pipeline,
                                source: {
                                    ...pipeline.source,
                                    connector_status: 'Active'
                                }
                            };
                        }

                        return pipeline;
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`sources.resume.${id}`);
                });
        },
        restart: async function (id) {
            loader.activateLoading(`sources.restart.${id}`);
            return sourcesApi
                .restartSourceConnection(id)
                .then(() => {})
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`sources.restart.${id}`);
                });
        },
        create: async function (connection) {
            loader.activateLoading('sources.create');
            return sourcesApi
                .createSource(connection)
                .then((data) => {
                    data = {
                        ...data,
                        connectorDisplayName: getConnectorDisplayName(data.connector)
                    };

                    if (!sources.value) {
                        sources.value = [];
                    }

                    sources.value.unshift(data);
                    if (allSourcesBrief.value?.length) allSourcesBrief.value.unshift(data);

                    if (sources.value?.length > pageSize.value) {
                        sources.value = sources.value.slice(0, pageSize.value);
                    }

                    window.analytics?.track('Source Created', {
                        date: new Date(),
                        source: data.connector,
                        id: data.id,
                        name: data.name
                    });

                    const metricsStore = useMetricsStore();
                    metricsStore.sources.fetch(data.id);
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('sources.create');
                });
        },
        edit: async function (connection, id) {
            loader.activateLoading('sources.edit');
            return retryApiCall(
                (retriesLeft = 5) =>
                    sourcesApi.editSource(connection, id, retriesLeft > 0).then(async (data) => {
                        if (!sources.value) {
                            sources.value = [
                                {
                                    ...connection,
                                    ...data
                                }
                            ];
                        }

                        if (sources.value?.length) {
                            sources.value = sources?.value?.map((source) => {
                                if (source.id === id) {
                                    return {
                                        ...source,
                                        ...connection,
                                        ...data
                                    };
                                }

                                return source;
                            });
                        }

                        if (allSourcesBrief.value?.length) {
                            allSourcesBrief.value = allSourcesBrief?.value?.map((source) => {
                                if (source.id === id) {
                                    return {
                                        ...source,
                                        ...connection,
                                        ...data
                                    };
                                }

                                return source;
                            });
                        }

                        const pipelinesStore = usePipelinesStore();

                        if (pipelinesStore.pipelines?.length) {
                            pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                                if (pipeline.source.id === id) {
                                    return {
                                        ...pipeline,
                                        source: {
                                            ...pipeline.source,
                                            ...connection,
                                            ...data
                                        }
                                    };
                                }

                                return pipeline;
                            });
                        }

                        return data;
                    }),
                408,
                5,
                10000
            )
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('sources.edit');
                });
        },
        snapshot: async function (connection) {
            return sourcesApi
                .snapshotSource(connection)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    console.error(err);
                    throw '* Oops, something has gone wrong.';
                })
                .finally(() => {});
        },
        cancelSnapshot: async function (connection) {
            return sourcesApi
                .cancelSourceSnapshot(connection)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    console.error(err);
                    throw '* Oops, something has gone wrong.';
                })
                .finally(() => {});
        }
    };
});
