<template>
    <div>
        <span class="text-red-600"> {{ introduction }} </span>
        <button aria-label="button" class="ml-1 text-green-600 underline" @click="onViewDetailsClick">View details</button>
    </div>
</template>

<script>
import useErrorDialog from '../composables/useErrorDialog.js';
import SentryService from '../services/sentry.service';
import _ from 'lodash';

export default {
    name: 'ErrorMessage',
    props: {
        error: {
            required: true,
            default: null
        },
        toSentry: {
            type: Boolean,
            default: false
        },
        context: {
            type: String,
            default: ''
        },
        introduction: {
            type: String,
            default: 'An error occured.'
        }
    },
    mounted() {
        const errorExists = this.error && (this.error.response?.data?.error || this.error.message || _.isString(this.error));

        if (this.toSentry && errorExists) {
            SentryService.notify(new Error(_.isString(this.error) ? this.error : this.error.response?.data?.error || this.error.message), {
                severity: 'error',
                context: this.context
            });
        }
    },
    methods: {
        onViewDetailsClick() {
            useErrorDialog({ errors: [_.isString(this.error) ? this.error : this.error.response?.data?.error || this.error.message] });
        }
    }
};
</script>

<style scoped></style>
