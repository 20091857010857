<script>
import { reactive, toRefs, ref, onMounted, watch } from 'vue';
import { PlusIcon, ArrowUpOnSquareStackIcon } from '@heroicons/vue/24/outline';
import TreeNode from '../components/TreeNode.vue';
import Papa from 'papaparse';
import TreeCheckbox from './TreeCheckbox.vue';

export default {
    components: {
        PlusIcon,
        ArrowUpOnSquareStackIcon,
        TreeCheckbox,
        TreeNode
    },
    props: {
        treeNodes: {
            type: Array,
            required: true
        },
        treeLevels: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        displayOnlySelected: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        },
        disableCreateAndEdit: {
            type: Boolean,
            default: false
        },
        connectorType: {
            type: String,
            required: false
        },
        background: {
            type: String,
            default: 'white'
        },
        addDefaultEmptyNode: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            treeData: props.treeData,
            selectAll: false
        });

        function canSaveDuplicate() {
            const hasDuplicates = checkDuplicates(props.treeNodes);
            const hasEmptyEntries = checkEmptyNodes(props.treeNodes);
            const canSave = !hasEmptyEntries && !hasDuplicates;
            emit('can-save-duplicate', canSave);
        }

        function checkDuplicates(nodes) {
            const names = nodes.map((node) => node.name);
            const uniqueNames = new Set(names);

            if (names.length !== uniqueNames.size) return true;

            for (const node of nodes) {
                if (node.children && node.children.length) {
                    if (checkDuplicates(node.children)) return true;
                }
            }

            return false;
        }

        function checkEmptyNodes(nodes) {
            for (let i = 0; i < nodes.length; i++) {
                if (!nodes[i].name || nodes[i].name.trim() === '') {
                    return true;
                }
                if (nodes[i].children && nodes[i].children.length > 0) {
                    if (checkEmptyNodes(nodes[i].children)) {
                        return true;
                    }
                }
            }

            return false;
        }

        function addNode(nodes, nodeLevel, schemaObject, customName = null, fromCSVFile = false) {
            const defaultPlaceholder = `Enter ${nodeLevel} name`;
            let placeholderName = customName ?? defaultPlaceholder;

            let name = fromCSVFile ? customName : '';

            nodes.push({
                name: name,
                isSelected: true,
                collapsed: true,
                schemaLevel: nodeLevel,
                schemaObject: schemaObject,
                children: [],
                placeholder: placeholderName
            });

            canSaveDuplicate();
        }

        function removeNode(nodeList, nodeIndex) {
            nodeList.splice(nodeIndex, 1);
        }

        // will select all nodes and children of nodes from all levels
        function selectAllNodes() {
            selectAllNodesRecursive(props.treeNodes, state.selectAll);
        }

        function selectAllNodesRecursive(nodes, select) {
            for (let i = 0; i < nodes.length; i++) {
                nodes[i].isSelected = select;
                if (nodes[i].children && nodes[i].children.length > 0) {
                    selectAllNodesRecursive(nodes[i].children, select);
                }
            }
        }

        const fileInput = ref(null);

        function checkAllSelected(nodes) {
            let allSelected = true;
            for (let i = 0; i < nodes.length; i++) {
                if (!nodes[i].isSelected) {
                    allSelected = false;
                    break;
                }

                if (nodes[i].children && nodes[i].children.length > 0) {
                    allSelected = checkAllSelected(nodes[i].children);
                    if (!allSelected) break;
                }
            }

            return allSelected;
        }

        // update selectAllState when nodes are updated individually
        watch(
            () => props.treeNodes,
            (newValue) => {
                // check recursively if all nodes are selected, including children
                state.selectAll = checkAllSelected(newValue);
            },
            { deep: true }
        );

        onMounted(() => {
            if (fileInput.value) {
                fileInput.value.addEventListener('change', async (event) => {
                    const parsedData = await loadCsvFile(event);
                    if (parsedData) {
                        parsedData.forEach((element) => {
                            addNode(props.treeNodes, props.treeLevels[0].schemaLevel, props.treeLevels[0].schemaObject, element, true);
                        });
                    }
                });
            }

            if (props.addDefaultEmptyNode) {
                addNode(props.treeNodes, props.treeLevels[0].schemaLevel, props.treeLevels[0].schemaObject);
            }

            state.selectAll = checkAllSelected(props.treeNodes);
        });

        const openFileUpload = () => {
            if (fileInput.value) {
                fileInput.value.value = '';
                fileInput.value.click();
            }
        };

        const parseCsv = (csv) => {
            return new Promise((resolve) => {
                Papa.parse(csv, {
                    delimiter: ',',
                    skipEmptyLines: true,
                    dynamicTyping: true,
                    transform: (value) =>
                        value
                            .replace(/(\s|^)\./g, '')
                            .replace(/\.$/, '')
                            .replace(/""/g, '"')
                            .trim(),
                    error: (error) => {
                        alert('Failed to parse CSV file.');
                        console.error('Failed to parse CSV file:', error.message);
                    },
                    complete: (results) => {
                        const flattenedData = results.data.flat();
                        const filteredData = flattenedData.filter((value) => value !== null && value !== '');
                        resolve(filteredData);
                    }
                });
            });
        };

        const loadCsvFile = async (event) => {
            const file = event.target.files[0];
            if (!file) return null;

            if (!file.type.includes('csv') && !file.name.toLowerCase().endsWith('.csv')) {
                alert('Please upload a valid CSV file.');
                console.error('Please upload a valid CSV file.');
                return;
            }

            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onload = async (e) => {
                    const csv = e.target.result;
                    const parsedData = await parseCsv(csv);
                    resolve(parsedData);
                };
                reader.readAsText(file);
            });
        };

        return {
            ...toRefs(state),
            addNode,
            // canAdd,
            removeNode,
            selectAllNodes,
            canSaveDuplicate,
            checkDuplicates,
            fileInput,
            openFileUpload,
            loadCsvFile,
            emit
        };
    },
    computed: {
        itemName() {
            return this.treeLevels[0].schemaLevel;
        }
    }
};
</script>

<template>
    <div v-if="treeNodes">
        <!-- select all component -->
        <div class="flex flex-row items-center gap-2 py-2">
            <TreeCheckbox
                :checked="selectAll"
                @change="
                    selectAll = !selectAll;
                    selectAllNodes();
                " />
            <label>Select all {{ itemName?.toLowerCase()?.endsWith('s') ? itemName : itemName + 's' }}</label>
        </div>

        <div class="parent-nodes flex flex-row items-center gap-2 p-2" :class="{ 'has-parent-border': !!treeNodes.length }" :style="{ background }">
            <div class="text-sm font-medium text-gray-700">
                {{ treeNodes.length }}
                {{ treeNodes.length != 1 ? treeLevels[0].schemaLevel + 's' : treeLevels[0].schemaLevel }}
            </div>

            <button
                v-if="editMode && !disableCreateAndEdit"
                aria-label="button"
                class="flex items-center px-1 py-1 border border-green-500 text-green-500 hover:bg-green-400 hover:text-white text-xs font-medium rounded"
                @click.prevent="addNode(treeNodes, treeLevels[0].schemaLevel, treeLevels[0].schemaObject)">
                <PlusIcon class="h-4 w-4 mr-1" />
                <span>Add {{ itemName }}</span>
            </button>

            <button
                v-if="editMode && !disableCreateAndEdit"
                aria-label="button"
                class="flex items-center px-1 py-1 border border-green-500 text-green-500 hover:bg-green-400 hover:text-white text-xs font-medium rounded"
                @click="openFileUpload()">
                <ArrowUpOnSquareStackIcon class="h-4 w-4 mr-1" />
                <span>Use .CSV</span>
                <input ref="fileInput" aria-label="input" type="file" accept=".csv" style="display: none" @change="loadCsvFile" />
            </button>
        </div>
        <div v-if="treeNodes.length > 0" class="overflow-y-auto tree-view">
            <div v-for="(node, nodeIndex) in treeNodes" :key="nodeIndex" class="flex flex-col">
                <tree-node
                    :nodes="treeNodes"
                    :readonly="readonly"
                    :display-only-selected="displayOnlySelected"
                    :edit-mode="editMode"
                    :disable-create-and-edit="disableCreateAndEdit"
                    :level="0"
                    :node="node"
                    :node-index="nodeIndex"
                    :is-last="nodeIndex === treeNodes.length - 1"
                    :tree-levels="treeLevels"
                    :background="background"
                    @duplicate="canSaveDuplicate()" />
            </div>
        </div>
    </div>
</template>

<style>
.tree-view {
    max-height: 50vh; /* Default height for very small devices */
}

@media screen and (min-height: 480px) {
    .tree-view {
        max-height: 55vh; /* Small devices (landscape phones, 480px and up) */
    }
}

@media screen and (min-height: 768px) {
    .tree-view {
        max-height: 62vh; /* Medium devices (tablets, 768px and up) */
    }
}

@media screen and (min-height: 992px) {
    .tree-view {
        max-height: 64vh; /* Large devices (laptops/desktops, 992px and up) */
    }
}

@media screen and (min-height: 1080px) {
    .tree-view {
        max-height: 70vh; /* 1K - Extra large devices (large laptops/desktops, 1080px and up) */
    }
}

@media screen and (min-height: 1440px) {
    .tree-view {
        max-height: 77vh; /* 2K */
    }
}

@media screen and (min-height: 1620px) {
    .tree-view {
        max-height: 78vh; /* 3K */
    }
}

@media screen and (min-height: 2160px) {
    .tree-view {
        max-height: 82vh; /* 4K */
    }
}

.has-parent-border {
    border-bottom: 1px solid #c0c0c0;
    width: 80%;
}

.tooltip {
    position: absolute; /* or 'fixed' */
    z-index: 9999; /* High z-index */
    /* Other styling */
}
</style>
