import api from '../services/axios.service';
import { constructQueryParams } from '../utils/utils';

export async function fetchSourceConnectors(from = 0, size = 1) {
    return api.get(`list_sources?from=${from}&size=${size}`);
}

export async function fetchSources(page = 0, pageSize = 10, searchQuery = '') {
    const queryParams = constructQueryParams({ page, page_size: pageSize, partial_name: searchQuery });

    return api.get(`sources?${queryParams}`);
}

export async function fetchAllSourcesBrief() {
    return api.get(`sources/all`);
}

export async function fetchSourceConfiguration(connector) {
    return api.get(`source_configuration?connector=${connector}`);
}

export async function deleteSourceConnection(id) {
    return api.delete(`sources?id=${id}`);
}

export async function pauseSourceConnection(id, hideToast = false) {
    return api.put(`pause_source?id=${id}`, {}, {}, hideToast);
}

export async function stopSourceConnection(id, hideToast = false) {
    return api.put(`stop_source?id=${id}`, {}, {}, hideToast);
}

export async function resumeSourceConnection(id, hideToast = false) {
    return api.put(`resume_source?id=${id}`, {}, {}, hideToast);
}

export async function restartSourceConnection(id) {
    return api.post(`restart_source?id=${id}`);
}

export async function createSource(connection) {
    return api.post(`sources`, connection);
}

export async function getSource(id) {
    const response = await api.get(`sources?id=${id}`);

    return response?.result?.[0];
}

export async function editSource(connection, id, hideToast = false) {
    return api.put(`sources?id=${id}`, connection, {}, hideToast);
}

export async function snapshotSource(connection) {
    return api.post(`execute_incremental_snapshot`, connection);
}

export async function cancelSourceSnapshot(connection) {
    return api.post(`stop_incremental_snapshot`, connection);
}
