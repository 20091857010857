<script>
import { useSourcesStore } from '../../stores/sources.store';
import { useDestinationsStore } from '../../stores/destinations.store';
import { useMetricsStore } from '../../stores/metrics.store';
import { useRouter } from 'vue-router';
import AppLoader from '../../components/AppLoader.vue';
import { ClockIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, ChevronUpIcon, ChevronUpDownIcon, FunnelIcon, PlusIcon } from '@heroicons/vue/20/solid';
import _ from 'lodash';
import { getStatusColor, formatter, getMetricFormattingInfo, userHasAccess } from '../../utils/utils';
import useConfirmBeforeAction from '../../composables/useConfirmBeforeAction.js';
import TableActionMenu from '../../components/TableActionMenu.vue';
import Pagination from '../../components/Pagination.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import useConfirmBeforeWarning from '../../composables/useConfirmBeforeWarning.js';
import { usePipelinesStore } from '../../stores/pipelines.store';
import useErrorDialog from '../../composables/useErrorDialog.js';
import SearchInput from '../../components/global/SearchInput.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'ProfileMenu',
    components: {
        ChevronDownIcon,
        ChevronUpIcon,
        ChevronUpDownIcon,
        InformationCircleIcon,
        FunnelIcon,
        ClockIcon,
        PlusIcon,
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        AppLoader,
        TableActionMenu,
        Pagination,
        DropdownFilters,
        SearchInput
    },
    data() {
        return {
            sourcesStore: useSourcesStore(),
            destinationsStore: useDestinationsStore(),
            router: useRouter(),
            authStore: useAuthStore(),
            metricsStore: useMetricsStore(),
            searchQuery: ref(null),
            sortColumn: ref('name'),
            sortDirection: ref(false),
            triggerFilterSelection: ref(false),
            generalLoading: ref(false),
            metricsLoading: ref(false),
            actionLoading: ref({}),
            pipelinesStore: usePipelinesStore(),
            sourcesPage: 0,
            sourcesPageSize: 0,
            destinationsPage: 0,
            destinationsPageSize: 0,
            checkedAsyncFilters: [],
            asyncFilters: [
                {
                    title: 'Sources per page',
                    name: 'count',
                    defaultIndex: 0,
                    options: [
                        { value: 10, displayValue: '10/page' },
                        { value: 20, displayValue: '20/page' },
                        { value: 50, displayValue: '50/page' }
                    ]
                }
            ],
            filters: [
                {
                    placeholder: 'All statuses',
                    text: '',
                    values: [null, 'Active', 'Broken', 'Delayed', 'Incomplete', 'Paused']
                },
                {
                    placeholder: 'All sources',
                    text: '',
                    values: []
                }
            ],
            selectedFilters: [[], []]
        };
    },
    computed: {
        allLatestMetrics() {
            return this.metricsStore.sources.allLatest;
        },
        searchedSources() {
            let result = this.applyFilters(this.sourcesStore.all);
            result = this.applySorting(result);
            return result;
        },
        possibleSources() {
            let result = this.searchedSources.map((a) => a.connectorDisplayName).filter((value, index, self) => self.indexOf(value) === index);
            result.splice(0, 0, null);
            return result;
        }
    },
    watch: {
        generalLoading: {
            handler: function (newValue) {
                if (newValue === true && this.$refs.scrollableContainer) {
                    this.$refs.scrollableContainer.scrollTop = 0;
                }
            }
        },
        searchQuery: {
            handler: _.debounce(async function () {
                await this.loadSources();
            }, 700)
        }
    },
    mounted() {
        this.destinationsPage = this.destinationsStore.page || 0;
        this.destinationsPageSize = this.destinationsStore.pageSize || 10;

        this.sourcesPage = this.sourcesStore.page || 0;
        this.sourcesPageSize = this.sourcesStore.pageSize || 10;

        this.loadSources();
        this.loadDestinations();
        this.initializeAsyncFilters();
    },
    methods: {
        userHasAccess,
        getStatusColor,
        getStatus(source) {
            return this.allLatestMetrics[source.id]?.inline?.connector_status ?? source?.connector_status ?? 'Unknown';
        },
        onClone(source) {
            this.router.push({
                name: 'new-source',
                params: { connector: source.connector },
                query: { cloneConfig: JSON.stringify(source.config) }
            });
        },
        // eslint-disable-next-line no-unused-vars
        async onDelete(entity, name, id, index) {
            try {
                this.actionLoading[id] = true;

                if (!this.pipelinesStore.allBrief || _.isEmpty(this.pipelinesStore.allBrief)) {
                    await this.pipelinesStore.fetchAllBrief(false);
                }

                const hasActivePipeline = this.pipelinesStore.allBrief.some((p) => p.source.id === id);

                if (hasActivePipeline) {
                    useConfirmBeforeWarning(
                        async () => {
                            this.actionLoading[id] = false;
                        },
                        { entity: entity, name: name },
                        () => {
                            this.actionLoading[id] = false;
                        }
                    );
                } else {
                    useConfirmBeforeAction(
                        'delete',
                        async () => {
                            try {
                                await this.sourcesStore.delete(id);
                            } catch (e) {
                                console.log(e);
                            } finally {
                                this.actionLoading[id] = false;
                            }
                        },
                        { entity: entity, name: name },
                        () => {
                            this.actionLoading[id] = false;
                        }
                    );
                }
            } catch {
                this.actionLoading[id] = false;
            }
        },
        async onStop(id) {
            try {
                this.actionLoading[id] = true;
                await this.sourcesStore.stop(id);
            } finally {
                this.actionLoading[id] = false;
            }
        },
        async onResume(id) {
            try {
                this.actionLoading[id] = true;
                await this.sourcesStore.resume(id);
            } finally {
                this.actionLoading[id] = false;
            }
        },
        async onRestart(id) {
            try {
                this.actionLoading[id] = true;
                await this.sourcesStore.restart(id);
                await this.sourcesStore.getById(id);
            } catch {
                this.actionLoading[id] = false;
            }
        },
        onViewLogs(sourceId) {
            const route = this.router.resolve({ name: 'logs', query: { source_id: sourceId } });
            window.open(route.href, '_blank');
        },
        getMetricFormattingInfo,
        formatter,
        getConnectorInfo(connector) {
            return Object.values(this.sourcesStore.sourceConnectors || {})
                .flat()
                .find((c) => c.connector === connector);
        },
        getFormattedValue(connectorToSearch, metricKey, dashOnRule = null) {
            const metricValue = this.allLatestMetrics[connectorToSearch.id]?.inline?.[metricKey];

            const connectorInfo = this.getConnectorInfo(connectorToSearch.connector);
            if (!connectorInfo) return { display: '-' };

            const metricFormattingInfo = this.getMetricFormattingInfo(metricKey, connectorInfo.metrics);

            const formatterParams = [metricValue, metricFormattingInfo || {}];

            if (dashOnRule || dashOnRule === false) {
                formatterParams.push(dashOnRule);
            }

            return this.formatter(...formatterParams);
        },
        onCreateSource() {
            return this.router.push({ name: 'add-connectors', query: { tab: 'Sources' } });
        },
        onSourceSelected(id) {
            return this.router.push(this.selectedSourceRouterPath(id));
        },
        selectedSourceRouterPath(id) {
            return { name: 'source-details', params: { id } };
        },
        async loadSources() {
            try {
                this.generalLoading = true;
                if (this.searchQuery || this.searchQuery === '') {
                    this.sourcesPage = 0;
                }

                await this.sourcesStore.fetchCreated(this.sourcesPage, this.sourcesPageSize, this.searchQuery);
                this.generalLoading = false;

                this.metricsLoading = true;
                await Promise.all([
                    this.metricsStore.sources.fetchAllInlineSourceMetrics(this.sourcesStore.all?.map((source) => source.id)),
                    this.sourcesStore.fetchConnectors()
                ]);
            } finally {
                this.generalLoading = false;
                this.metricsLoading = false;
            }
        },
        loadDestinations() {
            try {
                this.destinationsStore.fetchCreated(this.destinationsPage, this.destinationsPageSize).then(async () => {
                    await Promise.all([
                        this.metricsStore.destinations.fetchAllInlineDestinationMetrics(this.destinationsStore.all?.map((dest) => dest.id)),
                        this.destinationsStore.fetchConnectors()
                    ]);
                });
            } catch (e) {
                console.log(e);
            }
        },
        swapSorting(column, direction) {
            this.sortColumn = column;
            this.sortDirection = direction;
        },
        applySorting(result) {
            return _.orderBy(result, [this.sortColumn], [this.sortDirection ? 'desc' : 'asc']);
        },
        applyFilters(result) {
            return (
                result?.filter(
                    (x) =>
                        (this.selectedFilters[0].length <= 0 || this.selectedFilters[0].includes(x.connector_status)) &&
                        (this.selectedFilters[1].length <= 0 || this.selectedFilters[1].includes(x.connectorDisplayName))
                ) || []
            );
        },
        selectFilter(filter, possibleIndex, filterIndex) {
            if (possibleIndex !== 0) {
                const index = this.selectedFilters[filterIndex].indexOf(filter);
                if (index === -1) {
                    this.selectedFilters[filterIndex].push(filter);
                } else {
                    this.selectedFilters[filterIndex].splice(index, 1);
                }
            } else {
                this.selectedFilters[filterIndex] = [];
            }
            this.triggerFilterSelection = !this.triggerFilterSelection;
        },
        initializeAsyncFilters() {
            this.asyncFilters.forEach((filter) => {
                if (filter.name === 'count') {
                    this.sourcesPageSize = filter.options[filter.defaultIndex].value;
                }
                this.checkedAsyncFilters[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
            });
        },
        onAsyncFilterToggle({ filter, filterOption }) {
            if (filter.name === 'count') {
                this.sourcesPageSize = filterOption.value;
            }
            this.sourcesPage = 0;
            this.loadSources();
        },
        onPageChange(newPage) {
            this.sourcesPage = newPage;
            this.loadSources();
        },
        onStatusClick(source) {
            if (this.getStatus(source) === 'Broken' && source.task_statuses) {
                const errors = [...new Set(Object.values(source.task_statuses).map((task) => task.trace))];
                useErrorDialog({
                    errors,
                    width: 65
                });
            }
        }
    }
};
</script>

<template>
    <div class="table-page-container mt-5 mb-10">
        <div class="table-page-body">
            <div class="page-options flex justify-between items-start">
                <div class="flex gap-2 w-1/2">
                    <button
                        v-can-access.permissions="[$Permissions.WRITE_SOURCES]"
                        aria-label="button"
                        class="btn btn-primary rounded-lg"
                        @click="onCreateSource">
                        <button
                            aria-label="button"
                            type="button"
                            class="inline-flex items-center rounded-lg border border-transparent bg-green-400 p-0.5 text-white shadow-sm hover:bg-green-400 mr-1">
                            <PlusIcon class="h-5 w-5" />
                        </button>
                        <span class="mx-1 align-super font-medium text-sm">Add</span>
                    </button>

                    <SearchInput
                        :loading="generalLoading"
                        placeholder="Search by name..."
                        :custom-class="'w-2/3'"
                        @search="(input) => (searchQuery = input)" />
                </div>
                <div class="page-filters flex gap-2 justify-end w-1/2">
                    <Menu v-for="(filter, index) in filters" :key="index" as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton
                                :class="[
                                    generalLoading ? 'bg-gray-100 opacity-[0.7]' : 'bg-white hover:bg-gray-50',
                                    'inline-flex w-full justify-center items-center px-3 rounded-md border border-gray-300 text-sm font-medium text-gray-700 shadow-sm'
                                ]"
                                style="min-height: 40.5px !important"
                                :disabled="generalLoading">
                                <FunnelIcon class="h-4 w-4 self-center mr-1" />
                                <div v-if="selectedFilters[index].length > 0">
                                    <span
                                        v-for="(selectedFilter, index) in selectedFilters[index]"
                                        :key="index"
                                        class="inline-flex items-center rounded-full bg-gray-100 text-xs font-medium text-gray-800 m-1">
                                        <div class="flex">
                                            <small
                                                v-if="selectedFilter"
                                                class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                :style="`background: ${getStatusColor(selectedFilter)}`"
                                                >{{ selectedFilter }}</small
                                            >
                                            <span v-if="!selectedFilter">{{ selectedFilter }} {{ filter.text }}</span>
                                        </div>
                                    </span>
                                </div>
                                <div v-if="selectedFilters[index].length <= 0">
                                    {{ filter.placeholder }}
                                </div>
                                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" />
                            </MenuButton>
                        </div>
                        <transition
                            enter-se-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                            <MenuItems
                                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div v-if="index == 0" class="py-1">
                                    <MenuItem v-for="(possibleFilter, possibleIndex) in filter.values" :key="possibleIndex" v-slot="{}">
                                        <a
                                            href="#"
                                            :class="[
                                                selectedFilters[index].includes(possibleFilter) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            ]"
                                            @click="selectFilter(possibleFilter, possibleIndex, index)">
                                            <div class="flex">
                                                <small
                                                    v-if="possibleFilter"
                                                    class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                    :style="`background: ${getStatusColor(possibleFilter)}`"
                                                    >{{ possibleFilter }}</small
                                                >
                                                <span v-if="!possibleFilter">{{ possibleFilter ? possibleFilter : filter.placeholder }}</span>
                                            </div>
                                        </a>
                                    </MenuItem>
                                </div>

                                <div v-if="index == 1" class="py-1">
                                    <MenuItem v-for="(possibleFilter, possibleIndex) in possibleSources" :key="possibleIndex" v-slot="{}">
                                        <a
                                            href="#"
                                            :class="[
                                                selectedFilters[index].includes(possibleFilter) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            ]"
                                            @click="selectFilter(possibleFilter, possibleIndex, index)">
                                            <div class="flex">
                                                <small
                                                    v-if="possibleFilter"
                                                    class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                    :style="`background: ${getStatusColor(possibleFilter)}`"
                                                    >{{ possibleFilter }}</small
                                                >
                                                <span v-if="!possibleFilter">{{ possibleFilter ? possibleFilter : filter.placeholder }}</span>
                                            </div>
                                        </a>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>

                    <DropdownFilters
                        v-model="checkedAsyncFilters"
                        :filters="asyncFilters"
                        :disabled="generalLoading"
                        @on-filter-toggle="onAsyncFilterToggle" />
                </div>
            </div>
            <section class="paginated-section mt-2">
                <AppLoader
                    :force-loading="generalLoading"
                    default-height="20vh"
                    :default-width="'100%'"
                    background="#f9fafb"
                    :default-class="'shadow-md rounded-md'">
                    <div class="relative w-full shadow-md" :class="!searchedSources?.length || searchedSources?.length > 4 ? 'overflow-auto' : ''">
                        <table class="min-w-full divide-y divide-gray-300 border" aria-label="sources table">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Name</span>
                                            <span
                                                class="cursor-pointer ml-2 inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('name', !sortDirection)">
                                                <ChevronDownIcon v-if="sortColumn == 'name' && sortDirection == true" class="h-4 w-4" />
                                                <ChevronUpIcon v-if="sortColumn == 'name' && sortDirection == false" class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'name'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Type</span>
                                            <span
                                                class="cursor-pointer ml-2 inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('connector', !sortDirection)">
                                                <ChevronDownIcon v-if="sortColumn == 'connector' && sortDirection == true" class="h-4 w-4" />
                                                <ChevronUpIcon v-if="sortColumn == 'connector' && sortDirection == false" class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'connector'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider min-w-[220px]">
                                        <div class="flex">
                                            <span>Status/State</span>
                                        </div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">Topics</div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">Last Event</div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">Latency</div>
                                    </th>
                                    <th class="text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <template v-if="!generalLoading">
                                    <tr
                                        v-for="(source, sourceIndex) in searchedSources"
                                        :key="sourceIndex"
                                        class="transition duration-300 ease-in-out hover:bg-gray-50">
                                        <td class="px-6 py-4 whitespace-nowrap cursor-pointer font-medium text-green-500 no-underline">
                                            <div class="flex items-center mr-4 gap-1">
                                                <div class="my-auto whitespace-normal break-words max-w-md">
                                                    <router-link
                                                        :to="selectedSourceRouterPath(source.id)"
                                                        class="font-medium text-cyan-950 no-underline hover:text-cyan-800">
                                                        {{ source.name }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex flex-nowrap justify-content-start mr-4">
                                                <img width="20" height="20" class="mr-1" :src="`/icons/${source.connector}.svg`" alt="connector" />
                                                <div class="flex flex-row items-center align-center">
                                                    <div class="my-auto">
                                                        {{ source.connectorDisplayName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div
                                                    v-if="
                                                        allLatestMetrics[source.id] &&
                                                        Object.keys(allLatestMetrics[source.id]?.inline || {}).length > 0
                                                    "
                                                    class="flex flex-row items-center whitespace-nowrap">
                                                    <small
                                                        :class="[
                                                            'inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow',
                                                            getStatus(source) === 'Broken' ? 'cursor-pointer' : ''
                                                        ]"
                                                        :style="`background: ${getStatusColor(getStatus(source))}`"
                                                        @click="() => onStatusClick(source)"
                                                        >{{ getStatus(source) }}

                                                        <InformationCircleIcon v-if="getStatus(source) === 'Broken'" class="h-4 w-4 ml-1" />
                                                    </small>
                                                    <div
                                                        v-if="
                                                            allLatestMetrics[source.id]?.inline?.latency < 60000 &&
                                                            !allLatestMetrics[source.id]?.inline?.state?.toLowerCase().includes('idle')
                                                        "
                                                        class="mx-3 inline-flex"
                                                        role="status"
                                                        title="Low Latency">
                                                        <div class="snippet" data-title=".dot-flashing">
                                                            <div class="stage">
                                                                <div class="dot-flashing" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex flex-row items-center gap-2" title="High Latency">
                                                        <div>
                                                            <small v-if="allLatestMetrics[source.id]?.inline?.latency >= 60000">
                                                                <ClockIcon class="h-4 ml-1 w-4 flex-shrink-0 text-[#236C9E]" />
                                                            </small>
                                                        </div>
                                                        <div>
                                                            <small
                                                                v-if="allLatestMetrics[source.id]?.inline?.state"
                                                                class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                                style="background-color: rgb(249 250 251)">
                                                                <span class="my-auto">
                                                                    {{ allLatestMetrics[source.id]?.inline.state }}
                                                                </span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span v-else>
                                                    <small
                                                        class="inline-flex items-center rounded-full px-2.5 font-medium py-1 px-2.5 text-gray-800 shadow"
                                                        :style="`background: ${getStatusColor('Unknown')}`"
                                                        >Unknown</small
                                                    ></span
                                                >
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ source.topics?.length }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <span v-else>
                                                {{ getFormattedValue(source, 'StreamingMilliSecondsSinceLastEvent').display }}
                                            </span>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <div v-else class="flex flex-nowrap justify-content-start mr-4">
                                                <div class="my-auto mr-1">
                                                    {{ getFormattedValue(source, 'latency').display }}
                                                </div>
                                            </div>
                                        </td>

                                        <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                            <TableActionMenu
                                                v-if="
                                                    allLatestMetrics[source.id] && Object.keys(allLatestMetrics[source.id]?.inline || {}).length > 0
                                                "
                                                :show-view="true"
                                                :show-delete="userHasAccess([$Permissions.DELETE_SOURCES], authStore.user)"
                                                :show-logs="
                                                    userHasAccess(
                                                        [$Permissions.READ_SOURCE_METRICS, $Permissions.READ_DESTINATION_METRICS],
                                                        authStore.user
                                                    )
                                                "
                                                :show-clone="userHasAccess([$Permissions.WRITE_SOURCES], authStore.user)"
                                                :display-above="searchedSources.length && sourceIndex >= searchedSources.length - 2"
                                                :show-stop="
                                                    userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                                                    !(
                                                        getStatus(source) === 'Paused' ||
                                                        getStatus(source) === 'Starting' ||
                                                        getStatus(source) === 'Stropped'
                                                    )
                                                "
                                                :show-resume="
                                                    userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                                                    !(
                                                        getStatus(source) === 'Active' ||
                                                        getStatus(source) === 'Broken' ||
                                                        getStatus(source) === 'Starting'
                                                    )
                                                "
                                                :show-restart="
                                                    userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                                                    !(
                                                        getStatus(source) === 'Active' ||
                                                        getStatus(source) === 'Paused' ||
                                                        getStatus(source) === 'Stopped'
                                                    )
                                                "
                                                :loading="actionLoading[source.id]"
                                                :position-relative="!generalLoading"
                                                @view="onSourceSelected(source.id)"
                                                @delete="onDelete('source', source.name, source.id, sourceIndex)"
                                                @stop="onStop(source.id)"
                                                @clone="onClone(source)"
                                                @resume="onResume(source.id)"
                                                @restart="onRestart(source.id)"
                                                @logs="onViewLogs(source.id)" />
                                            <TableActionMenu
                                                v-else
                                                :position-relative="!generalLoading"
                                                :display-above="searchedSources.length && sourceIndex >= searchedSources.length - 2"
                                                :show-view="true"
                                                :show-delete="userHasAccess([$Permissions.DELETE_SOURCES], authStore.user)"
                                                :show-logs="
                                                    userHasAccess(
                                                        [$Permissions.READ_SOURCE_METRICS, $Permissions.READ_DESTINATION_METRICS],
                                                        authStore.user
                                                    )
                                                "
                                                :show-clone="userHasAccess([$Permissions.WRITE_SOURCES], authStore.user)"
                                                :loading="actionLoading[source.id]"
                                                @view="onSourceSelected(source.id)"
                                                @delete="onDelete('source', source.name, source.id, sourceIndex)"
                                                @logs="onViewLogs(source.id)"
                                                @clone="onClone(source)" />
                                        </td>
                                    </tr>
                                    <tr v-if="!searchedSources.length">
                                        <td colspan="99" class="text-center p-2">
                                            <small>No matching records found</small>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </AppLoader>
                <Pagination
                    v-if="sourcesStore.sources?.length || sourcesPage > 0"
                    :page="sourcesPage"
                    :page-size="sourcesPageSize"
                    :current-page-items-count="sourcesStore.sources?.length"
                    :total-items-count="sourcesStore.total"
                    :disable-navigation="generalLoading"
                    :message="{
                        show: searchedSources.length === sourcesStore.sources?.length && !generalLoading,
                        showCustomMessage: searchedSources.length !== sourcesStore.sources?.length && !generalLoading,
                        firstPart: 'Showing sources',
                        middlePart: 'to',
                        lastPart: `of ${sourcesStore.total}`
                    }"
                    @page-change="onPageChange">
                    <template #customMessage>
                        Showing
                        <span class="font-medium">{{ searchedSources.length }}</span>
                        {{ searchedSources.length === 1 ? 'match' : 'matches' }}
                        on this page
                    </template>
                </Pagination>
            </section>
        </div>
    </div>
</template>

<style scoped>
/* When the container is expanded */
.my-auto.whitespace-normal {
    min-height: unset; /* Remove the minimum height so it can expand */
    max-height: none; /* Ensure it's not constrained by max-height */
}
</style>
