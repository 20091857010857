<template>
    <div
        :class="{
            hidden: displayOnlySelected && !isNodeChecked,
            'sticky-node': hasChildren,
            'tree-node-with-branch': level == 0 || !isLast,
            'tree-node-with-branch-last': level != 0 && isLast
        }"
        :style="{ background, width: '95%' }">
        <div
            class="node-header top-0 z-10 flex flex-row items-center gap-1 ml-4"
            :style="{
                'z-index': zIndexBasedOnLevel,
                background: hasChildren ? background : undefined,
                width: '90%'
            }">
            <div v-if="treeLevels.length" class="node-branch-leaf"></div>
            <TreeCheckbox
                :checked="isNodeChecked"
                :disabled="!editMode && !disableCreateAndEdit"
                :readonly="readonly"
                :error="mutableNode.isError"
                @change="toggleSelected(nodes, mutableNode)" />
            <ChevronUpIcon
                v-if="mutableNode.children && !mutableNode.collapsed && treeLevels.length > 1"
                class="h-4 w-4 text-gray-600"
                @click="mutableNode.collapsed = true" />
            <ChevronDownIcon
                v-if="mutableNode.children && mutableNode.collapsed && treeLevels.length > 1"
                class="h-4 w-4 text-gray-600"
                @click="mutableNode.collapsed = false" />
            <div class="w-full flex flex-col">
                <div v-if="!editMode || disableCreateAndEdit" class="ml-2">{{ mutableNode.name }}</div>
                <input
                    v-else
                    :ref="`${node.name}-${nodeIndex}`"
                    v-model="mutableNode.name"
                    aria-label="input"
                    :disabled="!editMode"
                    :class="[
                        `block w-full appearance-none focus:outline-none focus:border-gray-300 rounded-md border px-2 py-1 placeholder-gray-400 shadow-sm sm:text-sm
          ${node.name}-${nodeIndex}`,
                        mutableNode.isError ? 'border-red-500 focus:border-red-500' : 'border-gray-300'
                    ]"
                    :placeholder="'* Name - cannot be null, duplicated or contain * (wildcard)'"
                    @input="checkDuplicateName(mutableNode, nodes)"
                    @keydown.enter="addChildNode(nodes, mutableNode.schemaLevel, mutableNode.schemaObject)" />
            </div>
            <XMarkIcon v-if="editMode && !disableCreateAndEdit" class="h-5 w-5 text-red-400 cursor-pointer" @click="removeNode(nodes, node)" />
        </div>
        <div class="node-children overflow-auto max-h-[30vh]">
            <div v-if="treeLevels.length > 1 && mutableNode.collapsed">
                <div v-if="mutableNode.children">
                    <div
                        :class="[
                            'children-list sticky-node flex flex-row items-center gap-2',
                            { hidden: displayOnlySelected && !isNodeChecked, 'has-parent-border': !!mutableNode.children.length }
                        ]"
                        :style="{
                            'z-index': zIndexBasedOnLevel - 1,
                            'padding-bottom': '0.5rem',
                            'padding-left': '0.75rem',
                            background,
                            width: '100%'
                        }">
                        <TreeCheckbox
                            v-if="mutableNode.children.length > 0"
                            :checked="isNodeChecked"
                            :readonly="readonly"
                            :partial="areChildrenPartiallySelected"
                            :disabled="!editMode && !disableCreateAndEdit"
                            :error="false"
                            @change="toggleAll(mutableNode)" />
                        <div class="text-sm font-medium text-gray-700">
                            {{ mutableNode.children.length }}
                            {{ labelName }}
                        </div>
                        <button
                            v-if="editMode && !disableCreateAndEdit"
                            aria-label="button"
                            class="flex items-center px-1 py-1 border border-green-500 text-green-500 hover:bg-green-400 hover:text-white text-xs font-medium rounded"
                            @click.prevent="addNode(mutableNode.children, mutableNode.schemaLevel)">
                            <PlusIcon class="h-4 w-4 mr-1" />
                            <span>Add {{ itemName }}</span>
                        </button>

                        <button
                            v-if="editMode && !disableCreateAndEdit"
                            aria-label="button"
                            class="flex items-center px-1 py-1 border border-green-500 text-green-500 hover:bg-green-400 hover:text-white text-xs font-medium rounded"
                            @click="openFileUpload()">
                            <ArrowUpOnSquareStackIcon class="h-4 w-4 mr-1" />
                            <span>Use .CSV</span>
                            <input ref="fileInput" aria-label="input" type="file" accept=".csv" style="display: none" @change="onFileInputChange" />
                        </button>
                    </div>
                    <div v-if="mutableNode.children.length > 0">
                        <div v-for="(child, childIndex) in mutableNode.children" :key="childIndex" class="child-node-container">
                            <!-- The branch line to the child -->
                            <div v-if="treeLevels.length > 1" class="node-branch-leaf"></div>
                            <!-- <div v-else-if="treeLevels.length > 1 && isLast" class="node-branch-leaf-last"></div> -->
                            <!-- The child node itself -->
                            <tree-node
                                :node="child"
                                :nodes="mutableNode.children"
                                :node-index="childIndex"
                                :level="level + 1"
                                :edit-mode="editMode"
                                :is-last="childIndex === mutableNode.children.length - 1"
                                :tree-levels="treeLevels"
                                :readonly="readonly"
                                :background="background"
                                :display-only-selected="displayOnlySelected"
                                :parent-node="mutableNode"
                                :disable-create-and-edit="disableCreateAndEdit"
                                @duplicate="$emit('duplicate')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PlusIcon, ChevronUpIcon, ChevronDownIcon, XMarkIcon, ArrowUpOnSquareStackIcon } from '@heroicons/vue/24/outline';
import TreeCheckbox from '../components/TreeCheckbox.vue';
import Papa from 'papaparse';

export default {
    name: 'TreeNode',
    components: {
        PlusIcon,
        ChevronUpIcon,
        ChevronDownIcon,
        TreeCheckbox,
        XMarkIcon,
        ArrowUpOnSquareStackIcon
    },
    props: {
        nodes: {
            type: Array,
            required: true
        },
        node: {
            type: Object,
            required: true
        },
        level: {
            type: Number,
            required: true
        },
        nodeIndex: {
            type: String,
            required: true
        },
        treeLevels: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        displayOnlySelected: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        },
        parentNode: {
            type: Object,
            default: null
        },
        disableCreateAndEdit: {
            type: Boolean,
            default: false
        },
        background: {
            type: String,
            default: 'white'
        },
        isLast: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileInput: null,
            oldNodesLength: this.nodes.length
        };
    },
    computed: {
        hasChildren() {
            return this.node.children && this.node.children.length > 0;
        },
        itemName() {
            return this.treeLevels[this.treeLevels.findIndex((level) => level.schemaLevel === this.mutableNode.schemaLevel) + 1].schemaLevel;
        },
        labelName() {
            return this.mutableNode.children.length != 1 ? this.itemName + 's' : this.itemName;
        },
        mutableNode() {
            return this.node;
        },
        isNodeChecked() {
            return this.mutableNode.children?.length > 0 ? this.areAnyChildrenSelected : this.mutableNode.isSelected;
        },
        areAnyChildrenSelected() {
            return this.mutableNode.children?.some((x) => x.isSelected);
        },
        areChildrenPartiallySelected() {
            const children = this.mutableNode.children;
            const selectedChildren = children.filter((obj) => obj.isSelected);
            return selectedChildren.length > 0 && selectedChildren.length < children.length;
        },
        zIndexBasedOnLevel() {
            const baseZIndex = 200;
            const decrementFactor = 10;
            const calculatedZIndex = baseZIndex - this.level * decrementFactor;
            return calculatedZIndex > 0 ? calculatedZIndex : 1; // Ensure z-index never goes below 1
        }
    },
    watch: {
        areAnyChildrenSelected: {
            handler(value) {
                // when no children is selected, deselect the current node. First time when a child is selected, select the current node
                this.mutableNode.isSelected = value;
            }
        },
        nodes: {
            deep: true,
            handler(newNodes) {
                if (newNodes.length > this.oldNodesLength) {
                    const newNode = newNodes[newNodes.length - 1];
                    this.checkDuplicateName(newNode, newNodes);
                }
                this.oldNodesLength = newNodes.length;
            }
        }
    },
    mounted() {
        this.focusInput();

        const fileRef = this.$refs.fileInput;
        if (fileRef) {
            this.fileInput = fileRef;
        }
    },
    methods: {
        removeNode: function (nodeList, nodeEntry) {
            const nodeIndex = nodeList.indexOf(nodeEntry);
            nodeList.splice(nodeIndex, 1);
            this.checkDuplicateName(nodeEntry, nodeList);
        },
        addNode: function (nodes, parentNodeLevel, customName = null, fromCSVFile = false) {
            const nodeLevel = this.treeLevels.findIndex((level) => level.schemaLevel === parentNodeLevel);
            const defaultPlaceholder = `Enter ${this.treeLevels[nodeLevel + 1].schemaLevel} name`;
            let placeholderName = customName ?? defaultPlaceholder;

            let name = fromCSVFile ? customName : '';

            const nodeEntry = {
                name: name,
                isSelected: true,
                collapsed: true,
                schemaLevel: this.treeLevels[nodeLevel + 1].schemaLevel,
                schemaObject: this.treeLevels[nodeLevel + 1].schemaObject,
                children: nodeLevel < this.treeLevels.length - 2 ? [] : undefined,
                placeholder: placeholderName
            };

            nodes.push(nodeEntry);
        },
        addChildNode: function (nodes, schemaLevel, schemaObject) {
            const nodeLevel = this.treeLevels.findIndex((level) => level.schemaLevel === schemaLevel);
            const defaultPlaceholder = `Enter ${schemaLevel} name`;
            nodes.push({
                name: '',
                isSelected: true,
                collapsed: true,
                schemaLevel: schemaLevel,
                schemaObject: schemaObject,
                children: nodeLevel < this.treeLevels.length - 2 ? [] : undefined,
                placeholder: defaultPlaceholder
            });
        },
        focusInput: function () {
            const myInput = `${this.node.name}-${this.nodeIndex}`;
            if (myInput) {
                const myRef = this.$refs[myInput];
                if (myRef) {
                    myRef.focus();
                }
            }
        },
        checkDuplicateName: function (node, nodesList) {
            const isDuplicate = nodesList.some((other) => other !== node && other.name.trim() === node.name.trim());
            const isEmpty = node.name === '' || node.name === null;
            const containsWildcard = node.name.includes('*');

            node.isError = isDuplicate || isEmpty || containsWildcard;

            if (!node.isError) {
                delete node.isError;
            }

            this.$emit('duplicate');
        },
        toggleAll: function (node) {
            node.isSelected = !node.isSelected;
            this.toggleAllChildren(node.children, node.isSelected);
        },
        toggleSelected: function (nodes, targetNode) {
            this.toggleNode(nodes, targetNode);
        },
        toggleNode: function (nodes, targetNode) {
            for (const node of nodes) {
                if (node === targetNode) {
                    node.isSelected = !node.isSelected;
                    if (node.children && node.children.length) {
                        this.toggleAllChildren(node.children, node.isSelected);
                    }
                    return true;
                }
                if (node.children && node.children.length) {
                    if (this.toggleNode(node.children, targetNode)) return true;
                }
            }
            return false;
        },
        toggleAllChildren: function (nodes, isSelected) {
            for (const node of nodes) {
                node.isSelected = isSelected;
                if (node.children && node.children.length) {
                    this.toggleAllChildren(node.children, isSelected);
                }
            }
        },
        openFileUpload: async function () {
            const fileRef = this.$refs.fileInput;
            if (fileRef) {
                this.fileInput = fileRef;
            }

            if (this.fileInput) {
                // Reset the file input value
                this.fileInput.value = '';
                this.fileInput.click();
            }
        },
        parseCsv: function (csv) {
            return new Promise((resolve) => {
                Papa.parse(csv, {
                    delimiter: ',',
                    skipEmptyLines: true,
                    dynamicTyping: true,
                    transform: (value) =>
                        value
                            .replace(/(\s|^)\./g, '')
                            .replace(/\.$/, '')
                            .replace(/""/g, '"')
                            .trim(),
                    error: (error) => {
                        alert('Failed to parse CSV file.');
                        console.error('Failed to parse CSV file:', error.message);
                    },
                    complete: (results) => {
                        const flattenedData = results.data.flat();
                        const filteredData = flattenedData.filter((value) => value !== null && value !== '');
                        resolve(filteredData);
                    }
                });
            });
        },
        onFileInputChange: async function (event) {
            event.target.removeEventListener('change', this.onFileInputChange);
            await this.loadCsvFile(event);
            event.target.addEventListener('change', this.onFileInputChange);
        },
        loadCsvFile: async function (event) {
            const file = event.target.files[0];
            if (file) {
                // Check if the file type is a CSV
                if (!file.type.includes('csv') && !file.name.toLowerCase().endsWith('.csv')) {
                    alert('Please upload a valid CSV file.');
                    console.error('Please upload a valid CSV file.');
                    return;
                }

                const reader = new FileReader();
                reader.onload = async (e) => {
                    const csv = e.target.result;
                    const parsedData = await this.parseCsv(csv);
                    parsedData.forEach((element) => {
                        this.addNode(this.mutableNode.children, this.mutableNode.schemaLevel, element, true);
                    });
                };
                reader.readAsText(file);
            }
        }
    }
};
</script>

<style scoped>
.node-header {
    padding: 8px;
}

.node-children {
    padding-left: 3.25rem;
}

.sticky-node {
    position: sticky;
    top: 0;
}

.children {
    padding-left: 20px;
}

.has-parent-border {
    border-bottom: 1px solid #c0c0c0;
    width: 80% !important;
}

.tooltip {
    position: absolute; /* or 'fixed' */
    z-index: 9999; /* High z-index */
    /* Other styling */
}

.tree-node-with-branch {
    border-left: 1px solid #c0c0c0; /* This creates the vertical line */
    position: relative;
}

.node-branch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.child-node-container {
    display: flex;
    position: relative;
    align-items: center; /* This will help vertically center the leaf */
    width: 100%;
}

.node-branch-leaf {
    width: 20px; /* Width of the leaf */
    height: 1px; /* Thickness of the branch line */
    background-color: #c0c0c0; /* Color of the branch line */
    position: absolute;
    left: 0; /* Aligns to the left edge of the container */
    z-index: 1; /* Ensure it's above the background but below the checkbox/input */
}

.tree-node-with-branch-last::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 50%; /* This will effectively reduce the length of the line by half */
    border-left: 1px solid #c0c0c0;
}

/* This styles the scrollbar track */
::-webkit-scrollbar-track {
    background: var(--primary);
}

/* This styles the scrollbar handle */
::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border: 3px solid var(--primary);
    border-radius: 30px; /* Optional: for rounded corners */
}

/* This styles the scrollbar itself (width, color) */
::-webkit-scrollbar {
    width: 10px; /* Thin scrollbar */
    background-color: #f0f0f0; /* Light grey scrollbar background */
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
    background: transparent;
}

/* This styles the scrollbar for Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 #f0f0f0; /* thumb and track color */
}
</style>
