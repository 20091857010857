import api from '../services/axios.service';
import { constructQueryParams } from '../utils/utils';

export async function fetchPipelines(page = 0, pageSize = 10, searchQuery = null) {
    const queryParams = constructQueryParams({ page, page_size: pageSize, partial_name: searchQuery });

    return api.get(`pipelines?${queryParams}`);
}

export async function fetchAllPipelineBrief() {
    return api.get(`pipelines/all`);
}

export async function createPipeline(connection) {
    return api.post(`pipelines`, connection);
}

export async function getPipeline(id) {
    const response = await api.get(`pipelines?id=${id}`);

    return response?.result?.[0];
}

export async function editPipeline(connection, id) {
    return api.put(`pipelines?id=${id}`, connection);
}

export async function deletePipeline(id) {
    return api.delete(`pipelines?id=${id}`);
}

export async function pausePipelineConnection(id) {
    return api.put(`pause_pipeline?id=${id}`);
}

export async function resumePipelineConnection(id) {
    return api.put(`resume_pipeline?id=${id}`);
}

export async function restartPipelineConnection(id) {
    return api.post(`restart_pipeline?id=${id}`);
}
