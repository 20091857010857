<template>
    <multiselect
        :id="randomUIDBasedOnTime"
        v-model="selected"
        :class="{ disabled: disabled || !editMode }"
        :loading="loading"
        :disabled="disabled || !editMode"
        :options="filteredOptions"
        :max-height="300"
        :multiple="multiple"
        :close-on-select="autoClose"
        :track-by="trackBy"
        :label="labelField"
        :searchable="searchable"
        :internal-search="false"
        :single-label-display="singleLabelDisplay"
        :group-label="groupLabel"
        :group-values="groupValues"
        :open-direction="openDirection"
        :group-select="groupSelect"
        :placeholder="placeholder"
        :show-labels="false"
        :allow-empty="!required"
        aria-expanded="false"
        aria-controls="options-listbox"
        @close="update"
        @search-change="onSearchChange"
        @input="onInput">
        <template #selection="{ values, search, isOpen }">
            <slot name="selection" :values="values" :search="search" :is-open="isOpen" />
        </template>

        <template #singleLabel="{ option, search }">
            <slot name="singleLabel" :option="option" :search="search">
                {{ getOptionLabel(option) }}
            </slot>
        </template>

        <template #tag="{ search, option, remove }">
            <slot name="tag" :option="option" :search="search">
                <span class="multiselect__tag">
                    <span>{{ getOptionLabel(option) }}</span>
                    <i
                        v-if="!option.required"
                        tabindex="1"
                        class="multiselect__tag-icon"
                        @click="
                            () => {
                                remove(option);
                                update();
                            }
                        " />
                </span>
            </slot>
        </template>

        <template #option="{ option, search }">
            <slot name="option" :option="option" :search="search">
                <div v-if="option.$groupLabel" class="text-center">
                    {{ option.$groupLabel }}
                </div>
                <div v-else class="d-flex flex-column justify-content-center pl-1">
                    <span>
                        {{ getOptionLabel(option) }}
                    </span>
                    <span v-if="showIds" class="text-muted">
                        {{ getOptionId(option) }}
                    </span>
                </div>
            </slot>
        </template>
        <!--
        <template #beforeList>
            <slot name="beforeList">
                <div class="d-flex justify-content-start" @click.stop.prevent>
                    <button aria-label="button"
                        v-if="multiple"
                        :disabled="allSelected"
                        class="p-3 btn btn-link"
                        @click.stop.prevent="selectAll">
                        Select All
                    </button>
                    <button aria-label="button"
                        v-if="multiple"
                        :disabled="noneSelected"
                        class="p-3 btn btn-link"
                        @click.stop.prevent="clearAll">
                        Deselect All
                    </button>
                    <button aria-label="button"
                        v-if="showRefresh"
                        class="p-3 btn btn-link ml-auto"
                        @click.stop.prevent="onRefresh">
                        Refresh
                    </button>
                </div>
            </slot>
        </template> -->

        <template #caret="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle" />
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _ from 'lodash';

export default {
    components: { Multiselect },
    props: {
        value: {
            type: [Array, String, Object, Boolean],
            default: null
        },
        options: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        autoClose: {
            type: Boolean,
            default: true
        },
        singleLabelDisplay: {
            type: String,
            default: undefined
        },
        labelField: {
            type: String,
            default: undefined
        },
        valueField: {
            type: String,
            default: undefined
        },
        trackBy: {
            type: String,
            default: undefined
        },
        groupSelect: {
            type: Boolean,
            default: false
        },
        groupLabel: {
            type: String,
            default: undefined
        },
        groupValues: {
            type: String,
            default: undefined
        },
        placeholder: {
            type: String,
            default: undefined
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        displayLabel: {
            type: String
        },
        searchable: {
            type: Boolean,
            default: false
        },
        showValues: {
            type: Boolean,
            default: false
        },
        showIds: {
            type: Boolean,
            default: false
        },
        showRefresh: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: true
        },
        openDirection: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            selected: this.value,
            searchTerm: ''
        };
    },
    computed: {
        availableOptions() {
            if (!this.labelField && !this.valueField) return this.options;
            return _.map(this.options, (option) => {
                return {
                    ...option,
                    $isDisabled: _.get(option, 'required', false)
                };
            });
        },
        filteredOptions() {
            const searchTerm = _.toLower(this.searchTerm);
            const options = _.filter(this.availableOptions, (option) => {
                const optionId = this.valueField ? option[this.valueField] : option;
                const optionLabel = this.labelField ? option[this.labelField] : option;
                return _.includes(_.toLower(optionLabel), searchTerm) || _.includes(_.toLower(optionId), searchTerm);
            });
            return options;
        },
        allSelected() {
            return _.size(this.selected) === _.size(this.filteredOptions);
        },
        noneSelected() {
            return _.size(this.selected) === 0;
        },
        randomUIDBasedOnTime() {
            return `multiselect-${Date.now()}`;
        }
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                if (this.valueField && this.multiple) {
                    if (this.groupSelect)
                        this.selected = _.filter(_.flatMap(this.availableOptions, this.groupValues), (opt) =>
                            _.includes(val, _.get(opt, this.valueField))
                        );
                    else this.selected = _.filter(this.availableOptions, (opt) => opt.required || _.includes(val, _.get(opt, this.valueField)));
                }
                if (this.valueField && !this.multiple) {
                    this.selected = _.find(this.availableOptions, { [this.valueField]: val }) || '';
                }
                if (!this.valueField && this.multiple) {
                    if (this.groupSelect) this.selected = _.filter(_.flatMap(this.availableOptions, this.groupValues), (opt) => _.includes(val, opt));
                    else this.selected = _.filter(this.availableOptions, (opt) => _.includes(val, opt));
                }
                if (!this.valueField && !this.multiple) {
                    this.selected = val;
                }
            }
        },
        options: {
            deep: true,
            handler(newOptions) {
                if (this.valueField && this.multiple) {
                    if (this.groupSelect) {
                        this.selected = _.filter(_.flatMap(newOptions, this.groupValues), (opt) =>
                            _.some(this.selected, {
                                [this.valueField]: _.get(opt, this.valueField)
                            })
                        );
                    } else {
                        this.selected = _.filter(newOptions, (opt) => {
                            return (
                                opt.required ||
                                _.some(this.selected, {
                                    [this.valueField]: _.get(opt, this.valueField)
                                })
                            );
                        });
                    }
                }
                if (this.valueField && !this.multiple) {
                    this.selected =
                        _.find(newOptions, {
                            [this.valueField]: _.get(this.selected, this.valueField)
                        }) || '';
                }
                if (!this.valueField && this.multiple) {
                    if (this.groupSelect) this.selected = _.filter(_.flatMap(newOptions, this.groupValues), (opt) => _.includes(this.selected, opt));
                    else this.selected = _.filter(newOptions, (opt) => _.includes(this.selected, opt));
                }
                if (!this.valueField && !this.multiple) {
                    this.selected = _.includes(newOptions, this.selected) ? this.selected : null;
                }
            }
        },
        loading: {
            handler(newVal) {
                if (!newVal) this.initSelectedValues();
            }
        }
    },
    created() {
        if (!this.loading) {
            this.initSelectedValues();
        }
    },
    methods: {
        initSelectedValues() {
            let newSelected = this.selected;
            if (this.multiple && this.valueField) {
                if (this.groupSelect)
                    newSelected = _.filter(_.flatMap(this.availableOptions, this.groupValues), (opt) =>
                        _.includes(this.value, _.get(opt, this.valueField))
                    );
                else {
                    newSelected = _.filter(this.availableOptions, (opt) => {
                        return opt.required || _.includes(this.value, _.get(opt, this.valueField));
                    });
                }
            }

            if (!this.multiple && this.valueField) {
                newSelected = _.find(this.availableOptions, { [this.valueField]: this.value }) || null;
            }

            if (!_.isEqual(newSelected, this.selected) || _.isEmpty(newSelected)) {
                this.selected = newSelected;
                this.update();
            }
        },
        getOptionLabel(option) {
            if (this.labelField) {
                return _.get(option, this.labelField, option.$groupLabel);
            }

            return option;
        },
        getOptionId(option) {
            if (this.valueField) {
                return _.get(option, this.valueField);
            }

            return option;
        },
        update() {
            if (!this.multiple) {
                if (this.valueField) {
                    this.$emit('input', _.get(this.selected, this.valueField, null));
                } else {
                    this.$emit('input', this.selected || null);
                }
            } else if (this.valueField) {
                let selected = [];
                if (this.groupSelect) {
                    selected = _.filter(_.flatMap(this.availableOptions, this.groupValues), (opt) => {
                        return _.some(this.selected, {
                            [this.valueField]: _.get(opt, this.valueField)
                        });
                    });
                } else {
                    selected = _.filter(this.availableOptions, (opt) => {
                        return _.some(this.selected, {
                            [this.valueField]: _.get(opt, this.valueField)
                        });
                    });
                }

                this.$emit('input', _.map(selected, this.valueField));
            } else {
                let selected = [];
                if (this.groupSelect)
                    selected = _.filter(_.flatMap(this.availableOptions, this.groupValues), (opt) => _.includes(this.selected, opt));
                else this.selected = _.filter(this.availableOptions, (opt) => _.includes(this.selected, opt));

                this.$emit('input', selected);
            }
        },
        clearAll() {
            this.selected = _.filter(this.filteredOptions, '$isDisabled');
        },
        selectAll() {
            this.selected = this.filteredOptions;
        },
        onSearchChange(search) {
            this.searchTerm = search;
        },
        onInput(values) {
            this.selected = values;
        },
        onRefresh() {
            this.$emit('refresh');
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
