import tooltipDirective from './Tooltip/';
import canAccessDirective from './CanAccess';

// register all directives
const directives = (app) => {
    tooltipDirective(app);
    canAccessDirective(app);
};

export default directives;
