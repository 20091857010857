<script setup>
import { ref, onMounted } from 'vue';
import {
    PencilSquareIcon,
    MinusCircleIcon,
    ViewColumnsIcon,
    PlayIcon,
    BackwardIcon,
    PauseIcon,
    StopIcon,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    CodeBracketSquareIcon,
    DocumentDuplicateIcon,
    EnvelopeOpenIcon,
    EnvelopeIcon,
    ClipboardIcon
} from '@heroicons/vue/24/outline';
import HalfCircleSpinner from './global/loader/HalfCircleSpinner.vue';

const props = defineProps({
    showView: ref(false),
    disableView: ref(false),
    showEdit: ref(false),
    disableEdit: ref(false),
    showPause: ref(false),
    showStop: ref(false),
    disableStop: ref(false),
    disablePause: ref(false),
    showResume: ref(false),
    disableResume: ref(false),
    showRestart: ref(false),
    disableRestart: ref(false),
    showSnapshot: ref(false),
    disableSnapshot: ref(false),
    showCancelSnapshot: ref(false),
    showViews: ref(false),
    disableViews: ref(false),
    showDelete: ref(false),
    disableDelete: ref(false),
    showLogs: ref(false),
    disableLogs: ref(false),
    showActionMenu: ref(false),
    showClone: ref(false),
    disableClone: ref(false),
    buttonStyle: ref(false),
    positionRelative: ref(false),
    displayAbove: ref(false),
    loading: ref(false),
    showPauseSource: ref(false),
    disablePauseSource: ref(false),
    showResumeSource: ref(false),
    disableResumeSource: ref(false),
    showStopSource: ref(false),
    disableStopSource: ref(false),
    showPauseDestination: ref(false),
    disablePauseDestination: ref(false),
    showResumeDestination: ref(false),
    disableResumeDestination: ref(false),
    showStopDestination: ref(false),
    disableStopDestination: ref(false),
    showRead: ref(false),
    showUnread: ref(false),
    showCopyId: ref(false),
    disableCopyId: ref(false)
});

const noActionAvailable =
    !props.showView &&
    !props.showEdit &&
    !props.showPause &&
    !props.showSnapshot &&
    !props.showViews &&
    !props.showCancelSnapshot &&
    !props.showPause &&
    !props.showResume &&
    !props.showRestart &&
    !props.showDelete &&
    !props.showClone &&
    !props.showLogs &&
    !props.showRead &&
    !props.showUnread &&
    !props.showPauseSource &&
    !props.showResumeSource &&
    !props.showPauseDestination &&
    !props.showResumeDestination &&
    !props.showStopSource &&
    !props.showStopDestination &&
    !props.showCopyId &&
    !props.showStop;

let showActionMenu = ref(props.showActionMenu);

function onShowActionMenu() {
    showActionMenu.value = !showActionMenu.value;
}

const onEmit = (value) => {
    onShowActionMenu();
    emit(value);
};

const componentRef = ref(null);
onMounted(() => {
    document.addEventListener('click', handleClick);
});

const emit = defineEmits([
    'discard',
    'next',
    'back',
    'view',
    'edit',
    'save',
    'snapshot',
    'cancelSnapshot',
    'pause',
    'resume',
    'restart',
    'delete',
    'clone',
    'logs',
    'pauseSource',
    'resumeSource',
    'pauseDestination',
    'resumeDestination',
    'stopSource',
    'stopDestination',
    'stop',
    'copyId'
]);
const handleClick = (event) => {
    if (componentRef.value != null && !componentRef.value.contains(event.target)) {
        showActionMenu.value = false;
    }
};
</script>

<template>
    <div v-if="!loading" ref="componentRef" :class="props.positionRelative ? 'relative flex justify-center items-center' : 'dropdown'">
        <div class="flex flex-col items-center">
            <div
                v-click-outside="() => (showActionMenu = false)"
                class="cursor-pointer p-1 rounded-full transition duration-300 ease-in-out"
                :class="`${props.buttonStyle ? '' : showActionMenu || props.loading ? 'bg-gray-200' : 'bg-white'} ${
                    showActionMenu || props.loading ? 'bg-gray-200' : 'hover:bg-gray-100'
                }`"
                @click="props.loading ? () => null : onShowActionMenu()">
                <div class="vertical-dots"></div>
            </div>
        </div>
        <div
            v-if="!loading"
            :class="[
                { 'dropdown-content-active': showActionMenu },
                { 'xl:right-[3rem] 2xl:right-[3.7rem] right-[2.5rem]': props.positionRelative },
                { 'bottom-[-50%]': props.displayAbove },
                { 'top-[-50%]': props.positionRelative && !props.displayAbove },
                'dropdown-content bg-white'
            ]">
            <button v-if="showView" aria-label="button" :disabled="props.disableView" class="btn-link hover:bg-gray-100" @click="onEmit('view')">
                <button
                    aria-label="button"
                    type="button"
                    title="View"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-gray-700">
                    <ViewColumnsIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-[#236C9E]" />
                </button>
                <small class="align-super ml-1">View</small>
            </button>
            <button v-if="showEdit" aria-label="button" :disabled="props.disableEdit" class="btn-link hover:bg-gray-100" @click="onEmit('edit')">
                <button
                    aria-label="button"
                    type="button"
                    title="Edit"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <PencilSquareIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-[#236C9E]" />
                </button>
                <small class="align-super ml-1">Edit</small>
            </button>
            <button
                v-if="showCopyId"
                aria-label="button"
                :disabled="props.disableCopyId"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('copyId')">
                <button
                    aria-label="button"
                    type="button"
                    title="Copy ID"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <ClipboardIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-[#236C9E]" />
                </button>
                <small class="align-super ml-1">Copy ID</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="showSnapshot"
                aria-label="button"
                :disabled="props.disableSnapshot"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('snapshot')">
                <button
                    aria-label="button"
                    type="button"
                    title="Snapshot"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <VideoCameraIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-[#236C9E]" />
                </button>
                <small class="align-super ml-1">Snapshot</small>
            </button>
            <button v-if="showViews" aria-label="button" :disabled="props.disableViews" class="btn-link hover:bg-gray-100" @click="onEmit('views')">
                <button
                    aria-label="button"
                    type="button"
                    title="Views"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <ViewColumnsIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-[#236C9E]" />
                </button>
                <small class="align-super ml-1">Views</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="showCancelSnapshot"
                aria-label="button"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('cancelSnapshot')">
                <button
                    aria-label="button"
                    type="button"
                    title="Snapshot"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <VideoCameraSlashIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-red-400" />
                </button>
                <small class="align-super ml-1">Cancel Snapshot</small>
            </button>
            <button
                v-if="showStop"
                aria-label="button"
                :disabled="props.disableStop"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('stop')">
                <button
                    aria-label="button"
                    type="button"
                    title="Stop"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-gray-700">
                    <StopIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Stop</small>
            </button>
            <button
                v-if="showPause"
                aria-label="button"
                :disabled="props.disablePause"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('pause')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-gray-700">
                    <PauseIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Pause</small>
            </button>
            <button
                v-if="showResume"
                aria-label="button"
                href="#"
                :disabled="props.disableResume"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('resume')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-gray-700">
                    <PlayIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-emerald-400" />
                </button>
                <small class="align-super ml-1">Resume</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="showPauseSource"
                :disable="props.disablePauseSource"
                aria-label="button"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('pauseSource')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause source"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-0.5 leading-4 text-gray-700">
                    <PauseIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Pause source</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="showStopSource"
                :disable="props.disableStopSource"
                aria-label="button"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('stopSource')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause source"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-0.5 leading-4 text-gray-700">
                    <StopIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Stop source</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="showResumeSource"
                :disable="props.disableResumeSource"
                aria-label="button"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('resumeSource')">
                <button
                    aria-label="button"
                    type="button"
                    title="Resume source"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-0.5 leading-4 text-gray-700">
                    <PlayIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-emerald-400" />
                </button>
                <small class="align-super ml-1">Resume source</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_DESTINATIONS]"
                v-if="showPauseDestination"
                :disable="props.disablePauseDestination"
                aria-label="button"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('pauseDestination')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause destination"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-0.5 leading-4 text-gray-700">
                    <PauseIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Pause destination</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_DESTINATIONS]"
                v-if="showStopDestination"
                aria-label="button"
                :disable="props.disableStopDestination"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('stopDestination')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause destination"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-0.5 leading-4 text-gray-700">
                    <StopIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Stop destination</small>
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_DESTINATIONS]"
                v-if="showResumeDestination"
                aria-label="button"
                :disable="props.disableResumeDestination"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('resumeDestination')">
                <button
                    aria-label="button"
                    type="button"
                    title="Resume destination"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-0.5 leading-4 text-gray-700">
                    <PlayIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-emerald-400" />
                </button>
                <small class="align-super ml-1">Resume destination</small>
            </button>
            <button
                v-if="showRestart"
                aria-label="button"
                :disabled="props.disableRestart"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('restart')">
                <button
                    aria-label="button"
                    type="button"
                    title="Pause"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-gray-700">
                    <BackwardIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-green-500" />
                </button>
                <small class="align-super ml-1">Reset</small>
            </button>
            <button v-if="showRead" aria-label="button" :disabled="false" href="#" class="btn-link hover:bg-gray-100" @click="onEmit('read')">
                <button
                    aria-label="button"
                    type="button"
                    title="Mark as read"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <EnvelopeOpenIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Mark as read</small>
            </button>
            <button v-if="showUnread" aria-label="button" :disabled="false" href="#" class="btn-link hover:bg-gray-100" @click="onEmit('unread')">
                <button
                    aria-label="button"
                    type="button"
                    title="Mark as unread"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <EnvelopeIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Mark as unread</small>
            </button>
            <button
                v-if="showDelete"
                aria-label="button"
                :disabled="props.disableDelete"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('delete')">
                <button
                    aria-label="button"
                    type="button"
                    title="Delete"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <MinusCircleIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-red-700" />
                </button>
                <small class="align-super ml-1">Delete</small>
            </button>
            <button
                v-if="showClone"
                aria-label="button"
                :disabled="props.disableClone"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('clone')">
                <button
                    aria-label="button"
                    type="button"
                    title="Clone"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <DocumentDuplicateIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-blue-600" />
                </button>
                <small class="align-super ml-1">Clone</small>
            </button>
            <button
                v-if="showLogs"
                aria-label="button"
                :disabled="props.disableLogs"
                href="#"
                class="btn-link hover:bg-gray-100"
                @click="onEmit('logs')">
                <button
                    aria-label="button"
                    type="button"
                    title="Delete"
                    class="cursor-pointer relative inline-flex w-0 flex-1 mr-1 items-center justify-center rounded text-sm font-medium px-2 py-1 leading-4 text-slate-700">
                    <CodeBracketSquareIcon class="h-5 w-5 flex-shrink-0 text-gray-600 text-slate-600" />
                </button>
                <small class="align-super ml-1">Logs</small>
            </button>
            <button v-if="noActionAvailable" aria-label="button" href="#" class="!p-3 cursor-default">
                <small class="text-gray-600 italic">No action available</small>
            </button>
        </div>
    </div>
    <div v-else>
        <HalfCircleSpinner :loading="loading" />
    </div>
</template>

<style>
.vertical-dots {
    width: 20px;
    height: 20px;
    background-image: radial-gradient(circle, black 1px, transparent 2px);
    background-size: 100% 33.33%;
}
/* The dropdown container */
.dropdown {
    float: left;
    overflow: hidden;
    cursor: pointer;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    min-width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
}

/* Links inside the dropdown */
.dropdown-content .btn-link {
    width: 100%;
    float: none;
    color: black;
    padding: 2px 12px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Show the dropdown menu on hover */
.dropdown-content-active {
    display: block;
    z-index: 9999;
}
</style>
