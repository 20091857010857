<script setup>
import { watch, ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useDestinationsStore } from '../../stores/destinations.store';
import AppLoader from '../../components/AppLoader.vue';
import DynamicField from '../../components/DynamicField.vue';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '../../components/AppFooter.vue';
import Tooltip from '../../components/Tooltip.vue';
import ErrorMessage from '../../components/ErrorMessage.vue';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import RowActionMenu from '@/components/RowActionMenu.vue';
import _ from 'lodash';
import { adjustControlConditionalValues, adjustControlConditionalVisibility } from '@/utils/utils';

const props = defineProps({
    connector: {
        type: String,
        default: null
    },
    cloneConfig: {
        type: Object,
        default: null
    }
});
let selectedTitle = ref(null);
const destinations = useDestinationsStore();
const router = useRouter();
const connection = ref({});
const connectionName = ref('');
let formError = ref('');
let loading = ref(false);
let loadingList = ref(false);
let allDestinationConnectors = ref([]);
let advancedMode = ref(false);
const hidePromptTemporary = ref(false);

const hideLateralDescriptionValue = ref(true);

onBeforeRouteLeave((to, from, next) => {
    if (loadingList.value) {
        return next();
    }

    if (hidePromptTemporary.value) {
        return next();
    }

    const answer = window.confirm('Are you sure you want to leave? You may have unsaved changes.');

    if (answer) {
        return next();
    } else {
        return next(false);
    }
});

const handleResize = () => {
    hideLateralDescriptionValue.value = window.innerWidth <= 1024;
};

onMounted(() => {
    handleResize();
});

window.addEventListener('resize', handleResize);

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

onMounted(async () => {
    try {
        loadingList.value = true;
        allDestinationConnectors.value = await getDestinationsConnectors();

        selectedTitle.value = allDestinationConnectors.value?.find((conn) => conn.connector == props.connector)?.name;
    } finally {
        loadingList.value = false;
    }

    watch(
        props,
        async ({ connector }) => {
            loadingList.value = true;
            if (connector && !destinationConnectorConfig.value) await destinations.fetchConfiguration(connector);
            loadingList.value = false;
        },
        { immediate: true }
    );
});

const onSaveDestination = () => {
    loading.value = true;

    const config = Object.values(connection.value).reduce((payload, { name, value }) => {
        return {
            ...payload,
            [name]: value
        };
    }, {});

    destinations
        .create({
            name: connectionName.value,
            connector: props.connector,
            config
        })
        .then(() => {
            // destinations.destinations = [];
            // metrics.destinations.inlineDestinationMetricsList = [];
            hidePromptTemporary.value = true;
            router.push({ name: 'connectors', query: { tab: 'Destinations' } });
            loading.value = false;
        })
        .catch((err) => {
            formError.value = err;
            loading.value = false;
            hidePromptTemporary.value = false;
        });
};

const onFieldChanged = ({ value, valid, name }) => {
    const control = destinations.configurations[props.connector].find((x) => x.name == name);

    if (control) {
        control.value.current_value = value;
    }

    connection.value[name] = {
        value,
        valid,
        name
    };
};

const destinationConnectorConfig = computed(() => {
    const configurations = destinations.configurations[props.connector];
    if (!configurations) return null;

    let connectorConfig = _.cloneDeep(configurations);

    if (props.cloneConfig && connectorConfig) {
        const cloneConfig = JSON.parse(props.cloneConfig);

        connectorConfig = _.map(connectorConfig, (configItem) => {
            if (configItem.encrypt) return configItem;

            const clonedConfigValue = cloneConfig[configItem.name];
            if (_.isNil(clonedConfigValue)) return configItem;

            return {
                ...configItem,
                value: {
                    ...configItem.value,
                    default: clonedConfigValue
                }
            };
        });
    }

    return connectorConfig;
});

const visibleBasicConnectorConfigControls = computed(() => {
    return _.chain(adjustControlConditionalValues(destinationConnectorConfig.value))
        .filter((x) => !x.display_advanced)
        .orderBy('order_of_display', 'asc')
        .map((control) => adjustControlConditionalVisibility(control, destinationConnectorConfig.value)?.control || null)
        .compact()
        .value();
});

const visibleAdvancedConnectorConfigControls = computed(() => {
    return _.chain(adjustControlConditionalValues(destinationConnectorConfig.value))
        .filter((x) => x.display_advanced)
        .orderBy('order_of_display', 'asc')
        .map((control) => adjustControlConditionalVisibility(control, destinationConnectorConfig.value)?.control || null)
        .compact()
        .value();
});

const enableSave = computed(() => {
    if (props.connector && destinationConnectorConfig && connectionName.value.length > 0) {
        return destinationConnectorConfig.value.reduce((valid, { name, display_in_ui }) => {
            return display_in_ui ? valid && connection.value[name] && connection.value[name].valid : true;
        }, true);
    } else {
        return false;
    }
});

async function getDestinationsConnectors() {
    var results = [];

    const values = await destinations.fetchConnectors();

    Object.values(values || {})
        .flat()
        .forEach((value) => {
            const item = { name: value.display_name, connector: value.connector };
            results.push(item);
        });

    return _.orderBy(results, 'name', ['asc']);
}

const getConnectorType = (destinationConnector) => {
    const destinationConnectors = destinations.destinationConnectors;
    const connector = Object.values(destinationConnectors)
        .flat()
        .find((x) => x.connector == destinationConnector);

    if (!connector?.status) {
        return '';
    }

    const arr = connector?.status?.split(' ');

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const firstLetterUpper = arr.join(' ');

    return firstLetterUpper == 'Beta' ? firstLetterUpper : '';
};

const getConnectorTypeColor = (connector) => {
    const destinationType = getConnectorType(connector);

    var result = '#F3F4F6';
    switch (destinationType) {
        // case "Published":
        //     result = "rgb(220, 252, 231)";
        //     break;
        case 'Beta':
            result = 'rgb(254 249 195)';
            break;
        default:
            break;
    }
    return result;
};

const getClass = (control, index, advanced = false) => {
    const spaceLeft = control.space_left;
    const usedConnectors = advanced ? visibleAdvancedConnectorConfigControls.value : visibleBasicConnectorConfigControls.value;

    const nextControlSpaceLeft = usedConnectors[index + 1]?.space_left;
    return spaceLeft ? (!nextControlSpaceLeft ? 'pl-5 mb-7' : 'pl-5 mb-3') : 'mb-3';
};
</script>

<template>
    <div class="min-h-screen">
        <div class="page-header">
            <AppHeader gap="''" :show-title="false">
                <template #titleLeft>
                    <nav class="text-gray-700" aria-label="Breadcrumb">
                        <ol class="list-none p-0 inline-flex font-semibold">
                            <li class="flex items-center">
                                <router-link to="/connectors?tab=Destinations" class="text-green-400 hover:text-green-500">Destinations</router-link>
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                <router-link to="/connectors/add?tab=Destinations" class="text-green-400 hover:text-green-500"
                                    >Add Destination</router-link
                                >
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />

                                <span v-if="selectedTitle" class="text-gray-600">{{ selectedTitle }}</span>
                                <div v-else class="snippet mt-2 ml-2 flex items-baseline" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </template>
            </AppHeader>
        </div>

        <AppLoader :force-loading="loadingList" default-height="75vh">
            <!-- <div v-if="!props.connector">
            <ConnectorList v-if="allDestinationConnectors.length > 0" :connectors="allDestinationConnectors" @select="onConnectorSelected" />
            <div v-else>0 destination connectors found.</div>
        </div> -->
            <div>
                <div v-if="formError" class="flex flex-col text-md font-semibold items-end mr-1">
                    <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'create-destination'" />
                </div>

                <div v-if="destinationConnectorConfig" class="flex flex-row justify-center w-full">
                    <div class="w-full">
                        <div class="flex justify-between items-center mb-3">
                            <div class="flex flex-row gap-2 items-center">
                                <img
                                    class="self-start h-12 w-12 flex-shrink-0"
                                    :src="`/icons/${props.connector}.svg`"
                                    :alt="`${props.connector} icon`" />
                                <span class="text-lg font-medium text-gray-800">{{ selectedTitle }}</span>
                            </div>
                            <div v-if="getConnectorType(props.connector)">
                                <span
                                    class="inline-flex items-center rounded-full px-4 py-2 text-sm font-medium uppercase shadow-sm"
                                    :style="`background: ${getConnectorTypeColor(props.connector)};`">
                                    {{ getConnectorType(props.connector) }}
                                </span>
                            </div>
                        </div>
                        <div class="bg-white p-4 shadow sm:rounded-lg sm:px-10 overflow-y-auto" style="max-height: 80vh">
                            <form
                                class="grid grid-cols-1 gap-y-3 sm:grid-cols-1 sm:gap-x-8 mt-5"
                                action="#"
                                method="POST"
                                autocomplete="new-password">
                                <div class="mb-3">
                                    <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                                    <div class="flex items-center mt-1">
                                        <div class="flex-1" :class="hideLateralDescriptionValue ? 'mr-2' : 'mr-4'">
                                            <input
                                                id="name"
                                                v-model="connectionName"
                                                aria-label="input"
                                                name="name"
                                                type="text"
                                                autocomplete="new-password"
                                                required=""
                                                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm" />
                                        </div>

                                        <div
                                            v-if="!hideLateralDescriptionValue"
                                            class="flex-1 p-3 bg-green-50 border-l-4 border-green-200 text-xs text-black rounded-md relative arrow-left">
                                            The name of the destination
                                        </div>

                                        <Tooltip v-else tooltip-text="The name of the destination" />
                                    </div>
                                </div>

                                <div v-for="(control, index) in visibleBasicConnectorConfigControls" :key="index" class="mb-3">
                                    <div v-show="control.display_in_ui" :class="getClass(control, index)">
                                        <DynamicField
                                            :key="control.name"
                                            :config="control"
                                            :hide-lateral-description="false"
                                            @input="onFieldChanged" />
                                    </div>
                                </div>

                                <div v-if="visibleAdvancedConnectorConfigControls.length > 0">
                                    <div>
                                        <h2 class="mb-0">
                                            <div
                                                class="group relative flex w-full items-center font-medium p-2 rounded-t-lg text-md cursor-pointer hover:bg-green-50 transition-all duration-300 ease-in-out"
                                                :class="advancedMode ? 'bg-green-50' : ''"
                                                type="button"
                                                @keydown.enter="advancedMode = !advancedMode"
                                                :aria-expanded="advancedMode"
                                                role="button"
                                                @click="advancedMode = !advancedMode">
                                                Advanced
                                                <span class="ml-auto h-5 w-5 shrink-0 transition-transform duration-200 ease-in-out">
                                                    <ChevronUpIcon v-show="!advancedMode" class="h-5 w-5 text-gray-600"></ChevronUpIcon>
                                                    <ChevronDownIcon v-show="advancedMode" class="h-5 w-5 text-gray-600"></ChevronDownIcon>
                                                </span>
                                            </div>
                                        </h2>
                                        <div
                                            v-show="advancedMode"
                                            class="grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-8 mb-5"
                                            style="max-height: 45vh"
                                            :class="advancedMode ? 'border p-5 pt-8' : 'p-3'">
                                            <div v-for="(control, index) in visibleAdvancedConnectorConfigControls" :key="index">
                                                <div v-show="control.display_in_ui" :class="getClass(control, index, true)">
                                                    <DynamicField
                                                        :key="control.name"
                                                        :config="control"
                                                        :hide-lateral-description="false"
                                                        @input="onFieldChanged" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </AppLoader>
    </div>
    <AppFooter v-if="destinationConnectorConfig">
        <template #right>
            <RowActionMenu
                :show-save="true"
                :show-discard="false"
                :disable-save="!enableSave || loading"
                :loading="loading"
                @save="onSaveDestination" />
        </template>
    </AppFooter>
</template>

<style>
.arrow-left::before {
    content: '';
    position: absolute;
    left: -12px; /* Adjusted for the larger arrow */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent; /* increased for a taller arrow */
    border-bottom: 18px solid transparent; /* increased for a taller arrow */
    border-right: 12px solid #bbf7d0; /* adjusted to match the border color of the blue container */
}
</style>
