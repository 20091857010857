import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';

import Config from '@/utils/config';
import { useAuthStore } from '../stores/auth.store';

class SentryService {
    constructor() {}

    setup(app, router) {
        Sentry.init({
            app,
            dsn: Config.sentry.appUrl,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: Config.sentry.tracingOrigins
                }),
                new Sentry.Replay({
                    maskAllText: false,
                    maskAllInputs: false
                })
            ],
            replaysSessionSampleRate: Config.app.isProd ? 0.1 : 1.0,
            replaysOnErrorSampleRate: 1.0,
            tracesSampleRate: Config.sentry.tracesSampleRate,
            release: Config.app.version,
            environment: Config.app.env,
            beforeSend(event) {
                // Modify the event here
                return event;
            }
        });
    }

    async init() {
        const authStore = useAuthStore();
        const user = authStore.user;
        const organisation = authStore.organisation;

        return new Promise((resolve) => {
            if (Config.app.isProd && user && organisation) {
                Sentry.setUser({
                    id: user.id,
                    email: user.email,
                    username: user.fullName,
                    ip_address: '{{auto}}',
                    organisationId: organisation.tenantId,
                    organisationName: organisation.name
                });
                resolve('loaded');
            } else {
                resolve('skipped');
            }
        });
    }

    notify(err, event = {}) {
        if (Config.app.isLocal) console.warn(err, event);
        else {
            const severity = event.severity || 'info';
            const context = event.context || '';
            const tags = Object.assign({ context, severity }, event.metadata);

            Sentry.withScope((scope) => {
                scope.setTags(tags);
                scope.setLevel(severity);
                Sentry.captureException(err);
            });
        }
    }
}

export default new SentryService();
