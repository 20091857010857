import LogMessageDialog from "../components/LogMessageDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";

const useLogMessageDialog = (props) => {
    const { reveal } = createConfirmDialog(LogMessageDialog, props);

    reveal();
};

export default useLogMessageDialog;
