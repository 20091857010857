<script>
import { RouterLink } from 'vue-router';
import SideMenuProfileSection from './SideMenuProfileSection.vue';
import SideMenuUpdatesSection from './SideMenuUpdatesSection.vue';
import { AdminPortal, mapAuthActions } from '@frontegg/vue';
import AppHeader from './AppHeader.vue';
import _ from 'lodash';
import Config from '@/utils/config';
import { useNovuStore } from '@/stores/novu.store';
import { useServicesStore } from '@/stores/services.store';
import { useLoaderStore } from '@/stores/loader.store';

import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from '@headlessui/vue';
import {
    Bars3Icon,
    XMarkIcon,
    CloudArrowUpIcon,
    CloudArrowDownIcon,
    ArrowsUpDownIcon,
    ChevronUpDownIcon,
    BuildingOffice2Icon,
    CodeBracketSquareIcon,
    ExclamationCircleIcon,
    QueueListIcon,
    AdjustmentsVerticalIcon,
    ShareIcon,
    ChartBarIcon,
    ArrowPathIcon,
    ServerIcon
} from '@heroicons/vue/24/solid';

import { useAuthStore } from '@/stores/auth.store';
import { BellIcon } from '@heroicons/vue/24/outline';
import { Permissions } from '@/utils/constants';
import { hasAccess } from '@/utils/utils';

export default {
    name: 'SideMenu',
    components: {
        RouterLink,
        SideMenuProfileSection,
        Bars3Icon,
        XMarkIcon,
        ArrowPathIcon,
        CloudArrowUpIcon,
        CloudArrowDownIcon,
        ArrowsUpDownIcon,
        ChevronUpDownIcon,
        QueueListIcon,
        AdjustmentsVerticalIcon,
        ExclamationCircleIcon,
        // eslint-disable-next-line vue/no-reserved-component-names
        Dialog,
        DialogPanel,
        TransitionChild,
        ServerIcon,
        TransitionRoot,
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        BuildingOffice2Icon,
        BellIcon,
        AppHeader,
        SideMenuUpdatesSection
    },
    data() {
        return {
            switchTenant: mapAuthActions('switchTenant'),
            ...this.mapAuthState(),
            // navigation: null,
            sidebarOpen: true,
            authStore: useAuthStore(),
            subscriberId: null,
            image: '/streamkap.svg',
            Config,
            novuStore: useNovuStore(),
            servicesStore: useServicesStore(),
            loaderStore: useLoaderStore(),
            servicesLoading: false
        };
    },
    computed: {
        isOrgAdmin() {
            return this.authStore.isOrgAdmin;
        },
        imageSrc() {
            return new URL(this.image, import.meta.url).href;
        },
        isStreamkapUser() {
            return this.authStore.isStreamkapUser;
        },
        isCustomerTenant() {
            return this.authStore.isCustomerTenant;
        },
        availableTenants() {
            return _.get(this.authState, ['tenantsState', 'tenants'], []);
        },
        selectedTenant() {
            return (
                _.find(this.availableTenants, {
                    tenantId: this.authState.user?.tenantId
                }) || {}
            );
        },
        href() {
            return window.location.href;
        },
        navigation() {
            const currentUrl = this.$route.path;
            const unreadCount = this.novuStore.userUnreadNotificationsCount;

            const nav = [
                {
                    name: 'Dashboard',
                    id: 'dashboard',
                    href: 'dashboard',
                    icon: ChartBarIcon,
                    current: currentUrl.includes('dashboard') || currentUrl.endsWith('/')
                },
                {
                    name: 'Pipelines',
                    id: 'pipelines',
                    href: 'pipelines',
                    icon: ArrowsUpDownIcon,
                    current: currentUrl.includes('pipelines'),
                    accessRules: {
                        permissions: {
                            values: [Permissions.READ_PIPELINES, Permissions.READ_PIPELINES_METRICS],
                            type: 'and'
                        }
                    }
                },
                {
                    name: 'Connectors',
                    id: 'connectors',
                    href: 'connectors',
                    icon: ShareIcon,
                    current: currentUrl.includes('connector'),
                    accessRules: {
                        permissions: {
                            values: [
                                Permissions.READ_DESTINATIONS,
                                Permissions.READ_SOURCES,
                                Permissions.READ_SOURCE_METRICS,
                                Permissions.READ_DESTINATION_METRICS
                            ],
                            type: 'or'
                        }
                    }
                },
                {
                    name: 'Transforms',
                    id: 'transforms',
                    href: 'transforms',
                    icon: AdjustmentsVerticalIcon,
                    current: currentUrl.includes('transforms'),
                    accessRules: {
                        permissions: {
                            values: [Permissions.READ_TRANSFORMS, Permissions.READ_TRANSFORM_METRICS],
                            type: 'and'
                        }
                    }
                },
                {
                    name: 'Topics',
                    id: 'topics',
                    href: 'topics',
                    icon: QueueListIcon,
                    current: currentUrl.includes('topics'),
                    accessRules: {
                        permissions: {
                            values: [Permissions.READ_TOPICS, Permissions.READ_TOPIC_METRICS],
                            type: 'and'
                        }
                    }
                },
                {
                    name: 'Logs',
                    id: 'logs',
                    href: 'logs',
                    icon: CodeBracketSquareIcon,
                    current: currentUrl.includes('logs'),
                    accessRules: {
                        permissions: {
                            values: [Permissions.READ_SOURCE_METRICS, Permissions.READ_DESTINATION_METRICS],
                            type: 'and'
                        }
                    }
                },
                // {
                //     name: 'Services',
                //     id: 'services',
                //     href: 'services',
                //     icon: ServerIcon,
                //     current: currentUrl.includes('services')
                // },
                {
                    name: 'Alerts',
                    id: 'alerts',
                    href: 'alerts',
                    icon: ExclamationCircleIcon,
                    unreadCount,
                    current: currentUrl.includes('alerts'),
                    accessRules: {
                        permissions: {
                            values: [Permissions.READ_ALERTS, Permissions.WRITE_ALERTS],
                            type: 'or'
                        }
                    }
                }
            ];

            return nav
                .filter((n) => (n.onlyDev ? this.Config.app.isDev && !n.hide : !n.hide))
                .filter((n) => {
                    return hasAccess(this.authStore.user, n.accessRules || {});
                });
        }
    },
    methods: {
        showAdminPortal(url) {
            AdminPortal.show();
            setTimeout(() => (window.location.href = url), 1);
        },
        async switchOrganisation(tenantId) {
            if (this.selectedTenant.tenantId !== tenantId) {
                this.switchTenant({ tenantId });

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        },
        notificationSessionLoaded() {}
    }
};
</script>

<template>
    <div class="flex h-screen overflow-auto bg-gray-100">
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="relative z-40 sm:hidden" aria-label="side-menu" @close="sidebarOpen = false">
                <!-- Backdrop -->
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
                </TransitionChild>

                <!-- Sidebar Panel -->
                <div class="fixed inset-0 z-40 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full">
                        <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white border-r border-gray-200 rounded-r-xl">
                            <!-- Close Button -->
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    aria-label="button"
                                    type="button"
                                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    @click="sidebarOpen = false">
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon class="h-6 w-6 text-white" />
                                </button>
                            </div>
                            <div class="flex-1 overflow-y-auto py-4">
                                <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                                    <!--Logo -->
                                    <div class="w-100 flex items-center justify-center pb-3">
                                        <img :src="imageSrc" style="width: 10rem" alt="Logo" />
                                    </div>
                                    <small v-can-access.permissions="$Permissions.READ_SERVICES">
                                        <span
                                            class="flex flex-col w-full items-center justify-center text-gray-500"
                                            :class="isStreamkapUser ? 'gap-2' : 'gap-1'">
                                            <span class="flex items-center w-full justify-center gap-1">
                                                {{ selectedTenant?.name || 'Loading...' }}

                                                <small
                                                    v-if="isStreamkapUser"
                                                    class="inline-flex items-center rounded-full px-2.5 font-medium py-0.5 text-gray-800 shadow"
                                                    :class="isCustomerTenant ? 'bg-orange-300' : ' bg-purple-300'">
                                                    <span> {{ isCustomerTenant ? 'Customer' : 'Internal' }}</span>
                                                </small>
                                            </span>
                                            <small
                                                v-if="!loaderStore.isLoading('services.fetch') && servicesStore.current"
                                                class="inline-flex items-center justify-center rounded-full px-2.5 font-medium py-0.5 text-gray-800 shadow bg-green-300">
                                                <span>{{ servicesStore.current?.name }}</span>
                                            </small>
                                            <div v-else class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                        </span>
                                    </small>
                                </div>

                                <nav class="mt-10 space-y-1 px-2">
                                    <RouterLink
                                        v-for="item in navigation"
                                        :key="item.name"
                                        :to="{ name: item.href }"
                                        :class="[
                                            item.current
                                                ? 'text-gray-700 bg-gray-100 border-[1px] hover:text-gray-700'
                                                : 'text-gray-600 hover:bg-gray-50 hover:font-medium hover:text-gray-700',
                                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                        ]">
                                        <component
                                            :is="item.icon"
                                            :class="[
                                                item.current ? 'font-medium text-green-400 fill-green-400' : 'text-gray-600',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            ]" />
                                        <div class="flex flex-col">
                                            {{ item.name }}
                                            <span
                                                v-if="item.comingSoon"
                                                class="text-xs font-normal text-gray-500"
                                                :class="item.current ? 'text-gray-600' : ''"
                                                >Coming soon</span
                                            >
                                        </div>
                                        <span
                                            v-if="item.unreadCount > 0"
                                            class="ml-2 inline-flex items-center justify-center px-1.5 text-[0.5rem] font-medium leading-none text-white bg-red-500 rounded-full"
                                            :class="item.unreadCount > 9 ? 'py-1.5' : 'py-1'">
                                            {{ item.unreadCount }}
                                        </span>
                                    </RouterLink>
                                </nav>
                            </div>

                            <!-- <div class="flex flex-col xl:flex-col p-5">
                <button aria-label="button"
                  type="button"
                  class="mt-3 inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700
                    shadow-sm hover:bg-gray-50 sm:mt-0 xl:ml-0 xl:mt-3 xl:w-full"
                  @click="showAdminPortal('#/admin-box/users')"
                >
                  Invite Team
                </button>
              </div> -->

                            <SideMenuUpdatesSection is-mobile />
                            <SideMenuProfileSection is-mobile />
                        </DialogPanel>
                    </TransitionChild>
                    <div class="w-14 flex-shrink-0">
                        <!-- Force sidebar to shrink to fit close icon -->
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div
            class="hidden sm:block sm:fixed sm:inset-y-0 sm:flex sm:flex-col bg-white border-r border-gray-200 shadow-xl 2xl:w-64 w-52"
            style="z-index: 300 !important">
            <!-- Sidebar component -->
            <div class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
                <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                    <!--Logo -->
                    <div class="w-100 flex items-center justify-center pb-3">
                        <img :src="imageSrc" style="width: 10rem" alt="Logo" />
                    </div>
                    <small>
                        <span class="flex flex-col w-full items-center justify-center text-gray-500" :class="isStreamkapUser ? 'gap-2' : 'gap-1'">
                            <span class="flex items-center w-full justify-center gap-1">
                                {{ selectedTenant?.name || 'Loading...' }}

                                <small
                                    v-if="isStreamkapUser"
                                    class="inline-flex items-center rounded-full px-2.5 font-medium py-0.5 text-gray-800 shadow"
                                    :class="isCustomerTenant ? 'bg-orange-300' : ' bg-purple-300'">
                                    <span> {{ isCustomerTenant ? 'Customer' : 'Internal' }}</span>
                                </small>
                            </span>
                            <small
                                v-if="!loaderStore.isLoading('services.fetch') && servicesStore.current"
                                class="inline-flex items-center justify-center rounded-full px-2.5 font-medium py-0.5 text-gray-800 shadow bg-green-300">
                                <span>{{ servicesStore.current?.name }}</span>
                            </small>
                            <div v-else class="snippet ml-3" data-title=".dot-flashing">
                                <div class="stage">
                                    <div class="dot-flashing" />
                                </div>
                            </div>
                        </span>
                    </small>
                    <nav class="mt-10 flex-1 space-y-1 bg-white px-2">
                        <RouterLink
                            v-for="item in navigation"
                            :key="item.name"
                            :to="{ name: item.href }"
                            :class="[
                                item.current
                                    ? 'text-gray-700 bg-gray-100 border-[1px] hover:text-gray-700'
                                    : 'text-gray-600 hover:bg-gray-50 hover:font-medium hover:text-gray-700',
                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                            ]">
                            <component
                                :is="item.icon"
                                :class="[
                                    item.current ? 'font-medium text-green-400 fill-green-400' : 'text-gray-600',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                ]" />
                            <div class="flex flex-col">
                                {{ item.name }}
                                <span v-if="item.comingSoon" class="text-xs font-normal text-gray-500" :class="item.current ? 'text-gray-600' : ''"
                                    >Coming soon</span
                                >
                            </div>
                            <span
                                v-if="item.unreadCount > 0"
                                class="ml-2 inline-flex items-center justify-center px-1.5 text-[0.5rem] font-medium leading-none text-white bg-red-500 rounded-full"
                                :class="item.unreadCount > 9 ? 'py-1.5' : 'py-1'">
                                {{ item.unreadCount }}
                            </span>
                        </RouterLink>
                    </nav>
                </div>

                <!-- <div class="flex flex-col xl:flex-col p-5">
          <button aria-label="button"
            type="button"
            class="mt-3 inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700
                    shadow-sm hover:bg-gray-50 sm:mt-0 xl:ml-0 xl:mt-3 xl:w-full"
            @click="showAdminPortal('#/admin-box/users')"
          >
            Invite Team
          </button>

                                      :user="{
                                id: currentUser.id,
                                email: currentUser.email,
                                name: currentUser.firstName + ' ' + currentUser.lastName,
                                tenant: {
                                    id: currentUser.tenant.tenantId,
                                    name: currentUser.tenant.name
                                }
                            }"
                            
        </div> -->

                <SideMenuUpdatesSection />

                <SideMenuProfileSection />
            </div>
        </div>
        <!-- Pages Layout -->
        <div class="flex flex-1 flex-col min-w-full 2xl:pl-64 sm:pl-52">
            <div class="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 sm:hidden">
                <button
                    aria-label="button"
                    type="button"
                    class="inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-600 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    @click="sidebarOpen = true">
                    <an class="sr-only">Open sidebar</an>
                    <Bars3Icon class="h-6 w-6" />
                </button>
            </div>

            <!-- Content -->

            <main class="flex-1 bg-gray-100 px-6 pt-4">
                <div class="mx-auto">
                    <slot />
                </div>
            </main>
        </div>
    </div>
</template>
