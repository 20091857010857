/* eslint-disable no-unused-vars */
import { useWebSocket } from '@vueuse/core';
import { useAuthStore } from '../stores/auth.store';
import Config from '../utils/config';
import SentryService from './sentry.service';
import _ from 'lodash';

class WebSocketService {
    #socket = null;
    #topics = {};
    #subscribersQueue = [];
    #messagesQueue = [];

    #allMessagesHistory = [];

    constructor() {}

    init() {
        this.close();
        const authStore = useAuthStore();
        const authProtocol = `Authorization#${authStore.accessToken}`;

        this.#socket = useWebSocket(`${Config.app.wsUrl}`, {
            autoReconnect: {
                retries: 10,
                delay: 1000,
                onFailed: () => {
                    console.log('WebSocket: Failed to connect after 10 retries');
                    SentryService.notify(new Error('WebSocket: Failed to connect after 10 retries'), { context: 'websockets', severity: 'error' });
                }
            },
            protocols: [authProtocol],
            onConnected: () => {
                console.log('WebSocket: connected!');
                this.processSubscribersQueue();

                // if no messages in the queue, but connected, we either reconnected or just connected
                // if we reconnected, we need to process all messages historically in the allMessagesHistory
                // if we just connected, we need to process all messages in the queue (if any)
                if (!this.#messagesQueue?.length) {
                    this.processHistoricalMessagesQueue();
                } else {
                    this.processMessagesQueue();
                }
            },
            onDisconnected: () => console.log('WebSocket: disconnected!'),
            onError: (_ws, event) => console.error('Websocket: error!', event),
            onMessage: (_ws, message) => this.onMessage(JSON.parse(message.data))
        });
    }

    onMessage(message) {
        if (this.#topics[message.type]) {
            this.#topics[message.type].forEach((listenerInfo) => {
                const fromWebSocket = true;
                listenerInfo.callback(message.data, {}, fromWebSocket);
            });
        }
    }

    open() {
        if (this.#socket && this.#socket.status.value === 'CLOSED') {
            this.#socket.open();
        }
    }

    close() {
        if (this.#socket && this.#socket.status.value !== 'CLOSED') {
            this.#socket.close();
        }
    }

    addListener(topic, entityType = null, entityId = null, callback, callbackId, startListFrom, listSize) {
        console.log('WebSocket: adding listener..', topic);

        const listenerInfo = {
            callback,
            callbackId,
            entityType,
            entityId,
            startListFrom,
            listSize
        };

        if (!this.#topics[topic]) {
            this.#topics[topic] = [listenerInfo];
        } else {
            const isDuplicate = this.#topics[topic].some(
                (item) =>
                    item.callbackId === callbackId &&
                    item.entityType === entityType &&
                    item.entityId === entityId &&
                    item.startListFrom === startListFrom &&
                    item.listSize === listSize
            );

            if (!isDuplicate) {
                this.#topics[topic].push(listenerInfo);
            }
        }

        this.sendListenerMessage(topic, listenerInfo, 'subscribe');
    }

    removeAllListeners() {
        console.log('WebSocket: removing all listeners');

        for (const topic in this.#topics) {
            const listenersInfo = this.#topics[topic];

            if (listenersInfo && listenersInfo.length > 0) {
                listenersInfo.forEach((listenerInfo) => {
                    this.sendListenerMessage(topic, listenerInfo, 'unsubscribe');
                });
            }
        }

        this.#topics = {};
    }

    removeListener(topic, entityType = null, entityId = null, callbackId, startListFrom, listSize) {
        console.log('WebSocket: removing listener..', topic);

        if (this.#topics[topic]) {
            this.#topics[topic] = this.#topics[topic].filter((c) => c.callbackId !== callbackId);

            if (this.#topics[topic].length === 0) {
                this.sendListenerMessage(topic, { entityType, entityId, startListFrom, listSize }, 'unsubscribe');
            }
        }
    }

    sendMessage(topic, message) {
        if (this.#socket && this.#socket.send) {
            const cleanedMessage = Object.fromEntries(Object.entries(message || {}).filter(([_, v]) => v !== null));
            const payload = JSON.stringify({ type: topic, ...cleanedMessage });

            console.log('WebSocket: sending message..', payload);
            this.#socket.send(payload);
        } else {
            console.log('WebSocket: adding message to the queue (websocket not finished being initialized yet)..', { topic, message });
            this.#messagesQueue.push({ topic, message });
        }
    }

    processSubscribersQueue() {
        if (this.#subscribersQueue && this.#subscribersQueue.length > 0) {
            console.log('WebSocket: processing all messages in the SubscribersQueue..');

            for (const subMsg of this.#subscribersQueue) {
                this.sendMessage(subMsg.topic, {
                    action: subMsg.action,
                    entity_type: subMsg.entity_type,
                    entity_id: subMsg.entity_id,
                    topic_list_from: subMsg.topic_list_from,
                    topic_list_size: subMsg.topic_list_size
                });
            }

            this.#subscribersQueue = [];
        }
    }

    processMessagesQueue() {
        if (this.#messagesQueue && this.#messagesQueue.length > 0) {
            console.log('WebSocket: processing all messages in the MessagesQueue..');

            for (const msg of this.#messagesQueue) {
                this.sendMessage(msg.topic, msg.message);
            }

            this.#messagesQueue = [];
        }
    }

    processHistoricalMessagesQueue() {
        // remove message duplicates
        this.#allMessagesHistory = _.uniqBy(this.#allMessagesHistory, (msg) => JSON.stringify(msg));

        if (this.#allMessagesHistory && this.#allMessagesHistory.length > 0) {
            console.log('WebSocket (post automatic reconnect): processing all messages historically in the allMessagesHistory..');

            for (const msg of this.#allMessagesHistory) {
                this.sendMessage(msg.topic, msg.message);
            }
        }
    }

    sendListenerMessage(topic, listenerInfo, action) {
        const message = {
            action,
            entity_type: listenerInfo.entityType,
            entity_id: listenerInfo.entityId,
            topic_list_from: listenerInfo.startListFrom,
            topic_list_size: listenerInfo.listSize
        };

        if (action === 'subscribe') {
            this.#allMessagesHistory.push({ topic, message });
        }

        this.sendMessage(topic, message);
    }
}

export default new WebSocketService();
