<template>
    <!-- Main List Container -->
    <ul class="tenant-list">
        <li v-for="item in props.items" :key="item.tenant_id" :class="getItemClass(item)">
            <a href="#" class="item-link ml-5" @click="input(item)">
                {{ item.tenant_name }}
            </a>
            <!-- Nested Items -->
            <ul v-if="item.children && item.children.length" class="nested-list">
                <TenantChildren :items="item.children" :selected-tenant="selectedTenant" @input="input" />
            </ul>
        </li>
    </ul>
</template>

<script setup>
import TenantChildren from './TenantChildren.vue';

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    selectedTenant: {
        type: Object
    },
    hasParent: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['input']);

const getItemClass = (item) => {
    return `block py-2 text-sm ${item.tenant_id === props.selectedTenant.tenant_id ? 'bg-gray-100 font-medium text-gray-600' : 'text-gray-600'}`;
};

const input = (item) => {
    emit('input', item);
};
</script>

<style scoped>
.tenant-list {
    list-style-type: none; /* Remove default list styling */
    padding-left: 0; /* Remove default padding */
}

.item-link::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 5px; /* Dot size */
    height: 5px; /* Dot size */
    background-color: #8b9db8; /* Dot color */
    border-radius: 50%; /* Make it round */
    transform: translateY(-50%);
}

.nested-list {
    margin-top: 10px; /* Adjust as needed */
    margin-left: 10px; /* Adjust indent for nested items */
}

.item-link {
    position: relative;
    padding-left: 15px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
