export const NO_SNAPSHOT_CONNECTORS = ['elasticsearch', 'kafkadirect'];

// enums/Permissions.js
export const Permissions = Object.freeze({
    // Sources
    READ_LIST_SOURCES: 'read:list_sources', // List Sources
    READ_SOURCE_CONFIGURATION: 'read:source_configuration', // Read Source Configs
    READ_SOURCES: 'read:sources', // Read Sources
    WRITE_SOURCES: 'write:sources', // Write Sources
    DELETE_SOURCES: 'delete:sources', // Delete Sources
    READ_SOURCE_METRICS: 'read:source_metrics', // Read Source Metrics
    TRIGGER_ACTION_SOURCES: 'trigger_action:sources', // Trigger Action on Source
    // Destinations
    READ_LIST_DESTINATIONS: 'read:list_destinations', // List Destinations
    READ_DESTINATION_CONFIGURATION: 'read:destination_configuration', // Read Destination Configs
    READ_DESTINATIONS: 'read:destinations', // Read Destinations
    WRITE_DESTINATIONS: 'write:destinations', // Write Destinations
    DELETE_DESTINATIONS: 'delete:destinations', // Delete Destinations
    READ_DESTINATION_METRICS: 'read:destination_metrics', // Read Destination Metrics
    TRIGGER_ACTION_DESTINATIONS: 'trigger_action:destinations', // Trigger Action on Destination

    // Topics
    READ_TOPICS: 'read:topics', // Read Topics
    READ_TOPIC_METRICS: 'read:topic_metrics', // Read Topic Metrics

    // Transforms
    WRITE_TRANSFORMS: 'write:transforms', // Write Transforms
    READ_TRANSFORMS: 'read:transforms', // Read Transforms
    READ_TRANSFORM_METRICS: 'read:transform_metrics', // Read Transform Metrics
    // Pipelines
    READ_PIPELINES: 'read:pipelines', // Read Pipelines
    READ_PIPELINES_METRICS: 'read:pipeline_metrics', // Read Pipelines Metrics
    WRITE_PIPELINES: 'write:pipelines', // Write Pipelines
    DELETE_PIPELINES: 'delete:pipelines', // Delete Pipelines
    // alerts
    READ_ALERTS: 'read:alerts', // Read Notifications
    WRITE_ALERTS: 'write:alerts', // Write Notifications\

    // notifications
    READ_NOTIFICATIONS: 'read:notifications', // Read Notifications
    WRITE_NOTIFICATIONS: 'write:notifications', // Write Notifications

    // Organisation
    READ_ORGANISATION: 'read:organisation', // Read Organisation
    // Services
    READ_SERVICES: 'read:services', // Read Services
    SWITCH_SERVICE: 'switch:service', // Switch Services

    // Logs
    READ_LOGS: 'read:logs', // Read Logs

    // integrations
    READ_INTEGRATIONS: 'read:integrations', // Read Integrations
    // Connectivity
    FE_CONNECTIVITY: 'fe.connectivity.*', // All connectivity permissions
    FE_CONNECTIVITY_READ_EMAIL_CONFIG: 'fe.connectivity.read.emailConfig', // Read email integration configurations
    FE_CONNECTIVITY_DELETE_BELL_NOTIFICATIONS: 'fe.connectivity.delete.bellNotifications', // Delete user bell notifications
    FE_CONNECTIVITY_READ_SLACK_USERS: 'fe.connectivity.read.slackUsers', // View registered Slack workspace users
    FE_CONNECTIVITY_READ_SLACK_APP: 'fe.connectivity.read.slackApp', // Read Slack application configurations
    FE_CONNECTIVITY_WRITE_SUBSCRIBE_WEBPUSH: 'fe.connectivity.write.subscribeWebpush', // Subscribe to webpush notifications
    FE_CONNECTIVITY_READ_SLACK_CHANNELS: 'fe.connectivity.read.slackChannels', // View registered Slack workspace channels
    FE_CONNECTIVITY_DELETE_WEBHOOK: 'fe.connectivity.delete.webhook', // Delete webhook configurations
    FE_CONNECTIVITY_READ_EVENT_CATEGORIES: 'fe.connectivity.read.eventCategories', // View connectivity event categories
    FE_CONNECTIVITY_DELETE_SLACK_SUBSCRIPTIONS: 'fe.connectivity.delete.slackSubscriptions', // Delete Slack subscriptions to events
    FE_CONNECTIVITY_READ_SMS_CONFIG: 'fe.connectivity.read.smsConfig', // View SMS integration configurations
    FE_CONNECTIVITY_DELETE_SMS_CONFIG: 'fe.connectivity.delete.smsConfig', // Delete SMS integration configurations
    FE_CONNECTIVITY_WRITE_EMAIL_CONFIG: 'fe.connectivity.write.emailConfig', // Create email integration configurations
    FE_CONNECTIVITY_WRITE_SLACK_SUBSCRIPTIONS: 'fe.connectivity.write.slackSubscriptions', // Create Slack subscriptions to events
    FE_CONNECTIVITY_WRITE_TRIGGER_EVENT: 'fe.connectivity.write.triggerEvent', // Trigger events that run integrations
    FE_CONNECTIVITY_READ_USER_BELL_NOTIFICATIONS: 'fe.connectivity.read.userBellNotifications', // View user bell notifications
    FE_CONNECTIVITY_WRITE_SLACK_APP_REGISTRATION: 'fe.connectivity.write.slackAppRegistration', // Register Slack application
    FE_CONNECTIVITY_WRITE_SEND_WEBPUSH_NOTIFICATION: 'fe.connectivity.write.sendWebpushNotification', // Create webpush notifications
    FE_CONNECTIVITY_DELETE_SLACK_APP_REGISTRATION: 'fe.connectivity.delete.slackAppRegistration', // Delete Slack app registration
    FE_CONNECTIVITY_WRITE_UPDATE_SLACK_SUBSCRIPTION: 'fe.connectivity.write.updateSlackSubscription', // Update Slack subscriptions
    FE_CONNECTIVITY_DELETE_SLACK_SUBSCRIPTION_EVENT: 'fe.connectivity.delete.slackSubscriptionEvent', // Delete events from Slack subscription
    FE_CONNECTIVITY_READ_WEBHOOKS: 'fe.connectivity.read.webhooks', // View webhook configurations
    FE_CONNECTIVITY_READ_WEBHOOK_LOGS: 'fe.connectivity.read.webhookLogs', // View webhook logs
    FE_CONNECTIVITY_READ_EVENTS: 'fe.connectivity.read.events', // View connectivity events
    FE_CONNECTIVITY_READ_SLACK_SUBSCRIPTIONS: 'fe.connectivity.read.slackSubscriptions', // View Slack event subscriptions
    FE_CONNECTIVITY_READ: 'fe.connectivity.read.*', // All connectivity read permissions
    FE_CONNECTIVITY_WRITE: 'fe.connectivity.write.*', // All connectivity write permissions
    FE_CONNECTIVITY_DELETE_EMAIL_CONFIG: 'fe.connectivity.delete.emailConfig', // Delete email integration configurations
    // Secure
    FE_SECURE: 'fe.secure.*', // All secure access permissions
    FE_SECURE_DELETE_GROUPS_USERS: 'fe.secure.delete.groupsUsers', // Remove users from any group
    FE_SECURE_DELETE_EMAIL_DOMAIN_RESTRICTIONS: 'fe.secure.delete.emailDomainRestrictions', // Delete email domain restrictions
    FE_SECURE_DELETE_SESSIONS: 'fe.secure.delete.sessions', // Revoke all user sessions
    FE_SECURE_READ_AUDITS: 'fe.secure.read.audits', // View audit logs
    FE_SECURE_DELETE_TENANT_API_TOKENS: 'fe.secure.delete.tenantApiTokens', // Delete account API tokens
    FE_SECURE_READ_SAML_DEFAULT_ROLES: 'fe.secure.read.samlDefaultRoles', // View SAML default role configuration
    FE_SECURE_WRITE_SECURITY_POLICY: 'fe.secure.write.securityPolicy', // Create or update security policies
    FE_SECURE_READ_USERS: 'fe.secure.read.users', // View all users in an account
    FE_SECURE_WRITE_USER_API_TOKENS: 'fe.secure.write.userApiTokens', // Create and update own API tokens
    FE_SECURE_READ_SECURITY_POLICY: 'fe.secure.read.securityPolicy', // View security policies
    FE_SECURE_WRITE_ACTOR_TOKEN: 'fe.secure.write.actorToken', // Create new or revoke existing actor tokens
    FE_SECURE_WRITE_GROUPS: 'fe.secure.write.groups', // Create or update any group
    FE_SECURE_READ_GROUPS: 'fe.secure.read.groups', // View all groups
    FE_SECURE_WRITE_DELEGATION: 'fe.secure.write.delegation', // Act on behalf of another user
    FE_SECURE_READ_ACCOUNT_SETTINGS: 'fe.secure.read.accountSettings', // View account settings
    FE_SECURE_DELETE_USERS: 'fe.secure.delete.users', // Delete users from account
    FE_SECURE_WRITE_USER_SUB_TENANTS: 'fe.secure.write.userSubTenants', // Invite users to sub-accounts
    FE_SECURE_DELETE_ROLE: 'fe.secure.delete.role', // Delete account roles
    FE_SECURE_WRITE_UPDATE_ROLE: 'fe.secure.write.updateRole', // Update account roles
    FE_SECURE_DELETE_USERS_ROLES: 'fe.secure.delete.usersRoles', // Delete roles from users in account
    FE_SECURE_DELETE_TENANT_INVITES: 'fe.secure.delete.tenantInvites', // Delete account invitations
    FE_SECURE_READ_ROLES: 'fe.secure.read.roles', // View vendor and account roles
    FE_SECURE_READ: 'fe.secure.read.*', // All secure access read permissions
    FE_SECURE_WRITE: 'fe.secure.write.*', // All secure access write permissions
    FE_SECURE_WRITE_IP_RESTRICTIONS: 'fe.secure.write.ipRestrictions', // Create new IP restriction and modify configuration
    FE_SECURE_DELETE_SAML_CONFIGURATION: 'fe.secure.delete.samlConfiguration', // Delete account SAML configuration
    FE_SECURE_WRITE_TENANT_API_TOKENS: 'fe.secure.write.tenantApiTokens', // Create or update account API tokens
    FE_SECURE_WRITE_USERS: 'fe.secure.write.users', // Add users to account
    FE_SECURE_DELETE_SECURITY_POLICY: 'fe.secure.delete.securityPolicy', // Delete security policies
    FE_SECURE_WRITE_PROVISIONING_CONFIGURATION: 'fe.secure.write.provisioningConfiguration', // Create new provisioning configurations
    FE_SECURE_DELETE_PROVISIONING_CONFIGURATION: 'fe.secure.delete.provisioningConfiguration', // Delete provisioning configurations
    FE_SECURE_READ_PROVISIONING_CONFIGURATION: 'fe.secure.read.provisioningConfiguration', // View provisioning configurations
    FE_SECURE_WRITE_SAML_CONFIGURATION: 'fe.secure.write.samlConfiguration', // Create and update account SAML configurations
    FE_SECURE_WRITE_GROUPS_ROLES: 'fe.secure.write.groupsRoles', // Edit roles of any group
    FE_SECURE_WRITE_RESEND_ACTIVATION_EMAIL: 'fe.secure.write.resendActivationEmail', // Resend activation emails to non-verified users
    FE_SECURE_WRITE_USERS_ROLES: 'fe.secure.write.usersRoles', // Add roles to users
    FE_SECURE_READ_EMAIL_DOMAIN_RESTRICTIONS: 'fe.secure.read.emailDomainRestrictions', // View domain restrictions and configuration
    FE_SECURE_READ_PERMISSIONS: 'fe.secure.read.permissions', // View permissions
    FE_SECURE_READ_USER_API_TOKENS: 'fe.secure.read.userApiTokens', // View own API tokens
    FE_SECURE_WRITE_SAML_DEFAULT_ROLES: 'fe.secure.write.samlDefaultRoles', // Write SAML default roles
    FE_SECURE_DELETE_GROUPS: 'fe.secure.delete.groups', // Delete any group
    FE_SECURE_DELETE_IP_RESTRICTIONS: 'fe.secure.delete.ipRestrictions', // Delete IP restrictions
    FE_SECURE_WRITE_UPDATE_USER: 'fe.secure.write.updateUser', // Update other users
    FE_SECURE_WRITE_ACCOUNT_SETTINGS: 'fe.secure.write.accountSettings', // Create or update account settings
    FE_SECURE_DELETE_TENANT_API_TOKENS: 'fe.secure.delete.tenantApiTokens', // Delete account API tokens
    FE_SECURE_DELETE_USERS_FROM_SUB_ACCOUNTS: 'fe.secure.delete.userSubTenants', // Delete users from sub-accounts
    FE_SECURE_DELETE_APPS_USERS: 'fe.secure.delete.appsUsers', // Remove any application from users
    FE_SECURE_WRITE_APPS_USERS: 'fe.secure.write.appsUsers', // Assign any application to users
    // Subscriptions
    FE_SUBSCRIPTIONS_WRITE: 'fe.subscriptions.write.*', // All subscriptions write permissions
    FE_SUBSCRIPTIONS_READ: 'fe.subscriptions.read.*', // All subscription read permissions
    FE_SUBSCRIPTIONS: 'fe.subscriptions.*', // All subscription permissions
    // Account Hierarchy
    FE_ACCOUNT_HIERARCHY_WRITE_SUB_ACCOUNT: 'fe.account-hierarchy.write.subAccount', // Create or update sub-accounts
    FE_ACCOUNT_HIERARCHY_DELETE_SUB_ACCOUNT: 'fe.account-hierarchy.delete.subAccount', // Delete sub-accounts
    FE_ACCOUNT_HIERARCHY_READ_SUB_ACCOUNT: 'fe.account-hierarchy.read.subAccount', // View sub-accounts
    FE_ACCOUNT_HIERARCHY_WRITE_SUB_ACCOUNT_ACCESS: 'fe.account-hierarchy.write.subAccountAccess', // Give a user access to sub-accounts
    FE_ACCOUNT_HIERARCHY_WRITE_SUB_ACCOUNT_MANAGEMENT: 'fe.account-hierarchy.write.subAccountManagement', // Update sub-accounts to allow sub-account management
    // Account Settings
    FE_ACCOUNT_SETTINGS_WRITE_CUSTOM_LOGIN_BOX: 'fe.account-settings.write.custom-login-box', // Write account custom login box styling
    FE_ACCOUNT_SETTINGS_DELETE_ACCOUNT: 'fe.account-settings.delete.account', // Delete my account
    FE_ACCOUNT_SETTINGS_READ_APP: 'fe.account-settings.read.app' // View all applications in the account
});

export const CONNECTORS_ICONS_NAME_MAPPING = {
    databricks: { possibleKeys: ['databricks'], displayName: 'Databricks' },
    starburst: { possibleKeys: ['starburst'], displayName: 'Starburst' },
    rockset: { possibleKeys: ['rockset'], displayName: 'Rockset' },
    db2: { possibleKeys: ['db2'], displayName: 'Db2' },
    clickhousesql: { possibleKeys: ['clickhousesql'], displayName: 'ClickHouse Upsert' },
    mysql: { possibleKeys: ['mysql'], displayName: 'MySQL' },
    postgresql: { possibleKeys: ['postgresql'], displayName: 'PostgreSQL' },
    oracle: { possibleKeys: ['oracle'], displayName: 'Oracle' },
    s3: { possibleKeys: ['s3'], displayName: 'S3' },
    redshift: { possibleKeys: ['redshift'], displayName: 'Redshift' },
    bigquery: { possibleKeys: ['bigquery'], displayName: 'BigQuery' },
    snowflake: { possibleKeys: ['snowflake'], displayName: 'Snowflake' },
    sqlserver: { possibleKeys: ['sqlserver'], displayName: 'SQL Server' },
    clickhouse: { possibleKeys: ['clickhouse'], displayName: 'Clickhouse' },
    elasticsearch: { possibleKeys: ['elasticsearch'], displayName: 'ElasticSearch' },
    dynamodb: { possibleKeys: ['dynamodb'], displayName: 'DynamoDB' },
    mongodbhosted: { possibleKeys: ['mongodbhosted'], displayName: 'MongoDB' },
    mongodb: { possibleKeys: ['mongodb'], displayName: 'MongoDB Atlas' },
    sqlserveraws: { possibleKeys: ['sqlserveraws'], displayName: 'SQL Server' },
    documentdb: { possibleKeys: ['documentdb'], displayName: 'DocumentDB' },
    oracleaws: { possibleKeys: ['oracleaws'], displayName: 'Oracle RDS' },
    cockroachdb: { possibleKeys: ['cockroachdb'], displayName: 'CockroachDB' },
    redis: { possibleKeys: ['redis'], displayName: 'Redis' },
    kafka: { possibleKeys: ['kafka'], displayName: 'Kafka' },
    kafkadirect: { possibleKeys: ['kafkadirect'], displayName: 'Kafka Direct' },
    azblob: { possibleKeys: ['azblob'], displayName: 'Azure Blob Storage' },
    vitess: { possibleKeys: ['vitess'], displayName: 'Vitess' }
};

export const Roles = Object.freeze({
    ADMIN: 'Admin',
    READ_ONLY: 'ReadOnly',
    SUB_ACCOUNTS: 'sub-accounts',
    BILLING: 'billing'
});

export const getConnectorIcon = (name) => {
    const lowerCaseName = name.toLowerCase();

    // Find the key where the name matches either any of the possible keys or the display name (case-insensitive)
    const foundKey = Object.keys(CONNECTORS_ICONS_NAME_MAPPING).find((key) => {
        const { possibleKeys, displayName } = CONNECTORS_ICONS_NAME_MAPPING[key];
        return possibleKeys.some((alias) => alias.toLowerCase() === lowerCaseName) || displayName.toLowerCase() === lowerCaseName;
    });

    return foundKey ? `/icons/${foundKey}.svg` : null;
};

export const getConnectorDisplayName = (name) => {
    const lowerCaseName = name?.toLowerCase();

    if (!lowerCaseName) return null;

    // Find the key where the name matches either any of the possible keys or the display name (case-insensitive)
    const foundKey = Object.keys(CONNECTORS_ICONS_NAME_MAPPING).find((key) => {
        const { possibleKeys, displayName } = CONNECTORS_ICONS_NAME_MAPPING[key];
        return possibleKeys.some((alias) => alias.toLowerCase() === lowerCaseName) || displayName.toLowerCase() === lowerCaseName;
    });

    return foundKey ? CONNECTORS_ICONS_NAME_MAPPING[foundKey].displayName : null;
};

export const DATE_FILTER_OPTIONS = [
    { label: 'All Time', value: 'allTime' },
    { label: 'Date', value: 'date' },
    { label: 'Between', value: 'dateRange' },
    { label: 'Today', value: 'today' },
    { label: 'Last 24h', value: 'last24h' },
    { label: 'Last 7 Days', value: 'last7Days' },
    { label: 'Last 14 Days', value: 'last14Days' },
    { label: 'Last 30 Days', value: 'last30Days' },
    { label: 'Last 90 Days', value: 'last90Days' },
    { label: 'Last 365 Days', value: 'last365Days' },
    { label: 'Last Week', value: 'lastWeek' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'This Week', value: 'thisWeek' },
    { label: 'This Month', value: 'thisMonth' }
];

export const ALERT_FILTERS = [
    { value: 'pipelines', displayValue: 'Pipelines' },
    { value: 'destinations', displayValue: 'Destinations' },
    { value: 'sources', displayValue: 'Sources' }
];

export const ALERT_DEFAULT_FILTER = { value: 'All', displayValue: 'All Types' };
