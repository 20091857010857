import LogsPage from '@/views/logs/LogsPage.vue';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/logs',
        name: 'logs',
        component: LogsPage,
        meta: {
            title: 'Logs',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.READ_SOURCE_METRICS, Permissions.READ_DESTINATION_METRICS],
                    type: 'and'
                }
            }
        }
    }
];
