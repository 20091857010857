<script>
import LoadingButton from '../../global/buttons/loading-btn.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { EyeIcon, EyeSlashIcon, ClipboardIcon, CheckIcon } from '@heroicons/vue/24/outline';
import * as notificationsApi from '../../../api/notifications.api';

export default {
    components: {
        LoadingButton,
        CheckCircleIcon,
        EyeIcon,
        EyeSlashIcon,
        ClipboardIcon,
        CheckIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        isConnected: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null
        },
        webhook: {
            type: String,
            default: null
        },
        subscriberId: {
            type: String,
            default: null
        },
        providerId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showWebhook: false,
            loading: false,
            inputValue: '',
            show: false,
            isCopying: false
        };
    },
    methods: {
        copyToClipboard(textToCopy) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    this.isCopying = true;
                    setTimeout(() => {
                        this.isCopying = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
        async toggleConnection() {
            this.loading = true;

            try {
                let connected = !this.isConnected;

                if (connected) {
                    this.showWebhook = true;
                }

                if (!connected) {
                    await notificationsApi.deleteSubscriberCredentials(this.subscriberId, this.providerId);
                    this.$emit('update:webhook', null);
                    this.inputValue = '';
                    this.showWebhook = false;
                    this.$emit('update:isConnected', connected);
                }
            } catch (error) {
                console.error('Error toggling connection:', error);
            } finally {
                this.loading = false;
            }
        },
        async saveInput() {
            this.loading = true;
            try {
                await notificationsApi.updateSubscriberCredentials(this.subscriberId, this.providerId, this.inputValue);
                this.$emit('update:webhook', this.inputValue);
                this.$emit('update:isConnected', true);
                this.showWebhook = false;
            } catch (error) {
                console.error('Failed to update subscriber:', error);
            } finally {
                this.loading = false;
            }
        },
        cancelInput() {
            this.inputValue = '';
            this.$emit('update:isConnected', false);
            this.showWebhook = false;
        }
    }
};
</script>

<template>
    <div class="integration-item flex items-center justify-between p-4 border-b bg-white shadow">
        <div class="flex items-start">
            <img :src="logo" :alt="title + ' logo'" class="w-10 h-10 mr-4" />
            <div>
                <div class="flex items-center">
                    <span class="text-md font-medium">{{ title }}</span>
                    <CheckCircleIcon v-if="isConnected" class="h-5 w-5 text-green-700 ml-1" />
                </div>
                <div class="flex items-center">
                    <div class="text-xs text-gray-600 italic">Webhook</div>
                    <div v-if="webhook && !showWebhook" class="h-6 text-gray-700">
                        <div v-if="!isCopying" title="Copy">
                            <ClipboardIcon class="h-5 w-5 text-gray-600 ml-1 cursor-pointer hover:text-gray-600" @click="copyToClipboard(webhook)" />
                        </div>
                        <div v-if="isCopying" title="Copied!">
                            <CheckIcon class="h-5 w-5 text-gray-600 ml-1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showWebhook" class="flex items-center justify-between flex-grow">
            <div class="relative w-full ml-5 mr-2">
                <input
                    v-model="inputValue"
                    aria-label="input"
                    :type="show ? 'text' : 'password'"
                    class="border p2 rounded w-full webhook-input"
                    :placeholder="placeholder || 'Enter value'"
                    :disabled="loading" />
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <div class="h-6 text-gray-700" :class="{ hidden: !show, block: show }" @click="show = !show">
                        <EyeSlashIcon class="h-5 w-5 text-gray-600 ml-1" />
                    </div>

                    <div class="h-6 text-gray-700" :class="{ block: !show, hidden: show }" @click="show = !show">
                        <EyeIcon class="h-5 w-5 text-gray-600 ml-1" />
                    </div>
                </div>
            </div>
            <div class="flex gap-2">
                <LoadingButton
                    custom-css="px-4 py-2 w-24 rounded-full bg-green-400 hover:bg-green-500"
                    :is-loading="loading"
                    :can-save="inputValue != ''"
                    @click="saveInput"
                    >Save</LoadingButton
                >
                <LoadingButton
                    v-if="!loading"
                    custom-css="px-4 py-2 w-24 rounded-full bg-red-500 hover:bg-red-600"
                    :is-loading="false"
                    :can-save="true"
                    @click="cancelInput"
                    >Cancel</LoadingButton
                >
            </div>
        </div>
        <div v-if="!showWebhook">
            <LoadingButton
                :custom-css="[
                    'px-6 py-2 w-28 rounded-full font-medium transition',
                    {
                        'bg-gray-500 hover:bg-gray-600': isConnected,
                        'bg-green-400 hover:bg-green-500': !isConnected
                    }
                ]"
                :is-loading="loading"
                :can-save="true"
                @click="toggleConnection"
                >{{ isConnected ? 'Disconnect' : 'Connect' }}</LoadingButton
            >
        </div>
    </div>
</template>

<style scoped>
.webhook-input {
    padding-right: 2.5rem !important;
}
</style>
