<template>
    <div class="flex items-center space-x-4">
        <!-- Conditionally display the dropdown or the date picker -->
        <div class="w-full">
            <div v-if="!isDatePickerVisible" class="w-full">
                <!-- Dropdown for selecting range type -->
                <div class="relative">
                    <select
                        v-model="selectedRange"
                        aria-label="general-date-filter"
                        :disabled="disabled"
                        class="form-select text-sm rounded-md"
                        @change="onRangeChange">
                        <option value="" disabled>Select date filter</option>
                        <option v-for="option in options" :key="option.value" :value="option.value">
                            {{ option.label }}
                        </option>
                    </select>
                    <div class="select-icon">
                        <ChevronDownIcon class="h-5 w-5 text-gray-600" />
                    </div>
                </div>
            </div>

            <!-- VueDatePicker for date and date range selection -->
            <div v-else class="text-sm flex flex-col items-center">
                <!-- VueDatePicker -->
                <div v-if="isRange && inputValue" class="text-xs text-gray-600 mt-1">
                    {{ formatRange(inputValue) }}
                </div>
                <div class="flex items-center gap-2">
                    <ChevronLeftIcon class="w-5 h-5 cursor-pointer hover:text-gray-800" @click="showDropdown" />
                    <VueDatePicker
                        v-model="inputValue"
                        :disabled="props.disabled || props.loading"
                        :readonly="props.disabled || props.loading"
                        :type="datePickerType"
                        :range="isRange"
                        class="w-full text-sm shadow-sm" />
                    <!-- Label for displaying selected date range -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { ChevronLeftIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { getDatesBasedOnFilter } from '@/utils/utils';
import { DATE_FILTER_OPTIONS } from '@/utils/constants';

const props = defineProps({
    disabled: Boolean,
    loading: Boolean,
    placeholder: String,
    hideOptions: {
        type: Array,
        default: () => []
    },
    selected: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['input']);

const inputValue = ref('');
const selectedRange = ref(props.selected || 'last24h');
const datePickerType = ref('date');
const isRange = ref(false);
const isDatePickerVisible = ref(false);

const options = ref(DATE_FILTER_OPTIONS);

onMounted(() => {
    if (props.hideOptions?.length) {
        options.value = options.value.filter((option) => !props.hideOptions.includes(option.value));
    }
});

watch(
    () => inputValue.value,
    (newValue) => {
        if (selectedRange.value === 'date' || selectedRange.value === 'dateRange') {
            emit('input', {
                type: selectedRange.value === 'dateRange' ? 'range' : 'date',
                key: selectedRange.value,
                value: isRange.value ? { start: newValue?.[0], end: newValue?.[1] } : newValue
            });
        }
    }
);

watch(
    () => selectedRange.value,
    (newValue) => {
        if (newValue !== 'date' && newValue !== 'dateRange') {
            emit('input', { type: 'custom', key: newValue, value: getDatesBasedOnFilter(newValue) });
        } else {
            if (newValue === 'date') {
                inputValue.value = new Date();
            } else if (newValue === 'dateRange') {
                inputValue.value = [new Date(new Date().setDate(new Date().getDate() - 5)), new Date()];
            }
        }
    }
);

watch(
    () => props.selected,
    (newValue) => {
        selectedRange.value = newValue;
    }
);

function onRangeChange() {
    isDatePickerVisible.value = selectedRange.value === 'date' || selectedRange.value === 'dateRange';
    isRange.value = selectedRange.value === 'dateRange';
}

function showDropdown() {
    isDatePickerVisible.value = false;
    selectedRange.value = '';
    inputValue.value = '';
}

function formatRange(range) {
    if (range?.[0] && range?.[1]) {
        return `${range[0].toLocaleDateString()} - ${range[1].toLocaleDateString()}`;
    }
    return '';
}
</script>
