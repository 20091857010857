<template>
    <div class="mx-auto">
        <div class="page-header">
            <AppHeader />
        </div>

        <Tabs :tabs="filteredTabs" :initial-active-tab="currentTab" @tab-change="setCurrentTab">
            <template #Sources>
                <SourcesPage />
            </template>

            <template #Destinations>
                <DestinationsPage />
            </template>
        </Tabs>
    </div>
</template>

<script setup>
import DestinationsPage from '../destinations/DestinationsPage.vue';
import SourcesPage from '../sources/SourcesPage.vue';
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import Tabs from '@/components/global/Tabs.vue';
import { CloudArrowDownIcon, CloudArrowUpIcon } from '@heroicons/vue/24/solid';
import AppHeader from '../../components/AppHeader.vue';
import { hasAccess } from '@/utils/utils';
import { useAuthStore } from '@/stores/auth.store';
import { Permissions } from '@/utils/constants';

const router = useRouter();
const authStore = useAuthStore();

const tabs = [
    {
        name: 'Sources',
        icon: {
            left: true,
            icon: CloudArrowDownIcon,
            size: 'h-6 w-6',
            weight: 'font-semibold'
        },
        accessRules: {
            permissions: {
                values: [Permissions.READ_SOURCES]
            }
        }
    },
    {
        name: 'Destinations',
        icon: {
            left: true,
            icon: CloudArrowUpIcon,
            size: 'h-6 w-6',
            weight: 'font-semibold'
        },
        accessRules: {
            permissions: {
                values: [Permissions.READ_DESTINATIONS]
            }
        }
    }
];

const filteredTabs = computed(() => {
    return tabs.filter((n) => {
        return hasAccess(authStore.user, n.accessRules || {});
    });
});

const currentTab = ref('');

const setCurrentTab = (str) => {
    currentTab.value = str;

    router.push({ query: { tab: currentTab.value } });
};

watch(
    () => router.currentRoute.value.query.tab,
    (newTab) => {
        if (newTab) {
            setCurrentTab(newTab);
        }
    },
    { immediate: true, deep: true }
);
</script>

<style scoped>
/* Additional specific styles if needed */
</style>
