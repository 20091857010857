<template>
    <div class="flex flex-col">
        <div class="w-full flex flex-row relative">
            <div class="flex flex-row items-center relative" :class="showTextInfoElement ? 'w-1/2 flex-1' : ''">
                <div class="flex flex-row items-center relative">
                    <label
                        :for="`control-${controlId}`"
                        class="block text-sm font-medium text-gray-700 mr-2"
                        :class="props.overrideLabelOpacity ? 'override-opacity' : ''">
                        {{ config.display_name }}
                    </label>
                    <div class="flex h-5 mr-2 items-center">
                        <label class="relative inline-flex items-center cursor-pointer">
                            <input
                                aria-label="input"
                                v-model="controlValue"
                                class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                type="checkbox"
                                role="switch"
                                :class="{ disabled: !editMode, 'bg-green-400': controlValue, 'bg-neutral-300': !controlValue }"
                                :true-value="true"
                                :false-value="false"
                                :readonly="!editMode || props.config.readonly || props.config.value?.readonly"
                                :disabled="!editMode"
                                aria-describedby="comments-description"
                                :name="`control-${controlId}`" />
                        </label>
                        <div v-if="errors.length" class="text-red-700 mt-1 text-xs">
                            <template v-for="error in errors" :key="error.$uid">
                                <span> * {{ error.$message }} </span>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="showTextInfoElement" class="border border-green-200 flex w-full"></div>
            </div>
            <Tooltip
                v-if="
                    hideLateralDescriptionValue &&
                    props.config.display_name.trim().toLowerCase() !== config.description.trim().toLowerCase() &&
                    config.description.trim().split(' ').length > 1
                "
                :tooltip-text="config.description"
                class="ml-2" />
            <div
                v-if="showTextInfoElement"
                class="flex-1 p-3 bg-green-50 border-l-4 border-green-200 text-xs text-black rounded-md relative arrow-left ml-4"
                :class="errors.length ? 'mb-5' : ''">
                {{ config.description }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { uuid4 } from '@sentry/utils';
import { ref, watch, onMounted, onUpdated, computed } from 'vue';
import Tooltip from './Tooltip.vue';

const controlId = uuid4();

const emit = defineEmits(['input']);
const props = defineProps({
    config: {
        type: Object,
        required: true
    },
    value: {
        type: Boolean,
        default: undefined
    },
    editMode: {
        type: Boolean,
        default: true
    },
    overrideLabelOpacity: {
        type: Boolean,
        default: false
    },
    hideLateralDescription: {
        type: Boolean,
        default: true
    },
    errors: {
        type: Array,
        default: () => []
    }
});

const hideLateralDescriptionValue = ref(props.hideLateralDescription);

onUpdated(() => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : !props.editMode || window.innerWidth <= 1024;
});
const defaultValue = ref(props.config.value.default === null || props.config.value.default === undefined ? false : props.config.value.default);

const controlValue = ref(props.value === null || props.value === undefined ? defaultValue.value : props.value);

const handleResize = () => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : window.innerWidth <= 1024;
};

const showTextInfoElement = computed(() => {
    return (
        props.config.description &&
        !hideLateralDescriptionValue.value &&
        props.config.display_name.trim().toLowerCase() !== props.config.description.trim().toLowerCase() &&
        props.config.description.trim().split(' ').length > 1
    );
});

window.addEventListener('resize', handleResize);

onMounted(() => {
    handleResize();
});

watch(controlValue, (newValue) => {
    emit('input', newValue);
});
</script>

<style scoped>
.arrow-left::before {
    content: '';
    position: absolute;
    left: -12px; /* Adjusted for the larger arrow */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent; /* increased for a taller arrow */
    border-bottom: 18px solid transparent; /* increased for a taller arrow */
    border-right: 12px solid #bbf7d0; /* adjusted to match the border color of the blue container */
}
</style>
