import ServicesPage from '@/views/stkServices/ServicesPage.vue';
import ServiceTypeSelection from '@/views/stkServices/steps/ServiceTypeSelection.vue';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/services',
        name: 'services',
        component: ServicesPage,
        meta: {
            title: 'Services',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.READ_SERVICES] // write/switch is not required to see the page and will hide the action buttons if needed
                }
            }
        }
    },
    {
        path: '/services/new',
        name: 'new-service',
        component: ServiceTypeSelection,
        meta: {
            title: 'New Service',
            requireAuth: true,
            onlyDev: true
        }
    }
];
