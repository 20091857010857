<template>
    <Menu as="div" class="relative inline-block text-left w-full" style="min-width: 155px">
        <div>
            <MenuButton ref="menuButton" class="menu-button group w-full rounded-md py-2 text-left text-sm font-medium">
                <span class="flex w-full items-center justify-center px-3">
                    <span class="flex min-w-0 items-center justify-between space-x-3">
                        <BuildingOffice2Icon class="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                        <div v-if="userStore.tenants" class="flex flex-wrap min-w-0">
                            <!-- Apply a max-width to ensure truncation -->
                            <span class="text-gray-600 truncate" style="max-width: 150px">
                                {{ selectedTenant?.tenant_name }}
                            </span>
                            <ChevronUpDownIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        </div>
                        <div v-else class="snippet mt-3 pb-3 pl-4 flex items-baseline" data-title=".dot-flashing" style="min-width: 101px !important">
                            <div class="stage">
                                <div class="dot-flashing"></div>
                            </div>
                        </div>
                    </span>
                </span>
            </MenuButton>
        </div>

        <MenuItems
            ref="menuItems"
            class="absolute z-10 mt-1 origin-bottom divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            :class="positionClass"
            style="min-width: 11rem">
            <div class="py-1">
                <div v-for="item in shownTenants" :key="item?.tenant_id" class="relative">
                    <MenuItem as="template" @click="actionToDo(item?.tenant_id)">
                        <a href="#" :class="getItemClass(item)" class="px-3 flex items-center justify-between w-full">
                            {{ item?.tenant_name }}
                        </a>
                    </MenuItem>
                    <!-- Nested Items -->
                    <div v-if="item.children && item.children.length">
                        <TenantChildren
                            :items="item.children"
                            :has-parent="true"
                            :selected-tenant="selectedTenant"
                            @input="(item) => actionToDo(item?.tenant_id)" />
                    </div>
                </div>
            </div>
        </MenuItems>
    </Menu>
</template>

<script setup>
import { computed, ref } from 'vue';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { useFrontegg } from '@frontegg/vue';
import { useUserStore } from '../../stores/user.store.js';
import TenantChildren from './TenantChildren.vue';
import { ChevronUpDownIcon, BuildingOffice2Icon } from '@heroicons/vue/24/solid';
import _ from 'lodash';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const fronteggUse = useFrontegg();

const props = defineProps({
    onSelect: {
        type: Function,
        default: null
    },
    dropdownPosition: {
        type: String,
        default: null
    },
    onlyCurrentTenant: {
        type: Boolean,
        default: false
    },
    includeAllOption: {
        type: Boolean,
        default: false
    }
});

const menuButton = ref(null);
const menuItems = ref(null);
const selectedTenantId = ref(null);
const router = useRouter();

const positionClass = computed(() => {
    switch (props.dropdownPosition) {
        case 'bottom':
            return '';
        default:
            return 'bottom-full';
    }
});

const shownTenants = computed(() => {
    let tenants = userStore.tenants;

    if (!tenants || !tenants.length) return [];

    // current tenant and it'schildren
    if (props.onlyCurrentTenant) {
        tenants = searchTenant(tenants, fronteggUse.authState.user?.tenantId);
    }

    // include all option
    if (props.includeAllOption && takeAllShownTenantIds([tenants].flat()).length > 1) {
        tenants = [{ tenant_id: 'all', tenant_name: 'All' }, tenants];
    }

    return [tenants].filter((x) => x).flat();
});

const searchTenant = (tenants, tenantId) => {
    if (!tenants || tenants.length === 0) return null;
    // Find the tenant at the current level
    let tenant = _.find(tenants, { tenant_id: tenantId });

    // If not found at the current level and children exist, search in children
    if (!tenant) {
        for (let t of tenants) {
            if (t.children && t.children.length > 0) {
                tenant = searchTenant(t.children, tenantId);

                if (tenant) break; // If found in children, break the loop
            }
        }
    }

    return tenant;
};

const takeAllShownTenantIds = (tenants) => {
    if (!tenants || tenants.length === 0) return [];

    let tenantIds = [];

    for (let tenant of tenants) {
        tenantIds.push(tenant.tenant_id);

        if (tenant.children && tenant.children.length > 0) {
            tenantIds = tenantIds.concat(takeAllShownTenantIds(tenant.children));
        }
    }

    return tenantIds;
};

const selectedTenant = computed(() => {
    const tenantId = selectedTenantId.value || fronteggUse.authState.user?.tenantId;
    let allTenants = userStore.tenants;

    if (props.includeAllOption && takeAllShownTenantIds([allTenants].flat()).length > 1) {
        allTenants = [{ tenant_id: 'all', tenant_name: 'All' }, allTenants || []].flat();
    }

    if (!allTenants || allTenants.length === 0) return {};
    if (!tenantId) return {};

    return searchTenant(allTenants, tenantId) || {};
});

const switchOrganisation = async (tenantId) => {
    if (selectedTenant.value.tenantId !== tenantId) {
        await router.push({ name: 'dashboard', query: {} });

        fronteggUse.fronteggAuth.tenantsActions.switchTenant({ tenantId, callback: () => setTimeout(() => window.location.reload(), 100) });
    }
};

const actionToDo = (tenantId) => {
    selectedTenantId.value = tenantId;

    if (props.onSelect) {
        if (tenantId === 'all') {
            const allTenantIds = takeAllShownTenantIds(shownTenants.value?.filter((t) => t.tenant_id !== 'all'));

            props.onSelect(allTenantIds);
        } else {
            props.onSelect([tenantId]);
        }
    } else {
        switchOrganisation(tenantId);
    }
};

const getItemClass = (item) => {
    return `block py-2 text-sm ${item?.tenant_id === selectedTenant.value.tenant_id ? 'bg-gray-100 font-medium text-gray-600' : 'text-gray-600'}`;
};
</script>
