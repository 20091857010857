/* eslint-disable object-curly-newline */
import _ from 'lodash';
import moment from 'moment';

export const retryApiCall = async (fn, onStatusCode = 400, retriesLeft = 5, interval = 1000) => {
    try {
        const response = await fn(retriesLeft);
        return response;
    } catch (error) {
        if (error.response?.status === onStatusCode && retriesLeft > 0) {
            await new Promise((resolve) => setTimeout(resolve, interval));
            return retryApiCall(fn, onStatusCode, retriesLeft - 1, interval);
        }

        throw error;
    }
};

export const getStatusColor = (status, hover = false, tailwind = false) => {
    if (!status) return;

    let result = tailwind ? 'bg-gray-300' : '#b3bbc9'; // Darker grey

    switch (status?.toLowerCase()) {
        case 'active':
        case 'completed':
        case 'available':
        case 'success':
        case 'running':
            result = tailwind ? 'bg-green-300' : '#BBF7D0'; // Softer green
            break;
        case 'broken':
        case 'failed':
        case 'incomplete':
        case 'error':
        case 'failing':
            result = hover ? (tailwind ? 'bg-red-700' : '#E36476') : tailwind ? 'bg-red-500' : '#FB7185'; // Bright red
            break;
        case 'paused':
        case 'stopped':
            result = tailwind ? 'bg-gray-300' : '#b3bbc9'; // Darker grey
            break;
        case 'delayed':
        case 'pending':
        case 'starting':
        case 'initializing':
        case 'cancelling':
            result = tailwind ? 'bg-yellow-400' : '#FEF08A'; // Brighter yellow
            break;
        case 'deploying':
        case 'restarting':
        case 'created':
            result = tailwind ? 'bg-blue-300' : '#93C5FD'; // Bright blue
            break;
        default:
            result = tailwind ? 'bg-gray-300' : '#b3bbc9'; // Darker grey
            break;
    }

    return result;
};

export const FILTER_CONSTANTS = {
    all: { value: 'All', displayValue: 'All' }
};

export const summarizeNum = (val) => {
    let num = parseFloat(_.replace(val, /,/g, ''));

    if (!num) return 0;
    const negative = num < 0;

    let suffix = '';

    num = Math.abs(num);

    if (num >= 1000) {
        suffix = 'K';
        num /= 1000;
    }
    if (num >= 1000) {
        suffix = 'M';
        num /= 1000;
    }
    if (num >= 1000) {
        suffix = 'B';
        num /= 1000;
    }

    if (num >= 1000) {
        suffix = 'T';
        num /= 1000;
    }

    if (negative) {
        return `-${_.round(num, 2)}${suffix}`?.trim();
    }

    return `${num.toFixed(2).replace(/[.,]00$/, '')}${suffix}`?.trim();
};

export const summarizeNumber = (val) => {
    let num = parseFloat(_.replace(val, /,/g, ''));

    if (!num) return 0;
    const negative = num < 0;

    num = Math.abs(num);

    if (num >= 1000) {
        num /= 1000;
    }
    if (num >= 1000) {
        num /= 1000;
    }
    if (num >= 1000) {
        num /= 1000;
    }

    if (num >= 1000) {
        num /= 1000;
    }

    if (negative) {
        return `-${_.round(num, 2)}`?.trim();
    }

    return `${num.toFixed(2).replace(/[.,]00$/, '')}`?.trim();
};

export function formatDuration(time, unit = 'milliseconds') {
    let SECOND = 1000;
    if (unit == 'seconds' || unit == 's') SECOND = 1;

    if (time < SECOND) return `0s ${_.isNumber(Number(time)) ? `${time}ms` : ''}`;

    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const MONTH = DAY * 30.44;
    const YEAR = DAY * 365.25;

    const numSeconds = Math.floor(time / SECOND);
    const numMinutes = Math.floor(time / MINUTE);
    const numHours = Math.floor(time / HOUR);
    const numDays = Math.floor(time / DAY);
    const numMonths = Math.floor(time / MONTH);
    const numYears = Math.floor(time / YEAR);

    if (numYears > 0) {
        const days = Math.floor((time - numYears * YEAR) / DAY);
        const hours = Math.floor((time - numYears * YEAR - days * DAY) / HOUR);
        return `${numYears}y ${days}d ${hours}h`;
    }

    if (numMonths > 0) {
        const days = Math.floor((time - numMonths * MONTH) / DAY);
        const hours = Math.floor((time - numMonths * MONTH - days * DAY) / HOUR);
        return `${numMonths}mo ${days}d ${hours}h`;
    }

    if (numDays > 0) {
        const hours = Math.floor((time - numDays * DAY) / HOUR);
        return `${numDays}d ${hours}h`;
    }

    if (numHours > 0) {
        const minutes = Math.floor((time - numHours * HOUR) / MINUTE);
        return `${numHours}h ${minutes}m`;
    }

    if (numMinutes > 0) {
        const seconds = Math.floor((time - numMinutes * MINUTE) / SECOND);
        return `${numMinutes}m ${seconds}s`;
    }

    return `0m ${_.isNumber(Number(numSeconds)) ? `${numSeconds}s` : ''} `;
}

export function extractNumberFromString(str) {
    // Check if input is a string
    if (typeof str !== 'string') {
        return str;
    }
    // Use a regular expression to match any numeric value
    const regex = /[+-]?\d+(\.\d+)?/g;
    const match = str.match(regex);
    if (match) {
        // Use parseFloat() to parse the first string and ensure that the value is not rounded
        const value = parseFloat(match[0]);
        // Use the Number.isNaN() method to check if the value is not a valid number
        if (Number.isNaN(value)) {
            return null;
        } else {
            // Return the first numeric value
            return value;
        }
    } else {
        // If no match is found, return null or an appropriate value
        return null;
    }
}

export function findPath(source, propName, mainParent, intermediateProperties = []) {
    let resultPath = null;

    const finalIntermediateProperties = Array.isArray(intermediateProperties) ? intermediateProperties : [intermediateProperties];

    function search(obj, currentPath) {
        if (resultPath) return;
        for (const intermediate of finalIntermediateProperties) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(intermediate)) {
                search(obj[intermediate], currentPath.concat(intermediate));
            }
        }

        for (const key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key) && !finalIntermediateProperties.includes(key)) {
                const newPath = currentPath.concat(key);
                if (key === propName) {
                    resultPath = newPath;
                    return;
                }

                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    search(obj[key], newPath);
                }
            }
        }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (source.hasOwnProperty(mainParent)) {
        let currentPath = [mainParent];
        search(source[mainParent], currentPath);
    }

    return resultPath || null;
}

export function buildTreeNodesOutOfExistingConfig(existingConfig, defaultConfig, connector, isSelectedDefault = true) {
    const treeNodes = [];
    const treeLevels = [];

    for (let schemaIndex = 0; schemaIndex < connector?.schema_levels?.length; schemaIndex++) {
        const schemaLevel = connector.schema_levels[schemaIndex];

        for (const propKey in existingConfig) {
            const matchingConfig = defaultConfig.find((c) => c.name === propKey && c.tab === 'schema' && c.schema_level == schemaLevel);

            if (matchingConfig) {
                const matchingConfigValues = existingConfig[matchingConfig.name].split(',');

                for (let i = 0; i < matchingConfigValues.length; i++) {
                    // parent
                    if (schemaIndex === 0) {
                        treeNodes.push({
                            name: matchingConfigValues[i].trim(),
                            isSelected: isSelectedDefault,
                            collapsed: true,
                            children: connector.schema_levels.indexOf(schemaLevel) != connector?.schema_levels?.length - 1 ? [] : undefined,
                            schemaLevel: schemaLevel,
                            schemaObject: propKey
                        });

                        const treeLevel = {
                            schemaLevel: schemaLevel,
                            schemaObject: propKey
                        };
                        const treeExists = treeLevels.some((item) => item.schemaObject === treeLevel.schemaObject);
                        if (!treeExists) {
                            treeLevels.push(treeLevel);
                        }
                    }
                    // children
                    if (schemaIndex === 1) {
                        const matchingSplits = matchingConfigValues[i].split('.');
                        const parentName = matchingSplits[0].trim();

                        const parentObj = treeNodes.find((parent) => parent.name === parentName.trim());
                        if (parentObj && parentObj.children) {
                            const childName = matchingSplits[1]?.trim();

                            parentObj.children.push({
                                name: childName,
                                isSelected: isSelectedDefault,
                                collapsed: true,
                                children: connector.schema_levels.indexOf(schemaLevel) != connector?.schema_levels?.length - 1 ? [] : undefined,
                                schemaLevel: schemaLevel,
                                schemaObject: propKey
                            });
                        }

                        const treeLevel = {
                            schemaLevel: schemaLevel,
                            schemaObject: propKey
                        };
                        const treeExists = treeLevels.some((item) => item.schemaObject === treeLevel.schemaObject);
                        if (!treeExists) {
                            treeLevels.push(treeLevel);
                        }
                    }
                    // grandchildren
                    if (schemaIndex === 2) {
                        const matchingSplits = matchingConfigValues[i].split('.');
                        const grandParentName = matchingSplits[0].trim();
                        const parentName = matchingSplits[1]?.trim() || '';

                        const grandParentObj = treeNodes
                            .find((grParent) => grParent.name === grandParentName)
                            .children.find((parent) => parent.name === parentName);
                        if (grandParentObj && grandParentObj.children) {
                            const childName = matchingSplits[2]?.trim();

                            grandParentObj.children.push({
                                name: childName,
                                isSelected: isSelectedDefault,
                                collapsed: true,
                                children: connector.schema_levels.indexOf(schemaLevel) != connector?.schema_levels?.length - 1 ? [] : undefined,
                                schemaLevel: schemaLevel,
                                schemaObject: propKey
                            });
                        }

                        const treeLevel = {
                            schemaLevel: schemaLevel,
                            schemaObject: propKey
                        };
                        const treeExists = treeLevels.some((item) => item.schemaObject === treeLevel.schemaObject);
                        if (!treeExists) {
                            treeLevels.push(treeLevel);
                        }
                    }
                }
            }
        }
    }

    return {
        treeNodes,
        treeLevels
    };
}

/**
 * Determines the start and end dates based on a provided filter. The filter can specify
 * a date or a custom range (e.g., 'last24h', 'lastWeek', 'lastMonth', 'last7Days', 'last30Days').
 *
 * @param {Object} filter - The filter to determine the date range.
 * @param {string} filter.type - Type of the filter (e.g., 'date', 'custom').
 * @param {string|Date} filter.value - The value for the filter (e.g., date, 'last24h').
 * @param {string|Date} [filter.endDate] - Optional end date for the interval.
 * @param {Object} user - User object to get current date context, optional.
 * @returns {Object} An object containing timestamp_from and timestamp_to.
 */
export function getDatesBasedOnFilter(filter = 'last24h', date = new Date()) {
    if (filter == 'all') return null;

    let start = moment.utc(new Date());
    let end = moment.utc(new Date());
    let timeUnit = 'day';

    if (filter == 'date') {
        start = moment.utc(date).startOf('day');
        end = moment.utc(date).endOf('day');
        timeUnit = 'hour';
    } else {
        switch (filter) {
            case 'last24h':
            case 'last-24h':
                start = start.subtract(24, 'hours');
                end = end.subtract(1, 'minutes');
                timeUnit = 'hour';
                break;
            case 'lastWeek':
            case 'last-week':
                start = start.subtract(1, 'week').startOf('week');
                end = end.subtract(1, 'week').endOf('week');
                timeUnit = 'day';
                break;
            case 'lastMonth':
            case 'last-month':
                start = start.subtract(1, 'month').startOf('month');
                end = end.subtract(1, 'month').endOf('month');
                timeUnit = 'day';
                break;
            case 'thisWeek':
            case 'this-week':
                start = start.startOf('week');
                end = moment.utc();
                timeUnit = 'day';
                break;
            case 'thisMonth':
            case 'this-month':
                start = start.startOf('month');
                end = moment.utc();
                timeUnit = 'day';
                break;

            case 'last7Days':
            case 'last-7-days':
                start.subtract(7, 'days');
                timeUnit = 'day';
                break;
            case 'last14Days':
            case 'last-14-days':
                start.subtract(14, 'days');
                timeUnit = 'day';
                break;
            case 'last30Days':
            case 'last-30-days':
                start.subtract(30, 'days');
                timeUnit = 'day';
                break;
            case 'last90Days':
            case 'last-90-days':
                start.subtract(90, 'days');
                timeUnit = 'day';
                break;
            case 'last365Days':
            case 'last-365-days':
                start.subtract(365, 'days');
                timeUnit = 'month';
                break;
            default:
                // Handle any other custom cases or leave it as default
                break;
        }
    }

    // Format the dates to the specified timestamp format
    return {
        start: start.utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        end: end.utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        timeUnit
    };
}

const DEFAULT_CONVERT_RULES = [
    {
        metric_unit: ['sec', 's', 'second', 'seconds'],
        imperial_unit: ['sec', 's', 'second', 'seconds'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                metric_conversion_unit: 'y',
                threshold: 31536000,
                factor: 1 / 31536000
            },
            {
                trigger: 'above',
                metric_conversion_unit: 'mo',
                threshold: 2592000,
                factor: 1 / 2592000
            },
            {
                metric_conversion_unit: 'd',
                trigger: 'above',
                threshold: 86400,
                factor: 1 / 86400
            },
            {
                trigger: 'above',
                threshold: 3600,
                factor: 1 / 3600,
                metric_conversion_unit: 'h'
            },
            {
                trigger: 'above',
                threshold: 60,
                factor: 1 / 60,
                metric_conversion_unit: 'min'
            }
        ]
    },
    {
        metric_unit: ['min', 'minute', 'minutes'],
        imperial_unit: ['min', 'minute', 'minutes'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 60,
                factor: 1 / 60,
                metric_conversion_unit: 'h'
            },
            {
                trigger: 'above',
                threshold: 1440,
                factor: 1 / 1440,
                metric_conversion_unit: 'd'
            },
            {
                metric_conversion_unit: 'mo',
                trigger: 'above',
                threshold: 43200,
                factor: 1 / 43200
            },
            {
                metric_conversion_unit: 'y',
                trigger: 'above',
                threshold: 525600,
                factor: 1 / 525600
            }
        ]
    },
    {
        metric_unit: ['h', 'hour', 'hours'],
        imperial_unit: ['h', 'hour', 'hours'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 24,
                factor: 1 / 24,
                metric_conversion_unit: 'd'
            },
            {
                metric_conversion_unit: 'mo',
                trigger: 'above',
                threshold: 730,
                factor: 1 / 730
            },
            {
                trigger: 'above',
                threshold: 730,
                factor: 1 / 730,
                metric_conversion_unit: 'y'
            }
        ]
    },
    {
        metric_unit: ['d', 'day', 'days'],
        imperial_unit: ['d', 'day', 'days'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                metric_conversion_unit: 'mo',
                trigger: 'above',
                threshold: 30,
                factor: 1 / 30
            },
            {
                trigger: 'above',
                threshold: 365,
                factor: 1 / 365,
                metric_conversion_unit: 'y'
            }
        ]
    },
    {
        metric_unit: ['w', 'week', 'weeks'],
        imperial_unit: ['w', 'week', 'weeks'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                metric_conversion_unit: 'mo',
                trigger: 'above',
                threshold: 4,
                factor: 1 / 4
            },
            {
                trigger: 'above',
                threshold: 52,
                factor: 1 / 52,
                metric_conversion_unit: 'y'
            }
        ]
    },
    {
        metric_unit: ['ms', 'millisecond', 'milliseconds'],
        imperial_unit: ['ms', 'millisecond', 'milliseconds'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 1000,
                factor: 1 / 1000,
                metric_conversion_unit: 's'
            },
            {
                trigger: 'above',
                threshold: 60000,
                factor: 1 / 60000,
                metric_conversion_unit: 'min'
            },
            {
                trigger: 'above',
                threshold: 3600000,
                factor: 1 / 3600000,
                metric_conversion_unit: 'h'
            },
            {
                trigger: 'above',
                threshold: 86400000,
                factor: 1 / 86400000,
                metric_conversion_unit: 'd'
            },
            {
                metric_conversion_unit: 'mo',
                trigger: 'above',
                threshold: 2592000000,
                factor: 1 / 2592000000
            },
            {
                trigger: 'above',
                threshold: 31536000000,
                factor: 1 / 31536000000,
                metric_conversion_unit: 'y'
            }
        ]
    },
    {
        metric_unit: ['GB', 'gigabyte', 'gigabytes'],
        imperial_unit: ['GB', 'gigabyte', 'gigabytes'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 1024,
                factor: 1 / 1024,
                metric_conversion_unit: 'TB'
            }
        ]
    },
    {
        metric_unit: ['MB', 'megabyte', 'megabytes'],
        imperial_unit: ['MB', 'megabyte', 'megabytes'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 1024,
                factor: 1 / 1024,
                metric_conversion_unit: 'GB'
            },
            {
                trigger: 'above',
                threshold: 1048576,
                factor: 1 / 1048576,
                metric_conversion_unit: 'TB'
            }
        ]
    },
    {
        metric_unit: ['KB', 'kilobyte', 'kilobytes'],
        imperial_unit: ['KB', 'kilobyte', 'kilobytes'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 1024,
                factor: 1 / 1024,
                metric_conversion_unit: 'MB'
            },
            {
                trigger: 'above',
                threshold: 1048576,
                factor: 1 / 1048576,
                metric_conversion_unit: 'GB'
            },
            {
                trigger: 'above',
                threshold: 1073741824,
                factor: 1 / 1073741824,
                metric_conversion_unit: 'TB'
            }
        ]
    },
    {
        metric_unit: ['bytes', 'byte'],
        imperial_unit: ['bytes', 'byte'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 1024,
                factor: 1 / 1024,
                metric_conversion_unit: 'KB'
            },
            {
                trigger: 'above',
                threshold: 1048576,
                factor: 1 / 1048576,
                metric_conversion_unit: 'MB'
            },
            {
                trigger: 'above',
                threshold: 1073741824,
                factor: 1 / 1073741824,
                metric_conversion_unit: 'GB'
            },
            {
                trigger: 'above',
                threshold: 1099511627776,
                factor: 1 / 1099511627776,
                metric_conversion_unit: 'TB'
            }
        ]
    },
    {
        metric_unit: ['bytes/seconds', 'byte/seconds'],
        imperial_unit: ['bytes/seconds', 'byte/seconds'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'above',
                threshold: 1024,
                factor: 1 / 1024,
                metric_conversion_unit: 'KB/seconds'
            },
            {
                trigger: 'above',
                threshold: 1048576,
                factor: 1 / 1048576,
                metric_conversion_unit: 'MB/seconds'
            },
            {
                trigger: 'above',
                threshold: 1073741824,
                factor: 1 / 1073741824,
                metric_conversion_unit: 'GB/seconds'
            },
            {
                trigger: 'above',
                threshold: 1099511627776,
                factor: 1 / 1099511627776,
                metric_conversion_unit: 'TB/seconds'
            }
        ]
    },
    {
        metric_unit: ['count/seconds', 'count/second'],
        imperial_unit: ['count/seconds', 'count/second'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'always',
                threshold: 0,
                factor: 1,
                metric_conversion_unit: '/seconds'
            }
        ]
    },
    {
        metric_unit: ['%', 'percentage', 'percent'],
        imperial_unit: ['%', 'percentage', 'percent'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'always',
                threshold: 0,
                factor: 1,
                metric_conversion_unit: '%'
            }
        ]
    },
    {
        metric_unit: ['id', 'enum'],
        imperial_unit: ['id', 'enum'],
        unitPosition: 'suffix',
        convert_rules: [
            {
                trigger: 'always',
                threshold: 0,
                factor: 1,
                metric_conversion_unit: ''
            }
        ]
    },
    {
        metric_unit: ['date', 'datetime', 'timestamp', 'unix'],
        imperial_unit: ['date', 'datetime', 'timestamp', 'unix'],
        unitPosition: 'suffix',
        to_date: true,
        convert_rules: [
            {
                trigger: 'always',
                threshold: 0,
                factor: 1,
                metric_conversion_unit: ''
            }
        ]
    }
];

/**
 * Format a value based on unit, conversion rules, and other parameters.
 *
 * @param {number|string} value - The value to format.
 * @param {Array} convert_rules - Rules to determine how value should be converted.
 * @param {string} unit - The unit of the value.
 * @param {string} [unitPosition='suffix'] - Position of the unit ('prefix' or 'suffix').
 * @param {number} [minimumDecimals=2] - Minimum number of decimals to display.
 * @param {number} [maximumDecimals=4] - Maximum number of decimals to display.
 * @param {string} [unitSystem='metric'] - The system of measurement ('metric' or 'imperial').
 * @param {string|null} [metricKey=null] - A key to determine special formatting.
 *
 * @returns {Object} Formatted value, raw value, display value, and unit.
 */

export function formatter(
    value,
    { convert_rules, unit, unitPosition = 'suffix', minimumDecimals = 2, maximumDecimals = 4, unitSystem = 'metric', imperial_conversion_unit } = {},
    sendDashOnRule = (value) => _.isNil(value) || _.isNaN(Number(value)) || value < 0 // we suppose on the metrics we don't have negative numbers, to adjust if/when needed
) {
    if (_.isNil(value) || (!value && _.isNaN(Number(value))) || (sendDashOnRule && sendDashOnRule(value))) {
        return {
            raw: value,
            display: sendDashOnRule ? (sendDashOnRule(value) ? '-' : value) : null,
            unit: null,
            rawValueInCurrentUnit: value
        };
    }

    if (!_.isNil(value) && !!value && _.isNaN(Number(value))) {
        return {
            raw: value,
            display: value,
            unit: null,
            rawValueInCurrentUnit: value
        };
    }

    const rule = DEFAULT_CONVERT_RULES.find(
        (rule) =>
            rule.metric_unit.some((u) => u.toLowerCase() === unit?.toLowerCase()) ||
            rule.imperial_unit.some((u) => u.toLowerCase() === unit?.toLowerCase())
    );

    if (!convert_rules) {
        convert_rules = rule?.convert_rules;
    }

    // todo if needed - check also the unit
    if (rule?.to_date) {
        return {
            raw: value,
            display: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-',
            unit: null,
            rawValueInCurrentUnit: value
        };
    }

    let convertedValue = parseFloat(value.toString().replace(/,/g, ''));
    let originalValue = _.cloneDeep(convertedValue);

    if (!convert_rules)
        return {
            raw: value,
            display: _.isNumber(Number(originalValue)) ? summarizeNum(Number(originalValue)) : value,
            unit: unit,
            rawValueInCurrentUnit: value
        };

    let aboveAlreadyDone = false;
    let belowAlreadyDone = false;

    // Handle percentages
    if ((unit === '%' || unit === 'percentage') && convertedValue < 1) {
        convertedValue *= 100;
        originalValue = _.cloneDeep(convertedValue);
    }

    // Sort convert_rules by priority: 'always' -> 'above' -> 'below'
    // also sort based on threshold, threshold factor should be applied first
    const sortedRules = _.orderBy(
        convert_rules,
        [
            (rule) => {
                if (rule.trigger === 'always') return 1;
                if (rule.trigger === 'above') return 2;
                if (rule.trigger === 'below') return 3;
                return 4;
            },
            (rule) => {
                if (rule.trigger === 'above') return -rule.threshold; // negative to sort in descending order
                return rule.threshold; // default is ascending order
            }
        ],
        ['asc', 'asc'] // both criteria are in ascending order, but we've adjusted the sign for 'above' inside the function
    );

    for (const rule of sortedRules) {
        const { trigger, threshold, factor, metric_conversion_unit, applicable_metric_system } = rule;

        // Skip this rule if it's not applicable for the current metric system
        if (applicable_metric_system && applicable_metric_system !== unitSystem) {
            // eslint-disable-next-line no-continue
            continue;
        }

        // If the conversion rule is 'always', set the original values
        if (trigger === 'always') {
            originalValue *= factor;
        }

        // Apply conversion rule based on trigger and metric system
        if (
            unitSystem === 'imperial' &&
            (trigger === 'always' || (trigger === 'above' && originalValue > threshold) || (trigger === 'below' && originalValue < threshold))
        ) {
            if (trigger === 'above' && aboveAlreadyDone) {
                continue;
            }

            if (trigger === 'below' && belowAlreadyDone) {
                continue;
            }

            convertedValue *= factor;
            unit = imperial_conversion_unit;

            if (trigger === 'above') {
                aboveAlreadyDone = true;
            }

            if (trigger === 'below') {
                belowAlreadyDone = true;
            }
        } else if (
            unitSystem === 'metric' &&
            (trigger === 'always' || (trigger === 'above' && originalValue > threshold) || (trigger === 'below' && originalValue < threshold))
        ) {
            if (trigger === 'above' && aboveAlreadyDone) {
                continue;
            }

            if (trigger === 'below' && belowAlreadyDone) {
                continue;
            }

            convertedValue *= factor;
            unit = metric_conversion_unit;

            if (trigger === 'above') {
                aboveAlreadyDone = true;
            }

            if (trigger === 'below') {
                belowAlreadyDone = true;
            }
        }
    }

    const TIME_UNITS = ['y', 'mo', 'd', 'ms', 'sec', 's', 'h', 'min'];

    const MILLISECONDS_NAMES = ['ms', 'milliseconds', 'millisecond'];
    const SECONDS_NAMES = ['s', 'sec', 'seconds', 'second'];
    const MINUTES_NAMES = ['min', 'minutes', 'minute'];
    const HOURS_NAMES = ['h', 'hours', 'hour'];
    const DAYS_NAMES = ['d', 'days', 'day'];
    const MONTHS_NAMES = ['mo', 'months', 'month'];
    const YEARS_NAMES = ['y', 'years', 'year'];

    // Handling of time units (sec, s, h, min)
    if (
        TIME_UNITS.includes(unit) ||
        rule?.metric_unit?.some((u) => TIME_UNITS.includes(u.toLowerCase())) ||
        rule?.imperial_unit?.some((u) => TIME_UNITS.includes(u.toLowerCase()))
    ) {
        let years = 0;
        let months = 0;
        let days = 0;
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        let milliseconds = 0;
        let rawMilliseconds = 0;

        if (MILLISECONDS_NAMES.includes(unit)) {
            milliseconds = Math.floor(convertedValue);
            rawMilliseconds = convertedValue;
        }

        if (SECONDS_NAMES.includes(unit)) {
            seconds = Math.floor(convertedValue);
            milliseconds = Math.round((convertedValue - seconds) * 1000);
        }

        if (MINUTES_NAMES.includes(unit)) {
            minutes = Math.floor(convertedValue);
            seconds = Math.round((convertedValue - minutes) * 60);

            rawMilliseconds = milliseconds;
        }

        if (HOURS_NAMES.includes(unit)) {
            hours = Math.floor(convertedValue);
            minutes = Math.floor((convertedValue - hours) * 60);
            seconds = Math.round((convertedValue - hours - minutes / 60) * 60 * 60);

            rawMilliseconds = milliseconds;
        }

        if (DAYS_NAMES.includes(unit)) {
            days = Math.floor(convertedValue);
            hours = Math.floor((convertedValue - days) * 24);
            minutes = Math.floor((convertedValue - days - hours / 24) * 24 * 60);
            seconds = Math.round((convertedValue - days - hours / 24 - minutes / 24 / 60) * 24 * 60 * 60);

            rawMilliseconds = milliseconds;
        }

        if (MONTHS_NAMES.includes(unit)) {
            months = Math.floor(convertedValue);
            days = Math.floor((convertedValue - months) * 30.44);
            hours = Math.floor((convertedValue - months - days / 30.44) * 30.44 * 24);
            minutes = Math.floor((convertedValue - months - days / 30.44 - hours / 30.44 / 24) * 30.44 * 24 * 60);

            rawMilliseconds = milliseconds;
        }

        if (YEARS_NAMES.includes(unit)) {
            years = Math.floor(convertedValue);
            months = Math.floor((convertedValue - years) * 12);
            days = Math.floor((convertedValue - years - months / 12) * 12 * 30.44);
            hours = Math.floor((convertedValue - years - months / 12 - days / 12 / 30.44) * 12 * 30.44 * 24);
            minutes = Math.floor((convertedValue - years - months / 12 - days / 12 / 30.44 - hours / 12 / 30.44 / 24) * 12 * 30.44 * 24 * 60);

            rawMilliseconds = milliseconds;
        }

        // Handle milliseconds overflow
        if (milliseconds >= 1000) {
            seconds += Math.floor(milliseconds / 1000);
            milliseconds %= 1000;
        }

        // Handle seconds overflow
        if (seconds >= 60) {
            minutes += Math.floor(seconds / 60);
            seconds %= 60;
        }

        // Handle minutes overflow
        if (minutes >= 60) {
            hours += Math.floor(minutes / 60);
            minutes %= 60;
        }

        // handle hours overflow
        if (hours >= 24) {
            days += Math.floor(hours / 24);
            hours %= 24;
        }

        // Handle days overflow
        if (days >= 30) {
            months += Math.floor(days / 30);
            days %= 30;
        }

        // Handle months overflow
        if (months >= 12) {
            years += Math.floor(months / 12);
            months %= 12;
        }

        let highestUnit = 'ms';

        let displayTime = '';
        if (years > 0) {
            displayTime += ` ${years}y `;
            highestUnit = 'y';
        }

        if (months > 0) {
            displayTime += ` ${months}mo `;
            highestUnit = highestUnit === 'y' ? 'y' : 'mo';
        }

        if (days > 0) {
            displayTime += ` ${days}d `;
            highestUnit = highestUnit === 'y' || highestUnit === 'mo' ? highestUnit : 'd';
        }

        if (hours > 0) {
            displayTime += ` ${hours}h `;
            highestUnit = highestUnit === 'y' || highestUnit === 'mo' || highestUnit === 'd' ? highestUnit : 'h';
        }

        if (minutes > 0) {
            displayTime += ` ${minutes}min `;
            highestUnit = highestUnit === 'y' || highestUnit === 'mo' || highestUnit === 'd' || highestUnit === 'h' ? highestUnit : 'min';
        }

        if (seconds > 0) {
            // if the value is in minutes, display also the seconds
            if (days <= 0 && months <= 0 && years <= 0) {
                displayTime += ` ${seconds}s`;
            }

            highestUnit =
                highestUnit === 'y' || highestUnit === 'mo' || highestUnit === 'd' || highestUnit === 'h' || highestUnit === 'min'
                    ? highestUnit
                    : 's';
        }

        if (milliseconds > 0) {
            displayTime += ` ${milliseconds}ms`;
            highestUnit =
                highestUnit === 'y' ||
                highestUnit === 'mo' ||
                highestUnit === 'd' ||
                highestUnit === 'h' ||
                highestUnit === 'min' ||
                highestUnit === 's'
                    ? highestUnit
                    : 'ms';
        }

        if (!displayTime) {
            displayTime = `${rawMilliseconds}ms`;
        }

        let rawValueInCurrentUnit = _.isNumber(Number(rawMilliseconds)) ? Number(rawMilliseconds) : rawMilliseconds;

        if (highestUnit === 'y') {
            rawValueInCurrentUnit = _.isNumber(Number(years)) ? Number(years) : years;
        } else if (highestUnit === 'mo') {
            rawValueInCurrentUnit = _.isNumber(Number(months)) ? Number(months) : months;
        } else if (highestUnit === 'd') {
            rawValueInCurrentUnit = _.isNumber(Number(days)) ? Number(days) : days;
        } else if (highestUnit === 'h') {
            rawValueInCurrentUnit = _.isNumber(Number(hours)) ? Number(hours) : hours;
        } else if (highestUnit === 'min') {
            rawValueInCurrentUnit = _.isNumber(Number(minutes)) ? Number(minutes) : minutes;
        } else if (highestUnit === 's') {
            rawValueInCurrentUnit = _.isNumber(Number(seconds)) ? Number(seconds) : seconds;
        } else if (highestUnit === 'ms') {
            rawValueInCurrentUnit = _.isNumber(Number(milliseconds)) ? Number(milliseconds) : milliseconds;
        }

        return {
            raw: rawMilliseconds,
            display: displayTime?.toString()?.trim(),
            unit: highestUnit,
            rawValueInCurrentUnit: rawValueInCurrentUnit
        };
    }

    // Determine the number of decimals based on the value
    let decimalsNeeded = minimumDecimals;

    if (convertedValue < 1 && convertedValue.toString().includes('.')) {
        const decimals = -Math.floor(Math.log10(convertedValue) + 1) + 2;
        decimalsNeeded = decimals > maximumDecimals ? maximumDecimals : decimals;
    }

    decimalsNeeded = decimalsNeeded < minimumDecimals ? minimumDecimals : decimalsNeeded;
    const rawValue = convertedValue.toFixed(decimalsNeeded);

    originalValue = _.isNumber(Number(originalValue)) ? originalValue.toFixed(decimalsNeeded) : originalValue;
    originalValue = _.isNumber(Number(originalValue)) ? Number(originalValue) : originalValue;

    const rawValueNumber = _.isNumber(Number(rawValue)) ? Number(rawValue) : rawValue;

    // Format the value based on its magnitude, but avoid summarizing for certain units
    const unitsToAvoidSummarization = ['ms', 'sec', 's', 'h', 'min', 'd', 'mo', 'y', 'bpm', 'kcal', 'm', 'ft'];
    if (
        Math.abs(convertedValue) > 1000 &&
        !(
            unitsToAvoidSummarization.includes(unit) ||
            rule?.metric_unit?.some((u) => unitsToAvoidSummarization.includes(u.toLowerCase())) ||
            rule?.imperial_unit?.some((u) => unitsToAvoidSummarization.includes(u.toLowerCase()))
        )
    ) {
        const displayValue = summarizeNum(convertedValue);

        return {
            raw: rawValueNumber,
            display: (unitPosition === 'prefix' ? `${unit} ${displayValue}` : `${displayValue} ${unit}`)?.trim(),
            unit,
            rawValueInCurrentUnit: convertedValue
        };
    }

    return {
        raw: _.isNumber(Number(rawValue)) ? Number(rawValue) : rawValue,
        display: (unitPosition === 'prefix' ? `${unit} ${rawValueNumber}` : `${rawValueNumber} ${unit}`)?.trim(),
        unit,
        rawValueInCurrentUnit: rawValueNumber
    };
}

export function getMetricFormattingInfo(metricKey, allMetrics) {
    const metricInfo = allMetrics?.find((metric) => metric.attribute?.trim()?.toLowerCase() === metricKey?.trim().toLowerCase());

    if (!metricInfo) {
        return null;
    }

    return metricInfo?.display;
}

export function shouldMetricBeShown(metricKey, allMetrics, whereToBeShown) {
    const metricInfo = allMetrics.find((metric) => metric.attribute?.trim()?.toLowerCase() === metricKey?.trim().toLowerCase());

    if (!metricInfo) {
        return false;
    }

    return metricInfo?.display?.pages?.[whereToBeShown]?.display_by_default || false;
}

export function getAllMetricsForCategoryAndLevel(allMetrics, category, level = null, whereToBeShown = null) {
    let metrics = allMetrics;
    const isLevel = level || _.isNumber(level);

    if (!metrics) return;

    if (isLevel && !whereToBeShown) throw new Error('The page where to be shown is required when level is provided (utils.js)');

    if (isLevel && _.isString(level)) {
        level = [level];
    }

    if (category) {
        metrics = metrics.filter((metric) => metric.category?.trim()?.toLowerCase() === category?.trim().toLowerCase());
    }

    if (isLevel) {
        // check if all levels searched are met
        metrics = metrics.filter(
            (metric) => metric.display?.pages?.[whereToBeShown]?.levels?.filter((l) => level.includes(l)).length === level.length
        );
    }

    metrics = metrics.map((metric) => ({
        ...metric,
        categoryDisplay: metric.category ? _.capitalize(metric.category) : null,
        levelDisplay: metric.level ? _.capitalize(metric.level) : null
    }));

    if (whereToBeShown) {
        metrics = metrics.filter((metric) => metric.display?.pages?.[whereToBeShown].display_by_default);
    }

    return metrics;
}

export function generateRandomName() {
    const n1 = ['Blue', 'Green', 'Red', 'Orange', 'Violet', 'Indigo', 'Yellow'];
    const n2 = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Zero'];
    return n1[Math.round(Math.random() * (n1.length - 1))] + '-' + n2[Math.round(Math.random() * (n2.length - 1))];
}

// NOVU UTILS
export const novu_alert_channels = {
    email: 'Email',
    in_app: 'In App',
    chat: 'Chat',
    push: 'Push'
};

export const novu_alert_channel_icons = {
    email: '/icons/email-notification.svg',
    in_app: '/icons/app-notification.svg',
    chat: '/icons/chat-notification.svg'
};

export function get_relative_time(utcDateString) {
    const now = new Date();
    const utcDate = new Date(utcDateString);
    const diffInSeconds = Math.floor((now - utcDate) / 1000);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;

    if (diffInSeconds < minute) {
        return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < hour) {
        return `${Math.floor(diffInSeconds / minute)} minutes ago`;
    } else if (diffInSeconds < day) {
        return `${Math.floor(diffInSeconds / hour)} hours ago`;
    } else if (diffInSeconds < month) {
        return `${Math.floor(diffInSeconds / day)} days ago`;
    } else if (diffInSeconds < year) {
        return `${Math.floor(diffInSeconds / month)} months ago`;
    } else {
        return `${Math.floor(diffInSeconds / year)} years ago`;
    }
}

export function get_formatted_date(utcDateString) {
    // Ensure utcDate is a Date object
    const utcDate = new Date(utcDateString);

    // Specify options for formatting the hour and minute in AM/PM format
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    // Format only the time part using Intl.DateTimeFormat
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(utcDate);

    // Use the getDayWithSuffix function to get the day with the correct suffix
    const dayWithSuffix = getDayWithSuffix(utcDate);

    // Assemble the final string without duplicating the month and day part
    return `${utcDate.toLocaleString('default', { month: 'long' })} ${dayWithSuffix} at ${formattedTime}`;
}

function getDayWithSuffix(date) {
    const day = date.getDate();
    if (day > 3 && day < 21) return day + 'th';
    switch (day % 10) {
        case 1:
            return day + 'st';
        case 2:
            return day + 'nd';
        case 3:
            return day + 'rd';
        default:
            return day + 'th';
    }
}

// Enhanced deepOmit function to recursively omit specified properties from any arrays found in the object
function deepOmit(obj, propertiesToOmit) {
    // Check if obj is an array and apply deepOmit to each element
    if (_.isArray(obj)) {
        return obj.map((item) => deepOmit(item, propertiesToOmit));
    } else if (_.isObject(obj) && !_.isFunction(obj)) {
        // For objects, first omit the specified properties from the current level
        const omitted = _.omit(obj, propertiesToOmit);
        // Then, recursively apply deepOmit to any arrays or object properties
        Object.keys(omitted).forEach((key) => {
            if (_.isArray(omitted[key]) || _.isObject(omitted[key])) {
                omitted[key] = deepOmit(omitted[key], propertiesToOmit);
            }
        });
        return omitted;
    }
    // Return non-object, non-array values unchanged
    return obj;
}

// Custom comparator function for isEqualWith, accepting properties to omit
function customComparator(objValue, othValue, propertiesToOmit) {
    if (_.isObject(objValue) && _.isObject(othValue)) {
        // Directly compare the objects after omitting specified properties
        const omittedObjValue = deepOmit(objValue, propertiesToOmit);
        const omittedOthValue = deepOmit(othValue, propertiesToOmit);
        return _.isEqual(omittedObjValue, omittedOthValue);
    }
    // Return undefined to allow default comparison for other types
}

// Function to compare two structures (arrays or objects), omitting specified properties
export function compareWithOmittedProperties(structure1, structure2, propertiesToOmit) {
    // Wrap customComparator to ensure it receives propertiesToOmit
    const comparatorWrapped = (objValue, othValue) => customComparator(objValue, othValue, propertiesToOmit);
    return _.isEqualWith(structure1, structure2, comparatorWrapped);
}

export function isJSON(input) {
    const inputStr = typeof input === 'string' ? input : JSON.stringify(input);

    try {
        JSON.parse(inputStr);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/**
 * Constructs query parameters for the API calls.
 * Includes all values except null and undefined.
 * @param {Object} params - The parameters to be included in the query string.
 * @returns {string} The constructed query string.
 */
export function constructQueryParams(params) {
    let queryParams = [];
    for (const [key, value] of Object.entries(params)) {
        let finalValue = value;
        // Check if value is not null or undefined
        if (finalValue !== NaN && finalValue !== null && finalValue !== undefined && (_.isArray(finalValue) ? !!finalValue.length : true)) {
            if (_.isArray(finalValue)) {
                finalValue = finalValue.join(',');
            }

            queryParams.push(`${key}=${encodeURIComponent(finalValue)}`);
        }
    }
    return queryParams.join('&');
}

const checkAccess = (required = [], userItems, checkType) => {
    if (checkType === 'or') {
        return _.some(required, (item) => userItems.find((userItem) => userItem.includes(item))) || false;
    } else {
        return _.every(required, (item) => userItems.find((userItem) => userItem.includes(item))) || false;
    }
};

/**
 *
 * @param {*} fronteggUser - The frontegg user sent by the frontegg state
 * @param {*} config - The config object containing the permissions and roles.
 * The permissions and roles objects should contain the values and the type of check to be performed.
 * The type of check can be 'and' or 'or'. The default is 'and'.
 * @returns - A boolean value indicating whether the user has access or not.
 */
export const hasAccess = (fronteggUser, config) => {
    if (!fronteggUser) {
        return false;
    }

    const { permissions, roles } = config;
    const permissionCheckType = permissions?.type || 'and';
    const roleCheckType = roles?.type || 'and';

    const userPermissions = fronteggUser.permissions?.map((permission) => permission.key) || [];
    const currentTenant = fronteggUser?.tenants?.find((x) => x.tenantId === fronteggUser?.tenantId);
    const userRoles = currentTenant.roles.map((r) => r.key);

    const userHasPermissions = permissions ? checkAccess(permissions.values, userPermissions, permissionCheckType) : true;
    const userHasRoles = roles ? checkAccess(roles.values, userRoles, roleCheckType) : true;

    return userHasPermissions && userHasRoles;
};

export const userHasAccess = (permissions = [], user = null, roles = [], type = 'and') => {
    if (!user) {
        return false;
    }

    const finalPermissions = {
        values: permissions,
        type
    };

    const finalRoles = {
        values: roles,
        type
    };

    return hasAccess(user, { permissions: finalPermissions, roles: finalRoles });
};

const OPERATORS = {
    EQ: 'EQ',
    NEQ: 'NEQ',
    GT: 'GT',
    GTE: 'GTE',
    LT: 'LT',
    LTE: 'LTE',
    IN: 'IN',
    NIN: 'NIN',
    AND: 'AND',
    OR: 'OR',
    NAND: 'NAND',
    NOR: 'NOR',
    CONTAINS: 'CONTAINS',
    STARTS_WITH: 'STARTS_WITH',
    ENDS_WITH: 'ENDS_WITH',
    BETWEEN: 'BETWEEN',
    NOT: 'NOT',
    EXISTS: 'EXISTS'
};

/**
 * Evaluates a condition against the current value of a related control.
 * @param {Object} condition - The condition to evaluate.
 * @param {Array} allControls - All controls in the form.
 * @returns {boolean} - True if the condition is met, false otherwise.
 */
export const evaluateCondition = (condition, allControls) => {
    const relatedControl = allControls.find((c) => c.name === condition.config);

    if (!relatedControl) return false;

    // Get the current value or default value of the related control
    const relatedControlValue = _.has(relatedControl.value, 'current_value') ? relatedControl.value.current_value : relatedControl.value?.default;

    switch (condition.operator) {
        case OPERATORS.EQ:
            return relatedControlValue === condition.value;
        case OPERATORS.NEQ:
            return relatedControlValue !== condition.value;
        case OPERATORS.GT:
            return relatedControlValue > condition.value;
        case OPERATORS.GTE:
            return relatedControlValue >= condition.value;
        case OPERATORS.LT:
            return relatedControlValue < condition.value;
        case OPERATORS.LTE:
            return relatedControlValue <= condition.value;
        case OPERATORS.IN:
            if (Array.isArray(relatedControlValue)) {
                if (Array.isArray(condition.value)) {
                    return relatedControlValue.every((val) => condition.value.includes(val));
                } else {
                    return condition.value.includes(relatedControlValue);
                }
            } else {
                if (Array.isArray(condition.value)) {
                    return condition.value.includes(relatedControlValue);
                } else {
                    return condition.value.includes(relatedControlValue);
                }
            }

        case OPERATORS.NIN:
            if (Array.isArray(relatedControlValue)) {
                if (Array.isArray(condition.value)) {
                    return relatedControlValue.every((val) => !condition.value.includes(val));
                } else {
                    return !condition.value.includes(relatedControlValue);
                }
            } else {
                if (Array.isArray(condition.value)) {
                    return !condition.value.includes(relatedControlValue);
                } else {
                    return !condition.value.includes(relatedControlValue);
                }
            }

        case OPERATORS.CONTAINS:
            return relatedControlValue.includes(condition.value);
        case OPERATORS.STARTS_WITH:
            return typeof relatedControlValue === 'string' && relatedControlValue.startsWith(condition.value);
        case OPERATORS.ENDS_WITH:
            return typeof relatedControlValue === 'string' && relatedControlValue.endsWith(condition.value);
        case OPERATORS.BETWEEN:
            return relatedControlValue >= condition.value[0] && relatedControlValue <= condition.value[1];
        case OPERATORS.NOT:
            return !evaluateCondition(condition.condition, allControls);
        case OPERATORS.EXISTS:
            return relatedControlValue !== undefined && relatedControlValue !== null;
        default:
            return false;
    }
};

/**
 * Adjusts the visibility and spacing of a control based on conditions.
 * @param {Object} control - The control to adjust.
 * @param {Array} allControls - All controls in the form.
 * @param {boolean} parentShown - Indicates if the parent control is shown.
 * @returns {Object} - An object containing the result of the evaluation and the updated control.
 */
export const adjustControlConditionalVisibility = (control, allControls, parentShown = true) => {
    if (!control.conditions || control.conditions.length === 0) {
        control.display_in_ui = parentShown;
        return { result: parentShown, control };
    }

    const result = control.conditions.every((condition) => {
        // Handle compound conditions (AND, OR, NAND, NOR)
        if ([OPERATORS.AND, OPERATORS.OR, OPERATORS.NAND, OPERATORS.NOR].includes(condition.operator)) {
            const subConditions = condition.conditions;

            if (condition.operator === OPERATORS.AND) {
                // AND: All subconditions must be true
                return subConditions.every((subCondition) =>
                    adjustControlConditionalVisibility({ conditions: [subCondition] }, allControls, parentShown)
                )?.result;
            } else if (condition.operator === OPERATORS.OR) {
                // OR: At least one subcondition must be true
                return subConditions.some((subCondition) =>
                    adjustControlConditionalVisibility({ conditions: [subCondition] }, allControls, parentShown)
                )?.result;
            } else if (condition.operator === OPERATORS.NAND) {
                // NAND: Not all subconditions must be true
                return !subConditions.every((subCondition) =>
                    adjustControlConditionalVisibility({ conditions: [subCondition] }, allControls, parentShown)
                )?.result;
            } else if (condition.operator === OPERATORS.NOR) {
                // NOR: None of the subconditions must be true
                return !subConditions.some((subCondition) =>
                    adjustControlConditionalVisibility({ conditions: [subCondition] }, allControls, parentShown)
                )?.result;
            }
        } else {
            // Handle simple conditions
            return evaluateCondition(condition, allControls);
        }

        return false;
    });

    control.display_in_ui = result;
    control.space_left = result;

    return { result, control };
};

/**
 * Adjusts the values and default value of a control based on conditions.
 * @param {Object} control - The control to adjust.
 * @param {Array} allControls - All controls in the form.
 * @returns {Object} - The updated control with conditional values.
 */
export const getConditionalValues = (control, allControls) => {
    if (control.value.control === 'one-select' || control.value.control === 'multiselect') {
        const conditionalValues = control.value?.conditions?.filter((item) => {
            if (item.condition) {
                return evaluateCondition(item.condition, allControls);
            }

            return true;
        });

        if (conditionalValues?.length > 0) {
            // Set raw_values and default value based on the matched condition
            control.value.raw_values = conditionalValues.map((item) => item.raw_values).flat();
            control.value.default = conditionalValues.find((item) => item.default)?.default || control.value.default;
        } else {
            // If no conditions are met, use the root-level raw_values
            control.value.raw_values = control.value.raw_values;
        }
    }
    return control;
};

/**
 * Adjusts the values and default values of all controls in the form based on conditions.
 * @param {Array} controls - All controls in the form.
 * @returns {Array} - The updated controls with conditional values.
 */
export const adjustControlConditionalValues = (controls) => {
    return controls.map((control) => getConditionalValues(control, controls));
};
