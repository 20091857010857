/* eslint-disable indent */
import { defineStore } from 'pinia';
import * as destinationsApi from '../api/destinations.api';
import { ref, computed } from 'vue';
import { useLoaderStore } from './loader.store';
import { useMetricsStore } from './metrics.store';
import { usePipelinesStore } from './pipelines.store';
import _ from 'lodash';
import { getConnectorDisplayName } from '../utils/constants';
import { useRouter } from 'vue-router';
import { retryApiCall } from '../utils/utils';

export const useDestinationsStore = defineStore('destinations', () => {
    const destinations = ref(undefined);
    const allDestinationsBrief = ref(undefined);
    const total = ref(0);
    const page = ref(0);
    const pageSize = ref(0);
    const destinationConnectors = ref({});
    const destinationConfigurations = ref({});
    const destinationTopicSampleScripts = ref({});
    const destinationTopicSampleTextScripts = ref({});
    const lastSearchQuery = ref(null);
    const router = useRouter();

    const loader = useLoaderStore();

    return {
        // State
        destinations,
        // Getters
        all: computed(() => destinations.value),
        allBrief: computed(() => allDestinationsBrief.value),
        total: computed(() => total.value),
        page: computed(() => page.value),
        pageSize: computed(() => pageSize.value),
        destinationConnectors: computed(() => destinationConnectors.value),
        byId: (id) =>
            computed(() => {
                return _.find(destinations.value, { id });
            }),
        connectors: computed(() => {
            let result = [];
            if (destinations.value != null && destinations.value.length > 0) {
                let connectors = destinations.value.map((a) => a.connector);
                if (Array.isArray(connectors)) {
                    connectors.forEach((connector) => {
                        const index = result.findIndex((object) => object.name === connector.charAt(0).toUpperCase() + connector.slice(1));
                        if (index === -1) {
                            const item = {
                                name: connector.charAt(0).toUpperCase() + connector.slice(1),
                                connector: connector
                            };
                            result.push(item);
                        }
                    });
                }
            }
            return result;
        }),
        configurations: computed(() => destinationConfigurations.value),
        // Actions
        getById: async function (id, customMessage, forceLoad = false) {
            if (!id) return;

            const found = _.find(destinations.value, { id });
            if (found && !forceLoad) {
                return found;
            }

            loader.activateLoading('destination.fetch');
            const result = await destinationsApi
                .getDestination(id)
                .then(async (data) => {
                    if (!data) {
                        router.push({ name: '404', params: { customMessage } });
                        return;
                    }

                    if (!destinations.value) {
                        destinations.value = [data];
                    }

                    if (destinations.value?.length) {
                        destinations.value = destinations?.value?.map((destination) => {
                            if (destination.id === id) {
                                return {
                                    ...destination,
                                    ...data
                                };
                            }

                            return destination;
                        });
                    }

                    const pipelinesStore = usePipelinesStore();
                    if (pipelinesStore.pipelines?.length) {
                        pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                            if (pipeline.destination.id === id) {
                                return {
                                    ...pipeline,
                                    destination: {
                                        ...pipeline.destination,
                                        ...data
                                    }
                                };
                            }

                            return pipeline;
                        });
                    }

                    return data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('destination.fetch');
                });

            return result;
        },
        fetch: async function (pageNumber, pageSizeNumber) {
            if (pageNumber === page.value && !_.isEmpty(destinations.value) && pageSize.value === pageSizeNumber) return destinations.value;

            loader.activateLoading('destinations.fetch');
            const destinationConnectorsList = Object.values(destinationConnectors.value || {}).flat();

            if (destinationConnectorsList.length <= 0) {
                await destinationsApi
                    .fetchDestinationConnectors()
                    .then(async (data) => {
                        destinationConnectors.value = data;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            return destinationsApi
                .fetchDestinations(pageNumber, pageSizeNumber)
                .then(async (data) => {
                    if (Array.isArray(data.result)) {
                        data.result.forEach((destination) => {
                            destination['connectorDisplayName'] = getConnectorDisplayName(destination.connector);
                        });

                        const ids = data.result.map((destination) => destination.id);
                        const metricsStore = useMetricsStore();
                        await metricsStore.destinations.fetchAllInlineDestinationMetrics(ids);
                    }

                    page.value = pageNumber;
                    total.value = data.total;
                    destinations.value = data.result;
                    pageSize.value = data.page_size;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('destinations.fetch');
                });
        },

        fetchAllBrief: async function (fetchMetrics = true) {
            loader.activateLoading('destinations.fetchAllBrief');

            return allDestinationsBrief.value && !_.isEmpty(allDestinationsBrief.value)
                ? allDestinationsBrief
                : destinationsApi
                      .fetchAllDestinationsBrief()
                      .then(async (data) => {
                          if (Array.isArray(data)) {
                              data.forEach((destination) => {
                                  destination['connectorDisplayName'] = getConnectorDisplayName(destination.connector);
                              });

                              if (fetchMetrics) {
                                  const ids = data.map((destination) => destination.id);
                                  const metricsStore = useMetricsStore();
                                  await metricsStore.destinations.fetchAllInlineDestinationMetrics(ids);
                              }
                          }
                          allDestinationsBrief.value = data;
                          return data;
                      })
                      .catch((err) => {
                          console.error(err);
                      })
                      .finally(() => {
                          loader.deactivateLoading('destinations.fetchAllBrief');
                      });
        },
        fetchCreated: async function (pageNumber = page.value, pageSizeNumber = pageSize.value, searchQuery = null) {
            if (
                !searchQuery &&
                searchQuery !== '' &&
                !lastSearchQuery.value &&
                lastSearchQuery.value !== '' &&
                pageNumber === page.value &&
                !_.isEmpty(destinations.value) &&
                pageSize.value === pageSizeNumber
            )
                return destinations.value;

            loader.activateLoading('sources.fetchCreated');

            return destinationsApi
                .fetchDestinations(pageNumber + 1, pageSizeNumber, searchQuery)
                .then(async (data) => {
                    if (Array.isArray(data.result)) {
                        data.result.forEach((destination) => {
                            destination['connectorDisplayName'] = getConnectorDisplayName(destination.connector);
                        });
                    }

                    page.value = pageNumber;
                    total.value = data.total;
                    destinations.value = data.result;
                    lastSearchQuery.value = searchQuery;
                    pageSize.value = data.page_size;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('sources.fetchCreated');
                });
        },
        fetchConnectors: async function () {
            loader.activateLoading('destinationsConnectors.fetch');

            const destinationConnectorsList = Object.values(destinationConnectors.value || {}).flat();

            return destinationConnectorsList.length > 0
                ? destinationConnectors.value
                : destinationsApi
                      .fetchDestinationConnectors()
                      .then(async (data) => {
                          destinationConnectors.value = data;

                          if (allDestinationsBrief.value && !_.isEmpty(allDestinationsBrief.value)) {
                              allDestinationsBrief.value = allDestinationsBrief.value.map((destination) => {
                                  destination['connectorDisplayName'] = getConnectorDisplayName(destination.connector);

                                  return destination;
                              });
                          }

                          if (!_.isEmpty(destinations.value)) {
                              destinations.value = destinations.value.map((destination) => {
                                  destination['connectorDisplayName'] = getConnectorDisplayName(destination.connector);

                                  return destination;
                              });
                          }
                          return data;
                      })
                      .catch((err) => {
                          console.error(err);
                      })
                      .finally(() => {
                          loader.deactivateLoading('destinationsConnectors.fetch');
                      });
        },
        fetchConfiguration: async function (connector) {
            loader.activateLoading('destinations.fetchConfiguration');

            return destinationsApi
                .fetchDestinationConfiguration(connector)
                .then((data) => {
                    if (Array.isArray(data)) {
                        data.forEach((obj) => {
                            obj.display_in_ui = true;
                        });
                    }

                    destinationConfigurations.value[connector] = data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('destinations.fetchConfiguration');
                });
        },
        delete: async function (id) {
            loader.activateLoading(`destinations.delete`);
            return destinationsApi
                .deleteDestinationConnection(id)
                .then(() => {
                    destinations.value = destinations.value.filter((destination) => destination.id !== id);
                    allDestinationsBrief.value = allDestinationsBrief.value.filter((destination) => destination.id !== id);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`destinations.delete`);
                });
        },
        stop: async function (id, hideToast = false) {
            loader.activateLoading(`destinations.stop.${id}`);
            return destinationsApi
                .stopDestinationConnection(id, hideToast)
                .then(() => {
                    destinations.value = destinations?.value?.map((destination) => {
                        if (destination.id === id) {
                            const metricsStore = useMetricsStore();
                            metricsStore.destinations.updateStatus(id, 'Stopped');
                            return {
                                ...destination,
                                connector_status: 'Stopped'
                            };
                        }

                        return destination;
                    });

                    const pipelinesStore = usePipelinesStore();

                    pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                        if (pipeline.destination.id === id) {
                            return {
                                ...pipeline,
                                destination: {
                                    ...pipeline.destination,
                                    connector_status: 'Stopped'
                                }
                            };
                        }

                        return pipeline;
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`destinations.stop.${id}`);
                });
        },
        pause: async function (id, hideToast = false) {
            loader.activateLoading(`destinations.pause.${id}`);
            return destinationsApi
                .pauseDestinationConnection(id, hideToast)
                .then(() => {
                    destinations.value = destinations?.value?.map((destination) => {
                        if (destination.id === id) {
                            const metricsStore = useMetricsStore();
                            metricsStore.destinations.updateStatus(id, 'Paused');
                            return {
                                ...destination,
                                connector_status: 'Paused'
                            };
                        }

                        return destination;
                    });

                    const pipelinesStore = usePipelinesStore();

                    pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                        if (pipeline.destination.id === id) {
                            return {
                                ...pipeline,
                                destination: {
                                    ...pipeline.destination,
                                    connector_status: 'Paused'
                                }
                            };
                        }

                        return pipeline;
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`destinations.pause.${id}`);
                });
        },
        resume: async function (id, hideToast = false) {
            loader.activateLoading(`destinations.resume.${id}`);
            return destinationsApi
                .resumeDestinationConnection(id, hideToast)
                .then(() => {
                    destinations.value = destinations?.value?.map((destination) => {
                        if (destination.id === id) {
                            const metricsStore = useMetricsStore();
                            metricsStore.destinations.updateStatus(id, 'Active');
                            return {
                                ...destination,
                                connector_status: 'Active'
                            };
                        }

                        return destination;
                    });

                    const pipelinesStore = usePipelinesStore();

                    pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                        if (pipeline.destination.id === id) {
                            return {
                                ...pipeline,
                                destination: {
                                    ...pipeline.destination,
                                    connector_status: 'Active'
                                }
                            };
                        }

                        return pipeline;
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`destinations.resume.${id}`);
                });
        },
        restart: async function (id) {
            loader.activateLoading(`destinations.restart.${id}`);
            return destinationsApi
                .restartDestinationConnection(id)
                .then(() => {})
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`destinations.restart.${id}`);
                });
        },
        create: async function (connection) {
            loader.activateLoading('destinations.create');
            return destinationsApi
                .createDestination(connection)
                .then((data) => {
                    data = {
                        ...data,
                        connectorDisplayName: getConnectorDisplayName(data.connector)
                    };

                    if (!destinations.value) {
                        destinations.value = [];
                    }

                    if (!allDestinationsBrief.value) {
                        allDestinationsBrief.value = [];
                    }

                    destinations.value.unshift(data);
                    if (allDestinationsBrief.value?.length) allDestinationsBrief.value.unshift(data);

                    if (destinations.value?.length > pageSize.value) {
                        destinations.value = destinations.value.slice(0, pageSize.value);
                    }

                    window.analytics?.track('Destination Created', {
                        date: new Date(),
                        source: data.connector,
                        id: data.id,
                        name: data.name
                    });

                    const metricsStore = useMetricsStore();
                    metricsStore.destinations.fetchAllInlineDestinationMetrics([data.id]);
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('destinations.create');
                });
        },
        edit: async function (connection, id) {
            loader.activateLoading('destinations.edit');
            return retryApiCall(
                (retriesLeft = 5) =>
                    destinationsApi.editDestination(connection, id, retriesLeft > 0).then((data) => {
                        if (!destinations.value) {
                            destinations.value = [
                                {
                                    ...connection,
                                    ...data
                                }
                            ];
                        }

                        if (destinations.value?.length) {
                            destinations.value = destinations?.value?.map((destination) => {
                                if (destination.id === id) {
                                    return {
                                        ...destination,
                                        ...connection,
                                        ...data
                                    };
                                }

                                return destination;
                            });
                        }

                        if (allDestinationsBrief.value?.length) {
                            allDestinationsBrief.value = allDestinationsBrief?.value?.map((destination) => {
                                if (destination.id === id) {
                                    return {
                                        ...destination,
                                        ...connection,
                                        ...data
                                    };
                                }

                                return destination;
                            });
                        }

                        const pipelinesStore = usePipelinesStore();

                        if (pipelinesStore.pipelines?.length) {
                            pipelinesStore.pipelines = pipelinesStore.pipelines?.map((pipeline) => {
                                if (pipeline.destination.id === id) {
                                    return {
                                        ...pipeline,
                                        destination: {
                                            ...pipeline.destination,
                                            ...connection,
                                            ...data
                                        }
                                    };
                                }

                                return pipeline;
                            });
                        }

                        return data;
                    }),
                408,
                5,
                10000
            )
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('destinations.edit');
                });
        },
        getTopicSampleScripts: async function (destinationId, topicIds) {
            loader.activateLoading('destinations.scripts');

            if (!topicIds?.length && !_.isEmpty(destinationTopicSampleTextScripts.value[destinationId])) {
                return destinationTopicSampleTextScripts.value[destinationId];
            }

            let existingSamples = [];

            let remainingTopicIds = topicIds;

            if (topicIds?.length && destinationTopicSampleScripts.value[destinationId]?.length) {
                const existingSamples = destinationTopicSampleScripts.value[destinationId]?.filter((sample) => topicIds.includes(sample.topicId));
                const stillToFetch = topicIds.filter((topicId) => !existingSamples.find((sample) => sample.topicId === topicId));

                remainingTopicIds = stillToFetch;
            } else {
                destinationTopicSampleScripts.value[destinationId] = [];
            }

            if (topicIds?.length && !remainingTopicIds?.length) {
                return existingSamples;
            }

            return destinationsApi
                .getTopicSampleScripts(destinationId, remainingTopicIds)
                .then((data) => {
                    if (typeof data === 'string') {
                        destinationTopicSampleTextScripts.value[destinationId] = data;
                        return data;
                    }

                    destinationTopicSampleScripts.value[destinationId] = [
                        ...destinationTopicSampleScripts.value[destinationId],
                        ..._.map(data, (item, topicId) => ({ topicId, info: item }))
                    ];

                    return [...existingSamples, ..._.map(data, (item, topicId) => ({ topicId, info: item }))];
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('destinations.scripts');
                });
        }
    };
});
