import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLoaderStore } from './loader.store';
import * as userApi from '../api/user.api';
import _ from 'lodash';

export const useUserStore = defineStore('frontegg', () => {
    const tenants = ref(undefined);
    const loader = useLoaderStore();
    const celloToken = ref(undefined);

    return {
        tenants,
        // Getters
        allTenants: computed(() => {
            const sortTenants = (tenants) => {
                // Check if the tenants array is empty
                if (!tenants || tenants.length === 0) {
                    return null;
                }

                // Sort the tenants array
                let sortedTenants = _.sortBy(tenants, 'tenant_name');

                // Recursively sort children, if they exist
                sortedTenants.forEach((tenant) => {
                    if (tenant.children && tenant.children.length > 0) {
                        tenant.children = sortTenants(tenant.children);
                    }

                    return;
                });

                return sortedTenants;
            };

            return sortTenants(tenants.value);
        }),
        // Actions
        fetchTenantTree: async function () {
            loader.activateLoading('user.tenants.fetch');
            return userApi
                .fetchTenantTree()
                .then((data) => {
                    tenants.value = data;
                    return data;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('user.tenants.fetch');
                });
        },
        inviteColleague: async function (payload) {
            loader.activateLoading('user.inviteColleague');
            return userApi
                .inviteColleague(payload)
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('user.inviteColleague');
                });
        },
        fetchCelloToken: async function () {
            if (celloToken.value) {
                return celloToken.value;
            }

            loader.activateLoading('user.fetchCelloToken');
            return userApi
                .fetchCelloToken()
                .then((data) => {
                    celloToken.value = data;

                    return data;
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('user.fetchCelloToken');
                });
        },
        sendCelloReferrerUCC: async function (ucc) {
            loader.activateLoading('user.sendCelloReferrerUCC');
            return userApi
                .sendCelloReferrerUCC(ucc)
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('user.sendCelloReferrerUCC');
                });
        }
    };
});
