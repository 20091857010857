<script setup>
import { useMetricsStore } from '../../stores/metrics.store';
import AppLoader from '../../components/AppLoader.vue';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import _ from 'lodash';
import { computed, ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useDestinationsStore } from '../../stores/destinations.store';
import Pagination from '../../components/Pagination.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import useConfirmBeforeAction from '../../composables/useConfirmBeforeAction.js';
import useConfirmBeforeWarning from '../../composables/useConfirmBeforeWarning.js';
import RowActionMenu from '../../components/RowActionMenu.vue';
import Multiselect from 'vue-multiselect';

import { usePipelinesStore } from '../../stores/pipelines.store';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
import { ClockIcon, ChevronDoubleRightIcon, ChevronRightIcon, CheckCircleIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { getStatusColor, getMetricFormattingInfo, formatter, getAllMetricsForCategoryAndLevel, isJSON, userHasAccess } from '../../utils/utils';
import { DATE_FILTER_OPTIONS } from '../../utils/constants';
import ConnectorTab from '../../components/ConnectorTab.vue';
import ErrorMessage from '../../components/ErrorMessage.vue';
import WebSocketService from '../../services/webSocket.service';
import SQLViewsModal from '../../components/SQLViewsModal.vue';
import StatCard from '../../components/global/StatCard.vue';
import { watch } from 'vue';
import Tabs from '@/components/global/Tabs.vue';
import HighChart from '../../components/HighChart.vue';
import DateFilter from '@/components/global/DateFilter.vue';
import moment from 'moment-timezone';
import { useAuthStore } from '@/stores/auth.store';
import { toast } from 'vue3-toastify';

const router = useRouter();
const route = useRoute();

const props = defineProps(['id']);
const pipelines = usePipelinesStore();
const destinationsStore = useDestinationsStore();
const metrics = useMetricsStore();
const authStore = useAuthStore();

const destinationLatestMetrics = computed(() => {
    return metrics.destinations.allLatest[props.id] || {};
});

const connectorInfo = computed(() => {
    return Object.values(destinationsStore.destinationConnectors || {})
        .flat()
        .find((c) => c.connector === destination.value?.connector);
});

let destination = ref();
let defaultDestination = ref();
let generalLoading = ref(false);
let metricsLoading = ref(false);
let configurationLoading = ref(false);
let actionLoading = ref(false);
let timeseriesLoading = ref(false);
let topicsLoading = ref(false);
let selectedTopicsDateFilter = ref({});
let viewsDownloading = ref(false);
let tabLoading = ref(false);
let viewsLoading = ref(false);
let destinationConfig = ref();
let formError = ref('');
let hidePromptTemporary = ref(false);

let canSave = ref(false);

let page = ref(0);
let pageSize = ref(0);
let checkedTopicFilters = ref([]);

let topicsScript = ref([]);
let selectedTopicsScript = ref([]);
let openViewsModal = ref(false);
let selectedTimeseriesDateFilter = ref({});

let allSelectedMetricsForChart = ref([]);
let plotSpecificMetrics = ref(null);
let specificTopicMetricsToUse = ref(null);

const filters = [
    {
        title: 'Topics per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            { value: 5, displayValue: '5/page' },
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
            // { value: 100, displayValue: '100/page' },
            // { value: 200, displayValue: '200/page' },
            // { value: 500, displayValue: '500/page' }
        ]
    }
];
initializeTopicsFilters();

onBeforeUnmount(() => {
    removeWebSocketMessage();
});

onBeforeRouteLeave((to, from, next) => {
    if (tabLoading.value || metricsLoading.value || configurationLoading.value || generalLoading.value || timeseriesLoading.value) {
        return next();
    }

    if (!canSave.value || hidePromptTemporary.value) {
        return next();
    }

    const answer = window.confirm('Are you sure you want to leave? You have unsaved changes.');

    if (answer) {
        return next();
    } else {
        return next(false);
    }
});

onMounted(async () => {
    try {
        generalLoading.value = true;
        tabLoading.value = true;
        configurationLoading.value = true;
        metricsLoading.value = true;

        const tabQuery = route.query.tab;

        // topic related queries
        const topicTableDateFilterQuery = route.query.selectedTopicsDateFilter;
        const topicTableGeneralFilterQuery = route.query.topicFilter ? JSON.parse(route.query.topicFilter) : null;

        // timeseries related queries
        const selectedTimeseriesMetricsQuery = route.query.selectedTimeseriesMetrics;
        const selectedTimeseriesDateFilterQuery = route.query.selectedTimeseriesDateFilter;

        setCurrentTab(tabQuery || 'Status');

        // topic related queries
        selectedTopicsDateFilter.value = topicTableDateFilterQuery ? JSON.parse(topicTableDateFilterQuery) : selectedTopicsDateFilter.value;
        checkedTopicFilters.value = topicTableGeneralFilterQuery
            ? { ...topicTableGeneralFilterQuery?.filter, [topicTableGeneralFilterQuery?.filter?.name]: [topicTableGeneralFilterQuery?.filterOption] }
            : checkedTopicFilters.value;

        // timeseries related queries
        allSelectedMetricsForChart.value = selectedTimeseriesMetricsQuery
            ? JSON.parse(selectedTimeseriesMetricsQuery)
            : allSelectedMetricsForChart.value;
        selectedTimeseriesDateFilter.value = selectedTimeseriesDateFilterQuery
            ? JSON.parse(selectedTimeseriesDateFilterQuery)
            : selectedTimeseriesDateFilter.value;

        metricsLoading.value = true;

        let metricsCallToDo = [
            metrics.destinations
                .fetch(props.id, {
                    topic_list_from: page.value * pageSize.value,
                    topic_list_size: pageSize.value,
                    level: 'connector',
                    time_type: !_.isEmpty(selectedTimeseriesDateFilter.value) ? 'latest' : null
                })
                .then(() => (metricsLoading.value = false))
        ];

        if (!_.isEmpty(selectedTimeseriesDateFilter.value)) {
            timeseriesLoading.value = true;
            metricsCallToDo.push(
                handleTimeseriesDateFilterInput({ ...selectedTimeseriesDateFilter.value, value: selectedTimeseriesDateFilter.value }).then(
                    () => (timeseriesLoading.value = false)
                )
            );
        }

        if (topicTableGeneralFilterQuery) {
            topicsLoading.value = true;
            metricsCallToDo.push(onTopicsFilterToggle(topicTableGeneralFilterQuery).then(() => (topicsLoading.value = false)));
        } else if (topicTableDateFilterQuery) {
            topicsLoading.value = true;
            metricsCallToDo.push(
                handleTopicsDateFilterInput({ ...selectedTopicsDateFilter.value, value: selectedTopicsDateFilter.value }).then(
                    () => (topicsLoading.value = false)
                )
            );
        } else {
            topicsLoading.value = true;
            metricsCallToDo.push(
                metrics.destinations
                    .fetch(props.id, { topic_list_from: page.value * pageSize.value, topic_list_size: pageSize.value, level: 'topic' })
                    .then(() => (topicsLoading.value = false))
            );
        }

        setCurrentTab(route.query.tab || 'Status');

        await Promise.all([
            destinationsStore.getById(props.id, route?.query?.customMessage).then((data) => {
                destination.value = JSON.parse(JSON.stringify(data ?? {}));
                defaultDestination.value = _.cloneDeep(destination.value);

                if (route?.query?.customMessage && data) {
                    router.push({ query: { ...route.query, customMessage: undefined } });
                }

                generalLoading.value = false;
            }),
            destinationsStore.fetchConnectors(),
            ...metricsCallToDo
        ]);

        tabLoading.value = false;

        await destinationsStore.fetchConfiguration(destination.value.connector).then(() => {
            destinationConfig.value = destinationsStore.configurations[destination.value.connector];
        });

        addWebSocketMessage();
    } finally {
        tabLoading.value = false;
        generalLoading.value = false;
        configurationLoading.value = false;
        metricsLoading.value = false;
    }
});

const setViewsModelOpenStatus = async (topic, value) => {
    openViewsModal.value = value;

    if (value) {
        await setTopicsScript([topic.id]);
    } else {
        await setTopicsScript();
    }
};

async function setTopicsScript(topicIds = []) {
    if (destination.value && destination.value.connector == 'snowflake') {
        viewsLoading.value = true;
        const rawSampleScriptsData = await destinationsStore.getTopicSampleScripts(props.id, topicIds);

        const sampleScriptsData = rawSampleScriptsData.map((topicInfo) => {
            return {
                topicName: topicInfo.topicId,
                scriptName: topicInfo.info?.dynamic_table.display_name,
                scriptValue: topicInfo.info?.dynamic_table.value
            };
        });

        topicsScript.value = sampleScriptsData;

        selectedTopicsScript.value = [
            {
                topicName: topicsScript.value[0]?.topicName,
                scriptName: 'Dynamic tables',
                scriptValue: topicsScript.value.map((item) => item.scriptValue).join('\n\n')
            }
        ];

        viewsLoading.value = false;
    }

    if (topicsScript.value.length <= 0) {
        const viewsIndex = tabs.findIndex((tab) => tab.name === 'Views');

        if (viewsIndex !== -1) {
            tabs.splice(viewsIndex, 1);
        }
    }
}

const availableMetrics = computed(() => {
    if (!connectorInfo.value?.metrics || _.isEmpty(connectorInfo.value?.metrics)) return null;

    const topicMetrics = getAllMetricsForCategoryAndLevel(connectorInfo.value?.metrics, null, 'topic', 'destinations');
    //const connectorMetrics = getAllMetricsForCategoryAndLevel(connectorInfo.value?.metrics, null, ['connector', 'task'], 'destinations');

    return {
        topic: topicMetrics
        // connector: connectorMetrics
    };
});

const topics = (useSpecificMetrics) => {
    const compValue = computed(() => {
        return (
            Object.keys(useSpecificMetrics?.topics || destinationLatestMetrics?.value?.topics || {})
                ?.map((t) => {
                    const topicName = destination.value?.topics?.find(
                        (x) => t.toLowerCase().includes(x.toLowerCase()) && t.split(x)?.[1] === '' // || !t.split(x)?.[1])
                    );

                    return {
                        id: t,
                        name: topicName
                        // snapshotStatus: topicMetric?.status,
                        // snapshotDisplayStatus: _.capitalize(topicMetric?.status),
                        // snapshotSubmitTimestamp: topicMetric?.submit_timestamp
                    };
                })
                .filter((x) => x) || []
        );
    });

    return compValue.value;
};

const destinationTimeseriesConnectorMetrics = computed(() => {
    return metrics.destinations.allTimeseries[props.id]?.connector || {};
});

const listOfTimeseriesMetricOptions = computed(() => {
    if (generalLoading.value || timeseriesLoading.value) return [];

    const defaultDateFilter =
        selectedTimeseriesDateFilter.value.key === 'last24h' || !selectedTimeseriesDateFilter.value || !selectedTimeseriesDateFilter.value?.key;
    const metricsToUse = defaultDateFilter ? destinationTimeseriesConnectorMetrics.value : plotSpecificMetrics.value?.connector;

    let availableTimeSeriesMetricKeys = Object.keys(metricsToUse || {});

    availableTimeSeriesMetricKeys = availableTimeSeriesMetricKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, connectorInfo.value?.metrics);

            if (!metricInfo) {
                return null;
            }

            return {
                key,
                ...metricInfo
            };
        })
        .filter((x) => x && metricsToUse[x.key]?.length);

    if (
        !availableTimeSeriesMetricKeys?.length ||
        (availableTimeSeriesMetricKeys?.length &&
            allSelectedMetricsForChart.value?.map((x) => x.key).some((x) => !availableTimeSeriesMetricKeys.map((y) => y.key).includes(x)))
    ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        allSelectedMetricsForChart.value = [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        delete route.query.selectedTimeseriesMetrics;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        router.push(route.query);
    }

    return availableTimeSeriesMetricKeys;
});

watch(
    () => listOfTimeseriesMetricOptions.value,
    (newValue) => {
        if (!allSelectedMetricsForChart.value.length && newValue.length) {
            allSelectedMetricsForChart.value = [newValue[0]];
        }
    }
);

watch(
    () => allSelectedMetricsForChart.value,
    (newValue) => {
        if (newValue.length) {
            router.push({ query: { ...route.query, selectedTimeseriesMetrics: JSON.stringify(newValue) } });
        } else {
            delete route.query.selectedTimeseriesMetrics;
            router.push({ query: route.query });
        }
    },
    { deep: true }
);

const chartOptions = computed(() => {
    const titleText = DATE_FILTER_OPTIONS.find((option) => option.value === selectedTimeseriesDateFilter.value?.key)?.label || 'Last 24h';

    const metricKeys = _.cloneDeep(allSelectedMetricsForChart.value)?.map((x) => x.key) || [];

    const dataForEachSelectedMetric = metricKeys.map((metricKey) => {
        const metricData =
            _.isNil(plotSpecificMetrics.value) || _.isEmpty(plotSpecificMetrics.value)
                ? destinationTimeseriesConnectorMetrics.value[metricKey]
                : plotSpecificMetrics.value?.connector[metricKey];

        return {
            metricKey,
            metricData,
            metricInfo: getMetricFormattingInfo(metricKey, connectorInfo.value?.metrics)
        };
    });

    const seriesAndYAxes = dataForEachSelectedMetric.map((metric, index) => {
        const yAxis = {
            title: {
                text: metric.metricInfo?.name
            },
            labels: {
                formatter: function () {
                    return formatter(this.value, { unit: metric.metricInfo?.unit }, false).display;
                }
            },
            opposite: index % 2 !== 0
        };

        const series = {
            name: metric.metricInfo?.name,
            data: metric.metricData?.map((dataItem) => {
                const localTime = moment.utc(dataItem.timestamp).tz(moment.tz.guess()).toDate();
                return {
                    x: localTime,
                    y: dataItem.value
                };
            }),
            type: 'line',
            yAxis: index,
            custom: {
                unit: metric.metricInfo?.unit
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter(this.y, { unit: metric.metricInfo?.unit }, false).display;
                }
            }
        };

        scrollToCurrentPosition();
        return { series, yAxis };
    });

    // Split the series and yAxis configurations
    const series = seriesAndYAxes.map((sy) => sy.series);
    const yAxis = seriesAndYAxes.map((sy) => sy.yAxis);

    const options = {
        time: {
            timezone: moment.tz.guess()
        },
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: titleText
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    // Get the current date and the date from the axis in the browser's timezone
                    const now = moment.utc(new Date()).tz(moment.tz.guess());
                    const axisDate = moment.utc(new Date(this.value)).tz(moment.tz.guess());

                    // Compare dates to determine formatting
                    if (now.format('YYYYMMDD') === axisDate.format('YYYYMMDD')) {
                        // Current day: show hour only
                        return axisDate.format('HH:mm');
                    } else if (now.format('YYYYMM') === axisDate.format('YYYYMM')) {
                        // Current month, different day: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else if (now.format('YYYY') === axisDate.format('YYYY')) {
                        // Different month, same year: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else {
                        // Different year: show day, month (short name), year, and hour
                        return axisDate.format('DD MMM YYYY HH:mm');
                    }
                }
            }
        },
        yAxis: yAxis,
        series: series,
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function () {
                const localTime = moment(new Date(this.x)).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

                let tooltip = `${localTime}<br>`;
                this.points.forEach((point) => {
                    tooltip += `<b>${point.series.name}:</b> ${
                        formatter(point.y, { unit: point.series.userOptions.custom?.unit }, false).display
                    }<br>`;
                });
                return tooltip;
            }
        }
    };

    return options;
});

const getFormattedValueForLatest = (metricKey, level = 'inline', children = null, dashOnRule = null, otherMetricSource = null) => {
    const isChildren = children || _.isNumber(children);

    if (!destination.value) return { display: '-' };

    const metricSource = otherMetricSource || destinationLatestMetrics.value;

    const metricValue = isChildren ? metricSource[level]?.[children]?.[metricKey] : metricSource[level]?.[metricKey];

    if (!connectorInfo.value) return { display: '-' };

    const metricFormattingInfo = getMetricFormattingInfo(metricKey, connectorInfo.value?.metrics);

    const formatterParams = [metricValue, metricFormattingInfo];

    if (dashOnRule || dashOnRule === false) {
        formatterParams.push(dashOnRule);
    }

    return {
        ...formatter(...formatterParams),
        metricDisplayName: metricFormattingInfo?.name
    };
};

async function loadDestinationUponAction() {
    tabLoading.value = true;
    metricsLoading.value = true;
    try {
        await Promise.all([
            destinationsStore.fetchConnectors(),
            metrics.destinations.fetch(props.id, { topic_list_from: page.value * pageSize.value, topic_list_size: pageSize.value })
        ]);

        const destinationVal = await destinationsStore.getById(props.id, null, true);

        destination.value = JSON.parse(JSON.stringify(destinationVal ?? {}));
        defaultDestination.value = _.cloneDeep(destination.value);
    } finally {
        tabLoading.value = false;
        metricsLoading.value = false;
    }
}

// async function onPause(id) {
//     try {
//         actionLoading.value = true;
//         await destinationsStore.pause(id);
//         await loadDestinationUponAction();
//     } finally {
//         actionLoading.value = false;
//     }
// }

async function onStop(id) {
    try {
        actionLoading.value = true;
        await destinationsStore.stop(id);
        await loadDestinationUponAction();
    } finally {
        actionLoading.value = false;
    }
}

async function onResume(id) {
    try {
        actionLoading.value = true;
        await destinationsStore.resume(id);
        await loadDestinationUponAction();
    } finally {
        actionLoading.value = false;
    }
}

async function onRestart(id) {
    try {
        actionLoading.value = true;
        await destinationsStore.restart(id);
        await loadDestinationUponAction();
    } finally {
        actionLoading.value = false;
    }
}

function onClone(destination) {
    router.push({
        name: 'new-destination',
        params: { connector: destination.connector },
        query: { cloneConfig: JSON.stringify(destination.config) }
    });
}

// eslint-disable-next-line no-unused-vars
async function onDelete(entity, name, id, index) {
    try {
        tabLoading.value = true;
        if (!pipelines.all) {
            await pipelines.fetch();
        }
        const hasActivePipeline = pipelines.all.some((p) => p.destination.id === id);

        if (hasActivePipeline) {
            useConfirmBeforeWarning(
                async () => {
                    tabLoading.value = false;
                },
                { entity: entity, name: name },
                () => {
                    tabLoading.value = false;
                }
            );
        } else {
            useConfirmBeforeAction(
                'delete',
                async () => {
                    try {
                        hidePromptTemporary.value = true;
                        await destinationsStore.delete(id);
                        destinationsStore.fetch();
                        router.push({ name: 'connectors', query: { tab: 'Destinations' } });
                    } finally {
                        tabLoading.value = false;
                        hidePromptTemporary.value = false;
                    }
                },
                { entity: entity, name: name },
                () => {
                    tabLoading.value = false;
                }
            );
        }
    } catch {
        tabLoading.value = false;
    }
}

const stats = computed(() => {
    const latencyMetric = getFormattedValueForLatest('latency', 'connector');

    let eventsSentMetric = getFormattedValueForLatest('sinkRecordSendTotal', 'inline');
    eventsSentMetric = !eventsSentMetric?.raw ? getFormattedValueForLatest('sinkRecordSendTotal', 'connector') : eventsSentMetric;

    const lagMetric = getFormattedValueForLatest('recordsLag', 'connector');

    return [
        {
            id: 0,
            name: 'Status',
            stat: destinationStatus.value,
            icon: CheckCircleIcon,
            infoIcon: destinationStatus.value === 'Broken',
            errors: destinationErrors.value,
            colorClass: getStatusColor(destinationStatus.value, false, true)
        },
        {
            id: 1,
            name: latencyMetric.metricDisplayName,
            stat: latencyMetric.display,
            icon: ClockIcon,
            colorClass: 'bg-orange-400' // Orange for Latency
        },
        {
            id: 2,
            name: lagMetric.metricDisplayName,
            stat: lagMetric.display,
            icon: ClockIcon,
            colorClass: 'bg-orange-400' // Orange for Latency
        },
        {
            id: 3,
            name: eventsSentMetric.metricDisplayName,
            stat: eventsSentMetric.display,
            icon: ChevronDoubleRightIcon,
            colorClass: 'bg-green-500' // Green for Events Received
        }
    ];
});

const destinationStatus = computed(() => {
    return destinationLatestMetrics?.value?.inline?.connector_status ?? destination?.value?.connector_status ?? 'Unknown';
});

let tabs = [
    { name: 'Status', href: '#' },
    { name: 'Auth', href: '#' },
    { name: 'Settings', href: '#' },
    {
        name: 'Logs',
        href: `/logs?destination_id=${props.id}`,
        target: '_blank',
        rel: 'noopener noreferrer',
        newTabUrl: true
    }
];

let currentTab = ref('');

const setCurrentTab = (str) => {
    if (str === 'Logs') {
        return;
    }

    currentTab.value = str;

    router.push({ ...route.query, query: { tab: currentTab.value } });
};

const destinationErrors = computed(() => {
    if (destinationStatus.value === 'Broken' && destination.value?.task_statuses) {
        return [...new Set(Object.values(destination.value?.task_statuses).map((task) => task.trace))];
    }

    return [];
});

const isDefaultTopicDateFilter = computed(() => {
    return selectedTopicsDateFilter.value.key === 'allTime' || !selectedTopicsDateFilter.value || !selectedTopicsDateFilter.value?.key;
});

// async function discardDestination() {
//     try {
//         tabLoading.value = true;

//         const destinationVal = await destinationsStore.getById(props.id);
//         destination.value = JSON.parse(JSON.stringify(destinationVal ?? {}));
//     } finally {
//         tabLoading.value = false;
//     }
// }

function onDiscard() {
    destination.value = _.cloneDeep(defaultDestination.value);
    canSave.value = false;
}

async function saveDestination() {
    tabLoading.value = true;
    formError.value = '';
    try {
        await destinationsStore.edit(destination.value, destination.value.id);
        await loadDestinationUponAction();
        canSave.value = false;

        toast.success('Destination successfully saved.');
    } catch (err) {
        console.error('Error in saveDestination:', err);
        formError.value = err;
        tabLoading.value = false;
    } finally {
        tabLoading.value = false;
    }
}

function initializeTopicsFilters() {
    filters.forEach((filter) => {
        if (filter.name === 'count') {
            pageSize.value = filter.options[filter.defaultIndex].value;
        }
        checkedTopicFilters.value[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

async function onTopicsPageChange(newPage) {
    topicsLoading.value = true;
    try {
        removeWebSocketMessage();

        page.value = newPage;

        destinationLatestMetrics.value.topics = {};

        const dateFilterToUse = isDefaultTopicDateFilter.value
            ? undefined
            : { ...selectedTopicsDateFilter.value?.value, key: selectedTopicsDateFilter.value?.key };

        specificTopicMetricsToUse.value = await metrics.destinations.fetch(props.id, {
            topic_list_from: page.value * pageSize.value,
            topic_list_size: pageSize.value,
            time_type: !isDefaultTopicDateFilter.value ? 'timesummary' : undefined,
            ...(dateFilterToUse || {}),
            level: 'topic'
        });

        if (isDefaultTopicDateFilter.value) {
            specificTopicMetricsToUse.value = null;
        }

        addWebSocketMessage();
    } finally {
        topicsLoading.value = false;
    }
}

async function onTopicsFilterToggle({ filter, filterOption }) {
    topicsLoading.value = true;
    try {
        removeWebSocketMessage();

        if (filter.name === 'count') {
            pageSize.value = filterOption.value;
        }
        page.value = 0;

        const topicFilter = JSON.stringify({ filter: { name: filter.name }, filterOption });

        destinationLatestMetrics.value.topics = {};
        const dateFilterToUse = isDefaultTopicDateFilter.value
            ? undefined
            : { ...selectedTopicsDateFilter.value?.value, key: selectedTopicsDateFilter.value?.key };

        const queryInfo = { query: { ...route.query, topicFilter } };

        if (dateFilterToUse?.key) {
            queryInfo.query.selectedTopicsDateFilter = JSON.stringify(dateFilterToUse);
        }

        router.push(queryInfo);

        specificTopicMetricsToUse.value = await metrics.destinations.fetch(props.id, {
            topic_list_from: page.value * pageSize.value,
            topic_list_size: pageSize.value,
            time_type: !isDefaultTopicDateFilter.value ? 'timesummary' : undefined,
            ...(dateFilterToUse || {}),
            level: 'topic'
        });

        if (isDefaultTopicDateFilter.value) {
            specificTopicMetricsToUse.value = null;
        }

        addWebSocketMessage();
    } finally {
        topicsLoading.value = false;
    }
}

async function handleTopicsDateFilterInput(dateFilter) {
    topicsLoading.value = true;

    try {
        selectedTopicsDateFilter.value = dateFilter;
        destinationLatestMetrics.value.topics = {};

        const dateFilterToUse = isDefaultTopicDateFilter.value
            ? undefined
            : { ...selectedTopicsDateFilter.value?.value, key: selectedTopicsDateFilter.value?.key };

        const queryInfo = { query: { ...route.query } };

        if (dateFilterToUse?.key) {
            queryInfo.query.selectedTopicsDateFilter = JSON.stringify(dateFilterToUse);
        }

        router.push(queryInfo);

        specificTopicMetricsToUse.value = await metrics.destinations.fetch(props.id, {
            topic_list_from: page.value * pageSize.value,
            topic_list_size: pageSize.value,
            time_type: !isDefaultTopicDateFilter.value ? 'timesummary' : undefined,
            ...(dateFilterToUse || {}),
            level: 'topic'
        });

        if (isDefaultTopicDateFilter.value) {
            specificTopicMetricsToUse.value = null;
        }
    } finally {
        topicsLoading.value = false;
    }
}

const addWebSocketMessage = () => {
    WebSocketService.addListener(
        'metrics',
        'destinations',
        props.id,
        metrics.onMetricsData,
        'metrics.onMetricsData',
        page.value * pageSize.value,
        pageSize.value
    );
};

const scrollToCurrentPosition = async () => {
    const container = document.querySelector('.flex.h-screen.overflow-auto.bg-gray-100');
    const scrollValue = _.cloneDeep(container.scrollTop);
    await nextTick(() => {
        const latestContainer = document.querySelector('.flex.h-screen.overflow-auto.bg-gray-100');
        const latestScrollValue = _.cloneDeep(latestContainer.scrollTop);

        if (latestScrollValue !== scrollValue) {
            setTimeout(() => (container.scrollTop = scrollValue), 1); // Reset to captured position'
        }
    });
};

const downloadViews = async () => {
    viewsDownloading.value = true;
    try {
        let textTopicsSampleScript = await destinationsStore.getTopicSampleScripts(props.id);

        if (isJSON(textTopicsSampleScript) && typeof textTopicsSampleScript === 'object') {
            textTopicsSampleScript = JSON.stringify(textTopicsSampleScript, null, 2);
        }

        const blob = new Blob([textTopicsSampleScript], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `stk_dest_${props.id?.trim()}sample_views.sql`;
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        console.log(err, 'err');
    } finally {
        viewsDownloading.value = false;
    }
};

const removeWebSocketMessage = () => {
    WebSocketService.removeListener('metrics', 'destinations', props.id, 'metrics.onMetricsData', page.value * pageSize.value, pageSize.value);
};

async function refreshTimeseries() {
    timeseriesLoading.value = true;
    const defaultDateFilter =
        selectedTimeseriesDateFilter.value.key === 'last24h' || !selectedTimeseriesDateFilter.value || !selectedTimeseriesDateFilter.value?.key;

    try {
        const timeseriesData = await metrics.destinations.fetchTimeseriesData(
            props.id,
            defaultDateFilter ? undefined : { ...selectedTimeseriesDateFilter.value?.value, key: selectedTimeseriesDateFilter.value?.key }
        );

        plotSpecificMetrics.value = defaultDateFilter ? null : timeseriesData;
    } catch (err) {
        console.log(err, 'err');
    } finally {
        await scrollToCurrentPosition();
        timeseriesLoading.value = false;
    }
}

async function handleTimeseriesDateFilterInput(dateFilter) {
    timeseriesLoading.value = true;

    try {
        selectedTimeseriesDateFilter.value = dateFilter;
        const defaultDateFilter =
            selectedTimeseriesDateFilter.value.key === 'last24h' || !selectedTimeseriesDateFilter.value || !selectedTimeseriesDateFilter.value?.key;
        const dateFilterToUse = defaultDateFilter
            ? undefined
            : { ...selectedTimeseriesDateFilter.value?.value, key: selectedTimeseriesDateFilter.value?.key };

        const queryInfo = { query: { ...route.query } };

        if (dateFilterToUse?.key) {
            queryInfo.query.selectedTimeseriesDateFilter = JSON.stringify(dateFilterToUse);
        }

        router.push(queryInfo);

        const timeseriesData = await metrics.destinations.fetchTimeseriesData(props.id, dateFilterToUse);

        plotSpecificMetrics.value = defaultDateFilter ? null : timeseriesData;
    } finally {
        await scrollToCurrentPosition();
        timeseriesLoading.value = false;
    }
}
</script>

<template>
    <div class="min-h-screen">
        <SQLViewsModal
            v-if="openViewsModal"
            :loading="viewsLoading"
            :scripts="selectedTopicsScript"
            :is-modal-open="openViewsModal"
            @update:is-open="setViewsModelOpenStatus($event)" />

        <div v-if="destination" class="page-header">
            <AppHeader :show-title="false">
                <template #titleRight>
                    <nav class="text-gray-700" aria-label="Breadcrumb">
                        <div class="flex items-center font-semibold">
                            <div>Connectors</div>
                            <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                            <router-link to="/connectors?tab=Destinations" class="text-green-400 hover:text-green-500">Destinations</router-link>
                            <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                            <div class="flex flex-row align-center items-center gap-3">
                                <div class="flex-shrink-0">
                                    <img class="h-6 w-6" :src="`/icons/${destination.connector}.svg`" alt="connector" />
                                </div>
                                <h3 class="text-lg font-medium leading-6 text-gray-900">
                                    {{ destination.name }}
                                </h3>
                            </div>
                        </div>
                    </nav>
                </template>
                <template #end>
                    <RowActionMenu
                        :show-clone="userHasAccess([$Permissions.WRITE_DESTINATIONS], authStore.user)"
                        :show-delete="userHasAccess([$Permissions.DELETE_DESTINATIONS], authStore.user)"
                        :show-stop="
                            userHasAccess([$Permissions.TRIGGER_ACTION_DESTINATIONS], authStore.user) &&
                            destinationStatus !== 'Paused' &&
                            destinationStatus !== 'Stopped'
                        "
                        :show-resume="
                            userHasAccess([$Permissions.TRIGGER_ACTION_DESTINATIONS], authStore.user) &&
                            destinationStatus !== 'Active' &&
                            destinationStatus !== 'Broken'
                        "
                        :show-restart="
                            userHasAccess([$Permissions.TRIGGER_ACTION_DESTINATIONS], authStore.user) &&
                            destinationStatus !== 'Active' &&
                            destinationStatus !== 'Paused'
                        "
                        :loading="actionLoading || tabLoading"
                        @clone="onClone(destination)"
                        @stop="onStop(destination.id)"
                        @delete="onDelete('destination', destination.name, destination.id, destIndex)"
                        @resume="onResume(destination.id)"
                        @restart="onRestart(destination.id)" />
                </template>
            </AppHeader>
        </div>

        <AppLoader :listen="['destinations.fetch']" :force-loading="generalLoading" default-height="85vh">
            <div v-if="destination" class="w-full">
                <Tabs :tabs="tabs" :initial-active-tab="currentTab" :loading="tabLoading" @tab-change="setCurrentTab">
                    <template #Status>
                        <div class="mt-5 w-full">
                            <div>
                                <h3 class="mb-3 font-medium">Stats</h3>

                                <div class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                    <StatCard
                                        v-for="item in stats"
                                        :key="item.id"
                                        :icon-bg-class="item.colorClass"
                                        :info-icon="item.infoIcon"
                                        :errors="item.errors"
                                        :icon="item.icon"
                                        :loading="metricsLoading || configurationLoading"
                                        :title="item.name"
                                        :value="item.stat" />
                                </div>
                            </div>

                            <div>
                                <div class="mt-10 mb-2">
                                    <div class="flex justify-between items-end mb-3">
                                        <h3 class="font-medium">Activity</h3>
                                    </div>
                                    <div class="rounded-lg bg-gray-50 shadow p-5">
                                        <AppLoader :force-loading="metricsLoading" default-height="40vh">
                                            <div class="flex items-center justify-between">
                                                <DateFilter
                                                    :selected="selectedTimeseriesDateFilter?.key || 'last24h'"
                                                    :disabled="timeseriesLoading"
                                                    :hide-options="[
                                                        'date',
                                                        'dateRange',
                                                        'today',
                                                        'allTime',
                                                        'lastWeek',
                                                        'lastMonth',
                                                        'thisWeek',
                                                        'thisMonth'
                                                    ]"
                                                    @input="handleTimeseriesDateFilterInput" />
                                                <div class="flex items-center justify-end w-1/3">
                                                    <multiselect
                                                        v-model="allSelectedMetricsForChart"
                                                        class="inputRight"
                                                        :options="listOfTimeseriesMetricOptions"
                                                        :multiple="true"
                                                        :close-on-select="false"
                                                        :clear-on-select="false"
                                                        :preserve-search="true"
                                                        :placeholder="
                                                            timeseriesLoading
                                                                ? 'Loading...'
                                                                : listOfTimeseriesMetricOptions?.length
                                                                  ? 'Select metrics'
                                                                  : 'No metrics available'
                                                        "
                                                        :select-label="'Select'"
                                                        :deselect-label="'Remove'"
                                                        :loading="timeseriesLoading"
                                                        :disabled="timeseriesLoading || !listOfTimeseriesMetricOptions?.length"
                                                        label="name"
                                                        track-by="key"
                                                        :preselect-first="true">
                                                        <template #selection="{ values, isOpen }"
                                                            ><span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{
                                                                values.length > 1 ? `${values.length} metrics selected` : values[0].name
                                                            }}</span></template
                                                        >
                                                    </multiselect>
                                                    <button
                                                        aria-label="button"
                                                        class="bg-cyan-950 hover:bg-cyan-800 text-white rounded-r-md px-4 flex items-center h-auto"
                                                        :style="allSelectedMetricsForChart?.length ? 'min-height: 42px' : 'min-height: 38px'"
                                                        :disabled="timeseriesLoading"
                                                        @click.stop="refreshTimeseries()">
                                                        <ArrowPathIcon class="h-4 w-4 p-0" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="mt-4 shadow rounded-lg">
                                                    <AppLoader :force-loading="timeseriesLoading" background="#f9fafb" default-height="42vh">
                                                        <HighChart v-if="!timeseriesLoading" ref="chart" :chart-options="chartOptions" />
                                                    </AppLoader>
                                                </div>
                                            </div>

                                            <!-- <div v-if="availableMetrics?.connector?.length">
                                            <h3 class="mt-8 mb-3">Events Breakdown</h3>

                                            <div class="relative w-full shadow-md overflow-auto">
                                                <table class="min-w-full divide-y divide-gray-300 border" aria-label="destinations events table">
                                                    <thead class="bg-gray-50">
                                                        <tr>
                                                            <th
                                                                v-for="metric in availableMetrics?.connector"
                                                                :key="metric.attribute"
                                                                scope="col"
                                                                class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                                {{ metric.display.name }}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" />
                                                        </tr>
                                                    </thead>
                                                    <tbody class="divide-y divide-gray-200 bg-white">
                                                        <td
                                                            v-for="metric in availableMetrics?.connector"
                                                            :key="metric.attribute"
                                                            class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                            <span>
                                                                {{ getFormattedValueForLatest(metric.attribute, 'connector', null, false).display }}
                                                            </span>
                                                        </td>
                                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600" />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> -->
                                        </AppLoader>
                                    </div>
                                </div>

                                <div class="mt-5">
                                    <div>
                                        <div class="w-full flex justify-between items-center mb-3">
                                            <div class="flex items-center gap-3">
                                                <h4 class="font-medium">Topics</h4>

                                                <!-- <RowActionMenu     v-if="destination.connector == 'snowflake'" :show-download-views="true" :loading="viewsDownloading" @download-views="downloadViews" /> -->
                                            </div>
                                            <div class="flex items-center gap-3">
                                                <DateFilter
                                                    :selected="selectedTopicsDateFilter?.key || 'allTime'"
                                                    :disabled="topicsLoading"
                                                    :hide-options="['date', 'dateRange', 'today']"
                                                    @input="handleTopicsDateFilterInput" />
                                                <DropdownFilters
                                                    v-model="checkedTopicFilters"
                                                    slim-dropdown-button
                                                    :filters="filters"
                                                    :disabled="topicsLoading"
                                                    @on-filter-toggle="onTopicsFilterToggle" />
                                            </div>
                                        </div>

                                        <section class="paginated-section">
                                            <AppLoader
                                                :force-loading="metricsLoading || topicsLoading"
                                                :default-width="'100%'"
                                                default-height="30vh"
                                                background="#f9fafb"
                                                :default-class="'shadow-md rounded-md'">
                                                <div class="relative w-full shadow-md overflow-auto">
                                                    <table class="min-w-full divide-y divide-gray-300 border" aria-label="destinations topics table">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                                    Topic
                                                                </th>
                                                                <th
                                                                    v-for="metric in availableMetrics?.topic"
                                                                    :key="metric.attribute"
                                                                    scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                                    {{ metric.display.name }}
                                                                </th>
                                                                <!-- <th
                                                                scope="col"
                                                                class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" /> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody class="divide-y divide-gray-200 bg-white">
                                                            <tr v-for="topic in topics(specificTopicMetricsToUse)" :key="topic">
                                                                <td class="px-6 py-4 whitespace-nowrap">
                                                                    <span>{{ topic.name }}</span>
                                                                </td>
                                                                <td
                                                                    v-for="metric in availableMetrics?.topic"
                                                                    :key="metric.attribute"
                                                                    class="px-6 py-4 whitespace-nowrap">
                                                                    <span>
                                                                        {{
                                                                            getFormattedValueForLatest(
                                                                                metric.attribute,
                                                                                'topics',
                                                                                topic.id,
                                                                                null,
                                                                                specificTopicMetricsToUse
                                                                            ).display
                                                                        }}
                                                                    </span>
                                                                </td>
                                                                <!-- <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                                                <TableActionMenu
                                                                    v-if="destination.connector == 'snowflake'"
                                                                    :show-views="destination.connector == 'snowflake' ? true : false"
                                                                    :button-style="false"
                                                                    :position-relative="true"
                                                                    :loading="actionLoading"
                                                                    @views="setViewsModelOpenStatus(topic, true)" />
                                                            </td> -->
                                                            </tr>
                                                            <tr v-if="!topics(specificTopicMetricsToUse)?.length">
                                                                <td
                                                                    colspan="15"
                                                                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center">
                                                                    <span>No topics found</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AppLoader>

                                            <Pagination
                                                class="mb-5"
                                                :page="page"
                                                :page-size="pageSize"
                                                :current-page-items-count="topics(specificTopicMetricsToUse)?.length"
                                                :total-items-count="destination.topics?.length ?? 0"
                                                :disable-navigation="metricsLoading"
                                                :message="{
                                                    show: !metricsLoading,
                                                    showCustomMessage: false,
                                                    firstPart: 'Showing topics',
                                                    middlePart: 'to',
                                                    lastPart: `of ${destination.topics?.length ?? 0}`
                                                }"
                                                @page-change="onTopicsPageChange" />
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #Auth>
                        <div class="mt-5 w-full">
                            <AppLoader :force-loading="configurationLoading" default-height="20vh">
                                <ConnectorTab
                                    v-if="destination.config && destinationConfig"
                                    :key="destination.config"
                                    :connector="destination"
                                    :default-connector="defaultDestination"
                                    :config="destinationConfig"
                                    :justify-content="'start'"
                                    :tab="'auth'"
                                    :edit-mode="true"
                                    @changed="(status) => (canSave = status)" />

                                <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'edit-destination'" />
                            </AppLoader>
                        </div>
                    </template>
                    <template #Settings>
                        <div class="mt-5 w-full">
                            <AppLoader :force-loading="configurationLoading" default-height="20vh">
                                <ConnectorTab
                                    v-if="destination.config && destinationConfig"
                                    :key="destination.config"
                                    :connector="destination"
                                    :default-connector="defaultDestination"
                                    :config="destinationConfig"
                                    :justify-content="'start'"
                                    :tab="'settings'"
                                    :edit-mode="true"
                                    @changed="(status) => (canSave = status)" />

                                <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'edit-destination'" />
                            </AppLoader>
                        </div>
                    </template>
                </Tabs>
            </div>
        </AppLoader>
    </div>
    <AppFooter v-if="destination" v-can-access.permissions="[$Permissions.WRITE_DESTINATIONS]">
        <template #right>
            <RowActionMenu
                :show-save="canSave"
                :disable-save="!canSave || tabLoading || !destination.name"
                :loading="actionLoading || tabLoading"
                @save="saveDestination"
                @discard="onDiscard" />
        </template>
    </AppFooter>
</template>
