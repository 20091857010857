<template>
    <div class="w-full flex items-center justify-center">
        <div class="mx-auto w-full flex align-center items-center overflow-auto pb-4 h-100">
            <div class="w-full mx-auto">
                <h3 class="flex w-full text-left text-lg font-medium mb-3">Setup your {{ type }} service</h3>
                <div class="p-6 w-full mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
                    <div class="flex-1">
                        <div class="flex items-center mb-5 gap-3">
                            <div>
                                <label for="cloud-provider" class="block text-sm font-medium text-gray-700">Cloud provider</label>
                                <div class="mt-1 flex rounded-md shadow-sm">
                                    <button
                                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 text-sm"
                                        :class="cloudProviderSelected === 'aws' ? 'bg-green-100 text-green-500' : 'bg-gray-50 text-gray-500'"
                                        style="min-height: 40.5px"
                                        @click="cloudProviderSelected = 'aws'">
                                        <img class="h-8 w-8 flex-shrink-0" :src="`/icons/aws.svg`" alt="cloud" />
                                    </button>
                                    <button
                                        class="inline-flex items-center px-3 border border-r-0 border-gray-300 text-sm"
                                        :class="cloudProviderSelected === 'gcp' ? 'bg-green-100 text-green-500' : 'bg-gray-50 text-gray-500'"
                                        style="min-height: 40.5px"
                                        @click="cloudProviderSelected = 'gcp'">
                                        <img class="h-8 w-8 flex-shrink-0" :src="`/icons/gcp.svg`" alt="cloud" />
                                    </button>
                                    <button
                                        class="inline-flex items-center px-3 rounded-r-md border border-gray-300 text-sm"
                                        :class="cloudProviderSelected === 'azure' ? 'bg-green-100 text-green-500' : 'bg-gray-50 text-gray-500'"
                                        style="min-height: 40.5px"
                                        @click="cloudProviderSelected = 'azure'">
                                        <img class="h-8 w-8 flex-shrink-0" :src="`/icons/azure.svg`" alt="cloud" />
                                    </button>
                                </div>
                            </div>
                            <div class="w-full">
                                <label for="cloud-provider" class="block text-sm font-medium text-gray-700">Region</label>
                                <BasicDropdown
                                    class="mt-1 justify-start"
                                    :options="regions[cloudProviderSelected]"
                                    :selected="selectedRegion"
                                    :placeholder="'Select region'"
                                    @input="selectedRegion = $event">
                                </BasicDropdown>
                            </div>
                        </div>

                        <div class="mb-5">
                            <label for="service-name" class="block text-sm font-medium text-gray-700">Service name</label>
                            <input
                                id="service-name"
                                v-model="serviceName"
                                type="text"
                                name="service-name"
                                class="mt-1 focus:ring-green-400 focus:border-green-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="A short, unique name for your service" />
                        </div>
                        <div class="w-full mx-auto my-5">
                            <h3 class="flex w-full text-left text-lg font-medium mb-3" :class="type !== 'production' ? 'text-gray-500' : ''">
                                Default service settings
                            </h3>
                            <div
                                class="p-6 w-full mx-auto rounded-xl shadow flex items-center space-x-4"
                                :class="type !== 'production' ? 'bg-gray-50' : 'bg-white'">
                                <div class="grid grid-cols-2 gap-4 w-full">
                                    <div class="mb-3">
                                        <div class="flex items-center gap-1">
                                            <label for="data-retention" class="block text-sm font-medium text-gray-700">Data Retention</label>
                                            <div class="">
                                                <Tooltip :tooltip-text="'Stored only for purpose of network interruptions'" />
                                            </div>
                                        </div>
                                        <div class="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                id="data-retention"
                                                v-model="serviceSetupSettings.dataRetention"
                                                type="number"
                                                min="1"
                                                :class="{
                                                    disabled: type !== 'production'
                                                }"
                                                :disabled="type !== 'production'"
                                                name="data-retention"
                                                class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="Data retention - in days" />
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-10">
                                                <span id="price-currency" class="text-gray-500 sm:text-sm">{{ dayLabel }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-row items-center relative">
                                        <label class="block text-sm font-medium text-gray-700 mr-2"> Publicly Available </label>
                                        <div class="flex h-5 mr-2 items-center">
                                            <label class="relative inline-flex items-center cursor-pointer">
                                                <input
                                                    v-model="serviceSetupSettings.publiclyAvailable"
                                                    aria-label="input"
                                                    class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                                    type="checkbox"
                                                    role="switch"
                                                    :class="{
                                                        'disabled-toggle': type !== 'production',
                                                        'bg-green-400': serviceSetupSettings.publiclyAvailable,
                                                        'bg-neutral-300': !serviceSetupSettings.publiclyAvailable
                                                    }"
                                                    :true-value="true"
                                                    :false-value="false"
                                                    :disabled="type !== 'production'"
                                                    aria-describedby="toggle" />
                                            </label>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="flex items-center gap-1">
                                            <label for="computeTasks" class="block text-sm font-medium text-gray-700">Compute</label>
                                            <div class="">
                                                <Tooltip :tooltip-text="'Streaming Transformations'" />
                                            </div>
                                        </div>
                                        <div class="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                id="computeTasks"
                                                v-model="serviceSetupSettings.computeTasks"
                                                type="number"
                                                min="1"
                                                name="computeTasks"
                                                :class="{
                                                    disabled: type !== 'production'
                                                }"
                                                :disabled="type !== 'production'"
                                                class="block w-full rounded-md border-0 py-1.5 pl-7 pr-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="Number of tasks" />
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-10">
                                                <span id="price-currency" class="text-gray-500 sm:text-sm">{{ taskLabel }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="flex items-center gap-1">
                                            <label for="computeStorage" class="block text-sm font-medium text-gray-700">Compute Storage</label>
                                            <div class="">
                                                <Tooltip :tooltip-text="'Storage for lookup tables'" />
                                            </div>
                                        </div>
                                        <div class="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                id="computeStorage"
                                                v-model="serviceSetupSettings.computeStorage"
                                                type="number"
                                                min="1"
                                                name="computeStorage"
                                                :disabled="type !== 'production'"
                                                :class="{
                                                    disabled: type !== 'production'
                                                }"
                                                class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="Compute Storage - in GB" />
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-10">
                                                <span id="price-currency" class="text-gray-500 sm:text-sm">GB</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center gap-3">
                            <button
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                                @click.stop.prevent="cancelServiceSetup">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                                @click.stop.prevent="createService">
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { generateRandomName } from '@/utils/utils';
import BasicDropdown from '@/components/BasicDropdown.vue';
import Tooltip from '@/components/Tooltip.vue';

defineProps({
    type: String
});

const router = useRouter();
const serviceName = ref('');
const cloudProviderSelected = ref('aws');
const selectedRegion = ref('');
const serviceSetupSettings = ref({
    dataRetention: 7,
    publiclyAvailable: false,
    computeTasks: 1,
    computeStorage: 1
});

onMounted(() => {
    serviceName.value = generateRandomName();
});

const dayLabel = computed(() => {
    const days = serviceSetupSettings.value.dataRetention;
    return days === 1 ? 'day' : 'days';
});

const taskLabel = computed(() => {
    const tasks = serviceSetupSettings.value.computeTasks;
    return tasks === 1 ? 'task' : 'tasks';
});

watch(
    () => cloudProviderSelected.value,
    () => {
        selectedRegion.value = '';
    }
);

const regions = ref({
    aws: [
        { name: 'US East (N. Virginia)', value: 'us-east-1' },
        { name: 'US East (Ohio)', value: 'us-east-2' },
        { name: 'US West (N. California)', value: 'us-west-1' },
        { name: 'US West (Oregon)', value: 'us-west-2' },
        { name: 'Africa (Cape Town)', value: 'af-south-1' },
        { name: 'Asia Pacific (Hong Kong)', value: 'ap-east-1' },
        { name: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
        { name: 'Asia Pacific (Osaka)', value: 'ap-northeast-3' },
        { name: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
        { name: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
        { name: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
        { name: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
        { name: 'Canada (Central)', value: 'ca-central-1' },
        { name: 'China (Beijing)', value: 'cn-north-1' },
        { name: 'China (Ningxia)', value: 'cn-northwest-1' },
        { name: 'Europe (Frankfurt)', value: 'eu-central-1' },
        { name: 'Europe (Ireland)', value: 'eu-west-1' },
        { name: 'Europe (London)', value: 'eu-west-2' },
        { name: 'Europe (Milan)', value: 'eu-south-1' },
        { name: 'Europe (Paris)', value: 'eu-west-3' },
        { name: 'Europe (Stockholm)', value: 'eu-north-1' },
        { name: 'Middle East (Bahrain)', value: 'me-south-1' },
        { name: 'South America (São Paulo)', value: 'sa-east-1' }
    ],
    gcp: [
        { name: 'Iowa (us-central1)', value: 'us-central1' },
        { name: 'South Carolina (us-east1)', value: 'us-east1' },
        { name: 'Northern Virginia (us-east4)', value: 'us-east4' },
        { name: 'Oregon (us-west1)', value: 'us-west1' },
        { name: 'Los Angeles (us-west2)', value: 'us-west2' },
        { name: 'Belgium (europe-west1)', value: 'europe-west1' },
        { name: 'London (europe-west2)', value: 'europe-west2' },
        { name: 'Frankfurt (europe-west3)', value: 'europe-west3' },
        { name: 'Netherlands (europe-west4)', value: 'europe-west4' },
        { name: 'Zurich (europe-west6)', value: 'europe-west6' },
        { name: 'Taiwan (asia-east1)', value: 'asia-east1' },
        { name: 'Hong Kong (asia-east2)', value: 'asia-east2' },
        { name: 'Tokyo (asia-northeast1)', value: 'asia-northeast1' },
        { name: 'Osaka (asia-northeast2)', value: 'asia-northeast2' },
        { name: 'Seoul (asia-northeast3)', value: 'asia-northeast3' },
        { name: 'Singapore (asia-southeast1)', value: 'asia-southeast1' },
        { name: 'Jakarta (asia-southeast2)', value: 'asia-southeast2' },
        { name: 'Mumbai (asia-south1)', value: 'asia-south1' },
        { name: 'Sydney (australia-southeast1)', value: 'australia-southeast1' },
        { name: 'Montréal (northamerica-northeast1)', value: 'northamerica-northeast1' },
        { name: 'São Paulo (southamerica-east1)', value: 'southamerica-east1' },
        { name: 'Warsaw (europe-central2)', value: 'europe-central2' },
        { name: 'Finland (europe-north1)', value: 'europe-north1' },
        { name: 'Delhi (asia-south2)', value: 'asia-south2' },
        { name: 'Melbourne (australia-southeast2)', value: 'australia-southeast2' }
    ],
    azure: [
        { name: 'East US', value: 'eastus' },
        { name: 'East US 2', value: 'eastus2' },
        { name: 'South Central US', value: 'southcentralus' },
        { name: 'West US 2', value: 'westus2' },
        { name: 'Australia East', value: 'australiaeast' },
        { name: 'Southeast Asia', value: 'southeastasia' },
        { name: 'North Europe', value: 'northeurope' },
        { name: 'UK South', value: 'uksouth' },
        { name: 'West Europe', value: 'westeurope' },
        { name: 'Central US', value: 'centralus' },
        { name: 'North Central US', value: 'northcentralus' },
        { name: 'West US', value: 'westus' },
        { name: 'South Africa North', value: 'southafricanorth' },
        { name: 'Central India', value: 'centralindia' },
        { name: 'East Asia', value: 'eastasia' },
        { name: 'Japan East', value: 'japaneast' },
        { name: 'Korea Central', value: 'koreacentral' },
        { name: 'Canada Central', value: 'canadacentral' },
        { name: 'France Central', value: 'francecentral' },
        { name: 'Germany West Central', value: 'germanywestcentral' },
        { name: 'Brazil South', value: 'brazilsouth' },
        { name: 'Central US EUAP', value: 'centraluseuap' },
        { name: 'East US 2 EUAP', value: 'eastus2euap' },
        { name: 'Central US Stage', value: 'centralusstage' },
        { name: 'East US Stage', value: 'eastusstage' },
        { name: 'East Asia Stage', value: 'eastasiastage' },
        { name: 'North Central US Stage', value: 'northcentralusstage' },
        { name: 'South Central US Stage', value: 'southcentralusstage' },
        { name: 'West US Stage', value: 'westusstage' },
        { name: 'West US 2 Stage', value: 'westus2stage' },
        { name: 'Asia', value: 'asia' },
        { name: 'Asia Pacific', value: 'asiapacific' },
        { name: 'Australia', value: 'australia' },
        { name: 'Brazil', value: 'brazil' },
        { name: 'Canada', value: 'canada' },
        { name: 'Europe', value: 'europe' },
        { name: 'Global', value: 'global' },
        { name: 'India', value: 'india' },
        { name: 'Japan', value: 'japan' },
        { name: 'United Kingdom', value: 'uk' },
        { name: 'United States', value: 'unitedstates' },
        { name: 'United States EUAP', value: 'unitedstateseuap' }
    ]
});

const cancelServiceSetup = () => {
    serviceName.value = generateRandomName();
    cloudProviderSelected.value = 'aws';
    selectedRegion.value = '';
    serviceSetupSettings.value = {
        dataRetention: 7,
        publiclyAvailable: false,
        computeTasks: 1,
        computeStorage: 1
    };

    router.push({ query: null });
};

const createService = () => {
    console.log('createService', serviceName.value, cloudProviderSelected.value, selectedRegion.value, serviceSetupSettings.value);
};
</script>

<style>
.disabled-toggle {
    pointer-events: none !important;
    opacity: 0.5 !important;
    cursor: pointer !important;
    background: #dfdfdf !important;
}
</style>
