<template>
    <div class="w-full flex flex-col items-start relative">
        <div class="w-full mt-1 flex flex-row items-center relative">
            <div :class="config.description && !hideLateralDescriptionValue ? 'flex-1 mr-4' : 'flex-grow'" class="relative">
                <label
                    :for="props.config.display_name"
                    :class="[
                        'block',
                        'text-sm',
                        'font-medium',
                        'text-gray-700',
                        'absolute',
                        'top-[-1.5rem]',
                        props.overrideLabelOpacity ? 'override-opacity' : ''
                    ]">
                    {{ props.config.display_name }}
                </label>
                <textarea
                    :id="props.config.display_name"
                    v-model="controlValue"
                    :name="props.config.display_name"
                    rows="4"
                    :readonly="!editMode || props.config.readonly || props.config.value?.readonly"
                    :disabled="!editMode"
                    autocomplete="new-password"
                    required=""
                    class="input__text block w-full appearance-none border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm rounded-md md:mt-5 lg:mt-2 xl:mt-2"
                    :class="{
                        disabled: !editMode
                    }" />
                <div v-if="errors.length" class="text-red-700 mt-1 text-xs">
                    <template v-for="error in errors" :key="error.$uid">
                        <span> * {{ error.$message }} </span>
                    </template>
                </div>
            </div>
            <div
                v-if="
                    hideLateralDescriptionValue &&
                    props.config.display_name.trim().toLowerCase() !== config.description.trim().toLowerCase() &&
                    config.description.trim().split(' ').length > 1
                "
                class="relative flex flex-col items-center group ml-2">
                <Tooltip :tooltip-text="config.description" />
            </div>
            <div
                v-if="
                    config.description &&
                    !hideLateralDescriptionValue &&
                    props.config.display_name.trim().toLowerCase() !== config.description.trim().toLowerCase() &&
                    config.description.trim().split(' ').length > 1
                "
                class="flex-1 p-3 bg-green-50 border-l-4 border-green-200 text-xs text-black rounded-md relative arrow-left"
                :class="errors.length ? 'mb-5' : ''">
                {{ config.description }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onUnmounted, onMounted, onUpdated } from 'vue';
import Tooltip from './Tooltip.vue';
import _ from 'lodash';

const emit = defineEmits(['input']);
const props = defineProps({
    config: {
        type: Object,
        required: true
    },
    value: {
        type: [String, null],
        default: null
    },
    editMode: {
        type: Boolean,
        default: true
    },
    overrideLabelOpacity: {
        type: Boolean,
        default: false
    },
    hideLateralDescription: {
        type: Boolean,
        default: false
    },
    errors: {
        type: Array,
        default: () => []
    }
});
const controlValue = ref(_.isNumber(props.value) ? props.value : props.value || props.config.value.default);

const hideLateralDescriptionValue = ref(props.hideLateralDescription);

onUpdated(() => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : !props.editMode || window.innerWidth <= 1024;
});

const handleResize = () => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : window.innerWidth <= 1024;
};

onMounted(() => {
    handleResize();
});

window.addEventListener('resize', handleResize);

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

watch(controlValue, (newValue) => {
    emit('input', newValue);
});

watch(
    () => props.value,
    (newValue) => {
        controlValue.value = newValue;
    }
);
</script>

<style scoped>
.arrow-left::before {
    content: '';
    position: absolute;
    left: -12px; /* Adjusted for the larger arrow */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent; /* increased for a taller arrow */
    border-bottom: 18px solid transparent; /* increased for a taller arrow */
    border-right: 12px solid #bbf7d0; /* adjusted to match the border color of the blue container */
}
</style>
