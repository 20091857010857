<template>
    <div class="modal-container fixed" style="z-index: 999 !important">
        <div class="text-sm modal-body shadow-xl m-3 max-h-[80%]" :style="`width: ${width}%;`">
            <span class="modal-close" @click="closeDialog">
                <XMarkIcon class="h-5 w-5 text-gray-600 hover:text-gray-600" />
            </span>
            <div class="mx-auto flex p-3 items-center justify-center rounded-full bg-red-100">
                <ExclamationTriangleIcon class="h-6 w-6 text-red-600" />
            </div>
            <div class="my-3 overflow-hidden flex flex-col">
                <p class="text-gray-600 mb-3">The following {{ errors.length > 1 ? 'errors' : 'error ' }} occured:</p>
                <Pagination
                    v-if="errors.length > 1"
                    :page-text="'Error'"
                    :page="page"
                    :page-size="1"
                    :total-items-count="errors.length"
                    :message="{ showCustomMessage: true }"
                    @page-change="changePage">
                    <template #customMessage>
                        Showing error <span class="font-medium">{{ page + 1 }}</span> out of
                        <span class="font-medium">{{ errors.length }}</span>
                    </template>
                </Pagination>
                <div class="bg-gray-200 w-full flex justify-end py-1 px-2 rounded-t">
                    <button aria-label="button" class="cursor-pointer flex items-center" :disabled="copied" @click="copyToClipboard">
                        <ClipboardIcon v-if="!copied" class="h-6 w-6 pr-1" />
                        <ClipboardDocumentCheckIcon v-else class="h-6 w-6 pr-1" />
                        <span v-if="!copied">Copy Error</span>
                        <span v-else>Copied!</span>
                    </button>
                </div>
                <pre class="whitespace-pre-line overflow-x-auto text-left text-sm font-mono bg-gray-100 p-2 rounded-b">
       <span v-html="sanitizeHtml(grayErrorPart)"></span>
        <span class="text-red-500" v-html="sanitizeHtml(redErrorPart)"></span>
        </pre>
            </div>
            <a href="#" class="font-medium text-cyan-950 no-underline hover:text-cyan-800" @click.prevent.stop="openChat"> Contact support </a>
        </div>
    </div>
</template>

<script>
import { ExclamationTriangleIcon, XMarkIcon, ClipboardDocumentCheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline';
import Pagination from './Pagination.vue';
import DOMPurify from 'dompurify';
import _ from 'lodash';

export default {
    name: 'ErrorDialog',
    components: {
        ExclamationTriangleIcon,
        XMarkIcon,
        ClipboardDocumentCheckIcon,
        ClipboardIcon,
        Pagination
    },
    props: {
        errors: {
            type: Array,
            required: true
        },
        width: {
            type: Number,
            default: 40
        }
    },
    emits: ['cancel'],
    data() {
        return {
            copied: false,
            error: '',
            page: 0
        };
    },
    computed: {
        grayErrorPart() {
            const splitter = 'error(s):\n';
            const index = this.formattedError.indexOf(splitter);
            if (index !== -1) {
                return this.formattedError.substring(0, index + splitter.length - 1);
            }
            return this.formattedError;
        },
        redErrorPart() {
            const splitter = 'error(s):\n';
            const index = this.formattedError.indexOf(splitter);
            if (index !== -1) {
                return this.formattedError.substring(index + splitter.length, this.formattedError.length);
            }
            return '';
        },
        formattedError() {
            const parts = this.error?.split('\nYou can also find') ?? [];
            if (parts.length === 2) {
                return parts[0];
            }
            return this.error ?? '';
        }
    },
    created() {
        if (this.errors.length > 0) {
            this.error = this.errors[0];
        }
    },
    methods: {
        sanitizeHtml(html) {
            if (!_.isNaN(Number(html))) {
                return html;
            }

            return DOMPurify.sanitize(html);
        },
        async copyToClipboard() {
            this.copied = true;
            await navigator.clipboard.writeText(this.formattedError);
            setTimeout(() => {
                this.copied = false;
            }, 3000);
        },
        closeDialog() {
            this.$emit('cancel');
        },
        changePage(newPage) {
            this.error = this.errors[newPage];
            this.page = newPage;
        },
        openChat() {
            window.Pylon('show');
        }
    }
};
</script>

<style>
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #cececeb5;
}
.modal-body {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 20px 40px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
}
.modal-action {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
}
.modal-close {
    cursor: pointer;
    position: relative;
    align-self: end;
    right: -25px;
    top: -10px;
}
</style>
