import DeleteDialog from '../components/DeleteDialog.vue';
import ConfirmDialog from '../components/ConfirmDialog.vue';

import { createConfirmDialog } from 'vuejs-confirm-dialog';

const useConfirmBeforeAction = (actionType = 'delete', action, props, onCancelAction) => {
    let dialogToUse = ConfirmDialog;

    switch (actionType) {
        case 'delete':
            dialogToUse = DeleteDialog;
            break;

        default:
            dialogToUse = ConfirmDialog;
            break;
    }

    const { reveal, onConfirm, onCancel } = createConfirmDialog(dialogToUse, props);

    onConfirm(action);
    onCancel(onCancelAction);

    reveal();
};

export default useConfirmBeforeAction;
