<template>
    <div class="table-page-container mb-7">
        <div class="page-header">
            <AppHeader />
        </div>

        <div class="table-page-body">
            <section class="page-options flex justify-between items-start">
                <SearchInput :loading="loading" placeholder="Search by message..." @search="(input) => (searchQuery = input)" />
                <div class="page-filters flex gap-5 ml-5">
                    <div class="page-filters flex gap-5">
                        <DropdownFilters v-model="checkedFilters" :filters="filters" :disabled="loading" @on-filter-toggle="onFilterToggle">
                            <template #dropdownListElement="{ filter, filterOption }">
                                <div class="flex gap-2 items-center">
                                    <img
                                        v-if="filter.name === 'connector' && filterOption.value !== 'All'"
                                        width="20"
                                        height="20"
                                        :src="`/icons/${filterOption.value.connectorType}.svg`"
                                        :title="`${filterOption.displayValue}`"
                                        :alt="filterOption.value.context" />
                                    <div class="flex-1 whitespace-nowrap">
                                        {{ filterOption.displayValue }}
                                    </div>
                                </div>
                            </template>
                        </DropdownFilters>
                    </div>

                    <button
                        v-if="isPlayPauseButtonEnabled"
                        aria-label="button"
                        type="button"
                        class="btn btn-primary rounded my-1 inline-flex items-center rounded-full border border-transparent bg-green-400 p-2 text-white shadow-sm hover:bg-green-400"
                        :class="{ 'pointer-events-none opacity-50': !isPlayPauseButtonEnabled }"
                        @click="onPlayPauseClick">
                        <PlayIcon v-if="playLiveFeed" class="h-5 w-5" />
                        <PauseIcon v-else class="h-5 w-5" />
                    </button>
                </div>
            </section>

            <section class="paginated-section mt-2">
                <AppLoader
                    :force-loading="loading"
                    :listen="['logs.fetch']"
                    default-height="20vh"
                    :default-width="'100%'"
                    background="#f9fafb"
                    :default-class="'shadow-md rounded-md'">
                    <div class="relative w-full shadow-md overflow-auto">
                        <table class="min-w-full divide-y divide-gray-300 border" aria-label="pipelines table">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        Timestamp
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        Connector
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Type</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Level</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200">
                                <tr v-for="(log, logIndex) in displayedLogs" :key="logIndex + log.timestamp" class="bg-white">
                                    <td class="px-6 py-4 whitespace-nowrap font-mono text-xs">
                                        {{ log.timestamp }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap font-mono text-xs">
                                        <router-link :to="selectedConnectorPath(log.connectorId, log.connectorContext)"
                                            ><div class="flex gap-2 items-center">
                                                <img
                                                    width="20"
                                                    height="20"
                                                    :src="`/icons/${log.connectorType}.svg`"
                                                    :title="`${log.connector}`"
                                                    :alt="log.connectorContext" />
                                                <span class="hover:text-gray-800">{{ log.connector }}</span>
                                            </div>
                                        </router-link>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap font-mono text-xs">
                                        <div class="flex items-center gap-2">
                                            <CloudArrowDownIcon
                                                v-if="log.connectorContext === 'source'"
                                                class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                            <CloudArrowUpIcon
                                                v-if="log.connectorContext === 'destination'"
                                                class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />

                                            {{ log.connectorContext }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap font-mono text-xs" :style="`color: ${getLevelColor(log.level)}`">
                                        {{ log.level }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-700 hover:text-black font-mono flex items-center gap-3">
                                        <ArrowTopRightOnSquareIcon
                                            class="flex-shrink-0 h-5 w-5 self-center -mr-1 cursor-pointer"
                                            @click="() => onViewMessageClick(log.message, log.level)" />
                                        <span class="whitespace-nowrap flex-grow">
                                            {{ log.message }}
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="!displayedLogs.length">
                                    <td colspan="99" class="text-center p-2">
                                        <small>No matching records found</small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </AppLoader>
                <Pagination
                    v-if="logs.all?.length || page > 0"
                    :page="page"
                    :page-size="pageSize"
                    :current-page-items-count="logs.all?.length ?? 0"
                    :disable-navigation="loading"
                    :message="{
                        show: logs.all?.length && searchQuery === '' && !loading,
                        showCustomMessage: logs.all?.length && searchQuery !== '' && !loading,
                        hideValues: loading,
                        firstPart: 'Showing logs',
                        middlePart: 'to',
                        lastPart: 'logs'
                    }"
                    @page-change="changePage">
                    <template #customMessage>
                        Showing
                        <span class="font-medium">{{ displayedLogs.length }}</span>
                        {{ displayedLogs.length === 1 ? 'match' : 'matches' }}
                        on this page
                    </template>
                </Pagination>
            </section>
        </div>
    </div>
</template>

<script>
import { useLogsStore } from '../../stores/logs.store';
import { useDestinationsStore } from '../../stores/destinations.store';
import useLogMessageDialog from '../../composables/useLogMessageDialog.js';
import AppLoader from '../../components/AppLoader.vue';
import AppHeader from '../../components/AppHeader.vue';
import Pagination from '../../components/Pagination.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import { ref } from 'vue';
import { PauseIcon, PlayIcon, ArrowTopRightOnSquareIcon, CloudArrowDownIcon, CloudArrowUpIcon } from '@heroicons/vue/24/solid';
import SearchInput from '../../components/global/SearchInput.vue';
import moment from 'moment';

export default {
    name: 'LogsPage',
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        CloudArrowDownIcon,
        CloudArrowUpIcon,
        AppLoader,
        AppHeader,
        Pagination,
        DropdownFilters,
        PauseIcon,
        PlayIcon,
        ArrowTopRightOnSquareIcon,
        SearchInput
    },
    data() {
        return {
            CONSTANTS: {
                all: { value: 'All', displayValue: 'All' },
                time: {
                    live: 'Live',
                    lastMin: 'Last min',
                    last15Min: 'Last 15 min',
                    last30Min: 'Last 30 min',
                    lastHour: 'Last hour',
                    lastDay: 'Last day',
                    lastWeek: 'Last week'
                },
                levels: {
                    debug: 'DEBUG',
                    info: 'INFO',
                    warn: 'WARN',
                    error: 'ERROR'
                }
            },
            loading: false,
            playLiveFeed: false,
            isPlayPauseButtonEnabled: false,
            page: 0,
            pageSize: 0,
            searchQuery: ref(''),
            checkedFilters: {},
            logs: useLogsStore(),
            destinations: useDestinationsStore()
        };
    },
    computed: {
        displayedLogs() {
            if (!this.logs.all) {
                return [];
            }
            return this.logs.all.filter((log) => log.message.toLowerCase().indexOf(this.searchQuery?.toLowerCase()) != -1);
        },
        filters() {
            return [
                {
                    title: 'Results per page',
                    name: 'count',
                    defaultIndex: 1,
                    options: [
                        { value: 10, displayValue: '10/page' },
                        { value: 20, displayValue: '20/page' },
                        { value: 50, displayValue: '50/page' }
                        // { value: 100, displayValue: '100/page' },
                        // { value: 200, displayValue: '200/page' },
                        // { value: 500, displayValue: '500/page' }
                    ]
                },
                // {
                //     title: 'Log level',
                //     name: 'level',
                //     multiple: true,
                //     defaultIndex: 0,
                //     options: [
                //         this.CONSTANTS.all,
                //         ...Object.values(this.CONSTANTS.levels).map((level) => ({ value: level.toLowerCase(), displayValue: level })),
                //     ],
                // },
                {
                    title: 'Connectors',
                    name: 'connector',
                    multiple: true,
                    dropdownWidth: '280px',
                    defaultIndex: 0,
                    options: [this.CONSTANTS.all]
                },
                {
                    title: 'Time',
                    name: 'timestamp',
                    buttonWidth: '138px',
                    defaultIndex: 2,
                    options: [
                        { value: 0, displayValue: this.CONSTANTS.time.live, disabled: true },
                        { value: 60, displayValue: this.CONSTANTS.time.lastMin },
                        { value: 60 * 15, displayValue: this.CONSTANTS.time.last15Min },
                        { value: 60 * 30, displayValue: this.CONSTANTS.time.last30Min },
                        { value: 60 * 60, displayValue: this.CONSTANTS.time.lastHour },
                        { value: 60 * 60 * 24, displayValue: this.CONSTANTS.time.lastDay },
                        { value: 60 * 60 * 24 * 7, displayValue: this.CONSTANTS.time.lastWeek }
                    ]
                }
            ];
        }
    },
    watch: {
        loading: {
            handler: function (newValue) {
                if (newValue === true && this.$refs.scrollableContainer) {
                    this.$refs.scrollableContainer.scrollTop = 0;
                }
            }
        }
    },
    async mounted() {
        this.initializeFilters();
        await this.fetchLogs(true);
        this.computeConnectorsFilter();
    },
    methods: {
        selectedConnectorPath(id, type = 'source') {
            return type === 'source' ? { name: 'source-details', params: { id } } : { name: 'destination-details', params: { id } };
        },
        initializeFilters() {
            this.filters.forEach((filter) => {
                if (filter.name === 'count') {
                    this.pageSize = filter.options[filter.defaultIndex].value;
                }
                this.checkedFilters[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
            });
        },
        computeConnectorsFilter() {
            const connectorFilterOptions = this.logs.connectors?.map((connector) => ({
                value: { id: connector.id, context: connector.context, connectorType: connector.connector },
                displayValue: connector.name
            }));

            const connectorsFilterIndex = this.filters.findIndex((filter) => filter.name === 'connector');
            this.filters[connectorsFilterIndex].options.push(...(connectorFilterOptions || []));
            const urlSourceId = this.$route.query.source_id;
            const urlDestinationId = this.$route.query.destination_id;

            if (urlSourceId || urlDestinationId) {
                const urlConnectorFilters = connectorFilterOptions?.filter(
                    (filter) => filter.value.id === urlSourceId || filter.value.id === urlDestinationId
                );
                this.checkedFilters['connector'] = urlConnectorFilters || [];
            }
        },
        computeFetchParams(initialFetch = false) {
            const urlSourceId = this.$route.query.source_id;
            const urlDestinationId = this.$route.query.destination_id;
            const from = this.page * this.pageSize;
            const selectedTime = this.checkedFilters['timestamp'][0].value;
            const timestampFrom = moment().utc().subtract(selectedTime, 'seconds').format('YYYY-MM-DDTHH:mm:ss.SSS');
            let sourceIds;
            let destinationIds;

            if (initialFetch) {
                sourceIds = urlSourceId ? [urlSourceId] : null;
                destinationIds = urlDestinationId ? [urlDestinationId] : null;
            } else if (this.checkedFilters['connector'].find((option) => JSON.stringify(option) === JSON.stringify(this.CONSTANTS.all))) {
                sourceIds = null;
                destinationIds = null;
            } else {
                sourceIds = this.checkedFilters['connector'].filter((filter) => filter.value.context === 'sources').map((filter) => filter.value.id);
                destinationIds = this.checkedFilters['connector']
                    .filter((filter) => filter.value.context === 'destinations')
                    .map((filter) => filter.value.id);
            }
            return [timestampFrom, from, this.pageSize, sourceIds, destinationIds];
        },
        async fetchLogs(initialFetch = false) {
            this.loading = true;
            await this.logs.fetch(...this.computeFetchParams(initialFetch));
            this.loading = false;
        },
        changePage(newPage) {
            this.page = newPage;
            this.fetchLogs();
        },
        onFilterToggle({ filter, filterOption }) {
            if (filter.name === 'count') {
                this.pageSize = filterOption.value;
            }
            if (filter.name === 'timestamp') {
                this.isPlayPauseButtonEnabled = filterOption.displayValue === this.CONSTANTS.time.live;
            }
            this.page = 0;
            this.fetchLogs();
        },
        onPlayPauseClick() {
            this.playLiveFeed = !this.playLiveFeed;
        },
        getLevelColor(level) {
            switch (level) {
                case this.CONSTANTS.levels.info:
                    return '#176e17';
                case this.CONSTANTS.levels.warn:
                    return '#c2410c';
                case this.CONSTANTS.levels.error:
                    return '#eb1313';
                default:
                    return '#4d4d4d';
            }
        },
        onViewMessageClick(message, level) {
            useLogMessageDialog({ message, isError: level === this.CONSTANTS.levels.error });
        }
    }
};
</script>
