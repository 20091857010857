import axios from 'axios';
import _ from 'lodash'; // Ensure you have lodash imported
import Config from '../utils/config';
import { useAuthStore } from '../stores/auth.store';
import { toast } from 'vue3-toastify';
import ContactSupport from '../components/ContactSupportTemplate.vue';

const apiDomain = Config.app.apiUrl.endsWith('/') ? Config.app.apiUrl : `${Config.app.apiUrl}/`;

const fronteggDomain = Config.frontegg.baseUrl.endsWith('/') ? Config.frontegg.baseUrl : `${Config.frontegg.baseUrl}/`;

const instance = axios.create({
    baseURL: apiDomain
});

const fronteggInstance = axios.create({
    baseURL: fronteggDomain
});

const populateHeader = (customHeaderObject = {}) => {
    const authStore = useAuthStore();

    return {
        Authorization: `Bearer ${authStore.accessToken}`,
        ...customHeaderObject
    };
};

instance.interceptors.response.use(
    (response) => {
        const errorData = _.get(response, 'data.error', null);
        const errorMessage = _.get(response, 'error.message', null);
        const hideToast = !!response.config.hideToast;

        if (errorData || errorMessage) {
            if (!hideToast) {
                const message = errorData || errorMessage;
                // Only show toast if hideToast is true
                toast.error(ContactSupport, {
                    autoClose: 10000,
                    data: {
                        message
                    }
                });
            }
            throw new Error(errorData || errorMessage);
        }

        if (response.data?.message && !hideToast) {
            // Check hideToast before displaying success message
            toast.success(_.capitalize(response.data.message));
        }

        return response;
    },
    (error) => {
        const hideToast = !!error?.response?.config?.hideToast;

        if (error.response?.data?.logout) {
            setTimeout(() => (window.location.href = '/account/logout'), 150);
        }
        // Handle global axios error here
        if (!hideToast && (error.response?.data?.error || error?.message)) {
            const message = error.response?.data?.error || error?.message;

            toast.error(ContactSupport, {
                autoClose: 10000,
                data: {
                    message
                }
            });
        }

        throw error;
    }
);

export default {
    async get(method, customHeaderObject = {}, hideToast = false) {
        const res = await instance.get(method, {
            hideToast,
            headers: populateHeader(customHeaderObject)
        });

        return res.data;
    },
    async post(method, body = {}, customHeaderObject = {}, hideToast = false) {
        const res = await instance.post(method, body, {
            hideToast,
            headers: populateHeader(customHeaderObject)
        });

        return res.data;
    },
    async put(method, body = {}, customHeaderObject = {}, hideToast = false) {
        const res = await instance.put(method, body, {
            hideToast,
            headers: populateHeader(customHeaderObject)
        });

        return res.data;
    },
    async delete(method, customHeaderObject = {}, hideToast = false) {
        const res = await instance.delete(method, {
            hideToast,
            headers: populateHeader(customHeaderObject)
        });

        return res.data;
    },
    async fronteggGet(method, customHeaderObject = {}, hideToast = false) {
        const res = await fronteggInstance.get(method, {
            hideToast,
            headers: populateHeader(customHeaderObject)
        });

        return res.data;
    }
};
