import api from '../services/axios.service';
import { constructQueryParams } from '../utils/utils';

export async function fetchTransformsForConnectors(page_size = 1000) {
    return api.get(`v2/transforms?page_size=${page_size}`);
}

export async function fetchTransforms({ page_size, page_number, partial_name }) {
    const queryParams = constructQueryParams({ page: page_number, page_size, partial_name });

    return api.get(`v2/transforms/details?${queryParams}`);
}

export async function fetchTransformConfigs() {
    return api.get(`v2/transforms/configs`);
}

export async function editTransform(data) {
    return api.put(`v2/transforms`, data);
}
