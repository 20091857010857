import api from '../services/axios.service';
import { constructQueryParams } from '../utils/utils';

export async function fetchTopics({ page_size, page_number, partial_name, entity_id, name }) {
    const queryParams = constructQueryParams({ page: page_number, page_size, partial_name, entity_id, name });

    return api.get(`/v2/topics/details?${queryParams}`);
}

export async function fetchTopic(topic_id) {
    const queryParams = constructQueryParams({ topic_id });
    const response = await api.get(`/v2/topics/details?${queryParams}`);

    return response?.result?.[0];
}

export async function fetchTopicConfigs() {
    return api.get(`/v2/topics/configs`);
}

export async function fetchTopicStatistics() {
    return api.get('/v2/topics/statistics');
}

export async function fetchTopicMessages(topic_id, { page_size, page, sort = 'asc' }) {
    const queryParams = constructQueryParams({ topic_id, page, page_size, sort });

    return api.get(`/v2/topics/messages?${queryParams}`);
}
