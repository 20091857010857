<template>
    <slot v-if="$slots.loading && isLoading" name="loading" />

    <div
        v-if="!$slots.loading && isLoading"
        class=""
        :class="`app-loader d-flex flex-fill flex-column m-auto flex items-center justify-center w-100 ${defaultClass}`"
        :style="{ height: defaultHeight, width: defaultWidth || 'auto', background: background }">
        <div class="flex items-center gap-3">
            <div class="ring1 m-auto">
                <div class="ring2">
                    <div class="ring3" />
                </div>
            </div>
            <div v-if="message">{{ message }}</div>
        </div>
    </div>
    <slot v-if="!isLoading" />
</template>

<script setup>
import { useLoaderStore } from '../stores/loader.store';
import { computed } from 'vue';
// import AppHeader from "./AppHeader.vue";

const props = defineProps({
    listen: {
        type: Array,
        default: () => []
    },
    forceLoading: {
        type: Boolean,
        default: () => false
    },
    defaultHeight: {
        type: String,
        default: () => '100vh'
    },
    defaultWidth: {
        type: String,
        default: () => ''
    },
    background: {
        type: String,
        default: () => 'none'
    },
    defaultClass: {
        type: String,
        default: ''
    },
    message: {
        type: String,
        default: ''
    }
});

const loader = useLoaderStore();

const isLoading = computed(() => props.forceLoading || props.listen.some(loader.isLoading));
</script>
