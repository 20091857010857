<template>
    <div class="mx-auto">
        <div class="page-header">
            <AppHeader />
        </div>
        <!-- Search and Filters -->
        <div class="flex justify-between mb-5 items-end">
            <div class="flex gap-2 w-2/3">
                <!-- <button aria-label="button" class="btn btn-primary rounded-lg" @click="onCreateService">
                    <button
                        aria-label="button"
                        type="button"
                        class="inline-flex items-center rounded-lg border border-transparent bg-green-400 p-0.5 text-white shadow-sm hover:bg-green-400 mr-1">
                        <PlusIcon class="h-5 w-5" />
                    </button>
                    <span class="mx-1 align-super font-medium text-sm">Add</span>
                </button> -->

                <SearchInput
                    :loading="generalLoading"
                    placeholder="Search by name..."
                    :custom-class="'w-1/3'"
                    @search="(input) => (searchQuery = input)" />
            </div>

            <!-- <div class="flex items-end gap-3">
                <DropdownFilters
                    v-model="checkedFilters"
                    slim-dropdown-button
                    :filters="filters"
                    :disabled="generalLoading"
                    @on-filter-toggle="onServicesFilterToggle">
                    <template #dropdownListElement="{ filterOption }">
                        <div class="flex items-center">
                            <div class="flex-1 whitespace-nowrap">
                                {{ filterOption.displayValue }}
                            </div>
                        </div>
                    </template>
                </DropdownFilters>
            </div> -->
        </div>

        <section class="paginated-section mt-2">
            <AppLoader
                :force-loading="generalLoading"
                default-height="20vh"
                :default-width="'100%'"
                background="#f9fafb"
                :default-class="'shadow-md rounded-md'">
                <div v-if="anyServiceIsSwitching" class="fixed inset-0 flex items-center justify-center lg:pl-[254px]" style="z-index: 900">
                    <div class="absolute inset-0 bg-black opacity-50"></div>
                    <div class="relative bg-white rounded-lg shadow-lg w-1/3 items-center justify-center p-10">
                        <AppLoader :force-loading="true" :message="'Service is being switched...'" default-height="inherit" :default-width="'100%'" />
                    </div>
                </div>

                <div class="relative w-full shadow-md overflow-auto">
                    <table class="min-w-full divide-y divide-gray-300 border" aria-label="pipelines table">
                        <thead class="bg-gray-50">
                            <tr>
                                <th
                                    v-can-access.permissions="[$Permissions.SWITCH_SERVICE]"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex items-center gap-3">
                                        <span>Current service</span>
                                    </div>
                                </th>
                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex items-center gap-3">
                                        <!-- <input aria-label="input" v-model="selectAllState" type="checkbox" @input="selectAll" /> -->
                                        <span>Name</span>
                                        <!-- <SortingButton
                                            :column="'name'"
                                            :direction="sortDirection"
                                            @swap-sorting="(event) => swapSorting('name', event)" /> -->
                                    </div>
                                </th>
                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Type</span>
                                        <!-- <SortingButton
                                            :column="'type'"
                                            :direction="sortDirection"
                                            @swap-sorting="(event) => swapSorting('type', event)" /> -->
                                    </div>
                                </th>
                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Created On</span>
                                        <!-- <SortingButton
                                            :column="'createdOn'"
                                            :direction="sortDirection"
                                            @swap-sorting="(event) => swapSorting('createdOn', event)" /> -->
                                    </div>
                                </th>
                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>State</span>
                                        <!-- <SortingButton
                                            :column="'state'"
                                            :direction="sortDirection"
                                            @swap-sorting="(event) => swapSorting('state', event)" /> -->
                                    </div>
                                </th>
                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Cloud</span>
                                        <!-- <SortingButton
                                            :column="'cloud'"
                                            :direction="sortDirection"
                                            @swap-sorting="(event) => swapSorting('cloud', event)" /> -->
                                    </div>
                                </th>
                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Region</span>
                                        <!-- <SortingButton
                                            :column="'region'"
                                            :direction="sortDirection"
                                            @swap-sorting="(event) => swapSorting('region', event)" /> -->
                                    </div>
                                </th>

                                <!-- <th class="text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th> -->
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <template v-if="!generalLoading">
                                <tr
                                    v-for="(service, serviceIndex) in filteredServices"
                                    :key="service.id"
                                    class="transition duration-300 ease-in-out hover:bg-gray-50">
                                    <td v-can-access.permissions="[$Permissions.SWITCH_SERVICE]" class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center gap-2">
                                            <label
                                                class="relative inline-flex items-center cursor-pointer"
                                                :tooltip="
                                                    service.isCurrent ? 'Switching to another service will \n automatically unselect this one' : null
                                                "
                                                :position="'right'">
                                                <input
                                                    v-model="service.isCurrent"
                                                    aria-label="input"
                                                    class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                                    type="checkbox"
                                                    role="switch"
                                                    :class="{
                                                        'bg-green-400': service.isCurrent,
                                                        'bg-neutral-300': !service.isCurrent
                                                    }"
                                                    :true-value="true"
                                                    :disabled="serviceSwitchLoading[service.id] || service.isCurrent"
                                                    :false-value="false"
                                                    aria-describedby="toggle"
                                                    @change="toggleMainService(service.id)" />
                                            </label>
                                            <div v-if="serviceSwitchLoading[service.id]">
                                                <HalfCircleSpinner :loading="serviceSwitchLoading[service.id]" />
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <!-- <div class="flex items-center gap-3">
                                            <div
                                                v-if="serviceInEdit.id !== service.id"
                                                class="max-w-[25rem] break-words whitespace-normal cursor-pointer hover:text-green-600"
                                                @click.stop="selectService(service)">
                                                {{ service.name }}
                                            </div>
                                            <PencilSquareIcon
                                                v-if="serviceInEdit.id !== service.id"
                                                class="h-4 w-4 text-gray-600 cursor-pointer hover:text-gray-600"
                                                @click.stop="editServiceName(service)" />

                                            <div v-else class="w-full flex flex-col">
                                                <div class="w-full flex items-center">
                                                    <input
                                                        v-model="serviceInEdit.name"
                                                        aria-label="input"
                                                        placeholder="Press enter to save, esc to cancel"
                                                        type="text"
                                                        :class="{ 'border-red-500': !serviceInEdit.name }"
                                                        class="w-full border border-gray-300 rounded-l-md px-2 py-1 focus:outline-none"
                                                        required
                                                        @keydown.enter.stop.prevent="serviceInEdit.name ? saveServiceName() : null"
                                                        @keydown.esc.stop.prevent="cancelEditServiceName" />
                                                    <button
                                                        aria-label="button"
                                                        class="bg-gray-400 hover:bg-gray-500 rounded-r-md text-white px-1 flex items-center h-auto"
                                                        :style="'min-height: 38px'"
                                                        @click.stop.prevent="cancelEditServiceName">
                                                        <XMarkIcon class="h-5 w-5" />
                                                    </button>
                                                </div>

                                                <div v-if="!serviceInEdit.name" class="text-red-700 mt-1 text-xs">
                                                    <span> * Service name is required </span>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="flex items-center gap-2">
                                            {{ service.name }}
                                            <small>
                                                <small
                                                    v-if="service.isCurrent"
                                                    class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow bg-green-300">
                                                    CURRENT
                                                </small>
                                            </small>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ service.type }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{{ service.created_timestamp }}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex flex-nowrap justify-content-start mr-4">
                                            <small
                                                class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                :style="`background: ${getStatusColor(service.state)}`"
                                                >{{ service.state.toUpperCase() }}
                                            </small>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <img class="h-7 w-7 flex-shrink-0" :src="`/icons/${service.cloud_provider}.svg`" alt="cloud" />
                                    </td>

                                    <td class="px-6 py-4 whitespace-nowrap">{{ service.region }}</td>
                                    <!-- <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                        <TableActionMenu
                                            :show-edit="true"
                                            :show-delete="true"
                                            :show-stop="canPause(service)"
                                            :show-resume="canResume(service)"
                                            :display-above="filteredServices?.length && serviceIndex >= filteredServices?.length - 2"
                                            :position-relative="!generalLoading"
                                            :button-style="false"
                                            :loading="generalLoading"
                                            @edit="onEdit(service)"
                                            @delete="onDelete(service)"
                                            @stop="onPause(service)"
                                            @resume="onResume(service)"
                                            @snapshot="onSnapshot(service)"
                                            @cancelSnapshot="onCancelSnapshot(service)" />
                                    </td> -->
                                </tr>
                                <tr v-if="!filteredServices?.length">
                                    <td colspan="99" class="text-center p-2">
                                        <small>No matching records found</small>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </AppLoader>

            <!-- <Pagination
                v-if="servicesStore.all.length > 0 || page > 0"
                class="mb-10"
                :page="page"
                :page-size="pageSize"
                :current-page-items-count="paginatedServices.length"
                :total-items-count="servicesStore.all.length"
                :message="paginationMessage"
                @page-change="onPageChange">
                <template #customMessage>
                    Showing
                    <span class="font-medium">{{ paginatedServices.length }}</span>
                    matches on this page
                </template>
            </Pagination> -->
        </section>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import _ from 'lodash';
import AppHeader from '../../components/AppHeader.vue';
import Pagination from '../../components/Pagination.vue';
import TableActionMenu from '../../components/TableActionMenu.vue';
import AppLoader from '../../components/AppLoader.vue';
import { PencilSquareIcon, XMarkIcon, PlusIcon } from '@heroicons/vue/24/outline';
import SortingButton from '../../components/global/SortingButton.vue';
import { FILTER_CONSTANTS } from '../../../src/utils/utils';
import { useServicesStore } from '@/stores/services.store';
import SearchInput from '@/components/global/SearchInput.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import Config from '../../utils/config';
import { getStatusColor } from '../../utils/utils';
import { useRouter } from 'vue-router';
import useConfirmBeforeAction from '../../composables/useConfirmBeforeAction.js';
import HalfCircleSpinner from '@/components/global/loader/HalfCircleSpinner.vue';

const servicesStore = useServicesStore();

// const page = ref(0);
// const pageSize = ref(0);
// const pageSize = 10;
const selectedService = ref(null);
const generalLoading = ref(false);
const sortColumn = ref('name');
const sortDirection = ref(false);
const serviceInEdit = ref({});

const serviceSwitchLoading = ref({});

let checkedFilters = ref([]);
const router = useRouter();

const searchQuery = ref('');

const filters = ref([
    {
        title: 'Services per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            { value: 5, displayValue: '5/page' },
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
        ]
    }
]);

const swapSorting = (column, direction) => {
    sortColumn.value = column;
    sortDirection.value = direction;
};

async function onPause(service) {
    useConfirmBeforeAction(
        'pause',
        async () => {
            try {
                console.log('pause');
            } catch (e) {
                console.log(e);
            } finally {
                console.log('done 1');
            }
        },
        { name: service.name, actionName: 'pause' },
        () => {
            console.log('done 2');
        }
    );
}

function canPause(service) {
    console.log('can pause service', service);

    return true;
}

function canResume(service) {
    console.log('resume', service);

    return false;
}

async function onResume(service) {
    console.log('resume', service);
}

async function onEdit(service) {
    console.log('edit service', service);
}

async function onDelete(service) {
    useConfirmBeforeAction(
        'delete',
        async () => {
            try {
                console.log('delete');
            } catch (e) {
                console.log(e);
            } finally {
                console.log('done 1');
            }
        },
        { entity: null, name: service.name },
        () => {
            console.log('done 2');
        }
    );
}

onMounted(async () => {
    // page.value = servicesStore.page || 0;
    // pageSize.value = servicesStore.pageSize || 10;
    // initializeServicesFilters();
    generalLoading.value = true;

    try {
        await servicesStore.fetch().then(() => {
            generalLoading.value = false;
        });
    } catch (e) {
        console.log(e);
    } finally {
        generalLoading.value = false;
    }
});

const anyServiceIsSwitching = computed(() => {
    return Object.values(serviceSwitchLoading.value).some((loading) => loading);
});

async function toggleMainService(serviceId) {
    try {
        serviceSwitchLoading.value[serviceId] = true;

        await servicesStore.switch(serviceId);

        setTimeout(() => {
            window.location.reload();
        }, 800);
    } catch (e) {
        console.log(e);
        serviceSwitchLoading.value[serviceId] = false;
    }
}

function initializeServicesFilters() {
    filters.value.forEach((filter) => {
        if (filter.name === 'count') {
            pageSize.value = filter.options[filter.defaultIndex].value;
        }

        checkedFilters.value[filter.name] = [filter.multiple ? FILTER_CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

const onCreateService = () => {
    router.push({ name: 'new-service' });
};

const editServiceName = (service) => {
    serviceInEdit.value = _.cloneDeep(service);
};

const cancelEditServiceName = () => {
    serviceInEdit.value = {};
};

const saveServiceName = async () => {
    await servicesStore.editService(serviceInEdit.value);

    serviceInEdit.value = {};
};

async function onServicesFilterToggle({ filter, filterOption }) {
    // Handle filter toggle logic
    console.log(filter, filterOption);

    return;
}

const filteredServices = computed(() => {
    // const startIndex = page.value * pageSize;

    // const paginated = _.slice(servicesStore.all, startIndex, startIndex + pageSize);

    return _.filter(servicesStore.all, (sr) =>
        !searchQuery.value ? true : `${sr.name}.${sr.id}`.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
});

const paginationMessage = computed(() => {
    return {
        show: paginatedServices.value.length === servicesStore.all.length,
        showCustomMessage: paginatedServices.value.length !== servicesStore.all.length,
        firstPart: 'Showing services',
        middlePart: 'to',
        lastPart: `out of ${servicesStore.all.length}`
    };
});

const selectService = (service) => {
    if (selectedService.value === service) {
        selectedService.value = null;
    } else {
        selectedService.value = service;
    }
};

const onPageChange = (newPage) => {
    page.value = newPage;
};
</script>

<style>
.disabled-toggle {
    pointer-events: none !important;
    opacity: 0.5 !important;
    cursor: pointer !important;
    background-color: #3b82f6 !important;
}
</style>
