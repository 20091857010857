import api from '../services/axios.service';

export async function fetchTenantTree() {
    return api.get('tenant_trees');
}

export async function inviteColleague(payload) {
    return api.post(`invite_colleague/${payload.connectorType}`, payload);
}

export async function fetchCelloToken() {
    return api.get(`cello_token`);
}

export async function sendCelloReferrerUCC(ucc) {
    return api.put('cello_ucc', { ucc });
}
