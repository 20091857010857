<template>
    <div class="modal-container fixed z-50">
        <div class="text-sm modal-body shadow-xl m-3 max-h-[80%]" :style="`width: ${width}%;`">
            <span class="modal-close" @click="closeDialog">
                <XMarkIcon class="h-5 w-5 text-gray-600 hover:text-gray-600" />
            </span>
            <div class="overflow-hidden flex flex-col">
                <div class="bg-gray-200 w-full flex justify-end py-1 px-2 rounded-t">
                    <button aria-label="button" class="cursor-pointer flex items-center" :disabled="copied" @click="copyToClipboard">
                        <ClipboardIcon v-if="!copied" class="h-6 w-6 pr-1" />
                        <ClipboardDocumentCheckIcon v-else class="h-6 w-6 pr-1" />
                        <span v-if="!copied">Copy Message</span>
                        <span v-else>Copied!</span>
                    </button>
                </div>
                <pre
                    :class="[
                        isError ? 'text-red-500' : '',
                        'whitespace-pre-line overflow-x-auto text-left text-sm font-mono bg-gray-100 p-2 rounded-b'
                    ]">
          {{ message }}
        </pre
                >
            </div>
        </div>
    </div>
</template>

<script>
import { XMarkIcon, ClipboardDocumentCheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'LogMessageDialog',
    components: {
        XMarkIcon,
        ClipboardDocumentCheckIcon,
        ClipboardIcon
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        isError: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 60
        }
    },
    emits: ['cancel'],
    data() {
        return {
            copied: false
        };
    },
    methods: {
        async copyToClipboard() {
            this.copied = true;
            await navigator.clipboard.writeText(this.message);
            setTimeout(() => {
                this.copied = false;
            }, 3000);
        },
        closeDialog() {
            this.$emit('cancel');
        }
    }
};
</script>

<style>
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #cececeb5;
}
.modal-body {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 20px 40px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
}
.modal-action {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
}
.modal-close {
    cursor: pointer;
    position: relative;
    align-self: end;
    right: -25px;
    top: -10px;
}
</style>
