<script>
import { RouterView } from 'vue-router';
import SideMenu from './components/SideMenu.vue';
import AppHeader from './components/AppHeader.vue';
import { useAuthStore } from './stores/auth.store';
import { WebSocketService, SentryService } from './services';
import _ from 'lodash';
import { useSourcesStore } from './stores/sources.store';
import { useDestinationsStore } from './stores/destinations.store';
import { usePipelinesStore } from './stores/pipelines.store';
import { useUserStore } from './stores/user.store';
import { useNovuStore } from './stores/novu.store';
import { useServicesStore } from './stores/services.store';
import AppLoader from './components/AppLoader.vue';
import Config from './utils/config';
import { userHasAccess } from './utils/utils';
import { Permissions } from './utils/constants';

export default {
    name: 'App',
    components: { SideMenu, AppHeader, RouterView, AppLoader },
    setup() {
        const authStore = useAuthStore();
        const sourcesStore = useSourcesStore();
        const destinationsStore = useDestinationsStore();
        const pipelinesStore = usePipelinesStore();
        const userStore = useUserStore();
        const novuStore = useNovuStore();
        const servicesStore = useServicesStore();
        return { authStore, sourcesStore, destinationsStore, pipelinesStore, userStore, novuStore, servicesStore };
    },
    data() {
        return {
            firstLoad: true,
            loading: false,
            Permissions,
            ...this.mapAuthState()
        };
    },
    async created() {
        this.authStore.updateState(this.authState);
    },
    async updated() {
        if (this.authStore.accessToken !== _.get(this.authState, 'user.accessToken', null)) {
            this.pipelinesStore.pipelines = undefined;
            this.sourcesStore.sources = undefined;
            this.destinationsStore.destinations = undefined;
            this.userStore.tenants = undefined;
            this.firstLoad = true;
        }

        this.authStore.updateState(this.authState);

        if (this.authState.isAuthenticated && this.firstLoad) {
            try {
                this.firstLoad = false;
                this.loading = true;

                await Promise.allSettled([SentryService.init(), WebSocketService.init()]);

                this.userStore.fetchTenantTree();

                window.pylon = {
                    chat_settings: {
                        app_id: 'baaa4911-2fd1-4c3a-bc59-ab905b8c63a4',
                        name: `${this.authStore.user.firstName} ${this.authStore.user.lastName}`,
                        email: this.authStore.user.email,
                        // (Optional) If you have the user's profile image available through something like Auth0
                        avatar_url: this.authStore.user.profilePictureUrl
                        // (Optional) If you are using Pylon's identity verification
                        // email_hash: 'USER_EMAIL_HASH'
                    }
                };

                window.pylon.debug = true;

                window.analytics.identify(this.authStore.user.id, {
                    email: this.authStore.user.email,
                    name: `${this.authStore.user.firstName} ${this.authStore.user.lastName}`,
                    company: {
                        id: this.authState?.tenantsState?.activeTenant?.tenantId,
                        name: this.authState?.tenantsState?.activeTenant?.name
                    }
                });

                window.Appcues.identify(this.authStore.user.id, {
                    firstName: this.authStore.user.firstName,
                    lastName: this.authStore.user.lastName,
                    companyName: this.authState?.tenantsState?.activeTenant?.name,
                    email: this.authStore.user.email
                });

                if (Config.app.isDev) {
                    const localAuthStore = this.authStore;
                    const localUserStore = this.userStore;

                    const localWindow = window;

                    // this.userStore.fetchCelloToken().then(async (celloInfo) => {
                    //     window.cello.cmd.push(async function (cello) {
                    //         try {
                    //             await cello.boot({
                    //                 productId: celloInfo.product_id,
                    //                 token: celloInfo.token,
                    //                 language: 'en',
                    //                 productUserDetails: {
                    //                     firstName: localAuthStore.user.firstName,
                    //                     lastName: localAuthStore.user.lastName,
                    //                     fullName: `${localAuthStore.user.firstName} ${localAuthStore.user.lastName}`,
                    //                     email: localAuthStore.user.email
                    //                 }
                    //             });

                    //             const referrerUCC = localWindow.CelloAttribution('getReferral');

                    //             if (referrerUCC) {
                    //                 localUserStore.sendCelloReferrerUCC(referrerUCC);
                    //             }

                    //             console.log('Cello booted successfully');
                    //         } catch (error) {
                    //             console.error('Failed to boot cello:', error);
                    //         }
                    //     });
                    // });
                }

                const permissions = [Permissions.READ_SERVICES];

                if (userHasAccess(permissions, this.authStore.user)) this.servicesStore.fetch();
                await this.novuStore.initializeService();
            } finally {
                this.firstLoad = false;
                this.loading = false;
            }
        }
    }
};
</script>

<template>
    <div v-if="fronteggLoaded && authState.isAuthenticated" id="app" class="min-h-screen bg-gray-100 overflow-auto">
        <SideMenu>
            <transition name="fade" mode="out-in">
                <RouterView v-slot="{ Component }">
                    <AppLoader :force-loading="firstLoad || loading" default-height="100vh">
                        <template v-if="Component && !loading">
                            <Suspense timeout="0">
                                <component :is="Component" />
                            </Suspense>
                        </template>
                    </AppLoader>
                </RouterView>
            </transition>
        </SideMenu>

        <DialogsWrapper />
    </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
