<script>
import AppLoader from '../../components/AppLoader.vue';
import AppHeader from '../../components/AppHeader.vue';

import { ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import _ from 'lodash';
import { useRouter } from 'vue-router';

import { usePipelinesStore } from '../../stores/pipelines.store';
import { useMetricsStore } from '../../stores/metrics.store';
import { useSourcesStore } from '../../stores/sources.store';
import { useDestinationsStore } from '../../stores/destinations.store';

import { getStatusColor, formatter, userHasAccess } from '../../utils/utils';

import { ClockIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { ChevronDownIcon, ChevronUpIcon, ChevronUpDownIcon, FunnelIcon, PlusIcon } from '@heroicons/vue/20/solid';

import useConfirmBeforeAction from '../../composables/useConfirmBeforeAction.js';
import TableActionMenu from '../../components/TableActionMenu.vue';
import useErrorDialog from '../../composables/useErrorDialog.js';
import Pagination from '../../components/Pagination.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import { toast } from 'vue3-toastify';
import SearchInput from '@/components/global/SearchInput.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'ProfileMenu',
    components: {
        InformationCircleIcon,
        ChevronDownIcon,
        ChevronUpIcon,
        ChevronUpDownIcon,
        FunnelIcon,
        ClockIcon,
        PlusIcon,
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        AppLoader,
        AppHeader,
        TableActionMenu,
        Pagination,
        DropdownFilters,
        SearchInput
    },
    data() {
        return {
            metricsStore: useMetricsStore(),
            router: useRouter(),
            authStore: useAuthStore(),
            sourcesStore: useSourcesStore(),
            pipelinesStore: usePipelinesStore(),
            destinationsStore: useDestinationsStore(),
            itemLoading: ref({}),
            searchQuery: null,
            sortColumn: ref('source.name'),
            sortDirection: ref(false),
            triggerFilterSelection: ref(false),
            generalLoading: ref(true),
            actionLoading: ref({}),
            metricsLoading: ref(false),
            page: 0,
            pageSize: 0,
            checkedAsyncFilters: [],
            asyncFilters: [
                {
                    title: 'Pipelines per page',
                    name: 'count',
                    defaultIndex: 0,
                    options: [
                        { value: 10, displayValue: '10/page' },
                        { value: 20, displayValue: '20/page' },
                        { value: 50, displayValue: '50/page' }
                    ]
                }
            ],
            filters: [
                {
                    placeholder: 'Status'
                },
                {
                    placeholder: 'Source'
                },
                {
                    placeholder: 'Destination'
                }
            ],
            selectedFilters: [[], [], []]
        };
    },
    computed: {
        allLatestMetrics() {
            return this.metricsStore.pipelines.allLatest;
        },
        searchedPipelines() {
            let result = this.applyFilters(this.pipelinesStore.all);
            result = this.applySorting(result);

            return result;
        },
        filterValues() {
            const result = [];
            result.push([null, 'Active', 'Broken', 'Delayed', 'Paused']);
            const sourceNames = this.loadSourceNames();
            const destinationName = this.loadDestinationNames();
            result.push(sourceNames);
            result.push(destinationName);

            return result;
        }
    },
    watch: {
        generalLoading: {
            handler: function (newValue) {
                if (newValue === true && this.$refs.scrollableContainer) {
                    this.$refs.scrollableContainer.scrollTop = 0;
                }
            }
        },
        searchQuery: {
            handler: _.debounce(async function () {
                await this.loadPipelines();
            }, 700)
        }
    },
    mounted() {
        this.page = this.pipelinesStore.page || 0;
        this.pageSize = this.pipelinesStore.pageSize || 10;

        this.loadPipelines();
        this.initializeAsyncFilters();
    },
    methods: {
        userHasAccess,
        formatter,
        getFormattedValue(pipelineToSearch, metricKey, unit, dashOnRule = null) {
            const metricValue = this.allLatestMetrics[pipelineToSearch.id]?.inline?.[metricKey];

            const formatterParams = [metricValue, { unit: unit || null }];

            if (dashOnRule || dashOnRule === false) {
                formatterParams.push(dashOnRule);
            }

            return this.formatter(...formatterParams);
        },
        getStatusColor,
        showStopSource(pipeline) {
            return !(
                pipeline.source.connector_status?.toLowerCase() === 'paused' ||
                pipeline.source.connector_status?.toLowerCase() === 'starting' ||
                pipeline.source.connector_status?.toLowerCase() === 'stopped'
            );
        },
        showResumeSource(pipeline) {
            return !(
                pipeline.source.connector_status?.toLowerCase() === 'active' ||
                pipeline.source.connector_status?.toLowerCase() === 'broken' ||
                pipeline.source.connector_status?.toLowerCase() === 'starting'
            );
        },
        showStopDestination(pipeline) {
            return !(
                pipeline.destination.connector_status?.toLowerCase() === 'paused' ||
                pipeline.destination.connector_status?.toLowerCase() === 'starting' ||
                pipeline.destination.connector_status?.toLowerCase() === 'stopped'
            );
        },
        showResumeDestination(pipeline) {
            return !(
                pipeline.destination.connector_status?.toLowerCase() === 'active' ||
                pipeline.destination.connector_status?.toLowerCase() === 'broken' ||
                pipeline.destination.connector_status?.toLowerCase() === 'starting'
            );
        },
        onCreatePipeline() {
            return this.router.push({ name: 'new-pipeline' });
        },
        onPipelineSelected(pipeline) {
            return this.router.push(this.selectedPipelineRouterPath(pipeline));
        },
        selectedPipelineRouterPath(pipeline) {
            return {
                name: 'pipeline-details',
                params: {
                    id: pipeline.id
                },
                query: {
                    source: pipeline.source.id,
                    destination: pipeline.destination.id
                }
            };
        },
        selectedConnectorPath(id, type = 'source') {
            return type === 'source' ? { name: 'source-details', params: { id } } : { name: 'destination-details', params: { id } };
        },
        onDelete(entity, name, id) {
            useConfirmBeforeAction(
                'delete',
                async () => {
                    try {
                        this.actionLoading[id] = true;
                        await this.pipelinesStore.delete(id);
                        this.actionLoading[id] = false;
                    } finally {
                        this.actionLoading[id] = false;
                    }
                },
                { entity: entity, name: name },
                () => {
                    this.actionLoading[id] = false;
                }
            );
        },
        onPipelineViewed(id) {
            return this.router.push({ name: 'pipeline-details', params: { id } });
        },
        // async onPauseDestination(pipeline) {
        //     this.actionLoading[pipeline.id] = true;
        //     try {
        //         await this.destinationsStore.pause(pipeline.destination.id, true);
        //         this.actionLoading[pipeline.id] = false;
        //         toast.success('Destination paused successfully.');
        //         this.itemLoading[pipeline.id] = true;
        //         await this.metricsStore.pipelines.fetchInlinePipelineMetrics([pipeline.id], true);
        //         this.itemLoading[pipeline.id] = false;
        //     } catch (e) {
        //         toast.error('There was an error while pausing the destination. Please try again.');
        //     } finally {
        //         this.actionLoading[pipeline.id] = false;
        //         this.itemLoading[pipeline.id] = false;
        //     }
        // },
        async onStopDestination(pipeline) {
            this.actionLoading[pipeline.id] = true;
            try {
                await this.destinationsStore.stop(pipeline.destination.id, true);
                this.actionLoading[pipeline.id] = false;
                toast.success('Destination stopped successfully.');
                this.itemLoading[pipeline.id] = true;
                await this.metricsStore.pipelines.fetchInlinePipelineMetrics([pipeline.id], true);
                this.itemLoading[pipeline.id] = false;
            } catch (e) {
                toast.error('There was an error while stopping the destination. Please try again.');
            } finally {
                this.actionLoading[pipeline.id] = false;
                this.itemLoading[pipeline.id] = false;
            }
        },
        async onResumeDestination(pipeline) {
            this.actionLoading[pipeline.id] = true;
            try {
                await this.destinationsStore.resume(pipeline.destination.id, true);
                this.actionLoading[pipeline.id] = false;
                toast.success('Destination resumed successfully.');
                this.itemLoading[pipeline.id] = true;
                await this.metricsStore.pipelines.fetchInlinePipelineMetrics([pipeline.id], true);
                this.itemLoading[pipeline.id] = false;
            } catch (e) {
                toast.error('There was an error while resuming the destination. Please try again.');
            } finally {
                this.actionLoading[pipeline.id] = false;
                this.itemLoading[pipeline.id] = false;
            }
        },
        // async onPauseSource(pipeline) {
        //     this.actionLoading[pipeline.id] = true;
        //     try {
        //         await this.sourcesStore.pause(pipeline.source.id, true);
        //         this.actionLoading[pipeline.id] = false;

        //         toast.success('Source paused successfully.');
        //         this.itemLoading[pipeline.id] = true;
        //         await this.metricsStore.pipelines.fetchInlinePipelineMetrics([pipeline.id], true);
        //         this.itemLoading[pipeline.id] = false;
        //     } catch (e) {
        //         toast.error('There was an error while pausing the source. Please try again.');
        //     } finally {
        //         this.actionLoading[pipeline.id] = false;
        //         this.itemLoading[pipeline.id] = false;
        //     }
        // },
        async onStopSource(pipeline) {
            this.actionLoading[pipeline.id] = true;
            try {
                await this.sourcesStore.stop(pipeline.source.id, true);
                this.actionLoading[pipeline.id] = false;

                toast.success('Source stopped successfully.');
                this.itemLoading[pipeline.id] = true;
                await this.metricsStore.pipelines.fetchInlinePipelineMetrics([pipeline.id], true);
                this.itemLoading[pipeline.id] = false;
            } catch (e) {
                toast.error('There was an error while stopping the source. Please try again.');
            } finally {
                this.actionLoading[pipeline.id] = false;
                this.itemLoading[pipeline.id] = false;
            }
        },
        async onResumeSource(pipeline) {
            this.actionLoading[pipeline.id] = true;
            try {
                await this.sourcesStore.resume(pipeline.source.id, true);
                this.actionLoading[pipeline.id] = false;

                this.itemLoading[pipeline.id] = true;
                await this.metricsStore.pipelines.fetchInlinePipelineMetrics([pipeline.id], true);
                this.itemLoading[pipeline.id] = false;
                toast.success('Source resumed successfully.');
            } catch (e) {
                toast.error('There was an error while resuming the source. Please try again.');
            } finally {
                this.actionLoading[pipeline.id] = false;
                this.itemLoading[pipeline.id] = false;
            }
        },
        async onRestart(id) {
            try {
                this.actionLoading[id] = true;
                await this.pipelinesStore.restart(id);
                await this.pipelinesStore.getById(id);
                this.actionLoading[id] = true;

                toast.success('Pipeline resumed successfully.');

                this.itemLoading[id] = true;
                await this.metricsStore.pipelines.fetchInlinePipelineMetrics([id], true);
                this.itemLoading[id] = false;
            } catch (e) {
                toast.error('There was an error while resuming the pipeline. Please try again.');
            } finally {
                this.actionLoading[id] = false;
                this.itemLoading[id] = false;
            }
        },
        onViewLogs(pipeline) {
            const route = this.router.resolve({
                name: 'logs',
                query: { source_id: pipeline.source.id, destination_id: pipeline.destination.id }
            });
            window.open(route.href, '_blank');
        },
        async loadPipelines() {
            try {
                this.generalLoading = true;

                if (this.searchQuery || this.searchQuery === '') {
                    this.page = 0;
                }

                await this.pipelinesStore.fetchCreated(this.page, this.pageSize, this.searchQuery);

                const pipelineIds = this.pipelinesStore.all?.map((pipeline) => pipeline.id);

                this.generalLoading = false;
                this.metricsLoading = true;
                await this.metricsStore.pipelines.fetchInlinePipelineMetrics(pipelineIds);
            } finally {
                this.generalLoading = false;
                this.metricsLoading = false;
            }
        },
        swapSorting(column, direction) {
            this.sortColumn = column;
            this.sortDirection = direction;
        },
        applySorting(result) {
            return _.orderBy(result, [this.sortColumn], [this.sortDirection ? 'desc' : 'asc']);
        },
        loadSourceNames() {
            const values = [null];
            this.searchedPipelines.forEach((pipeline) => {
                const index = values.indexOf(pipeline.source.name);
                if (index === -1) {
                    values.push(pipeline.source.name);
                }
            });
            return values;
        },
        loadDestinationNames() {
            const values = [null];
            this.searchedPipelines.forEach((pipeline) => {
                const index = values.indexOf(pipeline.destination.name);
                if (index === -1) {
                    values.push(pipeline.destination.name);
                }
            });
            return values;
        },
        applyFilters(result) {
            return result.filter(
                (x) =>
                    (this.selectedFilters[0].length <= 0 || this.selectedFilters[0].includes(this.allLatestMetrics[x.id]?.inline?.status)) &&
                    (this.selectedFilters[1].length <= 0 || this.selectedFilters[1].includes(x.source.name)) &&
                    (this.selectedFilters[2].length <= 0 || this.selectedFilters[2].includes(x.destination.name))
            );
        },
        selectFilter(filter, possibleIndex, filterIndex) {
            if (possibleIndex !== 0) {
                const index = this.selectedFilters[filterIndex].indexOf(filter);
                if (index === -1) {
                    this.selectedFilters[filterIndex].push(filter);
                } else {
                    this.selectedFilters[filterIndex].splice(index, 1);
                }
            } else {
                this.selectedFilters[filterIndex] = [];
            }
            this.triggerFilterSelection = !this.triggerFilterSelection;
        },
        initializeAsyncFilters() {
            this.asyncFilters.forEach((filter) => {
                if (filter.name === 'count') {
                    this.pageSize = filter.options[filter.defaultIndex].value;
                }
                this.checkedAsyncFilters[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
            });
        },
        onAsyncFilterToggle({ filter, filterOption }) {
            if (filter.name === 'count') {
                this.pageSize = filterOption.value;
            }

            this.page = 0;
            this.loadPipelines();
        },
        onPageChange(newPage) {
            this.page = newPage;
            this.loadPipelines();
        },
        onStatusClick(connector) {
            if (connector.connector_status === 'Broken' && connector.task_statuses) {
                const errors = [...new Set(Object.values(connector.task_statuses).map((task) => task.trace))];
                useErrorDialog({
                    errors,
                    width: 65
                });
            }
        },
        onPipelineStatusClick(pipeline) {
            const source = pipeline.source;
            const destination = pipeline.destination;
            let sourceTaskStatuses = source.connector_status === 'Broken' ? source.task_statuses || {} : {};
            let destinationTaskStatuses = destination.connector_status === 'Broken' ? destination.task_statuses || {} : {};

            sourceTaskStatuses = new Set(
                Object.values(sourceTaskStatuses).map(
                    (task) => `<b>SOURCE - ${source.connector} (${source.name} - ID: ${source.id})</b> - ${task.trace}\n`
                )
            );
            destinationTaskStatuses = new Set(
                Object.values(destinationTaskStatuses).map(
                    (task) => `\n<b>DESTINATION - ${destination.connector} (${destination.name} - ID: ${destination.id})</b> - ${task.trace}`
                )
            );

            if (sourceTaskStatuses.size || destinationTaskStatuses.size) {
                const errors = [...sourceTaskStatuses, ...destinationTaskStatuses];
                useErrorDialog({
                    errors,
                    width: 65
                });
            }
        }
    }
};
</script>

<template>
    <div class="table-page-container mx-auto">
        <div class="page-header">
            <AppHeader />
        </div>

        <div class="table-page-body">
            <div class="page-options flex justify-between items-start">
                <div class="flex gap-3 w-full">
                    <button
                        v-can-access.permissions="[$Permissions.WRITE_PIPELINES]"
                        aria-label="button"
                        class="btn btn-primary rounded-lg"
                        @click="onCreatePipeline">
                        <button
                            aria-label="button"
                            type="button"
                            class="inline-flex items-center rounded-lg border border-transparent bg-green-400 p-0.5 text-white shadow-sm hover:bg-green-400 mr-1">
                            <PlusIcon class="h-5 w-5" />
                        </button>
                        <span class="mx-1 align-super font-medium text-sm">Create</span>
                    </button>

                    <SearchInput
                        :loading="generalLoading"
                        placeholder="Search by name..."
                        :custom-class="'w-2/3'"
                        @search="(input) => (searchQuery = input)" />
                </div>

                <div class="page-filters flex gap-3">
                    <Menu v-for="(filter, index) in filters" :key="index" as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton
                                :class="[
                                    generalLoading ? 'bg-gray-100 opacity-[0.7]' : 'bg-white hover:bg-gray-50',
                                    'inline-flex w-full justify-center items-center px-3 rounded-md border border-gray-300 text-sm font-medium text-gray-700 shadow-sm'
                                ]"
                                style="min-height: 40.5px !important"
                                :disabled="generalLoading">
                                <FunnelIcon class="h-4 w-4 self-center mr-1" />
                                <div v-if="selectedFilters[index].length > 0">
                                    <div
                                        v-for="(selectedFilter, index) in selectedFilters[index]"
                                        :key="index"
                                        class="inline-flex items-center rounded-full bg-gray-100 text-xs font-medium text-gray-800 m-1">
                                        <div class="flex">
                                            <small
                                                v-if="selectedFilter"
                                                class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                :style="`background: ${getStatusColor(selectedFilter)}`"
                                                >{{ selectedFilter }}</small
                                            >
                                            <span v-if="!selectedFilter">{{ selectedFilter }} {{ filter.text }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedFilters[index].length <= 0">
                                    {{ filter.placeholder }}
                                </div>
                                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" />
                            </MenuButton>
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                            <MenuItems
                                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                    <MenuItem v-for="(possibleFilter, possibleIndex) in filterValues[index]" :key="possibleIndex" v-slot="{}">
                                        <a
                                            href="#"
                                            :class="[
                                                selectedFilters[index].includes(possibleFilter) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            ]"
                                            @click="selectFilter(possibleFilter, possibleIndex, index)">
                                            <div class="flex">
                                                <small
                                                    v-if="possibleFilter"
                                                    class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                    :style="`background: ${getStatusColor(possibleFilter)}`"
                                                    >{{ possibleFilter }}</small
                                                >
                                                <span v-if="!possibleFilter">{{ possibleFilter ? possibleFilter : filter.placeholder }}</span>
                                            </div>
                                        </a>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>

                    <DropdownFilters
                        v-model="checkedAsyncFilters"
                        :filters="asyncFilters"
                        :disabled="generalLoading"
                        @on-filter-toggle="onAsyncFilterToggle" />
                </div>
            </div>

            <section class="paginated-section mt-2">
                <AppLoader
                    :force-loading="generalLoading"
                    default-height="20vh"
                    :default-width="'100%'"
                    background="#f9fafb"
                    :default-class="'shadow-md rounded-md'">
                    <div
                        class="relative w-full shadow-md"
                        :class="!searchedPipelines?.length || searchedPipelines?.length > 4 ? 'overflow-auto' : ''">
                        <table class="min-w-full divide-y divide-gray-300 border" aria-label="pipelines table">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Name</span>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Status</span>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex gap-1 items-center">
                                            SOURCE
                                            <span
                                                class="cursor-pointer inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('source.connector_status', !sortDirection)">
                                                <ChevronDownIcon
                                                    v-if="sortColumn == 'source.connector_status' && sortDirection == true"
                                                    class="h-4 w-4" />
                                                <ChevronUpIcon
                                                    v-if="sortColumn == 'source.connector_status' && sortDirection == false"
                                                    class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'source.connector_status'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex gap-1 items-center">
                                            Destination
                                            <span
                                                class="cursor-pointer inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('destination.connector_status', !sortDirection)">
                                                <ChevronDownIcon
                                                    v-if="sortColumn == 'destination.connector_status' && sortDirection == true"
                                                    class="h-4 w-4" />
                                                <ChevronUpIcon
                                                    v-if="sortColumn == 'destination.connector_status' && sortDirection == false"
                                                    class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'destination.connector_status'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        Latency
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Lag</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Topics</th>
                                    <th class="text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <template v-if="!generalLoading">
                                    <tr
                                        v-for="(pipeline, pipelineIndex) in searchedPipelines"
                                        :key="pipelineIndex"
                                        class="transition duration-300 ease-in-out hover:bg-gray-50">
                                        <td class="px-6 py-4 whitespace-nowrap cursor-pointer font-medium text-green-500 no-underline">
                                            <div class="flex flex-col mr-4 gap-1 w-full">
                                                <div class="my-auto whitespace-normal break-words" style="max-width: 25rem">
                                                    <router-link
                                                        :to="selectedPipelineRouterPath(pipeline)"
                                                        class="font-medium text-cyan-950 no-underline hover:text-cyan-800">
                                                        {{ pipeline.name }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading || itemLoading[pipeline.id]" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <small
                                                v-else
                                                class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                :class="allLatestMetrics[pipeline.id]?.inline.status === 'Broken' ? `cursor-pointer` : ''"
                                                :style="`background: ${getStatusColor(allLatestMetrics[pipeline.id]?.inline.status)}`"
                                                @click="allLatestMetrics[pipeline.id]?.inline.status ? onPipelineStatusClick(pipeline) : null">
                                                {{ allLatestMetrics[pipeline.id]?.inline.status ?? '-' }}
                                                <InformationCircleIcon
                                                    v-if="allLatestMetrics[pipeline.id]?.inline.status === 'Broken'"
                                                    class="h-4 w-4 ml-1" />
                                            </small>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap cursor-pointer">
                                            <div class="flex items-center mr-4 gap-2">
                                                <img :src="`/icons/${pipeline.source.connector}.svg`" alt="source" class="w-6 h-6" />
                                                <div class="my-auto whitespace-normal break-words max-w-sm">
                                                    <router-link :to="selectedConnectorPath(pipeline.source.id, 'source')">
                                                        {{ pipeline.source.name }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </td>

                                        <!-- <div class="flex items-center">
                                                                <small
                                                                    class="inline-flex items-center justify-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                                    :class="[
                                                                        'inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow',
                                                                        pipeline.source.connector_status === 'Broken' ? 'cursor-pointer' : ''
                                                                    ]"
                                                                    :style="`background: ${getStatusColor(pipeline.source.connector_status)}`"
                                                                    @click="() => onStatusClick(pipeline.source)">
                                                                    {{ pipeline.source.connector_status }}
                                                                    <InformationCircleIcon
                                                                        v-if="pipeline.source.connector_status === 'Broken'"
                                                                        class="h-4 w-4 ml-1"
                                                                         />
                                                                </small>
                                                                <div
                                                                    v-if="
                                                                        !sourceMetrics[pipeline.source.id]?.inline?.state?.value
                                                                            ?.toLowerCase()
                                                                            .includes('idle') &&
                                                                        allLatestMetrics[pipeline.id]?.inline?.state?.value &&
                                                                        allLatestMetrics[pipeline.id]?.inline?.latency?.value < 60000
                                                                    "
                                                                    class="mx-3 inline-flex"
                                                                    role="status"
                                                                    title="Low Latency">
                                                                    <div class="snippet" data-title=".dot-flashing">
                                                                        <div class="stage">
                                                                            <div class="dot-flashing" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="flex flex-row items-center gap-2">
                                                                    <small
                                                                        v-if="allLatestMetrics[pipeline.id]?.inline.latency?.value > 60000"
                                                                        class="ml-2 shadow-sm">
                                                                        <ClockIcon
                                                                            class="h-4 w-4 flex-shrink-0 text-[#236C9E]"
                                                                            style="display: block; margin: auto"
                                                                             />
                                                                    </small>
                                                                    <div v-if="sourceMetrics[pipeline.source.id]?.inline?.state?.value" class="ml-2">
                                                                        <small
                                                                            class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                                            style="background-color: rgb(249 250 251)">
                                                                            <span class="my-auto">
                                                                                {{ sourceMetrics[pipeline.source.id]?.inline.state.value }}
                                                                            </span>
                                                                        </small>
                                                                    </div>
                                                                    <div v-else class="snippet ml-2" data-title=".dot-flashing">
                                                                        <div class="stage">
                                                                            <div class="dot-flashing" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                        <td class="px-6 py-4 whitespace-nowrap cursor-pointer">
                                            <div class="flex items-center mr-4 gap-2">
                                                <img :src="`/icons/${pipeline.destination.connector}.svg`" alt="destination" class="w-6 h-6" />
                                                <div class="my-auto whitespace-normal break-words max-w-sm">
                                                    <router-link :to="selectedConnectorPath(pipeline.destination.id, 'destination')">
                                                        {{ pipeline.destination.name }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex flex-nowrap justify-content-start mr-4">
                                                <div
                                                    v-if="metricsLoading || itemLoading[pipeline.id]"
                                                    class="snippet ml-3"
                                                    data-title=".dot-flashing">
                                                    <div class="stage">
                                                        <div class="dot-flashing" />
                                                    </div>
                                                </div>
                                                <div v-else class="flex my-auto items-center gap-1 mr-1">
                                                    {{ getFormattedValue(pipeline, 'latency', 'milliseconds').display }}
                                                    <small v-if="allLatestMetrics[pipeline.id]?.inline.latency > 0" class="flex items-center gap-2">
                                                        <small v-if="allLatestMetrics[pipeline.id]?.inline.latency >= 60000">
                                                            <ClockIcon class="h-4 ml-1 w-4 flex-shrink-0 text-[#236C9E]" />
                                                        </small>
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading || itemLoading[pipeline.id]" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <span v-else> {{ getFormattedValue(pipeline, 'recordsLag', 'count').display }}</span>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading || itemLoading[pipeline.id]" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <span v-else> {{ pipeline.topics.length }}</span>
                                        </td>
                                        <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                            <TableActionMenu
                                                :show-edit="userHasAccess([$Permissions.WRITE_PIPELINES], authStore.user)"
                                                :show-logs="
                                                    userHasAccess(
                                                        [$Permissions.READ_SOURCE_METRICS, $Permissions.READ_DESTINATION_METRICS],
                                                        authStore.user
                                                    )
                                                "
                                                :show-delete="userHasAccess([$Permissions.DELETE_PIPELINES], authStore.user)"
                                                :show-stop-source="
                                                    userHasAccess([$Permissions.WRITE_SOURCES], authStore.user) && showStopSource(pipeline)
                                                "
                                                :show-stop-destination="
                                                    userHasAccess([$Permissions.WRITE_DESTINATIONS], authStore.user) && showStopDestination(pipeline)
                                                "
                                                :show-resume-source="
                                                    userHasAccess([$Permissions.WRITE_SOURCES], authStore.user) && showResumeSource(pipeline)
                                                "
                                                :show-resume-destination="
                                                    userHasAccess([$Permissions.WRITE_DESTINATIONS], authStore.user) &&
                                                    showResumeDestination(pipeline)
                                                "
                                                :display-above="searchedPipelines.length && pipelineIndex >= searchedPipelines.length - 2"
                                                :position-relative="!generalLoading"
                                                :loading="actionLoading[pipeline.id]"
                                                @stopDestination="onStopDestination(pipeline)"
                                                @resumeDestination="onResumeDestination(pipeline)"
                                                @stopSource="onStopSource(pipeline)"
                                                @resumeSource="onResumeSource(pipeline)"
                                                @restart="onRestart(pipeline.id)"
                                                @edit="onPipelineSelected(pipeline)"
                                                @view="onPipelineViewed(pipeline.id)"
                                                @delete="onDelete('pipeline', pipeline.name, pipeline.id)"
                                                @logs="onViewLogs(pipeline)" />
                                        </td>
                                    </tr>
                                    <tr v-if="!searchedPipelines.length">
                                        <td colspan="99" class="text-center p-2">
                                            <small>No matching records found</small>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </AppLoader>

                <Pagination
                    v-if="pipelinesStore.pipelines?.length || page > 0"
                    class="mb-5"
                    :page="page"
                    :page-size="pageSize"
                    :current-page-items-count="pipelinesStore.pipelines?.length"
                    :total-items-count="pipelinesStore.total"
                    :disable-navigation="generalLoading"
                    :message="{
                        show: searchedPipelines.length === pipelinesStore.pipelines?.length && !generalLoading,
                        showCustomMessage: searchedPipelines.length !== pipelinesStore.pipelines?.length && !generalLoading,
                        firstPart: 'Showing pipelines',
                        middlePart: 'to',
                        lastPart: `of ${pipelinesStore.total}`
                    }"
                    @page-change="onPageChange">
                    <template #customMessage>
                        Showing
                        <span class="font-medium">{{ searchedPipelines.length }}</span>
                        {{ searchedPipelines.length === 1 ? 'match' : 'matches' }}
                        on this page
                    </template>
                </Pagination>
            </section>
        </div>
    </div>
</template>

<style scoped>
/* When the container is expanded */
.my-auto.whitespace-normal {
    min-height: unset; /* Remove the minimum height so it can expand */
    max-height: none; /* Ensure it's not constrained by max-height */
}
</style>
