import PipelinesPage from '@/views/pipelines/PipelinesPage.vue';
import CreatePipelinePage from '@/views/pipelines/CreatePipelinePage.vue';
import PipelineDetails from '@/views/pipelines/PipelineDetailsPage.vue';
import WebSocketService from '../services/webSocket.service';
import { useMetricsStore } from '../stores/metrics.store';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/pipelines',
        name: 'pipelines',
        component: PipelinesPage,
        meta: {
            title: 'Pipelines',
            requireAuth: true,
            onEnter: () => {
                const metricsStore = useMetricsStore();
                WebSocketService.addListener('metrics', 'pipelines', null, metricsStore.onMetricsData, 'metrics.onMetricsData');
            },
            accessRules: {
                permissions: {
                    values: [Permissions.READ_PIPELINES, Permissions.READ_PIPELINES_METRICS], // write pipeline is not required to see the page and will hide the action buttons if needed
                    type: 'and'
                }
            }
        }
    },
    {
        path: '/pipelines/new',
        name: 'new-pipeline',
        component: CreatePipelinePage,
        meta: {
            title: 'Create Pipeline',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.READ_DESTINATIONS, Permissions.READ_SOURCES, Permissions.WRITE_PIPELINES],
                    type: 'and'
                }
            }
        }
    },
    {
        path: '/pipelines/:id',
        name: 'pipeline-details',
        props: (route) => ({
            id: route.params.id
        }),
        component: PipelineDetails,
        meta: {
            title: 'Pipeline Details',
            requireAuth: true,
            onEnter: (to) => {
                const metricsStore = useMetricsStore();
                WebSocketService.addListener('metrics', 'pipelines', to.params.id, metricsStore.onMetricsData, 'metrics.onMetricsData');
            },
            accessRules: {
                permissions: {
                    values: [Permissions.READ_PIPELINES, Permissions.READ_PIPELINES_METRICS, Permissions.READ_TRANSFORMS], // write is not required to see the page and will hide the action buttons if needed
                    type: 'and'
                }
            }
        }
    }
];
