<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import AppLoader from '../../components/AppLoader.vue';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '../../components/AppFooter.vue';
import { useSourcesStore } from '../../stores/sources.store';
import { useDestinationsStore } from '../../stores/destinations.store';
import { usePipelinesStore } from '../../stores/pipelines.store';
import { useTransformsStore } from '../../stores/transforms.store';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import TreeView from '../../components/TreeView.vue';
import ErrorMessage from '../../components/ErrorMessage.vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import _ from 'lodash';
import { PlusCircleIcon } from '@heroicons/vue/20/solid';
import { buildTreeNodesOutOfExistingConfig } from '../../utils/utils';
import { getConnectorIcon, getConnectorDisplayName } from '@/utils/constants';
import RowActionMenu from '@/components/RowActionMenu.vue';
import SearchInput from '@/components/global/SearchInput.vue';
import HalfCircleSpinner from '@/components/global/loader/HalfCircleSpinner.vue';

let router = useRouter();
const sourcesStore = useSourcesStore();
const destinationsStore = useDestinationsStore();
const pipelinesStore = usePipelinesStore();
const transformsStore = useTransformsStore();

let sources = ref(_.cloneDeep(sourcesStore.allBrief));
let destinations = ref(_.cloneDeep(destinationsStore.allBrief));

let formError = ref('');

let currentStep = ref(1);
let pipelineName = ref(null);
let selectedSource = ref(null);
let selectedDestination = ref(null);
let selectedSourceTreeNodes = ref([]);
let selectedSourceTopics = ref([]);

let sourceSearchQuery = ref('');
let destinationSearchQuery = ref('');

let saveLoading = ref(false);
const sourcesLoading = ref(false);
const destinationsLoading = ref(false);
const schemaLoading = ref(false);
const transformsLoading = ref(false);

let sourceConfig = ref();
let treeLevels = ref([]);
let treeNodes = ref([]);
let searcheableTreeNodes = ref([]);
let correctTreeSelection = ref(true);
let hidePromptTemporary = ref(false);

let transformTreeNodes = ref([]);
let transformTreeLevels = ref([]);

let snapshotNewTables = ref(true);

const nextEnabled = computed(() => {
    return (
        (currentStep.value == 1 && selectedSource.value && selectedDestination.value) ||
        (currentStep.value == 2 && (selectedSourceTreeNodes.value.length || getSelectedTransformTreeNodes().length > 0) && correctTreeSelection.value)
    );
});

const canSave = computed(() => {
    return (
        pipelineName.value && (selectedSourceTreeNodes.value.length > 0 || getSelectedTransformTreeNodes().length > 0) && correctTreeSelection.value
    );
});

onBeforeRouteLeave((to, from, next) => {
    if (sourcesLoading.value || destinationsLoading.value || transformsLoading.value) {
        return next();
    }

    if (currentStep.value === 1 || hidePromptTemporary.value) {
        return next();
    }

    const answer = window.confirm('Are you sure you want to leave? You may have unsaved changes.');

    if (answer) {
        return next();
    } else {
        return next(false);
    }
});

onMounted(async () => {
    try {
        sourcesLoading.value = true;
        destinationsLoading.value = true;
        transformsLoading.value = true;

        await Promise.allSettled([
            sourcesStore.fetchAllBrief(false).then(() => {
                sources.value = _.cloneDeep(sourcesStore.allBrief);
                sourcesLoading.value = false;
            }),
            destinationsStore.fetchAllBrief(false).then(() => {
                destinations.value = _.cloneDeep(destinationsStore.allBrief);
                destinationsLoading.value = false;
            }),
            transformsStore.fetchForConnectors().then(() => {
                if (transformsStore.allForConnectors && !_.isEmpty(transformsStore.allForConnectors)) {
                    constructTransformTreeNodes();
                }

                transformsLoading.value = false;
            })
        ]);
    } finally {
        sourcesLoading.value = false;
        destinationsLoading.value = false;
        transformsLoading.value = false;
    }
});

const prevStep = () => {
    currentStep.value--;
};

const nextStep = () => {
    currentStep.value++;
};

const createPipeline = () => {
    saveLoading.value = true;

    processTreeNodesTopics(selectedSourceTreeNodes.value);

    pipelinesStore
        .create({
            name: pipelineName.value,
            snapshot_new_tables: !!snapshotNewTables.value,
            destination: {
                connector: selectedDestination.value.connector,
                name: selectedDestination.value.name,
                id: {
                    $oid: selectedDestination.value.id
                }
            },
            source: {
                connector: selectedSource.value.connector,
                name: selectedSource.value.name,
                topics: selectedSourceTopics.value,
                id: {
                    $oid: selectedSource.value.id
                }
            },
            transforms: getSelectedTransformTreeNodes()
        })
        .then(() => {
            // pipelinesStore.pipelines = [];
            // sourcesStore.sources = [];
            // destinationsStore.destinations = [];
            // metrics.pipelines.inlinePipelinesMetricsList = [];
            hidePromptTemporary.value = true;
            router.push({ name: 'pipelines' });
        })
        .catch((err) => {
            console.error(err);
            formError.value = err;
        })
        .finally(() => {
            saveLoading.value = false;
            hidePromptTemporary.value = false;
        });
};

const processTreeNodesTopics = (nodes, prefix = '') => {
    nodes.forEach((obj) => {
        const fullName = prefix ? `${prefix}.${obj.name}` : obj.name;

        if (!obj.children || obj.children?.length === 0) {
            if (obj.schemaLevel == treeLevels.value[treeLevels.value.length - 1].schemaLevel && obj.isSelected) {
                selectedSourceTopics.value.push(fullName);
            }
        } else {
            processTreeNodesTopics(obj.children, fullName);
        }
    });
};

const updateCorrectTreeSelectionState = (newValue) => {
    correctTreeSelection.value = newValue;
};

const updateSelectedSource = async (newSource) => {
    selectedSource.value =
        selectedSource.value && selectedSource.value.id === newSource.id
            ? null
            : sourcesStore.sources?.find((x) => x.id == newSource.id) || newSource;

    if (!selectedSource.value) {
        selectedDestination.value = null;
    }
};

const constructTransformTreeNodes = () => {
    transformTreeLevels.value.push({ schemaLevel: 'schema', schemaObject: 'random' }, { schemaLevel: 'topic', schemaObject: 'random' });
    transformTreeNodes.value = [];
    const children = [];

    _.forEach(transformsStore.allForConnectors, (transform) => {
        children.push({
            name: transform.topic,
            isSelected: false,
            schemaLevel: 'topic',
            schemaObject: transform
        });
    });

    transformTreeNodes.value.push({
        name: 'transforms',
        isSelected: false,
        collapsed: true,
        schemaLevel: 'schema',
        schemaObject: 'random',
        children
    });
};

const getSelectedTransformTreeNodes = () => {
    if (transformTreeNodes.value.length === 0) {
        return [];
    }
    return transformTreeNodes.value[0].children.filter((topicNode) => topicNode.isSelected).map((topicNode) => topicNode.schemaObject);
};

const updateSelectedDestination = (newDestination) => {
    selectedDestination.value =
        selectedDestination.value && selectedDestination.value.id === newDestination.id
            ? null
            : destinationsStore.destinations?.find((x) => x.id == newDestination.id) || newDestination;
};

const checkAllSelectedHaveSelectedChild = (nodes) => {
    for (let obj of nodes) {
        if (obj.isSelected && obj.children && obj.children?.length > 0) {
            if (!obj.children?.some((child) => child.isSelected)) {
                return false;
            }
        }

        if (obj.isSelected && (!obj.children || !obj.children?.length)) {
            return true;
        }

        if (obj.children) {
            const childResult = checkAllSelectedHaveSelectedChild(obj.children);
            if (!childResult) {
                return false;
            }
        }
    }
    return true;
};

const constructTreeNodes = async () => {
    let configProps = selectedSource.value.config;

    if (!configProps) {
        const [source] = await Promise.all([sourcesStore.getById(selectedSource.value.id), sourcesStore.fetchConnectors()]);

        configProps = source.config;
    }

    const sourceConnector = Object.values(sourcesStore.sourceConnectors)
        .flat()
        .find((x) => x.connector == selectedSource.value.connector);

    const nodes = buildTreeNodesOutOfExistingConfig(configProps, sourceConfig.value, sourceConnector, false);

    treeLevels.value = nodes.treeLevels;
    treeNodes.value = nodes.treeNodes;

    return { nodes: treeNodes.value, levels: treeLevels.value };
};

watch(
    treeNodes,
    (newVal) => {
        selectedSourceTreeNodes.value = newVal.filter((x) => x.isSelected);
        correctTreeSelection.value = checkAllSelectedHaveSelectedChild(selectedSourceTreeNodes.value);
    },
    { deep: true }
);

watch(selectedDestination, (newVal) => {
    formError.value = null;
    pipelineName.value = `${selectedSource.value?.name} __ ${newVal?.name} __ pipeline`;
});

watch(
    selectedSource,
    async (newVal) => {
        if (newVal) {
            schemaLoading.value = true;
            formError.value = null;

            try {
                await Promise.allSettled([
                    sourcesStore.fetchConfiguration(selectedSource.value.connector).then(async () => {
                        sourceConfig.value = sourcesStore.configurations[selectedSource.value.connector];

                        if (sourceConfig.value) {
                            await constructTreeNodes();

                            searcheableTreeNodes.value = Object.assign([], treeNodes.value);
                        }

                        schemaLoading.value = false;
                    })
                ]);
            } finally {
                schemaLoading.value = false;
            }
        }
    },
    { deep: true }
);

watch([destinationSearchQuery, sourceSearchQuery], ([destinationQuery, sourceQuery]) => {
    if (sourceQuery) {
        sources.value = _.cloneDeep(sourcesStore.allBrief.filter((x) => x.name.toLowerCase().includes(sourceQuery.toLowerCase())));
    } else {
        sources.value = _.cloneDeep(sourcesStore.allBrief);
    }

    if (destinationQuery) {
        destinations.value = _.cloneDeep(destinationsStore.allBrief.filter((x) => x.name.toLowerCase().includes(destinationQuery.toLowerCase())));
    } else {
        destinations.value = _.cloneDeep(destinationsStore.allBrief);
    }
});

// watch(
//     searchQuery,
//     (newVal) => {
//         if (newVal.length >= 3) {
//             searcheableTreeNodes.value = Object.assign(
//                 [],
//                 treeNodes?.value.filter((x) => x.name.toLowerCase().includes(newVal.toLocaleLowerCase()))
//             );
//         }

//         if (!newVal) {
//             loadTreeData();
//         }
//     },
//     { deep: true }
// );

// const loadTreeData = () => {
//     treeNodes.value = [];
//     selectedSource.value.topics.forEach((topic) => {
//         treeNodes.value.push({
//             name: topic,
//             isSelected: true,
//             schemaLevel: 'topic',
//             schemaObject: 'random',
//             children: []
//         });
//     });

//     searcheableTreeNodes.value = Object.assign([], treeNodes.value);
// };
</script>

<template>
    <div class="min-h-screen">
        <div class="page-header">
            <AppHeader />
        </div>

        <div v-if="sourcesLoading || destinationsLoading || (sourcesStore.allBrief?.length && destinationsStore.allBrief?.length)">
            <div class="flex w-full" style="min-height: 85vh">
                <div class="w-full flex-col flex items-center justify-center">
                    <div v-if="currentStep == 1" class="w-full flex flex-col md:flex-row gap-5">
                        <div class="w-full lg:w-1/2 flex flex-col" :default-height="'70vh'">
                            <div class="flex flex-col gap-3 items-center mb-3">
                                <div
                                    class="font-semibold text-lg text-center pr-3"
                                    :class="{
                                        'text-cyan-950': !selectedSource
                                    }">
                                    Select a Source
                                </div>
                                <SearchInput
                                    :loading="sourcesLoading || saveLoading || !sources?.length"
                                    :custom-class="'w-2/3'"
                                    :placeholder="'Search sources...'"
                                    @search="(input) => (sourceSearchQuery = input)" />
                            </div>

                            <div class="group flex flex-col align-start gap-3 overflow-y-auto pb-3 pr-3" style="max-height: 82vh">
                                <AppLoader
                                    :force-loading="sourcesLoading"
                                    :default-height="'60vh'"
                                    :default-width="'35vw'"
                                    :default-class="'border rounded-lg'">
                                    <div
                                        class="bg-white relative rounded-lg p-4 border cursor-pointer shadow-wrapper gap-3 group-item transition-all duration-300 ease-in-out border-l-8"
                                        @click="router.push({ name: 'add-connectors', query: { tab: 'Sources' } })">
                                        <div class="flex justify-between items-center">
                                            <div class="flex items-center gap-2 ml-4 font-medium">
                                                <PlusCircleIcon class="h-9 w-9 text-green-600" />
                                                Add a new source
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div
                                    class="bg-white relative rounded-lg p-4 border cursor-pointer shadow-wrapper gap-3 group-item transition-all duration-300 ease-in-out border-l-8">
                                    <div class="flex justify-between items-center">
                                        <div class="flex items-center gap-2 ml-4 font-medium">
                                            <AdjustmentsVerticalIcon class="h-9 w-9 text-gray-600" />
                                            Use Transforms
                                        </div>
                                    </div>
                                </div> -->

                                    <div v-for="(source, index) in sources" :key="index" class="relative">
                                        <div
                                            class="relative rounded-lg p-4 border cursor-pointer shadow-wrapper gap-3 group-item transition-all duration-300 ease-in-out"
                                            :class="{
                                                'bg-white': selectedSource?.id !== source.id,
                                                'bg-green-50': selectedSource && selectedSource.id === source.id
                                            }"
                                            @click="updateSelectedSource(source)">
                                            <div class="flex justify-between items-center">
                                                <div class="flex items-center gap-2 ml-4 font-medium">
                                                    <img :src="getConnectorIcon(source.connector)" alt="" class="w-9 h-9" />
                                                    {{ source.name }}
                                                </div>
                                                <div
                                                    class="flex flex-row items-center justify-center h-full flex-wrap rounded py-0.5 text-lg font-medium">
                                                    <span
                                                        class="inline-flex items-center rounded-full px-3 py-1 text-xs bg-green-200 text-gray-800 shadow font-medium ml-3"
                                                        title="Connectors"
                                                        >{{ getConnectorDisplayName(source.connector) }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!sources?.length" class="text-center">There are no sources</div>
                                </AppLoader>
                            </div>
                        </div>

                        <div class="w-full lg:w-1/2 flex flex-col" :default-height="'70vh'">
                            <div class="flex flex-col gap-3 items-center mb-3">
                                <div
                                    class="font-semibold text-lg text-center pr-3"
                                    :class="{
                                        'text-cyan-950': selectedSource
                                    }">
                                    Select a Destination
                                </div>
                                <SearchInput
                                    :loading="destinationsLoading || saveLoading || !destinations?.length"
                                    :custom-class="'w-2/3'"
                                    :placeholder="'Search destinations...'"
                                    @search="(input) => (destinationSearchQuery = input)" />
                            </div>
                            <div class="group flex flex-col align-start gap-3 overflow-y-auto pb-3 pr-3" style="max-height: 82vh">
                                <AppLoader
                                    :force-loading="destinationsLoading"
                                    :default-height="'60vh'"
                                    :default-width="'35vw'"
                                    :default-class="'border rounded-lg'">
                                    <div
                                        class="relative rounded-lg p-4 border cursor-pointer shadow-wrapper gap-3 group-item transition-all duration-300 ease-in-out border-l-8"
                                        :class="{
                                            'bg-white': selectedSource,
                                            'bg-gray-100': !selectedSource
                                        }"
                                        @click="router.push({ name: 'add-connectors', query: { tab: 'Destinations' } })">
                                        <div class="flex justify-between items-center">
                                            <div class="flex items-center gap-2 ml-4 font-medium">
                                                <PlusCircleIcon class="h-9 w-9 text-green-600" />
                                                Add a new destination
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-for="(destination, index) in destinations"
                                        :key="index"
                                        class="relative"
                                        :aria-disabled="!selectedSource"
                                        :disabled="!selectedSource">
                                        <div
                                            class="relative rounded-lg p-4 border cursor-pointer shadow-wrapper gap-3 group-item transition-all duration-300 ease-in-out"
                                            :class="{
                                                'bg-white': selectedSource && selectedDestination?.id !== destination.id,
                                                'bg-green-50': selectedDestination && selectedDestination.id === destination.id,
                                                'bg-gray-100 pointer-events-none': !selectedSource
                                            }"
                                            @click="updateSelectedDestination(destination)">
                                            <div class="flex justify-between items-center">
                                                <div class="flex items-center gap-2 ml-4 font-medium">
                                                    <img :src="getConnectorIcon(destination.connector)" alt="" class="w-9 h-9" />
                                                    {{ destination.name }}
                                                </div>
                                                <div
                                                    class="flex flex-row items-center justify-center h-full flex-wrap rounded py-0.5 text-lg font-medium ml-3">
                                                    <span
                                                        class="inline-flex items-center rounded-full px-3 py-1 text-xs bg-green-200 text-gray-800 shadow font-medium"
                                                        title="Connectors"
                                                        >{{ getConnectorDisplayName(destination.connector) }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!destinations?.length" class="text-center">There are no destinations</div>
                                </AppLoader>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentStep == 2" class="w-2/3 flex flex-col justify-center items-center pr-3">
                        <div class="flex flex-row items-center relative gap-3 mb-2">
                            <label for="name" class="block font-semibold text-lg text-center text-gray-800"> Snapshot topics </label>
                            <div class="flex h-5 items-center">
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input
                                        v-model="snapshotNewTables"
                                        aria-label="input"
                                        class="mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                        type="checkbox"
                                        role="switch"
                                        :class="{
                                            'disabled-toggle': transformsLoading || schemaLoading,
                                            'bg-green-400': snapshotNewTables,
                                            'bg-neutral-300': !snapshotNewTables
                                        }"
                                        :true-value="true"
                                        :false-value="false"
                                        :disabled="transformsLoading || schemaLoading"
                                        aria-describedby="toggle" />
                                </label>
                            </div>
                            <HalfCircleSpinner :loading="transformsLoading || schemaLoading" />
                        </div>

                        <label for="name" class="block font-semibold text-lg text-center text-gray-800 mt-10">
                            {{ selectedSource.name }} - Schema Selection
                        </label>
                        <div class="mb-4 bg-white p-5 rounded-lg w-full shadow-md">
                            <AppLoader :force-loading="schemaLoading" :default-height="'30vh'">
                                <TreeView
                                    v-if="searcheableTreeNodes && treeLevels && treeLevels[0] != undefined && treeLevels[0].schemaLevel != undefined"
                                    :tree-nodes="searcheableTreeNodes"
                                    :tree-levels="treeLevels"
                                    :disable-create-and-edit="true"
                                    :edit-mode="true"
                                    @can-save-duplicate="updateCorrectTreeSelectionState" />
                            </AppLoader>
                        </div>

                        <label for="name" class="block font-semibold text-lg text-center text-gray-800 mb-2 mt-10"> Transforms Selection </label>
                        <div class="mb-4 bg-white p-5 rounded-lg w-full shadow-md">
                            <AppLoader :force-loading="transformsLoading" :default-height="'30vh'">
                                <div v-if="transformTreeNodes && transformTreeLevels && transformTreeLevels[0] != undefined">
                                    <TreeView
                                        class="w-100"
                                        :tree-nodes="transformTreeNodes"
                                        :tree-levels="transformTreeLevels"
                                        :disable-create-and-edit="true"
                                        :edit-mode="true" />
                                </div>
                                <div v-else>No transforms found</div>
                            </AppLoader>
                        </div>
                    </div>
                    <div v-if="currentStep == 3" class="p-3 mr-3 flex flex-col w-3/4 items-center">
                        <label for="name" class="block font-semibold text-lg text-center text-gray-800 mb-2">4. Pipeline name</label>
                        <input
                            id="name"
                            v-model="pipelineName"
                            aria-label="input"
                            name="name"
                            placeholder="Name of your pipeline"
                            type="text"
                            autocomplete="new-password"
                            required=""
                            :class="{
                                'border border-red-500': !pipelineName
                            }"
                            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 text-md shadow-sm h-14" />
                    </div>
                    <div v-if="formError" class="flex justify-center pt-5">
                        <ErrorMessage
                            :error="formError"
                            :to-sentry="true"
                            :context="'create-pipeline'"
                            :introduction="'An error occurred while creating your pipeline.'" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-[100px]">
            <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" />
                </div>
                <div class="ml-3">
                    <p class="text-xs text-yellow-700">
                        Connectors are <span class="underline">required</span> before creating a new Pipeline.
                        {{ ' ' }}
                    </p>
                    <p class="text-xs text-yellow-700">Create at least 1 Source and 1 Destination.</p>
                </div>
            </div>
        </div>
        <AppFooter>
            <template #right>
                <RowActionMenu
                    :show-save="true"
                    :show-next="true"
                    :show-back="true"
                    :show-discard="false"
                    :disable-back="currentStep <= 1"
                    :disable-next="!nextEnabled"
                    :disable-save="
                        !canSave ||
                        saveLoading ||
                        sourcesLoading ||
                        destinationsLoading ||
                        !(sourcesStore.allBrief?.length && destinationsStore.allBrief?.length)
                    "
                    :loading="saveLoading"
                    @next="nextStep"
                    @back="prevStep"
                    @save="createPipeline" />
            </template>
        </AppFooter>
    </div>
</template>

<style>
.step-container {
    display: flex;
    flex-direction: column;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
