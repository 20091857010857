<script>
import LoadingButton from '../../global/buttons/loading-btn.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import * as notificationsApi from '../../../api/notifications.api';
import { isValidEmail as isValidEmailUtil } from '../../../utils/validators';

export default {
    components: {
        LoadingButton,
        CheckCircleIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        isConnected: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null
        },
        setInput: {
            type: String,
            default: null
        },
        subscriberId: {
            type: String,
            default: null
        },
        providerId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showInput: false,
            loading: false,
            inputValue: ''
        };
    },
    methods: {
        isValidEmail(input) {
            return isValidEmailUtil(input);
        },
        async toggleConnection() {
            this.loading = true;

            try {
                let connected = !this.isConnected;

                if (connected) {
                    this.showInput = true;
                }

                if (!connected) {
                    await notificationsApi.updateSubscriber(this.subscriberId, null);
                    this.$emit('update:setInput', null);
                    this.inputValue = '';
                    this.showInput = false;
                    this.$emit('update:isConnected', connected);
                }
            } catch (error) {
                console.error('Error toggling connection:', error);
            } finally {
                this.loading = false;
            }
        },
        async saveInput() {
            this.loading = true;
            try {
                await notificationsApi.updateSubscriber(this.subscriberId, this.inputValue);
                this.$emit('update:setInput', this.inputValue);
                this.$emit('update:isConnected', true);
                this.showInput = false;
            } catch (error) {
                console.error('Failed to update subscriber:', error);
            } finally {
                this.loading = false;
            }
        },
        cancelInput() {
            this.inputValue = '';
            this.$emit('update:isConnected', false);
            this.showInput = false;
        }
    }
};
</script>

<template>
    <div class="integration-item flex items-center justify-between p-4 border-b bg-white shadow">
        <div class="flex items-start">
            <img :src="logo" :alt="title + ' logo'" class="w-10 h-10 mr-4" />
            <div>
                <div class="flex items-center">
                    <span class="text-md font-medium">{{ title }}</span>
                    <CheckCircleIcon v-if="isConnected" class="h-5 w-5 text-green-500 ml-1" />
                </div>
                <div v-if="setInput && !showInput" class="text-xs text-gray-600 italic">
                    {{ setInput }}
                </div>
                <div v-if="!setInput" class="text-xs text-gray-600 italic">Address</div>
            </div>
        </div>
        <div v-if="showInput" class="flex items-center space-x-2 w-4/5">
            <input
                aria-label="input"
                v-model="inputValue"
                type="text"
                class="border px-4 py-2 rounded flex-grow"
                :class="{ 'border-red-500': !isValidEmail(inputValue) }"
                :placeholder="placeholder || 'Enter value'"
                :disabled="loading" />
            <LoadingButton
                custom-css="px-4 py-2 w-24 rounded-full bg-green-400 hover:bg-green-500"
                :is-loading="loading"
                :can-save="isValidEmail(inputValue)"
                @click="saveInput">
                Save
            </LoadingButton>
            <LoadingButton
                v-if="!loading"
                custom-css="px-4 py-2 w-24 rounded-full bg-red-500 hover:bg-red-600"
                :is-loading="false"
                :can-save="true"
                @click="cancelInput">
                Cancel
            </LoadingButton>
        </div>
        <div v-if="!showInput">
            <LoadingButton
                :custom-css="[
                    'px-6 py-2 w-28 rounded-full font-medium transition',
                    {
                        'bg-gray-500 hover:bg-gray-600': isConnected,
                        'bg-green-400 hover:bg-green-500': !isConnected
                    }
                ]"
                :is-loading="loading"
                :can-save="true"
                @click="toggleConnection">
                {{ isConnected ? 'Disconnect' : 'Connect' }}
            </LoadingButton>
        </div>
    </div>
</template>
