/* eslint-disable object-curly-newline */
import api from '../services/axios.service';
import { constructQueryParams } from '../utils/utils';

/**
 * Fetches metrics for a specific source.
 * Parameters like timestamp and time unit can be optionally provided to tailor the query.
 * The response includes 'latest' and 'timeseries' metrics.
 * @param {string} id - The ID of the source.
 * @param {Object} options - Object containing optional parameters:
 *   topicsFrom (number): Starting index for topic list retrieval.
 *   topicsSize (number): Number of topics to retrieve.
 *   from (number): Starting index for general data retrieval.
 *   size (number): Number of items to retrieve.
 *   level (string): Reporting level of the metrics ('inline', 'connector', 'topic', 'task', 'account').
 *   page (string): the page for which to send metrics ('source', 'destination', 'pipeline', 'usage')
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} The metrics data from the API.
 */

export async function fetchMetricsForSource(
    id = null,
    {
        topic_list_from = 0,
        topic_list_size = 5,
        from = 0,
        size = 1,
        level = null,
        page = null,
        timestamp_to = null,
        timestamp_from = null,
        time_unit = null,
        time_interval = null,
        time_type = null,
        tenant_ids = []
    } = {}
) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({
        level,
        id,
        topic_list_from,
        topic_list_size,
        from,
        size,
        page,
        timestamp_to,
        timestamp_from,
        time_unit,
        time_interval,
        time_type,
        tenant_ids
    });
    const url = `source_metrics?${queryParams}`;

    return api.get(url);
}

/**
 * Fetches metrics for a specific destination.
 * Allows for specifying time range and intervals for more detailed analysis.
 * The metrics are categorized into 'latest' and 'timeseries' and 'timesummary' data.
 * @param {string} id - The ID of the destination.
 * @param {Object} options - Object containing optional parameters:
 *   topicsFrom (number): Starting index for topic list retrieval.
 *   topicsSize (number): Number of topics to retrieve.
 *   from (number): Starting index for general data retrieval.
 *  level (string): Reporting level of the metrics ('inline', 'connector', 'topic', 'task', 'account').
 *   size (number): Number of items to retrieve.
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   page (string): the page for which to send metrics ('source', 'destination', 'pipeline', 'usage')
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} The metrics data from the API.
 */
export async function fetchMetricsForDestination(
    id,
    {
        topic_list_from = 0,
        topic_list_size = 5,
        from = 0,
        size = 1,
        timestamp_to = null,
        timestamp_from = null,
        level = null,
        page = null,
        time_unit = null,
        time_interval = null,
        time_type = null,
        tenant_ids = []
    } = {}
) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({
        id,
        topic_list_from,
        topic_list_size,
        from,
        size,
        level,
        page,
        timestamp_to,
        timestamp_from,
        time_unit,
        time_interval,
        time_type,
        tenant_ids
    });
    const url = `destination_metrics?${queryParams}`;

    return api.get(url);
}

/**
 * Retrieves metrics specific to a pipeline.
 * Supports time-based queries for detailed insights.
 * Metrics include both 'latest' and 'timeseries' data.
 * @param {string} id - The pipeline ID.
 * @param {Object} options - Object containing optional parameters:
 *   topicsFrom (number): Starting index for topic list retrieval.
 *   topicsSize (number): Number of topics to retrieve.
 *   from (number): Starting index for general data retrieval.
 *  level (string): Reporting level of the metrics ('inline', 'connector', 'topic', 'task', 'account').
 *   page (string): the page for which to send metrics ('source', 'destination', 'pipeline', 'usage')
 *   size (number): Number of items to retrieve.
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} The metrics data from the API.
 */
export async function fetchMetricsForPipeline(
    id,
    {
        from = 0,
        size = 1,
        timestamp_to = null,
        timestamp_from = null,
        time_unit = null,
        page = null,
        time_interval = null,
        time_type = null,
        level = null
    } = {}
) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({ id, from, size, timestamp_to, timestamp_from, time_unit, page, time_interval, time_type, level });
    const url = `pipeline_metrics?${queryParams}`;

    return api.get(url);
}

/**
 * Fetches metrics for a list of sources.
 * Capable of returning time series data for topics, including 'latest' and 'timeseries' metrics.
 * @param {Array<string>} ids - An array of source IDs.
 * @param {Object} options - Object containing optional parameters:
 *   level (string): Reporting level of the metrics ('inline', 'connector', 'topic', 'task').
 *   topicsFrom (number): Starting index for topic list retrieval.
 *   topicsSize (number): Number of topics to retrieve.
 *   from (number): Starting index for general data retrieval.
 *   size (number): Number of items to retrieve.
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} The metrics data from the API.
 */
export async function fetchMetricsForListOfSources(
    ids = [],
    { level = 'inline', timestamp_to = null, timestamp_from = null, time_unit = null, time_interval = null, time_type = null } = {}
) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({ level, ids: ids.join(','), timestamp_to, timestamp_from, time_unit, time_interval, time_type });
    const url = `source_metrics?${queryParams}`;

    const response = await api.get(url);
    return level ? response?.[`${level}_metrics`] : response;
}

/**
 * Retrieves metrics for a list of destinations.
 * Offers detailed metrics analysis with time series data.
 * The metrics are structured into 'latest' and 'timeseries' formats.
 * @param {Array<string>} ids - An array of destination IDs.
 * @param {Object} options - Object containing optional parameters:
 *   level (string): Reporting level of the metrics ('inline', 'connector', 'topic', 'task').
 *   topicsFrom (number): Starting index for topic list retrieval.
 *   topicsSize (number): Number of topics to retrieve.
 *   from (number): Starting index for general data retrieval.
 *   size (number): Number of items to retrieve.
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} Metrics data for the specified destinations.
 */
export async function fetchMetricsForListOfDestinations(
    ids = [],
    { level = 'inline', timestamp_to = null, timestamp_from = null, time_unit = null, time_interval = null, time_type = null } = {}
) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({ level, ids: ids.join(','), timestamp_to, timestamp_from, time_unit, time_interval, time_type });
    const url = `destination_metrics?${queryParams}`;

    const response = await api.get(url);
    return level ? response?.[`${level}_metrics`] : response;
}

/**
 * Provides metrics for a collection of pipelines.
 * Supports timestamp-based queries for 'latest' and 'timeseries' metrics.
 * @param {Array<string>} ids - An array of pipeline IDs.
 * @param {Object} options - Object containing optional parameters:
 *   level (string): Reporting level of the metrics ('inline', 'connector', 'topic', 'task').
 *   topicsFrom (number): Starting index for topic list retrieval.
 *   topicsSize (number): Number of topics to retrieve.
 *   from (number): Starting index for general data retrieval.
 *   size (number): Number of items to retrieve.
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} Aggregated metrics data for the given pipelines.
 */
export async function fetchMetricsForListOfPipelines(
    ids = [],
    { level = 'inline', timestamp_to = null, timestamp_from = null, time_unit = null, time_interval = null, time_type = null } = {}
) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({ level, ids: ids.join(','), timestamp_to, timestamp_from, time_unit, time_interval, time_type });
    const url = `pipeline_metrics?${queryParams}`;

    const response = await api.get(url);

    return level ? response?.[`${level}_metrics`] : response;
}

/**
 *
 * @returns {Promise<Object>} Used to return the counts of entities from the organisation
 */
export async function fetchOrgStatistics() {
    return api.get('organisation_statistics');
}

/**
 * Fetches the usage metrics for the organisation.
 * Allows for pagination and sorting of the results.
 * @param {Object} options - Object containing optional parameters:
 * page_size (number): Number of items to retrieve.
 * sort (string): The field to sort the results by.
 * page (number): The page number for the results.
 * partial_name (string): The partial name to filter the results by.
 * @returns {Promise<Object>} The usage metrics data from the API.
 */
export async function fetchOrganisationUsageMetrics({ page_size, sort, page, partial_name, tenant_ids }) {
    const queryParams = constructQueryParams({ sort, page, page_size, partial_name, tenant_ids });

    return api.get(`usage_metrics?${queryParams}`);
}

/**
 * Fetches the usage summary for the organisation.
 * @returns {Promise<Object>} The usage summary data from the API.
 */
export async function fetchUsageSummary(tenantIds = []) {
    return api.get(tenantIds?.length ? `usage_summary?tenant_ids=${tenantIds.join(',')}` : `usage_summary`);
}

/**
 * Fetches the usage summary for the organisation - returns it as a plan CSV text
 * @returns {Promise<Object>} The usage summary data from the API.
 */
export async function exportUsage(tenantIds = []) {
    return api.get(tenantIds?.length ? `usage_export?tenant_ids=${tenantIds.join(',')}` : `usage_export`);
}

/**
 * Fetches the global configs of the common metrics on sources
 * @param {string} mapping_type - The type of mapping to be used. Can be metrics or config. If nothing is sent, both are returned.
 * @returns {Promise<Object>} The usage metrics data from the API.
 */
export async function fetchGlobalSourceConfigs({ mapping_type } = {}) {
    const queryParams = constructQueryParams({ mapping_type });

    return api.get(`source_configs?${queryParams}`);
}

/**
 * Fetches the global configs of the common metrics on destinations
 * @param {string} mapping_type - The type of mapping to be used. Can be metrics or config. If nothing is sent, both are returned.
 * @returns {Promise<Object>} The usage metrics data from the API.
 */
export async function fetchGlobalDestinationConfigs({ mapping_type } = {}) {
    const queryParams = constructQueryParams({ mapping_type });

    return api.get(`destination_configs?${queryParams}`);
}

/**
 * Fetches metrics for all the topics (topics page).
 * Allows for specifying time range and intervals for more detailed analysis.
 * The metrics are categorized into 'latest' and 'timeseries' and 'timesummary' data.
 *   timestampTo (string): Upper bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 'now'.
 *   timestampFrom (string): Lower bound for the time range, format '%Y-%m-%dT%H:%M:%SZ', defaults to 24 hours ago.
 *   timeUnit (string): Unit of time for the timeseries data ('minute', 'hour', 'day').
 *   timeInterval (number): Numeric value representing the interval between each point in the timeseries.
 * @returns {Promise<Object>} The metrics data from the API.
 */
export async function fetchMetricsForTopics({
    timestamp_to = null,
    timestamp_from = null,
    time_unit = null,
    time_interval = null,
    time_type = null
} = {}) {
    // Error checks
    if (time_unit && !time_interval) throw new Error('Time interval is required when time unit is provided');
    if (time_interval && !time_unit) throw new Error('Time unit is required when time interval is provided');

    // Construct query string
    const queryParams = constructQueryParams({
        timestamp_to,
        timestamp_from,
        time_unit,
        time_interval,
        time_type
    });
    const url = `v2/topics/metrics?${queryParams}`;

    return api.get(url);
}
