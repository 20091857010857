import AlertsPage from '@/views/alerts/AlertsPage.vue';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/alerts',
        name: 'alerts',
        component: AlertsPage,
        meta: {
            title: 'Alerts',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.READ_ALERTS, Permissions.WRITE_ALERTS],
                    type: 'or'
                }
            }
        }
    }
];
