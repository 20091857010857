import { defineStore } from 'pinia';
import { ref } from 'vue';
import _ from 'lodash';

export const useLoaderStore = defineStore({
    id: 'loader',
    state: () => ({
        loading: ref({})
    }),
    getters: {
        isLoading: (state) => {
            return (name) => (!_.isNil(state.loading[name]) ? state.loading[name] > 0 : false);
        }
    },
    actions: {
        activateLoading(name) {
            if (this.loading[name]) {
                this.loading[name]++;
            } else {
                this.loading[name] = 1;
            }

            console.log('activateLoading', name, this.loading[name]);
        },
        deactivateLoading(name) {
            if (this.loading[name]) {
                this.loading[name]--;
            }

            console.log('deactivateLoading', name, this.loading[name]);
        }
    }
});
