<template>
    <div :class="[isModal ? 'w-full' : 'mt-10  w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5']">
        <ul class="topics-list">
            <li v-for="(item, index) in scripts" :key="index" class="mb-4">
                <div class="flex justify-between bg-gray-50 border rounded-md shadow-sm">
                    <div class="text-xs p-2">
                        {{ item.scriptName }}
                    </div>
                    <button
                        v-if="!isCopying"
                        aria-label="button"
                        title="Copy"
                        class="copy-button p-2"
                        @click="copyToClipboard(formatSql(item.scriptValue))">
                        <ClipboardIcon class="h-4 w-4" />
                    </button>
                    <button v-if="isCopying" aria-label="button" title="Copied!" class="copy-button p-2">
                        <CheckIcon class="h-4 w-4 text-green-600" />
                    </button>
                </div>
                <pre class="script-content border p-3 bg-white"><code v-html="highlightedSql(index)"></code></pre>
            </li>
        </ul>
    </div>
</template>

<script>
import { ClipboardIcon, CheckIcon } from '@heroicons/vue/24/outline';
import * as sqlFormatter from 'sql-formatter';
import Prism from 'prismjs';
import 'prismjs/components/prism-sql.min';
import 'prismjs/themes/prism.css';
import DOMPurify from 'dompurify';
import _ from 'lodash';

export default {
    components: {
        ClipboardIcon,
        CheckIcon
    },
    props: {
        scripts: {
            type: Array,
            default: () => []
        },
        isModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isCopying: false
        };
    },
    methods: {
        sanitizeHtml(html) {
            if (!_.isNaN(Number(html))) {
                return html;
            }

            return DOMPurify.sanitize(html);
        },
        highlightedSql(index) {
            const formattedSql = this.formatSql(this.scripts[index].scriptValue);
            return Prism.highlight(formattedSql, Prism.languages.sql, 'sql');
        },
        formatSql(scriptValue) {
            console.log(scriptValue);
            const languages = ['sql', 'sqlite', 'mysql', 'postgresql'];

            // we do with try and catch a loop to find the correct language
            let formattedSql = '';

            for (let i = 0; i < languages.length; i++) {
                try {
                    formattedSql = sqlFormatter.format(scriptValue, { language: languages[i] });
                    break;
                } catch (e) {
                    console.error(e, languages[i]);
                }
            }

            return formattedSql;
        },
        async copyToClipboard(script) {
            this.isCopying = true;

            try {
                await navigator.clipboard.writeText(script);

                setTimeout(() => {
                    this.isCopying = false;
                }, 1000);
            } catch (err) {
                console.error('Could not copy text: ', err);
            }
        }
    }
};
</script>

<style>
.topics-list {
    list-style: none;
    max-height: 55vh;
    overflow-y: auto;
}

.toggle-button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    text-align: left;
    width: 100%;
    align-items: center;
}

.toggle-button .icon {
    margin-right: 0.5rem;
}

.script-content {
    border-top: 1px solid #ddd;
    overflow-x: auto;
    font-size: 0.7rem;
}

.token.operator {
    color: #9a6e3a;
}

.token.keyword {
    font-weight: bold;
    color: #07a;
}

.copy-button {
    margin-left: auto;
    border: none;
    background: transparent;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #f3f3f3;
}
</style>
"
