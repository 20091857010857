<template>
    <div class="mx-auto w-full mb-5">
        <div class="page-header">
            <AppHeader>
                <template #end>
                    <AccountSwitcher
                        :only-current-tenant="true"
                        :dropdown-position="'bottom'"
                        :on-select="onSelectedTenantChange"
                        :include-all-option="true" />
                </template>
            </AppHeader>
        </div>
        <!-- <dl
                class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow lg:grid-cols-1 lg:divide-x lg:divide-y-0">
                <div class="p-4 px-6">
                    <div class="flex justify-between items-center">
                        <div>
                            <dt class="text-base font-normal text-gray-600">Usage this billing cycle</dt>
                            <dd class="mt-1 flex items-baseline gap-2 md:block lg:flex">
                                <div class="flex items-baseline text-2xl font-medium text-green-600">
                                    $430
                                    <span class="ml-2 text-sm font-medium text-gray-600">( previous month $365.5 )</span>
                                </div>

                                <div
                                    class="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium bg-blue-100 text-blue-800 md:mt-2 lg:mt-0">
                                    <ArrowUpIcon class="mr-0.5 flex-shrink-0 self-center h-4 w-4 text-green-600" />
                                    <span class="sr-only"> Increased by </span>
                                    12%
                                </div>
                            </dd>
                        </div>
                        <button
                            aria-label="button"
                            type="button"
                            class="rounded-md bg-green-400 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400">
                            Billing
                        </button>
                    </div>
                </div>
                <div class="p-4 px-6">
                    <div class="flex justify-between items-center">
                        <div>
                            <dt class="text-base font-normal text-gray-600">Services</dt>
                            <dd class="mt-1 flex items-baseline gap-2 md:block lg:flex">
                                <div class="flex items-baseline text-2xl font-medium text-green-600">
                                    2
                                    <span class="ml-2 text-sm font-medium text-green-500">( 2/3 running )</span>
                                </div>
                            </dd>
                        </div>
                        <button
                            aria-label="button"
                            type="button"
                            class="rounded-md bg-green-400 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400">
                            Manage
                        </button>
                    </div>
                </div>
            </dl> -->
        <div class="mt-5">
            <AppLoader :force-loading="globalSourceConfigsLoading || globalDestinationConfigsLoading" default-height="80vh">
                <div v-if="!globalSourceConfigsLoading && !globalDestinationConfigsLoading">
                    <div>
                        <div class="flex justify-between items-end mb-3">
                            <h3 class="font-semibold">Usage</h3>
                        </div>
                        <dl class="mt-5 grid grid-cols-1 gap-3 lg:grid-cols-4 xl:grid-cols-6">
                            <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt class="truncate text-sm font-medium text-gray-600">1 HOUR</dt>
                                <dd class="mt-1 text-3xl font-medium tracking-tight text-gray-900">
                                    {{
                                        formatter(
                                            metricsStore.organization.usageSummaryData.metrics['1-hours']?.byteTotal,
                                            getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                        ).display
                                    }}
                                </dd>
                            </div>
                            <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt class="truncate text-sm font-medium text-gray-600">1 DAY</dt>
                                <dd v-if="!organisationUsageSummaryLoading" class="mt-1 text-3xl font-medium tracking-tight text-gray-900">
                                    {{
                                        formatter(
                                            metricsStore.organization.usageSummaryData.metrics['1-days']?.byteTotal,
                                            getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                        ).display
                                    }}
                                </dd>
                                <div v-else class="snippet pl-2 pt-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>
                            <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt class="truncate text-sm font-medium text-gray-600">7 DAYS</dt>
                                <dd v-if="!organisationUsageSummaryLoading" class="mt-1 text-3xl font-medium tracking-tight text-gray-900">
                                    {{
                                        formatter(
                                            metricsStore.organization.usageSummaryData.metrics['7-days']?.byteTotal,
                                            getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                        ).display
                                    }}
                                </dd>
                                <div v-else class="snippet pl-2 pt-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>
                            <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt class="truncate text-sm font-medium text-gray-600">30 DAYS</dt>
                                <dd v-if="!organisationUsageSummaryLoading" class="mt-1 text-3xl font-medium tracking-tight text-gray-900">
                                    {{
                                        formatter(
                                            metricsStore.organization.usageSummaryData.metrics['30-days']?.byteTotal,
                                            getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                        ).display
                                    }}
                                </dd>
                                <div v-else class="snippet pl-2 pt-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>
                            <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt class="truncate text-sm font-medium text-gray-600">90 DAYS</dt>
                                <dd v-if="!organisationUsageSummaryLoading" class="mt-1 text-3xl font-medium tracking-tight text-gray-900">
                                    {{
                                        formatter(
                                            metricsStore.organization.usageSummaryData.metrics['90-days']?.byteTotal,
                                            getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                        ).display
                                    }}
                                </dd>
                                <div v-else class="snippet pl-2 pt-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>
                            <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt class="truncate text-sm font-medium text-gray-600">365 DAYS</dt>
                                <dd v-if="!organisationUsageSummaryLoading" class="mt-1 text-3xl font-medium tracking-tight text-gray-900">
                                    {{
                                        formatter(
                                            metricsStore.organization.usageSummaryData.metrics['365-days']?.byteTotal,
                                            getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                        ).display
                                    }}
                                </dd>
                                <div v-else class="snippet pl-2 pt-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>
                        </dl>
                    </div>

                    <div class="flex justify-between items-end mb-3 mt-10">
                        <h3 class="font-semibold">Billing Metrics</h3>
                    </div>

                    <div class="rounded-lg bg-gray-50 shadow p-5">
                        <div class="flex items-center justify-between">
                            <DateFilter
                                :selected="selectedBillingTimeseriesDateFilter?.key || 'last24h'"
                                :disabled="timeseriesLoading"
                                :hide-options="['date', 'dateRange', 'today', 'allTime', 'lastWeek', 'lastMonth', 'thisWeek', 'thisMonth']"
                                @input="(d) => fetchBillingTimeseries(d, selectedTenantIds)" />
                            <div class="flex items-center justify-end w-1/3">
                                <multiselect
                                    v-model="allSelectedMetricsForBillingChart"
                                    class="inputRight"
                                    :options="listOfBillingMetrics"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :placeholder="
                                        timeseriesLoading ? 'Loading...' : listOfBillingMetrics?.length ? 'Select metrics' : 'No metrics available'
                                    "
                                    :select-label="'Select'"
                                    :deselect-label="'Remove'"
                                    :loading="timeseriesLoading"
                                    :disabled="timeseriesLoading || !listOfBillingMetrics?.length"
                                    label="name"
                                    track-by="key">
                                    <template #selection="{ values, isOpen }"
                                        ><span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{
                                            values.length > 1 ? `${values.length} metrics selected` : values[0]?.name
                                        }}</span></template
                                    >
                                </multiselect>
                                <button
                                    aria-label="button"
                                    class="bg-cyan-950 hover:bg-cyan-800 text-white rounded-r-md px-4 flex items-center h-auto"
                                    :style="allSelectedMetricsForBillingChart?.length ? 'min-height: 42px' : 'min-height: 38px'"
                                    :disabled="timeseriesLoading"
                                    @click.stop="fetchBillingTimeseries(null, selectedTenantIds)">
                                    <ArrowPathIcon class="h-4 w-4 p-0" />
                                </button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="mt-4 shadow rounded-lg">
                                <AppLoader :force-loading="timeseriesLoading" background="#f9fafb" default-height="35vh">
                                    <HighChart :chart-options="chartOptions" />
                                </AppLoader>
                            </div>
                        </div>
                    </div>

                    <div class="table-page-container mt-10 mb-5">
                        <div class="table-page-body">
                            <!-- Search and Filters -->
                            <div>
                                <div class="flex items-center gap-3 mb-3">
                                    <h3 class="font-semibold">Connector Usage</h3>

                                    <RowActionMenu
                                        :show-export-usage="true"
                                        :loading="usageDownloadLoading"
                                        :disable-export-usage="
                                            usageTableDataLoading ||
                                            globalSourceConfigsLoading ||
                                            globalDestinationConfigsLoading ||
                                            usageDownloadLoading
                                        "
                                        :buttons-size="'xs'"
                                        @export-usage="downloadUsage(selectedTenantIds)" />
                                </div>
                                <div class="flex justify-between items-end mb-3">
                                    <SearchInput
                                        :loading="globalSourceConfigsLoading || globalDestinationConfigsLoading || usageTableDataLoading"
                                        placeholder="Search by name..."
                                        :custom-class="'w-1/3'"
                                        @search="
                                            (input) => {
                                                connectorSearchQuery = input;
                                                onSearchQueryInput(input);
                                            }
                                        " />
                                    <DropdownFilters
                                        v-model="checkedUsageTableFilters"
                                        slim-dropdown-button
                                        :filters="usageTableFilters"
                                        :disabled="usageTableDataLoading"
                                        @on-filter-toggle="(d) => onUsagePageFilterToggle(d, selectedTenantIds)" />
                                </div>
                                <div class="relative w-full overflow-auto shadow-md">
                                    <AppLoader :force-loading="usageTableDataLoading" default-height="45vh" background="white">
                                        <table class="min-w-full divide-y divide-gray-300 border" aria-label="dashboard table">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        Connector
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        1 Hour
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        1 Day
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        7 days
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        30 Days
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        90 Days
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                        365 Days
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 bg-white">
                                                <tr
                                                    v-for="(connectorMetrics, index) in usageTableData"
                                                    :key="index"
                                                    :aria-disabled="usageTableDataLoading"
                                                    class="transition duration-300 ease-in-out hover:bg-gray-50">
                                                    <td class="px-6 py-4 whitespace-nowrap cursor-pointer font-medium text-green-500 no-underline">
                                                        <div class="flex items-center mr-4 gap-2">
                                                            <img :src="getConnectorIcon(connectorMetrics.connector)" alt="" class="w-6 h-6" />
                                                            <div class="my-auto whitespace-normal break-words max-w-md">
                                                                <router-link
                                                                    :to="selectedSourceRouterPath(connectorMetrics.entity_id)"
                                                                    class="font-medium text-cyan-950 no-underline hover:text-cyan-800">
                                                                    {{ connectorMetrics.name }}
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                        <span>
                                                            {{
                                                                formatter(
                                                                    connectorMetrics.metrics['1-hours']?.byteTotal,
                                                                    getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                                                ).display
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                        <span>
                                                            {{
                                                                formatter(
                                                                    connectorMetrics.metrics['1-days']?.byteTotal,
                                                                    getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                                                ).display
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                        <span>
                                                            {{
                                                                formatter(
                                                                    connectorMetrics.metrics['7-days']?.byteTotal,
                                                                    getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                                                ).display
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                        <span>
                                                            {{
                                                                formatter(
                                                                    connectorMetrics.metrics['30-days']?.byteTotal,
                                                                    getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                                                ).display
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                        <span>
                                                            {{
                                                                formatter(
                                                                    connectorMetrics.metrics['90-days']?.byteTotal,
                                                                    getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                                                ).display
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                        <span>
                                                            {{
                                                                formatter(
                                                                    connectorMetrics.metrics['365-days']?.byteTotal,
                                                                    getMetricFormattingInfo('byteTotal', metricsStore.sources.globalConfigs?.metrics)
                                                                ).display
                                                            }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr v-if="!usageTableData.length" class="bg-white">
                                                    <td class="px-3 py-4 text-sm text-gray-600 text-center" colspan="5">No records found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </AppLoader>
                                </div>

                                <Pagination
                                    v-if="usageTableData.length > 0 || usageTablePage > 0"
                                    :page="usageTablePage"
                                    :page-size="usageTablePageSize"
                                    :current-page-items-count="usageTableData.length"
                                    :disable-navigation="globalSourceConfigsLoading || globalDestinationConfigsLoading || usageTableDataLoading"
                                    :total-items-count="metricsStore.organization.total"
                                    @page-change="(page) => fetchUsageTableData(page, null, null, selectedTenantIds)">
                                </Pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </AppLoader>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash';
import AppHeader from '../../components/AppHeader.vue';
import AppLoader from '../../components/AppLoader.vue';
import { computed, ref, onBeforeMount, watch } from 'vue';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useRouter, useRoute } from 'vue-router';
import DateFilter from '@/components/global/DateFilter.vue';
import DropdownFilters from '@/components/DropdownFilters.vue';
import HighChart from '../../components/HighChart.vue';
import { formatter, getMetricFormattingInfo, isJSON } from '../../utils/utils';
import { useMetricsStore } from '../../stores/metrics.store';
import { DATE_FILTER_OPTIONS } from '../../utils/constants';
import moment from 'moment-timezone';
import Multiselect from 'vue-multiselect';
import Pagination from '../../components/Pagination.vue';
import { getConnectorIcon } from '@/utils/constants';
import SearchInput from '@/components/global/SearchInput.vue';
import RowActionMenu from '../../components/RowActionMenu.vue';
import AccountSwitcher from '../../components/SideMenu/AccountSwitcher.vue';

const router = useRouter();
const route = useRoute();
const metricsStore = useMetricsStore();

const orgStatisticsLoading = ref(false);
const timeseriesLoading = ref(false);
const usageTableDataLoading = ref(false);
const globalSourceConfigsLoading = ref(false);
const globalDestinationConfigsLoading = ref(false);
const organisationUsageSummaryLoading = ref(false);
const usageDownloadLoading = ref(false);

const selectedBillingTimeseriesDateFilter = ref(null);
const billingTimeseriesData = ref([]);
const usageTableData = ref([]);

const usageTablePage = ref(0);
const usageTablePageSize = ref(10);
const allSelectedMetricsForBillingChart = ref([]);

const checkedUsageTableFilters = ref([]);
const connectorSearchQuery = ref(null);
const selectedTenantIds = ref(null);

const usageTableFilters = [
    {
        title: 'Items per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            // { value: 5, displayValue: '5/page' },
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
            // { value: 100, displayValue: '100/page' },
            // { value: 200, displayValue: '200/page' },
            // { value: 500, displayValue: '500/page' }
        ]
    }
];

onBeforeMount(async () => {
    await generalFetchOfTheBillingPage();
});

const generalFetchOfTheBillingPage = async (tenantIds = []) => {
    orgStatisticsLoading.value = true;
    timeseriesLoading.value = true;
    usageTableDataLoading.value = true;
    globalSourceConfigsLoading.value = true;
    globalDestinationConfigsLoading.value = true;
    organisationUsageSummaryLoading.value = true;

    initializeUsageTableFilters();

    // topic related queries
    const usageTableGeneralFilterQuery = route.query.usageFilter ? JSON.parse(route.query.usageFilter) : null;

    // timeseries related queries
    const selectedBillingTimeseriesMetricsQuery = route.query.selectedBillingTimeseriesMetrics;
    const selectedBillingTimeseriesDateFilterQuery = route.query.selectedBillingTimeseriesDateFilter;

    // topic related queries
    checkedUsageTableFilters.value = usageTableGeneralFilterQuery
        ? { ...usageTableGeneralFilterQuery?.filter, [usageTableGeneralFilterQuery?.filter?.name]: [usageTableGeneralFilterQuery?.filterOption] }
        : checkedUsageTableFilters.value;

    allSelectedMetricsForBillingChart.value = selectedBillingTimeseriesMetricsQuery
        ? JSON.parse(selectedBillingTimeseriesMetricsQuery)
        : allSelectedMetricsForBillingChart.value;
    selectedBillingTimeseriesDateFilter.value = selectedBillingTimeseriesDateFilterQuery
        ? JSON.parse(selectedBillingTimeseriesDateFilterQuery)
        : selectedBillingTimeseriesDateFilter.value;

    try {
        await Promise.all([
            metricsStore.sources.fetchGlobalSourceConfigs().then(() => {
                globalSourceConfigsLoading.value = false;
            }),
            metricsStore.destinations.fetchGlobalDestinationConfigs(() => {
                globalDestinationConfigsLoading.value = false;
            }),
            metricsStore.organization.fetchOrganisationUsageSummary(tenantIds).then(() => {
                organisationUsageSummaryLoading.value = false;
            }),
            usageTableGeneralFilterQuery
                ? onUsagePageFilterToggle(usageTableGeneralFilterQuery, tenantIds)
                : fetchUsageTableData(metricsStore.organization.page || 0, metricsStore.organization.pageSize || 10, null, tenantIds),
            fetchBillingTimeseries(selectedBillingTimeseriesDateFilter.value, tenantIds)
        ]);
    } catch (error) {
        console.log(error);
    } finally {
        orgStatisticsLoading.value = false;
        timeseriesLoading.value = false;
        usageTableDataLoading.value = false;
        globalDestinationConfigsLoading.value = false;
        globalSourceConfigsLoading.value = false;
        organisationUsageSummaryLoading.value = false;
    }

    return true;
};

const onSelectedTenantChange = async (tenantIds) => {
    if (_.isEqual(tenantIds, selectedTenantIds.value)) {
        return;
    }

    selectedTenantIds.value = tenantIds;

    await router.push({ query: {} });
    await new Promise((resolve) => setTimeout(resolve, 200));

    connectorSearchQuery.value = '';
    checkedUsageTableFilters.value = [];
    allSelectedMetricsForBillingChart.value = [];
    selectedBillingTimeseriesDateFilter.value = { key: 'last24h' };
    metricsStore.organization.setUsagePageNumber(0);
    metricsStore.organization.setUsagePageSize(10);

    await generalFetchOfTheBillingPage(selectedTenantIds.value);
};

const onSearchQueryInput = _.debounce(async (input) => {
    await fetchUsageTableData(null, null, input, selectedTenantIds.value);
}, 700);

const downloadUsage = async (tenantIds = []) => {
    usageDownloadLoading.value = true;
    try {
        let usageExportText = await metricsStore.organization.exportUsage(tenantIds);

        if (isJSON(usageExportText) && typeof usageExportText === 'object') {
            usageExportText = JSON.stringify(usageExportText, null, 2);
        }

        const blob = new Blob([usageExportText], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = selectedTenantIds.value?.length ? `streamkap_usage_tenant_${selectedTenantIds.value?.join('__')}.csv` : 'streamkap_usage.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        console.log(err, 'err');
    } finally {
        usageDownloadLoading.value = false;
    }
};

function initializeUsageTableFilters() {
    usageTableFilters.forEach((filter) => {
        if (filter.name === 'count') {
            usageTablePageSize.value = filter.options[filter.defaultIndex].value;
        }

        checkedUsageTableFilters.value[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

const listOfBillingMetrics = computed(() => {
    let sourceMetricsKeys = Object.keys(billingTimeseriesData.value?.sources?.account?.billing || {});
    let destinationMetricsKeys = Object.keys(billingTimeseriesData.value?.destinations?.account?.billing || {});

    sourceMetricsKeys = sourceMetricsKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, metricsStore.sources.globalConfigs?.metrics);

            if (!metricInfo) {
                return null;
            }

            return {
                ...metricInfo,
                key,
                type: 'source',
                name: `Sources - ${metricInfo.name}`
            };
        })
        .filter((x) => x && billingTimeseriesData.value?.sources?.account?.billing?.[x.key]?.length);

    destinationMetricsKeys = destinationMetricsKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, metricsStore.destinations.globalConfigs?.metrics); // TODO: Change to destinations

            if (!metricInfo) {
                return null;
            }

            return {
                ...metricInfo,
                key,
                type: 'destination',
                name: `Destinations - ${metricInfo.name}`
            };
        })
        .filter((x) => x && billingTimeseriesData.value?.destinations?.account?.billing?.[x.key]?.length);

    const keysToReturn = [...sourceMetricsKeys, ...destinationMetricsKeys];

    if (
        !keysToReturn?.length ||
        (keysToReturn?.length && allSelectedMetricsForBillingChart.value?.map((x) => x.key).some((x) => !keysToReturn.map((y) => y.key).includes(x)))
    ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        allSelectedMetricsForBillingChart.value = [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        delete route.query.selectedBillingTimeseriesMetrics;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        router.push(route.query);
    }

    return _.orderBy(keysToReturn, 'name', 'asc');
});

watch(
    () => listOfBillingMetrics.value,
    (newValue) => {
        const defaultTo = ['byteTotal'];

        if (!allSelectedMetricsForBillingChart.value.length && newValue.length) {
            allSelectedMetricsForBillingChart.value = newValue.filter((x) => defaultTo.includes(x.key));

            if (!allSelectedMetricsForBillingChart.value.length) {
                allSelectedMetricsForBillingChart.value = [newValue[0]];
            }
        }
    }
);

watch(
    () => allSelectedMetricsForBillingChart.value,
    (newValue) => {
        if (newValue?.filter((x) => x)?.length) {
            router.push({ query: { ...route.query, selectedBillingTimeseriesMetrics: JSON.stringify(newValue) } });
        } else {
            delete route.query.selectedBillingTimeseriesMetrics;
            router.push({ query: route.query });
        }
    },
    { deep: true }
);

const fetchBillingTimeseries = async (specificDateFilter, tenantIds = []) => {
    timeseriesLoading.value = true;
    selectedBillingTimeseriesDateFilter.value = specificDateFilter || selectedBillingTimeseriesDateFilter.value;

    const defaultDateFilter =
        selectedBillingTimeseriesDateFilter.value?.key === 'last24h' ||
        !selectedBillingTimeseriesDateFilter.value ||
        !selectedBillingTimeseriesDateFilter.value?.key;
    const dateFilterToUse = defaultDateFilter
        ? undefined
        : { ...selectedBillingTimeseriesDateFilter.value?.value, key: selectedBillingTimeseriesDateFilter.value?.key };

    const queryInfo = { query: { ...route.query, selectedBillingTimeseriesDateFilter: JSON.stringify(dateFilterToUse) } };

    if (dateFilterToUse?.key) {
        router.push(queryInfo);
    } else {
        delete queryInfo.query.selectedBillingTimeseriesDateFilter;
        router.push(queryInfo);
    }

    try {
        const timeseriesData = await metricsStore.organization.fetchBillingTimeseriesMetricsForAllConnectors(
            defaultDateFilter
                ? { tenant_ids: tenantIds }
                : {
                      ...selectedBillingTimeseriesDateFilter.value?.value,
                      key: selectedBillingTimeseriesDateFilter.value?.key,
                      tenant_ids: tenantIds
                  }
        );

        billingTimeseriesData.value = timeseriesData;

        return timeseriesData;
    } catch (err) {
        console.log(err, 'err');
    } finally {
        timeseriesLoading.value = false;
    }
};

function selectedSourceRouterPath(id) {
    return { name: 'source-details', params: { id } };
}

const chartOptions = computed(() => {
    const titleText = DATE_FILTER_OPTIONS.find((option) => option.value === selectedBillingTimeseriesDateFilter.value?.key)?.label || 'Last 24h';

    const metricKeys =
        _.cloneDeep(allSelectedMetricsForBillingChart.value)
            ?.map((x) => (x.key ? { key: x.key, type: x.type, name: x.name } : null))
            .filter((x) => x) || [];

    const dataForEachSelectedMetric = metricKeys
        .map(({ key, type, name }) => {
            const sourcesMetricData = billingTimeseriesData.value?.sources?.account?.billing[key];
            const destinationsMetricData = billingTimeseriesData.value?.destinations?.account?.billing[key];

            if (sourcesMetricData?.length && destinationsMetricData?.length) {
                return [
                    {
                        metricKey: key,
                        metricData: sourcesMetricData,
                        metricInfo: {
                            ...getMetricFormattingInfo(
                                key,
                                type === 'source' ? metricsStore.sources.globalConfigs?.metrics : metricsStore.destinations.globalConfigs?.metrics
                            ),
                            name
                        }
                    },
                    {
                        metricKey: key,
                        metricData: destinationsMetricData,
                        metricInfo: {
                            ...getMetricFormattingInfo(
                                key,
                                type === 'source' ? metricsStore.sources.globalConfigs?.metrics : metricsStore.destinations.globalConfigs?.metrics
                            ),
                            name
                        }
                    }
                ];
            }

            return {
                metricKey: key,
                metricData: sourcesMetricData || destinationsMetricData,
                metricInfo: {
                    ...getMetricFormattingInfo(
                        key,
                        type === 'source' ? metricsStore.sources.globalConfigs?.metrics : metricsStore.destinations.globalConfigs?.metrics
                    ),
                    name
                }
            };
        })
        .flat();

    const seriesAndYAxes = dataForEachSelectedMetric.map((metric, index) => {
        const yAxis = {
            title: {
                text: metric.metricInfo?.name
            },
            labels: {
                formatter: function () {
                    return formatter(this.value, { unit: metric.metricInfo?.unit }, false).display;
                }
            },
            opposite: index % 2 !== 0
        };

        const series = {
            name: metric.metricInfo?.name,
            data: metric.metricData?.map((dataItem) => {
                const localTime = moment.utc(dataItem.timestamp).tz(moment.tz.guess()).toDate();
                return {
                    x: localTime,
                    y: dataItem.value
                };
            }),
            type: 'line',
            yAxis: index,
            custom: {
                unit: metric.metricInfo?.unit
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter(this.y, { unit: metric.metricInfo?.unit }, false).display;
                }
            }
        };

        return { series, yAxis };
    });

    // Split the series and yAxis configurations
    const series = seriesAndYAxes.map((sy) => sy.series);
    const yAxis = seriesAndYAxes.map((sy) => sy.yAxis);

    const options = {
        time: {
            timezone: moment.tz.guess()
        },
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: titleText
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    // Get the current date and the date from the axis in the browser's timezone
                    const now = moment.utc(new Date()).tz(moment.tz.guess());
                    const axisDate = moment.utc(new Date(this.value)).tz(moment.tz.guess());

                    // Compare dates to determine formatting
                    if (now.format('YYYYMMDD') === axisDate.format('YYYYMMDD')) {
                        // Current day: show hour only
                        return axisDate.format('HH:mm');
                    } else if (now.format('YYYYMM') === axisDate.format('YYYYMM')) {
                        // Current month, different day: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else if (now.format('YYYY') === axisDate.format('YYYY')) {
                        // Different month, same year: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else {
                        // Different year: show day, month (short name), year, and hour
                        return axisDate.format('DD MMM YYYY HH:mm');
                    }
                }
            }
        },
        yAxis: yAxis,
        series: series,
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function () {
                const localTime = moment(new Date(this.x)).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

                let tooltip = `${localTime}<br>`;
                this.points.forEach((point) => {
                    tooltip += `<b>${point.series.name}:</b> ${
                        formatter(point.y, { unit: point.series.userOptions.custom?.unit }, false).display
                    }<br>`;
                });
                return tooltip;
            }
        }
    };

    return options;
});

async function fetchUsageTableData(newPage = null, pageSize = null, currentSearchQuery = null, tenantIds = []) {
    usageTableDataLoading.value = true;

    try {
        usageTablePage.value = _.isNil(newPage) ? usageTablePage.value : newPage;
        usageTablePageSize.value = _.isNil(pageSize) ? usageTablePageSize.value : pageSize;

        if (currentSearchQuery || currentSearchQuery === '') {
            usageTablePage.value = 0;
        }

        await metricsStore.organization
            .fetchOrganisationUsageMetrics({
                page_size: usageTablePageSize.value,
                page: usageTablePage.value,
                partial_name: currentSearchQuery || connectorSearchQuery.value,
                tenant_ids: tenantIds
            })
            .then(() => {
                usageTableData.value = metricsStore.organization.usageTableData;
                usageTableDataLoading.value = false;
            });
    } finally {
        usageTableDataLoading.value = false;
    }
}

async function onUsagePageFilterToggle({ filter, filterOption }, tenantIds = []) {
    try {
        let pageSize = metricsStore.organization.pageSize;

        if (filter.name === 'count') {
            pageSize = filterOption.value;
        }

        usageTablePage.value = 0;

        const usageFilter = JSON.stringify({ filter: { name: filter.name }, filterOption });

        const queryInfo = { query: { ...route.query, usageFilter } };
        router.push(queryInfo);

        await fetchUsageTableData(usageTablePage.value, pageSize, null, tenantIds);
    } catch (error) {
        console.log(error);
    }
}
</script>

<style>
/* Custom CSS for aspect ratio */
.aspect-square {
    position: relative;
}
</style>
