import BillingPage from '@/views/billing/BillingPage.vue';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/billing',
        name: 'billing',
        component: BillingPage,
        meta: {
            title: 'Billing',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [
                        Permissions.FE_SUBSCRIPTIONS_READ,
                        Permissions.READ_ORGANISATION,
                        Permissions.READ_SOURCE_METRICS,
                        Permissions.READ_DESTINATION_METRICS
                    ],
                    type: 'and'
                }
            }
        }
    }
];
