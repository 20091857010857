import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLoaderStore } from './loader.store';
import * as servicesApi from '../api/services.api';
import { useFrontegg } from '@frontegg/vue';
import _ from 'lodash';

export const useServicesStore = defineStore('services', () => {
    const loader = useLoaderStore();
    const services = ref([]);
    const fronteggState = useFrontegg();
    // const total = ref(0);
    // const page = ref(0);
    // const pageSize = ref(0);

    return {
        all: computed(() =>
            _.map(services.value, (s) => ({
                ...s,
                created_timestamp: new Date(s.created_timestamp).toLocaleString()
            }))
        ),
        current: computed(() => _.find(services.value, { isCurrent: true })),

        // Actions
        fetch: async function () {
            if (services.value.length) return services;

            loader.activateLoading('services.fetch');

            await servicesApi
                .fetchAllServices()
                .then((data) => {
                    const userMetadata = JSON.parse(fronteggState.authState.user.metadata);
                    const currentTenant = fronteggState.authState.user?.tenantId;

                    services.value = data.map((service) => ({
                        ...service,
                        isCurrent: service.id === userMetadata[currentTenant]
                    }));

                    return services;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('services.fetch');
                });
        },
        switch: async function (serviceId) {
            loader.activateLoading('services.switch');

            services.value = services.value.map((service) => ({
                ...service,
                isCurrent: service.id === serviceId
            }));

            return servicesApi
                .switchService(serviceId)
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('services.switch');
                });
        }
        // getById: async function (id) {
        //     if (!id) return;

        //     const found = _.find(servicesMocked.value, { id });

        //     if (!found) {
        //         router.push({ name: '404' });
        //         return;
        //     }

        //     if (found) {
        //         return found;
        //     }
        // },
        // editService: async function (service) {
        //     loader.activateLoading('services.edit');

        //     servicesMocked.value = servicesMocked.value.map((item) => {
        //         if (item.id === service.id) {
        //             return service;
        //         }

        //         return item;
        //     });

        //     // return transformsApi
        //     //     .editTransform(transform)
        //     //     .then((data) => {
        //     //         transforms.value = data;

        //     //         return data;
        //     //     })
        //     //     .catch((err) => {
        //     //         console.error(err);
        //     //     })
        //     //     .finally(() => {
        //     //         loader.deactivateLoading('transforms.edit');
        //     //     });
        // }
    };
});
