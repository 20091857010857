import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { NovuService } from '../services';
import { toast } from 'vue3-toastify';

export const useNovuStore = defineStore('novu', () => {
    const userPreferences = ref(undefined);
    const userNotifications = ref(undefined);
    const userUnreadNotificationsCount = ref(0);

    const updateUnreadCount = (newCount) => {
        userUnreadNotificationsCount.value = newCount;
    };

    NovuService.registerUnreadCountCallback(updateUnreadCount);

    // Higher-order function for error handling
    const executeWithTryCatch = (operation) => {
        return async (...args) => {
            try {
                return await operation(...args);
            } catch (e) {
                const errorMsg = `Notifications service - ${e}`;
                console.error(errorMsg);
                toast.error(errorMsg);
                return null;
            }
        };
    };

    return {
        userPreferences,
        userNotifications,
        userUnreadNotificationsCount: computed(() => userUnreadNotificationsCount.value),
        initializeService: executeWithTryCatch(async function () {
            let initialized = await NovuService.init();

            if (initialized) {
                userUnreadNotificationsCount.value = await NovuService.fetchUnreadCount();
            }

            return initialized;
        }),
        fetchUserPreferences: executeWithTryCatch(async function () {
            userPreferences.value = await NovuService.fetchUserPreferences();
            return true;
        }),
        updateUserPreference: executeWithTryCatch(async function (preference) {
            return await NovuService.updateUserPreference(preference);
        }),
        fetchNotifications: executeWithTryCatch(async function (page = 1, query) {
            try {
                userNotifications.value = await NovuService.fetchNotifications(page, query);
                return userNotifications.value;
            } catch (e) {
                const errorMsg = `Notifications service - ${e}`;
                console.error(errorMsg);
                toast.error(errorMsg);
            }
        }),
        fetchUnreadCount: executeWithTryCatch(async function () {
            userUnreadNotificationsCount.value = await NovuService.fetchUnreadCount();
            return userUnreadNotificationsCount.value;
        }),
        markNotificationsAs: executeWithTryCatch(async function (notificationIds = [], read = false) {
            return await NovuService.MarkNotificationsAs(notificationIds, read);
        }),
        removeNotifications: executeWithTryCatch(async function (notificationIds = []) {
            return await NovuService.RemoveNotifications(notificationIds);
        }),
        markAllNotificationsAsRead: executeWithTryCatch(async function () {
            return await NovuService.MarkAllNotificationsAsRead();
        }),
    };
});
