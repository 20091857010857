<template>
    <div class="flex flex-col items-center justify-center gap-2">
        <div v-if="toastProps?.data?.message" class="multi-line-truncate whitespace-normal break-words">
            {{ toastProps?.data?.message }}
        </div>
        <a href="#" class="font-medium text-cyan-950 no-underline hover:text-cyan-800" @click.prevent.stop="openChat"> Contact support </a>
        <small
            v-if="toastProps?.data?.message"
            aria-label="button"
            class="cursor-pointer flex items-center"
            :disabled="copied"
            @click="copyToClipboard">
            <ClipboardIcon v-if="!copied" class="h-4 w-4 pr-1" />
            <ClipboardDocumentCheckIcon v-else class="h-4 w-4 pr-1" />
            <span v-if="!copied">Copy message</span>
            <span v-else>Copied!</span>
        </small>
    </div>
</template>
<script setup>
import { ClipboardDocumentCheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';

const props = defineProps({
    toastProps: Object
});

const copied = ref(false);

const openChat = () => {
    window.Pylon('show');
};

const copyToClipboard = async () => {
    copied.value = true;
    await navigator.clipboard.writeText(props.toastProps?.data?.message);
    setTimeout(() => {
        copied.value = false;
    }, 3000);
};
</script>

<style>
.multi-line-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 7; /* Adjust the number of lines you want */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 14rem;
    margin-top: 1rem;
}
</style>
