<template>
    <div class="w-full flex flex-col relative">
        <label class="label py-2 text-sm font-medium absolute top-[-1.5rem]" :class="props.overrideLabelOpacity ? 'override-opacity' : ''">
            {{ config.display_name }}: {{ controlValue }}
        </label>

        <div class="mt-2 flex items-center gap-2 w-full">
            <div :class="config.description && !hideLateralDescriptionValue ? 'flex-1 mr-4' : 'flex-grow'">
                <Slider v-model="controlValue" :min="config.value.min" :max="config.value.max" :step="config.value.step" :edit-mode="editMode" />
                <div class="text-red-700 mt-1">
                    <template v-for="error in errors" :key="error.$uid">
                        <span> * {{ error.$message }} </span>
                    </template>
                </div>
            </div>
            <Tooltip
                v-if="
                    hideLateralDescriptionValue &&
                    props.config.display_name.trim().toLowerCase() !== config.description.trim().toLowerCase() &&
                    config.description.trim().split(' ').length > 1
                "
                :tooltip-text="config.description"
                class="ml-2" />
            <div
                v-if="
                    config.description &&
                    !hideLateralDescriptionValue &&
                    props.config.display_name.trim().toLowerCase() !== config.description.trim().toLowerCase() &&
                    config.description.trim().split(' ').length > 1
                "
                class="flex-1 p-3 bg-green-50 border-l-4 border-green-200 text-xs text-black rounded-md relative arrow-left"
                :class="errors.length ? 'mb-5' : ''">
                {{ config.description }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, onUpdated } from 'vue';
import Slider from './Slider.vue';
import Tooltip from './Tooltip.vue';
import _ from 'lodash';

const emit = defineEmits(['input']);
const props = defineProps({
    config: {
        type: Object,
        required: true
    },
    value: {
        type: [String, null],
        default: null
    },
    editMode: {
        type: Boolean,
        default: true
    },
    overrideLabelOpacity: {
        type: Boolean,
        default: false
    },
    hideLateralDescription: {
        type: Boolean,
        default: true
    },
    errors: {
        type: Array,
        default: () => []
    }
});

const controlValue = ref(_.isNumber(props.value) ? props.value : props.value || props.config.value.default);
const hideLateralDescriptionValue = ref(props.hideLateralDescription);

onUpdated(() => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : !props.editMode || window.innerWidth <= 1024;
});
const handleResize = () => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : window.innerWidth <= 1024;
};

window.addEventListener('resize', handleResize);

onMounted(() => {
    handleResize();
});

watch(controlValue, (newValue) => {
    emit('input', newValue);
});
</script>

<style scoped>
.arrow-left::before {
    content: '';
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: 12px solid #bbf7d0;
}
</style>
