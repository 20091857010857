import WarningDialog from '../components/WarningDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

const useConfirmBeforeAction = (action, props, onCancelAction) => {
    const { reveal, onConfirm, onCancel } = createConfirmDialog(WarningDialog, props);

    onConfirm(action);
    onCancel(onCancelAction);

    reveal();
};

export default useConfirmBeforeAction;
