import ConnectorsPage from '@/views/connectors/ConnectorsPage.vue';
import CreateSourcePage from '@/views/sources/CreateSourcePage.vue';
import SourceDetails from '@/views/sources/SourceDetails.vue';
import CreateDestinationPage from '@/views/destinations/CreateDestinationPage.vue';
import DestinationDetails from '@/views/destinations/DestinationDetails.vue';
import WebSocketService from '../services/webSocket.service';
import { useMetricsStore } from '../stores/metrics.store';
import AddConnectors from '@/views/connectors/AddConnectors.vue';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/connectors/add',
        name: 'add-connectors',
        component: AddConnectors,
        meta: {
            title: 'Add Connectors',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.WRITE_SOURCES, Permissions.WRITE_DESTINATIONS],
                    type: 'or'
                }
            }
        }
    },
    {
        path: '/connectors',
        name: 'connectors',
        component: ConnectorsPage,
        meta: {
            title: 'Connectors',
            requireAuth: true,
            onEnter: () => {
                const metricsStore = useMetricsStore();

                WebSocketService.addListener('metrics', 'sources', null, metricsStore.onMetricsData, 'metrics.onMetricsData');
                WebSocketService.addListener('metrics', 'destinations', null, metricsStore.onMetricsData, 'metrics.onMetricsData');
            },
            accessRules: {
                permissions: {
                    values: [
                        Permissions.READ_DESTINATIONS,
                        Permissions.READ_SOURCES,
                        Permissions.READ_SOURCE_METRICS,
                        Permissions.READ_DESTINATION_METRICS
                    ],
                    type: 'or'
                }
            }
        }
    },
    {
        path: '/connectors/sources/new/:connector?',
        name: 'new-source',
        props: (route) => ({
            connector: route.params.connector || null,
            cloneConfig: route.query.cloneConfig || null
        }),
        component: CreateSourcePage,
        meta: {
            title: 'Add Source',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.WRITE_SOURCES]
                }
            }
        }
    },
    {
        path: '/connectors/sources/:id',
        name: 'source-details',
        props: (route) => ({
            id: route.params.id
        }),
        component: SourceDetails,
        meta: {
            title: 'Source Details',
            requireAuth: true,
            permissions: {
                values: [Permissions.READ_SOURCES, Permissions.READ_SOURCE_METRICS], // write is not required to see the page and will hide the action buttons if needed
                type: 'and'
            }
        }
    },
    {
        path: '/connectors/destinations/new/:connector?',
        name: 'new-destination',
        props: (route) => ({
            connector: route.params.connector || null,
            cloneConfig: route.query.cloneConfig || null
        }),
        component: CreateDestinationPage,
        meta: {
            title: 'Add Destination',
            requireAuth: true,
            permissions: {
                values: [Permissions.WRITE_DESTINATIONS]
            }
        }
    },
    {
        path: '/connectors/destinations/:id',
        name: 'destination-details',
        props: (route) => ({
            id: route.params.id
        }),
        component: DestinationDetails,
        meta: {
            title: 'Destination Details',
            requireAuth: true,
            permissions: {
                values: [Permissions.READ_DESTINATIONS, Permissions.READ_DESTINATION_METRICS], // write is not required to see the page and will hide the action buttons if needed
                type: 'and'
            }
        }
    }
];
