import ErrorDialog from "../components/ErrorDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";

const useConfirmBeforeAction = (props) => {
    const { reveal } = createConfirmDialog(ErrorDialog, props);

    reveal();
};

export default useConfirmBeforeAction;
