<template>
    <div id="app-header" class="self-center mb-8">
        <div class="header-title md:text-xl w-full">
            <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row items-center" :class="gap">
                    <slot name="titleLeft"></slot>

                    <div class="flex flex-col">
                        <h2 v-if="showTitle" class="text-gray-800 font-semibold">
                            {{ route.meta.title }} {{ secondaryTitle ? `- ${secondaryTitle}` : '' }}
                        </h2>
                        <slot name="underTitle"></slot>
                    </div>
                    <slot name="titleRight"></slot>
                </div>
                <div class="flex items-center gap-6">
                    <slot name="end"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';
import { useAuthStore } from '../stores/auth.store';

const authStore = useAuthStore();
const subscriberId = ref(authStore.userTenantId || null);

defineProps({
    secondaryTitle: {
        type: String,
        default: null
    },
    showTitle: {
        type: Boolean,
        default: true
    },
    gap: {
        type: String,
        default: 'gap-2'
    }
});

const route = useRoute();

watch(
    () => authStore.userTenantId,
    (newUserTenantId) => {
        if (newUserTenantId) {
            subscriberId.value = newUserTenantId;
        }
    },
    { immediate: true }
);
</script>
