<template>
    <div v-if="config" class="my-1">
        <component
            :is="controlType"
            v-model="controlValue"
            :config="config"
            :value="value"
            :hide-lateral-description="hideLateralDescription"
            :edit-mode="editMode"
            :override-label-opacity="overrideLabelOpacity"
            :class="{
                'is-invalid': v$.controlValue.$invalid,
                'is-valid': !v$.controlValue.$invalid
            }"
            class="w-full"
            :errors="v$.controlValue.$dirty || customError ? v$.controlValue.$errors : []"
            @input="onValueChanged" />
    </div>
</template>

<script>
import OneSelectField from './OneSelectField.vue';
import TextField from './TextField.vue';
import TextAreaField from './TextAreaField.vue';
import NumberField from './NumberField.vue';
import PasswordField from './PasswordField.vue';
import BooleanField from './BooleanField.vue';
import FileUploadField from './FileUploadField.vue';
import MultiSelectField from './MultiSelectField.vue';
import SliderField from './SliderField.vue';
import DateTimeRangePicker from './DateTimeRangePicker.vue';
import ToggleField from './ToggleField.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref, watch } from 'vue';
import _ from 'lodash';

export default {
    components: {
        TextField,
        PasswordField,
        BooleanField,
        OneSelectField,
        FileUploadField,
        MultiSelectField,
        SliderField,
        DateTimeRangePicker,
        ToggleField,
        TextAreaField,
        NumberField
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: null
        },
        editMode: {
            type: Boolean,
            default: true
        },
        overrideLabelOpacity: {
            type: Boolean,
            default: false
        },
        hideLateralDescription: {
            type: Boolean,
            default: true
        }
    },
    emits: ['input'],
    setup(props, context) {
        const controlValue = ref(props.value);
        const controlTypes = getControlTypes();
        let controlType = ref();
        const validationRules = { controlValue: {} };

        if (props.config) {
            controlType.value = controlTypes[props.config.value.control];
            controlValue.value = setControlValue(controlValue, props);

            if (props.config.required) {
                validationRules.controlValue.required = required;
            }

            const v$ = useVuelidate(validationRules, { controlValue });
            const onValueChanged = createOnValueChanged(v$, controlValue, context, props);

            // Watcher to update the $errors array based on the customError prop
            watch(
                () => props.config?.custom_error,
                (newVal, oldVal) => {
                    if (v$.value.controlValue) {
                        // Remove the old custom error if it exists
                        if (oldVal && oldVal.trim() !== '') {
                            const oldErrorIndex = v$.value.controlValue.$errors.findIndex((error) => error.$message === oldVal);
                            if (oldErrorIndex !== -1) {
                                v$.value.controlValue.$errors.splice(oldErrorIndex, 1);
                            }
                        }
                        // Add the new custom error if it's not already in the $errors array
                        if (newVal && newVal.trim() !== '' && !v$.value.controlValue.$errors.some((error) => error.$message === newVal)) {
                            v$.value.controlValue.$errors.push({ $message: newVal, $params: {} });
                        }
                    }
                },
                { immediate: true }
            );

            context.emit('input', {
                value: controlValue.value,
                valid: !v$.value.$invalid,
                name: props.config.name
            });

            return { controlValue, controlType, v$, onValueChanged };
        }
    }
};

function getControlTypes() {
    return {
        json: 'file-upload-field',
        string: 'text-field',
        number: 'number-field',
        textarea: 'text-area-field',
        boolean: 'boolean-field',
        toggle: 'toggle-field',
        password: 'password-field',
        'multi-select': 'multi-select-field',
        'one-select': 'one-select-field',
        slider: 'slider-field',
        datetime: 'date-time-range-picker'
    };
}

function setControlValue(controlValue, props) {
    const defaultValue = props.config.value.default;
    if (props.config.value.control === 'boolean') {
        return _.isBoolean(controlValue.value) ? controlValue.value : defaultValue || false;
    }
    return controlValue.value !== null && controlValue.value !== undefined ? controlValue.value : defaultValue;
}

function createOnValueChanged(v$, controlValue, context, props) {
    return async (newValue) => {
        controlValue.value = newValue;
        v$.value.$validate().then((valid) => {
            context.emit('input', {
                value: newValue,
                valid,
                name: props.config.name
            });
        });
    };
}
</script>
