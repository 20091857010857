<template>
    <div class="flex flex-col">
        <div class="w-full flex flex-row relative">
            <div class="flex flex-row items-center relative" :class="showTextInfoElement ? 'w-1/2 flex-1' : ''">
                <div class="flex flex-row items-center relative">
                    <label
                        :for="`control-${controlId}`"
                        class="block text-sm font-medium text-gray-700 mr-2"
                        :class="props.overrideLabelOpacity ? 'override-opacity' : ''">
                        {{ config.display_name }}
                    </label>
                    <div class="flex h-5 mr-4 items-center">
                        <input
                            aria-label="input"
                            :id="`control-${controlId}`"
                            v-model="controlValue"
                            :class="{ disabled: !editMode }"
                            :true-value="true"
                            :false-value="false"
                            :readonly="!editMode || props.config.readonly || props.config.value?.readonly"
                            :disabled="!editMode"
                            aria-describedby="comments-description"
                            :name="`control-${controlId}`"
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300" />
                        <div v-if="errors.length" class="text-red-700 mt-1 text-xs">
                            <template v-for="error in errors" :key="error.$uid">
                                <span> * {{ error.$message }} </span>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="showTextInfoElement" class="border border-green-200 flex w-full"></div>
            </div>
            <Tooltip
                v-if="
                    hideLateralDescriptionValue &&
                    props.config.display_name.trim().toLowerCase() !== config.description.trim().toLowerCase() &&
                    config.description.trim().split(' ').length > 1
                "
                :tooltip-text="config.description"
                class="ml-2" />
            <div
                v-if="showTextInfoElement"
                class="flex-1 p-3 bg-green-50 border-l-4 border-green-200 text-xs text-black rounded-md relative arrow-left ml-4"
                :class="errors.length ? 'mb-5' : ''">
                {{ config.description }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { uuid4 } from '@sentry/utils';
import { ref, watch, onMounted, onUpdated, computed } from 'vue';
import Tooltip from './Tooltip.vue';

const controlId = uuid4();

const emit = defineEmits(['input']);
const props = defineProps({
    config: {
        type: Object,
        required: true
    },
    value: {
        type: Boolean,
        default: undefined
    },
    editMode: {
        type: Boolean,
        default: true
    },
    overrideLabelOpacity: {
        type: Boolean,
        default: false
    },
    hideLateralDescription: {
        type: Boolean,
        default: true
    },
    errors: {
        type: Array,
        default: () => []
    }
});

const hideLateralDescriptionValue = ref(props.hideLateralDescription);

onUpdated(() => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : !props.editMode || window.innerWidth <= 1024;
});
const defaultValue = ref(props.config.value.default === null || props.config.value.default === undefined ? false : props.config.value.default);

const controlValue = ref(props.value === null || props.value === undefined ? defaultValue.value : props.value);

const handleResize = () => {
    hideLateralDescriptionValue.value = props.hideLateralDescription ? props.hideLateralDescription : window.innerWidth <= 1024;
};

const showTextInfoElement = computed(() => {
    return (
        props.config.description &&
        !hideLateralDescriptionValue.value &&
        props.config.display_name.trim().toLowerCase() !== props.config.description.trim().toLowerCase() &&
        props.config.description.trim().split(' ').length > 1
    );
});

window.addEventListener('resize', handleResize);

onMounted(() => {
    handleResize();
});

watch(controlValue, (newValue) => {
    emit('input', newValue);
});
</script>

<style scoped>
.arrow-left::before {
    content: '';
    position: absolute;
    left: -12px; /* Adjusted for the larger arrow */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid transparent; /* increased for a taller arrow */
    border-bottom: 18px solid transparent; /* increased for a taller arrow */
    border-right: 12px solid #bbf7d0; /* adjusted to match the border color of the blue container */
}
</style>
