<script>
import { useDestinationsStore } from '../../stores/destinations.store';
import { useSourcesStore } from '../../stores/sources.store';
import { useMetricsStore } from '../../stores/metrics.store';
import { useRouter } from 'vue-router';
import AppLoader from '../../components/AppLoader.vue';
import { ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, ChevronUpIcon, ChevronUpDownIcon, FunnelIcon, PlusIcon } from '@heroicons/vue/20/solid';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import _ from 'lodash';
import Pagination from '../../components/Pagination.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import { getStatusColor, formatter, getMetricFormattingInfo, userHasAccess } from '../../utils/utils';
import useConfirmBeforeAction from '../../composables/useConfirmBeforeAction.js';
import useConfirmBeforeWarning from '../../composables/useConfirmBeforeWarning.js';
import { usePipelinesStore } from '../../stores/pipelines.store';
import TableActionMenu from '../../components/TableActionMenu.vue';
import useErrorDialog from '../../composables/useErrorDialog.js';
import SearchInput from '@/components/global/SearchInput.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'ProfileMenu',
    components: {
        ChevronDownIcon,
        ChevronUpIcon,
        ChevronUpDownIcon,
        FunnelIcon,
        PlusIcon,
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        MenuButton,
        InformationCircleIcon,
        MenuItem,
        MenuItems,
        AppLoader,
        TableActionMenu,
        Pagination,
        DropdownFilters,
        SearchInput
    },
    data() {
        return {
            destinationsStore: useDestinationsStore(),
            sourcesStore: useSourcesStore(),
            router: useRouter(),
            authStore: useAuthStore(),
            metricsStore: useMetricsStore(),
            pipelinesStore: usePipelinesStore(),
            searchQuery: ref(null),
            sortColumn: ref('name'),
            sortDirection: ref(false),
            triggerFilterSelection: ref(false),
            generalLoading: ref(false),
            metricsLoading: ref(false),
            actionLoading: ref({}),
            destinationsPage: 0,
            destinationsPageSize: 0,
            sourcesPage: 0,
            sourcesPageSize: 0,
            checkedAsyncFilters: [],
            asyncFilters: [
                {
                    title: 'Destinations per page',
                    name: 'count',
                    defaultIndex: 0,
                    options: [
                        { value: 10, displayValue: '10/page' },
                        { value: 20, displayValue: '20/page' },
                        { value: 50, displayValue: '50/page' }
                    ]
                }
            ],
            filters: [
                {
                    placeholder: 'All statuses',
                    text: '',
                    values: [null, 'Active', 'Broken', 'Delayed', 'Incomplete', 'Paused']
                },
                {
                    placeholder: 'All destinations',
                    text: '',
                    values: []
                }
            ],
            selectedFilters: [[], []]
        };
    },
    computed: {
        allLatestMetrics() {
            return this.metricsStore.destinations.allLatest;
        },
        searchedDestinations() {
            let result = this.applyFilters(this.destinationsStore.all);
            result = this.applySorting(result);
            return result;
        },
        possibleDestinations() {
            let result = this.destinationsStore.all.map((a) => a.connectorDisplayName).filter((value, index, self) => self.indexOf(value) === index);
            result.splice(0, 0, null);
            return result;
        }
    },
    watch: {
        generalLoading: {
            handler: function (newValue) {
                if (newValue === true && this.$refs.scrollableContainer) {
                    this.$refs.scrollableContainer.scrollTop = 0;
                }
            }
        },
        searchQuery: {
            handler: _.debounce(async function () {
                await this.loadDestinations();
            }, 700)
        }
    },
    mounted() {
        this.destinationsPage = this.destinationsStore.page || 0;
        this.destinationsPageSize = this.destinationsStore.pageSize || 10;

        this.sourcesPage = this.sourcesStore.page || 0;
        this.sourcesPageSize = this.sourcesStore.pageSize || 10;

        this.loadDestinations();
        this.loadSources();
        this.initializeAsyncFilters();
    },
    methods: {
        userHasAccess,
        getStatusColor,
        getStatus(destination) {
            return this.allLatestMetrics[destination.id]?.inline?.connector_status ?? destination?.connector_status ?? 'Unknown';
        },
        // eslint-disable-next-line no-unused-vars
        async onDelete(entity, name, id, index) {
            try {
                this.actionLoading[id] = true;

                if (!this.pipelinesStore.allBrief || _.isEmpty(this.pipelinesStore.allBrief)) {
                    await this.pipelinesStore.fetchAllBrief(false);
                }

                const hasActivePipeline = this.pipelinesStore.allBrief.some((p) => p.destination.id === id);

                if (hasActivePipeline) {
                    useConfirmBeforeWarning(
                        async () => {
                            this.actionLoading[id] = false;
                        },
                        { entity: entity, name: name },
                        () => {
                            this.actionLoading[id] = false;
                        }
                    );
                } else {
                    useConfirmBeforeAction(
                        'delete',
                        async () => {
                            try {
                                await this.destinationsStore.delete(id);
                            } finally {
                                this.actionLoading[id] = false;
                            }
                        },
                        { entity: entity, name: name },
                        () => {
                            this.actionLoading[id] = false;
                        }
                    );
                }
            } catch {
                this.actionLoading[id] = false;
            }
        },
        // async onPause(id) {
        //     try {
        //         this.actionLoading[id] = true;
        //         await this.destinationsStore.pause(id);
        //     } finally {
        //         this.actionLoading[id] = false;
        //     }
        // },
        async onStop(id) {
            try {
                this.actionLoading[id] = true;
                await this.destinationsStore.stop(id);
            } finally {
                this.actionLoading[id] = false;
            }
        },
        async onResume(id) {
            try {
                this.actionLoading[id] = true;
                await this.destinationsStore.resume(id);
            } finally {
                this.actionLoading[id] = false;
            }
        },
        async onRestart(id) {
            try {
                this.actionLoading[id] = true;
                await this.destinationsStore.restart(id);
                await this.destinationsStore.getById(id);
            } finally {
                this.actionLoading[id] = false;
            }
        },
        onClone(destination) {
            this.router.push({
                name: 'new-destination',
                params: { connector: destination.connector },
                query: { cloneConfig: JSON.stringify(destination.config) }
            });
        },
        onViewLogs(destinationId) {
            const route = this.router.resolve({
                name: 'logs',
                query: { destination_id: destinationId }
            });
            window.open(route.href, '_blank');
        },
        getConnectorInfo(connector) {
            return Object.values(this.destinationsStore.destinationConnectors || {})
                .flat()
                .find((c) => c.connector === connector);
        },
        getMetricFormattingInfo,
        formatter,
        getFormattedValue(connectorToSearch, metricKey, dashOnRule = null) {
            const metricValue = this.allLatestMetrics[connectorToSearch.id]?.inline?.[metricKey];

            const connectorInfo = this.getConnectorInfo(connectorToSearch.connector);
            if (!connectorInfo) return { display: '-' };

            const metricFormattingInfo = getMetricFormattingInfo(metricKey, connectorInfo.metrics);

            const formatterParams = [metricValue, metricFormattingInfo || {}];

            if (dashOnRule || dashOnRule === false) {
                formatterParams.push(dashOnRule);
            }

            return this.formatter(...formatterParams);
        },
        onCreateDestination() {
            return this.router.push({ name: 'add-connectors', query: { tab: 'Destinations' } });
        },
        onDestinationSelected(id) {
            return this.router.push(this.selectedDestinationRouterPath(id));
        },
        selectedDestinationRouterPath(id) {
            return { name: 'destination-details', params: { id } };
        },
        async loadDestinations() {
            try {
                if (this.searchQuery || this.searchQuery === '') {
                    this.destinationsPage = 0;
                }

                this.generalLoading = true;
                await this.destinationsStore.fetchCreated(this.destinationsPage, this.destinationsPageSize, this.searchQuery);
                this.generalLoading = false;

                this.metricsLoading = true;
                await Promise.all([
                    this.metricsStore.destinations.fetchAllInlineDestinationMetrics(this.destinationsStore.all?.map((dest) => dest.id)),
                    this.destinationsStore.fetchConnectors()
                ]);
            } finally {
                this.generalLoading = false;
                this.metricsLoading = false;
            }
        },
        loadSources() {
            try {
                this.sourcesStore.fetchCreated(this.sourcesPage, this.sourcesPageSize).then(async () => {
                    await Promise.all([
                        this.metricsStore.sources.fetchAllInlineSourceMetrics(this.sourcesStore.all?.map((source) => source.id)),
                        this.sourcesStore.fetchConnectors()
                    ]);
                });
            } catch (e) {
                console.log(e);
            }
        },
        swapSorting(column, direction) {
            this.sortColumn = column;
            this.sortDirection = direction;
        },
        applySorting(result) {
            return _.orderBy(result, [this.sortColumn], [this.sortDirection ? 'desc' : 'asc']);
        },
        applyFilters(result) {
            return (
                result?.filter(
                    (x) =>
                        (this.selectedFilters[0].length <= 0 || this.selectedFilters[0].includes(x.connector_status)) &&
                        (this.selectedFilters[1].length <= 0 || this.selectedFilters[1].includes(x.connectorDisplayName))
                ) || []
            );
        },
        selectFilter(filter, possibleIndex, filterIndex) {
            if (possibleIndex !== 0) {
                const index = this.selectedFilters[filterIndex].indexOf(filter);
                if (index === -1) {
                    this.selectedFilters[filterIndex].push(filter);
                } else {
                    this.selectedFilters[filterIndex].splice(index, 1);
                }
            } else {
                this.selectedFilters[filterIndex] = [];
            }
            this.triggerFilterSelection = !this.triggerFilterSelection;
        },
        initializeAsyncFilters() {
            this.asyncFilters.forEach((filter) => {
                if (filter.name === 'count') {
                    this.destinationsPageSize = filter.options[filter.defaultIndex].value;
                }
                this.checkedAsyncFilters[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
            });
        },
        onAsyncFilterToggle({ filter, filterOption }) {
            if (filter.name === 'count') {
                this.destinationsPageSize = filterOption.value;
            }
            this.destinationsPage = 0;
            this.loadDestinations();
        },
        onPageChange(newPage) {
            this.destinationsPage = newPage;
            this.loadDestinations();
        },
        onStatusClick(destination) {
            if (this.getStatus(destination) === 'Broken' && destination.task_statuses) {
                const errors = [...new Set(Object.values(destination.task_statuses).map((task) => task.trace))];
                useErrorDialog({
                    errors,
                    width: 65
                });
            }
        }
    }
};
</script>

<template>
    <div class="table-page-container mt-5 mb-5">
        <div class="table-page-body">
            <div class="page-options flex justify-between items-start">
                <div class="flex gap-2 w-1/2">
                    <button
                        v-can-access.permissions="[$Permissions.WRITE_DESTINATIONS]"
                        aria-label="button"
                        class="btn btn-primary rounded-lg"
                        @click="onCreateDestination">
                        <button
                            aria-label="button"
                            type="button"
                            class="inline-flex items-center rounded-lg border border-transparent bg-green-400 p-0.5 text-white shadow-sm hover:bg-green-400 mr-1">
                            <PlusIcon class="h-5 w-5" />
                        </button>
                        <span class="mx-1 align-super font-medium text-sm">Add</span>
                    </button>

                    <SearchInput
                        :loading="generalLoading"
                        placeholder="Search by name..."
                        :custom-class="'w-2/3'"
                        @search="(input) => (searchQuery = input)" />
                </div>
                <div class="page-filters flex gap-2 justify-end w-1/2">
                    <Menu v-for="(filter, index) in filters" :key="index" as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton
                                :class="[
                                    generalLoading ? 'bg-gray-100 opacity-[0.7]' : 'bg-white hover:bg-gray-50',
                                    'inline-flex w-full justify-center items-center px-3 rounded-md border border-gray-300 text-sm font-medium text-gray-700 shadow-sm'
                                ]"
                                style="min-height: 40.5px !important"
                                :disabled="generalLoading">
                                <FunnelIcon class="h-4 w-4 self-center mr-1" />
                                <div v-if="selectedFilters[index].length > 0">
                                    <span
                                        v-for="(selectedFilter, index) in selectedFilters[index]"
                                        :key="index"
                                        class="inline-flex items-center rounded-full bg-gray-100 text-xs font-medium text-gray-800 m-1">
                                        <div class="flex">
                                            <small
                                                v-if="selectedFilter"
                                                class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                :style="`background: ${getStatusColor(selectedFilter)}`"
                                                >{{ selectedFilter }}</small
                                            >
                                            <span v-if="!selectedFilter">{{ selectedFilter }} {{ filter.text }}</span>
                                        </div>
                                    </span>
                                </div>
                                <div v-if="selectedFilters[index].length <= 0">
                                    {{ filter.placeholder }}
                                </div>
                                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" />
                            </MenuButton>
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                            <MenuItems
                                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div v-if="index == 0" class="py-1">
                                    <MenuItem v-for="(possibleFilter, possibleIndex) in filter.values" :key="possibleIndex" v-slot="{}">
                                        <a
                                            href="#"
                                            :class="[
                                                selectedFilters[index].includes(possibleFilter) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            ]"
                                            @click="selectFilter(possibleFilter, possibleIndex, index)">
                                            <div class="flex">
                                                <small
                                                    v-if="possibleFilter"
                                                    class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                    :style="`background: ${getStatusColor(possibleFilter)}`"
                                                    >{{ possibleFilter }}</small
                                                >
                                                <span v-if="!possibleFilter">{{ possibleFilter ? possibleFilter : filter.placeholder }}</span>
                                            </div>
                                        </a>
                                    </MenuItem>
                                </div>
                                <div v-if="index == 1" class="py-1">
                                    <MenuItem v-for="(possibleFilter, possibleIndex) in possibleDestinations" :key="possibleIndex" v-slot="{}">
                                        <a
                                            href="#"
                                            :class="[
                                                selectedFilters[index].includes(possibleFilter) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            ]"
                                            @click="selectFilter(possibleFilter, possibleIndex, index)">
                                            <div class="flex">
                                                <small
                                                    v-if="possibleFilter"
                                                    class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                    :style="`background: ${getStatusColor(possibleFilter)}`"
                                                    >{{ possibleFilter }}</small
                                                >
                                                <span v-if="!possibleFilter">{{ possibleFilter ? possibleFilter : filter.placeholder }}</span>
                                            </div>
                                        </a>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>

                    <DropdownFilters
                        v-model="checkedAsyncFilters"
                        :filters="asyncFilters"
                        :disabled="generalLoading"
                        @on-filter-toggle="onAsyncFilterToggle" />
                </div>
            </div>

            <section class="paginated-section mt-2">
                <AppLoader
                    :force-loading="generalLoading"
                    default-height="20vh"
                    :default-width="'100%'"
                    background="#f9fafb"
                    :default-class="'shadow-md rounded-md'">
                    <div
                        class="relative w-full shadow-md"
                        :class="!searchedDestinations?.length || searchedDestinations?.length > 4 ? 'overflow-auto' : ''">
                        <table class="min-w-full divide-y divide-gray-300 border" aria-label="destinations table">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Name</span>
                                            <span
                                                class="cursor-pointer ml-2 inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('name', !sortDirection)">
                                                <ChevronDownIcon v-if="sortColumn == 'name' && sortDirection == true" class="h-4 w-4" />
                                                <ChevronUpIcon v-if="sortColumn == 'name' && sortDirection == false" class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'name'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Type</span>
                                            <span
                                                class="cursor-pointer ml-2 inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('connector', !sortDirection)">
                                                <ChevronDownIcon v-if="sortColumn == 'connector' && sortDirection == true" class="h-4 w-4" />
                                                <ChevronUpIcon v-if="sortColumn == 'connector' && sortDirection == false" class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'connector'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">
                                            <span>Status/State</span>
                                            <span
                                                class="cursor-pointer ml-2 inline-flex items-center rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                                @click="swapSorting('connector_status', !sortDirection)">
                                                <ChevronDownIcon v-if="sortColumn == 'connector_status' && sortDirection == true" class="h-4 w-4" />
                                                <ChevronUpIcon v-if="sortColumn == 'connector_status' && sortDirection == false" class="h-4 w-4" />
                                                <ChevronUpDownIcon v-if="sortColumn != 'connector_status'" class="h-4 w-4" />
                                            </span>
                                        </div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">Topics</div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">Latency</div>
                                    </th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                        <div class="flex">Lag</div>
                                    </th>
                                    <th class="text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <template v-if="!generalLoading">
                                    <tr
                                        v-for="(destination, destIndex) in searchedDestinations"
                                        :key="destIndex"
                                        class="transition duration-300 ease-in-out hover:bg-gray-50">
                                        <td class="px-6 py-4 whitespace-nowrap cursor-pointer font-medium text-green-500 no-underline">
                                            <div class="flex items-center mr-4 gap-1">
                                                <div
                                                    class="my-auto"
                                                    :class="{
                                                        'whitespace-normal break-words': true,
                                                        'max-w-md': true
                                                    }">
                                                    <router-link
                                                        :to="selectedDestinationRouterPath(destination.id)"
                                                        class="font-medium text-cyan-950 no-underline hover:text-cyan-800">
                                                        {{ destination.name }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex flex-nowrap justify-content-start mr-4">
                                                <img
                                                    width="20"
                                                    height="20"
                                                    class="mr-1"
                                                    :src="`/icons/${destination.connector}.svg`"
                                                    alt="connector" />
                                                <div class="flex flex-row items-center align-center">
                                                    <div class="my-auto">
                                                        {{ destination.connectorDisplayName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>
                                            <div v-else class="flex flex-nowrap justify-content-start mr-4">
                                                <small
                                                    :class="[
                                                        'inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow',
                                                        getStatus(destination) === 'Broken' ? 'cursor-pointer' : ''
                                                    ]"
                                                    :style="`background: ${getStatusColor(getStatus(destination))}`"
                                                    @click="() => onStatusClick(destination)"
                                                    >{{ getStatus(destination) }}
                                                    <InformationCircleIcon v-if="getStatus(destination) === 'Broken'" class="h-4 w-4 ml-1" />
                                                </small>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ destination.topics.length }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>

                                            <div v-else>
                                                <div class="flex flex-nowrap justify-content-start mr-4">
                                                    <div class="my-auto mr-1">
                                                        {{ getFormattedValue(destination, 'latency').display }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div v-if="metricsLoading" class="snippet ml-3" data-title=".dot-flashing">
                                                <div class="stage">
                                                    <div class="dot-flashing" />
                                                </div>
                                            </div>

                                            <div v-else>
                                                <div class="flex flex-nowrap justify-content-start mr-4">
                                                    <div class="my-auto mr-1">
                                                        {{ getFormattedValue(destination, 'recordsLag').display }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                            <TableActionMenu
                                                :show-view="true"
                                                :show-delete="userHasAccess([$Permissions.DELETE_DESTINATIONS], authStore.user)"
                                                :show-clone="userHasAccess([$Permissions.WRITE_DESTINATIONS], authStore.user)"
                                                :show-logs="
                                                    userHasAccess(
                                                        [$Permissions.READ_SOURCE_METRICS, $Permissions.READ_DESTINATION_METRICS],
                                                        authStore.user
                                                    )
                                                "
                                                :display-above="searchedDestinations.length && destIndex >= searchedDestinations.length - 2"
                                                :show-stop="
                                                    userHasAccess([$Permissions.TRIGGER_ACTION_DESTINATIONS], authStore.user) &&
                                                    !(
                                                        getStatus(destination) === 'Paused' ||
                                                        getStatus(destination) === 'Starting' ||
                                                        getStatus(destination) === 'Stopped'
                                                    )
                                                "
                                                :show-resume="
                                                    userHasAccess([$Permissions.TRIGGER_ACTION_DESTINATIONS], authStore.user) &&
                                                    !(
                                                        getStatus(destination) === 'Active' ||
                                                        getStatus(destination) === 'Broken' ||
                                                        getStatus(destination) === 'Starting'
                                                    )
                                                "
                                                :show-restart="
                                                    userHasAccess([$Permissions.TRIGGER_ACTION_DESTINATIONS], authStore.user) &&
                                                    !(
                                                        getStatus(destination) === 'Active' ||
                                                        getStatus(destination) === 'Paused' ||
                                                        getStatus(destination) === 'Stopped'
                                                    )
                                                "
                                                :loading="actionLoading[destination.id]"
                                                :position-relative="!generalLoading"
                                                @stop="onStop(destination.id)"
                                                @resume="onResume(destination.id)"
                                                @restart="onRestart(destination.id)"
                                                @view="onDestinationSelected(destination.id)"
                                                @clone="onClone(destination)"
                                                @delete="onDelete('destination', destination.name, destination.id, destIndex)"
                                                @logs="onViewLogs(destination.id)" />
                                        </td>
                                    </tr>
                                    <tr v-if="!searchedDestinations.length">
                                        <td colspan="99" class="text-center p-2">
                                            <small>No matching records found</small>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </AppLoader>
                <Pagination
                    v-if="destinationsStore.destinations?.length || destinationsPage > 0"
                    :page="destinationsPage"
                    :page-size="destinationsPageSize"
                    :current-page-items-count="destinationsStore.destinations?.length"
                    :total-items-count="destinationsStore.total"
                    :disable-navigation="generalLoading"
                    :message="{
                        show: searchedDestinations.length === destinationsStore.destinations?.length && !generalLoading,
                        showCustomMessage: searchedDestinations.length !== destinationsStore.destinations?.length && !generalLoading,
                        firstPart: 'Showing destinations',
                        middlePart: 'to',
                        lastPart: `of ${destinationsStore.total}`
                    }"
                    @page-change="onPageChange">
                    <template #customMessage>
                        Showing
                        <span class="font-medium">{{ searchedDestinations.length }}</span>
                        {{ searchedDestinations.length === 1 ? 'match' : 'matches' }}
                        on this page
                    </template>
                </Pagination>
            </section>
        </div>
    </div>
</template>

<style scoped>
/* When the container is expanded */
.my-auto.whitespace-normal {
    min-height: unset; /* Remove the minimum height so it can expand */
    max-height: none; /* Ensure it's not constrained by max-height */
}
</style>
