<template>
    <div class="mx-auto w-full mb-5">
        <div class="page-header">
            <AppHeader />
        </div>
        <!-- <dl
                class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow lg:grid-cols-1 lg:divide-x lg:divide-y-0">
                <div class="p-4 px-6">
                    <div class="flex justify-between items-center">
                        <div>
                            <dt class="text-base font-normal text-gray-600">Usage this billing cycle</dt>
                            <dd class="mt-1 flex items-baseline gap-2 md:block lg:flex">
                                <div class="flex items-baseline text-2xl font-medium text-green-600">
                                    $430
                                    <span class="ml-2 text-sm font-medium text-gray-600">( previous month $365.5 )</span>
                                </div>

                                <div
                                    class="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium bg-blue-100 text-blue-800 md:mt-2 lg:mt-0">
                                    <ArrowUpIcon class="mr-0.5 flex-shrink-0 self-center h-4 w-4 text-green-600" />
                                    <span class="sr-only"> Increased by </span>
                                    12%
                                </div>
                            </dd>
                        </div>
                        <button
                            aria-label="button"
                            type="button"
                            class="rounded-md bg-green-400 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400">
                            Billing
                        </button>
                    </div>
                </div>
                <div class="p-4 px-6">
                    <div class="flex justify-between items-center">
                        <div>
                            <dt class="text-base font-normal text-gray-600">Services</dt>
                            <dd class="mt-1 flex items-baseline gap-2 md:block lg:flex">
                                <div class="flex items-baseline text-2xl font-medium text-green-600">
                                    2
                                    <span class="ml-2 text-sm font-medium text-green-500">( 2/3 running )</span>
                                </div>
                            </dd>
                        </div>
                        <button
                            aria-label="button"
                            type="button"
                            class="rounded-md bg-green-400 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400">
                            Manage
                        </button>
                    </div>
                </div>
            </dl> -->
        <div class="mx-auto w-full">
            <div class="mb-5">
                <dl class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4">
                    <div
                        v-for="(stat, index) in generalStats"
                        :key="index"
                        class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                        <dt>
                            <div :class="`${stat.backgroundColorClass} absolute rounded-md p-3 flex items-center`">
                                <component :is="stat.icon" :class="`h-6 w-6 text-white`" />
                            </div>
                            <p class="ml-16 truncate text-sm font-medium text-gray-600">{{ stat.name }}</p>
                        </dt>
                        <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <div class="text-2xl font-medium text-gray-900">
                                <span v-if="!orgStatisticsLoading">{{ stat.value }} </span>
                                <div v-else class="snippet pl-2 pt-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>

                            <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div v-if="!orgStatisticsLoading" class="flex justify-between">
                                    <div v-if="stat.button?.label" class="text-sm">
                                        <a href="#" class="font-medium text-gray-700 hover:text-green-400" @click="stat.button.onClick">{{
                                            stat.button.label
                                        }}</a>
                                    </div>
                                    <div v-if="stat.info?.length" class="text-sm flex">
                                        <span
                                            v-for="(info, index) in stat.info"
                                            :key="index"
                                            :class="`ml-2 flex items-baseline text-sm font-medium ${info.colorClass} ${info.value ? '' : 'hidden'}`">
                                            {{ info.value }} {{ info.label }}
                                            <p
                                                v-if="stat.info?.filter((i) => i.value)?.length > 1 && index < stat.info?.length - 1"
                                                class="ml-2 flex items-baseline text-sm font-medium text-gray-600">
                                                /
                                            </p>
                                        </span>
                                    </div>
                                </div>
                                <div v-else class="snippet ml-3" data-title=".dot-flashing">
                                    <div class="stage">
                                        <div class="dot-flashing" />
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>
            <div class="mt-10 mb-2">
                <AppLoader :force-loading="globalSourceConfigsLoading || globalDestinationConfigsLoading" default-height="50vh" background="#f9fafb">
                    <div v-if="!globalSourceConfigsLoading && !globalDestinationConfigsLoading">
                        <div class="flex justify-between items-end mb-3">
                            <h3 class="font-semibold">Dashboard Metrics</h3>
                        </div>

                        <div class="rounded-lg bg-gray-50 shadow p-5">
                            <div class="flex items-center justify-between">
                                <DateFilter
                                    :selected="selectedUsageTimeseriesDateFilter?.key || 'last24h'"
                                    :disabled="timeseriesLoading"
                                    :hide-options="['date', 'dateRange', 'today', 'allTime', 'lastWeek', 'lastMonth', 'thisWeek', 'thisMonth']"
                                    @input="fetchUsageTimeseries" />
                                <div class="flex items-center justify-end w-1/3">
                                    <multiselect
                                        v-model="allSelectedMetricsForUsageChart"
                                        class="inputRight"
                                        :options="listOfUsageMetrics"
                                        :multiple="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :preserve-search="true"
                                        :placeholder="
                                            timeseriesLoading ? 'Loading...' : listOfUsageMetrics?.length ? 'Select metrics' : 'No metrics available'
                                        "
                                        :select-label="'Select'"
                                        :deselect-label="'Remove'"
                                        :loading="timeseriesLoading"
                                        :disabled="timeseriesLoading || !listOfUsageMetrics?.length"
                                        label="name"
                                        track-by="key">
                                        <template #selection="{ values, isOpen }"
                                            ><span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{
                                                values.length > 1 ? `${values.length} metrics selected` : values[0]?.name
                                            }}</span></template
                                        >
                                    </multiselect>
                                    <button
                                        aria-label="button"
                                        class="bg-cyan-950 hover:bg-cyan-800 text-white rounded-r-md px-4 flex items-center h-auto"
                                        :style="allSelectedMetricsForUsageChart?.length ? 'min-height: 42px' : 'min-height: 38px'"
                                        :disabled="timeseriesLoading"
                                        @click.stop="fetchUsageTimeseries()">
                                        <ArrowPathIcon class="h-4 w-4 p-0" />
                                    </button>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="mt-4 shadow rounded-lg">
                                    <AppLoader :force-loading="timeseriesLoading" background="#f9fafb" default-height="35vh">
                                        <HighChart :chart-options="chartOptions" />
                                    </AppLoader>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppLoader>
            </div>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash';
import AppHeader from '../../components/AppHeader.vue';
import AppLoader from '../../components/AppLoader.vue';
import { computed, ref, onMounted, watch } from 'vue';
import { ShareIcon, QueueListIcon, AdjustmentsVerticalIcon, ArrowsUpDownIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useRouter, useRoute } from 'vue-router';
import DateFilter from '@/components/global/DateFilter.vue';
import HighChart from '../../components/HighChart.vue';
import { formatter, getMetricFormattingInfo } from '../../utils/utils';
import { useMetricsStore } from '../../stores/metrics.store';
import { DATE_FILTER_OPTIONS } from '../../utils/constants';
import moment from 'moment-timezone';
import Multiselect from 'vue-multiselect';

const router = useRouter();
const route = useRoute();
const metricsStore = useMetricsStore();

const orgStatisticsLoading = ref(false);
const timeseriesLoading = ref(false);
const globalSourceConfigsLoading = ref(false);
const globalDestinationConfigsLoading = ref(false);

const selectedUsageTimeseriesDateFilter = ref(null);
const orgStats = ref({});
const allSelectedMetricsForUsageChart = ref([]);

const usageTimeseriesData = ref([]);

onMounted(async () => {
    orgStatisticsLoading.value = true;
    timeseriesLoading.value = true;
    globalSourceConfigsLoading.value = true;
    globalDestinationConfigsLoading.value = true;

    // timeseries related queries
    const selectedUsageTimeseriesMetricsQuery = route.query.selectedUsageTimeseriesMetrics;
    const selectedUsageTimeseriesDateFilterQuery = route.query.selectedUsageTimeseriesDateFilter;

    allSelectedMetricsForUsageChart.value = selectedUsageTimeseriesMetricsQuery
        ? JSON.parse(selectedUsageTimeseriesMetricsQuery)
        : allSelectedMetricsForUsageChart.value;
    selectedUsageTimeseriesDateFilter.value = selectedUsageTimeseriesDateFilterQuery
        ? JSON.parse(selectedUsageTimeseriesDateFilterQuery)
        : selectedUsageTimeseriesDateFilter.value;

    try {
        await Promise.all([
            metricsStore.organization.fetchOrgStatistics().then((data) => {
                orgStats.value = data;
                orgStatisticsLoading.value = false;
            }),
            metricsStore.sources.fetchGlobalSourceConfigs().then(() => {
                globalSourceConfigsLoading.value = false;
            }),
            metricsStore.destinations.fetchGlobalDestinationConfigs(() => {
                globalDestinationConfigsLoading.value = false;
            }),
            fetchUsageTimeseries(selectedUsageTimeseriesDateFilter.value)
        ]);
    } catch (error) {
        console.log(error);
    } finally {
        orgStatisticsLoading.value = false;
        timeseriesLoading.value = false;
        globalSourceConfigsLoading.value = false;
        globalDestinationConfigsLoading.value = false;
    }
});

const listOfUsageMetrics = computed(() => {
    let sourceMetricsKeys = Object.keys(usageTimeseriesData.value?.sources?.account?.usage || {});
    let destinationMetricsKeys = Object.keys(usageTimeseriesData.value?.destinations?.account?.usage || {});

    sourceMetricsKeys = sourceMetricsKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, metricsStore.sources.globalConfigs?.metrics);

            if (!metricInfo) {
                return null;
            }

            return {
                ...metricInfo,
                key,
                type: 'source',
                name: `Sources - ${metricInfo.name}`
            };
        })
        .filter((x) => x && usageTimeseriesData.value?.sources?.account?.usage?.[x.key]?.length);

    destinationMetricsKeys = destinationMetricsKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, metricsStore.destinations.globalConfigs?.metrics); // TODO: Change to destinations

            if (!metricInfo) {
                return null;
            }

            return {
                ...metricInfo,
                key,
                type: 'destination',
                name: `Destinations - ${metricInfo.name}`
            };
        })
        .filter((x) => x && usageTimeseriesData.value?.destinations?.account?.usage?.[x.key]?.length);

    const keysToReturn = [...sourceMetricsKeys, ...destinationMetricsKeys];

    if (
        !keysToReturn?.length ||
        (keysToReturn?.length && allSelectedMetricsForUsageChart.value?.map((x) => x.key).some((x) => !keysToReturn.map((y) => y.key).includes(x)))
    ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        allSelectedMetricsForUsageChart.value = [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        delete route.query.selectedBillingTimeseriesMetrics;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        router.push(route.query);
    }

    return _.orderBy(keysToReturn, 'name', 'asc');
});

watch(
    () => listOfUsageMetrics.value,
    (newValue) => {
        const defaultTo = ['recordSendTotal', 'sinkRecordSendTotal'];

        if (!allSelectedMetricsForUsageChart.value.length && newValue.length) {
            allSelectedMetricsForUsageChart.value = newValue.filter((x) => defaultTo.includes(x.key));

            if (!allSelectedMetricsForUsageChart.value.length) {
                allSelectedMetricsForUsageChart.value = [newValue[0]];
            }
        }
    }
);

watch(
    () => allSelectedMetricsForUsageChart.value,
    (newValue) => {
        if (newValue?.filter((x) => x)?.length) {
            router.push({ query: { ...route.query, selectedUsageTimeseriesMetrics: JSON.stringify(newValue) } });
        } else {
            delete route.query.selectedUsageTimeseriesMetrics;
            router.push({ query: route.query });
        }
    },
    { deep: true }
);

const generalStats = computed(() => {
    const goodStatuses = ['Active', 'Paused', 'Starting', 'Stopped'];
    const badStatuses = ['Broken', 'Unknown'];
    const allStatuses = [...goodStatuses, ...badStatuses];

    return [
        {
            id: 2,
            name: 'Pipelines',
            value: _.sum(allStatuses.map((status) => orgStats.value?.pipelines?.[status] || 0)),
            icon: ArrowsUpDownIcon,
            backgroundColorClass: 'bg-cyan-950',
            info: [
                {
                    value: _.sum(badStatuses.map((status) => orgStats.value?.pipelines?.[status] || 0)),
                    label: 'broken',
                    colorClass: 'text-red-600'
                },
                {
                    value: _.sum(goodStatuses.map((status) => orgStats.value?.pipelines?.[status] || 0)),
                    label: 'active',
                    colorClass: 'text-green-400'
                }
            ],
            button: {
                label: 'View all',
                onClick: () => router.push({ name: 'pipelines' })
            }
        },
        {
            id: 0,
            name: 'Connectors',
            value:
                _.sum(allStatuses.map((status) => orgStats.value?.destinations?.[status] || 0)) +
                _.sum(allStatuses.map((status) => orgStats.value?.sources?.[status] || 0)),
            icon: ShareIcon,
            backgroundColorClass: 'bg-cyan-950',
            info: [
                {
                    value:
                        _.sum(badStatuses.map((status) => orgStats.value?.destinations?.[status] || 0)) +
                        _.sum(badStatuses.map((status) => orgStats.value?.sources?.[status] || 0)),
                    label: 'broken',
                    colorClass: 'text-red-600'
                },
                {
                    value:
                        _.sum(goodStatuses.map((status) => orgStats.value?.destinations?.[status] || 0)) +
                        _.sum(goodStatuses.map((status) => orgStats.value?.sources?.[status] || 0)),
                    label: 'active',
                    colorClass: 'text-green-400'
                }
            ],
            button: {
                label: 'View all',
                onClick: () => router.push({ name: 'connectors' })
            }
        },
        {
            id: 3,
            name: 'Topics',
            value: orgStats.value?.topics || 0,
            icon: QueueListIcon,
            backgroundColorClass: 'bg-cyan-950'
            // button: {
            //     label: 'View all',
            //     onClick: () => router.push({ name: 'topics' })
            // }
        },
        {
            id: 4,
            name: 'Transforms',
            value: orgStats.value?.transforms || 0,
            icon: AdjustmentsVerticalIcon,
            backgroundColorClass: 'bg-cyan-950'
            // button: {
            //     label: 'View all',
            //     onClick: () => router.push({ name: 'transforms' })
            // }
        }
    ];
});

const fetchUsageTimeseries = async (specificDateFilter) => {
    timeseriesLoading.value = true;
    selectedUsageTimeseriesDateFilter.value = specificDateFilter || selectedUsageTimeseriesDateFilter.value;

    const defaultDateFilter =
        selectedUsageTimeseriesDateFilter.value?.key === 'last24h' ||
        !selectedUsageTimeseriesDateFilter.value ||
        !selectedUsageTimeseriesDateFilter.value?.key;
    const dateFilterToUse = defaultDateFilter
        ? undefined
        : { ...selectedUsageTimeseriesDateFilter.value?.value, key: selectedUsageTimeseriesDateFilter.value?.key };

    const queryInfo = { query: { ...route.query, selectedUsageTimeseriesDateFilter: JSON.stringify(dateFilterToUse) } };

    if (dateFilterToUse?.key) {
        router.push(queryInfo);
    } else {
        delete queryInfo.query.selectedUsageTimeseriesDateFilter;
        router.push(queryInfo);
    }

    try {
        const timeseriesData = await metricsStore.organization.fetchUsageTimeseriesMetricsForAllConnectors(
            defaultDateFilter ? undefined : { ...selectedUsageTimeseriesDateFilter.value?.value, key: selectedUsageTimeseriesDateFilter.value?.key }
        );

        usageTimeseriesData.value = timeseriesData;

        return timeseriesData;
    } catch (err) {
        console.log(err, 'err');
    } finally {
        timeseriesLoading.value = false;
    }
};

const chartOptions = computed(() => {
    const titleText = DATE_FILTER_OPTIONS.find((option) => option.value === selectedUsageTimeseriesDateFilter.value?.key)?.label || 'Last 24h';

    const metricKeys =
        _.cloneDeep(allSelectedMetricsForUsageChart.value)
            ?.map((x) => (x.key ? { key: x.key, type: x.type, name: x.name } : null))
            .filter((x) => x) || [];

    const dataForEachSelectedMetric = metricKeys
        .map(({ key, type, name }) => {
            const sourcesMetricData = usageTimeseriesData.value?.sources?.account?.usage[key];
            const destinationsMetricData = usageTimeseriesData.value?.destinations?.account?.usage[key];

            if (sourcesMetricData?.length && destinationsMetricData?.length) {
                return [
                    {
                        metricKey: key,
                        metricData: sourcesMetricData,
                        metricInfo: {
                            ...getMetricFormattingInfo(
                                key,
                                type === 'source' ? metricsStore.sources.globalConfigs?.metrics : metricsStore.destinations.globalConfigs?.metrics
                            ),
                            name
                        }
                    },
                    {
                        metricKey: key,
                        metricData: destinationsMetricData,
                        metricInfo: {
                            ...getMetricFormattingInfo(
                                key,
                                type === 'source' ? metricsStore.sources.globalConfigs?.metrics : metricsStore.destinations.globalConfigs?.metrics
                            ),
                            name
                        }
                    }
                ];
            }

            return {
                metricKey: key,
                metricData: sourcesMetricData || destinationsMetricData,
                metricInfo: {
                    ...getMetricFormattingInfo(
                        key,
                        type === 'source' ? metricsStore.sources.globalConfigs?.metrics : metricsStore.destinations.globalConfigs?.metrics
                    ),
                    name
                }
            };
        })
        .flat();

    const seriesAndYAxes = dataForEachSelectedMetric.map((metric, index) => {
        const yAxis = {
            title: {
                text: metric.metricInfo?.name
            },
            labels: {
                formatter: function () {
                    return formatter(this.value, { unit: metric.metricInfo?.unit }, false).display;
                }
            },
            opposite: index % 2 !== 0
        };

        const series = {
            name: metric.metricInfo?.name,
            data: metric.metricData?.map((dataItem) => {
                const localTime = moment.utc(dataItem.timestamp).tz(moment.tz.guess()).toDate();
                return {
                    x: localTime,
                    y: dataItem.value
                };
            }),
            type: 'line',
            yAxis: index,
            custom: {
                unit: metric.metricInfo?.unit
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter(this.y, { unit: metric.metricInfo?.unit }, false).display;
                }
            }
        };

        return { series, yAxis };
    });

    // Split the series and yAxis configurations
    const series = seriesAndYAxes.map((sy) => sy.series);
    const yAxis = seriesAndYAxes.map((sy) => sy.yAxis);

    const options = {
        time: {
            timezone: moment.tz.guess()
        },
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: titleText
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    // Get the current date and the date from the axis in the browser's timezone
                    const now = moment.utc(new Date()).tz(moment.tz.guess());
                    const axisDate = moment.utc(new Date(this.value)).tz(moment.tz.guess());

                    // Compare dates to determine formatting
                    if (now.format('YYYYMMDD') === axisDate.format('YYYYMMDD')) {
                        // Current day: show hour only
                        return axisDate.format('HH:mm');
                    } else if (now.format('YYYYMM') === axisDate.format('YYYYMM')) {
                        // Current month, different day: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else if (now.format('YYYY') === axisDate.format('YYYY')) {
                        // Different month, same year: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else {
                        // Different year: show day, month (short name), year, and hour
                        return axisDate.format('DD MMM YYYY HH:mm');
                    }
                }
            }
        },
        yAxis: yAxis,
        series: series,
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function () {
                const localTime = moment(new Date(this.x)).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

                let tooltip = `${localTime}<br>`;
                this.points.forEach((point) => {
                    tooltip += `<b>${point.series.name}:</b> ${
                        formatter(point.y, { unit: point.series.userOptions.custom?.unit }, false).display
                    }<br>`;
                });
                return tooltip;
            }
        }
    };

    return options;
});
</script>

<style>
/* Custom CSS for aspect ratio */
.aspect-square {
    position: relative;
}
</style>
