<script>
import RedirectIntegrationType from './IntegrationTypes/RedirectIntegrationType.vue';
import InputIntegrationType from './IntegrationTypes/EmailIntegrationType.vue';
import WebhookIntegrationType from './IntegrationTypes/WebhookIntegrationType.vue';

export default {
    components: {
        RedirectIntegrationType,
        InputIntegrationType,
        WebhookIntegrationType
    },
    props: {
        integration: {
            type: Object,
            required: true
        },
        subscriberId: {
            type: String,
            default: null
        }
    }
};
</script>

<template>
    <div v-if="integration">
        <RedirectIntegrationType
            v-if="integration.type == 'redirect'"
            :key="integration.title"
            :title="integration.title"
            :logo="integration.logo"
            :placeholder="integration.placeholder"
            :url="integration.url"
            :is-connected="integration.isConnected"
            :subscriber-id="subscriberId"
            :provider-id="integration.providerId"
            :is-polling="integration.isPolling"
            @update:isConnected="($event) => $emit('update:isConnected', $event)"
            @update:isPolling="($event) => $emit('update:isPolling', $event)"
            @update:subscriber="($event) => $emit('update:subscriber', $event)" />

        <InputIntegrationType
            v-if="integration.type == 'input'"
            :key="integration.title"
            aria-label="integration-item"
            :title="integration.title"
            :logo="integration.logo"
            :placeholder="integration.placeholder"
            :set-input="integration.setInput"
            :is-connected="integration.isConnected"
            :subscriber-id="subscriberId"
            :provider-id="integration.providerId"
            @update:isConnected="($event) => $emit('update:isConnected', $event)"
            @update:setInput="($event) => $emit('update:setInput', $event)"
            @update:subscriber="($event) => $emit('update:subscriber', $event)" />

        <WebhookIntegrationType
            v-if="integration.type == 'webhook'"
            :key="integration.title"
            :title="integration.title"
            :logo="integration.logo"
            :placeholder="integration.placeholder"
            :webhook="integration.webhook"
            :is-connected="integration.isConnected"
            :subscriber-id="subscriberId"
            :provider-id="integration.providerId"
            @update:isConnected="($event) => $emit('update:isConnected', $event)"
            @update:webhook="($event) => $emit('update:webhook', $event)"
            @update:subscriber="($event) => $emit('update:subscriber', $event)" />
    </div>
</template>