import TopicsPage from '@/views/topics/TopicsPage.vue';
import { Permissions } from '../utils/constants';
import TopicDetails from '@/views/topics/TopicDetails.vue';

export default [
    {
        path: '/topics',
        name: 'topics',
        component: TopicsPage,
        meta: {
            title: 'Topics',
            requireAuth: true,
            accessRules: {
                permissions: {
                    values: [Permissions.READ_TOPICS, Permissions.READ_TOPIC_METRICS],
                    type: 'and'
                }
            }
        }
    },
    {
        path: '/topics/:topic_id',
        name: 'topic-details',
        props: (route) => ({
            topic_id: route.params.topic_id
        }),
        component: TopicDetails,
        meta: {
            title: 'Topic Details',
            requireAuth: true
        }
    }
];
