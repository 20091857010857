<script>
import { AdminPortal, mapAuthActions } from '@frontegg/vue';
import _ from 'lodash';
import { ref } from 'vue';
import { ChevronRightIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useAuthStore } from '../stores/auth.store';
import AccountSwitcher from './SideMenu/AccountSwitcher.vue';
import Config from '@/utils/config';

export default {
    name: 'SideMenuProfileSection',
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ChevronRightIcon,
        ChevronUpIcon,
        AccountSwitcher
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            switchTenant: mapAuthActions('switchTenant'),
            ...this.mapAuthState(),
            enabled: ref(true),
            Config
        };
    },
    computed: {
        availableTenants() {
            return _.get(this.authState, ['tenantsState', 'tenants'], []);
        },
        currentTenant() {
            return (
                _.find(this.availableTenants, {
                    tenantId: this.authState.user?.tenantId
                }) || {}
            );
        }
    },
    mounted() {
        this.authStore = useAuthStore();
    },

    methods: {
        logout() {
            this.$router.push('/account/logout');
        },
        showAdminPortal(url) {
            AdminPortal.show();
            setTimeout(() => (window.location.href = url), 1);
        },
        goToBilling() {
            this.$router.push('/billing');
        },
        goToServices() {
            this.$router.push('/services');
        }
    }
};
</script>

<template>
    <div class="flex flex-shrink-0 border-t border-gray-200 pt-4 pb-5 2xl:px-3 px-1">
        <a href="#" class="group block w-full flex-shrink-0">
            <div class="flex items-center">
                <Menu as="div" class="mt-3 relative inline-block w-full">
                    <div>
                        <MenuButton class="rounded-full link-profile inline-flex w-full bg-white text-sm font-medium font-medium">
                            <div class="flex items-center flex-wrap justify-center w-full">
                                <div>
                                    <img
                                        class="inline-block h-[40px] w-[40px] rounded-full border-2 border-cyan-950 w-fu"
                                        :src="authState.user.profilePictureUrl"
                                        alt="profile" />
                                </div>
                                <div class="mx-2 flex flex-1 items-center">
                                    <span class="text-sm text-gray-600 pl-1">
                                        {{ authState.user.name }}
                                        <!-- <div class="text-xs text-gray-500 flex gap-1 items-center text-left w-full px-3">
                                            {{ currentTenant.name }}
                                        </div> -->
                                    </span>
                                    <ChevronRightIcon v-if="!isMobile" class="h-5 w-5 text-gray-600 ml-auto" />
                                    <ChevronUpIcon v-if="isMobile" class="h-5 w-5 text-gray-600 ml-auto" />
                                </div>
                            </div>
                        </MenuButton>
                    </div>
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                        <MenuItems
                            :class="[
                                isMobile ? 'bottom-[4rem] right-[-0.75rem]' : 'bottom-[0.25rem] left-[100%]',
                                'absolute w-[12rem] z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                            ]">
                            <div class="py-1">
                                <AccountSwitcher />
                            </div>
                            <div class="py-1">
                                <!-- <MenuItem v-slot="{ active }">
                                    <a
                                        href="#"
                                        :class="[
                                            active
                                                ? 'bg-gray-100 font-medium'
                                                : 'text-gray-600',
                                            'block px-4 py-2 text-sm'
                                        ]"
                                        @click="showAdminPortal('#/admin-box/')"
                                        >Profile</a
                                    >
                                </MenuItem> -->
                                <!-- <MenuItem v-slot="{ active }">
                                    <a
                                        href="#"
                                        :class="[
                                            active
                                                ? 'bg-gray-100 font-medium'
                                                : 'text-gray-600',
                                            'block px-4 py-2 text-sm'
                                        ]"
                                        @click="showAdminPortal('#/admin-box/account')"
                                        >Organization</a
                                    >
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a
                                        href="#"
                                        :class="[
                                            active
                                                ? 'bg-gray-100 font-medium'
                                                : 'text-gray-600',
                                            'block px-4 py-2 text-sm'
                                        ]"
                                        @click="showAdminPortal('#/admin-box/users')"
                                        >Team</a
                                    >
                                </MenuItem> -->
                                <MenuItem v-slot="{ active }" v-can-access.permissions.or="[$Permissions.READ_SERVICES]">
                                    <router-link
                                        :to="{ name: 'services' }"
                                        :class="[active ? 'bg-gray-100 text-gray-700' : 'text-gray-600', 'block px-4 py-2 text-sm']"
                                        @click="goToServices"
                                        >Services</router-link
                                    >
                                </MenuItem>
                                <MenuItem
                                    v-slot="{ active }"
                                    v-can-access.permissions.and="[
                                        $Permissions.FE_SUBSCRIPTIONS_READ,
                                        $Permissions.READ_ORGANISATION,
                                        $Permissions.READ_SOURCE_METRICS,
                                        $Permissions.READ_DESTINATION_METRICS
                                    ]">
                                    <router-link
                                        :to="{ name: 'billing' }"
                                        :class="[active ? 'bg-gray-100 text-gray-700' : 'text-gray-600', 'block px-4 py-2 text-sm']"
                                        @click="goToBilling"
                                        >Billing</router-link
                                    >
                                </MenuItem>

                                <MenuItem v-slot="{ active }">
                                    <a
                                        href="#"
                                        :class="[active ? 'bg-gray-100 text-gray-700' : 'text-gray-600', 'block px-4 py-2 text-sm']"
                                        @click="showAdminPortal('#/admin-box/privacy')"
                                        >Settings</a
                                    >
                                </MenuItem>
                                <!-- <MenuItem v-slot="{ active }">
                                    <a
                                        href="#"
                                        :class="[
                                            active
                                                ? 'bg-gray-100 font-medium'
                                                : 'text-gray-600',
                                            'block px-4 py-2 text-sm'
                                        ]"
                                        @click="showAdminPortal('#/admin-box/subscriptions')"
                                        >Billing</a
                                    >
                                </MenuItem> -->
                            </div>
                            <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <a
                                        href="#"
                                        :class="[active ? 'bg-gray-100 text-gray-700' : 'text-gray-600', 'block px-4 py-2 text-sm']"
                                        @click="logout"
                                        >Log Out</a
                                    >
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </a>
    </div>
</template>
