<template>
    <div class="min-h-screen">
        <div class="mx-auto">
            <div class="page-header">
                <AppHeader :show-title="false">
                    <template #titleRight>
                        <nav class="text-gray-700" aria-label="Breadcrumb">
                            <ol class="list-none p-0 inline-flex font-semibold">
                                <li class="flex items-center">
                                    <router-link to="/topics" class="text-green-400 hover:text-green-500">Topics</router-link>
                                    <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                    <div class="flex flex-row align-center items-center gap-3">
                                        <h3 class="text-lg font-medium text-gray-900 flex flex-col items-left">
                                            {{ topic.name || 'Loading...' }}
                                            <span v-if="topic.id" class="text-sm text-gray-500">ID: {{ topic.id }}</span>
                                        </h3>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </template>
                </AppHeader>
            </div>

            <!-- Stats Section -->
            <div class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mb-10">
                <StatCard
                    v-for="stat in stats"
                    :title="stat.name"
                    :value="stat.stat"
                    :icon="stat.icon"
                    :icon-bg-class="stat.colorClass"
                    :loading="topicLoading || configLoading" />
            </div>

            <!-- Search and Filters -->
            <div class="w-full flex mb-2 mt-6" style="min-height: 60px !important">
                <div class="w-full flex items-center justify-end gap-5">
                    <!-- <DateFilter @input="handleDateFilterInput" /> -->
                    <BasicDropdown
                        class="justify-end"
                        :selected="sortingSelected"
                        :options="sortingOptions"
                        :loading="messagesLoading"
                        :placeholder="'Sort by'"
                        @input="
                            sortingSelected = $event;
                            fetchTopicsMessages(null, null, $event);
                        ">
                    </BasicDropdown>
                    <DropdownFilters
                        v-model="checkedFilters"
                        slim-dropdown-button
                        :filters="filters"
                        :disabled="messagesLoading"
                        @on-filter-toggle="onMessagesFilterToggle" />
                </div>
            </div>

            <!-- Chart for Topic Data -->
            <!-- <div class="bg-white p-4 rounded-lg shadow mb-6">
                        <div class="flex gap-2 items-center">
                            <ChevronUpIcon
                                v-if="showChart"
                                class="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-800"
                                @click="showChart = !showChart" />
                            <ChevronDownIcon
                                v-else
                                class="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-800"
                                @click="showChart = !showChart" />
                            <h3 v-if="!showChart">{{ chartOptions.title.text }}</h3>
                        </div>
                        <HighChart v-if="showChart" :chart-options="chartOptions" />
                    </div> -->

            <TopicMessagesTable
                :messages="messages"
                :page-size="topicsStore.messagesPageSize"
                :unselect-all="!selectedMessage"
                @select-message="selectMessage"
                :loading="messagesLoading"
                :total-messages="topicsStore.messagesPageTotal" />

            <!-- Side Popup for Selected Message -->
            <TopicMessageDetailsDrawer :message="selectedMessage" @close="selectMessage(null)" />

            <!-- Tab Navigation -->
            <!-- <Tabs :tabs="tabs" @tab-change="setCurrentTab">

               <template #Schema>
                    <TopicColumnSchema :columns="topic?.columns" @hash="onChangeHashColumnState" @exclude="onChangeExcludeColumnState" />
                </template>
            </Tabs> -->
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, isRef } from 'vue';
import { ExclamationCircleIcon, ChevronDoubleLeftIcon, ScaleIcon, QueueListIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '../../components/AppFooter.vue';
import HighChart from '../../components/HighChart.vue';
import StatCard from '../../components/global/StatCard.vue';
import TopicMessagesTable from '../../components/Topics/TopicMessagesTable.vue';
import TopicMessageDetailsDrawer from '../../components/Topics/TopicMessageDetailsDrawer.vue';
import { useRoute, useRouter } from 'vue-router';
import { useTopicsStore } from '../../stores/topics.store';
import SearchInput from '@/components/global/SearchInput.vue';
import DropdownFilters from '@/components/DropdownFilters.vue';
import DateFilter from '@/components/global/DateFilter.vue';
import RowActionMenu from '../../components/RowActionMenu.vue';
import TopicColumnSchema from '@/components/Topics/TopicColumnSchema.vue';
import Tabs from '@/components/global/Tabs.vue';
import { formatter, getMetricFormattingInfo } from '../../utils/utils';
import BasicDropdown from '@/components/BasicDropdown.vue';
import _ from 'lodash';

const route = useRoute();
const router = useRouter();

const topicsStore = useTopicsStore();
const topic = ref({});
const defaultTopic = ref({});

const messagesPageSize = ref(0);
const messagesPage = ref(0);

const messages = ref([]);
const topicLoading = ref(false);
const messagesLoading = ref(false);
const configLoading = ref(false);
const openSortDropdown = ref(false);
const showChart = ref(true);
const sortingSelected = ref('asc');

const sortingOptions = ref([
    {
        name: 'Newest First',
        value: 'asc'
    },
    {
        name: 'Oldest First',
        value: 'desc'
    }
]);

const tabs = ['General', 'Schema'];

const currentTab = ref('');

const setCurrentTab = (str) => {
    if (str === 'Logs') {
        return;
    }

    currentTab.value = str;

    router.push({ query: { tab: currentTab.value } });
};

setCurrentTab(route.query.tab || 'Status');

const toggleDropdown = () => {
    openSortDropdown.value = !openSortDropdown.value;
};

const chartOptions = ref({
    chart: {
        type: 'column',
        height: '300px'
    },
    title: {
        text: 'Message Volume Over Time'
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Volume'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} messages</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            dataLabels: {
                enabled: true
            }
        }
    },
    series: [
        {
            name: 'Messages',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4], // Mock data
            borderRadiusTopLeft: '15%',
            borderRadiusTopRight: '15%',
            color: '#958cee'
        }
    ]
});

let checkedFilters = ref([]);

const filters = [
    {
        title: 'Messages per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
        ]
    }
];

const stats = computed(() => {
    const [volume, errors, eventsSent, written] = ['byteTotal', 'recordErrorTotal', 'recordSendTotal', 'recordWrittenTotal'].map((metricKey) => {
        return {
            metric: metricKey,
            displayValue: getFormattedValue(metricKey, topic.value?.metrics?.[metricKey]).display
        };
    });

    return [
        {
            id: 3,
            name: 'Written',
            stat: written.displayValue,
            icon: QueueListIcon,
            colorClass: 'bg-blue-400'
        },
        {
            id: 0,
            name: 'Volume',
            stat: volume.displayValue,
            icon: ScaleIcon,
            colorClass: 'bg-purple-400'
        },
        {
            id: 1,
            name: 'Errors',
            stat: errors.displayValue,
            icon: ExclamationCircleIcon,
            colorClass: 'bg-red-500'
        },
        {
            id: 2,
            name: 'Events',
            stat: eventsSent.displayValue,
            icon: ChevronDoubleLeftIcon,
            colorClass: 'bg-green-500'
        }
    ];
});

function initializeTopicMessagesFilters() {
    filters.forEach((filter) => {
        if (filter.name === 'count') {
            messagesPageSize.value = filter.options[filter.defaultIndex].value;
        }
        checkedFilters.value[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

async function onMessagesFilterToggle({ filter, filterOption }) {
    try {
        let pageSize = topicsStore.messagesPageSize;

        if (filter.name === 'count') {
            pageSize = filterOption.value;
        }

        messagesPage.value = 0;

        await fetchTopicsMessages(messagesPage.value, pageSize);
    } catch (error) {
        console.log(error);
    }
}

async function fetchTopicsMessages(newPage = null, newPageSize = null, sort = 'asc', currentSearchQuery = null) {
    messagesLoading.value = true;

    try {
        messagesPage.value = _.isNil(newPage) ? messagesPage.value : newPage;
        messagesPageSize.value = _.isNil(newPageSize) ? messagesPageSize.value : newPageSize;

        if (currentSearchQuery || currentSearchQuery === '') {
            messagesPage.value = 0;
        }

        return await topicsStore
            .fetchTopicMessages(route.params.topic_id, {
                page_size: messagesPageSize.value,
                page: messagesPage.value,
                sort
            })
            .then(
                (res) => {
                    messages.value = res;
                    messagesLoading.value = false;

                    return res;
                },
                (err) => {
                    messagesLoading.value = false;
                    console.error(err);
                }
            );
    } finally {
        messagesLoading.value = false;
    }
}

async function handleDateFilterInput(filter) {
    console.log(filter);
}

const selectedMessage = ref(null);

const searchQuery = ref('');
const pageSize = ref(0);

const selectMessage = (message) => {
    selectedMessage.value = isRef(message) ? message?.value : message;
};

onMounted(async () => {
    topicLoading.value = true;
    messagesLoading.value = true;
    configLoading.value = true;

    initializeTopicMessagesFilters();

    await Promise.all([
        topicsStore.getById(route.params.topic_id).then(
            (res) => {
                topic.value = res;
                defaultTopic.value = _.cloneDeep(topic.value);
                topicLoading.value = false;

                return res;
            },
            (err) => {
                topicLoading.value = false;
                console.error(err);
            }
        ),
        fetchTopicsMessages(),
        topicsStore.fetchTopicConfigs().then(
            (res) => {
                configLoading.value = false;
                return res;
            },
            (err) => {
                console.error(err);
            }
        )
    ]);
});

const getFormattedValue = (metricKey, value) => {
    const metricInfo = getMetricFormattingInfo(metricKey, topicsStore.configs?.metrics);

    return formatter(value, metricInfo || {}, false);
};

const canSave = computed(() => {
    return !_.isEqual(topic.value, defaultTopic.value);
});

function canShowCancelSnapshot() {
    /* TBD */
    return false;
}

async function onSnapshot() {
    console.log('snapshotting');
}

async function onCancelSnapshot() {
    console.log('cancel snapshotting');
}

async function onChangeHashColumnState(column) {
    console.log('hashing column', column);
}

async function onChangeExcludeColumnState(column) {
    console.log('changing exclude column state', column);
}
</script>

<style scoped>
/* Tailwind CSS styling */
</style>
