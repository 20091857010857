<template>
    <div class="table-page-container mx-auto">
        <div class="page-header">
            <AppHeader />
        </div>

        <div class="table-page-body">
            <div class="page-options flex justify-between items-start">
                <div class="flex gap-2 w-2/3">
                    <SearchInput
                        :loading="loading"
                        placeholder="Search by issue..."
                        :custom-class="'w-1/2'"
                        @search="(input) => (searchQuery = input)" />
                    <div class="page-filters flex gap-5">
                        <DropdownFilters v-model="checkedFilters" :filters="filters" :disabled="loading" @on-filter-toggle="onFilterToggle">
                            <template #dropdownListElement="{ filter, filterOption }">
                                <div class="flex gap-2 items-center">
                                    <CloudArrowDownIcon v-if="filterOption.value === 'sources'" class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                    <CloudArrowUpIcon v-if="filterOption.value === 'destinations'" class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                    <ArrowsUpDownIcon v-if="filterOption.value === 'pipelines'" class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                    <div class="flex-1 whitespace-nowrap">
                                        {{ filterOption.displayValue }}
                                    </div>
                                </div>
                            </template>
                        </DropdownFilters>
                    </div>
                    <div class="flex gap-1 items-center">
                        <input v-model="showUnread" aria-label="input" type="checkbox" :disabled="loading" />
                        <span>Show unread</span>
                    </div>
                </div>
                <div class="page-filters flex gap-2 justify-end w-1/3">
                    <RowActionMenu
                        :left="true"
                        class=""
                        :show-mark-all="true"
                        :disable-mark-all="!anyUnreadNotifications"
                        :loading="loading"
                        @markAll="markAllNotificationsRead()" />
                    <Cog6ToothIcon
                        v-can-access.permissions="[$Permissions.WRITE_ALERTS]"
                        class="h-7 w-7 mx-2 self-center cursor-pointer"
                        @click="setAlertConfigurationModalOpenStatus(true)">
                    </Cog6ToothIcon>
                </div>
            </div>
            <section class="paginated-section mt-2" :class="filteredNotifications?.length ? 'overflow-y-auto' : ''">
                <AppLoader
                    :force-loading="loading"
                    default-height="20vh"
                    :default-width="'100%'"
                    background="#f9fafb"
                    :default-class="'shadow-md rounded-md'">
                    <table class="min-w-full divide-y divide-gray-300 border" aria-label="pipelines table">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Name</span>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Type</span>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Issue</span>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                    <div class="flex">
                                        <span>Date</span>
                                    </div>
                                </th>
                                <th class="text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <template v-if="!loading">
                                <tr
                                    v-for="(userNotification, userNotificationIndex) in filteredNotifications"
                                    :key="userNotificationIndex"
                                    class="transition duration-300 ease-in-out hover:bg-gray-50"
                                    :class="userNotification.read ? 'bg-gray-100' : 'unread-entry'">
                                    <td class="px-6 py-4 whitespace-nowrap text-cyan-950 no-underline hover:text-cyan-800">
                                        <div class="flex flex-col mr-4 gap-1 w-full">
                                            <div class="my-auto whitespace-normal break-words" style="max-width: 25rem">
                                                <router-link
                                                    :to="
                                                        selectedEntityPath(
                                                            userNotification?.payload?.entity_id,
                                                            userNotification?.payload?.entity_type,
                                                            userNotification?.payload?.entity_name
                                                        )
                                                    ">
                                                    <div class="flex gap-2 items-center font-medium text-cyan-950 no-underline hover:text-cyan-800">
                                                        {{ userNotification?.payload?.entity_name ?? '-' }}
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center gap-2">
                                            <div>
                                                <CloudArrowDownIcon
                                                    v-if="userNotification?.payload?.entity_type === 'sources'"
                                                    class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                                <CloudArrowUpIcon
                                                    v-if="userNotification?.payload?.entity_type === 'destinations'"
                                                    class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                                <ArrowsUpDownIcon
                                                    v-if="userNotification?.payload?.entity_type === 'pipelines'"
                                                    class="h-5 w-5 self-center -mr-1 fill-[#8b9db8]" />
                                            </div>
                                            <div>
                                                {{
                                                    userNotification?.payload?.entity_type === 'sources'
                                                        ? 'Source'
                                                        : userNotification?.payload?.entity_type === 'destinations'
                                                          ? 'Destination'
                                                          : userNotification?.payload?.entity_type === 'pipelines'
                                                            ? 'Pipeline'
                                                            : 'Unknown'
                                                }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap cursor-pointer no-underline">
                                        <div class="flex flex-col mr-4 gap-1 w-full">
                                            <div class="my-auto whitespace-normal">
                                                <div
                                                    class="flex items-center gap-2"
                                                    style="max-width: 80vh; overflow: hidden"
                                                    @click="() => onViewMessageClick(userNotification?.content)">
                                                    <ExclamationCircleIcon
                                                        class="flex-shrink-0 h-5 w-5 self-center -mr-1 cursor-pointer fill-[#EF4444]" />
                                                    <span class="truncate self-center">{{ userNotification?.content ?? '-' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="font-semibold">
                                            {{ userNotification?.createdAt ? get_relative_time(userNotification?.createdAt) : '-' }}
                                        </div>
                                        <div>{{ userNotification?.createdAt ? get_formatted_date(userNotification?.createdAt) : '-' }}</div>
                                    </td>
                                    <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                        <TableActionMenu
                                            :show-delete="userHasAccess([$Permissions.WRITE_ALERTS], authStore.user)"
                                            :show-read="userHasAccess([$Permissions.WRITE_ALERTS], authStore.user) && !userNotification?.read"
                                            :show-unread="userHasAccess([$Permissions.WRITE_ALERTS], authStore.user) && userNotification?.read"
                                            :button-style="true"
                                            :display-above="false"
                                            :position-relative="!loading"
                                            :loading="loading"
                                            @delete="onDelete([userNotification?.id])"
                                            @read="markNotificationAs([userNotification?.id], true)"
                                            @unread="markNotificationAs([userNotification?.id], false)" />
                                    </td>
                                </tr>
                                <tr v-if="!filteredNotifications?.length">
                                    <td colspan="99" class="text-center p-2">
                                        <small>No matching records found</small>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </AppLoader>
            </section>
            <Pagination
                v-if="filteredNotifications && page > 0"
                :page="page - 1"
                :page-size="userNotificationsResponse?.pageSize"
                :current-page-items-count="filteredNotifications?.length ?? 0"
                :disable-navigation="loading"
                :message="{
                    show: false,
                    showCustomMessage: true,
                    hideValues: loading,
                    firstPart: 'Showing alerts',
                    middlePart: 'to',
                    lastPart: 'alerts'
                }"
                @page-change="changePage">
                <template #customMessage>
                    <div v-if="filteredNotifications?.length > 0">
                        Showing
                        <span class="font-medium">{{ filteredNotifications?.length }}</span>
                        {{ filteredNotifications?.length === 1 ? 'alert' : 'alerts' }}
                        on this page
                    </div>
                </template>
            </Pagination>
        </div>
    </div>
    <AlertConfigurationModal
        v-if="openAlertConfigurationModal"
        :is-modal-open="openAlertConfigurationModal"
        @update:is-open="setAlertConfigurationModalOpenStatus($event)" />
</template>

<script setup>
import AppHeader from '../../components/AppHeader.vue';
import AppLoader from '../../components/AppLoader.vue';
import Pagination from '../../components/Pagination.vue';
import TableActionMenu from '../../components/TableActionMenu.vue';
import { ref, onMounted, computed, watch } from 'vue';
import SearchInput from '../../components/global/SearchInput.vue';
import { CloudArrowDownIcon, CloudArrowUpIcon, ArrowsUpDownIcon, ExclamationCircleIcon, Cog6ToothIcon } from '@heroicons/vue/24/solid';
import useLogMessageDialog from '../../composables/useLogMessageDialog.js';
import AlertConfigurationModal from '../../components/AlertIntegrations/AlertConfigurationModal.vue';
import { useNovuStore } from '@/stores/novu.store';
import RowActionMenu from '@/components/RowActionMenu.vue';
import DropdownFilters from '@/components/DropdownFilters.vue';
import { get_formatted_date, get_relative_time, userHasAccess } from '../../utils/utils.js';
import { ALERT_FILTERS, ALERT_DEFAULT_FILTER } from '@/utils/constants';
import { useAuthStore } from '@/stores/auth.store';

defineProps({
    isModalOpen: {
        type: Boolean,
        default: false
    }
});

let loading = ref(true);
let userNotificationsResponse = ref(false);
let page = ref(1);
let authStore = useAuthStore();
let searchQuery = ref(false);
let openAlertConfigurationModal = ref(false);
let novuStore = useNovuStore();
let filters = [
    {
        title: 'Type',
        name: 'type',
        multiple: false,
        defaultIndex: 0,
        dropdownWidth: '170px',
        options: [ALERT_DEFAULT_FILTER, ...ALERT_FILTERS]
    }
];
let checkedFilters = ref({});

let showUnread = ref(true);
let alertsQuery = computed(() => {
    let query = {};

    if (!showUnread.value) {
        query.read = false;
    }

    const entityTypeFilter = checkedFilters.value.type;
    if (entityTypeFilter) {
        const entityTypeFilterValue = checkedFilters.value.type[0].value;
        if (entityTypeFilterValue !== 'All') {
            query.payload = { entity_type: entityTypeFilterValue };
        }
    }

    return query;
});

onMounted(async () => {
    await initializeFilters();
    await loadAlertsPage(page.value, alertsQuery.value);
});

async function initializeFilters() {
    filters.forEach((filter) => {
        checkedFilters.value[filter.name] = [filter.multiple ? ALERT_DEFAULT_FILTER : filter.options[filter.defaultIndex]];
    });
}

async function loadAlertsPage(pageNumber = 1, query) {
    loading.value = true;
    await novuStore.fetchNotifications(pageNumber - 1, query);
    await novuStore.fetchUnreadCount();

    if (novuStore.userNotifications) {
        userNotificationsResponse.value = novuStore.userNotifications;
        searchQuery.value = '';
        loading.value = false;
    }
}

watch(
    alertsQuery,
    async (newQuery) => {
        await loadAlertsPage(page.value, newQuery);
    },
    { deep: true }
);

async function onFilterToggle({ filter, filterOption }) {}

async function markAllNotificationsRead() {
    loading.value = true;
    const marked = await novuStore.markAllNotificationsAsRead();
    if (marked) {
        userNotificationsResponse.value.data.forEach((obj) => (obj.read = true));
        loading.value = false;
    }
}
async function markNotificationAs(notificationIds, read) {
    const marked = await novuStore.markNotificationsAs(notificationIds, read);

    if (marked) {
        notificationIds.forEach((notificationId) => {
            userNotificationsResponse.value.data.map((x) => {
                if (x.id == notificationId) {
                    x.read = read;
                }
            });
        });
    }
}

async function onDelete(notificationIds) {
    const deleted = await novuStore.removeNotifications(notificationIds);
    if (deleted) {
        await loadAlertsPage(page.value);
    }
}

function onViewMessageClick(message) {
    useLogMessageDialog({ message, isError: false });
}

function selectedEntityPath(id, type, name) {
    const msg = `"${name}" has since been removed.`;
    switch (type) {
        case 'sources':
            return { name: 'source-details', params: { id }, query: { customMessage: `Source ${msg}` } };
        case 'destinations':
            return { name: 'destination-details', params: { id }, query: { customMessage: `Destination ${msg}` } };
        case 'pipelines':
            return { name: 'pipeline-details', params: { id }, query: { customMessage: `Pipeline ${msg}` } };
        default:
            console.log('Invalid option selected.');
            break;
    }
}

function setAlertConfigurationModalOpenStatus() {
    openAlertConfigurationModal.value = !openAlertConfigurationModal.value;
}

async function changePage(newPage) {
    page.value = newPage + 1;
    await loadAlertsPage(page.value, alertsQuery.value);
}

const filteredNotifications = computed(() => {
    if (!loading.value) {
        if (!searchQuery.value) {
            return userNotificationsResponse.value?.data ?? [];
        } else {
            return userNotificationsResponse.value?.data.filter((notification) =>
                notification.payload.entity_name.toLowerCase().includes(searchQuery.value.toLowerCase())
            );
        }
    }

    return [];
});

const anyUnreadNotifications = computed(() => !!novuStore.userUnreadNotificationsCount);
</script>

<style scoped>
tr > td:first-child {
    position: relative;
}

.unread-entry > td:first-child::before,
.read-entry > td:first-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
}

.unread-entry > td:first-child::before {
    background-color: #ef4444;
}

.read-entry > td:first-child::before {
    background-color: white;
}
</style>
