<template>
    <div class="flex overflow-hidden rounded-lg bg-white p-5 shadow gap-3">
        <div :class="`${iconBgClass} rounded-md p-3 flex items-center`">
            <component :is="icon" :class="`h-6 w-6 text-white`" />
        </div>
        <div class="flex flex-col items-start justify-start">
            <p class="truncate text-sm font-medium items-center flex" :class="titleClass">
                {{ title }}
                <InformationCircleIcon
                    v-if="infoIcon && !loading"
                    class="h-5 w-5 text-gray-600 ml-2"
                    :class="errors?.length ? 'cursor-pointer hover:text-gray-800' : ''"
                    @click="errors?.length ? onShowError() : null" />
            </p>
            <div class="flex items-baseline">
                <div v-if="loading" class="snippet mt-3 pb-3 ml-2 flex items-baseline" data-title=".dot-flashing">
                    <div class="stage">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
                <p
                    v-else
                    class="text-2xl font-medium flex items-center"
                    :class="`${valueClass} ${errors?.length ? 'cursor-pointer hover:text-gray-800' : ''}`"
                    @click="errors?.length ? onShowError() : null">
                    <span v-html="purify(value)"></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import useErrorDialog from '../../composables/useErrorDialog.js';
import DOMPurify from 'dompurify';
import _ from 'lodash';

const props = defineProps({
    title: String,
    value: [String, Number, HTMLElement],
    icon: Function, // Expecting a Vue component
    infoIcon: Boolean,
    errors: {
        type: Array,
        default: () => []
    },
    bgClass: {
        type: String,
        default: 'bg-white'
    },
    valueClass: {
        type: String,
        default: 'text-gray-900'
    },
    titleClass: {
        type: String,
        default: 'text-gray-600'
    },
    iconBgClass: {
        type: String,
        default: 'bg-gray'
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const onShowError = () => {
    useErrorDialog({
        errors: props.errors,
        width: 65
    });
};

const purify = (html) => {
    if (!_.isNaN(Number(html))) {
        return html;
    }

    return DOMPurify.sanitize(html);
};
</script>
<style scoped>
/* Basic Tailwind CSS styling for the card */
</style>
