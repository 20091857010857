<template>
    <div v-if="Config.app.isDev" class="mx-auto">
        <div class="page-header">
            <AppHeader :show-title="false">
                <template #titleRight>
                    <nav class="text-gray-700" aria-label="Breadcrumb">
                        <ol class="list-none p-0 inline-flex font-semibold">
                            <li class="flex items-center">
                                <div
                                    :class="type ? `text-green-400 hover:text-green-500 cursor-pointer` : ''"
                                    @click="type ? cancelServiceSetup() : null">
                                    New Service
                                </div>
                                <ChevronRightIcon v-if="type" class="w-4 h-4 mx-2 text-gray-600" />
                                <div v-if="type">{{ capitalize(type) }}</div>
                            </li>
                        </ol>
                    </nav>
                </template>
            </AppHeader>
        </div>
        <div class="flex align-center items-center justify-center w-full">
            <div class="w-full flex flex-col items-center justify-center">
                <div v-if="!type" class="mx-auto w-full flex align-center items-center overflow-auto">
                    <div class="mx-auto">
                        <h3 class="flex w-full text-left text-lg font-medium mb-3">Select the type of service you want</h3>
                        <!-- <div class="mx-auto max-w-4xl text-center">
                        <p class="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Services that tailor to your use-case</p>
                    </div>
                    <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                        Choose the service that's packed with the best features for streaming your data flawlessly
                    </p> -->
                        <!-- <div class="mt-16 flex justify-center">
                <fieldset
                    class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-medium leading-5 ring-1 ring-inset ring-gray-200">
                    <legend class="sr-only">Payment frequency</legend>
                    <label class="cursor-pointer rounded-full px-2.5 py-1">
                        <input aria-label="input" type="radio" name="frequency" value="monthly" class="sr-only" />
                        <span>Monthly</span>
                    </label>
                    <label class="cursor-pointer rounded-full px-2.5 py-1">
                        <input aria-label="input" type="radio" name="frequency" value="annually" class="sr-only" />
                        <span>Annually</span>
                    </label>
                </fieldset>
            </div> -->

                        <div class="isolate mx-auto grid grid-cols-1 gap-8 xl:grid-cols-2">
                            <div class="rounded-3xl p-8 ring-1 ring-gray-200 bg-white">
                                <h2 id="tier-development" class="text-lg font-medium leading-8 text-gray-900">Development</h2>
                                <p class="mt-4 text-sm leading-6 text-gray-600">Great for smaller workloads and proof of concepts</p>
                                <p class="mt-6 flex items-baseline gap-x-1">
                                    <span class="text-sm font-medium leading-6 text-gray-600 mr-1">Starting at</span>
                                    <!-- Price, update based on frequency toggle state -->
                                    <span class="text-4xl font-semibold tracking-tight text-gray-900">$100</span>
                                    <!-- Payment frequency, update based on frequency toggle state -->
                                    <span class="text-sm font-medium leading-6 text-gray-600">/month</span>
                                </p>
                                <a
                                    href="#"
                                    aria-describedby="tier-development"
                                    class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 text-green-400 hover:text-green-500 ring-1 ring-inset ring-green-200 hover:ring-green-300"
                                    @click="enableServiceSetup('development')"
                                    >Buy service</a
                                >
                                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                    <li class="flex gap-x-3">
                                        <CheckIcon class="h-5 w-5 text-green-500" />
                                        Not highly available
                                    </li>
                                    <li class="flex gap-x-3">
                                        <CheckIcon class="h-5 w-5 text-green-500" />
                                        30 day free trial
                                    </li>
                                </ul>
                            </div>
                            <div class="rounded-3xl p-8 ring-2 ring-green-500 bg-white">
                                <h2 id="tier-production" class="text-lg font-medium leading-8 text-green-500">Production</h2>
                                <p class="mt-4 text-sm leading-6 text-gray-600">A plan that scales with your rapidly growing database(s).</p>
                                <p class="mt-6 flex items-baseline gap-x-1">
                                    <span class="text-sm font-medium leading-6 text-gray-600 mr-1">Starting at</span>
                                    <!-- Price, update based on frequency toggle state -->
                                    <span class="text-4xl font-semibold tracking-tight text-gray-900">$300</span>
                                    <!-- Payment frequency, update based on frequency toggle state -->
                                    <span class="text-sm font-medium leading-6 text-gray-600">/month</span>
                                </p>
                                <a
                                    href="#"
                                    aria-describedby="tier-production"
                                    class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 hover:text-white bg-green-500 text-white shadow-sm hover:bg-green-400"
                                    @click="enableServiceSetup('production')"
                                    >Buy service</a
                                >
                                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                    <li class="flex gap-x-3">
                                        <CheckIcon class="h-5 w-5 text-green-500" />
                                        Highly available & scalable
                                    </li>
                                    <li class="flex gap-x-3">
                                        <CheckIcon class="h-5 w-5 text-green-500" />
                                        Uptime SLA
                                    </li>
                                    <li class="flex gap-x-3">
                                        <CheckIcon class="h-5 w-5 text-green-500" />
                                        Automatic scaling
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="rounded-3xl p-8 ring-1 ring-gray-200 bg-white mt-8">
                            <h2 id="tier-own-cloud" class="text-lg font-medium leading-8 text-gray-900">Your Own Cloud</h2>
                            <p class="mt-4 text-sm leading-6 text-gray-600">Deploy in your own cloud</p>

                            <a
                                href="#"
                                aria-describedby="tier-own-cloud"
                                class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 text-green-400 hover:text-green-500 ring-1 ring-inset ring-green-200 hover:ring-green-300"
                                @click="openChat"
                                >Get in touch</a
                            >
                        </div>
                    </div>
                </div>
                <ServiceSetup v-if="type" :type="type" class="mt-5" />
            </div>
        </div>
    </div>
    <div v-else>
        <div>COMING SOON</div>
    </div>
</template>

<script setup>
import Config from '../../../utils/config';
import { CheckIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import ServiceSetup from './ServiceSetup.vue';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import AppHeader from '../../../components/AppHeader.vue';

const router = useRouter();

const enableServiceSetup = (type) => {
    router.push({ query: { type } });
};

const type = computed(() => router.currentRoute.value.query.type);

const openChat = () => {
    window.Pylon('show');
};

const cancelServiceSetup = () => {
    router.push({ query: null });
};

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
</script>

<style>
/* Custom CSS for aspect ratio */
.aspect-square {
    position: relative;
}
</style>
