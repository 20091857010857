<script setup>
import { ref, onMounted, computed } from 'vue';
import { NovuService } from '../../services';
import AppLoader from '../../components/AppLoader.vue';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid';
import { useNovuStore } from '@/stores/novu.store';
import { novu_alert_channels, novu_alert_channel_icons } from '../../utils/utils.js';
import _ from 'lodash';

const props = defineProps({});
const loading = ref(true);
const userPreferences = ref([]);
const novuStore = useNovuStore();

onMounted(async () => {
    const fetchedUserPreferences = await novuStore.fetchUserPreferences();

    if (fetchedUserPreferences && novuStore.userPreferences) {
        userPreferences.value = novuStore.userPreferences.map((preference) => ({
            ...preference,
            expanded: false
        }));
    }

    loading.value = false;
});

function resolveChannelName(channelCode) {
    const channelNames = novu_alert_channels;
    return channelNames[channelCode] || channelCode;
}

function resolveChannelIcon(channelCode) {
    const channelIcons = novu_alert_channel_icons;
    return channelIcons[channelCode] || channelCode;
}

function getChannelNames(channels) {
    return Object.entries(channels)
        .filter(([_, enabled]) => enabled)
        .map(([channel, _]) => resolveChannelName(channel))
        .join(', ');
}

function toggleTemplate(templateId) {
    userPreferences.value = userPreferences.value.map((u) => {
        if (u.template._id === templateId)
            return {
                ...u,
                expanded: !u.expanded
            };

        return u;
    });
}

const sortedAndGroupedTemplates = computed(() => {
    const filteredPreferences = _(userPreferences.value)
        .filter((item) => item.preference.enabled)
        .map((item) => ({
            id: item.template._id,
            name: item.template.name,
            channels: item.preference.channels,
            expanded: item.expanded
        }))
        .value();

    const grouped = _.groupBy(filteredPreferences, 'id');

    const groupedMapped = _.mapValues(grouped, (items) => _.pick(items[0], ['name', 'channels', 'expanded']));

    const sortedKeys = _.sortBy(_.keys(groupedMapped), (key) => groupedMapped[key].name);

    const sortedObject = _.fromPairs(sortedKeys.map((key) => [key, groupedMapped[key]]));

    return sortedObject;
});

async function toggleChannelStatus(templateId, channelType) {
    const preference = {
        templateId: templateId,
        channelType: channelType,
        checked: !userPreferences.value.find((x) => x.template._id === templateId)?.preference?.channels?.[channelType]
    };

    const updated = await novuStore.updateUserPreference(preference);

    if (updated) {
        userPreferences.value = userPreferences.value.map((template) => {
            if (template.template._id === templateId) {
                return {
                    ...template,
                    preference: {
                        ...template.preference,
                        channels: {
                            ...template.preference.channels,
                            [channelType]: !template.preference.channels[channelType]
                        }
                    }
                };
            }
            return template;
        });
    }
}
</script>

<template>
    <div v-if="loading" class="table-loader min-h-[50px] mt-2">
        <AppLoader :force-loading="loading" default-height="inherit" />
    </div>
    <div v-if="!loading" class="integrations-list overflow-auto max-h-[60vh] px-2 py-4">
        <div v-for="(template, id) in sortedAndGroupedTemplates" :key="id" class="mb-[16px]">
            <div class="integration-item mb-0 mt-1 flex items-center justify-between p-4 border-b bg-white shadow">
                <div class="flex items-center justify-between w-full cursor-pointer" @click="toggleTemplate(id)">
                    <div>
                        <div class="flex items-center">
                            <span class="text-md font-medium">{{ template.name }}</span>
                        </div>
                        <div class="flex mt-1">
                            <span class="text-xs text-gray-600 italic mr-1">{{ getChannelNames(template.channels) }}</span>
                        </div>
                    </div>
                    <div>
                        <ChevronDownIcon v-if="!template.expanded" class="-mr-1 ml-2 h-5 w-5" />
                        <ChevronUpIcon v-else class="-mr-1 ml-2 h-5 w-5" />
                    </div>
                </div>
            </div>
            <div v-if="template.expanded" class="integration-item-expanded border-t border-b bg-white shadow">
                <div class="px-4 py-[2px]">
                    <div v-for="(enabled, channel) in template.channels" :key="`channel-${channel}`" class="flex items-center justify-between p-2">
                        <div :for="`${id}-${channel}`" class="flex text-sm mr-2">
                            <img :src="resolveChannelIcon(channel)" class="w-8 h-8 mr-4 opacity-60" />
                            <div class="self-center">{{ resolveChannelName(channel) }}</div>
                        </div>
                        <div class="relative inline-flex items-center cursor-pointer">
                            <input
                                @click="toggleChannelStatus(id, channel)"
                                aria-label="input"
                                class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                :checked="template.channels[channel]"
                                type="checkbox"
                                role="switch"
                                :class="{
                                    'bg-green-400': template.channels[channel],
                                    'bg-neutral-300': !template.channels[channel]
                                }"
                                :true-value="true"
                                :false-value="false"
                                aria-describedby="toggle" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
