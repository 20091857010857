import Config from '../utils/config';

const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.type = 'module';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

export const loadCello = () => {
    let mainCdnUrl;
    let attributionCdnUrl;

    if (Config.app.isDev) {
        mainCdnUrl = 'https://assets.sandbox.cello.so/app/latest/cello.js';
        attributionCdnUrl = 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js';
    } else if (Config.app.isProd) {
        mainCdnUrl = 'https://assets.cello.so/app/latest/cello.js';
        attributionCdnUrl = 'https://assets.cello.so/attribution/latest/cello-attribution.js';
    }

    if (mainCdnUrl) {
        return Promise.all([loadScript(mainCdnUrl), loadScript(attributionCdnUrl)]);
    } else {
        return Promise.reject(new Error('Invalid environment'));
    }
};
