import { createRouter, createWebHistory } from 'vue-router';
import pipelinesRoutes from './pipelines.routes';
import View404 from '../components/global/404.vue';
import topicsRoutes from './topics.routes';
import connectorsRoutes from './connectors.routes';
import transformsRoutes from './transforms.routes';
import dashboardRoutes from './dashboard.routes';
import billingRoutes from './billing.routes';
import servicesRoutes from './services.routes';
import logsRoutes from './logs.routes';
import alertsRoutes from './alerts.routes';
import { isAuthenticatedGuard, useFrontegg } from '@frontegg/vue';
import Config from '@/utils/config';
import { hasAccess } from '@/utils/utils';

const waitForAuthStateToLoad = (fronteggInfo) => {
    return new Promise((resolve) => {
        const checkAuthState = () => {
            if (!fronteggInfo?.authState?.isLoading) {
                resolve();
            } else {
                setTimeout(checkAuthState, 100); // Check again after 100ms
            }
        };

        checkAuthState();
    });
};

const otherRoutes = [
    {
        path: '/404/:customMessage?',
        name: '404',
        component: View404,
        props: true,
        meta: {
            title: '404 - Not Found'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catchAll',
        beforeEnter: (to, from, next) => {
            if (to.path.includes('logout') || to.path.includes('account')) {
                next();
            } else {
                next({ name: 'dashboard' });
            }
        }
    },
    {
        path: '/',
        name: 'home-redirect',
        redirect: { name: 'dashboard' }
    },
    {
        path: '/logout',
        name: 'logout-base',
        redirect: { path: '/account/logout' },
        meta: {
            requireAuth: false
        }
    }
];

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        ...pipelinesRoutes,
        ...logsRoutes,
        ...topicsRoutes,
        ...connectorsRoutes,
        ...transformsRoutes,
        ...dashboardRoutes,
        ...servicesRoutes,
        ...alertsRoutes,
        ...billingRoutes,
        ...otherRoutes
    ]
});

router.beforeEach((to, from, next) => {
    const fronteggInfo = useFrontegg();

    const handleOnLeave = (route) => {
        if (route.meta.onLeave) {
            route.meta.onLeave(to, from);
        }
    };

    const handleOnEnter = (route) => {
        if (route.meta.onEnter) {
            route.meta.onEnter(to, from);
        }
    };

    const handleAuthGuard = () => {
        if (!fronteggInfo?.authState?.isAuthenticated) {
            return next('/account/login');
        }

        if (to.meta.accessRules) {
            if (!hasAccess(fronteggInfo?.authState?.user, to.meta.accessRules)) {
                return next({ name: '404' });
            }
        }

        if (to.meta.onlyDev && !Config.app.isDev) {
            return next({ name: 'dashboard' });
        }

        return isAuthenticatedGuard(to, from, next);
    };

    const proceedAuthGuard = () => {
        if (fronteggInfo?.authState?.isLoading) {
            waitForAuthStateToLoad(fronteggInfo).then(() => {
                handleAuthGuard();
            });
        } else {
            handleAuthGuard();
        }
    };

    // Handle 'onLeave' logic
    handleOnLeave(from);

    // Handle 'onEnter' logic
    handleOnEnter(to);

    // Handle auth guard logic
    if (to.meta.requireAuth) {
        proceedAuthGuard();
    } else {
        if (to.meta.onlyDev && !Config.app.isDev) {
            return next({ name: 'dashboard' });
        }

        return next(true);
    }
});

export default router;
