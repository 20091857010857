<template>
    <div
        class="checkbox-wrapper h-[1.25rem] min-h-[1.25rem] w-[1.25rem] min-w-[1.25rem] rounded border border-gray-300 cursor-pointer"
        :class="{
            'bg-green-400': isChecked && !isPartial,
            'bg-gray-400': (isChecked && isPartial) || isReadonly,
            'bg-white': !isChecked && !isDisabled,
            'cursor-not-allowed': isDisabled || isReadonly,
            'border-red-500': isDuplicate || isError,
            'opacity-[0.5]': isReadonly
        }"
        @click="toggleCheckbox">
        <div v-if="isChecked && !isPartial" class="checkbox-checkmark">&#10003;</div>
        <div v-if="isPartial && isChecked" class="checkbox-checkmark">&#8213;</div>
    </div>
</template>

<script>
export default {
    props: ['checked', 'disabled', 'duplicate', 'partial', 'readonly', 'error'],
    computed: {
        isChecked() {
            return this.checked;
        },
        isDisabled() {
            return this.disabled;
        },
        isDuplicate() {
            return this.duplicate;
        },
        isError() {
            return this.error;
        },
        isPartial() {
            return this.partial;
        },
        isReadonly() {
            return this.readonly;
        }
    },
    methods: {
        toggleCheckbox() {
            if (!this.isDisabled && !this.isReadonly) {
                this.$emit('change', !this.isChecked);
            }
        }
    }
};
</script>

<style scoped>
.checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-checkmark {
    color: white;
    font-size: 0.7rem; /* Adjust the font-size to scale down the checkmark */
}
</style>
