// eslint-disable
import { defineRule } from 'vee-validate';
import { required, max_value, min_value } from '@vee-validate/rules';
import validator from 'validator';

export function isValidEmail(input) {
    if (!input) return false;

    const emailRegex = /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(input);
}

defineRule('positive', (value) => {
    return value >= 0;
});

defineRule('required', required);
defineRule('max_value', max_value);
defineRule('min_value', min_value);

defineRule('phone', (value) => {
    return validator.isMobilePhone(String(value));
});

defineRule('email', (value) => validator.isEmail(String(value)));
