<template>
    <vue-highcharts
        ref="chart"
        v-if="chartOptionsExists && !hideChart"
        :options="chartOptionsToUse"
        :redraw-on-update="true"
        :one-to-one-update="false"
        :animate-on-update="true"
        @rendered="onRender"
        @update="onUpdate"
        @destroy="onDestroy" />
    <div v-else class="flex items-center justify-center h-96">
        <div v-if="!hideChart" class="text-gray-500">No data to display</div>
        <AppLoader v-else :force-loading="hideChart" default-height="35vh"></AppLoader>
    </div>
</template>

<script>
import VueHighcharts from 'vue3-highcharts';
import { ref } from 'vue';
import HighCharts from 'highcharts';
import useMapCharts from 'highcharts/modules/map';
import useDataCharts from 'highcharts/modules/data';
import RoundedCorners from 'highcharts-rounded-corners';
import { formatter } from '../utils/utils';
import AppLoader from './AppLoader.vue';
import moment from 'moment-timezone';
import _ from 'lodash';

useDataCharts(HighCharts);
useMapCharts(HighCharts);
RoundedCorners(HighCharts);

HighCharts.setOptions({
    time: {
        timezone: moment.tz.guess()
    }
});

export default {
    name: 'HighChart',
    components: {
        VueHighcharts
    },
    props: ['title', 'subtitle', 'xTitle', 'yTitle', 'series', 'chartOptions'],
    data() {
        return {
            hideChart: false
        };
    },
    computed: {
        chartOptionsExists() {
            return !_.isEmpty(this.chartOptions) && !_.isEmpty(this.chartOptions?.series);
        },
        chartOptionsToUse() {
            return (
                this.chartOptions || {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: props.title
                    },
                    subtitle: {
                        text: props.subtitle
                    },
                    xAxis: {
                        categories: [props.xTitle],
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: props.yTitle
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormatter: function () {
                            var value;
                            if (this.y % 1 !== 0) {
                                value = HighCharts.numberFormat(this.y, 2, '.', '');
                            } else {
                                value = HighCharts.numberFormat(this.y, 0, '', '');
                            }
                            return (
                                '<tr><td style="color:' +
                                this.series.color +
                                ';padding:0">' +
                                this.series.name +
                                ': </td>' +
                                '<td style="padding:0"><b>' +
                                formatter(value).display +
                                '</b></td></tr>'
                            );
                        },
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: props.series
                }
            );
        }
    },
    setup(props) {
        // eslint-disable-next-line no-unused-vars
        const chart = ref(null);

        const onRender = () => {};

        const onUpdate = () => {};

        const onDestroy = () => {};

        return {
            onRender,
            onUpdate,
            onDestroy,
            chart
        };
    },
    watch: {
        chartOptions: {
            handler: function (newValue, oldValue) {
                // TODO: This is a workaround to force the chart to update when the series change. To be improved in the future
                if (newValue?.series?.length !== oldValue?.series?.length) {
                    this.hideChart = true;
                    setTimeout(() => {
                        this.hideChart = false;
                    }, 1);
                } else {
                    this.$refs.chart?.chart?.update(newValue);
                    this.$refs.chart?.chart?.redraw();
                }
            },
            deep: true
        }
    }
};
</script>
