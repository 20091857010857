<template>
    <SQLViewsModal
        v-if="openTransformSQLModal"
        :scripts="[{ scriptValue: transformForModal?.sql_query || '', scriptName: transformForModal?.name }]"
        :is-modal-open="openTransformSQLModal"
        @update:is-open="setSQLModalOpenStatus($event)" />

    <div class="mx-auto">
        <div class="page-header">
            <AppHeader />
        </div>
        <!-- Search and Filters -->
        <div class="table-page-container mt-5 mb-5">
            <div class="table-page-body">
                <div class="flex justify-between mb-3 items-end">
                    <SearchInput
                        :loading="configLoading || transformTableDataLoading"
                        placeholder="Search by name..."
                        @search="(input) => (searchQuery = input)" />
                    <div class="flex items-end gap-3">
                        <!-- <DateFilter @input="handleDateFilterInput" /> -->
                        <DropdownFilters
                            v-model="checkedFilters"
                            slim-dropdown-button
                            :filters="filters"
                            :disabled="configLoading || transformTableDataLoading"
                            @on-filter-toggle="onTransformsFilterToggle">
                            <template #dropdownListElement="{ filterOption }">
                                <div class="flex items-center">
                                    <div class="flex-1 whitespace-nowrap">
                                        {{ filterOption.displayValue }}
                                    </div>
                                </div>
                            </template>
                        </DropdownFilters>
                    </div>
                </div>

                <!-- Transforms Table -->
                <!-- <RowActionMenu
            :left="true"
            class="mb-2"
            :show-snapshot="true"
            :disable-snapshot="!anyCheckedTopic"
            :show-cancel-snapshot="canShowCancelSnapshot()"
            :loading=" configLoading"
            @snapshot="onSnapshot()"
            @cancelSnapshot="onCancelSnapshot()" /> -->

                <div class="overflow-x-auto bg-white rounded-lg shadow-md">
                    <section class="paginated-section">
                        <AppLoader
                            :force-loading="transformTableDataLoading || configLoading"
                            default-height="40vh"
                            background="#f9fafb"
                            :default-width="'100%'"
                            :default-class="'shadow-md rounded-md'">
                            <div
                                class="relative w-full shadow-md"
                                :class="!transformsWithLatestMetrics?.length || transformsWithLatestMetrics?.length > 4 ? 'overflow-auto' : ''">
                                <table class="min-w-full divide-y divide-gray-300 border" aria-label="sources table">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex items-center gap-3">
                                                    <!-- <input aria-label="input" v-model="selectAllState" type="checkbox" @input="selectAll" /> -->
                                                    <span>Name</span>
                                                    <!-- <SortingButton
                                        :column="'name'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('name', event)" /> -->
                                                </div>
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex">
                                                    <span>Status</span>
                                                    <!-- <SortingButton
                                        :column="'status'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('status', event)" /> -->
                                                </div>
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex">
                                                    <span>Start Time</span>
                                                    <!-- <SortingButton
                                        :column="'start_time'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('start_time', event)" /> -->
                                                </div>
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex">
                                                    <span>Duration</span>
                                                    <!-- <SortingButton
                                        :column="'duration'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('duration', event)" /> -->
                                                </div>
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex">
                                                    <span>End Time</span>
                                                    <!-- <SortingButton
                                        :column="'end_time'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('end_time', event)" /> -->
                                                </div>
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex">
                                                    <span>Latency</span>
                                                    <!-- <SortingButton
                                        :column="'latency'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('latency', event)" /> -->
                                                </div>
                                            </th>
                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                <div class="flex">
                                                    <span>Tasks</span>
                                                    <!-- <SortingButton
                                        :column="'tasks'"
                                        :direction="sortDirection"
                                        @swap-sorting="(event) => swapSorting('tasks', event)" /> -->
                                                </div>
                                            </th>

                                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <template v-if="!configLoading">
                                            <tr
                                                v-for="(transform, index) in transformsWithLatestMetrics"
                                                :key="transform.id"
                                                class="transition duration-300 ease-in-out hover:bg-gray-50">
                                                <td class="px-6 py-4 whitespace-nowrap no-underline">
                                                    <div class="flex items-center gap-3 w-full">
                                                        <!-- <input aria-label="input" v-model="checkedTransforms[transform.id]" type="checkbox" @input.stop="checkTransform(transform.id)" /> -->
                                                        <div
                                                            v-if="transformInEdit.id !== transform.id"
                                                            class="my-auto whitespace-normal break-words max-w-sm">
                                                            <span
                                                                :class="
                                                                    transform.sql_query
                                                                        ? 'font-medium text-cyan-950 no-underline hover:text-cyan-800 cursor-pointer'
                                                                        : ''
                                                                "
                                                                @click="transform.sql_query ? setSQLModalOpenStatus(true, transform) : null">
                                                                {{ transform.name }}</span
                                                            >
                                                        </div>
                                                        <PencilSquareIcon
                                                            v-can-access.permissions.or="[$Permissions.WRITE_TRANSFORMS]"
                                                            v-if="transformInEdit.id !== transform.id"
                                                            class="h-4 w-4 text-gray-600 cursor-pointer hover:text-gray-600"
                                                            @click.stop="editTransformName(transform)" />

                                                        <div v-else class="w-full flex flex-col">
                                                            <div class="w-full flex items-center">
                                                                <input
                                                                    v-model="transformInEdit.name"
                                                                    aria-label="input"
                                                                    placeholder="Press enter to save, esc to cancel"
                                                                    :disabled="actionPerItemLoading[transform.id]"
                                                                    type="text"
                                                                    :class="{
                                                                        'border-red-500': !transformInEdit.name,
                                                                        disabled: actionPerItemLoading[transform.id]
                                                                    }"
                                                                    class="w-full border border-gray-300 rounded-l-md px-2 py-1 focus:outline-none"
                                                                    required
                                                                    @keydown.enter.stop.prevent="
                                                                        transformInEdit.name && transformInEdit.name !== transform.name
                                                                            ? saveTransformName(transform.id)
                                                                            : null
                                                                    "
                                                                    @keydown.esc.stop.prevent="cancelEditTransformName" />
                                                                <button
                                                                    aria-label="button"
                                                                    class="bg-gray-500 hover:bg-gray-600 text-white px-1 flex items-center h-auto"
                                                                    :style="'min-height: 38px'"
                                                                    :disabled="actionPerItemLoading[transform.id]"
                                                                    @click.stop.prevent="cancelEditTransformName">
                                                                    <XMarkIcon class="h-5 w-5" />
                                                                </button>
                                                                <button
                                                                    v-can-access.permissions="[$Permissions.WRITE_TRANSFORMS]"
                                                                    aria-label="button"
                                                                    class="bg-green-400 hover:bg-green-500 text-white rounded-r-md px-1 flex items-center h-auto"
                                                                    :style="'min-height: 38px'"
                                                                    :disabled="
                                                                        !transformInEdit.name ||
                                                                        transformInEdit.name === transform.name ||
                                                                        actionPerItemLoading[transform.id]
                                                                    "
                                                                    @click.stop="saveTransformName(transform.id)">
                                                                    <CheckCircleIcon class="h-5 w-5" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex flex-nowrap justify-content-start mr-4">
                                                        <small
                                                            class="inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                            :style="`background: ${getStatusColor(transform.metrics.status)}`"
                                                            >{{ transform.metrics.status.toUpperCase() }}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ getFormattedValue('start_time', transform.metrics.start_time).display }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ getFormattedValue('duration', transform.metrics.duration).display }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ getFormattedValue('end_time', transform.metrics.end_time).display }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ getFormattedValue('latency', transform.metrics.latency).display }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ getFormattedValue('tasks', transform.metrics.tasks).display }}
                                                </td>
                                                <td class="sticky right-0 bg-white z-10 whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                                    <TableActionMenu
                                                        :show-copy-id="true"
                                                        @copy-id="copyId(transform.id)"
                                                        :position-relative="!(transformTableDataLoading || configLoading)"
                                                        :display-above="
                                                            transformsWithLatestMetrics.length && index >= transformsWithLatestMetrics.length - 2
                                                        "
                                                        :button-style="false"
                                                        :loading="transformTableDataLoading || configLoading" />
                                                </td>
                                            </tr>
                                            <tr v-if="!transformTableData?.length">
                                                <td colspan="99" class="text-center p-2">
                                                    <small>No matching records found</small>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </AppLoader>
                    </section>
                </div>
                <Pagination
                    v-if="transformTableData.length > 0 || page > 0"
                    :page="page"
                    :page-size="pageSize"
                    :disable-navigation="transformTableDataLoading || configLoading"
                    :current-page-items-count="transformTableData?.length"
                    :total-items-count="transformsStore.total"
                    :message="paginationMessage"
                    @page-change="(page) => fetchTransformsTableData(page)">
                </Pagination>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import _ from 'lodash';
import AppHeader from '../../components/AppHeader.vue';
import Pagination from '../../components/Pagination.vue';
import AppLoader from '../../components/AppLoader.vue';

import { FILTER_CONSTANTS } from '../../../src/utils/utils';
import { useTransformsStore } from '@/stores/transforms.store';
import { useMetricsStore } from '@/stores/metrics.store';
import SearchInput from '@/components/global/SearchInput.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import { formatter, getStatusColor, getMetricFormattingInfo } from '../../utils/utils';
import SQLViewsModal from '@/components/SQLViewsModal.vue';

// import DateFilter from '@/components/global/DateFilter.vue';
import TableActionMenu from '../../components/TableActionMenu.vue';
import { PencilSquareIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
// import SortingButton from '../../components/global/SortingButton.vue';
// import RowActionMenu from '@/components/RowActionMenu.vue';
// import moment from 'moment';
// import ComingSoon from '@/components/global/ComingSoon.vue';

const transformsStore = useTransformsStore();
const metricsStore = useMetricsStore();

const page = ref(0);
const pageSize = ref(10);

const transformTableData = ref([]);
const configLoading = ref(false);
const transformTableDataLoading = ref(false);
const actionPerItemLoading = ref({});
let checkedFilters = ref([]);

const openTransformSQLModal = ref(false);

const transformInEdit = ref({});
const transformForModal = ref({});
// const selectedTransform = ref(null);
// const sortColumn = ref('name');
// const sortDirection = ref(false);
// const selectAllState = ref(false);
// const checkedTransforms = ref({});

const searchQuery = ref(null);

const filters = ref([
    {
        title: 'Transforms per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
        ]
    }
]);

onMounted(async () => {
    page.value = transformsStore.page || 0;
    pageSize.value = transformsStore.pageSize || 0;
    configLoading.value = true;

    initializeTransformsFilters();

    try {
        await Promise.all([
            fetchTransformsTableData(),
            transformsStore.fetchTransformConfigs().then(() => {
                configLoading.value = false;
            })
        ]);
    } catch (error) {
        console.log(error);
    } finally {
        configLoading.value = false;
    }
});

watch(
    () => searchQuery.value,
    _.debounce(async () => {
        await fetchTransformsTableData(null, null, searchQuery.value);
    }, 700)
);

function initializeTransformsFilters() {
    filters.value.forEach((filter) => {
        if (filter.name === 'count') {
            pageSize.value = filter.options[filter.defaultIndex].value;
        }

        checkedFilters.value[filter.name] = [filter.multiple ? FILTER_CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

async function fetchTransformsTableData(newPage = null, newPageSize = null, currentSearchQuery = null) {
    transformTableDataLoading.value = true;

    try {
        page.value = _.isNil(newPage) ? page.value : newPage;
        pageSize.value = _.isNil(newPageSize) ? pageSize.value : newPageSize;

        if (currentSearchQuery || currentSearchQuery === '') {
            page.value = 0;
        }

        await transformsStore
            .fetch({
                page_size: pageSize.value,
                page_number: page.value,
                partial_name: currentSearchQuery || searchQuery.value
            })
            .then(() => {
                transformTableData.value = transformsStore.all;
                transformTableDataLoading.value = false;
            });
    } finally {
        transformTableDataLoading.value = false;
    }
}

const getFormattedValue = (metricKey, value) => {
    const metricInfo = getMetricFormattingInfo(metricKey, transformsStore.configs?.metrics);

    return formatter(value, metricInfo || {});
};

async function onTransformsFilterToggle({ filter, filterOption }) {
    try {
        let pageSize = transformsStore.pageSize;

        if (filter.name === 'count') {
            pageSize = filterOption.value;
        }

        page.value = 0;

        await fetchTransformsTableData(page.value, pageSize);
    } catch (error) {
        console.log(error);
    }
}

const paginationMessage = computed(() => {
    return {
        show: transformTableData.value.length === transformsStore.all?.length,
        showCustomMessage: transformTableData.value.length !== transformsStore.all?.length,
        firstPart: 'Showing transforms',
        middlePart: 'to',
        lastPart: ``
    };
});

const cancelEditTransformName = () => {
    transformInEdit.value = {};
};

const editTransformName = (transform) => {
    transformInEdit.value = {
        id: transform.id,
        name: transform.name
    };
};

const saveTransformName = async (transformId) => {
    try {
        actionPerItemLoading.value[transformId] = true;

        await transformsStore.editTransform(transformInEdit.value);

        transformTableData.value = transformsStore.all;
        transformInEdit.value = {};
    } catch (error) {
        console.log(error);
    } finally {
        actionPerItemLoading.value[transformId] = false;
    }
};

const transformsWithLatestMetrics = computed(() => {
    return (
        transformTableData.value?.map((transform) => {
            return {
                ...transform,
                metrics: {
                    ...transform.metrics,
                    ...(metricsStore.transforms.allLatest?.[transform.id]?.inline || {})
                }
            };
        }) || []
    );
});

const setSQLModalOpenStatus = (status, transform = {}) => {
    transformForModal.value = transform;
    openTransformSQLModal.value = status;
};

const copyId = (id) => {
    navigator.clipboard.writeText(id);
};

// async function handleDateFilterInput(filter) {
//     console.log(filter);
// }

// const selectAll = () => {
//     selectAllState.value = !selectAllState.value;

//     _.forEach(transformsStore.allMocked, (transform) => {
//         checkedTransforms.value[transform.id] = selectAllState.value;
//     });
// };

// const selectTransform = (transform) => {
//     if (selectedTransform.value === transform) {
//         selectedTransform.value = null;
//     } else {
//         selectedTransform.value = transform;
//     }
// };

// const swapSorting = (column, direction) => {
//     sortColumn.value = column;
//     sortDirection.value = direction;
// };

// const anyCheckedTopic = computed(() => !!Object.values(checkedTransforms.value || []).filter((x) => x).length);

// const checkTransform = (id) => {
//     checkedTransforms.value[id] = !checkedTransforms.value[id];

//     if (Object.values(checkedTransforms.value).filter((x) => x).length === transformsStore.allMocked.length) {
//         selectAllState.value = true;
//     } else {
//         selectAllState.value = false;
//     }
// };

// function canShowCancelSnapshot() {
//     /* TBD */
//     return false;
// }

// async function onSnapshot(topic) {
//     if (topic) console.log('snapshot topic', topic);
//     else console.log('snapshot global');
// }

// async function onCancelSnapshot(topic) {
//     if (topic) console.log('cancel snapshotting for topic', topic);
//     else console.log('cancel snapshotting globally');
// }

// const canSnapshot = (transform) => {
//     console.log(transform, 'canSnapshot');

//     return true;
// };

// const canTransformCancelSnapshot = (transform) => {
//     console.log(transform, 'canTransformCancelSnapshot');

//     return false;
// };
</script>
