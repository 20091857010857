import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLoaderStore } from './loader.store';
import _ from 'lodash';
import * as topicsApi from '../api/topics.api';

export const useTopicsStore = defineStore('topics', () => {
    const topics = ref(null);
    const loader = useLoaderStore();
    const totalSize = ref(0);
    const page = ref(0);
    const pageSize = ref(0);
    const statistics = ref(null);
    const lastSearchQuery = ref(null);
    const topicConfigs = ref(null);
    const messages = ref({});

    const messagesPage = ref(0);
    const messagesPageSize = ref(0);
    const messagesPageTotal = ref(0);
    const messagesPageSort = ref('asc');

    return {
        total: computed(() => totalSize.value),
        page: computed(() => page.value),
        pageSize: computed(() => pageSize.value),
        messagesPage: computed(() => messagesPage.value),
        messagesPageSize: computed(() => messagesPageSize.value),
        messagesPageTotal: computed(() => messagesPageTotal.value),
        topics,
        configs: computed(() => topicConfigs.value),
        statistics: computed(() => statistics.value),
        // Getters
        all: computed(() => topics.value),
        // Actions
        fetch: async function ({ page_size, page_number, partial_name } = {}) {
            // TODO - enable once we have real time updates
            // if (
            //     !partial_name &&
            //     partial_name !== '' &&
            //     !lastSearchQuery.value &&
            //     lastSearchQuery.value !== '' &&
            //     page_number === page.value &&
            //     !_.isEmpty(topics.value) &&
            //     pageSize.value === page_size
            // )
            //     return topics.value;

            loader.activateLoading('topics.fetch');

            return topicsApi
                .fetchTopics({ page_size, page_number: page_number + 1, partial_name })
                .then(({ result, total }) => {
                    topics.value = _.isEmpty(result || []) ? [] : result;

                    page.value = page_number;
                    pageSize.value = page_size;
                    totalSize.value = total;
                    lastSearchQuery.value = partial_name;

                    return result;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('topics.fetch');
                });
        },
        fetchTopicConfigs: async () => {
            loader.activateLoading('metrics.topics.fetchTopicConfigs');
            try {
                if (topicConfigs.value && !_.isEmpty(topicConfigs.value)) {
                    return topicConfigs.value;
                }

                const data = await topicsApi.fetchTopicConfigs();
                topicConfigs.value = data;

                return data;
            } catch (err) {
                console.error(err);
            } finally {
                loader.deactivateLoading('metrics.topics.fetchTopicConfigs');
            }
        },
        fetchTopicStatistics: async () => {
            loader.activateLoading('metrics.topics.fetchTopicStatistics');
            try {
                if (statistics.value && !_.isEmpty(statistics.value)) {
                    return statistics.value;
                }

                const data = await topicsApi.fetchTopicStatistics();
                statistics.value = data;

                return data;
            } catch (err) {
                console.error(err);
            } finally {
                loader.deactivateLoading('metrics.topics.fetchTopicStatistics');
            }
        },
        getById: async function (topic_id) {
            if (!topic_id) {
                console.error('Topic ID is required');
                return;
            }

            const found = _.find(topics.value, { id: topic_id });

            if (found) {
                return found;
            }

            loader.activateLoading('metrics.topics.getById');

            try {
                const topic = topicsApi.fetchTopic(topic_id);

                return topic;
            } catch (err) {
                console.error(err);
            } finally {
                loader.deactivateLoading('metrics.topics.getById');
            }
        },
        fetchTopicMessages: async function (id, { page_size = 10, page = 0, sort = 'asc' } = {}) {
            loader.activateLoading('metrics.topics.getMessagesForTopic');

            if (messages.value[id] && messages.value[id][page]?.messages?.length && messages.value[id][page]?.sort === sort) {
                if (messagesPage.value === page && messagesPageSize.value === page_size) {
                    return messages.value[id][page]?.messages;
                }
            }

            return topicsApi
                .fetchTopicMessages(id, { page_size, page: page + 1, sort })
                .then(({ result, total }) => {
                    messages.value[id] = {
                        [page]: {
                            messages: _.isEmpty(result || []) ? [] : result,
                            sort
                        }
                    };

                    console.log(result, total, 'result, total');

                    messagesPage.value = page;
                    messagesPageSize.value = page_size;
                    messagesPageTotal.value = total;
                    messagesPageSort.value = sort;

                    return result;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('metrics.topics.getMessagesForTopic');
                });
        },
        getMessageById: async function (id) {
            if (!id) return;

            const found = _.find(messages.value, { id });

            if (found) {
                return found;
            }
        }
    };
});
