<template>
    <div class="flex items-center w-full">
        <div class="relative" :class="widthClass">
            <label v-if="props.label" :for="props.label" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {{ props.label }}
            </label>
            <select
                v-model="selectedOption"
                aria-label="basic-dropdown"
                class="form-select text-sm"
                :class="props.inputRight ? 'rounded-l-md' : 'rounded-md'"
                :disabled="props.disabled || props.loading">
                <option v-if="props.placeholder" value="" disabled>{{ props.placeholder }}</option>
                <option v-for="option in options" :key="option.value || option.key" :value="option.key || option.value">
                    {{ option.name }}
                </option>
            </select>
            <div class="select-icon">
                <ChevronDownIcon class="h-5 w-5 text-gray-600" />
            </div>
        </div>
        <slot name="inputRight" />
    </div>
</template>

<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { ref, watch, onMounted } from 'vue';

const props = defineProps({
    options: {
        type: Array,
        default: () => []
    },
    selected: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: 'Select an option'
    },
    widthClass: {
        type: String,
        default: 'w-1/3'
    },
    inputRight: {
        type: Boolean,
        default: false
    }
});

const emits = defineEmits(['input']);

const selectedOption = ref('');

watch(
    () => props.selected,
    (newSelected) => {
        if (selectedOption.value !== newSelected) selectedOption.value = newSelected;
    }
);

watch(
    () => selectedOption.value,
    (newSelectedOption) => {
        if (props.selected !== newSelectedOption) emits('input', newSelectedOption);
    }
);

onMounted(() => {
    selectedOption.value = props.selected;
});
</script>

<style scoped></style>
