/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unexpected-multiline */
import { defineStore } from 'pinia';
import { reactive, computed, ref } from 'vue';
import _ from 'lodash';
import { useLoaderStore } from './loader.store';
import * as metricsApi from '../api/metrics.api';

export const useMetricsStore = defineStore('metrics', () => {
    let currentMetrics = reactive({
        sources: {},
        destinations: {},
        pipelines: {},
        transforms: {},
        topics: {}
    });

    const orgUsagePageNumber = ref(0);
    const orgUsagePageSize = ref(0);
    const orgUsagePageTotal = ref(0);

    const usageTableData = ref([]);
    const usageSummaryData = ref({});

    const globalSourceMetricConfigs = ref({});
    const globalDestinationMetricConfigs = ref({});

    // eslint-disable-next-line object-curly-newline
    const onMetricsData = (
        // eslint-disable-next-line object-curly-newline
        { entity_type, entity_id = null, level, metrics, time_type, task, topic, page = null, save_it = true, reset_content = false } = {},
        localObject = {},
        fromWebSocket = false
    ) => {
        // for now, time_type, task, topic as a params will be sent to this function only from the websockets
        // so we do the changes below to make sure we have the right structure like from the API

        //localObject is set and sent only when save_it is false

        const isTask = !!task || (!_.isNil(task) && !_.isNaN(Number(task)) && !metrics[task]);
        const isTopic = !!topic || (!_.isNil(topic) && !_.isNaN(Number(topic)) && !metrics[topic]);

        if (time_type && !metrics[time_type] && !isTask && !isTopic) {
            metrics = {
                [time_type]: metrics
            };
        }

        if (isTask) {
            metrics = {
                [task]: {
                    [time_type]: metrics
                }
            };
        }

        if (isTopic) {
            metrics = {
                [topic]: {
                    [time_type]: metrics
                }
            };
        }

        if (reset_content) {
            switch (level) {
                case 'task':
                    _.set(currentMetrics, [entity_type, entity_id, 'tasks'], {});
                    break;
                case 'topic':
                    _.set(currentMetrics, [entity_type, entity_id, 'topics'], {});
                    break;
                case 'connector':
                    _.set(currentMetrics, [entity_type, entity_id, 'connector'], {});
                    break;
                case 'inline':
                    _.set(currentMetrics, [entity_type, entity_id, 'inline'], {});
                    break;
                case 'account':
                    page
                        ? entity_id
                            ? _.set(currentMetrics, [entity_type, entity_id, 'account', page], {})
                            : _.set(currentMetrics, [entity_type, 'account', page], {})
                        : entity_id
                          ? _.set(currentMetrics, [entity_type, entity_id, 'account'], {})
                          : _.set(currentMetrics, [entity_type, 'account'], {});

                    break;
                case 'topics':
                    page
                        ? entity_id
                            ? _.set(currentMetrics, [entity_type, entity_id, 'topics', page], {})
                            : _.set(currentMetrics, [entity_type, 'topics', page], {})
                        : entity_id
                          ? _.set(currentMetrics, [entity_type, entity_id, 'topics'], {})
                          : _.set(currentMetrics, [entity_type, 'topics'], {});

                    break;
                default:
                    _.set(currentMetrics, [entity_type, entity_id], {});

                    break;
            }
        }

        // changes end here ^^

        _.forEach(time_type ? [time_type] : ['latest', 'timeseries', 'timesummary'], (granularity) => {
            for (let [entityName, entityMetrics] of Object.entries(metrics || {})) {
                // for  topics and tasks we have another level of metrics
                // so before getting to granularity, we get through tasks/topics
                if (!['latest', 'timeseries', 'timesummary'].includes(entityName)) {
                    // so here we are in the second level of data, that's why we say the granularity here
                    entityMetrics = entityMetrics?.[granularity];

                    if (!entityMetrics) {
                        continue;
                    }
                } else {
                    // if we are on the first level with granularity directly, we have to go forward only when same granularity as the one we want is present
                    if (entityName !== granularity) {
                        continue;
                    }
                }

                let path = [];
                switch (level) {
                    case 'task':
                        path = [entity_type, entity_id, 'tasks', entityName, granularity];
                        break;
                    case 'topic':
                        path = [entity_type, entity_id, 'topics', entityName, granularity];
                        break;
                    case 'connector':
                        path = [entity_type, entity_id, 'connector', granularity];
                        break;
                    case 'inline':
                        path = [entity_type, entity_id, 'inline', granularity];
                        break;
                    case 'account':
                        path = page
                            ? entity_id
                                ? [entity_type, entity_id, 'account', page, granularity]
                                : [entity_type, 'account', page, granularity]
                            : entity_id
                              ? [entity_type, entity_id, 'account', granularity]
                              : [entity_type, 'account', granularity];
                        break;
                    case 'topics':
                        path = page
                            ? entity_id
                                ? [entity_type, entity_id, 'topics', page, granularity]
                                : [entity_type, 'topics', page, granularity]
                            : entity_id
                              ? [entity_type, entity_id, 'topics', granularity]
                              : [entity_type, 'topics', granularity];
                        break;
                    default:
                        path = [entity_type, entity_id, granularity];
                        break;
                }

                const itemCurrentMetrics = save_it ? _.get(currentMetrics, path, {}) : {};
                const newMetrics = _.merge({}, itemCurrentMetrics, entityMetrics);

                // if it's data from the websocket, we don't have to update/add stuff on non-existing data, apart of transforms page and topics page, where we get the metrics on transforms/topics
                // and then we wait for updates on the websockets, even if empty
                // otherwise, it's from APIs, so we can update/add stuff on non-existing data
                if (
                    !fromWebSocket ||
                    (fromWebSocket && (!_.isEmpty(itemCurrentMetrics) || entity_type === 'transforms' || entity_type === 'topics'))
                ) {
                    _.set(save_it ? currentMetrics : localObject, path, newMetrics);
                }
            }
        });

        return localObject;
    };

    const loader = useLoaderStore();

    return {
        //WebSocket
        onMetricsData,
        organization: {
            setUsagePageNumber: (pageNumber) => {
                orgUsagePageNumber.value = pageNumber;
            },
            setUsagePageSize: (pageSize) => {
                orgUsagePageSize.value = pageSize;
            },
            total: computed(() => orgUsagePageTotal.value),
            page: computed(() => orgUsagePageNumber.value),
            pageSize: computed(() => orgUsagePageSize.value),
            usageTableData: computed(() =>
                Object.values(usageTableData.value).map((item) => {
                    return {
                        ...item,
                        metrics: _.keyBy(item.metrics, (i) => `${i.time_interval}-${i.time_unit}`)
                    };
                })
            ),
            usageSummaryData: computed(() => ({
                metrics: _.keyBy(usageSummaryData.value?.metrics, (i) => `${i.time_interval}-${i.time_unit}`)
            })),
            fetchOrgStatistics: async () => {
                loader.activateLoading('metrics.organization.fetch');
                try {
                    const data = await metricsApi.fetchOrgStatistics();

                    return data;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading('metrics.organization.fetch');
                }
            },
            fetchOrganisationUsageMetrics: async ({ page_size, page, sort, partial_name, tenant_ids }) => {
                loader.activateLoading('metrics.organization.fetchVolumeMetrics');
                try {
                    const data = await metricsApi.fetchOrganisationUsageMetrics({
                        page_size,
                        page: page + 1,
                        sort,
                        partial_name,
                        tenant_ids
                    });

                    orgUsagePageNumber.value = page;
                    orgUsagePageSize.value = data.page_size;
                    orgUsagePageTotal.value = data.total;

                    usageTableData.value = data.result;

                    return data?.result;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading('metrics.organization.fetchVolumeMetrics');
                }
            },
            fetchOrganisationUsageSummary: async (tenantIds = []) => {
                loader.activateLoading('metrics.organization.fetchUsageSummary');
                try {
                    const data = await metricsApi.fetchUsageSummary(tenantIds);

                    usageSummaryData.value = data;

                    return data;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading('metrics.organization.fetchUsageSummary');
                }
            },
            fetchBillingTimeseriesMetricsForAllConnectors: async ({ start = null, end = null, timeUnit = null, tenant_ids = [] } = {}) => {
                loader.activateLoading(`metrics.organisation.fetch.billing.timeseries`);

                try {
                    const [sourceMetrics, destinationMetrics] = await Promise.all([
                        metricsApi.fetchMetricsForSource(null, {
                            timestamp_from: start,
                            timestamp_to: end,
                            time_unit: timeUnit,
                            page: 'billing',
                            level: 'account',
                            time_type: 'timeseries',
                            tenant_ids,
                            time_interval: timeUnit ? 1 : null
                        }),
                        metricsApi.fetchMetricsForDestination(null, {
                            timestamp_from: start,
                            timestamp_to: end,
                            time_unit: timeUnit,
                            page: 'billing',
                            level: 'account',
                            time_type: 'timeseries',
                            time_interval: timeUnit ? 1 : null,
                            tenant_ids
                        })
                    ]);

                    let sourceAccountMetrics = sourceMetrics?.account_metrics;
                    let destinationAccountMetrics = destinationMetrics?.account_metrics;

                    let sourceLocalMetrics = {};
                    let destinationLocalMetrics = {};

                    let finalMetrics = {};

                    // Extract connector metrics
                    if (sourceAccountMetrics) {
                        sourceLocalMetrics = onMetricsData(
                            {
                                entity_type: 'sources',
                                entity_id: null,
                                page: 'billing',
                                level: 'account',
                                metrics: sourceAccountMetrics,
                                time_type: 'timeseries',
                                save_it: false
                            },
                            sourceLocalMetrics
                        );

                        finalMetrics.sources = getAllTimeseries(sourceLocalMetrics, 'sources');
                    }

                    if (destinationAccountMetrics) {
                        destinationLocalMetrics = onMetricsData(
                            {
                                entity_type: 'destinations',
                                entity_id: null,
                                page: 'billing',
                                level: 'account',
                                metrics: destinationAccountMetrics,
                                time_type: 'timeseries',
                                save_it: false
                            },
                            destinationLocalMetrics
                        );

                        finalMetrics.destinations = getAllTimeseries(destinationLocalMetrics, 'destinations');
                    }

                    return finalMetrics;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading(`metrics.organisation.fetch.billing.timeseries`);
                }
            },
            fetchUsageTimeseriesMetricsForAllConnectors: async ({ start = null, end = null, timeUnit = null } = {}) => {
                loader.activateLoading(`metrics.organisation.fetch.usage.timeseries`);

                try {
                    const [sourceMetrics, destinationMetrics] = await Promise.all([
                        metricsApi.fetchMetricsForSource(null, {
                            timestamp_from: start,
                            timestamp_to: end,
                            time_unit: timeUnit,
                            page: 'usage',
                            level: 'account',
                            time_type: 'timeseries',
                            time_interval: timeUnit ? 1 : null
                        }),
                        metricsApi.fetchMetricsForDestination(null, {
                            timestamp_from: start,
                            timestamp_to: end,
                            time_unit: timeUnit,
                            page: 'usage',
                            level: 'account',
                            time_type: 'timeseries',
                            time_interval: timeUnit ? 1 : null
                        })
                    ]);

                    let sourceAccountMetrics = sourceMetrics?.account_metrics;
                    let destinationAccountMetrics = destinationMetrics?.account_metrics;

                    let sourceLocalMetrics = {};
                    let destinationLocalMetrics = {};

                    let finalMetrics = {};

                    // Extract connector metrics
                    if (sourceAccountMetrics) {
                        sourceLocalMetrics = onMetricsData(
                            {
                                entity_type: 'sources',
                                entity_id: null,
                                page: 'usage',
                                level: 'account',
                                metrics: sourceAccountMetrics,
                                time_type: 'timeseries',
                                save_it: false
                            },
                            sourceLocalMetrics
                        );

                        finalMetrics.sources = getAllTimeseries(sourceLocalMetrics, 'sources');
                    }

                    if (destinationAccountMetrics) {
                        destinationLocalMetrics = onMetricsData(
                            {
                                entity_type: 'destinations',
                                entity_id: null,
                                page: 'usage',
                                level: 'account',
                                metrics: destinationAccountMetrics,
                                time_type: 'timeseries',
                                save_it: false
                            },
                            destinationLocalMetrics
                        );

                        finalMetrics.destinations = getAllTimeseries(destinationLocalMetrics, 'destinations');
                    }

                    return finalMetrics;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading(`metrics.organisation.fetch.usage.timeseries`);
                }
            },
            exportUsage: async (tenantIds = []) => {
                loader.activateLoading('metrics.organization.exportUsage');
                try {
                    const data = await metricsApi.exportUsage(tenantIds);

                    return data;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading('metrics.organization.exportUsage');
                }
            }
        },
        sources: {
            all: computed(() => {
                return _.get(currentMetrics, ['sources'], {});
            }),
            allLatest: computed(() => {
                return getAllLatest(currentMetrics, 'sources');
            }),
            allTimeseries: computed(() => {
                return getAllTimeseries(currentMetrics, 'sources');
            }),
            allTimesummaries: computed(() => {
                return getAllTimesummaries(currentMetrics, 'sources');
            }),
            globalConfigs: computed(() => {
                return globalSourceMetricConfigs.value;
            }),
            updateStatus: (id, status) => {
                var metric = _.get(_.cloneDeep(currentMetrics), ['sources', id, 'inline', 'latest'], {});

                if (metric?.connector_status) {
                    metric.connector_status = status;

                    _.set(currentMetrics, ['sources', id, 'inline', 'latest'], metric);
                }
            },
            fetch: async (
                id,
                {
                    start = null,
                    end = null,
                    timeUnit = null,
                    key = null,
                    time_type = null,
                    topic_list_from = null,
                    topic_list_size = null,
                    level = null
                } = {}
            ) => {
                level ? loader.activateLoading(`metrics.sources.fetch.${level}.${id}`) : loader.activateLoading(`metrics.sources.fetch.${id}`);

                try {
                    const {
                        connector_metrics = null,
                        inline_metrics = null,
                        task_metrics = null,
                        topic_metrics = null
                    } = await metricsApi.fetchMetricsForSource(id, {
                        topic_list_from,
                        topic_list_size,
                        level,
                        timestamp_from: start,
                        timestamp_to: end,
                        time_interval: timeUnit && time_type !== 'timesummary' ? 1 : null,
                        time_type,
                        time_unit: time_type !== 'timesummary' ? timeUnit : null
                    });
                    // Extract connector metrics
                    const saveMetrics = !key || key === 'allTime';

                    let localMetrics = {};

                    if (connector_metrics && connector_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'sources',
                                entity_id: id,
                                level: 'connector',
                                metrics: connector_metrics[id],
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    if (inline_metrics && inline_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'sources',
                                entity_id: id,
                                level: 'inline',
                                metrics: inline_metrics[id],
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    if (task_metrics && task_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'sources',
                                entity_id: id,
                                level: 'task',
                                metrics: task_metrics[id],
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    if (topic_metrics && topic_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'sources',
                                entity_id: id,
                                level: 'topic',
                                metrics: topic_metrics[id],
                                reset_content: true,
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    switch (time_type) {
                        case 'timeseries':
                            return getAllTimeseries(localMetrics, 'sources')?.[id];
                        case 'timesummary':
                            return getAllTimesummaries(localMetrics, 'sources')?.[id];
                        default:
                            return getAllLatest(localMetrics, 'sources')?.[id];
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    level
                        ? loader.deactivateLoading(`metrics.sources.fetch.${level}.${id}`)
                        : loader.deactivateLoading(`metrics.sources.fetch.${id}`);
                }
            },
            fetchAllInlineSourceMetrics: async (ids) => {
                loader.activateLoading(`metrics.sources.fetch`);

                if (ids && !_.isEmpty(currentMetrics.sources)) {
                    ids = _.filter(ids, (id) => !currentMetrics.sources[id]?.inline);
                }

                if (!ids || !ids.length) return;

                await metricsApi
                    .fetchMetricsForListOfSources(ids, { level: 'inline', time_type: 'latest' })
                    .then((data) => {
                        Object.entries(data || {}).forEach(([key, value]) => {
                            onMetricsData({
                                entity_type: 'sources',
                                entity_id: key,
                                level: 'inline',
                                metrics: value
                            });
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        loader.deactivateLoading(`metrics.sources.fetch`);
                    });
            },
            fetchTimeseriesData: async (id, { start = null, end = null, timeUnit = null, key = null } = {}) => {
                loader.activateLoading(`metrics.sources.fetch.timeseries.${id}`);
                const saveMetrics = !key || key === 'last24h';

                try {
                    const { connector_metrics, inline_metrics, task_metrics, topic_metrics } = await metricsApi.fetchMetricsForSource(id, {
                        timestamp_from: start,
                        timestamp_to: end,
                        time_unit: timeUnit,
                        time_type: 'timeseries',
                        time_interval: timeUnit ? 1 : null
                    });

                    let localMetrics = {};
                    // Extract connector metrics
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'sources',
                            entity_id: id,
                            level: 'connector',
                            metrics: connector_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    // Extract inline metrics
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'sources',
                            entity_id: id,
                            level: 'inline',
                            metrics: inline_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    // Extract tasks metrics { task_id: metrics }
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'sources',
                            entity_id: id,
                            level: 'task',
                            metrics: task_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    // Extract topic metrics { task_id: metrics }
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'sources',
                            entity_id: id,
                            level: 'topic',
                            metrics: topic_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    return getAllTimeseries(localMetrics, 'sources')?.[id];
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading(`metrics.sources.fetch.timeseries.${id}`);
                }
            },
            fetchGlobalSourceConfigs: async ({ mapping_type = 'metrics' } = {}) => {
                loader.activateLoading('metrics.sources.fetchGlobalSourceConfigs');
                try {
                    if (globalSourceMetricConfigs.value && !_.isEmpty(globalSourceMetricConfigs.value)) {
                        return globalSourceMetricConfigs.value;
                    }

                    const data = await metricsApi.fetchGlobalSourceConfigs({ mapping_type });
                    globalSourceMetricConfigs.value = data;

                    return data;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading('metrics.sources.fetchGlobalSourceConfigs');
                }
            }
        },
        destinations: {
            all: computed(() => {
                return _.get(currentMetrics, ['destinations'], {});
            }),
            allLatest: computed(() => {
                return getAllLatest(currentMetrics, 'destinations');
            }),
            allTimeseries: computed(() => {
                return getAllTimeseries(currentMetrics, 'destinations');
            }),
            allTimesummaries: computed(() => {
                return getAllTimesummaries(currentMetrics, 'destinations');
            }),
            globalConfigs: computed(() => {
                return globalDestinationMetricConfigs.value;
            }),
            updateStatus: (id, status) => {
                var metric = _.get(_.cloneDeep(currentMetrics), ['destinations', id, 'inline', 'latest'], {});

                if (metric?.connector_status) {
                    metric.connector_status = status;

                    _.set(currentMetrics, ['destinations', id, 'inline', 'latest'], metric);
                }
            },
            fetch: async (
                id,
                {
                    start = null,
                    end = null,
                    timeUnit = null,
                    key = null,
                    time_type = null,
                    topic_list_from = null,
                    topic_list_size = null,
                    level = null
                } = {}
            ) => {
                level
                    ? loader.activateLoading(`metrics.destinations.fetch.${level}.${id}`)
                    : loader.activateLoading(`metrics.destinations.fetch.${id}`);

                try {
                    const {
                        connector_metrics = null,
                        inline_metrics = null,
                        task_metrics = null,
                        topic_metrics = null
                    } = await metricsApi.fetchMetricsForDestination(id, {
                        topic_list_from,
                        topic_list_size,
                        level,
                        timestamp_from: start,
                        timestamp_to: end,
                        time_interval: timeUnit && time_type !== 'timesummary' ? 1 : null,
                        time_type,
                        time_unit: time_type !== 'timesummary' ? timeUnit : null
                    });
                    // Extract connector metrics
                    const saveMetrics = !key || key === 'allTime';

                    let localMetrics = {};

                    if (connector_metrics && connector_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'destinations',
                                entity_id: id,
                                level: 'connector',
                                metrics: connector_metrics[id],
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    if (inline_metrics && inline_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'destinations',
                                entity_id: id,
                                level: 'inline',
                                metrics: inline_metrics[id],
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    if (task_metrics && task_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'destinations',
                                entity_id: id,
                                level: 'task',
                                metrics: task_metrics[id],
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    if (topic_metrics && topic_metrics[id]) {
                        localMetrics = onMetricsData(
                            {
                                entity_type: 'destinations',
                                entity_id: id,
                                level: 'topic',
                                metrics: topic_metrics[id],
                                reset_content: true,
                                save_it: saveMetrics
                            },
                            localMetrics
                        );
                    }

                    switch (time_type) {
                        case 'timeseries':
                            return getAllTimeseries(localMetrics, 'destinations')?.[id];
                        case 'timesummary':
                            return getAllTimesummaries(localMetrics, 'destinations')?.[id];
                        default:
                            return getAllLatest(localMetrics, 'destinations')?.[id];
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    level
                        ? loader.deactivateLoading(`metrics.destinations.fetch.${level}.${id}`)
                        : loader.deactivateLoading(`metrics.destinations.fetch.${id}`);
                }
            },
            fetchTimeseriesData: async (id, { start = null, end = null, timeUnit = null, key = null } = {}) => {
                loader.activateLoading(`metrics.destinations.fetch.timeseries.${id}`);
                const saveMetrics = !key || key === 'last24h';

                try {
                    const { connector_metrics, inline_metrics, task_metrics, topic_metrics } = await metricsApi.fetchMetricsForDestination(id, {
                        timestamp_from: start,
                        timestamp_to: end,
                        time_unit: timeUnit,
                        time_type: 'timeseries',
                        time_interval: timeUnit ? 1 : null
                    });

                    let localMetrics = {};
                    // Extract connector metrics
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'destinations',
                            entity_id: id,
                            level: 'connector',
                            metrics: connector_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    // Extract inline metrics
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'destinations',
                            entity_id: id,
                            level: 'inline',
                            metrics: inline_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    // Extract tasks metrics { task_id: metrics }
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'destinations',
                            entity_id: id,
                            level: 'task',
                            metrics: task_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    // Extract topic metrics { task_id: metrics }
                    localMetrics = onMetricsData(
                        {
                            entity_type: 'destinations',
                            entity_id: id,
                            level: 'topic',
                            metrics: topic_metrics[id],
                            time_type: 'timeseries',
                            save_it: saveMetrics
                        },
                        localMetrics
                    );

                    return getAllTimeseries(localMetrics, 'destinations')?.[id];
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading(`metrics.destinations.fetch.timeseries.${id}`);
                }
            },
            fetchAllInlineDestinationMetrics: async (ids) => {
                loader.activateLoading(`metrics.destinations.fetch`);

                if (ids && !_.isEmpty(currentMetrics.destinations)) {
                    ids = _.filter(ids, (id) => !currentMetrics.destinations[id]?.inline);
                }

                if (!ids || !ids.length) return;

                await metricsApi
                    .fetchMetricsForListOfDestinations(ids, { level: 'inline', time_type: 'latest' })
                    .then((data) => {
                        Object.entries(data || {}).forEach(([key, value]) => {
                            onMetricsData({
                                entity_type: 'destinations',
                                entity_id: key,
                                level: 'inline',
                                metrics: value
                            });
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        loader.deactivateLoading(`metrics.destinations.fetch`);
                    });
            },
            fetchGlobalDestinationConfigs: async ({ mapping_type = 'metrics' } = {}) => {
                loader.activateLoading('metrics.destinations.fetchGlobalDestinationConfigs');
                try {
                    if (globalDestinationMetricConfigs.value && !_.isEmpty(globalDestinationMetricConfigs.value)) {
                        return globalDestinationMetricConfigs.value;
                    }

                    const data = await metricsApi.fetchGlobalDestinationConfigs({ mapping_type });
                    globalDestinationMetricConfigs.value = data;

                    return data;
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading('metrics.destinations.fetchGlobalDestinationConfigs');
                }
            }
        },
        pipelines: {
            all: computed(() => {
                return _.get(currentMetrics, ['pipelines'], {});
            }),
            allLatest: computed(() => {
                return getAllLatest(currentMetrics, 'pipelines');
            }),
            allTimeseries: computed(() => {
                return getAllTimeseries(currentMetrics, 'pipelines');
            }),
            allTimesummaries: computed(() => {
                return getAllTimesummaries(currentMetrics, 'pipelines');
            }),
            fetchInlinePipelineMetrics: async (ids, forceRefresh = false) => {
                loader.activateLoading(`metrics.pipelines.fetch`);

                if (!ids && !_.isEmpty(currentMetrics.pipelines)) {
                    ids = forceRefresh ? ids : _.filter(ids, (id) => !currentMetrics.pipelines[id]?.inline);
                }

                if (!ids || !ids.length) return;

                await metricsApi
                    .fetchMetricsForListOfPipelines(ids, { level: 'inline', time_type: 'latest' })
                    .then((data) => {
                        Object.entries(data || {}).forEach(([key, value]) => {
                            onMetricsData({
                                entity_type: 'pipelines',
                                entity_id: key,
                                level: 'inline',
                                metrics: value
                            });
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        loader.deactivateLoading(`metrics.pipelines.fetch`);
                    });
            },
            fetch: async (id) => {
                loader.activateLoading(`metrics.pipelines.fetch.${id}`);

                currentMetrics.pipelines = {};
                return metricsApi
                    .fetchMetricsForPipeline(id)
                    .then(({ connector_metrics, inline_metrics, task_metrics, topic_metrics }) => {
                        // Extract connector metrics
                        onMetricsData({
                            entity_type: 'pipelines',
                            entity_id: id,
                            level: 'connector',
                            metrics: connector_metrics[id]
                        });

                        // Extract inline metrics
                        onMetricsData({
                            entity_type: 'pipelines',
                            entity_id: id,
                            level: 'inline',
                            metrics: inline_metrics[id]
                        });

                        // Extract tasks metrics { task_id: metrics }
                        onMetricsData({
                            entity_type: 'pipelines',
                            entity_id: id,
                            level: 'task',
                            metrics: task_metrics[id]
                        });

                        // Extract topic metrics { task_id: metrics }
                        onMetricsData({
                            entity_type: 'pipelines',
                            entity_id: id,
                            level: 'topic',
                            metrics: topic_metrics[id]
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        loader.deactivateLoading(`metrics.pipelines.fetch.${id}`);
                    });
            }
        },
        transforms: {
            all: computed(() => {
                return _.get(currentMetrics, ['transforms'], {});
            }),
            allLatest: computed(() => {
                return getAllLatest(currentMetrics, 'transforms');
            })
        },
        topics: {
            all: computed(() => {
                return _.get(currentMetrics, ['topics'], {});
            }),
            allLatest: computed(() => {
                return getAllLatest(currentMetrics, 'topics');
            }),
            fetch: async ({ start = null, end = null, timeUnit = null, time_type = 'timeseries' } = {}) => {
                loader.activateLoading(`metrics.topics.fetch.all`);

                try {
                    const data = await metricsApi.fetchMetricsForTopics({
                        timestamp_from: start,
                        timestamp_to: end,
                        time_interval: timeUnit && time_type !== 'timesummary' ? 1 : null,
                        time_type,
                        time_unit: time_type !== 'timesummary' ? timeUnit : null
                    });

                    let localMetrics = {};

                    localMetrics = onMetricsData(
                        {
                            entity_type: 'topics',
                            entity_id: null,
                            level: 'topics', // we add this ourselves so we separate it out from the source topic metrics
                            metrics: data,
                            page: 'topics_page',
                            time_type,
                            save_it: false
                        },
                        localMetrics
                    );

                    switch (time_type) {
                        case 'timeseries':
                            return getAllTimeseries(localMetrics, 'topics');
                        case 'timesummary':
                            return getAllTimesummaries(localMetrics, 'topics');
                        default:
                            return getAllLatest(localMetrics, 'topics');
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    loader.deactivateLoading(`metrics.topics.fetch.all`);
                }
            }
        }
    };
});

const getAllTimeseries = (metrics, type) => {
    if (!type) throw new Error('Type is required');

    let result = _.get(_.cloneDeep(metrics), [type], {});

    _.forEach(result, (_value, key) => {
        // Iterate over all properties of the current object
        _.forEach(result[key], (_value, key2) => {
            // Additional check for 'topics'
            if ((key2 === 'topics' || key2 === 'tasks') && result[key][key2]) {
                _.forEach(result[key][key2], (second_level) => {
                    delete second_level.latest;
                    delete second_level.timesummary;
                });
            }

            // Delete the 'timeseries' property
            delete result[key][key2].latest;
            delete result[key][key2].timesummary;
        });

        // Iterate over all properties of the current object
        _.forEach(result[key], (_value, key2) => {
            // Additional check for 'topics'
            if ((key2 === 'topics' || key2 === 'tasks') && result[key][key2]) {
                _.forEach(result[key][key2], (second_level) => {
                    _.extend(second_level, second_level.timeseries);

                    delete second_level.timeseries;
                });
            }

            // Delete the 'timeseries' property
            _.extend(result[key][key2], result[key][key2].timeseries);
            delete result[key][key2].timeseries;
        });
    });

    return result;
};

const getAllLatest = (metrics, type) => {
    if (!type) throw new Error('Type is required');

    let result = _.get(_.cloneDeep(metrics), [type], {});

    _.forEach(result, (_value, key) => {
        // Iterate over all properties of the current object
        _.forEach(result[key], (_value, key2) => {
            // Additional check for 'topics'
            if ((key2 === 'topics' || key2 === 'tasks') && result[key][key2]) {
                _.forEach(result[key][key2], (second_level) => {
                    delete second_level.timeseries;
                    delete second_level.timesummary;
                });
            }

            // Delete the 'timeseries' property
            delete result[key][key2].timeseries;
            delete result[key][key2].timesummary;
        });

        // Iterate over all properties of the current object
        _.forEach(result[key], (_value, key2) => {
            // Additional check for 'topics'
            if ((key2 === 'topics' || key2 === 'tasks') && result[key][key2]) {
                _.forEach(result[key][key2], (second_level) => {
                    _.extend(second_level, second_level.latest);

                    delete second_level.latest;
                });
            }

            // Delete the 'timeseries' property
            _.extend(result[key][key2], result[key][key2].latest);
            delete result[key][key2].latest;
        });
    });

    return result;
};

const getAllTimesummaries = (metrics, type) => {
    if (!type) throw new Error('Type is required');

    let result = _.get(_.cloneDeep(metrics), [type], {});

    _.forEach(result, (_value, key) => {
        // Iterate over all properties of the current object
        _.forEach(result[key], (_value, key2) => {
            // Additional check for 'topics'
            if ((key2 === 'topics' || key2 === 'tasks') && result[key][key2]) {
                _.forEach(result[key][key2], (second_level) => {
                    delete second_level.timeseries;
                    delete second_level.latest;
                });
            }

            // Delete the 'timeseries' property
            delete result[key][key2].timeseries;
            delete result[key][key2].latest;
        });

        // Iterate over all properties of the current object
        _.forEach(result[key], (_value, key2) => {
            // Additional check for 'topics'
            if ((key2 === 'topics' || key2 === 'tasks') && result[key][key2]) {
                _.forEach(result[key][key2], (second_level) => {
                    _.extend(second_level, second_level.timesummary);

                    delete second_level.timesummary;
                });
            }

            // Delete the 'timeseries' property
            _.extend(result[key][key2], result[key][key2].timesummary);
            delete result[key][key2].timesummary;
        });
    });

    return result;
};
