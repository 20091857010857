import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import * as pipelinesApi from '../api/pipelines.api';
import { useLoaderStore } from './loader.store';
import { useMetricsStore } from './metrics.store';
import _ from 'lodash';
import { useRouter } from 'vue-router';

export const usePipelinesStore = defineStore('pipelines', () => {
    const pipelines = ref(undefined);
    const allPipelinesBrief = ref(undefined);
    const total = ref(0);
    const page = ref(0);
    const lastSearchQuery = ref(null);
    const pageSize = ref(0);
    const loader = useLoaderStore();
    const router = useRouter();

    return {
        // State
        pipelines,
        allPipelinesBrief,
        // Getters
        all: computed(() => pipelines.value),
        allBrief: computed(() => allPipelinesBrief.value),
        total: computed(() => total.value),
        page: computed(() => page.value),
        pageSize: computed(() => pageSize.value),
        byId: (id) =>
            computed(() => {
                const pipeline = _.find(pipelines.value, { id });
                return pipeline;
            }),
        // Actions
        getById: async function (id, customMessage) {
            if (!id) return;

            const found = _.find(pipelines.value, { id });

            if (found) {
                return found;
            }

            loader.activateLoading('pipeline.fetch');

            const result = await pipelinesApi
                .getPipeline(id)
                .then((data) => {
                    if (!data) {
                        router.push({ name: '404', params: { customMessage } });
                        return;
                    }

                    pipelines.value = pipelines?.value?.map((pipe) => {
                        if (pipe.id === id) {
                            return {
                                ...pipe,
                                ...data
                            };
                        }

                        return pipe;
                    });

                    return data;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    loader.deactivateLoading('pipeline.fetch');
                });

            return result;
        },
        fetchCreated: async function (pageNumber, pageSizeNumber, searchQuery = null) {
            if (
                !searchQuery &&
                searchQuery !== '' &&
                !lastSearchQuery.value &&
                lastSearchQuery.value !== '' &&
                pageNumber === page.value &&
                !_.isEmpty(pipelines.value) &&
                pageSize.value === pageSizeNumber
            )
                return pipelines.value;

            loader.activateLoading('pipelines.fetch');
            return pipelinesApi
                .fetchPipelines(pageNumber + 1, pageSizeNumber, searchQuery)
                .then(async (data) => {
                    pipelines.value = data.result;

                    page.value = pageNumber;
                    total.value = data.total;
                    lastSearchQuery.value = searchQuery;
                    pageSize.value = data.page_size;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('pipelines.fetch');
                });
        },
        fetch: async function (pageNumber, pageSizeNumber, fetchMetrics = true) {
            if (pageNumber === page.value && !_.isEmpty(pipelines.value) && pageSize.value === pageSizeNumber) return pipelines.value;

            loader.activateLoading('pipelines.fetch');
            return pipelinesApi
                .fetchPipelines(pageNumber, pageSizeNumber)
                .then(async (data) => {
                    if (Array.isArray(data.result) && fetchMetrics) {
                        const pipelineIds = data.result.map((pipeline) => pipeline.id);
                        const sourceIds = data.result.map((pipeline) => pipeline.source.id);
                        const destinationIds = data.result.map((pipeline) => pipeline.destination.id);

                        const metricsStore = useMetricsStore();

                        await Promise.all([
                            metricsStore.pipelines.fetchInlinePipelineMetrics(pipelineIds),
                            metricsStore.destinations.fetchAllInlineDestinationMetrics(destinationIds),
                            metricsStore.sources.fetchAllInlineSourceMetrics(sourceIds)
                        ]);
                    }

                    page.value = pageNumber;
                    pipelines.value = data.result;
                    total.value = data.total;
                    pageSize.value = data.page_size;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('pipelines.fetch');
                });
        },
        fetchAllBrief: async function (fetchMetrics = true) {
            loader.activateLoading('pipelines.fetchAllBrief');
            return pipelinesApi
                .fetchAllPipelineBrief()
                .then(async (data) => {
                    if (Array.isArray(data) && fetchMetrics) {
                        const pipelineIds = data.map((pipeline) => pipeline.entity_id);
                        const sourceIds = data.map((pipeline) => pipeline.source.id);
                        const destinationIds = data.map((pipeline) => pipeline.destination.id);
                        const metricsStore = useMetricsStore();
                        await Promise.all([
                            metricsStore.pipelines.fetchInlinePipelineMetrics(pipelineIds),
                            metricsStore.destinations.fetchAllInlineDestinationMetrics(destinationIds),
                            metricsStore.sources.fetchAllInlineSourceMetrics(sourceIds)
                        ]);
                    }

                    allPipelinesBrief.value = data.map((pipeline) => ({
                        ...pipeline,
                        id: pipeline.id || pipeline._id?.$oid,
                        source: {
                            ...pipeline.source,
                            id: pipeline.source.id || pipeline.source._id?.$oid
                        },
                        destination: {
                            ...pipeline.destination,
                            id: pipeline.destination.id || pipeline.destination._id?.$oid
                        }
                    }));
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading('pipelines.fetch');
                });
        },
        delete: async function (id) {
            loader.activateLoading(`pipelines.delete`);
            return pipelinesApi
                .deletePipeline(id)
                .then(() => {
                    pipelines.value = pipelines?.value.filter((pipeline) => pipeline.id !== id);
                    allPipelinesBrief.value = allPipelinesBrief?.value.filter((pipeline) => pipeline.id !== id);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`pipelines.delete`);
                });
        },
        create: async function (connection) {
            loader.activateLoading('pipelines.create');
            return pipelinesApi
                .createPipeline(connection)
                .then((data) => {
                    if (!pipelines.value) {
                        pipelines.value = [];
                    }

                    if (!allPipelinesBrief.value) {
                        allPipelinesBrief.value = [];
                    }

                    pipelines.value.unshift(data);
                    allPipelinesBrief.value.unshift(data);

                    if (pipelines.value?.length > pageSize.value) {
                        pipelines.value = pipelines.value.slice(0, pageSize.value);
                    }

                    window.analytics?.track('Pipeline Created', {
                        date: new Date(),
                        source: data.source.id,
                        destination: data.destination.id,
                        id: data.id,
                        name: data.name
                    });

                    const metricsStore = useMetricsStore();

                    metricsStore.pipelines.fetchInlinePipelineMetrics([data.id]);
                    metricsStore.sources.fetchAllInlineSourceMetrics([data.source.id]);
                    metricsStore.destinations.fetchAllInlineDestinationMetrics([data.destination.id]);
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('pipelines.create');
                });
        },
        // pause: async function (id) {
        //     loader.activateLoading(`pipelines.pause.${id}`);
        //     return pipelinesApi
        //         .pausePipelineConnection(id)
        //         .then((data) => {
        //             console.log('pause', id, data);
        //             pipelines.value = pipelines?.value.filter((source) => source.id !== id);
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //         })
        //         .finally(() => {
        //             loader.deactivateLoading(`pipelines.delete`);
        //         });
        // },
        // resume: async function (id) {
        //     loader.activateLoading(`pipelines.resume.${id}`);
        //     return pipelinesApi
        //         .resumePipelineConnection(id)
        //         .then((data) => {
        //             console.log('resume', id, data);
        //             pipelines.value = pipelines?.value.filter((source) => source.id !== id);
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //         })
        //         .finally(() => {
        //             loader.deactivateLoading(`pipelines.resume.${id}`);
        //         });
        // },
        restart: async function (id) {
            loader.activateLoading(`pipelines.restart.${id}`);
            return pipelinesApi
                .restartPipelineConnection(id)
                .then(() => {})
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    loader.deactivateLoading(`pipelines.restart.${id}`);
                });
        },
        edit: async function (connection, id) {
            return pipelinesApi
                .editPipeline(connection, id)
                .then((data) => {
                    pipelines.value = pipelines?.value?.map((pipe) => {
                        if (pipe.id === id) {
                            return {
                                ...pipe,
                                ...connection,
                                ...data
                            };
                        }

                        return pipe;
                    });

                    return data;
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
                .finally(() => {
                    loader.deactivateLoading('pipelines.edit');
                });
        }
    };
});
