<script>
import ModalComponent from './global/modals/Modal.vue';
import AppLoader from '../components/AppLoader.vue';
import SQLViewsTab from '../components/SQLViewsTab.vue';

export default {
    components: {
        ModalComponent,
        AppLoader,
        SQLViewsTab
    },
    props: {
        isModalOpen: {
            type: Boolean,
            default: false
        },
        scripts: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    watch: {
        isModalOpen: {
            handler(val) {
                this.isOpen = val;
            }
        }
    },
    created() {
        this.isOpen = this.isModalOpen;
    },
    mounted() {}
};
</script>

<template>
    <ModalComponent
        :is-open="isOpen"
        :show-footer-buttons="false"
        title="Topic Views"
        :size="'4xl'"
        position="start"
        @update:is-open="
            isOpen = $event;
            $emit('update:isOpen', false);
        ">
        <template #header></template>
        <div v-if="loading" class="table-loader min-h-[100px]">
            <AppLoader :force-loading="loading" default-height="inherit" />
        </div>
        <div v-else>
            <SQLViewsTab v-if="scripts" :scripts="scripts" :is-modal="true" />
        </div>
    </ModalComponent>
</template>
