import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';

import { Frontegg } from '@frontegg/vue';

import Config from './utils/config';
import { SentryService } from './services';
import 'bootstrap';
import './assets/styles/main.scss';
import './style.css';
import * as ConfirmDialog from 'vuejs-confirm-dialog';
import directives from './directives/';
import Vue3Toastify from 'vue3-toastify';
import VueDatePicker from '@vuepic/vue-datepicker';
import ClickOutside from './directives/ClickOutside/click-outside.js';
import AnnounceKit from 'announcekit-vue';
import { loadCello } from './services/cello.service';
import { Permissions, Roles } from './utils/constants';

const app = createApp(App);

const store = createPinia();

app.use(Frontegg, {
    contextOptions: {
        baseUrl: Config.frontegg.baseUrl,
        clientId: Config.frontegg.clientId
    },
    authOptions: {
        keepSessionAlive: true,
        enforceRedirectToSameSite: true,
        allowedRedirectOrigins: [
            'https://local.streamkap.com',
            'https://local.streamkap.com:443',
            'https://local.streamkap.com:9443',
            'https://dev.streamkap.com',
            'https://prod.streamkap.com',
            'https://streamkap.com',
            'https://app.streamkap.com'
        ]
    },
    hostedLoginBox: false,
    router
});

app.use(store);
app.use(router);

if (Config.app.isProd) {
    SentryService.setup(app, router);
}

console.log('APP_ENV:', Config.app.env);
console.log('APP_VERSION:', Config.app.version);

app.use(ConfirmDialog);
app.use(AnnounceKit);

app.use(Vue3Toastify, {
    autoClose: 3000
});

// register all directives
directives(app);

app.component('VueDatePicker', VueDatePicker);
app.directive('click-outside', ClickOutside);

// Attach the Permissions ENUM to the global properties
app.config.globalProperties.$Permissions = Permissions;
app.config.globalProperties.$Roles = Roles;

loadCello()
    .then(() => {
        console.log('Cello script loaded successfully');
        app.mount('#app');
    })
    .catch((error) => {
        console.error('Failed to load Cello script:', error);
        app.mount('#app'); // Optionally mount the app even if the script fails to load
    });
