<script setup>
import { useSourcesStore } from '../../stores/sources.store';
import { useMetricsStore } from '../../stores/metrics.store';
import AppLoader from '../../components/AppLoader.vue';
import _ from 'lodash';
import { computed, ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '../../components/AppFooter.vue';
import HighChart from '../../components/HighChart.vue';
import { ClockIcon, ExclamationCircleIcon, ChevronDoubleLeftIcon, ChevronRightIcon, CheckCircleIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import RowActionMenu from '../../components/RowActionMenu.vue';
import TableActionMenu from '../../components/TableActionMenu.vue';
import Pagination from '../../components/Pagination.vue';
import DropdownFilters from '../../components/DropdownFilters.vue';
import useConfirmBeforeAction from '../../composables/useConfirmBeforeAction.js';
import useConfirmBeforeWarning from '../../composables/useConfirmBeforeWarning.js';
import { usePipelinesStore } from '../../stores/pipelines.store';
import { useRouter, useRoute } from 'vue-router';
import {
    getStatusColor,
    formatter,
    getMetricFormattingInfo,
    getAllMetricsForCategoryAndLevel,
    userHasAccess,
    adjustControlConditionalValues,
    adjustControlConditionalVisibility
} from '../../utils/utils';
import Multiselect from 'vue-multiselect';
import TreeView from '../../components/TreeView.vue';
import ConnectorTab from '../../components/ConnectorTab.vue';
import ErrorMessage from '../../components/ErrorMessage.vue';
import WebSocketService from '../../services/webSocket.service';
import { DATE_FILTER_OPTIONS, NO_SNAPSHOT_CONNECTORS } from '../../utils/constants';
import { buildTreeNodesOutOfExistingConfig } from '../../utils/utils';
import StatCard from '@/components/global/StatCard.vue';
import Tabs from '@/components/global/Tabs.vue';
import DateFilter from '@/components/global/DateFilter.vue';
import DynamicField from '../../components/DynamicField.vue';
import moment from 'moment-timezone';
import { onBeforeRouteLeave } from 'vue-router';
import { useAuthStore } from '@/stores/auth.store';
import { toast } from 'vue3-toastify';

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const props = defineProps(['id']);
const sourcesStore = useSourcesStore();
const metrics = useMetricsStore();
const pipelines = usePipelinesStore();

let source = ref();
let defaultSource = ref({});

let generalLoading = ref(false);
let configurationLoading = ref(false);
let treeLoading = ref(false);
let metricsLoading = ref(false);
let timeseriesLoading = ref(false);
let hidePromptTemporary = ref(false);

let tabLoading = ref(false);
let actionLoading = ref(false);
let topicActionLoading = ref({});
let topicsLoading = ref(false);
let sourceConfig = ref();
let formError = ref('');

let canSave = ref(false);
let canSaveSchemaTree = ref(false);

let treeLevels = ref([]);
let treeNodes = ref([]);

let defaultTreeLevels = ref([]);
let defaultTreeNodes = ref([]);

let page = ref(0);
let pageSize = ref(0);
let checkedTopicFilters = ref([]);
let selectedTimeseriesDateFilter = ref({});
let selectedTopicsDateFilter = ref({});
let noExistingSchema = ref(false);
const checkedTopics = ref({});
const allTopicsAreChecked = ref(false);

let allSelectedMetricsForChart = ref([]);
let plotSpecificMetrics = ref(null);
let specificTopicMetricsToUse = ref(null);

watch(
    [treeLevels, treeNodes],
    (values) => {
        canSaveSchemaTree.value = !_.isEqual(values[0], defaultTreeLevels.value) || !_.isEqual(values[1], defaultTreeNodes.value);
    },
    { deep: true, immediate: true }
);

const canSaveButton = computed(() => {
    return canSave.value || canSaveSchemaTree.value;
});

onBeforeRouteLeave((to, from, next) => {
    if (
        generalLoading.value ||
        configurationLoading.value ||
        treeLoading.value ||
        metricsLoading.value ||
        timeseriesLoading.value ||
        tabLoading.value
    ) {
        return next();
    }

    if (!canSaveButton.value) {
        return next();
    }

    const answer = window.confirm('Are you sure you want to leave? You have unsaved changes.');

    if (answer) {
        return next();
    } else {
        return next(false);
    }
});

const visibleConnectorSchemaConfig = computed(() => {
    const connectorConfig =
        sourceConfig.value?.filter((x) => !x.display_advanced).filter((x) => x.tab === 'schema' && !x.schema_level && !x.schema_name_format) || [];

    return connectorConfig;
});

const visibleSchemaConnectorConfigControls = computed(() => {
    return _.chain(adjustControlConditionalValues(visibleConnectorSchemaConfig.value))
        .filter((x) => !x.display_advanced)
        .filter((x) => x.tab !== 'schema')
        .orderBy('order_of_display', 'asc')
        .map((control) => adjustControlConditionalVisibility(control, visibleConnectorSchemaConfig.value)?.control || null)
        .compact()
        .value();
});

const getClass = (control, index, advanced = false) => {
    const spaceLeft = control.space_left;
    const usedConnectors = advanced ? visibleAdvancedConnectorConfigControls.value : visibleBasicConnectorConfigControls.value;

    const nextControlSpaceLeft = usedConnectors[index + 1]?.space_left;
    return spaceLeft ? (!nextControlSpaceLeft ? 'pl-5 mb-10' : 'pl-5 mb-5') : 'mb-5';
};

const filters = [
    {
        title: 'Topics per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            { value: 5, displayValue: '5/page' },
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
            // { value: 100, displayValue: '100/page' },
            // { value: 200, displayValue: '200/page' },
            // { value: 500, displayValue: '500/page' }
        ]
    }
];
initializeTopicsFilters();

function updateCanSave(newValue) {
    canSave.value = newValue;
}

onBeforeUnmount(() => {
    removeWebSocketMessage();
});

onMounted(async () => {
    try {
        generalLoading.value = true;
        treeLoading.value = true;
        configurationLoading.value = true;
        metricsLoading.value = true;

        const tabQuery = route.query.tab;

        // topic related queries
        const topicTableDateFilterQuery = route.query.selectedTopicsDateFilter;
        const topicTableGeneralFilterQuery = route.query.topicFilter ? JSON.parse(route.query.topicFilter) : null;

        // timeseries related queries
        const selectedTimeseriesMetricsQuery = route.query.selectedTimeseriesMetrics;
        const selectedTimeseriesDateFilterQuery = route.query.selectedTimeseriesDateFilter;

        setCurrentTab(tabQuery || 'Status');

        // topic related queries
        selectedTopicsDateFilter.value = topicTableDateFilterQuery ? JSON.parse(topicTableDateFilterQuery) : selectedTopicsDateFilter.value;
        checkedTopicFilters.value = topicTableGeneralFilterQuery
            ? { ...topicTableGeneralFilterQuery?.filter, [topicTableGeneralFilterQuery?.filter?.name]: [topicTableGeneralFilterQuery?.filterOption] }
            : checkedTopicFilters.value;

        // timeseries related queries
        allSelectedMetricsForChart.value = selectedTimeseriesMetricsQuery
            ? JSON.parse(selectedTimeseriesMetricsQuery)
            : allSelectedMetricsForChart.value;
        selectedTimeseriesDateFilter.value = selectedTimeseriesDateFilterQuery
            ? JSON.parse(selectedTimeseriesDateFilterQuery)
            : selectedTimeseriesDateFilter.value;

        metricsLoading.value = true;

        let metricsCallToDo = [
            metrics.sources
                .fetch(props.id, {
                    topic_list_from: page.value * pageSize.value,
                    topic_list_size: pageSize.value,
                    level: 'connector',
                    time_type: !_.isEmpty(selectedTimeseriesDateFilter.value) ? 'latest' : null
                })
                .then(() => (metricsLoading.value = false))
        ];

        if (!_.isEmpty(selectedTimeseriesDateFilter.value)) {
            timeseriesLoading.value = true;
            metricsCallToDo.push(
                handleTimeseriesDateFilterInput({ ...selectedTimeseriesDateFilter.value, value: selectedTimeseriesDateFilter.value }).then(
                    () => (timeseriesLoading.value = false)
                )
            );
        }

        if (topicTableGeneralFilterQuery) {
            topicsLoading.value = true;
            metricsCallToDo.push(onTopicsFilterToggle(topicTableGeneralFilterQuery).then(() => (topicsLoading.value = false)));
        } else if (topicTableDateFilterQuery) {
            topicsLoading.value = true;
            metricsCallToDo.push(
                handleTopicsDateFilterInput({ ...selectedTopicsDateFilter.value, value: selectedTopicsDateFilter.value }).then(
                    () => (topicsLoading.value = false)
                )
            );
        } else {
            topicsLoading.value = true;
            metricsCallToDo.push(
                metrics.sources
                    .fetch(props.id, {
                        topic_list_from: page.value * pageSize.value,
                        topic_list_size: pageSize.value,
                        level: 'topic'
                    })
                    .then(() => (topicsLoading.value = false))
            );
        }

        await Promise.all([
            sourcesStore.getById(props.id, route?.query?.customMessage).then((data) => {
                source.value = JSON.parse(JSON.stringify(data ?? {}));
                defaultSource.value = _.cloneDeep(source.value);

                if (route?.query?.customMessage && data) {
                    router.push({ query: { ...route.query, customMessage: undefined } });
                }

                generalLoading.value = false;
            }),
            sourcesStore.fetchConnectors(),
            ...metricsCallToDo
        ]);

        await sourcesStore.fetchConfiguration(source.value.connector).then(() => {
            sourceConfig.value = sourcesStore.configurations[source.value.connector];
            treeLoading.value = false;

            if (sourceConfig.value?.some((cfg) => cfg.tab === 'schema')) {
                constructTreeNodes();
                defaultTreeLevels.value = _.cloneDeep(treeLevels.value);
                defaultTreeNodes.value = _.cloneDeep(treeNodes.value);
            } else {
                noExistingSchema.value = true;
            }
        });
    } finally {
        generalLoading.value = false;
        configurationLoading.value = false;
        metricsLoading.value = false;
        treeLoading.value = false;
        tabLoading.value = false;
    }

    addWebSocketMessage();
});

const addWebSocketMessage = () => {
    WebSocketService.addListener(
        'metrics',
        'sources',
        props.id,
        metrics.onMetricsData,
        'metrics.onMetricsData',
        page.value * pageSize.value,
        pageSize.value
    );
};

const removeWebSocketMessage = () => {
    WebSocketService.removeListener('metrics', 'sources', props.id, 'metrics.onMetricsData', page.value * pageSize.value, pageSize.value);
};

const constructTreeNodes = () => {
    let configProps = source.value.config;
    let sourceConnector = Object.values(sourcesStore.sourceConnectors)
        .flat()
        .find((x) => x.connector == source.value.connector);

    const nodes = buildTreeNodesOutOfExistingConfig(configProps, sourceConfig.value, sourceConnector);

    treeNodes.value = nodes.treeNodes;
    treeLevels.value = nodes.treeLevels;
};

const connectorInfo = computed(() => {
    return Object.values(sourcesStore.sourceConnectors || {})
        .flat()
        .find((c) => c.connector === source.value?.connector);
});

const sourceLatestMetrics = computed(() => {
    return metrics.sources.allLatest[props.id] || {};
});

const sourceTimeseriesConnectorMetrics = computed(() => {
    return metrics.sources.allTimeseries[props.id]?.connector || {};
});

const isDefaultTopicDateFilter = computed(() => {
    return selectedTopicsDateFilter.value.key === 'allTime' || !selectedTopicsDateFilter.value || !selectedTopicsDateFilter.value?.key;
});

const topics = (useSpecificMetrics) => {
    const compValue = computed(() => {
        return (
            Object.keys(useSpecificMetrics?.topics || sourceLatestMetrics?.value?.topics || {})
                ?.map((t) => {
                    const topicName = source.value?.topics?.find(
                        (x) => t.toLowerCase().includes(x.toLowerCase()) && t.split(x)?.[1] === '' // || !t.split(x)?.[1])
                    );

                    return {
                        id: t,
                        name: topicName
                        // snapshotStatus: topicMetric?.status,
                        // snapshotDisplayStatus: _.capitalize(topicMetric?.status),
                        // snapshotSubmitTimestamp: topicMetric?.submit_timestamp
                    };
                })
                .filter((x) => x) || []
        );
    });

    return compValue.value;
};

const listOfTimeseriesMetricOptions = computed(() => {
    if (generalLoading.value || timeseriesLoading.value) return [];

    const defaultDateFilter =
        selectedTimeseriesDateFilter.value.key === 'last24h' || !selectedTimeseriesDateFilter.value || !selectedTimeseriesDateFilter.value?.key;
    const metricsToUse = defaultDateFilter ? sourceTimeseriesConnectorMetrics.value : plotSpecificMetrics.value?.connector;

    let availableTimeSeriesMetricKeys = Object.keys(metricsToUse || {});

    availableTimeSeriesMetricKeys = availableTimeSeriesMetricKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, connectorInfo.value?.metrics);

            if (!metricInfo) {
                return null;
            }

            return {
                key,
                ...metricInfo
            };
        })
        .filter((x) => x && metricsToUse[x.key]?.length);

    if (
        !availableTimeSeriesMetricKeys?.length ||
        (availableTimeSeriesMetricKeys?.length &&
            allSelectedMetricsForChart.value?.map((x) => x.key).some((x) => !availableTimeSeriesMetricKeys.map((y) => y.key).includes(x)))
    ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        allSelectedMetricsForChart.value = [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        delete route.query.selectedTimeseriesMetrics;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        router.push(route.query);
    }

    return _.orderBy(availableTimeSeriesMetricKeys, [(item) => item.key !== 'sourceRecordWriteTotal'], ['asc']);
});

watch(
    () => listOfTimeseriesMetricOptions.value,
    (newValue) => {
        if (!allSelectedMetricsForChart.value.length && newValue.length) {
            allSelectedMetricsForChart.value = [newValue[0]];
        }
    }
);

watch(
    () => allSelectedMetricsForChart.value,
    (newValue) => {
        if (newValue.length) {
            router.push({ query: { ...route.query, selectedTimeseriesMetrics: JSON.stringify(newValue) } });
        } else {
            delete route.query.selectedTimeseriesMetrics;
            router.push({ query: route.query });
        }
    },
    { deep: true }
);

const availableMetrics = computed(() => {
    if (isDefaultTopicDateFilter.value && (!connectorInfo.value?.metrics || _.isEmpty(connectorInfo.value?.metrics))) return null;

    //const streamingMetrics = getAllMetricsForCategoryAndLevel(connectorInfo.value?.metrics, 'streaming', null, 'sources');
    const topicMetrics = getAllMetricsForCategoryAndLevel(connectorInfo.value?.metrics, null, 'topic', 'sources');

    return {
        // streaming: streamingMetrics,
        topic: topicMetrics
    };
});

async function loadSourceUponAction() {
    tabLoading.value = true;
    try {
        metricsLoading.value = true;

        await Promise.all([
            sourcesStore.fetchConnectors(),
            metrics.sources.fetch(props.id, { topic_list_from: page.value * pageSize.value, topic_list_size: pageSize.value })
        ]);

        const sourceVal = await sourcesStore.getById(props.id, null, true);

        source.value = JSON.parse(JSON.stringify(sourceVal ?? {}));
        defaultSource.value = _.cloneDeep(source.value);

        constructTreeNodes();
    } catch (e) {
        console.error('Error in loadSourceUponAction:', e);
    } finally {
        tabLoading.value = false;
        metricsLoading.value = false;
    }
}

async function onStop(id) {
    try {
        actionLoading.value = true;
        await sourcesStore.stop(id);
        await loadSourceUponAction();
    } finally {
        actionLoading.value = false;
    }
}

async function refreshTimeseries() {
    timeseriesLoading.value = true;
    const defaultDateFilter =
        selectedTimeseriesDateFilter.value.key === 'last24h' || !selectedTimeseriesDateFilter.value || !selectedTimeseriesDateFilter.value?.key;

    try {
        const timeseriesData = await metrics.sources.fetchTimeseriesData(
            props.id,
            defaultDateFilter ? undefined : { ...selectedTimeseriesDateFilter.value?.value, key: selectedTimeseriesDateFilter.value?.key }
        );

        plotSpecificMetrics.value = defaultDateFilter ? null : timeseriesData;
    } catch (err) {
        console.log(err, 'err');
    } finally {
        await scrollToCurrentPosition();
        timeseriesLoading.value = false;
    }
}

async function onResume(id) {
    try {
        actionLoading.value = true;
        await sourcesStore.resume(id);
        await loadSourceUponAction();
    } finally {
        actionLoading.value = false;
    }
}

async function onRestart(id) {
    try {
        actionLoading.value = true;
        await sourcesStore.restart(id);
        await loadSourceUponAction();
    } finally {
        actionLoading.value = false;
    }
}

function onClone(source) {
    router.push({
        name: 'new-source',
        params: { connector: source.connector },
        query: { cloneConfig: JSON.stringify(source.config) }
    });
}

// eslint-disable-next-line no-unused-vars
async function onDelete(entity, name, id, index) {
    try {
        tabLoading.value = true;
        if (!pipelines.pipelines) {
            await pipelines.fetch();
        }

        const hasActivePipeline = pipelines.all.some((p) => p.source.id === id);

        if (hasActivePipeline) {
            useConfirmBeforeWarning(
                async () => {
                    tabLoading.value = false;
                },
                { entity: entity, name: name },
                () => {
                    tabLoading.value = false;
                }
            );
        } else {
            useConfirmBeforeAction(
                'delete',
                async () => {
                    try {
                        await sourcesStore.delete(id);
                        sourcesStore.sources = undefined;
                        hidePromptTemporary.value = true;
                        router.push({ name: 'connectors', query: { tab: 'Sources' } });
                    } finally {
                        tabLoading.value = false;
                        hidePromptTemporary.value = false;
                    }
                },
                { entity: entity, name: name },
                () => {
                    tabLoading.value = false;
                }
            );
        }
    } catch {
        tabLoading.value = false;
    }
}

function initializeTopicsFilters() {
    filters.forEach((filter) => {
        if (filter.name === 'count') {
            pageSize.value = filter.options[filter.defaultIndex].value;
        }

        checkedTopicFilters.value[filter.name] = [filter.multiple ? this.CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

async function onTopicsPageChange(newPage) {
    topicsLoading.value = true;
    try {
        removeWebSocketMessage();

        page.value = newPage;
        sourceLatestMetrics.value.topics = {};

        const dateFilterToUse = isDefaultTopicDateFilter.value
            ? undefined
            : { ...selectedTopicsDateFilter.value?.value, key: selectedTopicsDateFilter.value?.key };

        specificTopicMetricsToUse.value = await metrics.sources.fetch(props.id, {
            topic_list_from: page.value * pageSize.value,
            topic_list_size: pageSize.value,
            time_type: !isDefaultTopicDateFilter.value ? 'timesummary' : undefined,
            ...(dateFilterToUse || {}),
            level: 'topic'
        });

        if (isDefaultTopicDateFilter.value) {
            specificTopicMetricsToUse.value = null;
        }

        addWebSocketMessage();
    } finally {
        topicsLoading.value = false;
    }
}

async function onTopicsFilterToggle({ filter, filterOption }) {
    topicsLoading.value = true;
    try {
        removeWebSocketMessage();

        if (filter.name === 'count') {
            pageSize.value = filterOption.value;
        }

        page.value = 0;

        const topicFilter = JSON.stringify({ filter: { name: filter.name }, filterOption });
        sourceLatestMetrics.value.topics = {};
        const dateFilterToUse = isDefaultTopicDateFilter.value
            ? undefined
            : { ...selectedTopicsDateFilter.value?.value, key: selectedTopicsDateFilter.value?.key };
        const queryInfo = { query: { ...route.query, topicFilter } };

        if (dateFilterToUse?.key) {
            queryInfo.query.selectedTopicsDateFilter = JSON.stringify(dateFilterToUse);
        } else {
            delete queryInfo.query.selectedTopicsDateFilter;
        }

        router.push(queryInfo);

        specificTopicMetricsToUse.value = await metrics.sources.fetch(props.id, {
            topic_list_from: page.value * pageSize.value,
            topic_list_size: pageSize.value,
            time_type: !isDefaultTopicDateFilter.value ? 'timesummary' : undefined,
            ...(dateFilterToUse || {}),
            level: 'topic'
        });

        if (isDefaultTopicDateFilter.value) {
            specificTopicMetricsToUse.value = null;
        }

        addWebSocketMessage();
    } finally {
        topicsLoading.value = false;
    }
}

// function canConnectorSnapshot() {
//     let canIt = true;

//     if (NO_SNAPSHOT_CONNECTORS.includes(source.value.connector)) return false;

//     return canIt;
// }

function canConnectorCancelSnapshot() {
    let canIt = true;

    if (NO_SNAPSHOT_CONNECTORS.includes(source.value.connector)) return false;

    return canIt;
}

function canConnectorSnapshot() {
    if (NO_SNAPSHOT_CONNECTORS.includes(source.value.connector)) return false;

    return true;
}

function canTopicCancelSnapshot() {
    if (NO_SNAPSHOT_CONNECTORS.includes(source.value.connector)) return false;

    return true;
}

const checkTopic = (id) => {
    checkedTopics.value[id] = !checkedTopics.value[id];

    if (Object.values(checkedTopics.value).filter((x) => x).length === topics(specificTopicMetricsToUse)?.length) {
        allTopicsAreChecked.value = true;
    } else {
        allTopicsAreChecked.value = false;
    }
};

// function canConnectorSnapshot() {
//     if (NO_SNAPSHOT_CONNECTORS.includes(source.value.connector)) return false;
//     if (source.value.connector_status == 'Paused') return false;

//     const canIt = topicsSnapshotStatuses?.value?.every((snapshot) => snapshot.status === 'completed');

//     return canIt;
// }

// function canConnectorCancelSnapshot() {
//     if (source.value.connector_status == 'Paused') return false;

//     if (!topicsSnapshotStatuses?.value?.length) return false;

//     const canIt = topicsSnapshotStatuses?.value?.every((snapshot) => snapshot.status === 'pending' || snapshot.status === 'running');

//     return canIt;
// }

// function canConnectorSnapshot(topic) {
//     if (source.value.connector_status == 'Paused') return false;

//     switch (topic.snapshotStatus) {
//         case 'pending':
//             return false;
//         case 'running':
//             return false;
//         case 'completed':
//             return true;
//         default:
//             return true;
//     }
// }

// function canTopicCancelSnapshot(topic) {
//     if (source.value.connector_status == 'Paused') return false;

//     switch (topic.snapshotStatus) {
//         case 'pending':
//             return true;
//         case 'running':
//             return true;
//         case 'completed':
//             return false;
//         default:
//             return false;
//     }
// }

const scrollToCurrentPosition = async () => {
    const container = document.querySelector('.flex.h-screen.overflow-auto.bg-gray-100');
    const scrollValue = _.cloneDeep(container.scrollTop);
    await nextTick(() => {
        const latestContainer = document.querySelector('.flex.h-screen.overflow-auto.bg-gray-100');
        const latestScrollValue = _.cloneDeep(latestContainer.scrollTop);

        if (latestScrollValue !== scrollValue) {
            setTimeout(() => (container.scrollTop = scrollValue), 1); // Reset to captured position'
        }
    });
};

const anyTopicActionLoading = computed(() => {
    return Object.values(topicActionLoading.value).some((x) => x);
});

const checkAllTopics = () => {
    topics(specificTopicMetricsToUse)?.forEach((topic) => {
        checkedTopics.value[topic.id] = !allTopicsAreChecked.value;
    });
};

async function onSnapshot(specificTopics = []) {
    if (specificTopics.length) {
        specificTopics.forEach((topic) => {
            topicActionLoading.value[topic.id || topic] = true;
        });
    } else {
        actionLoading.value = true;
    }

    try {
        const snapshotBody = {
            source_id: source.value.id,
            connector: source.value.connector,
            topic_names: specificTopics.length ? specificTopics.map((topic) => topic?.name || topic) : source.value?.topics
        };

        await sourcesStore.snapshot(snapshotBody);
        await metrics.sources.fetch(props.id, { topic_list_from: page.value * pageSize.value, topic_list_size: pageSize.value });
    } finally {
        if (specificTopics.length) {
            specificTopics.forEach((topic) => {
                topicActionLoading.value[topic.id || topic] = false;
                checkedTopics.value[topic.id || topic] = false;
            });
        } else {
            actionLoading.value = false;
        }
    }
}

async function onCancelSnapshot(specificTopics = []) {
    if (specificTopics.length) {
        specificTopics.forEach((topic) => {
            topicActionLoading.value[topic.id || topic] = true;
        });
    } else {
        actionLoading.value = true;
    }

    try {
        const snapshotBody = {
            source_id: source.value.id,
            connector: source.value.connector,
            topic_names: specificTopics.length
                ? specificTopics.map((topic) => topic.name || topic)
                : topics(specificTopicMetricsToUse).value?.map((topic) => topic.name || topic)
        };
        await sourcesStore.cancelSnapshot(snapshotBody);
    } finally {
        if (specificTopics.length) {
            specificTopics.forEach((topic) => {
                topicActionLoading.value[topic.id || topic] = false;
            });
        } else {
            actionLoading.value = false;
        }
    }
}

const stats = computed(() => {
    const latencyMetric = getFormattedValueForLatest('latency', 'connector');
    let eventsWrittenMetric = getFormattedValueForLatest('sourceRecordWriteTotal', 'inline');

    eventsWrittenMetric = !eventsWrittenMetric?.raw ? getFormattedValueForLatest('sourceRecordWriteTotal', 'connector') : eventsWrittenMetric;

    return [
        {
            id: 0,
            name: 'Status',
            stat: `<span class="flex items-center text-lg font-medium">
                <span class="mr-2">${sourceStatus.value}</span>
                <div class="flex flex-row items-center gap-3" title="High Latency">
                 ${
                     sourceLatestMetrics.value?.inline?.latency >= 60000
                         ? `<span class="mr-[2px] shadow-sm">
                                            <div
                                                class="h-4 w-4 flex-shrink-0 text-[#236C9E]"
                                                style="display: block; margin: auto"
                                                >
                                                <svg data-v-3f029013="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="h-5 w-5 text-gray-600 ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                                </div>
                                        </span>`
                         : ''
                 }
                                        ${
                                            sourceLatestMetrics.value?.inline?.state
                                                ? `<span

                                            class="inline-flex items-center rounded-full px-3 py-2 text-md text-gray-800 shadow font-medium"
                                            style="background-color: rgb(249 250 251)">
                                            ${sourceLatestMetrics.value?.inline?.state}
                                        </span>`
                                                : ''
                                        }
                </div>
                </span>`,
            icon: CheckCircleIcon,
            infoIcon: sourceStatus.value === 'Broken',
            errors: sourceErrors.value,
            colorClass: getStatusColor(sourceStatus.value, false, true)
        },
        {
            id: 1,
            name: latencyMetric.metricDisplayName || 'Latency',
            stat: latencyMetric.display,
            icon: ClockIcon,
            colorClass: 'bg-orange-400' // Orange for Latency
        },
        // {
        //     id: 2,
        //     name: 'Topics',
        //     stat: getTopicsLength(),
        //     icon: QueueListIcon,
        //     colorClass: 'bg-green-400' // Blue for Topics
        // },
        {
            id: 3,
            name: eventsWrittenMetric.metricDisplayName || 'Events',
            stat: eventsWrittenMetric.display,
            icon: ChevronDoubleLeftIcon,
            colorClass: 'bg-blue-500' // Green for Events Received
        },
        {
            id: 4,
            name: 'Errors',
            stat: getErrors(),
            icon: ExclamationCircleIcon,
            colorClass: 'bg-red-500' // Red for Errors
        }
    ];
});

const getFormattedValueForLatest = (metricKey, level = 'inline', children = null, dashOnRule = null, otherMetricSource = null) => {
    const isChildren = children || _.isNumber(children);

    if (!source.value) return { display: '-' };

    const metricSource = otherMetricSource || sourceLatestMetrics.value;
    const metricValue = isChildren ? metricSource[level]?.[children]?.[metricKey] : metricSource[level]?.[metricKey];

    if (!connectorInfo.value) return { display: '-' };

    const metricFormattingInfo = getMetricFormattingInfo(metricKey, connectorInfo.value?.metrics);
    let formatterParams = [metricValue, metricFormattingInfo || {}];

    if (dashOnRule || dashOnRule === false) {
        formatterParams.push(dashOnRule);
    }

    return {
        ...(formatter(...formatterParams) || {}),
        metricDisplayName: metricFormattingInfo?.name
    };
};

const getErrors = () => {
    const errSum = sourceLatestMetrics?.value.topics
        ? topics(specificTopicMetricsToUse.value)?.value?.reduce((total, topic) => {
              const output = getFormattedValueForLatest(
                  'recordErrorTotal',
                  'topics',
                  topic.id,
                  null,
                  specificTopicMetricsToUse.value
              ).rawValueInCurrentUnit;
              return total + (output === '-' ? 0 : output);
          }, 0)
        : '0';

    return errSum > 0 ? errSum : '0';
};

let tabs = ref([
    { name: 'Status', href: '#', current: true },
    { name: 'Schema', href: '#', current: false },
    { name: 'Auth', href: '#', current: false },
    { name: 'Settings', href: '#', current: false },
    {
        name: 'Logs',
        href: `/logs?source_id=${props.id}`,
        target: '_blank',
        rel: 'noopener noreferrer',
        newTabUrl: true,
        current: false
    }
]);

let currentTab = ref('');

const setCurrentTab = (str) => {
    if (str === 'Logs') {
        return;
    }

    currentTab.value = str;

    router.push({ query: { ...route.query, tab: currentTab.value } });
};

const sourceErrors = computed(() => {
    if (sourceStatus.value === 'Broken' && source.value?.task_statuses) {
        return [...new Set(Object.values(source.value?.task_statuses).map((task) => task.trace))];
    }

    return [];
});

// const topicsSnapshotStatuses = computed(() => {
//     if (!sourceLatestMetrics.value?.connector?.snapshotStatus || !sourceLatestMetrics.value?.connector?.snapshotStatus?.length) {
//         return [];
//     }

//     return sourceLatestMetrics.value?.connector?.snapshotStatus || [];
// });

const sourceStatus = computed(() => {
    return sourceLatestMetrics?.value?.inline?.latest?.connector_status ?? source?.value?.connector_status ?? 'Unknown';
});

// async function discardSource() {
//     try {
//         tabLoading.value = true;

//         const sourceVal = await sourcesStore.getById(props.id);
//         source.value = JSON.parse(JSON.stringify(sourceVal ?? {}));

//         constructTreeNodes();
//     } finally {
//         tabLoading.value = false;
//     }
// }

async function saveSource() {
    tabLoading.value = true;
    formError.value = '';
    editTreeLists(treeNodes.value);

    try {
        await sourcesStore.edit(source.value, source.value.id);
        await loadSourceUponAction();

        canSave.value = false;
        canSaveSchemaTree.value = false;

        toast.success('Source successfully saved.');
    } catch (err) {
        console.error('Error in saveSource:', err);
        formError.value = err;
        tabLoading.value = false;
    } finally {
        tabLoading.value = false;
    }
}

function onDiscard() {
    source.value = _.cloneDeep(defaultSource.value);
    constructTreeNodes();
    canSave.value = false;
    canSaveSchemaTree.value = false;
}

async function handleTimeseriesDateFilterInput(dateFilter) {
    timeseriesLoading.value = true;

    try {
        selectedTimeseriesDateFilter.value = dateFilter;
        const defaultDateFilter =
            selectedTimeseriesDateFilter.value.key === 'last24h' || !selectedTimeseriesDateFilter.value || !selectedTimeseriesDateFilter.value?.key;

        const dateFilterToUse = defaultDateFilter
            ? undefined
            : { ...selectedTimeseriesDateFilter.value?.value, key: selectedTimeseriesDateFilter.value?.key };

        const queryInfo = { query: { ...route.query } };

        if (dateFilterToUse?.key) {
            queryInfo.query.selectedTimeseriesDateFilter = JSON.stringify(dateFilterToUse);
        } else {
            delete queryInfo.query.selectedTimeseriesDateFilter;
        }

        await router.push(queryInfo);

        const timeseriesData = await metrics.sources.fetchTimeseriesData(props.id, dateFilterToUse);

        plotSpecificMetrics.value = defaultDateFilter ? null : timeseriesData;
    } finally {
        await scrollToCurrentPosition();
        timeseriesLoading.value = false;
    }
}

async function handleTopicsDateFilterInput(dateFilter) {
    topicsLoading.value = true;

    try {
        selectedTopicsDateFilter.value = dateFilter;
        sourceLatestMetrics.value.topics = {};

        const dateFilterToUse = isDefaultTopicDateFilter.value
            ? undefined
            : { ...selectedTopicsDateFilter.value?.value, key: selectedTopicsDateFilter.value?.key };

        const queryInfo = { query: { ...route.query } };

        if (dateFilterToUse?.key) {
            queryInfo.query.selectedTopicsDateFilter = JSON.stringify(dateFilterToUse);
        } else {
            delete queryInfo.query.selectedTopicsDateFilter;
        }

        await router.push(queryInfo);

        specificTopicMetricsToUse.value = await metrics.sources.fetch(props.id, {
            topic_list_from: page.value * pageSize.value,
            topic_list_size: pageSize.value,
            time_type: !isDefaultTopicDateFilter.value ? 'timesummary' : undefined,
            ...(dateFilterToUse || {}),
            level: 'topic'
        });

        if (isDefaultTopicDateFilter.value) {
            specificTopicMetricsToUse.value = null;
        }
    } finally {
        topicsLoading.value = false;
    }
}

function constructTreeLists(nodesList) {
    const outputMap = {};

    treeLevels.value.forEach((level) => {
        outputMap[level.schemaObject] = [];
    });

    const processNode = (node, parentName) => {
        if (node.isSelected) {
            const schemaObject = node.schemaObject;
            if (schemaObject) {
                const nodeName = parentName ? `${parentName}.${node.name}` : node.name;
                outputMap[schemaObject].push(nodeName);
            }
            if (node.children) {
                node.children.forEach((childNode) => processNode(childNode, node.name));
            }
        }
    };

    nodesList.forEach((node) => processNode(node, null));
    return outputMap;
}

function editTreeLists(nodesList) {
    const outputMap = constructTreeLists(nodesList);

    for (const schemaObject in outputMap) {
        if (source.value.config) {
            source.value.config[schemaObject] = outputMap[schemaObject].join(', ');
        }
    }
}

function onFieldChanged({ value, valid, name }) {
    const control = sourceConfig.value.find((x) => x.name == name);

    if (control) {
        control.value.current_value = value;

        sourceConfig.value = _.chain(adjustControlConditionalValues(sourceConfig.value))
            .map((control) => adjustControlConditionalVisibility(control, sourceConfig.value)?.control || null)
            .compact()
            .value();
    }

    if (valid && !_.isUndefined(value)) {
        if (source.value) {
            // eslint-disable-next-line vue/no-mutating-props
            source.value.config[name] = value;
        }
    }
}

const chartOptions = computed(() => {
    const titleText = DATE_FILTER_OPTIONS.find((option) => option.value === selectedTimeseriesDateFilter.value?.key)?.label || 'Last 24h';

    const metricKeys = _.cloneDeep(allSelectedMetricsForChart.value)?.map((x) => x.key) || [];

    const dataForEachSelectedMetric = metricKeys.map((metricKey) => {
        const metricData =
            _.isNil(plotSpecificMetrics.value) || _.isEmpty(plotSpecificMetrics.value)
                ? sourceTimeseriesConnectorMetrics.value[metricKey]
                : plotSpecificMetrics.value?.connector[metricKey];

        return {
            metricKey,
            metricData,
            metricInfo: getMetricFormattingInfo(metricKey, connectorInfo.value?.metrics)
        };
    });

    const seriesAndYAxes = dataForEachSelectedMetric.map((metric, index) => {
        const yAxis = {
            title: {
                text: metric.metricInfo?.name
            },
            labels: {
                formatter: function () {
                    return formatter(this.value, { unit: metric.metricInfo?.unit }, false).display;
                }
            },
            opposite: index % 2 !== 0
        };

        const series = {
            name: metric.metricInfo?.name,
            data: metric.metricData?.map((dataItem) => {
                const localTime = moment.utc(dataItem.timestamp).tz(moment.tz.guess()).toDate();
                return {
                    x: localTime,
                    y: dataItem.value
                };
            }),
            type: 'line',
            yAxis: index,
            custom: {
                unit: metric.metricInfo?.unit
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter(this.y, { unit: metric.metricInfo?.unit }, false).display;
                }
            }
        };

        return { series, yAxis };
    });

    // Split the series and yAxis configurations
    const series = seriesAndYAxes.map((sy) => sy.series);
    const yAxis = seriesAndYAxes.map((sy) => sy.yAxis);

    const options = {
        time: {
            timezone: moment.tz.guess()
        },
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: titleText
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    // Get the current date and the date from the axis in the browser's timezone
                    const now = moment.utc(new Date()).tz(moment.tz.guess());
                    const axisDate = moment.utc(new Date(this.value)).tz(moment.tz.guess());

                    // Compare dates to determine formatting
                    if (now.format('YYYYMMDD') === axisDate.format('YYYYMMDD')) {
                        // Current day: show hour only
                        return axisDate.format('HH:mm');
                    } else if (now.format('YYYYMM') === axisDate.format('YYYYMM')) {
                        // Current month, different day: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else if (now.format('YYYY') === axisDate.format('YYYY')) {
                        // Different month, same year: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else {
                        // Different year: show day, month (short name), year, and hour
                        return axisDate.format('DD MMM YYYY HH:mm');
                    }
                }
            }
        },
        yAxis: yAxis,
        series: series,
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function () {
                const localTime = moment(new Date(this.x)).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

                let tooltip = `${localTime}<br>`;
                this.points.forEach((point) => {
                    tooltip += `<b>${point.series.name}:</b> ${
                        formatter(point.y, { unit: point.series.userOptions.custom?.unit }, false).display
                    }<br>`;
                });
                return tooltip;
            }
        }
    };

    return options;
});

const goToTopic = (topic) => {
    router.push({ name: 'topic-details', params: { topic_id: topic.id } });
};
</script>

<template>
    <div class="min-h-screen">
        <div v-if="source" class="page-header">
            <AppHeader :show-title="false">
                <template #titleRight>
                    <nav class="text-gray-700" aria-label="Breadcrumb">
                        <ol class="list-none p-0 inline-flex font-semibold">
                            <li class="flex items-center">
                                <div>Connectors</div>
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                <router-link to="/connectors?tab=Sources" class="text-green-400 hover:text-green-500">Sources</router-link>
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                <div class="flex flex-row align-center items-center gap-3">
                                    <div class="flex-shrink-0">
                                        <img class="h-6 w-6" :src="`/icons/${source.connector}.svg`" alt="connector" />
                                    </div>
                                    <h3 class="text-lg font-medium text-gray-900">
                                        {{ source.name }}
                                    </h3>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </template>
                <template #end>
                    <RowActionMenu
                        :show-delete="userHasAccess([$Permissions.DELETE_SOURCES], authStore.user)"
                        :show-clone="userHasAccess([$Permissions.WRITE_SOURCES], authStore.user)"
                        :show-stop="
                            userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                            sourceStatus !== 'Paused' &&
                            sourceStatus !== 'Stopped'
                        "
                        :show-restart="
                            userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                            sourceStatus !== 'Active' &&
                            sourceStatus !== 'Paused' &&
                            sourceStatus !== 'Stopped'
                        "
                        :show-resume="
                            userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                            sourceStatus !== 'Active' &&
                            sourceStatus !== 'Broken'
                        "
                        :show-snapshot="userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) && canConnectorSnapshot()"
                        :show-cancel-snapshot="userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) && canConnectorCancelSnapshot()"
                        :loading="actionLoading || tabLoading"
                        @delete="onDelete('source', source.name, source.id, sourceIndex)"
                        @stop="onStop(source.id)"
                        @resume="onResume(source.id)"
                        @restart="onRestart(source.id)"
                        @snapshot="onSnapshot()"
                        @clone="onClone(source)"
                        @cancel-snapshot="onCancelSnapshot()" />
                </template>
            </AppHeader>
        </div>

        <AppLoader :listen="['sources.fetch']" :force-loading="generalLoading" default-height="85vh">
            <div v-if="source" class="w-full">
                <Tabs :tabs="tabs" :initial-active-tab="currentTab" :loading="tabLoading" @tab-change="setCurrentTab">
                    <template #Status>
                        <div class="mt-5">
                            <div>
                                <h3 class="mb-3 font-medium">Stats</h3>
                                <div class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                                    <StatCard
                                        v-for="item in stats"
                                        :key="item.id"
                                        :icon-bg-class="item.colorClass"
                                        :icon="item.icon"
                                        :info-icon="item.infoIcon"
                                        :errors="item.errors"
                                        :loading="metricsLoading || configurationLoading"
                                        :title="item.name"
                                        :value="item.stat" />
                                </div>
                            </div>

                            <div>
                                <div class="mt-10 mb-2">
                                    <div class="flex justify-between items-end mb-3">
                                        <h3 class="font-medium">Activity</h3>
                                    </div>
                                    <div class="rounded-lg bg-gray-50 shadow p-5">
                                        <AppLoader :force-loading="metricsLoading" default-height="50vh">
                                            <div class="flex items-center justify-between">
                                                <DateFilter
                                                    :selected="selectedTimeseriesDateFilter?.key || 'last24h'"
                                                    :disabled="timeseriesLoading"
                                                    :hide-options="[
                                                        'date',
                                                        'dateRange',
                                                        'today',
                                                        'allTime',
                                                        'lastWeek',
                                                        'lastMonth',
                                                        'thisWeek',
                                                        'thisMonth'
                                                    ]"
                                                    @input="handleTimeseriesDateFilterInput" />
                                                <div class="flex items-center justify-end w-1/3">
                                                    <multiselect
                                                        v-model="allSelectedMetricsForChart"
                                                        class="inputRight"
                                                        :options="listOfTimeseriesMetricOptions"
                                                        :multiple="true"
                                                        :close-on-select="false"
                                                        :clear-on-select="false"
                                                        :preserve-search="true"
                                                        :placeholder="
                                                            timeseriesLoading
                                                                ? 'Loading...'
                                                                : listOfTimeseriesMetricOptions?.length
                                                                  ? 'Select metrics'
                                                                  : 'No metrics available'
                                                        "
                                                        :select-label="'Select'"
                                                        :deselect-label="'Remove'"
                                                        :loading="timeseriesLoading"
                                                        :disabled="timeseriesLoading || !listOfTimeseriesMetricOptions?.length"
                                                        label="name"
                                                        track-by="key"
                                                        :preselect-first="true">
                                                        <template #selection="{ values, isOpen }"
                                                            ><span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{
                                                                values.length > 1 ? `${values.length} metrics selected` : values[0].name
                                                            }}</span></template
                                                        >
                                                    </multiselect>
                                                    <button
                                                        aria-label="button"
                                                        class="bg-cyan-950 hover:bg-cyan-800 text-white rounded-r-md px-4 flex items-center h-auto"
                                                        :style="allSelectedMetricsForChart?.length ? 'min-height: 42px' : 'min-height: 38px'"
                                                        :disabled="timeseriesLoading"
                                                        @click.stop="refreshTimeseries()">
                                                        <ArrowPathIcon class="h-4 w-4 p-0" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="mt-4 shadow rounded-lg">
                                                    <AppLoader :force-loading="timeseriesLoading" background="#f9fafb" default-height="42vh">
                                                        <HighChart v-if="!timeseriesLoading" ref="chart" :chart-options="chartOptions" />
                                                    </AppLoader>
                                                </div>
                                            </div>
                                        </AppLoader>
                                    </div>
                                </div>

                                <div class="mt-5">
                                    <div>
                                        <div class="w-full flex justify-between items-center mb-3">
                                            <h4 class="font-medium">Topics</h4>
                                            <div class="flex items-center gap-3">
                                                <DateFilter
                                                    :selected="selectedTopicsDateFilter?.key || 'allTime'"
                                                    :disabled="topicsLoading"
                                                    :hide-options="['date', 'dateRange', 'today']"
                                                    @input="handleTopicsDateFilterInput" />
                                                <DropdownFilters
                                                    v-model="checkedTopicFilters"
                                                    slim-dropdown-button
                                                    :filters="filters"
                                                    :disabled="topicsLoading"
                                                    @on-filter-toggle="onTopicsFilterToggle" />
                                            </div>
                                        </div>

                                        <section class="paginated-section">
                                            <AppLoader
                                                :force-loading="metricsLoading || topicsLoading"
                                                default-height="30vh"
                                                :default-width="'100%'"
                                                background="#f9fafb"
                                                :default-class="'shadow-md rounded-md'">
                                                <div v-if="!topicsLoading" class="relative w-full shadow-md overflow-auto">
                                                    <!-- Topics Table Component -->
                                                    <RowActionMenu
                                                        v-if="
                                                            userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                                                            canConnectorSnapshot()
                                                        "
                                                        :left="true"
                                                        class="mb-2"
                                                        :show-snapshot="true"
                                                        :disable-snapshot="Object.values(checkedTopics).every((x) => !x)"
                                                        :loading="topicsLoading || anyTopicActionLoading || actionLoading"
                                                        @snapshot="onSnapshot(Object.keys(checkedTopics).filter((key) => checkedTopics[key]))" />

                                                    <table class="min-w-full divide-y divide-gray-300 border" aria-label="sources topics table">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                                    <div class="flex gap-2 items-center">
                                                                        <input
                                                                            v-model="allTopicsAreChecked"
                                                                            aria-label="input"
                                                                            type="checkbox"
                                                                            @input="checkAllTopics()" />
                                                                        Topic
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    v-for="metric in availableMetrics?.topic"
                                                                    :key="metric.attribute"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                                    <span> {{ metric.display.name }}</span>
                                                                </th>
                                                                <!-- <th
                                                                scope="col"
                                                                class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                                Snapshot
                                                            </th> -->
                                                                <th
                                                                    scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider" />
                                                            </tr>
                                                        </thead>
                                                        <tbody class="divide-y divide-gray-200 bg-white">
                                                            <tr v-for="(topic, index) in topics(specificTopicMetricsToUse)" :key="index">
                                                                <td class="px-6 py-4 whitespace-nowrap">
                                                                    <div class="flex items-center gap-3">
                                                                        <input
                                                                            v-if="canConnectorSnapshot()"
                                                                            v-model="checkedTopics[topic.id]"
                                                                            aria-label="input"
                                                                            type="checkbox"
                                                                            @input.stop="checkTopic(topic.id)" />
                                                                        <div
                                                                            class="my-auto whitespace-normal break-words max-w-sm hover:text-green-600 cursor-pointer font-medium text-green-500 no-underline"
                                                                            @click="goToTopic(topic)">
                                                                            <span>{{ topic.name }}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    v-for="metric in availableMetrics?.topic"
                                                                    :key="metric.attribute"
                                                                    class="px-6 py-4 whitespace-nowrap">
                                                                    <span>
                                                                        {{
                                                                            getFormattedValueForLatest(
                                                                                metric.attribute,
                                                                                'topics',
                                                                                topic.id,
                                                                                null,
                                                                                specificTopicMetricsToUse
                                                                            ).display
                                                                        }}
                                                                        <span
                                                                            v-if="
                                                                                metric.attribute === 'StreamingLastEvent' &&
                                                                                !getFormattedValueForLatest(
                                                                                    'StreamingMilliSecondsSinceLastEvent',
                                                                                    'topics',
                                                                                    topic.id,
                                                                                    null,
                                                                                    specificTopicMetricsToUse
                                                                                ).display === '-'
                                                                            ">
                                                                            (
                                                                            {{
                                                                                getFormattedValueForLatest(
                                                                                    'StreamingMilliSecondsSinceLastEvent',
                                                                                    'topics',
                                                                                    topic.id,
                                                                                    null,
                                                                                    specificTopicMetricsToUse
                                                                                ).display
                                                                            }}
                                                                            ago)
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                                <!-- <td class="px-6 py-4 whitespace-nowrap">
                                                                <span
                                                                    class="inline-flex items-center font-medium rounded-full px-2.5 py-1 text-sm text-gray-800 shadow"
                                                                    :style="`background: ${getStatusColor(topic.snapshotStatus)}`"
                                                                    :tooltip="`Submit Date: ${topic.snapshotSubmitTimestamp}`"
                                                                    :position="top">
                                                                    {{ topic.snapshotDisplayStatus }}
                                                                </span>
                                                            </td> -->
                                                                <td class="sticky right-0 z-0 whitespace-nowrap text-sm text-gray-600">
                                                                    <TableActionMenu
                                                                        :key="index"
                                                                        :button-style="false"
                                                                        :loading="topicActionLoading[topic.id]"
                                                                        :show-snapshot="
                                                                            userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                                                                            canConnectorSnapshot(topic)
                                                                        "
                                                                        :display-above="
                                                                            topics(specificTopicMetricsToUse)?.length &&
                                                                            index >= topics(specificTopicMetricsToUse)?.length - 2
                                                                        "
                                                                        :show-cancel-snapshot="
                                                                            userHasAccess([$Permissions.TRIGGER_ACTION_SOURCES], authStore.user) &&
                                                                            canTopicCancelSnapshot(topic)
                                                                        "
                                                                        :position-relative="true"
                                                                        @snapshot="onSnapshot([topic], true)"
                                                                        @cancel-snapshot="onCancelSnapshot([topic], true)" />
                                                                </td>
                                                            </tr>
                                                            <tr v-if="!topics(specificTopicMetricsToUse)?.length">
                                                                <td
                                                                    colspan="15"
                                                                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center">
                                                                    <span>No topics found</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AppLoader>
                                            <Pagination
                                                class="mb-10"
                                                :page="page"
                                                :page-size="pageSize"
                                                :current-page-items-count="topics(specificTopicMetricsToUse)?.length"
                                                :total-items-count="source.topics?.length ?? 0"
                                                :disable-navigation="metricsLoading"
                                                :message="{
                                                    show: !metricsLoading,
                                                    showCustomMessage: false,
                                                    firstPart: 'Showing topics',
                                                    middlePart: 'to',
                                                    lastPart: `of ${source.topics?.length}`
                                                }"
                                                @page-change="onTopicsPageChange" />
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #Schema>
                        <div v-if="noExistingSchema" class="flex w-full p-5 font-medium">
                            <h2>There is no schema for this source</h2>
                        </div>
                        <AppLoader v-else :force-loading="treeLoading" default-height="20vh">
                            <div class="bg-white p-4 shadow-md rounded-md mt-5 py-5 sm:px-10">
                                <div :class="visibleSchemaConnectorConfigControls?.length ? 'mt-10' : 'mt-3'">
                                    <div v-for="(control, index) in visibleSchemaConnectorConfigControls" :key="index">
                                        <div v-show="control.display_in_ui" :class="getClass(control, index)">
                                            <DynamicField
                                                :key="control.name"
                                                :config="control"
                                                :hide-lateral-description="false"
                                                @input="onFieldChanged" />
                                        </div>
                                    </div>
                                </div>
                                <label v-if="visibleSchemaConnectorConfigControls?.length" class="text-base font-medium text-gray-700 mb-2"
                                    >Schema</label
                                >
                                <TreeView
                                    v-if="!treeLoading"
                                    class="w-full overflow-y-auto mb-5"
                                    style="max-height: 82vh"
                                    :tree-nodes="treeNodes"
                                    :tree-levels="treeLevels"
                                    :edit-mode="true"
                                    :connector-type="source.connector"
                                    @can-save-duplicate="($event) => (canSaveSchemaTree = $event)" />
                            </div>
                        </AppLoader>
                    </template>
                    <template #Auth>
                        <AppLoader :force-loading="treeLoading" default-height="20vh">
                            <ConnectorTab
                                v-if="source.config && sourceConfig"
                                :key="source.config"
                                class="mt-5"
                                :connector="source"
                                :default-connector="defaultSource"
                                :config="sourceConfig"
                                :justify-content="'start'"
                                :tab="'auth'"
                                :edit-mode="true"
                                @changed="(status) => updateCanSave(status)" />
                            <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'edit-source'" />
                        </AppLoader>
                    </template>
                    <template #Settings>
                        <AppLoader :force-loading="treeLoading" default-height="20vh">
                            <ConnectorTab
                                v-if="source.config && sourceConfig"
                                :key="source.config"
                                class="mt-5"
                                :connector="source"
                                :default-connector="defaultSource"
                                :config="sourceConfig"
                                :justify-content="'start'"
                                :tab="'settings'"
                                :edit-mode="true"
                                @changed="(status) => updateCanSave(status)" />
                            <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'edit-source'" />
                        </AppLoader>
                    </template>
                </Tabs>
            </div>
        </AppLoader>
    </div>
    <AppFooter v-if="source" v-can-access.permissions="[$Permissions.WRITE_SOURCES]">
        <template #right>
            <RowActionMenu
                :show-save="canSaveButton"
                :disable-save="!canSaveButton || tabLoading || !source.name"
                :loading="actionLoading || tabLoading"
                @save="saveSource"
                @discard="onDiscard" />
        </template>
    </AppFooter>
</template>
