<template>
    <div class="mx-auto">
        <div class="page-header">
            <AppHeader />
        </div>

        <AppLoader :force-loading="configLoading" default-height="80vh">
            <div class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mb-10">
                <StatCard
                    v-for="stat in stats"
                    :title="stat.name"
                    :value="stat.stat"
                    :icon="stat.icon"
                    :icon-bg-class="stat.colorClass"
                    :loading="topicStatisticsLoading || configLoading" />
            </div>

            <div class="flex justify-between items-end mb-3 mt-10">
                <h3 class="font-semibold">Metrics</h3>
            </div>

            <div class="rounded-lg bg-gray-50 shadow p-5">
                <div class="flex items-center justify-between">
                    <DateFilter
                        :selected="selectedTopicsTimeseriesDateFilter?.key || 'last24h'"
                        :disabled="configLoading || timeseriesLoading"
                        :hide-options="['date', 'dateRange', 'today', 'allTime', 'lastWeek', 'lastMonth', 'thisWeek', 'thisMonth']"
                        @input="fetchTopicsTimeseries" />
                    <div class="flex items-center justify-end w-1/3">
                        <multiselect
                            v-model="allSelectedMetricsForTopicsChart"
                            class="inputRight"
                            :options="listOfTopicsMetrics"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :placeholder="timeseriesLoading ? 'Loading...' : listOfTopicsMetrics?.length ? 'Select metrics' : 'No metrics available'"
                            :select-label="'Select'"
                            :deselect-label="'Remove'"
                            :loading="timeseriesLoading"
                            :disabled="timeseriesLoading || !listOfTopicsMetrics?.length"
                            label="name"
                            track-by="key">
                            <template #selection="{ values, isOpen }"
                                ><span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{
                                    values.length > 1 ? `${values.length} metrics selected` : values[0]?.name
                                }}</span></template
                            >
                        </multiselect>
                        <button
                            aria-label="button"
                            class="bg-cyan-950 hover:bg-cyan-800 text-white rounded-r-md px-4 flex items-center h-auto"
                            :style="allSelectedMetricsForTopicsChart?.length ? 'min-height: 42px' : 'min-height: 38px'"
                            :disabled="timeseriesLoading"
                            @click.stop="fetchTopicsTimeseries()">
                            <ArrowPathIcon class="h-4 w-4 p-0" />
                        </button>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="mt-4 shadow rounded-lg">
                        <AppLoader :force-loading="configLoading || timeseriesLoading" background="#f9fafb" default-height="35vh">
                            <HighChart :chart-options="chartOptions" />
                        </AppLoader>
                    </div>
                </div>
            </div>

            <div class="table-page-container mt-10 mb-5">
                <div class="table-page-body">
                    <!-- Search and Filters -->
                    <div>
                        <div class="flex items-center gap-3 mb-3">
                            <h3 class="font-semibold">Topics</h3>
                        </div>
                        <div class="flex justify-between mb-3 items-end">
                            <SearchInput
                                :loading="configLoading || topicTableDataLoading"
                                placeholder="Search by name..."
                                @search="(input) => (searchQuery = input)" />
                            <div class="flex items-end gap-3">
                                <!-- <DateFilter @input="handleDateFilterInput" /> -->
                                <DropdownFilters
                                    v-model="checkedFilters"
                                    slim-dropdown-button
                                    :filters="filters"
                                    :disabled="configLoading || topicTableDataLoading"
                                    @on-filter-toggle="onTopicsFilterToggle">
                                    <template #dropdownListElement="{ filterOption }">
                                        <div class="flex items-center">
                                            <div class="flex-1 whitespace-nowrap">
                                                {{ filterOption.displayValue }}
                                            </div>
                                        </div>
                                    </template>
                                </DropdownFilters>
                            </div>
                        </div>

                        <div class="overflow-x-auto bg-white rounded-lg shadow-md">
                            <section class="paginated-section">
                                <AppLoader
                                    :force-loading="configLoading || topicTableDataLoading"
                                    default-height="40vh"
                                    background="#f9fafb"
                                    :default-width="'100%'"
                                    :default-class="'shadow-md rounded-md'">
                                    <table class="min-w-full divide-y divide-gray-300" aria-label="topics table">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                    <div class="flex items-center gap-3">
                                                        <span>Name</span>
                                                    </div>
                                                </th>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                    <div class="flex items-center gap-3">
                                                        <span>Connector</span>
                                                    </div>
                                                </th>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                    <div class="flex">
                                                        <span>Volume</span>
                                                    </div>
                                                </th>
                                                <!-- <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                            <div class="flex">
                                                <span>Retries</span>
                                            </div>
                                        </th> -->
                                                <!-- <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                            <div class="flex">
                                                <span>Received</span>
                                            </div>
                                        </th> -->
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                    <div class="flex">
                                                        <span>Errors</span>
                                                    </div>
                                                </th>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                    <div class="flex">
                                                        <span>Written</span>
                                                    </div>
                                                </th>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                                    <div class="flex">
                                                        <span>Last</span>
                                                    </div>
                                                </th>
                                                <!-- <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                            <div class="flex">
                                                <span>Partitions</span>
                                            </div>
                                        </th> -->
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <template v-if="!configLoading">
                                                <tr
                                                    v-for="topic in topicsWithLatestMetrics"
                                                    :key="topic.id"
                                                    class="transition duration-300 ease-in-out hover:bg-gray-50">
                                                    <td class="px-6 py-4 whitespace-nowrap cursor-pointer font-medium text-green-500 no-underline">
                                                        <div class="flex items-center gap-3 w-full">
                                                            <!-- <input aria-label="input" v-model="checkedTopics[topic.id]" type="checkbox" @input.stop="checkTopic(topic.id)" /> -->
                                                            <div class="flex flex-col items-start gap-1">
                                                                <div
                                                                    class="my-auto whitespace-normal break-words max-w-sm hover:text-green-600"
                                                                    @click="goToTopic(topic)">
                                                                    <span> {{ topic.name }}</span>
                                                                </div>

                                                                <small class="font-regular text-gray-400"> ID: {{ topic.id }}</small>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                        <div class="flex items-center mr-4 gap-2">
                                                            <img :src="getConnectorIcon(topic?.entity?.connector)" alt="" class="w-6 h-6" />
                                                            <div class="my-auto whitespace-normal break-words max-w-md">
                                                                <router-link
                                                                    :to="
                                                                        selectedConnectorRouterPath(
                                                                            topic?.entity?.entity_id,
                                                                            topic?.entity?.entity_type
                                                                        )
                                                                    ">
                                                                    {{ topic?.entity?.name }}
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        {{ getFormattedValue('byteTotal', topic.metrics.byteTotal).display }}
                                                    </td>
                                                    <!-- <td class="px-6 py-4 whitespace-nowrap">
                                                {{ getFormattedValue('recordRetryTotal', topic.metrics.recordRetryTotal).display }}
                                            </td> -->
                                                    <!-- <td class="px-6 py-4 whitespace-nowrap">
                                                {{ getFormattedValue('recordSendTotal', topic.metrics.recordSendTotal).display }}
                                            </td> -->
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        {{ getFormattedValue('recordErrorTotal', topic.metrics.recordErrorTotal).display }}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        {{ getFormattedValue('recordWrittenTotal', topic.metrics.recordWrittenTotal).display }}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        {{ getFormattedValue('StreamingLastEvent', topic.metrics.StreamingLastEvent).display }}
                                                    </td>
                                                    <!-- <td class="px-6 py-4 whitespace-nowrap">
                                                {{ getFormattedValue('partitionTotal', topic.metrics.partitionTotal).display }}
                                            </td> -->
                                                </tr>
                                                <tr v-if="!topicTableData?.length">
                                                    <td colspan="99" class="text-center p-2">
                                                        <small>No matching records found</small>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </AppLoader>
                            </section>
                        </div>
                        <Pagination
                            v-if="topicTableData.length > 0 || page > 0"
                            :page="page"
                            :page-size="pageSize"
                            :disable-navigation="topicTableDataLoading || configLoading"
                            :current-page-items-count="topicTableData?.length"
                            :total-items-count="topicsStore.total"
                            :message="paginationMessage"
                            @page-change="(page) => fetchTopicsTableData(page)">
                        </Pagination>
                    </div>
                </div>
            </div>
        </AppLoader>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch } from 'vue';
import _ from 'lodash';
import AppHeader from '../../components/AppHeader.vue';
import Pagination from '../../components/Pagination.vue';
import AppLoader from '../../components/AppLoader.vue';

import { FILTER_CONSTANTS } from '../../../src/utils/utils';
import { useTopicsStore } from '@/stores/topics.store';
import { useMetricsStore } from '@/stores/metrics.store';
import SearchInput from '@/components/global/SearchInput.vue';
import Multiselect from 'vue-multiselect';
import DropdownFilters from '../../components/DropdownFilters.vue';
import DateFilter from '@/components/global/DateFilter.vue';
import HighChart from '../../components/HighChart.vue';
import StatCard from '../../components/global/StatCard.vue';
import { formatter, getMetricFormattingInfo } from '../../utils/utils';
import { DATE_FILTER_OPTIONS } from '../../utils/constants';
import {
    ClockIcon,
    ExclamationCircleIcon,
    ChevronDoubleLeftIcon,
    ChevronRightIcon,
    CheckCircleIcon,
    ArrowPathIcon,
    ScaleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    QueueListIcon
} from '@heroicons/vue/24/outline';
import { useRoute, useRouter } from 'vue-router';
import { getConnectorIcon } from '@/utils/constants';
import moment from 'moment-timezone';
import { list } from 'postcss';

const topicsStore = useTopicsStore();
const metricsStore = useMetricsStore();
const route = useRoute();
const router = useRouter();

const page = ref(0);
const pageSize = ref(10);

const configLoading = ref(false);
const topicTableDataLoading = ref(false);
const topicStatisticsLoading = ref(false);

const topicTableData = ref([]);
let checkedFilters = ref([]);
const allSelectedMetricsForTopicsChart = ref([]);
const topicStatistics = ref(null);

const selectedTopicsTimeseriesDateFilter = ref(null);
const topicsTimeseriesData = ref([]);
const timeseriesLoading = ref(false);

const searchQuery = ref(null);

const filters = ref([
    {
        title: 'Topics per page',
        name: 'count',
        defaultIndex: 0,
        options: [
            { value: 10, displayValue: '10/page' },
            { value: 20, displayValue: '20/page' },
            { value: 50, displayValue: '50/page' }
        ]
    }
]);

onBeforeMount(async () => {
    page.value = topicsStore.page || 0;
    pageSize.value = topicsStore.pageSize || 10;
    configLoading.value = true;
    topicTableDataLoading.value = true;
    topicStatisticsLoading.value = true;

    // timeseries related queries
    const selectedTopicsTimeseriesMetricsQuery = route.query.selectedTopicsTimeseriesMetrics;
    const selectedTopicsTimeseriesDateFilterQuery = route.query.selectedTopicsTimeseriesDateFilter;

    // topic related queries
    allSelectedMetricsForTopicsChart.value = selectedTopicsTimeseriesMetricsQuery
        ? JSON.parse(selectedTopicsTimeseriesMetricsQuery)
        : allSelectedMetricsForTopicsChart.value;
    selectedTopicsTimeseriesDateFilter.value = selectedTopicsTimeseriesDateFilterQuery
        ? JSON.parse(selectedTopicsTimeseriesDateFilterQuery)
        : selectedTopicsTimeseriesDateFilter.value;

    initializeTopicsFilters();

    try {
        await Promise.all([
            fetchTopicsTableData(),
            topicsStore.fetchTopicConfigs().then(() => {
                configLoading.value = false;
            }),
            fetchTopicsTimeseries(selectedTopicsTimeseriesDateFilter.value),
            topicsStore.fetchTopicStatistics().then(() => {
                topicStatistics.value = topicsStore.statistics;
                topicStatisticsLoading.value = false;
            })
        ]);
    } catch (error) {
        console.log(error);
    } finally {
        configLoading.value = false;
        topicTableDataLoading.value = false;
        topicStatisticsLoading.value = false;
    }
});

const stats = computed(() => {
    const [volume, errors, eventsSent, written] = ['byteTotal', 'recordErrorTotal', 'recordSendTotal', 'recordWrittenTotal'].map((metricKey) => {
        return {
            metric: metricKey,
            displayValue: getFormattedValue(metricKey, topicStatistics.value?.[metricKey]).display
        };
    });

    const topicsTotal = topicStatistics.value?.topicTotal;

    return [
        {
            id: 3,
            name: 'Topics',
            stat: topicsTotal,
            icon: QueueListIcon,
            colorClass: 'bg-blue-400'
        },
        {
            id: 0,
            name: 'Volume',
            stat: volume.displayValue,
            icon: ScaleIcon,
            colorClass: 'bg-purple-400'
        },
        {
            id: 1,
            name: 'Errors',
            stat: errors.displayValue,
            icon: ExclamationCircleIcon,
            colorClass: 'bg-red-500'
        },
        {
            id: 2,
            name: 'Events',
            stat: eventsSent.displayValue,
            icon: ChevronDoubleLeftIcon,
            colorClass: 'bg-green-500'
        }
    ];
});

const chartOptions = computed(() => {
    const titleText = DATE_FILTER_OPTIONS.find((option) => option.value === selectedTopicsTimeseriesDateFilter.value?.key)?.label || 'Last 24h';

    const metricKeys =
        _.cloneDeep(allSelectedMetricsForTopicsChart.value)
            ?.map((x) => (x.key ? { key: x.key, name: x.name } : null))
            .filter((x) => x) || [];

    const dataForEachSelectedMetric = metricKeys
        .map(({ key, name }) => {
            const topicsMetricData = topicsTimeseriesData.value?.topics?.topics_page?.[key];

            if (topicsMetricData?.length) {
                return [
                    {
                        metricKey: key,
                        metricData: topicsMetricData,
                        metricInfo: {
                            ...getMetricFormattingInfo(key, topicsStore.configs?.metrics),
                            name
                        }
                    }
                ];
            }

            return {
                metricKey: key,
                metricData: topicsMetricData,
                metricInfo: {
                    ...getMetricFormattingInfo(key, topicsStore.configs?.metrics),
                    name
                }
            };
        })
        .flat();

    const seriesAndYAxes = dataForEachSelectedMetric.map((metric, index) => {
        const yAxis = {
            title: {
                text: metric.metricInfo?.name
            },
            labels: {
                formatter: function () {
                    return formatter(this.value, { unit: metric.metricInfo?.unit }).display;
                }
            },
            opposite: index % 2 !== 0
        };

        const series = {
            name: metric.metricInfo?.name,
            data: metric.metricData?.map((dataItem) => {
                const localTime = moment.utc(dataItem.timestamp).tz(moment.tz.guess()).toDate();
                return {
                    x: localTime,
                    y: dataItem.value
                };
            }),
            type: 'line',
            yAxis: index,
            custom: {
                unit: metric.metricInfo?.unit
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter(this.y, { unit: metric.metricInfo?.unit }).display;
                }
            }
        };

        return { series, yAxis };
    });

    // Split the series and yAxis configurations
    const series = seriesAndYAxes.map((sy) => sy.series);
    const yAxis = seriesAndYAxes.map((sy) => sy.yAxis);

    const options = {
        time: {
            timezone: moment.tz.guess()
        },
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: titleText
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    // Get the current date and the date from the axis in the browser's timezone
                    const now = moment.utc(new Date()).tz(moment.tz.guess());
                    const axisDate = moment.utc(new Date(this.value)).tz(moment.tz.guess());

                    // Compare dates to determine formatting
                    if (now.format('YYYYMMDD') === axisDate.format('YYYYMMDD')) {
                        // Current day: show hour only
                        return axisDate.format('HH:mm');
                    } else if (now.format('YYYYMM') === axisDate.format('YYYYMM')) {
                        // Current month, different day: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else if (now.format('YYYY') === axisDate.format('YYYY')) {
                        // Different month, same year: show day, month (short name), and hour
                        return axisDate.format('DD MMM HH:mm');
                    } else {
                        // Different year: show day, month (short name), year, and hour
                        return axisDate.format('DD MMM YYYY HH:mm');
                    }
                }
            }
        },
        yAxis: yAxis,
        series: series,
        tooltip: {
            shared: true,
            crosshairs: true,
            formatter: function () {
                const localTime = moment(new Date(this.x)).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

                let tooltip = `${localTime}<br>`;
                this.points.forEach((point) => {
                    tooltip += `<b>${point.series.name}:</b> ${formatter(point.y, { unit: point.series.userOptions.custom?.unit }).display}<br>`;
                });
                return tooltip;
            }
        }
    };

    return options;
});

const listOfTopicsMetrics = computed(() => {
    let topicMetricsKeys = Object.keys(topicsTimeseriesData.value?.topics?.topics_page || {});

    const keysToReturn = topicMetricsKeys
        .map((key) => {
            const metricInfo = getMetricFormattingInfo(key, topicsStore.configs?.metrics);

            if (!metricInfo) {
                return null;
            }

            return {
                ...metricInfo,
                key,
                name: metricInfo.name
            };
        })
        .filter((x) => x && topicsTimeseriesData.value?.topics?.topics_page?.[x.key]?.length);

    if (
        !keysToReturn?.length ||
        (keysToReturn?.length && allSelectedMetricsForTopicsChart.value?.map((x) => x.key).some((x) => !keysToReturn.map((y) => y.key).includes(x)))
    ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        allSelectedMetricsForTopicsChart.value = [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        delete route.query.selectedTopicsTimeseriesMetrics;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        router.push(route.query);
    }

    return _.orderBy(keysToReturn, 'name', 'asc');
});

watch(
    () => listOfTopicsMetrics.value,
    (newValue) => {
        const defaultTo = ['byteTotal'];

        if (!allSelectedMetricsForTopicsChart.value.length && newValue.length) {
            allSelectedMetricsForTopicsChart.value = newValue.filter((x) => defaultTo.includes(x.key));

            if (!allSelectedMetricsForTopicsChart.value.length) {
                allSelectedMetricsForTopicsChart.value = [newValue[0]];
            }
        }
    }
);

watch(
    () => allSelectedMetricsForTopicsChart.value,
    (newValue) => {
        if (newValue?.filter((x) => x)?.length) {
            router.push({ query: { ...route.query, selectedTopicsTimeseriesMetrics: JSON.stringify(newValue) } });
        } else {
            delete route.query.selectedTopicsTimeseriesMetrics;
            router.push({ query: route.query });
        }
    },
    { deep: true }
);

watch(
    () => searchQuery.value,
    _.debounce(async () => {
        await fetchTopicsTableData(null, null, searchQuery.value);
    }, 700)
);

function initializeTopicsFilters() {
    filters.value.forEach((filter) => {
        if (filter.name === 'count') {
            pageSize.value = filter.options[filter.defaultIndex].value;
        }

        checkedFilters.value[filter.name] = [filter.multiple ? FILTER_CONSTANTS.all : filter.options[filter.defaultIndex]];
    });
}

async function fetchTopicsTableData(newPage = null, newPageSize = null, currentSearchQuery = null) {
    topicTableDataLoading.value = true;

    try {
        page.value = _.isNil(newPage) ? page.value : newPage;
        pageSize.value = _.isNil(newPageSize) ? pageSize.value : newPageSize;

        if (currentSearchQuery || currentSearchQuery === '') {
            page.value = 0;
        }

        await topicsStore
            .fetch({
                page_size: pageSize.value,
                page_number: page.value,
                partial_name: currentSearchQuery || searchQuery.value
            })
            .then(() => {
                topicTableData.value = topicsStore.all;
                topicTableDataLoading.value = false;
            });
    } finally {
        topicTableDataLoading.value = false;
    }
}

const getFormattedValue = (metricKey, value) => {
    const metricInfo = getMetricFormattingInfo(metricKey, topicsStore.configs?.metrics);

    return formatter(value, metricInfo || {});
};

async function onTopicsFilterToggle({ filter, filterOption }) {
    try {
        let pageSize = topicsStore.pageSize;

        if (filter.name === 'count') {
            pageSize = filterOption.value;
        }

        page.value = 0;

        await fetchTopicsTableData(page.value, pageSize);
    } catch (error) {
        console.log(error);
    }
}

const paginationMessage = computed(() => {
    return {
        show: topicTableData.value.length === topicsStore.all?.length,
        showCustomMessage: topicTableData.value.length !== topicsStore.all?.length,
        firstPart: 'Showing topics',
        middlePart: 'to',
        lastPart: ``
    };
});

const topicsWithLatestMetrics = computed(() => {
    return (
        topicTableData.value?.map((topic) => {
            return {
                ...topic,
                metrics: {
                    ...topic.metrics
                    // ...(metricsStore.topics.allLatest?.[topic.id]?.inline || {})
                }
            };
        }) || []
    );
});

const fetchTopicsTimeseries = async (specificDateFilter) => {
    timeseriesLoading.value = true;
    selectedTopicsTimeseriesDateFilter.value = specificDateFilter || selectedTopicsTimeseriesDateFilter.value;

    const defaultDateFilter =
        selectedTopicsTimeseriesDateFilter.value?.key === 'last24h' ||
        !selectedTopicsTimeseriesDateFilter.value ||
        !selectedTopicsTimeseriesDateFilter.value?.key;
    const dateFilterToUse = defaultDateFilter
        ? undefined
        : { ...selectedTopicsTimeseriesDateFilter.value?.value, key: selectedTopicsTimeseriesDateFilter.value?.key };

    const queryInfo = { query: { ...route.query, selectedTopicsTimeseriesDateFilter: JSON.stringify(dateFilterToUse) } };

    if (dateFilterToUse?.key) {
        router.push(queryInfo);
    } else {
        delete queryInfo.query.selectedTopicsTimeseriesDateFilter;
        router.push(queryInfo);
    }

    try {
        const timeseriesData = await metricsStore.topics.fetch(
            defaultDateFilter ? undefined : { ...selectedTopicsTimeseriesDateFilter.value?.value, key: selectedTopicsTimeseriesDateFilter.value?.key }
        );

        topicsTimeseriesData.value = timeseriesData;

        return timeseriesData;
    } catch (err) {
        console.log(err, 'err');
    } finally {
        timeseriesLoading.value = false;
    }
};

function selectedConnectorRouterPath(id, type) {
    return type?.includes('source') ? { name: 'source-details', params: { id } } : { name: 'destination-details', params: { id } };
}

const goToTopic = (topic) => {
    router.push({ name: 'topic-details', params: { topic_id: topic.id } });
};
</script>
