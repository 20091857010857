<template>
    <div class="flex flex-col align-center md:items-center md:flex-row md:flex-wrap gap-2" :class="left ? 'justify-start' : 'justify-end'">
        <div class="flex flex-col align-center md:flex-row md:flex-wrap md:justify-end gap-2">
            <button
                v-if="props.showBack"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableBack"
                @click="onEmit('back')">
                <ChevronLeftIcon class="icon" :class="[iconColorClass('back')]" />
                Back
            </button>
            <button
                v-if="props.showNext"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableNext"
                @click="onEmit('next')">
                <ChevronRightIcon class="icon" :class="[iconColorClass('next')]" />
                Next
            </button>
            <button
                v-if="props.showView"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableView"
                @click="onEmit('view')">
                <ViewColumnsIcon class="icon" :class="[iconColorClass('view')]" />
                View
            </button>
            <button
                v-if="props.showEdit"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableEdit"
                @click="onEmit('edit')">
                <PencilSquareIcon class="icon" :class="[iconColorClass('edit')]" />
                Edit
            </button>
            <button
                v-if="props.showCopyId"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableCopyId"
                @click="onEmit('copyId')">
                <ClipboardIcon class="icon" :class="[iconColorClass('copyId')]" />
                Copy ID
            </button>
            <button
                v-if="props.showSave"
                aria-label="button"
                class="btn save"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableSave"
                @click="onEmit('save')">
                <CheckIcon class="icon" :class="[iconColorClass('save')]" />
                Save
            </button>
            <button
                v-if="props.showDiscard && props.showSave && !props.disableSave"
                aria-label="button"
                class="btn discard"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableSave || props.disableDiscard"
                @click="onEmit('discard')">
                <XMarkIcon class="icon" :class="[iconColorClass('discard')]" />
                Discard
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="props.showSnapshot"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableSnapshot"
                @click="onEmit('snapshot')">
                <VideoCameraIcon class="icon" :class="[iconColorClass('snapshot')]" />
                Snapshot
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="props.showCancelSnapshot"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading"
                @click="onEmit('cancelSnapshot')">
                <VideoCameraSlashIcon class="icon" :class="[iconColorClass('cancel-snapshot')]" />
                Cancel Snapshot
            </button>
            <button
                v-if="props.showPause"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disablePause"
                @click="onEmit('pause')">
                <PauseIcon class="icon" :class="[iconColorClass('pause')]" />
                Pause
            </button>
            <button
                v-if="props.showStop"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableStop"
                @click="onEmit('stop')">
                <StopIcon class="icon" :class="[iconColorClass('stop')]" />
                Stop
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="props.showPauseSource"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disablePauseSource"
                @click="onEmit('pauseSource')">
                <PauseIcon class="icon" :class="[iconColorClass('pause')]" />
                Pause source
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="props.showStopSource"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableStopSource"
                @click="onEmit('stopSource')">
                <PauseIcon class="icon" :class="[iconColorClass('stop')]" />
                Stop source
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_SOURCES]"
                v-if="props.showResumeSource"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableResumeSource"
                @click="onEmit('resumeSource')">
                <PlayIcon class="icon" :class="[iconColorClass('resume')]" />
                Resume source
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_DESTINATIONS]"
                v-if="props.showPauseDestination"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disablePauseDestination"
                @click="onEmit('pauseDestination')">
                <PauseIcon class="icon" :class="[iconColorClass('pause')]" />
                Pause destination
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_DESTINATIONS]"
                v-if="props.showStopDestination"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableStopDestination"
                @click="onEmit('stopDestination')">
                <StopIcon class="icon" :class="[iconColorClass('stop')]" />
                Stop destination
            </button>
            <button
                v-can-access.permissions="[$Permissions.TRIGGER_ACTION_DESTINATIONS]"
                v-if="props.showResumeDestination"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableResumeDestination"
                @click="onEmit('resumeDestination')">
                <PlayIcon class="icon" :class="[iconColorClass('resume')]" />
                Resume destination
            </button>
            <button
                v-if="props.showResume"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableResume"
                @click="onEmit('resume')">
                <PlayIcon class="icon" :class="[iconColorClass('resume')]" />
                Resume
            </button>
            <button
                v-if="props.showRestart"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableRestart"
                @click="onEmit('restart')">
                <BackwardIcon class="icon" :class="[iconColorClass('restart')]" />
                Reset
            </button>
            <button
                v-if="props.showClone"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableClone"
                @click="onEmit('clone')">
                <DocumentDuplicateIcon class="icon" :class="[iconColorClass('clone')]" />
                Clone
            </button>
            <button
                v-if="props.showLogs"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableLogs"
                @click="onEmit('logs')">
                <CodeBracketSquareIcon class="icon" :class="[iconColorClass('logs')]" />
                Logs
            </button>
            <button
                v-if="showViews"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableViews"
                @click="onEmit('views')">
                <ViewfinderCircleIcon class="icon" :class="[iconColorClass('view')]" />
                Views
            </button>
            <button
                v-if="showDownloadViews"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableDownloadViews"
                @click="onEmit('downloadViews')">
                <ViewfinderCircleIcon class="icon" :class="[iconColorClass('view')]" />
                Download All Views
            </button>
            <button
                v-if="showExportUsage"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableExportUsage"
                @click="onEmit('exportUsage')">
                <ArrowDownOnSquareIcon class="icon" :class="[iconColorClass('usage')]" />
                Export Usage
            </button>
            <button
                v-if="props.showDelete"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableDelete"
                @click="onEmit('delete')">
                <MinusCircleIcon class="icon" :class="[iconColorClass('delete')]" />
                Delete
            </button>
            <button
                v-if="props.showMarkAll"
                aria-label="button"
                class="btn"
                :class="[textSizeBasedOnPropSize]"
                :disabled="props.disabled || props.loading || props.disableMarkAll"
                @click="onEmit('markAll')">
                <EnvelopeOpenIcon class="icon" :class="[iconColorClass('view')]" />
                Mark all as read
            </button>
        </div>
        <div v-if="loading">
            <HalfCircleSpinner :loading="loading" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import {
    PencilSquareIcon,
    MinusCircleIcon,
    ViewColumnsIcon,
    PlayIcon,
    BackwardIcon,
    PauseIcon,
    StopIcon,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    DocumentDuplicateIcon,
    CodeBracketSquareIcon,
    ViewfinderCircleIcon,
    ArrowDownOnSquareIcon,
    CheckIcon,
    XMarkIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    EnvelopeOpenIcon,
    ClipboardIcon
} from '@heroicons/vue/24/outline';
import HalfCircleSpinner from '../components/global/loader/HalfCircleSpinner.vue';

const props = defineProps({
    loading: Boolean,
    disabled: Boolean,
    left: Boolean,

    showNext: Boolean,
    disableNext: Boolean,

    showBack: Boolean,
    disableBack: Boolean,

    showView: Boolean,
    disableView: Boolean,

    showEdit: Boolean,
    disableEdit: Boolean,

    showSave: Boolean,
    disableSave: Boolean,

    showDiscard: {
        type: Boolean,
        default: true
    },
    disableDiscard: {
        type: Boolean,
        default: false
    },

    showPause: Boolean,
    disablePause: Boolean,

    showStop: Boolean,
    disableStop: Boolean,

    showResume: Boolean,
    disableResume: Boolean,

    showStopSource: Boolean,
    showPauseSource: Boolean,
    showResumeSource: Boolean,

    showStopDestination: Boolean,
    showPauseDestination: Boolean,
    showResumeDestination: Boolean,

    disableStopSource: Boolean,
    disablePauseSource: Boolean,
    disableResumeSource: Boolean,

    disableStopDestination: Boolean,
    disablePauseDestination: Boolean,
    disableResumeDestination: Boolean,

    showRestart: Boolean,
    disableRestart: Boolean,

    showDelete: Boolean,
    disableDelete: Boolean,

    showLogs: Boolean,
    disableLogs: Boolean,

    showClone: Boolean,
    disableClone: Boolean,

    showViews: Boolean,
    disableViews: Boolean,

    showDownloadViews: Boolean,
    disableDownloadViews: Boolean,

    showExportUsage: Boolean,
    disableExportUsage: Boolean,

    showMarkAll: Boolean,
    disableMarkAll: Boolean,

    showSnapshot: Boolean,
    showCancelSnapshot: Boolean,
    disableSnapshot: Boolean,

    showCopyId: Boolean,
    disableCopyId: Boolean,

    buttonsSize: {
        type: String,
        default: 'sm'
    }
});

const emit = defineEmits([
    'discard',
    'next',
    'back',
    'view',
    'edit',
    'save',
    'snapshot',
    'cancelSnapshot',
    'pause',
    'stop',
    'resume',
    'restart',
    'delete',
    'clone',
    'logs',
    'stopSource',
    'pauseSource',
    'resumeSource',
    'pauseDestination',
    'stopDestination',
    'resumeDestination',
    'downloadViews',
    'exportUsage',
    'markAll',
    'copyId'
]);

const onEmit = (event) => {
    if (!props.disabled) {
        emit(event);
    }
};

const textSizeBasedOnPropSize = computed(() => {
    switch (props.buttonsSize) {
        case 'xs':
            return 'text-xs';
        case 'sm':
            return 'text-sm';
        case 'md':
            return 'text-base';
        case 'lg':
            return 'text-lg';
        case 'xl':
            return 'text-xl';
        default:
            return 'text-xs';
    }
});

const iconColorClass = (actionType) => {
    const colorMap = {
        back: 'text-blue-400',
        next: 'text-blue-400',
        view: 'text-blue-500',
        edit: 'text-yellow-500',
        save: 'text-green-600',
        discard: 'text-yellow-600',
        snapshot: 'text-purple-500',
        'cancel-snapshot': 'text-gray-500',
        pause: 'text-orange-500',
        resume: 'text-green-400',
        restart: 'text-red-600',
        delete: 'text-red-700',
        clone: 'text-blue-400',
        logs: 'text-gray-500',
        copyId: 'text-gray-500'
        // Add more mappings as necessary
    };
    return colorMap[actionType] || 'text-gray-500'; // Default color if action type not mapped
};
</script>

<style type="scss">
.btn {
    @apply cursor-pointer inline-flex items-center px-2 py-1 border border-gray-300 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm;
    transition: background-color 0.2s;
}

.btn:disabled {
    @apply opacity-50 cursor-not-allowed;
}

.btn.save {
    @apply bg-green-200;
}

.btn.discard {
    @apply bg-yellow-200;
}

.icon {
    @apply mr-1 h-4 w-4;
}
</style>
