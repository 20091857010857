<template>
    <span :tooltip="tooltipText" :position="position">
        <InformationCircleIcon :class="iconClass" />
        <slot />
    </span>
</template>
<script>
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'Tooltip',
    components: {
        InformationCircleIcon
    },
    props: {
        tooltipText: {
            type: String,
            default: 'Tooltip text'
        },
        position: {
            default: 'top',
            type: String
        },
        iconClass: {
            type: String,
            default: 'h-4 w-4 text-gray-600'
        }
    }
};
</script>
