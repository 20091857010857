import api from '../services/axios.service';

export async function getSubscriber(subscriberId) {
    const response = await api.get(`subscribers/${subscriberId}`);

    const subscriber = {
        deleted: response?.data?.deleted,
        email: response?.data?.email,
        channels: response?.data?.channels
    };

    return subscriber;
}

export async function updateSubscriber(subscriberId, email) {
    return api.put(`subscribers/${subscriberId}`, { email }, {}, true);
}

export async function updateSubscriberCredentials(subscriberId, providerId, webhookUrl) {
    const credentials = {
        credentials: {
            webhookUrl: webhookUrl
        },
        providerId: providerId
    };

    return api.put(`subscribers/${subscriberId}/credentials`, { credentials }, {}, true);
}

export async function deleteSubscriberCredentials(subscriberId, providerId) {
    return api.delete(`subscribers/${subscriberId}/credentials?providerId=${providerId}`, {}, true);
}
