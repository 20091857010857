<script>
import IntegrationItem from './IntegrationItem.vue';
import config from '../../utils/config';
import { useAuthStore } from '../../stores/auth.store';
import * as notificationsApi from '../../api/notifications.api';
import AppLoader from '../../components/AppLoader.vue';
import { toast } from 'vue3-toastify';

export default {
    components: {
        IntegrationItem,
        AppLoader
    },
    data() {
        return {
            config,
            subscriberId: null,
            authStore: null,
            integrations: [],
            subscriber: null,
            loading: true,
            MAX_RETRIES: 45
        };
    },
    watch: {
        'authStore.userTenantId': {
            immediate: true,
            async handler(newUserTenantId) {
                if (newUserTenantId) {
                    this.subscriberId = newUserTenantId;

                    this.subscriber = await notificationsApi.getSubscriber(this.subscriberId);
                    if (this.subscriber) {
                        this.isConnected = true;

                        this.integrations = [
                            {
                                title: 'EMAIL',
                                logo: '/icons/email.svg',
                                isConnected: this.subscriber.email ? true : false,
                                isInput: true,
                                placeholder: 'Enter email address',
                                setInput: this.subscriber.email,
                                providerId: 'email',
                                type: 'input'
                            },
                            {
                                title: 'SLACK',
                                logo: '/icons/slack.svg',
                                isConnected: this.subscriber.channels.find((x) => x.providerId == 'slack') ? true : false,
                                url: `https://api.novu.co/v1/subscribers/${this.subscriberId}/credentials/slack/oauth?environmentId=${config.novu.envId}&integrationIdentifier=${config.novu.slackIntegrationId}`,
                                providerId: 'slack',
                                isPolling: false,
                                type: 'redirect'
                            },
                            {
                                title: 'TEAMS',
                                logo: '/icons/teams.svg',
                                isConnected: this.subscriber.channels.find((x) => x.providerId == 'msteams') ? true : false,
                                webhook: this.subscriber.channels.find((x) => x.providerId === 'msteams')?.credentials?.webhookUrl || null,
                                placeholder: 'Enter webhook',
                                providerId: 'msteams',
                                type: 'webhook'
                            },
                            {
                                title: 'DISCORD',
                                logo: '/icons/discord.svg',
                                isConnected: this.subscriber.channels.find((x) => x.providerId == 'discord') ? true : false,
                                webhook: this.subscriber.channels.find((x) => x.providerId === 'discord')?.credentials?.webhookUrl || null,
                                placeholder: 'Enter webhook',
                                providerId: 'discord',
                                type: 'webhook'
                            },
                            {
                                title: 'MATTERMOST',
                                logo: '/icons/mattermost.svg',
                                isConnected: this.subscriber.channels.find((x) => x.providerId == 'mattermost') ? true : false,
                                webhook: this.subscriber.channels.find((x) => x.providerId === 'mattermost')?.credentials?.webhookUrl || null,
                                placeholder: 'Enter webhook',
                                providerId: 'mattermost',
                                type: 'webhook'
                            },
                            {
                                title: 'GRAFANA',
                                logo: '/icons/grafana.svg',
                                isConnected: this.subscriber.channels.find((x) => x.providerId == 'grafana-on-call') ? true : false,
                                webhook: this.subscriber.channels.find((x) => x.providerId === 'grafana-on-call')?.credentials?.webhookUrl || null,
                                placeholder: 'Enter webhook',
                                providerId: 'grafana-on-call',
                                type: 'webhook'
                            }
                        ];

                        this.loading = false;
                    }
                }
            }
        }
    },
    mounted() {
        this.authStore = useAuthStore();
    },
    methods: {
        updateConnectionStatus(title, isConnected) {
            const integration = this.integrations.find((i) => i.title === title);

            if (integration) {
                integration.isConnected = isConnected;
            }
        },
        updateConnectionSetInput(title, setInput) {
            const integration = this.integrations.find((i) => i.title === title);

            if (integration) {
                integration.setInput = setInput;
            }
        },
        updateConnectionWebhook(title, webhook) {
            const integration = this.integrations.find((i) => i.title === title);

            if (integration) {
                integration.webhook = webhook;
            }
        },
        async updateIsPolling(providerId, pollingVal) {
            const integration = this.integrations.find((i) => i.providerId === providerId);
            if (integration) {
                integration.isPolling = pollingVal;
            }
        },
        async updateSubscriber(providerId) {
            const integration = this.integrations.find((i) => i.providerId === providerId);

            if (integration) {
                integration.isPolling = true;

                await this.pollSubscriber(integration);
            }
        },
        async pollSubscriber(integration, retries = 0) {
            this.subscriber = await notificationsApi.getSubscriber(this.subscriberId);

            if (this.subscriber?.channels?.some((x) => x.providerId == integration.providerId)) {
                integration.isConnected = true;
                integration.isPolling = false;
            } else if (integration.isPolling && retries < this.MAX_RETRIES) {
                const retry = retries + 1;
                setTimeout(() => this.pollSubscriber(integration, retry), 2000);
            } else if (integration.isPolling && retries >= this.MAX_RETRIES) {
                integration.isPolling = false;
                retries = 0;

                toast.error(`Unable to retrieve ${integration.providerId?.toUpperCase()} integration information.`);
            }
        }
    }
};
</script>

<template>
    <div
        v-if="loading"
        class="table-loader min-h-[50px] mt-2"
    >
        <AppLoader
            :force-loading="loading"
            default-height="inherit"
        />
    </div>
    <div
        v-if="!loading"
        class="integrations-list overflow-auto max-h-[60vh] px-2 py-4"
    >
        <IntegrationItem
            v-for="integration in integrations"
            :key="integration.title"
            :integration="integration"
            :subscriber-id="subscriberId"
            @update:isConnected="updateConnectionStatus(integration.title, $event)"
            @update:setInput="updateConnectionSetInput(integration.title, $event)"
            @update:webhook="updateConnectionWebhook(integration.title, $event)"
            @update:isPolling="updateIsPolling(integration.providerId, $event)"
            @update:subscriber="updateSubscriber(integration.providerId, $event)"
        />
    </div>
</template>
