<template>
    <div>
        <!-- Mobile Dropdown -->
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <div class="relative">
                <select id="tabs" aria-label="tabs" class="form-select text-sm rounded-md" name="tabs" @change="updateActiveTab">
                    <option v-for="tab in tabs" :key="getTabKey(tab)" :value="getTabKey(tab)" :selected="getTabKey(tab) === getTabKey(activeTab)">
                        {{ getTabName(tab) }}
                    </option>
                </select>
                <div class="select-icon">
                    <ChevronDownIcon class="h-5 w-5 text-gray-600" />
                </div>
            </div>
        </div>

        <!-- Desktop Tabs -->
        <div class="hidden sm:block">
            <div class="flex items-end">
                <ul class="flex p-1 bg-gray-150 rounded-l-lg rounded-tr-lg">
                    <li v-for="tab in tabs" :key="getTabKey(tab)">
                        <a
                            :href="tab?.href"
                            :target="tab?.target"
                            :rel="tab?.rel"
                            class="flex items-center align-center font-medium hover:text-green-400 cursor-pointer"
                            :class="
                                getTabKey(tab) === getTabKey(activeTab)
                                    ? 'flex justify-center items-center bg-white rounded-lg shadow text-green-400 py-2 px-4'
                                    : 'flex justify-center items-center py-2 px-4'
                            "
                            @click="() => (getTab(tab)?.newTabUrl ? null : updateActiveTab(tab))">
                            <component
                                :is="tab?.icon?.icon"
                                v-if="tab?.icon?.left"
                                :class="`${tab?.icon?.size || 'h-4 w-4'} mr-2 ${tab?.icon?.weight || ''}`"
                                @click="tab?.icon?.routePathName ? toPath(tab?.icon?.routePathName) : null" />
                            {{ getTabName(tab) }}
                            <ArrowTopRightOnSquareIcon v-if="tab?.newTabUrl" class="h-4 w-4 ml-2" />

                            <component
                                :is="tab?.icon?.icon"
                                v-if="tab?.icon?.right"
                                :class="`${tab?.icon?.size || 'h-4 w-4'} ml-2 ${tab?.icon?.weight || ''}`"
                                @click="tab?.icon?.routePathName ? toPath(tab?.icon?.routePathName) : null" />
                        </a>
                    </li>
                </ul>
                <div class="flex-grow border-b-4 border-gray-150"></div>
            </div>
        </div>
        <AppLoader :force-loading="loading" default-height="20vh">
            <div v-if="!loading" class="tab-content">
                <slot :name="getTabKey(activeTab)"></slot>
            </div>
        </AppLoader>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { ChevronDownIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import AppLoader from '../../components/AppLoader.vue';
import { useRouter } from 'vue-router';

const props = defineProps({
    tabs: Array,
    initialActiveTab: [String, Object],
    loading: Boolean
});

const emit = defineEmits(['tab-change']);
const router = useRouter();
const activeTab = ref(props.initialActiveTab || (props.tabs.length > 0 ? props.tabs[0] : ''));

watch(
    () => props.initialActiveTab,
    (newVal) => {
        if (newVal) {
            activeTab.value = newVal;
        }
    },
    { immediate: true }
);

function getTabName(tab) {
    return typeof tab === 'object' && tab.name ? tab.name : tab;
}

function getTabKey(tab) {
    return typeof tab === 'object' ? tab.name : tab;
}

function getTab(tabInfo) {
    return typeof tabInfo === 'object' ? tabInfo : props.tabs.find((tab) => getTabKey(tab) === tabInfo);
}

function updateActiveTab(eventOrTab) {
    activeTab.value = typeof eventOrTab === 'object' ? eventOrTab?.target?.value || eventOrTab.name : eventOrTab;
    emit('tab-change', activeTab.value);
}

function toPath(pathName) {
    return router.push({ name: pathName });
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
