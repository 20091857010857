<template>
    <TransitionRoot as="template" :show="isOpen">
        <TransitionChild
            as="template"
            enter="transition ease-out duration-200 transform"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in duration-200 transform"
            leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div class="fixed inset-y-0 right-0 w-96 bg-white shadow-lg z-50" style="z-index: 999">
                <div class="pl-4">
                    <div class="flex justify-between items-center">
                        <h2 class="text-lg font-medium mb-4">Message Details</h2>
                        <button aria-label="button" class="mb-4 p-2 text-gray-600 hover:text-gray-700" @click="closeDrawer">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <div>
                        <Tabs :tabs="tabs">
                            <template #General>
                                <ul class="divide-y divide-gray-200 font-mono text-xs">
                                    <li class="flex items center gap-2 p-4">
                                        <span class="font-semibold">Offset:</span>
                                        <span>{{ message?.offset }}</span>
                                    </li>
                                    <li class="flex items center gap-2 p-4">
                                        <span class="font-semibold">Partition:</span>
                                        <span>{{ message?.partition }}</span>
                                    </li>
                                    <li class="flex items center gap-2 p-4">
                                        <span class="font-semibold">Timestamp:</span>
                                        <span>{{ message?.timestamp }}</span>
                                    </li>
                                    <li class="flex items center gap-2 p-4">
                                        <span class="font-semibold">Timestamp Type:</span>
                                        <span>{{ message?.timestamp_type }}</span>
                                    </li>
                                    <li class="flex flex-col items center gap-2 p-4">
                                        <span class="font-semibold">Sizes:</span>
                                        <div class="flex items center gap-2 ml-5">
                                            <span>Header:</span>
                                            <span>{{ formatter(message?.serialized_header_size, { unit: 'bytes' }).display }}</span>
                                        </div>
                                        <div class="flex items center gap-2 ml-5">
                                            <span>Key:</span>
                                            <span>{{ formatter(message?.serialized_key_size, { unit: 'bytes' }).display }}</span>
                                        </div>
                                        <div class="flex items center gap-2 ml-5">
                                            <span>Value:</span>
                                            <span>{{ formatter(message?.serialized_value_size, { unit: 'bytes' }).display }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                            <template #KEY>
                                <div class="p-4">
                                    <div class="flex items-center gap-2 mb-3">
                                        <button
                                            aria-label="button"
                                            v-if="!keyExpanded && isValidJSON(message?.key)"
                                            class="btn"
                                            @click="
                                                collapseOnDepth = undefined;
                                                keyExpanded = !keyExpanded;
                                            ">
                                            <PlusIcon class="icon" /> Expand all
                                        </button>
                                        <button
                                            aria-label="button"
                                            v-if="keyExpanded && isValidJSON(message?.key)"
                                            class="btn"
                                            @click="
                                                collapseOnDepth = 1;
                                                keyExpanded = !keyExpanded;
                                            ">
                                            <MinusIcon class="icon" /> Collapse all
                                        </button>
                                        <div title="Copy" class="flex gap-1 items-center">
                                            <ClipboardIcon
                                                class="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-600 flex items-center"
                                                @click="copyToClipboard(message?.key)" />
                                            <span v-if="copyDone" class="text-xs text-gray-600">Copied!</span>
                                        </div>
                                    </div>
                                    <VueJsonPretty
                                        v-if="isValidJSON(message?.key)"
                                        :data="message?.key"
                                        :show-line-number="true"
                                        :show-icon="true"
                                        :show-length="true"
                                        collapsed-on-click-brackets="true"
                                        :deep="collapseOnDepth" />

                                    <span v-else>{{ message?.key }}</span>
                                </div>
                            </template>
                            <template #VALUE>
                                <div class="p-4">
                                    <div class="flex items-center gap-2 mb-3">
                                        <button
                                            aria-label="button"
                                            v-if="!valueExpanded && isValidJSON(message?.value)"
                                            class="btn"
                                            @click="
                                                collapseOnDepth = undefined;
                                                valueExpanded = !valueExpanded;
                                            ">
                                            <PlusIcon class="icon" /> Expand all
                                        </button>
                                        <button
                                            aria-label="button"
                                            v-if="valueExpanded && isValidJSON(message?.value)"
                                            class="btn"
                                            @click="
                                                collapseOnDepth = 1;
                                                valueExpanded = !valueExpanded;
                                            ">
                                            <MinusIcon class="icon" /> Collapse all
                                        </button>
                                        <div title="Copy" class="flex gap-1 items-center">
                                            <ClipboardIcon
                                                class="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-600 flex items-center"
                                                @click="copyToClipboard(message?.value)" />
                                            <span v-if="copyDone" class="text-xs text-gray-600">Copied!</span>
                                        </div>
                                    </div>
                                    <VueJsonPretty
                                        v-if="isValidJSON(message?.value)"
                                        :data="message?.value"
                                        :show-line-number="true"
                                        :show-icon="true"
                                        :show-length="true"
                                        collapsed-on-click-brackets="true"
                                        :deep="collapseOnDepth" />

                                    <span v-else>{{ message?.value }}</span>
                                </div>
                            </template>
                        </Tabs>
                    </div>
                </div>
            </div>
        </TransitionChild>
    </TransitionRoot>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ClipboardIcon, PlusIcon, MinusIcon } from '@heroicons/vue/24/outline';
import VueJsonPretty from 'vue-json-pretty';
import Tabs from '@/components/global/Tabs.vue';
import { formatter } from '@/utils/utils';

const props = defineProps({
    message: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(['close']);

const collapseOnDepth = ref(1);
const keyExpanded = ref(false);
const valueExpanded = ref(false);
const isOpen = ref(false);
const tabs = ['General', 'KEY', 'VALUE'];
const copyDone = ref(false);

const isValidJSON = (json) => {
    try {
        JSON.parse(JSON.stringify(json));
        return true;
    } catch (e) {
        return false;
    }
};

watch(
    () => props.message,
    () => {
        keyExpanded.value = false;
        valueExpanded.value = false;
    }
);

function copyToClipboard(textToCopy) {
    copyDone.value = true;
    navigator.clipboard
        .writeText(textToCopy && typeof textToCopy === 'object' ? JSON.stringify(textToCopy, null, 2) : textToCopy)
        .then(() => setTimeout(() => (copyDone.value = false), 1000))
        .catch((err) => {
            console.error('Could not copy text: ', err);
        });
}

const closeDrawer = () => {
    isOpen.value = false;
    emit('close');
};

watch(
    () => props.message,
    (newVal) => {
        isOpen.value = !!newVal;
    }
);

onMounted(() => {
    isOpen.value = !!props.message;
});
</script>

<style scoped>
.fixed {
    will-change: transform;
}
</style>
