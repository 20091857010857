<template>
    <div class="page-filters flex items-end gap-3">
        <Menu v-for="(filter, filterIndex) in filters" :key="filterIndex + filter.name" as="div" class="relative inline-block text-left">
            <Menu as="div" class="relative inline-block text-left">
                <div>
                    <MenuButton
                        :class="[
                            disabled ? 'bg-gray-100 opacity-[0.7]' : 'bg-white hover:bg-gray-50',
                            slimDropdownButton ? 'px-3' : 'px-4',
                            'inline-flex w-full justify-center items-center rounded-md border border-gray-300 px-4 text-sm font-medium text-gray-700 shadow-sm'
                        ]"
                        :style="{ width: filter.buttonWidth ?? 'fit-content', 'min-height': '40.5px !important' }"
                        :disabled="disabled">
                        <FunnelIconOutline v-if="filter.multiple && isChecked(filter, CONSTANTS.all)" class="h-4 w-4 self-center mr-1" />
                        <FunnelIconSolid
                            v-if="filter.multiple && !isChecked(filter, CONSTANTS.all)"
                            class="h-4 w-4 self-center mr-1 fill-green-400" />
                        <span class="px-1">{{ getFilterTitle(filter) }}</span>
                        <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-600" />
                    </MenuButton>
                </div>

                <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95">
                    <MenuItems
                        class="overflow-x-auto absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        :style="{ width: filter.dropdownWidth ?? '10rem' }">
                        <div class="py-1 w-fit min-w-full flex flex-col max-h-[50vh]">
                            <MenuItem
                                v-for="(filterOption, index) in filter.options"
                                v-slot="{ active }"
                                :key="index + filterOption.displayValue"
                                :disabled="filterOption.disabled"
                                :class="[filterOption.disabled ? 'opacity-50' : 'hover:cursor-pointer']"
                                @click="toggleFilter(filter, filterOption)">
                                <div
                                    :class="[
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'text-sm inline-flex items-center gap-3 px-4 py-2 h-fit w-full'
                                    ]"
                                    :title="filterOption.displayValue">
                                    <input
                                        aria-label="input"
                                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 cursor-pointer focus:ring-indigo-600"
                                        readonly
                                        :disabled="filterOption.disabled"
                                        :value="filterOption.displayValue"
                                        :type="filter.multiple ? 'checkbox' : 'radio'"
                                        :checked="isChecked(filter, filterOption)" />
                                    <slot :filter="filter" :filter-option="filterOption" name="dropdownListElement">
                                        <div class="flex-1 whitespace-nowrap">
                                            {{ filterOption.displayValue }}
                                        </div>
                                    </slot>
                                </div>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </Menu>
    </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { FunnelIcon as FunnelIconSolid, ChevronDownIcon } from '@heroicons/vue/24/solid';
import { FunnelIcon as FunnelIconOutline } from '@heroicons/vue/24/outline';
import { FILTER_CONSTANTS } from '../../src/utils/utils';

export default {
    name: 'DropdownFilters',
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        FunnelIconSolid,
        FunnelIconOutline,
        ChevronDownIcon
    },
    props: {
        filters: {
            type: Array,
            required: true
        },
        modelValue: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        slimDropdownButton: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue', 'onFilterToggle'],
    data() {
        return {
            checkedFilters: this.modelValue,
            CONSTANTS: FILTER_CONSTANTS
        };
    },
    watch: {
        modelValue(newValue) {
            this.checkedFilters = newValue;
        }
    },
    methods: {
        toggleFilter(filter, filterOption) {
            if (filterOption.disabled) {
                return;
            }
            if (filter.multiple) {
                this.toggleCheckbox(filter, filterOption);
            } else {
                this.toggleRadio(filter, filterOption);
            }
            this.$emit('update:modelValue', this.checkedFilters);
            this.$emit('onFilterToggle', { filter, filterOption });
        },
        toggleCheckbox(filter, filterOption) {
            if (JSON.stringify(filterOption) === JSON.stringify(this.CONSTANTS.all)) {
                this.checkedFilters[filter.name] = [filterOption];
                return;
            }
            const checkedValues = this.checkedFilters[filter.name];

            if (this.isChecked(filter, this.CONSTANTS.all)) {
                checkedValues.splice(this.filterOptionIndex(filter, this.CONSTANTS.all), 1);
            }

            if (this.isChecked(filter, filterOption)) {
                checkedValues.splice(this.filterOptionIndex(filter, filterOption), 1);
            } else {
                checkedValues.push(filterOption);
            }

            if (!checkedValues.length) {
                checkedValues.push(this.CONSTANTS.all);
            }
        },
        toggleRadio(filter, filterOption) {
            this.checkedFilters[filter.name] = [filterOption];
        },
        isChecked(filter, filterOption) {
            return this.filterOptionIndex(filter, filterOption) !== -1;
        },
        filterOptionIndex(filter, filterOption) {
            if (!this.checkedFilters[filter.name]) {
                return -1;
            }
            return this.checkedFilters[filter.name].findIndex((option) => JSON.stringify(option) === JSON.stringify(filterOption));
        },
        getFilterTitle(filter) {
            if (filter.multiple) {
                return filter.title;
            }
            const radioFilterOption = this.checkedFilters[filter.name]?.[0];
            return radioFilterOption?.displayValue;
        }
    }
};
</script>

<style scoped></style>
