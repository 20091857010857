<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import AppLoader from '../../components/AppLoader.vue';
import AppHeader from '../../components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import { useSourcesStore } from '../../stores/sources.store';
import { useDestinationsStore } from '../../stores/destinations.store';
import { usePipelinesStore } from '../../stores/pipelines.store';
import { useTransformsStore } from '../../stores/transforms.store';
import { useMetricsStore } from '@/stores/metrics.store';
import { useAuthStore } from '@/stores/auth.store';
import ErrorMessage from '../../components/ErrorMessage.vue';
import TreeView from '../../components/TreeView.vue';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
import _ from 'lodash';
import RowActionMenu from '@/components/RowActionMenu.vue';
import StatCard from '@/components/global/StatCard.vue';
import { toast } from 'vue3-toastify';
import HalfCircleSpinner from '../../components/global/loader/HalfCircleSpinner.vue';
import { userHasAccess } from '../../utils/utils';
// import HighChart from '../../components/HighChart.vue';

import {
    InformationCircleIcon,
    ChevronRightIcon,
    CheckCircleIcon,
    CpuChipIcon,
    AdjustmentsVerticalIcon,
    // ChevronUpIcon,
    // ChevronDownIcon,
    ClockIcon
} from '@heroicons/vue/24/outline';
import { QueueListIcon } from '@heroicons/vue/24/solid';

import { buildTreeNodesOutOfExistingConfig, getStatusColor, formatter, compareWithOmittedProperties } from '../../utils/utils';
import { getConnectorIcon, getConnectorDisplayName } from '@/utils/constants';
import Tabs from '@/components/global/Tabs.vue';
import useErrorDialog from '../../composables/useErrorDialog.js';

const props = defineProps(['id', 'customMessage', 'source', 'destination']);
const pipelinesStore = usePipelinesStore();
const sourcesStore = useSourcesStore();
const destinationsStore = useDestinationsStore();
const transformsStore = useTransformsStore();
const metricsStore = useMetricsStore();
const authStore = useAuthStore();

let pipeline = ref(null);
let pipelineSource = ref(null);
let pipelineSourceConfig = ref(null);
let router = useRouter();
let route = useRoute();

let generalLoading = ref(false);
let actionLoading = ref(false);
let configurationSchemaLoading = ref(false);
let transformsLoading = ref(false);

let defaultTransformsTree = ref([]);
let defaultSchemaTree = ref([]);
let hidePromptTemporary = ref(false);

const chart = ref(null);
// const showChart = ref(true);
const selectedChart = ref('throughput');
const chartVariants = ref([
    {
        id: 'throughput',
        metricName: 'Throughput',
        chartName: 'Throughput Over Time',
        unit: 'GB',
        data: [
            150.0, 200.5, 250.4, 300.2, 350.0, 400.0, 450.6, 500.5, 550.4, 600.1, 650.6, 700.4, 550.0, 800.5, 250.4, 300.2, 350.0, 400.0, 450.6,
            500.5, 550.4, 600.1, 650.6, 700.4
        ], // Mock data
        color: '#958cee'
    },
    {
        id: 'lag',
        metricName: 'Lag',
        chartName: 'Lag Over Time',
        unit: 's',
        data: [0.1, 0.5, 0.4, 0.2, 0.0, 0.0, 0.6, 0.5, 0.4, 0.1, 0.6, 0.4, 0.0, 0.5, 0.4, 0.2, 0.0, 0.0, 0.6, 0.5, 0.4, 0.1, 0.6, 0.4], // Mock data
        color: '#ee8c8c'
    },
    {
        id: 'latency',
        metricName: 'Latency',
        chartName: 'Latency Over Time',
        unit: 'ms',
        data: [120, 85, 600, 300, 150, 200, 250, 400, 450, 500, 550, 600, 650, 700, 550, 800, 250, 300, 350, 400, 450, 500, 550, 600], // Mock data
        color: '#8cadee'
    }
]);

const chartOptions = ref(null);

let formError = ref('');
let treeLevels = ref([]);
let treeNodes = ref([]);
let selectedSourceTopics = ref([]);
let transformTreeNodes = ref([]);
let transformTreeLevels = ref([]);
let metricsLoading = ref(false);
let snapshotNewTables = ref(true);
let defaultPipelineName = ref('');

let canSaveTree = ref(true);
let areTreesEqual = ref(false);

let tabs = [
    { name: 'Status', href: '#', current: true },
    { name: 'Settings', href: '#', current: false },
    {
        name: 'Source',
        href: `/connectors/sources/${route.query.source}`,
        target: '_blank',
        rel: 'noopener noreferrer',
        newTabUrl: true,
        current: false
    },
    {
        name: 'Destination',
        href: `/connectors/destinations/${route.query.destination}`,
        target: '_blank',
        rel: 'noopener noreferrer',
        newTabUrl: true,
        current: false
    }
];

const showStopSource = computed(() => {
    return !(
        pipeline.value?.source.connector_status?.toLowerCase() === 'paused' ||
        pipeline.value?.source.connector_status?.toLowerCase() === 'starting' ||
        pipeline.value?.source.connector_status?.toLowerCase() === 'stopped'
    );
});

const showResumeSource = computed(() => {
    return !(
        pipeline.value?.source.connector_status?.toLowerCase() === 'active' ||
        pipeline.value?.source.connector_status?.toLowerCase() === 'broken' ||
        pipeline.value?.source.connector_status?.toLowerCase() === 'starting'
    );
});

const showStopDestination = computed(() => {
    return !(
        pipeline.value?.destination.connector_status?.toLowerCase() === 'paused' ||
        pipeline.value?.destination.connector_status?.toLowerCase() === 'starting' ||
        pipeline.value?.destination.connector_status?.toLowerCase() === 'stopped'
    );
});

const showResumeDestination = computed(() => {
    return !(
        pipeline.value?.destination.connector_status?.toLowerCase() === 'active' ||
        pipeline.value?.destination.connector_status?.toLowerCase() === 'broken' ||
        pipeline.value?.destination.connector_status?.toLowerCase() === 'starting'
    );
});

// const onPauseDestination = async () => {
//     try {
//         actionLoading.value = true;
//         await destinationsStore.pause(pipeline.value.destination.id, true);
//         actionLoading.value = false;
//         toast.success('Destination paused successfully.');

//         metricsLoading.value = true;
//         const promises = await Promise.all([metricsStore.pipelines.fetch(pipeline.value.id), await pipelinesStore.getById(props.id)]);
//         pipeline.value = promises[1];
//         metricsLoading.value = false;
//     } catch (e) {
//         toast.error('There was an error while pausing the destination. Please try again.');
//     } finally {
//         actionLoading.value = false;
//         metricsLoading.value = false;
//     }
// };

const onStopDestination = async () => {
    try {
        actionLoading.value = true;
        await destinationsStore.stop(pipeline.value.destination.id, true);
        actionLoading.value = false;
        toast.success('Destination stopped successfully.');

        metricsLoading.value = true;
        const promises = await Promise.all([metricsStore.pipelines.fetch(pipeline.value.id), await pipelinesStore.getById(props.id)]);
        pipeline.value = promises[1];
        metricsLoading.value = false;
    } catch (e) {
        toast.error('There was an error while stopping the destination. Please try again.');
    } finally {
        actionLoading.value = false;
        metricsLoading.value = false;
    }
};

const onResumeDestination = async () => {
    try {
        actionLoading.value = true;
        await destinationsStore.resume(pipeline.value.destination.id, true);
        actionLoading.value = false;
        toast.success('Destination resumed successfully.');

        metricsLoading.value = true;
        const promises = await Promise.all([metricsStore.pipelines.fetch(pipeline.value.id), await pipelinesStore.getById(props.id)]);
        pipeline.value = promises[1];
        metricsLoading.value = false;
    } catch (e) {
        toast.error('There was an error while resuming the destination. Please try again.');
    } finally {
        actionLoading.value = false;
        metricsLoading.value = false;
    }
};

const onStopSource = async () => {
    try {
        actionLoading.value = true;
        await sourcesStore.stop(pipeline.value.source.id, true);
        actionLoading.value = false;
        toast.success('Source stopped successfully.');

        metricsLoading.value = true;
        const promises = await Promise.all([metricsStore.pipelines.fetch(pipeline.value.id), await pipelinesStore.getById(props.id)]);
        pipeline.value = promises[1];

        metricsLoading.value = false;
    } catch (e) {
        toast.error('There was an error while stopping the source. Please try again.');
    } finally {
        actionLoading.value = false;
        metricsLoading.value = false;
    }
};

// const onPauseSource = async () => {
//     try {
//         actionLoading.value = true;
//         await sourcesStore.pause(pipeline.value.source.id, true);
//         actionLoading.value = false;
//         toast.success('Source paused successfully.');

//         metricsLoading.value = true;
//         const promises = await Promise.all([metricsStore.pipelines.fetch(pipeline.value.id), await pipelinesStore.getById(props.id)]);
//         pipeline.value = promises[1];

//         metricsLoading.value = false;
//     } catch (e) {
//         toast.error('There was an error while pausing the source. Please try again.');
//     } finally {
//         actionLoading.value = false;
//         metricsLoading.value = false;
//     }
// };

const onResumeSource = async () => {
    try {
        actionLoading.value = true;
        await sourcesStore.resume(pipeline.value.source.id, true);
        actionLoading.value = false;

        toast.success('Source resumed successfully.');
        metricsLoading.value = true;
        const promises = await Promise.all([metricsStore.pipelines.fetch(pipeline.value.id), await pipelinesStore.getById(props.id)]);
        pipeline.value = promises[1];

        metricsLoading.value = false;
    } catch (e) {
        toast.error('There was an error while resuming the source. Please try again.');
    } finally {
        actionLoading.value = false;
        metricsLoading.value = false;
    }
};

const pipelineLatestMetrics = computed(() => {
    return metricsStore.pipelines.allLatest[pipeline.value?.id];
});

const stats = computed(() => {
    return [
        {
            id: 0,
            name: 'Status',
            stat: pipelineLatestMetrics.value?.inline?.status ?? '-',
            icon: CheckCircleIcon,
            colorClass: getStatusColor(pipelineLatestMetrics.value?.inline?.status, false, true)
        },
        {
            id: 2,
            name: 'Topics',
            stat: pipeline.value.topics.length,
            icon: QueueListIcon,
            colorClass: 'bg-green-400'
        },
        {
            id: 4,
            name: 'Latency',
            icon: ClockIcon,
            colorClass: 'bg-yellow-500',
            stat: `<div class="flex flex-row items-center gap-3" title="High Latency">
                ${getFormattedValue('latency', 'milliseconds').display}
                                    ${
                                        pipelineLatestMetrics.value?.inline?.latency >= 60000
                                            ? `<span class="shadow-sm">
                                            <div
                                                class="h-4 w-4 flex-shrink-0 text-[#236C9E]"
                                                style="display: block; margin: auto"
                                                >
                                                <svg data-v-3f029013="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="h-5 w-5 text-gray-600 ml-2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                                </div>
                                        </span>`
                                            : ``
                                    }
                                   </div>     `
        },
        {
            id: 4,
            name: 'Lag',
            stat: getFormattedValue('recordsLag', 'count').display,
            icon: CpuChipIcon,
            colorClass: 'bg-purple-400'
        }
    ];
});

const formattedTopicsAndTransforms = computed(() => {
    const transforms = _.map(pipeline.value.transforms, (transform) => {
        if (transform.topic_id) {
            const split = transform.topic_id.split('.');
            const head = split.shift();

            return {
                id: split.length ? head : '-',
                name: split.length ? split.join('.') : head,
                topic_id: transform.topic_id,
                transform_name: transform.name,
                transform: true
            };
        } else {
            return {
                id: transform.id,
                name: transform.name,
                transform: true
            };
        }
    });

    const transformIds = _.map(
        transforms.filter((t) => t.topic_id),
        (transform) => transform.topic_id
    );

    const topics = _.map(
        pipeline.value.topic_ids.filter((t) => !transformIds.includes(t)),
        (topicId) => {
            const split = topicId.split('.');
            const head = split.shift();

            return {
                id: split.length ? head : '-',
                name: split.length ? split.join('.') : head,
                topic_id: topicId,
                topic: true
            };
        }
    );

    return [...transforms, ...topics];
});

let currentTab = ref('');

const setCurrentTab = (str) => {
    if (str === 'Source' || str === 'Destination') {
        return;
    }

    currentTab.value = str;

    router.push({
        query: { tab: currentTab.value, source: route.query.source, destination: route.query.destination, customMessage: route.query.customMessage }
    });
};

const updateCanSaveTree = (newValue) => {
    canSaveTree.value = newValue;
};

function onStatusClick(connector) {
    if (connector.connector_status === 'Broken' && connector.task_statuses) {
        const errors = [...new Set(Object.values(connector.task_statuses).map((task) => task.trace))];
        useErrorDialog({
            errors,
            width: 65
        });
    }
}

function selectedConnectorPath(id, type = 'source') {
    return type === 'source' ? { name: 'source-details', params: { id } } : { name: 'destination-details', params: { id } };
}

onMounted(async () => {
    generalLoading.value = true;
    configurationSchemaLoading.value = true;
    transformsLoading.value = true;
    metricsLoading.value = true;

    setCurrentTab(route.query.tab || 'Status');

    onChartChange();

    try {
        if (props.destination && props.source) {
            const output = await Promise.all([sourcesStore.getById(props.source), getPipelineDetails(props.id, route?.query?.customMessage)]);

            pipelineSource.value = output[0];
            pipeline.value = output[1];
            generalLoading.value = false;

            await Promise.all([
                sourcesStore.fetchConfiguration(pipeline.value.source.connector),
                sourcesStore.fetchConnectors(),
                metricsStore.pipelines
                    .fetch(pipeline.value.id)
                    .then(() => {
                        metricsLoading.value = false;
                    })
                    .catch((e) => {
                        console.log(e);
                    }),
                transformsStore.fetchForConnectors().then(() => {
                    transformsLoading.value = false;

                    if (transformsStore.allForConnectors && !_.isEmpty(transformsStore.allForConnectors)) {
                        constructTransformTreeNodes();
                    }
                })
            ]);
        } else {
            pipeline.value = await getPipelineDetails(props.id, route?.query?.customMessage);
            generalLoading.value = false;

            let sourceItem = null;

            if (pipeline.value) {
                const response = await Promise.all([
                    sourcesStore.getById(pipeline.value.source.id),
                    sourcesStore.fetchConfiguration(pipeline.value.source.connector),
                    sourcesStore.fetchConnectors(),
                    metricsStore.pipelines.fetch(pipeline.value.id).then(() => {
                        metricsLoading.value = false;
                    }),
                    transformsStore.fetchForConnectors().then(() => {
                        transformsLoading.value = false;

                        if (transformsStore.allForConnectors && !_.isEmpty(transformsStore.allForConnectors)) {
                            constructTransformTreeNodes();
                        }
                    })
                ]);

                sourceItem = response[0];
            }

            pipelineSource.value = sourceItem;
        }

        defaultPipelineName.value = pipeline.value?.name || '';
        loadTreeViews();

        configurationSchemaLoading.value = false;
    } finally {
        generalLoading.value = false;
        configurationSchemaLoading.value = false;
        transformsLoading.value = false;
        metricsLoading.value = false;
    }
});

async function getPipelineDetails(id, customMsg) {
    var result = await pipelinesStore.getById(id, customMsg);

    if (customMsg && result) {
        router.push({ query: { ...route.query, customMessage: undefined } });
    }

    return result;
}
function loadTreeViews() {
    if (pipelineSource.value) {
        try {
            pipelineSourceConfig.value = sourcesStore.configurations[pipeline.value.source.connector];
            if (pipelineSourceConfig.value) {
                constructTreeNodes();
            }

            const topics = formattedTopicsAndTransforms.value?.filter((x) => x.topic);

            setSelectedTopics(
                treeNodes.value,
                topics?.map((t) => ({ name: t.name, topic_id: t.topic_id })),
                ''
            );

            defaultSchemaTree.value = _.cloneDeep({ nodes: treeNodes.value, levels: treeLevels.value });
        } finally {
            transformsLoading.value = false;
        }
    }
}

function setSelectedTopics(nodes, topics, parentName) {
    let isSelected = false;

    for (let node of nodes) {
        let fullName = parentName ? parentName + '.' + node.name : node.name;

        // if the topic id is same as the fullName, mark it
        // if we have a parent and maybe a topic id that has also the parent included, and fullName (with parentName) is not equal, we check directly with the node name without parent
        // (since it might include already the parent name due to it being named like the id)

        if (
            topics.find(
                (x) => x.topic_id === fullName || x.name === fullName
                // || (parentName ? x.name === parentName || x.name === node.name || x.topic_id === node.name : false)
            )
        ) {
            node.isSelected = true;
            isSelected = true;
        }

        if (node.children) {
            if (setSelectedTopics(node.children, topics, fullName)) {
                node.isSelected = true;
                isSelected = true;
            }
        }
    }
    return isSelected;
}

const getFormattedValue = (metricKey, unit, dashOnRule = null) => {
    const metricValue = pipelineLatestMetrics.value?.inline?.[metricKey];

    const formatterParams = [metricValue, { unit }];

    if (dashOnRule || dashOnRule === false) {
        formatterParams.push(dashOnRule);
    }

    return formatter(...formatterParams);
};

const constructTreeNodes = () => {
    var configProps = pipelineSource.value.config;
    var sourceConnector = Object.values(sourcesStore.sourceConnectors)
        .flat()
        .find((x) => x.connector == pipelineSource.value.connector);

    const nodes = buildTreeNodesOutOfExistingConfig(configProps, pipelineSourceConfig.value, sourceConnector, false);

    treeLevels.value = nodes.treeLevels;
    treeNodes.value = nodes.treeNodes;

    return { nodes: treeNodes.value, levels: treeLevels.value };
};

const checkSchemaTreeValidity = (transformTreeValid = false) => {
    if (_.isEmpty(treeNodes.value) && _.isEmpty(treeLevels.value)) {
        return {
            valid: true,
            equal: true
        };
    }

    let valid = transformTreeNodes.value.some((x) => x.isSelected) ? transformTreeValid : false;

    let equal = false;
    const areNodesEqual = compareWithOmittedProperties(defaultSchemaTree.value.nodes, treeNodes.value, ['collapsed']);
    const areLevelsEqual = compareWithOmittedProperties(defaultSchemaTree.value.levels, treeLevels.value, ['collapsed']);

    if (treeNodes.value.some((x) => x.isSelected)) {
        valid = checkAllSelectedHaveSelectedChild(treeNodes.value);
    } else {
        // the schema tree is valid when nothing is selected only if transform tree has selected stuff (we can't have both empty)
        valid = checkPartialSelectedHaveSomeChild(transformTreeNodes.value);
    }

    equal = areNodesEqual && areLevelsEqual;

    return {
        valid,
        equal
    };
};

const checkTransformTreeValidity = (schemaTreeValid = false) => {
    if (_.isEmpty(transformTreeNodes.value) && _.isEmpty(transformTreeLevels.value)) {
        return {
            valid: true,
            equal: true
        };
    }

    let valid = treeNodes.value.some((x) => x.isSelected) ? schemaTreeValid : false;
    let equal = false;

    const areNodesEqual = compareWithOmittedProperties(defaultTransformsTree.value.nodes, transformTreeNodes.value, ['collapsed']);
    const areLevelsEqual = compareWithOmittedProperties(defaultTransformsTree.value.levels, transformTreeLevels.value, ['collapsed']);

    if (transformTreeNodes.value.some((x) => x.isSelected)) {
        valid = checkAllSelectedHaveSelectedChild(transformTreeNodes.value);
    } else {
        // the transform tree is valid when nothing is selected only if schema tree has selected stuff (we can't have both empty)
        valid = checkPartialSelectedHaveSomeChild(treeNodes.value);
    }

    equal = areNodesEqual && areLevelsEqual;

    return {
        valid,
        equal
    };
};

const nameChanged = computed(() => {
    return pipeline.value?.name && pipeline.value?.name !== defaultPipelineName.value;
});

watch(
    treeNodes,
    () => {
        const transformTreeValid = checkTransformTreeValidity();
        const validity = checkSchemaTreeValidity(transformTreeValid.valid);

        canSaveTree.value = validity.valid;
        areTreesEqual.value = validity.equal && checkTransformTreeValidity(validity.valid).equal;
    },
    { deep: true }
);

watch(
    transformTreeNodes,
    () => {
        const schemaTreeValid = checkSchemaTreeValidity();
        const validity = checkTransformTreeValidity(schemaTreeValid.valid);

        canSaveTree.value = validity.valid;
        areTreesEqual.value = validity.equal && checkSchemaTreeValidity(validity.valid).equal;
    },
    { deep: true }
);

const checkAllSelectedHaveSelectedChild = (nodes) => {
    for (let obj of nodes) {
        if (obj.isSelected && obj.children && obj.children?.length > 0) {
            if (!obj.children?.some((child) => child.isSelected)) {
                return false;
            }
        }

        if (obj.isSelected && (!obj.children || !obj.children?.length)) {
            return true;
        }

        if (obj.children) {
            const childResult = checkAllSelectedHaveSelectedChild(obj.children);
            if (!childResult) {
                return false;
            }
        }
    }

    return true;
};

const checkPartialSelectedHaveSomeChild = (nodes) => {
    for (let obj of nodes) {
        if (obj.isSelected) {
            return true;
        }

        if (obj.children && obj.children?.length > 0) {
            if (obj.children?.some((child) => child.isSelected)) {
                return true;
            }
        }

        if (obj.children) {
            const childResult = checkPartialSelectedHaveSomeChild(obj.children);

            if (!childResult) {
                return false;
            }
        }
    }

    return false;
};

const processTreeNodesTopics = (nodes, prefix = '') => {
    nodes.forEach((obj) => {
        const fullName = prefix ? `${prefix}.${obj.name}` : obj.name;

        if (!obj.children || obj.children?.length === 0) {
            if (obj.schemaLevel == treeLevels.value[treeLevels.value.length - 1].schemaLevel && obj.isSelected) {
                selectedSourceTopics.value.push(fullName);
            }
        } else {
            processTreeNodesTopics(obj.children, fullName);
        }
    });
};

const onChartChange = () => {
    const option = _.cloneDeep(chartVariants.value).find((x) => x.id === selectedChart.value);
    const chartRef = chart.value?.chart?.chart;

    const options = {
        chart: {
            type: 'column',
            height: '300px'
        },
        title: {
            text: option.chartName
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ]
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Throughput'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} units</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [
            {
                name: `${option.metricName} (${option.unit})`,
                data: option.data, // Mock data
                color: option.color,
                borderRadiusTopLeft: '15%',
                borderRadiusTopRight: '15%'
            }
        ]
    };

    if (chartRef) {
        chartOptions.value = options;
        chartRef.update(options);
    } else {
        chartOptions.value = options;
    }
};

const constructTransformTreeNodes = () => {
    transformTreeLevels.value.push({ schemaLevel: 'schema', schemaObject: 'random' }, { schemaLevel: 'topic', schemaObject: 'random' });
    transformTreeNodes.value = [];
    const children = [];

    transformsStore.allForConnectors.forEach((transform) => {
        const isTransformIncluded = !!pipeline.value.transforms.find(
            (t) => t.name === transform.name && t.id === transform.id && t.topic_id === transform.topic_id
        );

        children.push({
            name: transform.topic,
            isSelected: isTransformIncluded,
            schemaLevel: 'topic',
            schemaObject: transform
        });
    });

    transformTreeNodes.value.push({
        name: 'transforms',
        isSelected: children?.filter((c) => c.isSelected).length === _.size(transformsStore.allForConnectors),
        collapsed: true,
        schemaLevel: 'schema',
        schemaObject: 'random',
        children
    });

    defaultTransformsTree.value = _.cloneDeep({ nodes: transformTreeNodes.value, levels: transformTreeLevels.value });
};

const getSelectedTransformTreeNodes = () => {
    if (transformTreeNodes.value.length === 0) {
        return [];
    }
    return transformTreeNodes.value[0].children.filter((topicNode) => topicNode.isSelected).map((topicNode) => topicNode.schemaObject);
};

onBeforeRouteLeave((to, from, next) => {
    if (generalLoading.value || actionLoading.value || configurationSchemaLoading.value || transformsLoading.value || metricsLoading.value) {
        return next();
    }

    if (hidePromptTemporary.value || (areTreesEqual.value && !nameChanged.value && !!pipeline.value.name)) {
        return next();
    }

    const answer = window.confirm('Are you sure you want to leave? You have unsaved changes.');

    if (answer) {
        return next();
    } else {
        return next(false);
    }
});

const onSavePipeline = () => {
    actionLoading.value = true;

    processTreeNodesTopics(treeNodes.value);

    pipelinesStore
        .edit(
            {
                name: pipeline.value.name,
                snapshot_new_tables: !!snapshotNewTables.value,
                destination: {
                    connector: pipeline.value.destination.connector,
                    name: pipeline.value.destination.name,
                    id: {
                        $oid: pipeline.value.destination.id
                    }
                },
                source: {
                    connector: pipeline.value.source.connector,
                    name: pipeline.value.source.name,
                    topics: selectedSourceTopics.value,
                    id: {
                        $oid: pipeline.value.source.id
                    }
                },
                transforms: getSelectedTransformTreeNodes()
            },
            props.id
        )
        .then(() => {
            hidePromptTemporary.value = true;
            pipelinesStore.fetch();
            router.push({ name: 'pipelines' });
        })
        .catch((err) => {
            formError.value = err;
        })
        .finally(() => {
            actionLoading.value = false;
            hidePromptTemporary.value = false;
        });
};
</script>

<template>
    <div class="min-h-screen">
        <div class="page-header">
            <AppHeader :show-title="false">
                <template #titleRight>
                    <nav class="text-gray-700" aria-label="Breadcrumb">
                        <ol v-if="pipeline" class="list-none p-0 inline-flex font-semibold">
                            <li class="flex items-center">
                                <router-link to="/pipelines" class="text-green-400 hover:text-green-500">Pipelines</router-link>
                                <ChevronRightIcon class="w-4 h-4 mx-2 text-gray-600" />
                                <h3
                                    class="text-lg font-medium text-gray-900 truncate"
                                    style="max-width: 25rem"
                                    :title="pipeline.name"
                                    :alt="pipeline.name">
                                    {{ pipeline.name }}
                                </h3>
                            </li>
                        </ol>
                    </nav>
                </template>
                <template #end>
                    <RowActionMenu
                        v-if="!generalLoading"
                        :show-stop-source="userHasAccess([$Permissions.WRITE_SOURCES], authStore.user) && showStopSource"
                        :show-stop-destination="userHasAccess([$Permissions.WRITE_DESTINATIONS], authStore.user) && showStopDestination"
                        :show-resume-source="userHasAccess([$Permissions.WRITE_SOURCES], authStore.user) && showResumeSource"
                        :show-resume-destination="userHasAccess([$Permissions.WRITE_DESTINATIONS], authStore.user) && showResumeDestination"
                        :loading="actionLoading"
                        :disabled="generalLoading || actionLoading"
                        @stopSource="onStopSource"
                        @resumeSource="onResumeSource"
                        @stopDestination="onStopDestination"
                        @resumeDestination="onResumeDestination" />
                </template>
            </AppHeader>
        </div>
        <AppLoader :force-loading="generalLoading" default-height="75vh">
            <div v-if="pipeline" class="w-full mb-5">
                <Tabs :tabs="tabs" :initial-active-tab="currentTab" :loading="tabLoading" @tab-change="setCurrentTab">
                    <template #Status>
                        <div class="mt-5">
                            <div class="w-full">
                                <h3 class="mb-3 font-medium">Stats</h3>

                                <div class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                    <StatCard
                                        v-for="item in stats"
                                        :key="item.id"
                                        :icon-bg-class="item.colorClass"
                                        :info-icon="item.infoIcon"
                                        :errors="item.errors"
                                        :icon="item.icon"
                                        :loading="metricsLoading || configurationLoading"
                                        :title="item.name"
                                        :value="item.stat" />
                                </div>
                            </div>
                            <!-- <div class="bg-white p-4 rounded-lg shadow my-5">
                            <div class="flex items-center justify-between">
                                <div class="flex gap-2 items-center w-full">
                                    <ChevronUpIcon
                                        v-if="showChart"
                                        class="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-800"
                                        
                                        @click="showChart = !showChart" />
                                    <ChevronDownIcon
                                        v-else
                                        class="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-800"
                                        
                                        @click="showChart = !showChart" />
                                    <h3 v-if="!showChart">{{ chartOptions.title.text }}</h3>
                                </div>
                                <div class="w-1/3">
                                    <div class="relative">
                                        <select v-model="selectedChart" class="form-select text-sm" @change="onChartChange">
                                            <option value="" disabled>Select chart</option>
                                            <option v-for="variant in chartVariants" :key="variant.id" :value="variant.id">
                                                {{ variant.metricName }}
                                            </option>
                                        </select>
                                        <div class="select-icon">
                                            <ChevronDownIcon class="h-5 w-5 text-gray-600"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HighChart v-if="showChart" ref="chart" :chart-options="chartOptions" />
                        </div> -->
                            <div class="flex flex-col lg:flex-row w-full items-center gap-3 mt-5">
                                <div class="w-full lg:w-1/2">
                                    <div class="font-semibold flex items-center gap-3 text-md mb-3 pr-3">Source</div>
                                    <div class="relative flex items-center rounded-lg shadow-md p-4 border gap-3 bg-white min-h-[5.5rem]">
                                        <div class="flex justify-between items-center w-full">
                                            <div class="flex items-center gap-2 ml-4 font-medium flex-col md:flex-row">
                                                <router-link :to="selectedConnectorPath(pipeline.source.id, 'source')">
                                                    <div class="flex items-center gap-2 truncate">
                                                        <img :src="getConnectorIcon(pipeline.source.connector)" alt="" class="w-9 h-9" />
                                                        <span :title="pipeline.source.name" class="truncate" style="max-width: 25rem">{{
                                                            pipeline.source.name
                                                        }}</span>
                                                    </div>
                                                </router-link>
                                                <span
                                                    class="inline-flex items-center rounded-full px-3 py-1 text-xs bg-green-200 text-gray-800 shadow font-medium"
                                                    title="Connectors"
                                                    >{{ getConnectorDisplayName(pipeline.source.connector) }}</span
                                                >
                                                <small
                                                    class="inline-flex items-center justify-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow"
                                                    :class="[
                                                        'inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow',
                                                        pipeline.source.connector_status === 'Broken' ? 'cursor-pointer' : ''
                                                    ]"
                                                    :style="`background: ${getStatusColor(pipeline.source.connector_status)}`"
                                                    @click="() => onStatusClick(pipeline.source)">
                                                    {{ pipeline.source.connector_status }}
                                                    <InformationCircleIcon
                                                        v-if="pipeline.source.connector_status === 'Broken'"
                                                        class="h-4 w-4 ml-1" />
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-1/2">
                                    <div class="font-semibold flex items-center gap-3 text-md mb-3 pr-3">Destination</div>
                                    <div class="relative flex items-center rounded-lg shadow-md p-4 border gap-3 bg-white min-h-[5.5rem]">
                                        <div class="flex justify-between items-center w-full">
                                            <div class="flex items-center gap-2 ml-4 font-medium flex-col md:flex-row">
                                                <router-link :to="selectedConnectorPath(pipeline.destination.id, 'destination')">
                                                    <div class="flex items-center gap-1 truncate">
                                                        <img :src="getConnectorIcon(pipeline.destination.connector)" alt="" class="w-9 h-9" />
                                                        <span :title="pipeline.destination.name" class="truncate" style="max-width: 25rem">{{
                                                            pipeline.destination.name
                                                        }}</span>
                                                    </div>
                                                </router-link>
                                                <span
                                                    class="inline-flex items-center rounded-full px-3 py-1 text-xs bg-green-200 text-gray-800 shadow font-medium"
                                                    title="Connectors"
                                                    >{{ getConnectorDisplayName(pipeline.destination.connector) }}</span
                                                >
                                                <small
                                                    :class="[
                                                        'inline-flex items-center rounded-full font-medium py-1 px-2.5 text-gray-800 shadow',
                                                        pipeline.destination.connector_status === 'Broken' ? 'cursor-pointer' : ''
                                                    ]"
                                                    :style="`background: ${getStatusColor(pipeline.destination.connector_status)}`"
                                                    @click="() => onStatusClick(pipeline.destination)">
                                                    {{ pipeline.destination.connector_status }}
                                                    <InformationCircleIcon
                                                        v-if="pipeline.destination.connector_status === 'Broken'"
                                                        class="h-4 w-4 ml-1" />
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="formattedTopicsAndTransforms.length" class="w-full mt-5">
                                <div class="font-semibold flex items-center gap-3 text-md mb-3 pr-3">Topics</div>
                                <div class="relative w-full shadow-md overflow-auto">
                                    <table class="min-w-full divide-y divide-gray-300 border" aria-label="topics table">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">NAME</th>
                                                <th class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">TYPE</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr
                                                v-for="topicOrTransform in formattedTopicsAndTransforms"
                                                :key="topicOrTransform.id"
                                                class="transition duration-300 ease-in-out hover:bg-gray-50">
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ topicOrTransform.id }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    {{ topicOrTransform.name }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="gap-2 flex flex-col text-gray-600">
                                                        <div class="flex items-center gap-1 text-gray-600">
                                                            <QueueListIcon v-if="topicOrTransform.topic" class="h-5 w-5 text-gray-600" />
                                                            <AdjustmentsVerticalIcon v-else class="h-5 w-5 text-gray-600" />

                                                            {{ topicOrTransform.topic ? 'TOPIC' : 'TRANSFORM' }}
                                                        </div>

                                                        <small v-if="topicOrTransform.transform_name">
                                                            Transform: {{ topicOrTransform.transform_name }}</small
                                                        >
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template #Settings>
                        <div class="mt-5">
                            <div class="w-full">
                                <div class="mb-10">
                                    <label for="name" class="block text-md text-gray-600 font-semibold mb-2"> Name</label>
                                    <div class="flex items-center mt-1">
                                        <input
                                            id="name"
                                            v-model="pipeline.name"
                                            :disabled="generalLoading || actionLoading || transformsLoading || configurationSchemaLoading"
                                            aria-label="input"
                                            name="name"
                                            type="text"
                                            placeholder="Your pipeline name"
                                            autocomplete="name"
                                            required=""
                                            class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm" />
                                    </div>
                                </div>

                                <div class="relative w-full">
                                    <div class="w-full flex flex-col mt-5">
                                        <div class="w-full mb-4">
                                            <div class="flex flex-col items-center w-full">
                                                <div class="w-full">
                                                    <div class="relative flex flex-col rounded-lg shadow-md p-4 border gap-3 bg-white">
                                                        <div class="flex justify-between items-center w-full">
                                                            <div class="flex items-center gap-2 ml-4 font-medium flex-col md:flex-row">
                                                                <router-link :to="selectedConnectorPath(pipeline.source.id, 'source')">
                                                                    <div class="flex items-center gap-2 truncate">
                                                                        <img
                                                                            :src="getConnectorIcon(pipeline.source.connector)"
                                                                            alt=""
                                                                            class="w-9 h-9" />
                                                                        <span
                                                                            :title="pipeline.source.name"
                                                                            class="truncate"
                                                                            style="max-width: 25rem"
                                                                            >{{ pipeline.source.name }}</span
                                                                        >
                                                                    </div>
                                                                </router-link>
                                                                <span
                                                                    class="inline-flex items-center rounded-full px-3 py-1 text-xs bg-green-200 text-gray-800 shadow font-medium"
                                                                    title="Connectors"
                                                                    >{{ getConnectorDisplayName(pipeline.source.connector) }}</span
                                                                >
                                                            </div>
                                                        </div>

                                                        <div class="flex flex-row items-center relative gap-3 mt-5">
                                                            <label for="name" class="block text-md text-gray-600 font-semibold">
                                                                Snapshot new topics
                                                            </label>
                                                            <div class="flex h-5 items-center">
                                                                <label class="relative inline-flex items-center cursor-pointer">
                                                                    <input
                                                                        v-model="snapshotNewTables"
                                                                        aria-label="input"
                                                                        class="mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        :class="{
                                                                            'disabled-toggle': transformsLoading || configurationSchemaLoading,
                                                                            'bg-green-400': snapshotNewTables,
                                                                            'bg-neutral-300': !snapshotNewTables
                                                                        }"
                                                                        :true-value="true"
                                                                        :false-value="false"
                                                                        :disabled="transformsLoading || configurationSchemaLoading"
                                                                        aria-describedby="toggle" />
                                                                </label>
                                                            </div>
                                                            <HalfCircleSpinner :loading="transformsLoading || configurationSchemaLoading" />
                                                        </div>

                                                        <div class="w-full mt-5">
                                                            <label for="name" class="block text-md text-gray-600 font-semibold mb-1">
                                                                Source Schema
                                                            </label>
                                                            <AppLoader :force-loading="configurationSchemaLoading" default-height="15vh">
                                                                <TreeView
                                                                    v-if="
                                                                        treeNodes && treeNodes.length > 0 && treeLevels && treeLevels[0] != undefined
                                                                    "
                                                                    :tree-nodes="treeNodes"
                                                                    :tree-levels="treeLevels"
                                                                    :disable-create-and-edit="true"
                                                                    :edit-mode="true"
                                                                    @can-save-duplicate="updateCanSaveTree" />
                                                            </AppLoader>
                                                        </div>
                                                        <div class="w-full mt-5">
                                                            <label for="name" class="block text-md text-gray-600 font-semibold"> Transforms </label>
                                                            <AppLoader :force-loading="transformsLoading" default-height="15vh">
                                                                <TreeView
                                                                    v-if="
                                                                        transformTreeNodes &&
                                                                        transformTreeLevels &&
                                                                        transformTreeLevels[0] != undefined
                                                                    "
                                                                    :tree-nodes="transformTreeNodes"
                                                                    :tree-levels="transformTreeLevels"
                                                                    :disable-create-and-edit="true"
                                                                    :edit-mode="true"
                                                                    @can-save-duplicate="updateCanSaveTree()" />

                                                                <div v-else>No transforms found</div>
                                                            </AppLoader>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ErrorMessage v-if="formError" :error="formError" :to-sentry="true" :context="'pipeline-details'" class="mt-3" />
                        </div>
                    </template>
                </Tabs>
            </div>
        </AppLoader>
    </div>
    <AppFooter v-if="pipeline" v-can-access.permissions="[$Permissions.WRITE_PIPELINES]">
        <template #right>
            <RowActionMenu
                v-if="!generalLoading"
                :show-save="true"
                :show-discard="false"
                :disable-save="
                    !pipeline?.name ||
                    (nameChanged ? !canSaveTree : false) ||
                    !canSaveTree ||
                    (canSaveTree && areTreesEqual && !nameChanged) ||
                    generalLoading ||
                    configurationSchemaLoading ||
                    transformsLoading
                "
                :loading="actionLoading"
                :disabled="generalLoading || actionLoading"
                @save="onSavePipeline" />
        </template>
    </AppFooter>
</template>

<style></style>
