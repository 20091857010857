export default {
    app: {
        version: import.meta.env.VITE_APP_VERSION,
        env: import.meta.env.MODE,
        get isLocal() {
            return import.meta.env.MODE === 'development' && !!window.location.host.match(/localhost(:\d+)?$/);
        },
        get isDev() {
            return import.meta.env.MODE === 'development';
        },
        get isProd() {
            return import.meta.env.MODE === 'production';
        },
        wsUrl: import.meta.env.VITE_WS_URL,
        apiUrl: import.meta.env.VITE_API_URL,
    },
    sentry: {
        appUrl: import.meta.env.VITE_SENTRY_URL,
        tracingOrigins: [import.meta.env.VITE_WS_URL, import.meta.env.VITE_API_URL],
        tracesSampleRate: 1.0
    },
    frontegg: {
        baseUrl: import.meta.env.VITE_FRONTEGG_URL,
        clientId: import.meta.env.VITE_FRONTEGG_CLIENT_ID
    },
    front: {
        chatId: import.meta.env.VITE_FRONT_CHAT_ID
    },
    novu: {
        appId: import.meta.env.VITE_NOVU_APP_ID,
        envId: import.meta.env.VITE_NOVU_ENVIRONMENT_ID,
        slackIntegrationId: import.meta.env.VITE_NOVU_SLACK_INTEGRATION_ID
    }
};
