<script setup>
import { ref, watch, onMounted } from 'vue';
import ModalComponent from '../global/modals/Modal.vue';
import Tabs from '@/components/global/Tabs.vue';
import AlertPreferences from './AlertPreferences.vue';
import AlertSettings from './AlertSettings.vue';

const props = defineProps({
    isModalOpen: {
        type: Boolean,
        default: false
    }
});

const isOpen = ref(false);

watch(() => props.isModalOpen, (newVal) => {
    isOpen.value = newVal;
});

onMounted(async () => {
    isOpen.value = props.isModalOpen;
});

let tabs = ref([
    { name: 'Settings', href: '#', current: true },
    { name: 'Preferences', href: '#', current: false }
]);

let currentTab = ref('Settings');

function setCurrentTab(tab) {
    currentTab.value = tab;
}
</script>

<template>
    <ModalComponent
        :is-open="isOpen"
        :show-footer-buttons="false"
        title="Alerts configuration"
        size="2xl"
        position="start"
        @update:is-open="isOpen = $event; $emit('update:isOpen', false);"
    >
        <template
            #header
        ></template>
        <div
            class="integrations-list max-h-[70vh]"
        >
            <Tabs
                :tabs="tabs"
                :initial-active-tab="currentTab"
                :loading="tabLoading"
                @tab-change="setCurrentTab"
            ></Tabs>
            <AlertPreferences
                v-if="currentTab == 'Preferences'"
            ></AlertPreferences>
            <AlertSettings
                v-if="currentTab == 'Settings'"
            ></AlertSettings>
        </div>
    </ModalComponent>
</template>