const clickOutside = {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            // Check if click was outside the el and its children
            if (!(el === event.target || el.contains(event.target))) {
                // Ensure the binding value is a function before calling
                if (typeof binding.value === 'function') {
                    binding.value(event);
                }
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    }
};

export default clickOutside;
