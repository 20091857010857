<template>
    <div class="relative outline-none flex items-center" :class="customClass">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none outline-none">
            <svg
                :class="[{ 'opacity-[0.7]': props.loading }, 'w-5 h-5 text-gray-800']"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        </div>
        <input
            aria-label="input"
            :id="`${randomUIDBasedOnTime}-default-search`"
            v-model="searchQuery"
            type="search"
            :disabled="props.loading"
            :class="[
                props.loading ? 'bg-gray-100 opacity-[0.7]' : 'bg-white',
                'block pl-10 w-full text-sm text-gray-900 rounded-lg border border-gray-300 outline-none hover:outline-none'
            ]"
            :placeholder="props.placeholder || 'Search...'"
            style="min-height: 40.5px !important"
            required />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    placeholder: {
        type: String,
        default: ''
    },
    loading: {
        type: Boolean,
        default: false
    },
    customClass: {
        type: String,
        default: 'w-[30%]'
    }
});
const emit = defineEmits(['search']);

const searchQuery = ref('');
const randomUIDBasedOnTime = new Date().getTime();

watch(
    () => searchQuery.value,
    (newSearchQuery) => {
        emit('search', newSearchQuery);
    }
);
</script>
