<template>
    <div
        v-if="isOpen"
        :class="['fixed', 'inset-0', 'flex', `items-${position}`, 'pt-20', 'justify-center', 'lg:pl-[254px]']"
        style="z-index: 999 !important">
        <!-- Backdrop -->
        <div class="absolute inset-0 bg-black opacity-50"></div>

        <!-- Modal -->
        <div :class="['relative bg-white rounded-lg shadow-lg', modalWidthClass, 'w-2/3 lg:w-full']">
            <!-- Modal Header -->
            <div class="border-b p-4 flex justify-between items-center">
                <div v-if="title" class="font-medium text-lg flex-grow" v-html="sanitizeHtml(title)" />
                <div class="flex items-center space-x-2">
                    <!-- This flex container holds the slot and the close button -->
                    <slot name="header"></slot>
                    <button aria-label="button" @click="closeModal">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Modal content slot -->
            <div class="p-4">
                <slot></slot>
            </div>

            <!-- Footer buttons -->
            <div v-if="showFooterButtons" class="border-t p-4 flex justify-end space-x-2">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import DOMPurify from 'dompurify';
import _ from 'lodash';

export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        showFooterButtons: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: 'md'
        },
        position: {
            type: String,
            default: 'center'
        }
    },
    computed: {
        modalWidthClass() {
            switch (this.size) {
                case 'sm':
                    return 'max-w-xs';
                case 'lg':
                    return 'max-w-lg';
                case 'xl':
                    return 'max-w-xl';
                case '2xl':
                    return 'max-w-2xl';
                case '3xl':
                    return 'max-w-3xl';
                case '4xl':
                    return 'max-w-4xl';
                case '5xl':
                    return 'max-w-5xl';
                case 'xxl':
                    return 'max-w-screen-xl'; // For XXL size
                case 'md':
                default:
                    return 'max-w-md';
            }
        }
    },
    methods: {
        sanitizeHtml(html) {
            if (!_.isNaN(Number(html))) {
                return html;
            }

            return DOMPurify.sanitize(html);
        },
        closeModal() {
            this.$emit('update:isOpen', false);
        }
    }
};
</script>

<style scoped>
/* You can add any additional styles if needed */
</style>
