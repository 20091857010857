import TransformsPage from '@/views/transforms/TransformsPage.vue';
import { useMetricsStore } from '@/stores/metrics.store';
import WebSocketService from '../services/webSocket.service';
import { Permissions } from '@/utils/constants';

export default [
    {
        path: '/transforms',
        name: 'transforms',
        component: TransformsPage,
        meta: {
            title: 'Transforms',
            requireAuth: true,
            onEnter: () => {
                const metricsStore = useMetricsStore();

                WebSocketService.addListener('metrics', 'transforms', null, metricsStore.onMetricsData, 'metrics.onMetricsData');
            },
            accessRules: {
                permissions: {
                    values: [Permissions.READ_TRANSFORMS, Permissions.READ_TRANSFORM_METRICS], // write is not required to see the page and will hide the action buttons if needed
                    type: 'and'
                }
            }
        }
    }
];
